import React, { useCallback, useMemo, useState } from 'react'
import { useTable, useExpanded } from 'react-table'
import s from './campaignReportTable.module.scss'
import { ReportTableHead } from './ReportTableHead'
import { ReportTableBody } from './ReportTableBody'
import { ReportDetailModal } from '../report-detail-modal/ReportDetailModal'
import { useCampaignReportStore } from '../../store/campaignReportStore'
import { CampaignReportType, ReportDetailModalType } from '../../types'
import { getTableData } from '../../utils'
import { reportTableColumns } from '../../constants'

export const CampaignReportTable: React.FC = () => {
	const [detailModal, setDetailModal] = useState<
		ReportDetailModalType | undefined
	>(undefined)

	const report = useCampaignReportStore(
		(store) => store.report
	) as Partial<CampaignReportType>

	const data = useMemo(() => getTableData(report), [report])

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({ columns: reportTableColumns, data }, useExpanded)

	const handleDetailClick = useCallback((type: ReportDetailModalType) => {
		setDetailModal(type)
	}, [])

	const handleDetailModalClose = useCallback(() => {
		setDetailModal(undefined)
	}, [])
	return (
		<>
			<table {...getTableProps()} className={s.table}>
				<ReportTableHead headerGroups={headerGroups} />
				<ReportTableBody
					onMoreDetailClick={handleDetailClick}
					getTableBodyProps={getTableBodyProps}
					prepareRow={prepareRow}
					rows={rows}
				/>
			</table>
			{detailModal && (
				<ReportDetailModal
					advFormat={detailModal}
					onClose={handleDetailModalClose}
					open={!!detailModal}
				/>
			)}
		</>
	)
}
