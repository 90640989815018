import React from 'react'
import { Button } from 'react-bootstrap'
import { ReactComponent as ArrowBack } from 'src/assets/images/backNew.svg'
import { RightPanelFooter, RightPanelPlacementsList } from '../../right-panel'
import { PlacementShort } from 'src/stores/ADMarketTypes.types'
import s from './mobileModal.module.scss'

type Props = {
	onClose: () => void
	itemsCount: number
	onSubmit: () => void
	addedPlacement: PlacementShort[]
	onRemoveItem: (itemId: number) => void
	onRemoveAll: () => void
}

export const AddedPlacementsMobile = ({
	onClose,
	itemsCount,
	onSubmit,
	addedPlacement,
	onRemoveItem,
	onRemoveAll,
}: Props) => {
	return (
		<div className={s.addedPlacementsMobile}>
			<div className={s.addedPlacementsMobile_header}>
				<div
					onClick={onClose}
					className={s.addedPlacementsMobile_headerBackButton}
				>
					<ArrowBack style={{ width: '1rem', height: '1rem' }} />
				</div>

				<div style={{}}>
					<p
						style={{
							margin: 0,
							width: '100%',
						}}
					>
						Добавленные места
					</p>
				</div>
			</div>

			<RightPanelPlacementsList
				addedPlacement={addedPlacement}
				onRemoveItem={onRemoveItem}
			/>

			<div
				style={{
					padding: '1rem',
					width: '100%',
					borderTop: '1px solid #CED4DA',
				}}
			>
				<div
					style={{
						paddingBottom: '1rem',
						borderBottom: '1px solid #CED4DA',
					}}
				>
					<Button
						onClick={onRemoveAll}
						style={{ width: '100%' }}
						variant={'danger'}
					>
						Убрать все поверхности
					</Button>
				</div>
				<div>
					<RightPanelFooter
						itemsCount={itemsCount}
						onSubmit={onSubmit}
					/>
				</div>
			</div>
		</div>
	)
}
