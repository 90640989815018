import React, { useEffect, useMemo, useState } from 'react'
import bem from '../../utils/bem'
import '../../styles/providersidebar.scss'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { provider_routes } from '../_provider/provider_page/ProviderPage'
import { ReactComponent as Arrow } from '../../assets/images/arrow.svg'
import useResponsive from '../../utils/useResponsive'
import useProviderData from '../../stores/providerData'
import { useHistory, useLocation } from 'react-router-dom'
import { FOOTER_LINKS } from './Footer'

const cn = bem('provider-sidebar')

const ProviderSideBar = React.forwardRef(function ProviderSideBar(
	{ forceOpened, setForceOpened }: any,
	ref: any
) {
	const [active, setActive] = useState('')
	const [provider] = useProviderData()
	const { isTablet, isMobile } = useResponsive()
	const history = useHistory()
	const location = useLocation()
	const handleLinkClick = (path) => {
		if (location.pathname.includes(path)) return false
		history.push('/provider' + path)
		setActive(path)
	}
	const menuWithCounts = useMemo(() => {
		if (!provider) return provider_routes
		const routes_copy = provider_routes.map((i) => {
			return { ...i }
		})
		routes_copy.forEach((element) => {
			switch (element.path) {
				case '/bookings':
					element.count = provider?.counts?.bookings?.total_count
					break
				case '/media-formats':
					element.count = provider?.counts?.media_types?.total_count
					break
				case '/placements':
					element.count = provider?.counts?.placements?.total_count
					break
			}
		})
		return routes_copy
	}, [provider])

	useEffect(() => {
		if (forceOpened) {
			localStorage.setItem('sidebar', 'open')
		} else {
			localStorage.setItem('sidebar', 'close')
		}
	}, [forceOpened])

	// Ставим активным пункт меню при первом рендере
	useEffect(() => {
		provider_routes.forEach((route) => {
			if (route.renderToMenu) {
				if (location.pathname.includes(route.path))
					return setActive(route.path)
			}
		})
	}, [location])

	const handleHideSidebar = () => {
		setForceOpened(false)
	}
	const handleShowSidebar = () => {
		setForceOpened(true)
	}
	return (
		<div
			className={cn(null, { force: forceOpened, absolute: isTablet })}
			ref={ref}
		>
			{!isTablet && !isMobile && (
				<OverlayTrigger
					placement="right"
					overlay={(props) => (
						<Tooltip id="sidebar_show_btn-tooltip" {...props}>
							Раскрыть меню
						</Tooltip>
					)}
				>
					<div className={cn('hidden')} onClick={handleShowSidebar}>
						<Arrow />
					</div>
				</OverlayTrigger>
			)}
			{!isTablet && !isMobile && (
				<OverlayTrigger
					placement="right"
					overlay={(props) => (
						<Tooltip id="sidebar_hide_btn-tooltip" {...props}>
							Скрыть меню
						</Tooltip>
					)}
				>
					<div
						className={cn('hide_btn')}
						onClick={handleHideSidebar}
						id="sidebar_hide_btn"
					>
						<Arrow />
					</div>
				</OverlayTrigger>
			)}

			<div className={cn('routes')}>
				{menuWithCounts.map(
					(i, k) =>
						i.renderToMenu && (
							<a
								key={k}
								onClick={(e) => {
									e.preventDefault()
									if (isTablet) setForceOpened(false)
									handleLinkClick(i.path)
								}}
								href={'/provider' + i.path}
								className={active === i.path ? 'active' : ''}
							>
								<div>{i.title}</div>
								<Badge>{i.count}</Badge>
							</a>
						)
				)}
			</div>
			<div className={cn('links')}>
				{FOOTER_LINKS.map((link) => (
					<a
						href={link.href}
						key={link.href}
						className={link.blue ? 'text-primary' : ''}
						target={'_blank'}
						rel="noreferrer"
					>
						{link.label}
					</a>
				))}
				<p className={'mt-4'}>© 2024 ООО «ЭдТех Инвест»</p>
			</div>
		</div>
	)
})

export default ProviderSideBar
