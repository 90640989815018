import React, { useEffect, useMemo, useState } from 'react'
import { TVIcon } from '../../Step2-ADformats/TVAd/TVSelector'
import { Formik } from 'formik'
import { Button, Card, Dropdown, Form, Spinner } from 'react-bootstrap'
import useResponsive from '../../../../../utils/useResponsive'
import s from '../DataUpload.module.scss'
import TvUploadItem from './TVUploadItem'
import useCampaignData from '../../../../../stores/campaignData'
import useBidRequest from '../CreateCreative/useBidRequest'
import {
	REUSABLE_MODALS,
	useReusableModals,
} from '../../../../UtilityComponents/ReusableModals/ReusableModalsProvider'
import { AutoPostingLegalInfo } from '../components/auto-posting-legal-info/AutoPostingLegalInfo'

const TvUpload = ({
	isLoading,
	cn,
	onBack,
	onNext,
	isStepper,
	setGlobalStep,
	isLastStep,
	onStepBack,
	step,
	totalSteps,
}) => {
	const { isMobile } = useResponsive()
	const [isMediaPlanLoading, setIsMediaPlanLoading] = useState(false)
	const [campaign] = useCampaignData()
	const { isHaveBidRequest } = useBidRequest()
	const showModal = useReusableModals()
	const handleSubmit = async (values) => {
		onNext()
	}

	const isNextBtnDisabled = useMemo(() => {
		if (campaign.teletarget.is_selected) {
			if (
				!(
					campaign.teletarget.l_image ||
					campaign.teletarget.image ||
					isHaveBidRequest({
						id: campaign.teletarget.id,
						type: 'teletarget',
					})
				)
			)
				return true
		}
		if (campaign.adstream.is_selected) {
			if (
				!(
					campaign.adstream.image ||
					isHaveBidRequest({
						id: campaign.adstream.id,
						type: 'adstream',
					})
				)
			)
				return true
		}
		return false
	}, [campaign])

	useEffect(() => {
		let timeoutId: NodeJS.Timeout

		if (isMediaPlanLoading) {
			timeoutId = setTimeout(() => {
				setIsMediaPlanLoading(false)
			}, 3000)
		}

		return () => clearTimeout(timeoutId)
	}, [isMediaPlanLoading])

	const handleMediaPlanClick = async () => {
		setIsMediaPlanLoading(true)
		try {
			await showModal(REUSABLE_MODALS.showMediaPlanModal)
		} catch (error) {
		} finally {
			setIsMediaPlanLoading(false)
		}
	}

	//TODO зарефакторить как минимум нижний блок кнопок во всех step-3 Upload компонентах
	return (
		<>
			<Formik initialValues={{}} onSubmit={handleSubmit}>
				{({
					values,
					setFieldValue,
					handleSubmit,
					errors,
					setFieldError,
				}: any) => (
					<Form onSubmit={handleSubmit}>
						<AutoPostingLegalInfo />

						<Card className={s.card}>
							<div className={'d-flex justify-content-between'}>
								<h4 className={'mb-0'}>ТВ-Реклама</h4>
								<TVIcon />
							</div>
							<div className={'mt-2'} style={{ fontWeight: 400 }}>
								Загрузите необходимые материалы в соответствии с
								их техническими требованиями.
							</div>
						</Card>

						{campaign.adstream.is_selected && (
							<Card
								className={s.card}
								style={{ marginTop: '8px' }}
							>
								<TvUploadItem adName={'adstream'} />
							</Card>
						)}
						{campaign.teletarget.is_selected && (
							<>
								{campaign.teletarget.is_small_banner && (
									<Card
										className={s.card}
										style={{ marginTop: '8px' }}
									>
										<TvUploadItem
											adName={'teletarget'}
											teletarget_banner_type={
												'small_banner'
											}
										/>
									</Card>
								)}
								{campaign.teletarget.is_l_banner && (
									<Card
										className={s.card}
										style={{ marginTop: '8px' }}
									>
										<TvUploadItem
											adName={'teletarget'}
											teletarget_banner_type={'l_banner'}
										/>
									</Card>
								)}
							</>
						)}

						{/*controls buttons*/}
						<div className={cn('buttons')}>
							<Button
								variant={'primary'}
								onClick={onNext}
								style={
									isMobile
										? { width: '100%', marginLeft: '10px' }
										: {}
								}
								disabled={isNextBtnDisabled || isLoading}
							>
								{/* {isLastStep ? 'Последний шаг' : 'Продолжить'} */}
								Продолжить
								{isLoading && (
									<Spinner
										style={{ marginLeft: '6px' }}
										size="sm"
										animation="border"
									/>
								)}
							</Button>
							<Button
								variant={'secondary'}
								onClick={() => {
									if (isStepper) {
										return step - 1 >= 0
											? onStepBack()
											: onBack()
									}
									return onBack()
								}}
							>
								Назад
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</>
	)
}

export default TvUpload
