import React, { useState } from 'react'
import {
	HandleActiveItemChangeType,
	HelpContentKey,
	helpList,
} from '../../constants'
import { HelpListItem } from './HelpListItem'

type HelpListProps = {
	activeItem: HelpContentKey
	nestedItem: HelpContentKey | undefined
	changeActive: HandleActiveItemChangeType
}

export const HelpList = ({
	changeActive,
	activeItem,
	nestedItem,
}: HelpListProps) => {
	return (
		<ul
			style={{
				listStyleType: 'none',
				padding: 0,
				margin: '8px',
				position: 'sticky',
				top: 74,
				left: 0,
			}}
		>
			{helpList.map((el) => (
				<HelpListItem
					changeActive={changeActive}
					activeNestedItem={nestedItem}
					isActive={el.key === activeItem}
					item={el}
					key={el.key}
				/>
			))}
		</ul>
	)
}
