import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { BackToSelectorsCard } from '../../../adv-format-stage/selected-adv-format/back-to-selectors-card/BackToSelectorsCard'
import { BottomNav } from './bottom-nav/BottomNav'
import { RecommendedFormats } from './rec-variants/RecommendedFormats'
import { RecommendationPresentation } from './rec-presentation/RecommendationPresentation'
import s from './recommendations.module.scss'
import { CardWrapper } from '../../../common/card-wrapper/CardWrapper'
import { Spinner } from 'react-bootstrap'
import { authFetch } from 'src/utils/authFetch'
import { BASE_URL } from 'src/constants/api'
import useCampaignData from 'src/stores/campaignData'
import {
	RecommendationsApplyInput,
	RecommendationsType,
} from 'src/stores/types/recommendations'
import * as yup from 'yup'
import { InputAlert } from './input-alert/InputAlert'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'

const validationSchema = yup.object().shape({
	vk_link: yup
		.string()
		.required('Необходим адрес сайта')
		.test('is-valid-url', 'Введите правильный адрес сайта', (value) =>
			value
				? /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-./?%&=]*)?$/.test(
						value
				  )
				: false
		),
	yandex_link: yup
		.string()
		.required('Необходим адрес сайта')
		.test('is-valid-url', 'Введите правильный адрес сайта', (value) =>
			value
				? /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-./?%&=]*)?$/.test(
						value
				  )
				: false
		),
})

type Props = {
	onBack: () => void
}
export const Recommendations = ({ onBack }: Props) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { addToast } = useContext(ToastsContext)

	const [pageProcess, setPageProcess] = useState<
		'loading' | 'submitting' | 'pending'
	>('pending')

	const [recommendations, setRecommendations] =
		useState<RecommendationsType | null>(null)

	const isInputNeeded = !!recommendations?.vk || !!recommendations?.yandex

	const [applyData, setApplyData] = useState<RecommendationsApplyInput>({
		vk_link: '',
		yandex_link: '',
	})
	const [errors, setErrors] = useState<RecommendationsApplyInput>({
		vk_link: '',
		yandex_link: '',
	})

	const fetchRecommendations = async () => {
		setPageProcess('loading')
		try {
			const result = await authFetch<RecommendationsType>({
				method: 'GET',
				url: `${BASE_URL}/recommendation/${campaign.id}/`,
			})
			setRecommendations(result)
		} catch (error) {
			console.log('error', error)
		} finally {
			setPageProcess('pending')
		}
	}

	const applyRecommendations = async () => {
		//данные которые отправятся вместе с применением рекомендаций
		let inputData: Partial<RecommendationsApplyInput> = {}

		//Если необходимо отправить данные надо выполнить их проверку
		if (isInputNeeded) {
			//определяем поля для валидации
			const fieldsToValidate: (keyof RecommendationsApplyInput)[] = []

			if (recommendations?.vk) {
				fieldsToValidate.push('vk_link')
			}
			if (recommendations?.yandex) {
				fieldsToValidate.push('yandex_link')
			}

			//обновляем ошибки перед валидацией
			let validationErrors: RecommendationsApplyInput = {
				vk_link: '',
				yandex_link: '',
			}

			//проверяем все необходимые поля
			for (const field of fieldsToValidate) {
				const error = await validateField(field, applyData[field])
				if (error) {
					validationErrors[field] = error
				}
			}

			//выход из функции если есть ошибки, иначе - заполнение данных
			if (Object.values(validationErrors).some((error) => error)) {
				setErrors(validationErrors)
				return
			} else {
				for (const field of fieldsToValidate) {
					inputData[field] = addProtocolIfMissing(applyData[field])
				}
			}
		}

		try {
			setPageProcess('submitting')

			await authFetch({
				method: 'POST',
				url: `${BASE_URL}/recommendation/${campaign.id}/using/`,
				body: inputData,
				raw: true,
			})
			onBack()
			await campaignInterface.refetchSelected()
			addToast({
				text: 'Рекомендации успешно применены',
				type: 'success',
			})
		} catch (error) {
			addToast({
				text: 'Не удалось применить рекомендации',
				type: 'danger',
			})
			setPageProcess('pending')
		}
	}

	const handleChangeApplyInput = useCallback(
		(field: keyof RecommendationsApplyInput, value: string) => {
			setErrors((prev) => ({ ...prev, [field]: '' }))
			setApplyData((prev) => ({ ...prev, [field]: value }))
		},
		[]
	)

	const shouldShowRec = useMemo(() => {
		return checkShouldShowRecommended(recommendations)
	}, [recommendations])

	useEffect(() => {
		fetchRecommendations()
	}, [])

	return (
		<div className={s.recommendationsContainer}>
			<BackToSelectorsCard onBackClick={onBack} />
			{pageProcess === 'loading' ? (
				<CardWrapper>
					<div className="d-flex align-items-center justify-content-center">
						<Spinner animation="border" variant="primary" />
					</div>
				</CardWrapper>
			) : shouldShowRec ? (
				<div className={s.recommendationsContent}>
					<RecommendationPresentation />
					<RecommendedFormats
						errors={errors}
						recommendations={recommendations}
						onChangeApplyInput={handleChangeApplyInput}
						applyInput={applyData}
					/>
				</div>
			) : (
				<CardWrapper>
					<div className="d-flex align-items-center justify-content-center">
						<h5>Не удалось подобрать рекоммендации</h5>
					</div>
				</CardWrapper>
			)}

			{isInputNeeded && (
				<InputAlert
					isVk={!!recommendations.vk}
					isYandex={!!recommendations.yandex}
					errors={errors}
					applyData={applyData}
				/>
			)}

			<BottomNav
				nextDisabled={
					!!errors.vk_link ||
					!!errors.yandex_link ||
					pageProcess !== 'pending'
				}
				isLoading={pageProcess === 'submitting'}
				onBack={onBack}
				onNext={applyRecommendations}
			/>
		</div>
	)
}

/**
 * Валидирует отдельное поле на основе схемы Yup.
 *
 * @param fieldName - Название поля, которое нужно валидировать.
 * @param value - Значение поля для проверки.
 * @returns Промис, который возвращает строку с ошибкой или пустую строку, если ошибок нет.
 */
const validateField = async (
	fieldName: keyof RecommendationsApplyInput,
	value: string
): Promise<string> => {
	try {
		// Динамически валидируем только указанное поле
		await validationSchema.validateAt(fieldName, { [fieldName]: value })
		return '' // Ошибок нет
	} catch (err) {
		if (err instanceof yup.ValidationError) {
			return err.message // Возвращаем сообщение об ошибке
		}
		throw err // Если ошибка не относится к Yup, пробрасываем её дальше
	}
}

// Функция для добавления протокола, если он отсутствует
function addProtocolIfMissing(url: string): string {
	if (!/^(https?:\/\/)/.test(url)) {
		return `https://${url}`
	}
	return url
}

function checkShouldShowRecommended(
	recommendations: RecommendationsType | null
) {
	if (!recommendations) {
		return false
	}

	const variantsKeys = [
		'yandex',
		'vk',
		'adstream',
		'public_transport',
		'bookings',
		'districtbookings',
	]

	for (const variantKey of variantsKeys) {
		if (recommendations[variantKey]) {
			return true
		}
	}

	return false
}
