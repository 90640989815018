import React, { ChangeEvent } from 'react'
import { Form, Button } from 'react-bootstrap'
import { Formik, Form as FormikForm, FormikHelpers } from 'formik'
import { NewPasswordFormData } from '../../../model/types'
import { newPasswordValidationSchema } from '../../../model/schemas'
import s from './resetStages.module.scss'
import { SubmitButton } from '../../common/SubmitButton'

type Props = {
	onSubmit: (
		values: NewPasswordFormData,
		formikHelpers: FormikHelpers<NewPasswordFormData>
	) => Promise<void>
}

export const NewPassword = ({ onSubmit }: Props) => {
	const newPasswordHelperText =
		'Пароль должен содержать не менее восьми знаков, включать буквы, цифры и специальные символы'

	const handlePasswordChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		setFieldValue: (field: string, value: any) => void,
		setFieldError: (field: string, message: string | undefined) => void
	) => {
		setFieldValue('password', e.target.value)
		setFieldError('password', undefined)
	}

	const handlePasswordConfirmationChange = (
		e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		setFieldValue: (field: string, value: any) => void,
		setFieldError: (field: string, message: string | undefined) => void
	) => {
		setFieldValue('password_confirmation', e.target.value)
		setFieldError('password_confirmation', undefined)
	}

	return (
		<div>
			<Formik<NewPasswordFormData>
				initialValues={{
					password: '',
					password_confirmation: '',
				}}
				validationSchema={newPasswordValidationSchema}
				validateOnChange={false}
				validateOnBlur={false}
				onSubmit={onSubmit}
			>
				{({
					errors,
					touched,
					setFieldValue,
					isSubmitting,
					setFieldError,
				}) => (
					<FormikForm>
						<div className={s.formContent}>
							<div className={s.inputs}>
								<Form.Group controlId="new-password">
									<Form.Label>Новый пароль</Form.Label>
									<Form.Control
										disabled={isSubmitting}
										type="password"
										placeholder="••••"
										aria-label="Новый пароль"
										aria-describedby="new-password"
										isInvalid={
											touched.password &&
											!!errors.password
										}
										className={s.autofillNone}
										onChange={(e) =>
											handlePasswordChange(
												e,
												setFieldValue,
												setFieldError
											)
										}
									/>
									{errors.password ? (
										<Form.Control.Feedback type="invalid">
											{errors.password}
										</Form.Control.Feedback>
									) : (
										<span
											className={s.newPasswordHelperText}
										>
											{newPasswordHelperText}
										</span>
									)}
								</Form.Group>
								<Form.Group controlId="confirm-password">
									<Form.Label>
										Подтверждение пароля
									</Form.Label>
									<Form.Control
										disabled={isSubmitting}
										type="password"
										placeholder="••••"
										aria-label="Подтверждение пароля"
										aria-describedby="confirm-password"
										isInvalid={
											touched.password_confirmation &&
											!!errors.password_confirmation
										}
										className={s.autofillNone}
										onChange={(e) =>
											handlePasswordConfirmationChange(
												e,
												setFieldValue,
												setFieldError
											)
										}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.password_confirmation}
									</Form.Control.Feedback>
								</Form.Group>
							</div>

							<SubmitButton
								disabled={isSubmitting}
								text="Обновить пароль"
							/>
						</div>
					</FormikForm>
				)}
			</Formik>
		</div>
	)
}
