import {
	Formik,
	FormikErrors,
	FormikHelpers,
	FormikProps,
	useFormikContext,
} from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { SingleValue } from 'react-select'
import {
	NotificationDetails,
	NotificationModel,
} from 'src/components/_moderator/types/ModerationRequestTypes'
import { ReactComponent as Close } from 'src/assets/images/close cancel x.svg'
import Select from 'react-select'
import { SenderTypeButtons } from '../common'
import { SubmitValue } from 'src/components/_moderator/types/SubmitValue'
import useResponsive from 'src/utils/useResponsive'
import { ReactComponent as Arrow } from 'src/assets/images/arrow.svg'

type OptionType = {
	value: string
	label: string
}

type FormType = {
	header: string
	text: string
	senderType: 'client' | 'adv_company' | 'adv_type'
	senderItems: string[]
	manager: SingleValue<OptionType> | undefined
}

type Props = {
	notification: NotificationModel & NotificationDetails
	senderItems: string[]
	manager: SingleValue<OptionType> | undefined
	errors: FormikErrors<FormType> | undefined
	isLoading: boolean
	onDelete: () => void
	onSubmit: (
		values: FormType,
		formikHelpers: FormikHelpers<FormType>
	) => void | Promise<any>
	validate: (
		values: FormType
	) => void | object | Promise<FormikErrors<FormType>>
	closeNeeded: boolean
	allManagers: {
		label: string
		value: string
	}[]
	onManagerChange: (
		manager: SingleValue<OptionType>,
		setFieldValue: (
			field: string,
			value: SingleValue<OptionType>,
			shouldValidate?: boolean | undefined
		) => void
	) => void
	onSenderItemsChange: (
		items: string[],
		setFieldValue: (
			field: string,
			value: any,
			shouldValidate?: boolean | undefined
		) => void
	) => void
	mode: 'edit' | 'create'
	onClose: () => void
	updateDraftNotification: (values: Partial<SubmitValue>) => Promise<void>
}

export const NotificationEditContent = ({
	notification,
	senderItems,
	manager,
	errors,
	onSubmit,
	isLoading,
	onDelete,
	validate,
	allManagers,
	onManagerChange,
	onSenderItemsChange,
	mode,
	closeNeeded,
	onClose,
	updateDraftNotification,
}: Props) => {
	const { isMobile } = useResponsive()

	const ref = useRef<FormikProps<FormType>>(null)

	const handleSaveDraftChanges = async () => {
		if (ref && ref.current?.dirty) {
			await updateDraftNotification(ref.current.values)
		}
		onClose()
	}
	useEffect(() => {
		if (closeNeeded) {
			handleSaveDraftChanges()
		}
	}, [closeNeeded])
	return (
		<Formik<FormType>
			innerRef={ref}
			initialValues={{
				header: notification.title,
				text: notification.description,
				senderType:
					notification.category === 'related_item' ||
					!notification.category
						? 'client'
						: notification.category,
				senderItems: senderItems,
				manager: manager,
			}}
			initialErrors={errors}
			onSubmit={onSubmit}
			validateOnChange={true}
			enableReinitialize
			validate={validate}
		>
			{({
				values,
				setFieldValue,
				handleSubmit,
				errors,
				initialErrors,
				setFieldError,
				setValues,
				dirty,
			}) => (
				<>
					{/* {isMobile ? (
						<Arrow
							fill={'#000'}
							style={{
								position: 'absolute',
								left: 15,
								top: 14,
								transform: 'scale(1.6)',
							}}
							onClick={(e) =>
								onClose({
									dirty: dirty,
									values: values,
								})
							}
						/>
					) : (
						<Button
							variant="text"
							style={{
								position: 'absolute',
								top: '0.75rem',
								right: '1rem',
							}}
							onClick={() =>
								onClose({
									dirty: dirty,
									values: values,
								})
							}
						>
							<Close />
						</Button>
					)} */}

					<Form
						className={'d-flex flex-column gap-3 p-3'}
						style={{
							flexGrow: 1,
							overflowY: isMobile ? 'auto' : 'inherit',
						}}
					>
						<Form.Group>
							<Form.Label>Заголовок</Form.Label>
							<Form.Control
								size={'lg'}
								value={values.header}
								onChange={(e) => {
									setFieldValue('header', e.target.value)
									setFieldError('header', undefined)
								}}
								isInvalid={!!errors.header}
								name={`header`}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.header}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group>
							<Form.Label>Основой текст</Form.Label>
							<Form.Control
								size={'lg'}
								value={values.text}
								onChange={(e) => {
									setFieldValue(`text`, e.target.value)
									setFieldError(`text`, undefined)
								}}
								isInvalid={!!errors[`text`]}
								name={`text`}
								as={'textarea'}
							/>
							<Form.Control.Feedback type="invalid">
								{errors[`text`]}
							</Form.Control.Feedback>
						</Form.Group>

						<Form.Group
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Form.Label>Отправить по</Form.Label>
							<SenderTypeButtons
								onChange={(items: string[]) =>
									onSenderItemsChange(items, setFieldValue)
								}
								detailedNotify={notification}
								setFieldValue={setFieldValue}
								value={values.senderType}
							/>
							<div
								className="invalid-feedback"
								style={{
									display: !!errors.senderItems
										? 'flex'
										: 'none',
								}}
							>
								{errors.senderItems}
							</div>
						</Form.Group>
						<Form.Group
							style={{
								display: 'flex',
								flexDirection: 'column',
							}}
						>
							<Form.Label>Менеджер</Form.Label>
							<Select
								menuPlacement={isMobile ? 'top' : 'auto'}
								options={allManagers}
								placeholder={'Выберите менеджера'}
								value={values.manager}
								onChange={(e) =>
									onManagerChange(e, setFieldValue)
								}
							/>
							<div
								className="invalid-feedback"
								style={{
									display: !!errors.manager ? 'flex' : 'none',
								}}
							>
								{errors.manager}
							</div>
						</Form.Group>
					</Form>
					{isMobile ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								gap: '0.5rem',
								padding: '1rem',
								borderTop: '1px solid #CED4DA',
							}}
						>
							<BottomButtons
								handleSubmit={handleSubmit}
								isLoading={isLoading}
								mode={mode}
								onDelete={onDelete}
								disabled={
									!values.senderItems.length ||
									!values.manager ||
									!values.header ||
									!values.text ||
									!dirty ||
									isLoading
								}
							/>{' '}
						</div>
					) : (
						<Modal.Footer>
							<BottomButtons
								handleSubmit={handleSubmit}
								isLoading={isLoading}
								mode={mode}
								onDelete={onDelete}
								disabled={
									!values.senderItems.length ||
									!values.manager ||
									!values.header ||
									!values.text ||
									!dirty ||
									isLoading
								}
							/>
						</Modal.Footer>
					)}
				</>
			)}
		</Formik>
	)
}

type BottomButtonsProps = {
	onDelete: () => void
	handleSubmit: any
	disabled: boolean
	mode: 'edit' | 'create'
	isLoading: boolean
}
export const BottomButtons = ({
	disabled,
	handleSubmit,
	onDelete,
	mode,
	isLoading,
}: BottomButtonsProps) => {
	return (
		<>
			<Button variant="danger" onClick={onDelete}>
				Удалить
			</Button>
			<Button
				variant="primary"
				onClick={handleSubmit as any}
				type={'submit'}
				disabled={disabled}
			>
				{mode === 'edit' ? 'Сохранить' : 'Создать'}{' '}
				{isLoading && (
					<Spinner
						style={{ marginLeft: '6px' }}
						size="sm"
						animation="border"
					/>
				)}
			</Button>
		</>
	)
}
