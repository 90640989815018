import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { withPage } from '../../page/Page'
import s from './ProviderSettingsPage.module.scss'
import {
	Button,
	Dropdown,
	Form,
	OverlayTrigger,
	Spinner,
	Tooltip,
} from 'react-bootstrap'
import { Formik } from 'formik'
import useProviderData from '../../../stores/providerData'
import { ToastsContext } from '../../UtilityComponents/ToastsContextProvider'
import PhoneFormatter from '../../../utils/phone-formatter'
import moment from 'moment'

const ProviderSettingsPage = () => {
	const [provider, providerInterface] = useProviderData()
	const [loading, setLoading] = useState(true)
	useEffect(() => {
		;(async () => {
			await providerInterface.fetchInvites()
			await providerInterface.fetchMembers()
			setLoading(false)
		})()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const currentInvites = useMemo(() => {
		if (provider?.invites) return provider.invites
		return []
	}, [provider])

	const currentMembers = useMemo(() => {
		if (provider?.members) return provider.members
		return []
	}, [provider])
	if (loading)
		return (
			<div className={s.settingsContainer} style={{ height: '100%' }}>
				<div
					className={
						'd-flex justify-content-center align-items-center h-100'
					}
				>
					<Spinner animation={'border'} variant={'primary'} />
				</div>
			</div>
		)
	return (
		<div className={s.settingsContainer}>
			<h1>Настройки</h1>
			<section className={s.settingsSection}>
				<h3>Приглашения</h3>
				<Dropdown.Divider className={'m-0 p-0'} />
				<InviteProviderBlock providerInterface={providerInterface} />
				<CurrentInvitesBlock
					currentInvites={currentInvites}
					providerInterface={providerInterface}
				/>
			</section>
			<section className={s.settingsSection}>
				<h3>Пользователи</h3>
				<Dropdown.Divider className={'m-0 p-0'} />
				<ProviderUsers currentMembers={currentMembers} />
			</section>
		</div>
	)
}

const InviteProviderBlock = ({ providerInterface }) => {
	const { addToast } = useContext(ToastsContext)
	const validateEmail = useCallback((elementValue) => {
		const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
		return emailPattern.test(elementValue)
	}, [])
	async function handleValidate(values) {
		const errors: any = {}
		if (!validateEmail(values.email)) {
			errors.email = 'Введите корректный email.'
		}
		return errors
	}
	async function handleSubmit(
		values,
		{ setSubmitting, resetForm, setFieldError }
	) {
		const errors = await providerInterface.sendInvite(values.email)
		if (errors) {
			setFieldError('email', errors.email[0])
		} else {
			resetForm()
			addToast({
				text: 'Приглашение отправлено',
				type: 'success',
				withAction: false,
			})
		}
	}
	return (
		<div className={s.inviteProvider}>
			<Formik
				initialValues={{
					email: '',
				}}
				validate={handleValidate}
				onSubmit={handleSubmit}
				validateOnChange={false}
			>
				{({
					values,
					errors,
					handleSubmit,
					setErrors,
					setFieldValue,
				}) => (
					<Form onSubmit={handleSubmit} style={{ width: '100%' }}>
						<Form.Group
							style={{ marginBottom: '24px', flex: '1 0 auto' }}
						>
							<Form.Label>Email</Form.Label>
							<Form.Control
								size="lg"
								type={'text'}
								value={values.email}
								onChange={(e) => {
									setFieldValue('email', e.target.value)
									setErrors({})
								}}
								isInvalid={!!errors.email}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.email}
							</Form.Control.Feedback>
						</Form.Group>
						<div>
							<Button
								type="submit"
								size={'lg'}
								style={{
									marginTop: '32px',
									marginLeft: '16px',
								}}
								disabled={values.email === ''}
								variant={
									values.email === ''
										? 'secondary'
										: 'primary'
								}
							>
								Отправить приглашение
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	)
}

const CurrentInvitesBlock = ({ currentInvites, providerInterface }) => {
	const { addToast } = useContext(ToastsContext)

	async function handleDelete(id) {
		await providerInterface.deleteInvite(id)
		addToast({
			text: 'Приглашение удалено',
			type: 'success',
			withAction: false,
		})
	}
	const formatTime = useCallback((timestring) => {
		const date = moment(timestring)
		return date.format('D MMMM YYYY[,] HH:mm')
	}, [])

	if (currentInvites.length === 0) return null
	return (
		<div className={s.currentInvites}>
			<div className={s.row}>
				<div>
					<span
						className={'text-secondary'}
						style={{ fontSize: '12px' }}
					>
						Email
					</span>
				</div>
				<div>
					<span
						className={'text-secondary'}
						style={{ fontSize: '12px' }}
					>
						Дата приглашения
					</span>
				</div>
			</div>
			<Dropdown.Divider className={'mt-2 p-0 mb-0'} />
			{currentInvites.map((invite, index) => (
				<React.Fragment key={invite.id}>
					<div className={s.datarow}>
						<div>
							<a href={`mailto:${invite.email}`}>
								{invite.email}
							</a>
						</div>
						<div>
							<span
								className={'text-secondary'}
								style={{ marginRight: '16px' }}
							>
								{formatTime(invite.created_at)}
							</span>
							<OverlayTrigger
								placement={'left'}
								overlay={
									<Tooltip id={`tooltip-top`}>
										Удалить приглашение
									</Tooltip>
								}
							>
								<div
									className={s.deleteIcon}
									onClick={() => handleDelete(invite.id)}
								>
									<i className={'bi bi-trash'} />
								</div>
							</OverlayTrigger>
						</div>
					</div>
					{index !== currentInvites.length - 1 && (
						<Dropdown.Divider className={'m-0 p-0'} />
					)}
				</React.Fragment>
			))}
		</div>
	)
}

const ProviderUsers = ({ currentMembers }) => {
	const tmpNoUsers = currentMembers.length === 0

	if (tmpNoUsers)
		return (
			<div className={s.providerUsers}>
				<div className={s.noProviderUsers}>
					<h2>🙂</h2> <h5>У вас пока нет пользователей</h5>
				</div>
			</div>
		)

	return (
		<div className={s.providerUsers}>
			<div className={s.rowTitle}>
				<div>
					<span
						className={'text-secondary'}
						style={{ fontSize: '12px' }}
					>
						ФИО
					</span>
				</div>
				<div>
					<span
						className={'text-secondary'}
						style={{ fontSize: '12px' }}
					>
						Телефон
					</span>
				</div>
				<div>
					<span
						className={'text-secondary'}
						style={{ fontSize: '12px' }}
					>
						Email
					</span>
				</div>
			</div>
			<Dropdown.Divider className={'m-0 p-0'} />
			{currentMembers.map((member, index) => (
				<React.Fragment key={member.id}>
					<div className={s.row}>
						<div>{`${member.last_name || ''} ${
							member.first_name || ''
						} ${member.middle_name || ''}`}</div>
						<div>{PhoneFormatter(member.phone_number)}</div>
						<div>
							{member.email ? (
								<a href={`mailto:${member.email}`}>
									{member.email}
								</a>
							) : (
								'Нет'
							)}
						</div>
					</div>
					{index !== currentMembers.length - 1 && (
						<Dropdown.Divider className={'m-0 p-0'} />
					)}
				</React.Fragment>
			))}
		</div>
	)
}

export default withPage({ noSidebar: false, isProvider: true })(
	ProviderSettingsPage
)
