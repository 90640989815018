import React from 'react'
import { ReactComponent as RecommendIcon } from 'src/assets/images/recommendIcon.svg'
import { ReactComponent as Right } from 'src/assets/images/go-right.svg'

import s from './recLink.module.scss'

type Props = {
	onClick: () => void
}
export const RecommendationsLink = ({ onClick }: Props) => {
	return (
		<div className={s.recommmendationsLink} onClick={onClick}>
			<div>
				<RecommendIcon />
			</div>
			<div className={s.linkText}>
				<h6>Воспользоваться рекомендациями</h6>
				<p>
					Подбираются автоматически, на основании заполненных данных
					на шаге 1
				</p>
			</div>
			<div>
				<Right
					style={{ fill: '#6C757D', width: '1rem', height: '1rem' }}
				/>
			</div>
		</div>
	)
}
