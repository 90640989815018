import React, { useCallback, useMemo, useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import s from './districtBookingEdit.module.scss'
import { ExteranalTypesState } from './DistrictBookingEdit'
import useCampaignData from 'src/stores/campaignData'
import {
	AdvItemState,
	useTechnicalRequirementsStore,
} from 'src/stores/technical-requirements-store'
import { useCampaignInfoStore } from 'src/components/_client/campaign-info/store/campaignInfo.store'
import useResponsive from 'src/utils/useResponsive'
type DistrictBookingEditFooterProps = {
	dictrictBookingId: number
	isLoading: boolean
	externalTypesState: ExteranalTypesState
	onClose: () => void
}
export const DistrictBookingEditFooter = ({
	externalTypesState,
	onClose,
	dictrictBookingId,
}: DistrictBookingEditFooterProps) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { isMobile } = useResponsive()
	const fetchTechnicalRequirements = useTechnicalRequirementsStore(
		(state) => state.fetchTechnicalRequirements
	)
	const fetchVinstantCreative = useCampaignInfoStore(
		(state) => state.fetchVinstantCreative
	)
	const loadingItem = useMemo(
		() =>
			Object.keys(externalTypesState).find(
				(key) => externalTypesState[key] === 'loading'
			),
		[externalTypesState]
	)
	const erroredItem = useMemo(
		() =>
			Object.keys(externalTypesState).find(
				(key) => externalTypesState[key] === 'error'
			),
		[externalTypesState]
	)
	const removerItem = useMemo(
		() =>
			Object.keys(externalTypesState).find(
				(key) => externalTypesState[key] === 'removed'
			),
		[externalTypesState]
	)

	const disabled = !!loadingItem || !!erroredItem || !!removerItem

	const handleSendDistrictBookingToModeration = useCallback(async () => {
		const nextState =
			campaign.state === 'moderation'
				? AdvItemState.Inner_Moderation
				: AdvItemState.Moderation
		try {
			await campaignInterface.updateDistrictBooking({
				districtId: dictrictBookingId,
				state: nextState,
			})

			const campaign = await campaignInterface.refetchSelected()
			if (campaign) {
				await fetchTechnicalRequirements(campaign)
				await fetchVinstantCreative(campaign.id)
			}

			onClose()
		} catch (e) {
			console.log(e)
		}
	}, [])
	return (
		<div className={s.districtBookingFooter}>
			<Button variant="secondary" onClick={onClose}>
				Отменить
			</Button>
			<Button
				variant="primary"
				disabled={disabled}
				onClick={handleSendDistrictBookingToModeration}
			>
				{isMobile ? `На модерацию` : `Отправить на модерацию `}
				{!!loadingItem && (
					<Spinner
						animation="border"
						size="sm"
						style={{ color: '#007BFF' }}
					/>
				)}
			</Button>
		</div>
	)
}
