import React from 'react'
import { Card } from 'react-bootstrap'
import s from './header.module.scss'
import { MediaFormatCounts } from 'src/stores/technical-requirements-store'
import { MediaFormatStatistics } from './MediaFormatStatistics'
import { CollapseCardButton } from '../common/CollapseButton'

type GarantidMediaFormatCardHeaderProps = {
	counts: MediaFormatCounts
	mediaTypeName: string
	open: boolean
	setOpen: (open: boolean) => void
	isOpenButton: boolean
}
const openText = 'Показать рекламные места'
const closeText = 'Скрыть рекламные места'
export const GarantidMediaFormatCardHeader = ({
	counts,
	mediaTypeName,
	open,
	setOpen,
	isOpenButton,
}: GarantidMediaFormatCardHeaderProps) => {
	const tooltipOverlayText = open ? closeText : openText
	return (
		<Card.Header className={s.garantidMediaFormatHeader}>
			<div className={s.garantidMediaFormatHeader_placementsState}>
				<h5>{mediaTypeName}</h5>
				<MediaFormatStatistics counts={counts} />
			</div>

			{isOpenButton && (
				<CollapseCardButton
					onClick={() => setOpen(!open)}
					open={open}
					overlayText={tooltipOverlayText}
				/>
			)}
		</Card.Header>
	)
}
