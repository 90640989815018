import React from 'react'
import s from './common.module.scss'

export const AdjusmentMessage = ({
	adjusmentMessage,
	isDocRequired,
}: {
	adjusmentMessage: string
	isDocRequired: boolean
}) => {
	return (
		<div className={s.adjusmentMessage}>
			{isDocRequired && (
				<h6 style={{ color: '#007BFF', margin: 0 }}>
					Необходимы документы
				</h6>
			)}

			<h6 style={{ color: '#007BFF', margin: 0 }}>
				Исправить: {adjusmentMessage}
			</h6>
		</div>
	)
}
