import React from 'react'
import s from './style.module.scss'
import cn from 'classnames'
import { ProblemInfo } from './ProblemInfo'
import { IssueObjectType } from './CampaignIssueRow'
import { NotifyItem } from '../common/components/IssuesContent'
import {
	ProblemAdvType,
	problemAdvTitles,
} from '../common/constants/problemAdvTitles'

type AdvProblemProps = {
	children?: React.ReactNode
	type: ProblemAdvType
	issues: IssueObjectType[]
	onNotification: (notifyItem: NotifyItem) => void
}

export const AdvProblem = ({
	children,
	type,
	issues,
	onNotification,
}: AdvProblemProps) => {
	return (
		<>
			<tr className={cn(s.tRow)}>
				{children}
				<td rowSpan={issues.length + 1} scope="rowgroup">
					{problemAdvTitles[type]}
				</td>
			</tr>
			{issues.map((el) => (
				<ProblemInfo
					onNotificationClick={() =>
						onNotification({
							id: el.id,
							type: el.type,
							name: el.name,
							placeName: el?.placeName,
						})
					}
					key={el.name}
					issue={el}
				/>
			))}
		</>
	)
}
