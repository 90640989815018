import React, { FC } from 'react'
import useResponsive from '../../../utils/useResponsive'

interface IRenderDesktop {
	children: React.ReactNode | React.ReactNode[]
}

export const RenderDesktop: FC<IRenderDesktop> = ({ children }) => {
	const { isMobile, isTablet } = useResponsive()
	return isMobile || isTablet ? null : <>{children}</>
}
