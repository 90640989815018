import React, { FC } from 'react'
import useResponsive from '../../../utils/useResponsive'

interface IRenderDesktop {
	children: React.ReactNode | React.ReactNode[]
}

export const RenderMobileAndTablet: FC<IRenderDesktop> = ({ children }) => {
	const { isDesktop } = useResponsive()
	return isDesktop ? null : <>{children}</>
}
