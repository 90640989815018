import React from 'react'
import { ModerAdvCompanyItemType } from 'src/components/_moderator/types/ModerationRequestTypes'
import { getCampaignInfoMainFields } from 'src/components/_moderator/utils'
import { CampaignItemInfoField } from './CampaignItemInfoField'

type CampaignMainItemInfoProps = {
	campaign: ModerAdvCompanyItemType
}
export const CampaignMainItemInfo = ({
	campaign,
}: CampaignMainItemInfoProps) => {
	const infoFields = campaign && getCampaignInfoMainFields(campaign)

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '4px',
			}}
		>
			{infoFields &&
				Object.keys(infoFields!).map((key, index, arr) => (
					<CampaignItemInfoField
						key={key}
						title={key}
						text={infoFields[key]}
						mainStyle={{
							borderBottom:
								index === arr.length - 1 ? 'none' : '',
						}}
					/>
				))}
		</div>
	)
}
