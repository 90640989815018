import React, { useCallback, useMemo, useState } from 'react'
import { EditVinstantInputButton } from 'src/components/_client/campaign-info/common/edit-vinstant-input-button/EditVinstantInputButton'
import FileInput from 'src/components/UtilityComponents/FileInput'
import { ObjectValue } from 'src/components/UtilityComponents/ProgressFileInput'
import {
	MediaRequirements,
	useTechnicalRequirementsStore,
} from 'src/stores/technical-requirements-store'
import s from '../commonMedia.module.scss'
import { Button, Spinner } from 'react-bootstrap'
import { EditVinstantProcessingWrapped } from 'src/components/_client/campaign-info/common/edit-vinstant-processing/EditVinstantProcessing'
import { MassDocsUpload } from './MassDocsUpload'
import useCampaignData from 'src/stores/campaignData'
import { CampaignStates } from 'src/stores/ADMarketTypes.types'
import { getError } from 'src/utils/getError'
import { getFileDescription, getMaxFileSize } from 'src/utils'

type Props = {
	bookingItemsIds: number[]
	file: string
	fileName: string
	screentResolution: string
	onVinstantEditOpen: () => void
	mediaRequierments: MediaRequirements
	isVinstantLoading: boolean
	isDocsRequired: boolean
	bookingVinstantLink: string | null
	onClose: () => void
}

type LocalMedia = ObjectValue & {
	file: File | null
}
export const CommonMediaEdit = ({
	bookingItemsIds,
	file,
	fileName,
	onVinstantEditOpen,
	mediaRequierments,
	isVinstantLoading,
	isDocsRequired,
	bookingVinstantLink,
	onClose,
}: Props) => {
	const fetchTechnicalRequirements = useTechnicalRequirementsStore(
		(state) => state.fetchTechnicalRequirements
	)

	//медиа file заполняется только после добавления через input. file нужен для отправки нового креатива в букинг[0]=> во все букинги данного формата.
	const [media, setMedia] = useState<LocalMedia | null>({
		name: fileName,
		src: file,
		//файл появляется только после handleAddMedia, и служит условием для необходимости отправки нового креатива и показа доп кнопок в инпуте
		file: null,
	})
	const [mediaError, setMediaError] = useState('')

	const [campaign, campaignInterface] = useCampaignData()

	const [isDocsChanged, setIsDocsChanged] = useState(false)
	//если креатив был создан с помощью vinstant то считает его уже измененым. (проблема с isMediaChanged после изменения винстант по ссылке)
	const [isMediaChanged, setIsMediaChanged] = useState(!!bookingVinstantLink)
	const [isLoading, setIsLoading] = useState(false)

	const maxSize = useMemo(() => {
		return getMaxFileSize(mediaRequierments)
	}, [mediaRequierments])

	const description = useMemo(() => {
		return getFileDescription(mediaRequierments)
	}, [mediaRequierments])
	const allowedExtensions = useMemo(() => {
		return [
			...mediaRequierments.image_formats,
			...mediaRequierments.video_formats,
		]
	}, [mediaRequierments])

	const moderationDisabled =
		// если не завершилась обработка винстант
		isVinstantLoading ||
		// если нужны документы, но документы не изменялись
		// (isDocsRequired && !isDocsChanged) ||
		// если документы не нужны, но медиа не изменялось
		(!isDocsRequired && !isMediaChanged) ||
		// если не добавлено медиа
		!media ||
		// если происходит какая то подгрузка
		isLoading ||
		!!mediaError

	const handleAddMedia = useCallback(async (file: File) => {
		const newMedia: LocalMedia = {
			name: file.name,
			src: URL.createObjectURL(file),
			file: file,
		}

		setMedia(newMedia)
		setIsMediaChanged(true)
	}, [])
	const handleDeleteMedia = useCallback(() => {
		setMediaError('')
		setMedia(null)
	}, [])

	const handleSendToModeration = useCallback(async () => {
		if (moderationDisabled) {
			return
		}

		const nextBookingState =
			campaign.state === CampaignStates.Moderation
				? 'inner_moderation'
				: 'moderation'
		setIsLoading(true)

		/**
		 * file должен быть отправлен в букинг, а затем склонироваться во все другие букинги этого медиаформата.
		 * отправка не производится если есть vinstantLink, если была нужна дозагрузка документов
		 */
		if (media.file) {
			try {
				await campaignInterface.uploadSingleMedia({
					advItemId: bookingItemsIds[0],
					file: media.file,
				})
			} catch (e) {
				const error = getError(e)

				if (error?.data?.file) {
					if (Array.isArray(error.data.file)) {
						setMediaError(error.data.file.join(', '))
					}
				}
				setIsLoading(false)
				return
			}
		}

		const bookingsToModeration = bookingItemsIds.map((bookingId) =>
			campaignInterface.patchBooking(
				bookingId.toString(),
				{
					state: nextBookingState,
				},
				false
			)
		)

		await Promise.all(bookingsToModeration)

		const campaignResult = await campaignInterface.refetchSelected()
		if (campaignResult) {
			await fetchTechnicalRequirements(campaignResult)
		}

		onClose()
		setIsLoading(false)
	}, [bookingItemsIds, media])

	return (
		<div className={s.commonMediaEditContainer}>
			<div className={s.commonMediaEdit}>
				<div className={s.commonMediaEditUpload}>
					<p>Загрузка креатива</p>
					{isVinstantLoading ? (
						<EditVinstantProcessingWrapped />
					) : (
						<FileInput
							ExtraButton={
								!media?.file && bookingVinstantLink ? (
									<EditVinstantInputButton
										onClick={onVinstantEditOpen}
									/>
								) : null
							}
							error={mediaError}
							onUpload={handleAddMedia}
							value={media}
							onRemove={handleDeleteMedia}
							size={maxSize}
							allowedExtensions={allowedExtensions}
							descr={description}
						/>
					)}
				</div>
				{isDocsRequired && (
					<div className={s.massDocsUploadContainer}>
						<p>Загрузка документов</p>
						<MassDocsUpload
							setIsLoading={setIsLoading}
							bookingItemsIds={bookingItemsIds}
							onDocsChanged={setIsDocsChanged}
						/>
					</div>
				)}
			</div>
			<div className={s.commonMediaEditFooter}>
				<Button variant="secondary" onClick={onClose}>
					Отменить
				</Button>
				<Button
					disabled={moderationDisabled}
					onClick={handleSendToModeration}
				>
					Отправить на модерацию{' '}
					{isLoading && (
						<Spinner
							animation="border"
							size="sm"
							style={{ color: '#007BFF' }}
						/>
					)}
				</Button>
			</div>
		</div>
	)
}
