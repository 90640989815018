import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import FieldsForm from './FieldsForm'
import useUserData, { PhoneConfirmationVariant } from '../../stores/userData'
import useAuth from './useAuth'
import PhoneConfirmation from './PhoneConfirmation'
import validatePhoneAndPassword from './validatePhoneAndPassword'
import { Button } from 'react-bootstrap'
import yandexTracking from '../../utils/yandexTracking'
import s from './authstyles/signInForm.module.scss'
import { AuthActionsWrapper } from './RecoveryForm'

export default function LoginForm({ cn }) {
	const [user, userInterface] = useUserData()

	const { errors, handleSubmit } = useAuth(async (data) => {
		const { phone_number, password, remember } = data

		if (user.phone_number && !user.is_phone_confirmed) {
			await userInterface.sendPhoneActivationCode('phone')
			setStep(1)
			return
		}
		await userInterface.login({ phone_number, password }, remember)
		yandexTracking.setUser(data)
	})
	const [step, setStep] = useState(0)

	async function handlePhoneConfirmation(data) {
		await userInterface.acceptPhoneActivationCode(data)
		await yandexTracking.reachGoal('accept_phone')
	}

	async function handleCodeResend(variant: PhoneConfirmationVariant) {
		await userInterface.sendPhoneActivationCode(variant)
	}

	useEffect(() => {
		if (user.phone_number && !user.is_phone_confirmed && step !== 1) {
			handleCodeResend('phone')
			setStep(1)
		}
	}, [user, step]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<h1 className={s.signInHeader}>Вход</h1>

			{step === 0 && (
				<FieldsForm
					clName={s.authForm}
					extraActionsClassName={s.authExtraActionsContainer}
					onValidate={validatePhoneAndPassword}
					errors={errors}
					fields={[
						{
							name: 'phone_number',
							autoComplete: 'tel',
							label: 'Телефон',
							placeholder: '+7 (900) 000-00-00',
						},
						{
							name: 'password',
							autoComplete: 'current-password',
							label: 'Пароль',
							toggleVisibility: true,
							placeholder: '••••••••••',
						},
						{
							name: 'remember',
							autoComplete: 'off',
							label: 'Запомнить меня',
						},
					]}
					extraActions={
						<AuthActionsWrapper>
							<LoginAuthActions cn={cn} />
						</AuthActionsWrapper>
					}
					submitLabel={'Войти'}
					onSubmit={handleSubmit}
					cn={cn}
				/>
			)}
			{step === 1 && (
				<PhoneConfirmation
					onCodeResend={handleCodeResend}
					onSubmit={handlePhoneConfirmation}
					onBack={async () => {
						await userInterface.logout()
						setStep(0)
					}}
					phone={user.phone_number}
					cn={cn}
				/>
			)}
		</>
	)
}

export const LoginAuthActions = ({
	cn,
}: {
	cn: (className: string) => string | undefined
}) => {
	const history = useHistory()

	return (
		<>
			<Button
				variant={'link'}
				className={cn('button-link')}
				onClick={() => history.push('/register')}
			>
				Создать аккаунт
			</Button>
			<Button
				variant={'link'}
				className={cn('button-link')}
				onClick={() => history.push('/recovery')}
			>
				Сбросить пароль
			</Button>
		</>
	)
}
