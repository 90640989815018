import React from 'react'
import { Placeholder } from 'react-bootstrap'

export const PreviewPlaceholder = (props: any) => {
	return (
		<Placeholder animation="glow" className="d-flex gap-2">
			<Placeholder
				style={{ borderRadius: '12px', width: '80px', height: '80px' }}
			/>
			<div className="d-flex flex-column gap-2 align-self-center">
				<Placeholder style={{ width: '100px', height: '16px' }} />
				<Placeholder style={{ width: '120px', height: '12px' }} />
			</div>
		</Placeholder>
	)
}
