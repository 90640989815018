import React, { FC } from 'react'
import { ReusableModalProps } from '../../UtilityComponents/ReusableModals/ReusableModalsProvider'
import useResponsive from '../../../utils/useResponsive'
import MobileModal from '../../UtilityComponents/MobileModal'
import { Button, Modal } from 'react-bootstrap'
import useQuery from '../../../utils/useQuery'
import { companyInfo, exportBookings } from '../../../constants/api'
import useCampaignData from '../../../stores/campaignData'
import { CampaignStates, ForeCast } from '../../../stores/ADMarketTypes.types'
import DesktopLayout from './DesktopLayout'
import downloadBlob from '../../../utils/downloadBlob'
import { authFetch } from '../../../utils/authFetch'
import moment from 'moment/moment'
import ShowMore from '../../UtilityComponents/ShowMore/ShowMore'
import CampaignSummaryInfo from './CampaignSummaryInfo'
import ForeCastTable from './ForeCastTable'

const MediaPlanModal: FC<ReusableModalProps<any>> = ({
	show,
	onHide,
	onDone,
}) => {
	const { isMobile } = useResponsive()
	const [{ id }] = useCampaignData()

	const [campaign] = useCampaignData()
	const statsUrl =
		campaign?.id && campaign.state === CampaignStates.Draft
			? 'forecast'
			: 'stats'

	const [mediaplan, loading, error] = useQuery<ForeCast>({
		url: companyInfo.params(id) + `${statsUrl}/`,
	})

	const download = async () => {
		const answ: Response = await authFetch({
			url: companyInfo.params(id) + `${statsUrl}/export/`,
			method: 'POST',
			raw: true,
		})
		if (answ.ok) {
			const blob = await answ.blob()
			downloadBlob(blob, `Медиаплан ${mediaplan?.name}`, 'xlsx')
		} else throw new Error('Ошибка при загрузке')
	}

	if (!mediaplan) return null
	if (isMobile)
		return (
			<MobileModal
				onHide={onHide}
				show={show}
				title={'Медиаплан рекламной кампании'}
			>
				<ShowMore
					toggleComponent={<h5 className={'mb-0'}>Общие сведения</h5>}
				>
					<CampaignSummaryInfo mediaplan={mediaplan} />
				</ShowMore>
				<h5 className={'mt-2'}>Данные рекламы</h5>
				<div
					style={{
						maxWidth: '100%',
						width: '100%',
						overflow: 'auto',
					}}
				>
					<ForeCastTable forecast={mediaplan} />
					<div className={'d-flex w-100 justify-content-end mt-3'}>
						<Button variant={'light'} onClick={() => download()}>
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
								className={'me-1'}
							>
								<path
									d="M15 20L3 20L3 4L15 4V8H17V3C17 2.44772 16.5523 2 16 2H2C1.73478 2 1.48043 2.10536 1.29289 2.29289C1.10536 2.48043 1 2.73478 1 3V21C1 21.5523 1.44772 22 2 22L15 22L15 20Z"
									fill="#212529"
								/>
								<path
									d="M20.6439 15.2285C20.8086 15.3897 20.8909 15.6296 20.8909 15.9482C20.8909 16.5319 20.6152 16.8237 20.0638 16.8237C19.6663 16.8237 19.3799 16.7217 19.2044 16.5176C19.0289 16.3135 18.9412 15.9858 18.9412 15.5347H17.3568C17.3568 16.061 17.4731 16.5176 17.7059 16.9043C17.9422 17.291 18.268 17.5882 18.6834 17.7959C19.0988 18.0036 19.5589 18.1074 20.0638 18.1074C20.8086 18.1074 21.3976 17.9159 21.8309 17.5327C22.2641 17.1496 22.4808 16.6178 22.4808 15.9375C22.4808 15.318 22.3071 14.8078 21.9598 14.4067C21.616 14.0057 21.0628 13.6673 20.3001 13.3916C19.8633 13.2054 19.5589 13.0264 19.387 12.8545C19.2151 12.679 19.1292 12.4839 19.1292 12.269C19.1292 11.9933 19.2062 11.7749 19.3602 11.6138C19.5141 11.4491 19.729 11.3667 20.0047 11.3667C20.2983 11.3667 20.5221 11.4634 20.6761 11.6567C20.8336 11.8465 20.9124 12.124 20.9124 12.4893H22.4861C22.4861 12.0202 22.3841 11.6012 22.18 11.2324C21.9795 10.8636 21.6912 10.5789 21.3152 10.3784C20.9393 10.1743 20.5114 10.0723 20.0316 10.0723C19.3118 10.0723 18.7174 10.2728 18.2484 10.6738C17.7829 11.0713 17.5501 11.5995 17.5501 12.2583C17.5501 12.8026 17.722 13.2752 18.0657 13.6763C18.4095 14.0737 18.9734 14.4211 19.7576 14.7183C20.1873 14.8937 20.4827 15.0638 20.6439 15.2285Z"
									fill="#212529"
								/>
								<path
									d="M8.17798 12.8652L9.23071 10.1797H11.0408L9.20386 14.0576L11.0837 18H9.2522L8.17798 15.2661L7.10913 18H5.27759L7.15747 14.0576L5.31519 10.1797H7.12525L8.17798 12.8652Z"
									fill="#212529"
								/>
								<path
									d="M13.6831 16.6895H16.4546V18H12.104V10.1797H13.6831V16.6895Z"
									fill="#212529"
								/>
							</svg>
							Скачать таблицу в Excel
						</Button>
					</div>
				</div>
			</MobileModal>
		)
	return (
		<Modal show={show} onHide={onHide} fullscreen={true} animation={false}>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'}>
					Медиаплан рекламной кампании
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className={'p-0'}>
				<DesktopLayout mediaplan={mediaplan} download={download} />
			</Modal.Body>
		</Modal>
	)
}

export default MediaPlanModal
