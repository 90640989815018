import React, { Dispatch, SetStateAction, useState } from 'react'
import { Alert, Form, InputGroup } from 'react-bootstrap'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import moment from 'moment'
import { ReactComponent as WarningRing } from 'src/assets/images/warningRing.svg'

type AdjusmentContentActionType = 'adjustment' | 'reject'

const getAlertText = (action: AdjusmentContentActionType) => {
	return `${
		action === 'adjustment' ? 'Исправление' : 'Отклонение'
	} рекламной кампании переведет все размещения в соответствующий статус.`
}

export const AdjusmentContent = ({
	adjustmentMessage,
	setAdjustmentMessage,
	action,
}: {
	setAdjustmentMessage: Dispatch<SetStateAction<string>>
	adjustmentMessage: string
	action: AdjusmentContentActionType
}) => {
	const [moder, moderInterface] = useModerationData()
	const minLength = 5
	const [error, setError] = useState<string | undefined>(() => {
		if (adjustmentMessage?.length < 5) {
			return `Минимальная длина сообщения: ${minLength} символов`
		}
		return undefined
	})

	const getTextAreaHeaderText = () => {
		if (action === 'adjustment') {
			return `Напишите, что необходимо исправить заказчику, чтобы пройти модерацию РК ${
				moder.detailedCampaign.company_name
			} от ${moment(moder.detailedCampaign.start_date).format(
				'DD.MM.YY'
			)}`
		}
		if (action === 'reject') {
			return `Напишите причину отклонения РК ${
				moder.detailedCampaign.company_name
			} от ${moment(moder.detailedCampaign.start_date).format(
				'DD.MM.YY'
			)}`
		}
	}
	const alertText = `${
		action === 'adjustment' ? 'Исправление' : 'Отклонение'
	} рекламной кампании переведет все размещения в соответствующий статус.`
	const handleAdjustmentMessageChange = (value: string) => {
		if (value.length < 5) {
			setError(`Минимальная длина сообщения: ${minLength} символов`)
			setAdjustmentMessage(value)
		} else {
			setError(undefined)
			setAdjustmentMessage(value)
		}
	}
	return (
		<div
			style={{
				padding: '24px',
				display: 'flex',
				flexDirection: 'column',
				gap: '24px',
			}}
		>
			<Alert
				variant={'secondary'}
				style={{
					display: 'flex',
					gap: '16px',
					borderRadius: '8px',
					margin: 0,
				}}
			>
				<WarningRing
					style={{
						fill: 'orange',
						width: '48px',
						height: '48px',
					}}
				/>
				<h6
					style={{
						letterSpacing: '-0.03em',
						margin: 0,
					}}
				>
					{alertText}
				</h6>
			</Alert>
			<div>
				<p>{getTextAreaHeaderText()}</p>
				<InputGroup style={{ margin: 0 }} hasValidation>
					<Form.Control
						placeholder="Ваш комментарий"
						size={'lg'}
						value={adjustmentMessage}
						onChange={(e) => {
							handleAdjustmentMessageChange(e.target.value)
						}}
						name={`text`}
						as={'textarea'}
						isInvalid={!!error}
					/>
					<Form.Control.Feedback type="invalid">
						{error}
					</Form.Control.Feedback>
				</InputGroup>
			</div>
		</div>
	)
}
