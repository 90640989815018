import React from 'react'

type NoContentProps = {}
export const NoBookingsInCurrentTab = ({}: NoContentProps) => (
	<div
		className={
			'd-flex flex-column justify-content-center w-100 h-100 align-items-center'
		}
		style={{ gap: '16px', minHeight: 'calc(100vh - 300px)' }}
	>
		<div style={{ fontSize: '40px' }}>🙂</div>
		<div>
			<h4 className={'text-secondary'}>Нет ни одной брони</h4>
		</div>
	</div>
)
