import React from 'react'
import { CustomRange, Search } from 'src/components/UtilityComponents'
import { RadiusRange } from './RadiusRange'
import { formatSliderValue } from '../../../../utils'

type LeftPanelHeaderProps = {
	radius: number
	onSearchStringChange: (searchString: string) => void
	onRadiusChange: (value: number) => void
	isRadiusBlock: boolean
}
export const LeftPanelHeader = ({
	onRadiusChange,
	radius,
	onSearchStringChange,
	isRadiusBlock,
}: LeftPanelHeaderProps) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				padding: '24px',
				gap: '24px',
				width: '100%',
				boxShadow: '0px 5px 10px rgba(33, 37, 41, 0.1)',
			}}
		>
			<Search
				setSearchString={onSearchStringChange}
				minWidth="50%"
				placeholder="Поиск по адресу"
			/>
			{isRadiusBlock && (
				<RadiusRange
					onRadiusChange={onRadiusChange}
					radius={radius}
					value={formatSliderValue(radius)}
				/>
			)}
		</div>
	)
}
