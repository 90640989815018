import React from 'react'
import { MessageProperties } from 'src/components/_client/campaign-info/constants/messages-data'
import s from './garantidItem.module.scss'
import { Button } from 'react-bootstrap'
type StateWithMessageProps = {
	stateData: MessageProperties
	styles?: React.CSSProperties
	onEdit?: () => void
}
export const StateWithMessage = ({
	stateData,
	styles,
	onEdit,
}: StateWithMessageProps) => {
	return (
		<div className={s.stateWithMessage} style={styles}>
			<div
				style={{ color: stateData?.color }}
				className={s.stateWithMessageStatus}
			>
				<i className={stateData?.icon} />
				<p>{stateData?.title}</p>
			</div>

			<div className={s.progressLineContainer}>
				<div
					style={{
						width: `${stateData?.progress}%`,
						backgroundColor: stateData?.color,
					}}
					className={s.progressLine}
				></div>
			</div>
			<p style={{ color: stateData?.color }}>{stateData?.message}</p>

			{onEdit && (
				<Button onClick={onEdit} style={{ maxWidth: 'min-content' }}>
					Исправить
				</Button>
			)}
		</div>
	)
}
