import React, { useMemo } from 'react'
import f from '../../../../../../_client/create-campaign/Step2-ADformats/ADFormatSellector.module.scss'

import { CreateReportAdFormat } from './CreateReportAdFormat'
import { AdFormatNameType } from '../CreateReportStepThree'

type CreateReportThreeAdFormatsProps = {
	handleAdFormatChange: (name: AdFormatNameType, isSelected: boolean) => void
	disabledAdFormats: Record<AdFormatNameType, boolean>
}

export const CreateReportAdFormatsList = ({
	handleAdFormatChange,
	disabledAdFormats,
}: CreateReportThreeAdFormatsProps) => {
	const staticFormatsData = useMemo(
		(): Array<{
			title: string
			name: AdFormatNameType
			disabled: boolean
		}> => [
			{
				title: 'Наружная реклама',
				name: 'outdoor',
				disabled: disabledAdFormats.outdoor,
			},
			{
				title: 'Внутренняя реклама',
				name: 'indoor',
				disabled: disabledAdFormats.indoor,
			},
			{
				title: 'Интернет-реклама',
				name: 'internet',
				disabled: disabledAdFormats.internet,
			},
			{
				title: 'ТВ-реклама',
				name: 'tv',
				disabled: disabledAdFormats.tv,
			},
			{
				title: 'Общественный транспорт',
				name: 'publicTransport',
				disabled: disabledAdFormats.tv,
			},
		],
		[disabledAdFormats]
	)

	return (
		<div
			style={{
				position: 'relative',
				width: '100%',
			}}
		>
			<div className={f.container}>
				{staticFormatsData.map((format) => (
					<CreateReportAdFormat
						title={format.title}
						disabled={format.disabled}
						name={format.name}
						key={format.name}
						onSelectedChange={(isSelected) =>
							handleAdFormatChange(format.name, isSelected)
						}
					/>
				))}
			</div>
		</div>
	)
}
