import { AnimatePresence } from 'framer-motion'
import React from 'react'
import useResponsive from 'src/utils/useResponsive'
import useProviderData from 'src/stores/providerData'
import bem from 'src/utils/bem'
const cn = bem('reklamnie-nositeli-page')

import { NoContent } from '../placements-content'
import { RenderListItem } from './RenderListItem'

export type PlacementsListProps = {
	selectedItem: any
	setSelectedItem: any
	setBottomModal: any
	onDisable: any
	onEnable: any
	onDelete: any
	onArchive: any
	onFromArchive: any
	onCreate: (itemId?: string) => void
}

export const PlacementsList = ({
	onArchive,
	onDelete,
	onDisable,
	onEnable,
	onFromArchive,
	onCreate,
	selectedItem,
	setBottomModal,
	setSelectedItem,
}: PlacementsListProps) => {
	const [provider] = useProviderData()

	const { isTablet, isDesktop } = useResponsive()

	return (
		<div
			style={{
				flexGrow: 1,
				overflow: 'auto',
				borderBottom: '1px solid #CED4DA',
				borderTop: '1px solid #CED4DA',
			}}
		>
			{provider?.placements?.results?.length === 0 ? (
				<NoContent onCreate={onCreate} />
			) : (
				<AnimatePresence initial={false}>
					{provider?.placements?.results?.map((el) => {
						return (
							<RenderListItem
								image={el.placement_type.image}
								title={el.name}
								size={el.size}
								media_name={el.media_type.name}
								status={el.state}
								id={el.id}
								key={el.id}
								cn={cn}
								selectedItem={selectedItem}
								setSelectedItem={setSelectedItem}
								setBottomModal={setBottomModal}
								isTablet={isTablet}
								is_active={el.is_active}
								activation_date={el.activation_date}
								onDisable={onDisable}
								onEnable={onEnable}
								onDelete={onDelete}
								onArchive={onArchive}
								onEdit={onCreate}
								onFromArchive={onFromArchive}
								in_archive={el.in_archive}
							/>
						)
					})}
				</AnimatePresence>
			)}
		</div>
	)
}
