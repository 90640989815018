import React from 'react'
import { PlacementType } from 'src/stores/ADMarketTypes.types'

type Props = {
	details: PlacementType
	selectedPrice: number | undefined
	setSelectedPrice: (showingCountsTypeId: number) => void
}
export const ShowingCountTypeSelector = ({
	details,
	selectedPrice,
	setSelectedPrice,
}: Props) => {
	return (
		<div
			className={'mt-3'}
			style={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{details.placement_showing_counts.map(({ id, name }) => (
				<label
					className={
						'mb-2 ' + (details.is_active ? '' : 'text-secondary')
					}
					style={{ fontSize: '14px' }}
					key={id}
				>
					<input
						className="form-check-input me-3"
						type="radio"
						checked={id === selectedPrice || false}
						onChange={() => setSelectedPrice(id)}
						disabled={!details.is_active}
					/>
					{name}
				</label>
			))}
		</div>
	)
}
