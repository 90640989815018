import React, { useEffect, useMemo, useState } from 'react'
import { ReactComponent as ArrowDownIcon } from '../../../../assets/images/down.svg'
import { ReactComponent as PlusIcon } from '../../../../assets/images/plus-bordered.svg'
import { ReactComponent as MinusIcon } from '../../../../assets/images/minus-bordered.svg'
import { ReactComponent as MoreIcon } from '../../../../assets/images/more-vert.svg'
import { ReactComponent as XLSBlueIcon } from '../../../../assets/images/xls-blue.svg'
import { ReactComponent as DeleteIcon } from '../../../../assets/images/delete.svg'
import { ReactComponent as PDF } from '../../../../assets/images/pdf.svg'

import { CampaignBadge, getStartEndString, isCampaignCanBeDeleted } from '.'
import IntlFormatter from 'src/utils/intl-money-formatter'
import Portal from './Portal'
import { authFetch } from 'src/utils/authFetch'
import downloadBlob from 'src/utils/downloadBlob'
import modalS from './ClientStatistics.module.scss'
import { Table, Cell } from './Table/Table'
import { useRef } from 'react'
import s from './ClientStatistics.module.scss'
import { ExpandedStatistics } from './ExpandedStatistics'
import cn from 'classnames'
import { CampaignStates } from 'src/stores/ADMarketTypes.types'
import { CampaignsListSortField } from 'src/stores/campaignData'
import { CampaignReportContent } from '../../campaign-info/common/campaign-report/CampaignReportCard'
import { ExpandedRow } from './ExpandedRow'

export function StatisticsTable({
	setCampaignIdForDocumnents,
	campaignIdForDocumnents,
	initData,
	handleCampaign,
	expandedRows,
	expandedData,
	modalState,
	openModal,
	campaignStat,
	toggleModal,
	exportToExcel,
	handleCampaignClick,
	handleCampaignDelete,
	handleCampaignCancel,
	sortDirection,
	setSortDirection,
	setSortField,
	sortField,
}: Record<any, any>) {
	const [data, setData] = useState<any[]>([])

	const handleCampaignDeleteWithFetch = async (campaignId: string) => {
		await handleCampaignDelete(campaignId)
		if (data) {
			const newData = data.filter((el) => el.id !== campaignId)
			setData(newData)
		}
	}

	const cols = [
		{
			sortKey: 'non-sortable',
			key: 'name',
			title: 'Название',
			width: 28,
			cell: (row) => (
				<div
					onClick={() => handleCampaignClick(row.id, row.state)}
					className={s.pointer}
				>
					{row.name}
				</div>
			),
			cellLeft: (row) =>
				expandedRows.includes(row.id) ? (
					<MinusIcon
						className={s.iconButton}
						onClick={() => handleCampaign(row.id)}
					/>
				) : (
					<PlusIcon
						className={s.iconButton}
						onClick={() => handleCampaign(row.id)}
					/>
				),
			primaryHover: true,
		},
		{
			sortKey: CampaignsListSortField.Created_At,
			key: 'period',
			title: 'Период',
			cell: (row) => getStartEndString(row.start_date, row.end_date),
			width: 16,
		},
		{
			sortKey: CampaignsListSortField.Region,
			key: 'region_titles',
			title: 'Регион показа',
			cell: (row) => row.extra_data.region_titles[0],
			cellRight: (row) => {
				const { length } = row.extra_data.region_titles
				return (
					length > 1 && (
						<div className={s.regionCount}>+{length - 1}</div>
					)
				)
			},
			width: 18,
			extraStyle: {
				flex: '1 1',
			},
		},
		{
			sortKey: CampaignsListSortField.Gender,
			key: 'gender',
			title: 'Пол',
			cell: (row) => row.extra_data.gender_titles,
			width: 10,
			extraStyle: {
				minWidth: '32px',
			},
			ellipsis: true,
		},
		{
			sortKey: CampaignsListSortField.Age,
			key: 'age',
			title: 'Возраст',
			cell: (row) => row.extra_data.age_titles.join(', '),
			width: 10,
		},
		{
			sortKey: 'non-sortable',
			key: 'budget',
			title: 'Бюджет',
			cell: (row) => IntlFormatter.format(row.extra_data.total_budget),
			width: 12,
			justify: 'right',
			ellipsis: true,
		},
		{
			sortKey: 'non-sortable',
			key: 'state',
			title: 'Статус',
			cell: (row) => <CampaignBadge state={row.state} />,
			justify: 'right',
			width: 12,
			ellipsis: true,
		},
		{
			sortKey: 'non-sortable',
			key: 'actions',
			justify: 'center',
			cell: (row) => {
				return (
					<>
						<MoreIcon
							className={cn(s.iconMore, s.scalable)}
							onClick={() => openModal(row.id)}
						/>
						<CampaignActionsDropdown
							onDocumentsClick={() =>
								setCampaignIdForDocumnents(row.id)
							}
							campaign={row}
							isOpened={modalState[row.id]}
							onClose={() => toggleModal(row.id)}
							exportToExcel={exportToExcel}
							handleCampaignDelete={handleCampaignDeleteWithFetch}
							handleCampaignCancel={handleCampaignCancel}
							campaignStat={campaignStat}
						/>
					</>
				)
			},
			noPadding: true,
			width: 3.2,
			staticWidth: '32px',
			extraStyle: {
				flex: '0 0 32px',
				maxWidth: '32px',
			},
		},
	]
	return (
		<>
			<Table
				data={initData}
				cols={cols}
				headerCell={(col, i) => {
					const isRightDirected = col.justify === 'right'

					const sortable = Object.values(
						CampaignsListSortField
					).includes(col.sortKey)

					const sortIcon = sortable && (
						<ArrowDownIcon
							className={cn(s.sort, {
								[s.upIcon]:
									sortDirection === 'asc' &&
									sortField === col.sortKey,
								[s.active]: sortField === col.sortKey,
							})}
						/>
					)

					return (
						<Cell
							className={cn(s.headerCell, {
								[s.sortable]: sortable,
							})}
							content={
								<>
									{isRightDirected && sortIcon}
									{col.title}
									{!isRightDirected && sortIcon}
								</>
							}
							onClick={() => {
								if (!sortable) return
								if (sortField === col.sortKey) {
									setSortDirection(
										sortDirection === 'asc' ? 'desc' : 'asc'
									)
								} else {
									setSortField(col.sortKey)
									setSortDirection('desc')
								}
							}}
							noBorderRight={i === cols.length - 1}
							isHeader
							key={col.key}
							extraStyle={col.extraStyle}
						/>
					)
				}}
				wrapRow={(renderedRow, row) => {
					return (
						<React.Fragment key={row.id}>
							{renderedRow}
							{expandedData[row.id] &&
								expandedRows.includes(row.id) &&
								(row.state !== CampaignStates.Completed ? (
									<ExpandedStatistics
										campaignState={row.state}
										expandedData={expandedData[row.id]}
										key={row.id + '-stats'}
									/>
								) : (
									<ExpandedRow
										expandedData={expandedData}
										row={row}
									/>
								))}
						</React.Fragment>
					)
				}}
				ellipsis
				rounded
			/>
		</>
	)
}

function CampaignActionsDropdown({
	onDocumentsClick,
	campaign,
	isOpened,
	onClose,
	handleCampaignCancel,
	exportToExcel,
	campaignStat,
	handleCampaignDelete,
}: Record<any, any>) {
	const divRef = useRef(null)

	const download = async () => {
		const answ: Response = await authFetch({
			url: '/core/api/v1/adv_companies/stats/export/',
			method: 'POST',
			raw: true,
			body: {
				id: [campaign?.id],
				// start_date: mediaplan?.start_date,
				// end_date: mediaplan?.end_date,
				// booking_id: [mediaplan?.booking.map((el)=> el.id)],
				// // releated_key: (mediaplan?.vk, mediaplan.yandex, mediaplan.teletarget, mediaplan.adstream),
				// placement_id:mediaplan?.booking.map((el)=> el.placement.id)
			},
		})
		if (answ.ok) {
			const blob = await answ.blob()
			downloadBlob(blob, `Статистика ${campaign?.name}`, 'xlsx')
		} else {
			throw new Error('Ошибка при загрузке')
		}
	}

	return (
		<Portal
			isOpened={isOpened}
			onLayerMouseDown={(e) => e.preventDefault()}
			onClose={() => onClose && onClose()}
			marginLeft={-330}
			zIndex={15}
		>
			<div className={modalS.modalContent}>
				{[CampaignStates.Completed, CampaignStates.Active].includes(
					campaign.state
				) && (
					<>
						{campaign?.count_ones_docs > 0 &&
							campaign.state === 'completed' && (
								<div
									onClick={onDocumentsClick}
									className={modalS.modalContentOnDay}
								>
									<div
										className={
											modalS.modalContentOnDayContent
										}
									>
										<PDF />
										<h1
											className={
												modalS.modalContentOnDayContentext
											}
										>
											Выгрузить документы
										</h1>
									</div>
								</div>
							)}

						<div
							className={modalS.modalContentOnDay}
							onClick={() => download()}
						>
							<div className={modalS.modalContentOnDayContent}>
								<XLSBlueIcon />
								<h1
									className={
										modalS.modalContentOnDayContentext
									}
								>
									Выгрузить в Excel по дням
								</h1>
							</div>
						</div>

						<div className={modalS.modalContentOnDay}>
							<div className={modalS.modalContentOnDayContent}>
								<XLSBlueIcon />
								<h1
									onClick={() => exportToExcel(campaign)}
									className={
										modalS.modalContentOnDayContentext
									}
								>
									Выгрузить в Excel за весь период
								</h1>
							</div>
						</div>
					</>
				)}

				{campaign?.state !== 'active' && (
					<div
						className={modalS.modalContentOnDelete}
						onClick={(e) => {
							e.stopPropagation()
							if (isCampaignCanBeDeleted(campaign)) {
								handleCampaignDelete(campaign.id)
							} else {
								handleCampaignCancel(campaign.id)
							}
						}}
					>
						<div className={modalS.modalContentOnDeleteContent}>
							<DeleteIcon />
							<h1
								className={
									modalS.modalContentOnDeleteContentext
								}
							>
								Отменить
							</h1>
						</div>
					</div>
				)}
			</div>
		</Portal>
	)
}
