import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import Select from 'react-select'
import { MultiValue } from 'react-select'
import { moderationApi } from 'src/components/_moderator/api/moderatorApi'
import s from '../common.module.scss'
import cn from 'classnames'
import { CommonSenderProps } from './SenderTypeButtonsAdditional'
import useResponsive from 'src/utils/useResponsive'

type OptionType = {
	value: string
	label: string
}
type Props = {} & CommonSenderProps
export const ClientSender = ({ onChange, detailedNotify }: Props) => {
	const { isMobile } = useResponsive()
	const selectedCompanies = detailedNotify.extra_data.company_names

	const [clientsType, setClientsType] = useState<'all' | 'selected-clients'>(
		'selected-clients'
	)

	const [allOptions, setAllOptions] = useState<MultiValue<OptionType>>([])
	const [selectedOptions, setSelectedOptions] = useState<
		MultiValue<OptionType>
	>(() => {
		return Object.entries(selectedCompanies).map((el) => {
			return { label: el[1], value: el[0] }
		})
	})

	const loadOptions = async () => {
		const result = await moderationApi.fetchCompanies()

		const options: MultiValue<OptionType> = result.map((el) => {
			return { label: el?.legal_short_name, value: el.id }
		})

		if (result.length === Object.keys(selectedCompanies).length) {
			setClientsType('all')
		} else {
			setClientsType('selected-clients')
		}

		setAllOptions(options)
	}

	// Обработчик изменения выбранных значений
	const handleSelectedChange = (newValue: MultiValue<OptionType>) => {
		setSelectedOptions(newValue)
	}

	useEffect(() => {
		loadOptions()
	}, [])
	useEffect(() => {
		if (clientsType === 'all') {
			onChange(allOptions.map((el) => el.value))
		}
		if (clientsType === 'selected-clients') {
			onChange(selectedOptions.map((el) => el.value))
		}
	}, [selectedOptions, clientsType, allOptions])
	return (
		<div className={cn(s.clientSender)}>
			<div className={cn(s.clientSenderTypeSelector)}>
				<Form.Check
					onChange={() => setClientsType('all')}
					checked={clientsType === 'all'}
					type={'radio'}
					label={`Всем клиентам`}
					id={`all-clients`}
				/>
				<Form.Check
					onChange={() => setClientsType('selected-clients')}
					checked={clientsType === 'selected-clients'}
					type={'radio'}
					label={`Выборочно`}
					id={`selected-clients`}
				/>
			</div>

			{clientsType === 'selected-clients' && (
				<Select
					blurInputOnSelect={false}
					menuPlacement={isMobile ? 'top' : 'auto'}
					closeMenuOnSelect={false}
					placeholder={'Выберите клиентов'}
					isMulti
					options={allOptions}
					value={selectedOptions} // Передача выбранных значений
					onChange={handleSelectedChange} // Обработчик изменения выбранных значений
				/>
			)}
		</div>
	)
}
