import { DistrictBookingExternalType } from 'src/stores/ADMarketTypes.types'

export const districtBookingExternalTitles: {
	[key in DistrictBookingExternalType]: string
} = {
	bb: '3*6м',
	rb: 'Ситиборд',
	ss: 'Суперсайт',
	cf: 'Суперсайт',
	rc: 'Суперсайт',
	rd: 'Суперсайт',
	wd: 'Суперсайт',
}
