import React from 'react'
import { Modal } from 'react-bootstrap'
import MobileModal from 'src/components/UtilityComponents/MobileModal'
import ProviderYandexMap from 'src/components/UtilityComponents/ProviderYandexMap'
import useResponsive from 'src/utils/useResponsive'

const modalTitle = 'Бронь на карте'

type Props = {
	open: boolean
	onClose: () => void
	bookingCoords: [number, number]
}
export const BookingOnMapModal = ({ onClose, open, bookingCoords }: Props) => {
	const { isMobile } = useResponsive()
	return isMobile ? (
		<MobileModal
			onHide={onClose}
			show={open}
			title={modalTitle}
			withCloseButton={true}
			enableTouching={false}
			defaultTouchingSetup={false}
			contentStyles={{ padding: 0 }}
		>
			<ProviderYandexMap
				width={'100%'}
				height={'100%'}
				zoom={15}
				singleCoords={bookingCoords}
				bookingCoords={bookingCoords}
			/>
		</MobileModal>
	) : (
		<Modal fullscreen={true} show={open} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>{modalTitle}</Modal.Title>
			</Modal.Header>

			<Modal.Body className="p-0">
				<ProviderYandexMap
					width={'100%'}
					height={'100%'}
					zoom={15}
					singleCoords={bookingCoords}
					bookingCoords={bookingCoords}
				/>
			</Modal.Body>
		</Modal>
	)
}
