import React from 'react'
import { Login } from '../common/ui/login/Login'
import { useParams } from 'react-router-dom'
import { loginValidationSchema } from '../common/model/schemas'
import { FormikHelpers } from 'formik'
import { LoginFormData } from '../common/model/types'
import useUserData from 'src/stores/userData'
import yandexTracking from 'src/utils/yandexTracking'

export const AgencyLogin = () => {
	const { agencyAlias } = useParams<{ agencyAlias: string }>()

	const [user, userInterface] = useUserData()

	const headerTitle = 'Вход от агентства'

	const handleResetPassword = () => {
		window.location.href = `/agency-auth/#/recovery/${agencyAlias}`
	}

	const handleRegistration = () => {
		window.location.href = `/auth-agency/#/registration/`
	}

	const handleSubmit = async (
		values: LoginFormData,
		formikHelpers: FormikHelpers<LoginFormData>
	) => {
		const formattedPhone = `+${values.phone_number.replace(/\D/g, '')}`

		try {
			await userInterface.login(
				{
					phone_number: formattedPhone,
					password: values.password,
					agency_alias: agencyAlias,
				},
				values.rememberMe
			)
			yandexTracking.setUser(values)
		} catch (e: any) {
			if (typeof e?.data === 'object') {
				Object.keys(e.data).forEach((key) => {
					formikHelpers.setFieldError(key, e.data[key][0])
				})
			}
		}
	}

	return (
		<Login
			headerTitle={headerTitle}
			toRegister={handleRegistration}
			toResetPassword={handleResetPassword}
			onSubmit={handleSubmit}
			validationSchema={loginValidationSchema}
		/>
	)
}
