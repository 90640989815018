type PlaceRequirementsHeaderProps = {}
import React from 'react'
export const PlaceRequirementsHeader = (props: any) => {
	return (
		<div
			style={{
				position: 'sticky',
				top: 0,
				left: 0,
				padding: '16px',
				borderBottom: '1px solid #CED4DA',
				backgroundColor: '#fff',
			}}
		>
			<h6 className={'mb-0'}>
				Технические требования к загрузке материала
			</h6>
		</div>
	)
}
