const yandexTracking = {
	setUser(user) {
		try {
			// @ts-ignore
			window.ym(85853890, 'setUserID', user.phone_number)
		} catch {}
	},
	reachGoal(goal) {
		return new Promise((resolve, reject) => {
			try {
				// @ts-ignore
				window.ym(85853890, 'reachGoal', goal, {}, resolve)
				console.log('-> yandexTrackingGoalReached', goal)
				resolve(true)
			} catch (e) {
				reject(e)
			}
		})
	},
}

export default yandexTracking
