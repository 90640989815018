export const helpImgList = {
	outdoor: {
		main: 'outdoor_26_11zon',
		create: {
			4: 'in-outdoorCreate4_8_11zon',
			5: 'outdoorCreate5_27_11zon',
			6: 'outdoorCreate6_28_11zon',
			7: 'outdoorCreate7_29_11zon',
			8: 'outdoorCreate8_30_11zon',
			9: 'outdoorCreate9_31_11zon',
			10: 'outdoorCreate10_32_11zon',
			11: 'outdoorCreate11_33_11zon',
		},
	},
	indoor: {
		main: 'indoor_main_1',
		create: {
			4: 'in-outdoorCreate4_8_11zon',
			5: 'indoorCreate5',
			6: 'indoorCreate6_1_11zon',
			7: 'indoorCreate7_3_11zon',
			8: 'indoorCreate8_4_11zon',
			9: 'indoorCreate9_5_11zon',
			10: 'indoorCreate10_6_11zon',
			11: 'indoorCreate11_7_11zon',
		},
	},
	internet: {
		main: 'internet',
		create: {
			4: 'internetCreate4_13_11zon',
			5: 'internetCreate5_14_11zon',
			6: 'internetCreate6_15_11zon',
			7: 'internetCreate7_16_11zon',
		},
	},
	tv: {
		main: 'tv_4_11zon',
		create: {
			4: 'tvCreate4_5_11zon',
			5: 'tvCreate5_6_11zon',
			6: 'tvCreate6_7_11zon',
		},
	},
	mediaPlan: {
		1: 'mediaplan_25_11zon',
	},
	signUp: {
		1: 'signup1_50_11zon',
		2: 'signup2_1_11zon',
		3: 'signup3_2_11zon',
		4: 'signup4_3_11zon',
	},
	legal: {
		create: {
			1: 'legalCreate1_21_11zon',
			2: 'legalCreate2_22_11zon',
			3: 'legalCreate3_23_11zon',
			4: 'legalCreate4_24_11zon',
		},
	},
	person: {
		create: {
			1: 'personCreate1_41_11zon',
			2: 'personCreate2_42_11zon',
			3: 'personCreate3_43_11zon',
			4: 'personCreate4_44_11zon',
		},
	},
	campaignData: {
		1: 'personCampData1_36_11zon',
		2: 'personCampData2_37_11zon',
		3: 'personCampData3_38_11zon',
		4: 'personCampData4_39_11zon',
		5: 'personCampData5_40_11zon',
	},
	adFormats: {
		1: 'personAdFormats1_34_11zon',
		2: 'personAdFormats2_35_11zon',
	},
	dataUpload: {
		legalAndPerson: {
			1: 'personDataUpload1_45_11zon',
			2: 'personDataUpload2_46_11zon',
		},
		common: {
			sendToModerate: 'personDataUpload3_47_11zon',
			confirm: 'personDataUpload4_48_11zon',
			result: 'personDataUpload5_49_11zon',
		},
		inAndOutDoor: {
			1: 'in-outdoorDataUpload1_9_11zon',
			2: 'in-outdoorDataUpload2_10_11zon',
		},
		internet: {
			1: 'internetDataUpload1_17_11zon',
			2: 'internetDataUpload2_18_11zon',
			3: 'internetDataUpload3_19_11zon',
		},
		tv: {
			1: 'tvDataUpload1_8_11zon',
			2: 'tvDataUpload2_9_11zon',
		},
	},
	documents: {
		1: 'docs1',
		2: 'docs2',
		3: 'docs3',
		4: 'docs4',
	},
	stats: {
		1: 'stats1',
		2: 'stats2',
		forming: {
			2: 'statsForming1',
			3: 'statsForming2',
			4: 'statsForming3',
			5: 'statsForming4',
		},
	},
}
const baseHelpImgUrl =
	'https://storage.yandexcloud.net/admarket-images/static-img/'
export const getHelpImg = (url: string) => {
	if (url === 'internet') {
		return baseHelpImgUrl + url + '.png'
	}

	return baseHelpImgUrl + url + '.jpg'
}
