import React, { useEffect, useMemo, useState } from 'react'
import bem from '../../../utils/bem'
import ROUTES from '../../../constants/Routes'
import useResponsive from '../../../utils/useResponsive'
import { useHistory, useLocation } from 'react-router-dom'
import useCampaignData from '../../../stores/campaignData'
import useNotificationData from '../../../stores/notificationsData'
import useQuery from 'src/utils/useQuery'
import { ReactComponent as Rocket } from '../../../assets/images/rocket.svg'
import { ReactComponent as Clock } from '../../../assets/images/clock.svg'
import { ReactComponent as Warning } from '../../../assets/images/warningRing.svg'
import { ReactComponent as Notification } from '../../../assets/images/notification.svg'
import cn from 'classnames'
import s from './clientSidebar.module.scss'
import {
	AppLinks,
	ClientSidebarFooter,
	ToggleSidebarButton,
} from './components'
import useUserData from 'src/stores/userData'

const bottomLinks = ['help']
export type SidebarMenuItemType = {
	key: string
	title: string
	count: number
	path: string
	withCount: boolean
	icon: {
		item: React.JSXElementConstructor<React.SVGAttributes<SVGElement>>
		alt: string
	}
}
const moderatorMenu: Array<SidebarMenuItemType> = [
	{
		key: 'moderator/campaigns_moderation',
		title: 'Рекламные кампании',
		count: 0,
		path: ROUTES.ROUTE_MODERATOR + ROUTES.ROUTE_CAMPAIGN_MODERATION,
		withCount: false,
		icon: {
			item: Rocket,
			alt: 'moderationCampaigns',
		},
	},
	{
		key: 'moderator/display_issues',
		title: 'Проблемы с показами',
		count: 0,
		path: ROUTES.ROUTE_MODERATOR + ROUTES.ROUTE_DISPLAY_ISSUES_MODERATION,
		withCount: false,
		icon: {
			item: Warning,
			alt: 'diplayIssues',
		},
	},
	{
		key: 'moderator/notifications_moderation',
		title: 'Уведомления',
		count: 0,
		path: ROUTES.ROUTE_MODERATOR + ROUTES.ROUTE_NOTIFICATIONS_MODERATION,
		withCount: false,
		icon: {
			item: Notification,
			alt: 'notifications',
		},
	},
]

const client_menu = [
	{
		key: 'campaigns',
		title: 'Кампании',
		count: 0,
		path: '/campaigns',
		withCount: true,
		icon: {
			item: Rocket,
			alt: 'campaigns',
		},
	},
	{
		key: 'operations',
		title: 'История операций',
		count: 0,
		path: '/operations',
		withCount: false,
		icon: {
			item: Clock,
			alt: 'history',
		},
	},
]
//TODO разбить на компоненты, исправить семантику (a contain button)
export const ClientSidebar = React.forwardRef(function ClientSidebar(
	{ forceOpened, setForceOpened }: any,
	ref: any
) {
	const [user, userInterface] = useUserData()
	const isModerState = userInterface.getIsModerState()

	const [active, setActive] = useState('')

	const { isDesktop } = useResponsive()
	const history = useHistory()
	const location = useLocation()

	const [campaign, campaignInterface] = useCampaignData()
	const [notifications, notificationInterface] = useNotificationData()
	const handleLinkClick = (path) => {
		if (location.pathname.includes(path)) return false
		if (user?.is_moder) {
			history.replace(path)
		} else {
			history.push(path)
		}

		setActive(path)
	}

	const client_menu_with_counts = useMemo(() => {
		return client_menu.map((menu) => {
			let count = 0
			if (menu.key === 'campaigns') {
				count = campaignInterface.list()?.count_total
			}
			if (menu.key === 'notifications') {
				count = notificationInterface.getNotificationsCountNotRead()
			}
			return { ...menu, count: count }
		})
	}, [campaign, notifications]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (forceOpened) {
			localStorage.setItem('sidebar', 'open')
		} else {
			localStorage.setItem('sidebar', 'close')
		}
	}, [forceOpened])

	useEffect(() => {
		bottomLinks.forEach((path) => {
			if (location.pathname.includes(path)) return setActive(path)
		})

		if (isModerState) {
			moderatorMenu.forEach((route) => {
				if (location.pathname.includes(route.key))
					return setActive(route.key)
			})
		} else {
			client_menu.forEach((route) => {
				if (location.pathname.includes(route.key))
					return setActive(route.key)
			})
		}
	}, [location, isModerState])

	const onCreateCampaign = async () => {
		campaignInterface.removeSelectedCampaign()
		history.push('/create-campaign/')
	}

	useEffect(() => {
		if (isDesktop) return
		setForceOpened(false)
	}, [location])

	return (
		<div
			id={'client-sidebar'}
			className={cn(s.clientSidebar, {
				[s.clientSidebar_force]: forceOpened,
				[s.clientSidebar_absolute]: !isDesktop,
			})}
			ref={ref}
		>
			<ToggleSidebarButton
				forceOpened={forceOpened}
				toggleOpenSidebar={setForceOpened}
			/>

			<AppLinks
				active={active}
				setActive={setActive}
				client_menu_with_counts={
					isModerState ? moderatorMenu : client_menu_with_counts
				}
				forceOpened={forceOpened}
				isDesktop={isDesktop}
				linkClick={handleLinkClick}
				setForceOpened={setForceOpened}
				onCreateCampaign={onCreateCampaign}
			/>

			<ClientSidebarFooter />
		</div>
	)
})
