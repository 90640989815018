import React, { FC, useEffect, useMemo, useState } from 'react'
import { withPage } from '../../page/Page'
import useHeaderTitle from '../../page/useHeaderTitle'
import { HelpContent, HelpList, HelpNavigator } from './components'
import {
	ActiveItemType,
	HandleActiveItemChangeType,
	HelpContentKey,
} from './constants'
import useResponsive from 'src/utils/useResponsive'
import { ReactComponent as AdmarketLogo } from 'src/assets/images/logo.svg'
type Props = {
	publicPage: boolean
}
export const UserHelpPageComponent = ({ publicPage = false }: Props) => {
	useHeaderTitle('Справка')

	const [contentKey, setContentKey] = useState<HelpContentKey>('registration')

	const [activeItem, setActiveItem] = useState<ActiveItemType>({
		itemId: 'registration',
		nestedId: undefined,
	})

	const handleActiveItemChange: HandleActiveItemChangeType = (
		itemId,
		nestedId
	) => {
		setActiveItem({
			itemId: itemId,
			nestedId: nestedId,
		})

		if (nestedId) {
			setContentKey(nestedId as HelpContentKey)
			return
		}

		setContentKey(itemId as HelpContentKey)
	}

	const { isDesktop, isMobile } = useResponsive()

	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				justifyContent: 'space-between',
				flexDirection: !isDesktop ? 'column' : 'row',
				background: 'white',
			}}
		>
			{!isDesktop && (
				<div
					className="d-flex w-100"
					style={{ flexDirection: isMobile ? 'column' : 'row' }}
				>
					<div
						className="d-flex align-items-center justify-content-center px-3"
						style={{
							borderBottom: '1px solid #ced4da',
							borderRight: '1px solid #ced4da',
						}}
					>
						<a href="https://admarket.ru">
							<AdmarketLogo />
						</a>
					</div>
					<div className="w-100">
						<HelpNavigator
							changeActive={handleActiveItemChange}
							activeItem={activeItem.itemId}
							nestedItem={activeItem.nestedId}
						/>
					</div>
				</div>
			)}

			{isDesktop && (
				<div
					style={{
						width: '280px',
						minWidth: '280px',
						height: publicPage ? 'auto' : '100%',
						borderRight: '1px solid #ccc',
						position: 'relative',
					}}
				>
					<div className="w-100 d-flex align-items-center justify-content-center px-2 pt-3 ">
						<a href="https://admarket.ru">
							<AdmarketLogo />
						</a>
					</div>
					<HelpList
						changeActive={handleActiveItemChange}
						activeItem={activeItem.itemId}
						nestedItem={activeItem.nestedId}
					/>
				</div>
			)}

			<HelpContent
				changeActive={handleActiveItemChange}
				contentKey={contentKey}
			/>
		</div>
	)
}

export const UserHelpPage = withPage({ noSidebar: false, isProvider: false })(
	UserHelpPageComponent
)
