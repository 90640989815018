import React, { useMemo, useState } from 'react'
import { Button } from 'react-bootstrap'
import s from './advFormatsNav.module.scss'
import useCampaignData from 'src/stores/campaignData'
import { checkIsAtLeastOneFormatSelected } from 'src/utils'

type Props = {
	onBack: () => void
	onNextStep: () => void
}
export const AdvFormatsNav = ({ onBack, onNextStep }: Props) => {
	const [campaign, campaignInterface] = useCampaignData()

	const [isLoading, setIsLoading] = useState(false)

	const isFormatSelected = useMemo(
		() => checkIsAtLeastOneFormatSelected(campaign),
		[campaign]
	)

	const handleNextStep = async () => {
		if (campaign.is_budget_confirmed) {
			onNextStep()
			return
		}

		if (isFormatSelected && !isLoading) {
			setIsLoading(true)
			try {
				await campaignInterface.confirmBudget(campaign.id)
				onNextStep()
			} catch (e) {
				console.log(e)
			} finally {
				setIsLoading(false)
			}
		}
	}

	const handleBackStep = () => {
		onBack()
	}

	return (
		<div className={s.advFormatsNav}>
			<Button variant="secondary" onClick={handleBackStep}>
				Назад
			</Button>
			<Button
				variant="primary"
				onClick={handleNextStep}
				disabled={!isFormatSelected || isLoading}
			>
				Далее
			</Button>
		</div>
	)
}
