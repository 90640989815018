import React from 'react'
import { Image } from 'react-bootstrap'

type Props = {
	// Define any props you need here
	imageUrl: string
	totalCount: number
}

export const PhotoReportPreview = ({ imageUrl, totalCount }: Props) => {
	return (
		<div style={{ position: 'relative', padding: 0 }}>
			{/* Animated Image using framer-motion */}

			<Image
				src={imageUrl}
				alt="Preview"
				fluid
				style={{
					objectFit: 'cover',
					width: '100%',
					height: '72px',
					borderRadius: '8px',
					boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
				}}
			/>

			<div
				style={{
					position: 'absolute',
					bottom: 0,
					left: 0,
					color: 'white',
					padding: '6px 6px',
					height: 'max-content',
				}}
			>
				{totalCount} фото
			</div>
		</div>
	)
}
