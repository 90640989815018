import React, { FC, useContext, useEffect, useState } from 'react'
import { InternetUploadForm } from './InternetUpload'
import { YandexIcon } from '../../Step2-ADformats/InternetAD/InternetSelector'
import { Alert, Button, Form } from 'react-bootstrap'
import FormLabelTooltip from '../../../../UtilityComponents/FormLabelTooltip'
import { InternetTooltips } from '../../Step2-ADformats/InternetAD/InternetTooltips'
import useCampaignData from '../../../../../stores/campaignData'
import { internetYandex } from '../../../../../constants/api'
import useBidRequest, { bidInfo } from '../CreateCreative/useBidRequest'
import CreativeFileInput from '../../../../UtilityComponents/CreativeFileInput'
import useResponsive from '../../../../../utils/useResponsive'
import { CreateCreativeFields } from '../CreateCreative/CreateCreativeForm'
import { PlacementType } from 'src/stores/ADMarketTypes.types'
import { TemplatesModal } from '../components'
import useNotificationData from 'src/stores/notificationsData'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { OrderLayoutModal } from '../components/order-layout-modal'
import { NewContentCreatedStatusType } from 'src/stores/types/newContendCreatedType'
import { InputExtraButtons } from '../components/outdoor/common/input-extra-buttons/InputExtraButtons'
import { useCampaignInfoStore } from 'src/components/_client/campaign-info/store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from 'src/components/_client/campaign-info/store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { EditVinstantModal } from 'src/components/_client/campaign-info/common/edit-vinstant-modal/EditVinstantModal'
import { downloadByRequest } from 'src/utils'
import { EditVinstantProcessingWrapped } from 'src/components/_client/campaign-info/common/edit-vinstant-processing/EditVinstantProcessing'

interface IUploadYandex {
	values: InternetUploadForm
	setFieldValue: (field_name: keyof InternetUploadForm, value: any) => null
	setFieldError: (field_name: keyof InternetUploadForm, error: any) => null
	errors: { [key in keyof InternetUploadForm]?: string }
}

const UploadYandex: FC<IUploadYandex> = ({
	setFieldError,
	errors,
	setFieldValue,
	values,
}) => {
	const [campaign, campaignInterface] = useCampaignData()

	const [loadingInProgress, setLoadingInProgress] = useState(false)

	const { isHaveBidRequest } = useBidRequest()
	const { isMobile } = useResponsive()
	const [modalOpen, setModalOpen] = useState(false)
	const [editVinstantOpen, setEditVinstantOpen] = useState(false)
	const [isOrderLayoutModal, setIsOrderLayoutModal] = useState(false)

	const editVinstantKey = String(campaign.internet_yandex?.id)
	const yandexVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'yandex_id',
			itemId: editVinstantKey,
		})
	)

	const patchSingleField = async (
		field: keyof InternetUploadForm,
		value: string | number
	) => {
		const correctFieldName = field.replace(field.split('_')[0] + '_', '')
		const prevValue = campaign.internet_yandex[correctFieldName]
		if (value === prevValue) return
		const formData = new FormData()
		formData.append(correctFieldName, value.toString())
		await campaignInterface.patchInternetYandex(campaign.id, formData)
	}

	const controlValidationYandexText = () => {
		if (values.yandex_text !== '' && values.yandex_text.length <= 81) {
			patchSingleField('yandex_text', values.yandex_text).then()
		} else if (values.yandex_text.length > 81) {
			setFieldError(
				'yandex_text',
				'Тех.ограничение "Яндекс" длина не должна привышать 81 символ'
			)
		} else {
			setFieldError('yandex_text', 'Введите текст объявления')
		}
	}

	const controlValidationYandexHeading = () => {
		if (
			values.yandex_heading !== '' &&
			values.yandex_heading.length <= 81
		) {
			patchSingleField('yandex_heading', values.yandex_heading).then()
		} else if (values.yandex_heading.length > 81) {
			setFieldError(
				'yandex_heading',
				'Тех.ограничение "Яндекс" длина не должна привышать 81 символ'
			)
		} else {
			setFieldError('yandex_heading', 'Введите заголовок')
		}
	}

	const handleCreateMaterial = async () => {
		setModalOpen(true)
	}

	const closeModal = () => {
		setModalOpen(false)
	}
	const virtualPlacement = {
		name: campaign.internet_yandex.link as string,
		size: 'до 5 МБ',
		media_type: {
			video_formats: ['MPEG4', 'MP4', 'MOV'],
			image_formats: ['JPEG', 'JPG', 'PNG', 'PDF'],
			min_width: 1920,
			min_height: 1080,
			aspect_ratio: '16:9',
		},
		image: 'https://storage.yandexcloud.net/admarket-images/static-img/internet.png',
		placement_type: {
			name: 'Яндекс-реклама',
		},
	} as Omit<PlacementType, 'placement_showing_counts'>

	const [_, notificationsInterface] = useNotificationData()

	const createdMaterialStatus = notificationsInterface.getNewContentCreated(
		'yandex'
	) as NewContentCreatedStatusType | undefined

	const { addToast } = useContext(ToastsContext)

	const handleIsOrderLayoutOpen = () => {
		setModalOpen(false)
		setIsOrderLayoutModal(true)
	}

	const bidInfo: bidInfo = {
		id: campaign.internet_yandex.id,
		type: 'internet_yandex',
	}
	const handleOrderLayoutSubmit = async (values: CreateCreativeFields) => {
		for (let key in values) {
			if (
				values[key] === null ||
				values[key] === undefined ||
				values[key] === ''
			) {
				delete values[key]
			}
		}

		await campaignInterface.createNewBidRequest(values, {
			yandex_id: campaign.internet_yandex.id.toString(),
		})
		setIsOrderLayoutModal(false)
	}

	const handleStatusHasChanged = async (
		status: NewContentCreatedStatusType
	) => {
		if (status === 'start') {
			setLoadingInProgress(true)
		}
		if (status === 'end') {
			setLoadingInProgress(false)

			await campaignInterface.refetchSelected()

			addToast({
				text: `Загрузка контента для ${virtualPlacement.placement_type.name} завершена`,
				type: 'success',
			})

			notificationsInterface.setNewContendCreated({
				yandex: 'pending',
			})
		}
	}

	const handleUpload = () => {
		if (campaign.internet_yandex.image) {
			downloadByRequest(
				`adv_companies/${campaign.id}/yandex_creative/download/`,
				campaign.internet_yandex.image
			)
		}
	}
	useEffect(() => {
		if (!!createdMaterialStatus) {
			handleStatusHasChanged(createdMaterialStatus)
		}
	}, [createdMaterialStatus])
	return (
		<>
			{modalOpen && (
				<TemplatesModal
					getOrderLayoutModal={handleIsOrderLayoutOpen}
					newContentKey="yandex"
					placement={virtualPlacement}
					instanceId={campaign.internet_yandex.id.toString()}
					clsName={'yandexinternetadvertising'}
					campaingId={campaign.id}
					closeModal={closeModal}
					modalOpen={modalOpen}
					screenResolution={'1920*1080'}
				/>
			)}

			<EditVinstantModal
				newContentKey={'yandex'}
				editVinstantLink={yandexVinstantLink}
				onClose={() => setEditVinstantOpen(false)}
				open={editVinstantOpen}
			/>

			{isOrderLayoutModal && (
				<OrderLayoutModal
					onSubmit={handleOrderLayoutSubmit}
					open={isOrderLayoutModal}
					onClose={() => setIsOrderLayoutModal(false)}
				/>
			)}
			<div className={'d-flex flex-column gap-4'}>
				<div className={'d-flex justify-content-between'}>
					<h5 className={'mb-0'}>Яндекс</h5>
					<div>
						<YandexIcon />
					</div>
				</div>
				<Alert
					variant={'secondary'}
					className={'d-flex align-items-start gap-3 mb-0'}
				>
					<i
						className={'bi bi-info-circle'}
						style={{ fontSize: '20px' }}
					/>
					<span>
						Укажите информацию ниже и загрузите материал для
						рекламного баннера. Если материал нет, мы можем сделать
						его за вас. Для этого нажмите на кнопку «Создать
						материал».
					</span>
				</Alert>
				{/*Heading*/}
				<Form.Group>
					<Form.Label>
						Что будете рекламировать?
						<FormLabelTooltip
							text={InternetTooltips.YANDEX.heading}
						/>
					</Form.Label>
					<Form.Control
						type={'text'}
						size={'lg'}
						value={values.yandex_heading}
						onChange={(e) => {
							setFieldError('yandex_heading', undefined)
							setFieldValue('yandex_heading', e.target.value)
						}}
						onBlur={() => controlValidationYandexHeading()}
						isInvalid={!!errors.yandex_heading}
						placeholder={'Не задан'}
					/>
					<Form.Control.Feedback type={'invalid'}>
						{errors.yandex_heading}
					</Form.Control.Feedback>
				</Form.Group>
				{/*Text*/}
				<Form.Group>
					<Form.Label>
						Описание вашего товара или услуги{' '}
						<FormLabelTooltip text={InternetTooltips.YANDEX.text} />
					</Form.Label>
					<Form.Control
						type={'text'}
						size={'lg'}
						value={values.yandex_text}
						onChange={(e) => {
							setFieldError('yandex_text', undefined)
							setFieldValue('yandex_text', e.target.value)
						}}
						onBlur={() => controlValidationYandexText()}
						isInvalid={!!errors.yandex_text}
						placeholder={'Не задан'}
					/>
					<Form.Control.Feedback type={'invalid'}>
						{errors.yandex_text}
					</Form.Control.Feedback>
				</Form.Group>
				<Form.Group>
					<Form.Label>
						Рекламный материал
						<FormLabelTooltip
							text={
								'Требования к материалам:\n' +
								'Формат: JPEG, PNG, GIF.\n' +
								'Разрешение: 1920 x 1080 px.\n' +
								'Размер: до 5 МБ.'
							}
						/>
					</Form.Label>

					{createdMaterialStatus === 'start' ? (
						<EditVinstantProcessingWrapped />
					) : (
						<CreativeFileInput
							ExtraButton={
								<InputExtraButtons
									onEditVinstant={
										yandexVinstantLink
											? () => setEditVinstantOpen(true)
											: undefined
									}
									onUpload={handleUpload}
								/>
							}
							maxSizeMb={5}
							allowedExtension={['jpg', 'png']}
							loadingInProgress={loadingInProgress}
							bidInfo={bidInfo}
							initialValue={{
								src: campaign.internet_yandex.image,
								name: 'Текущий материал',
							}}
							updateUrl={internetYandex.params(campaign.id)}
							nameInBody={'image'}
							description={
								' Изображения — JPG, PNG. Разрешение: 1920 x 1080 px. Размер: до 5 МБ.'
							}
							removeMethod={'PATCH'}
							onFileLoad={() =>
								campaignInterface.refetchSelected()
							}
							onFileDelete={() =>
								campaignInterface.refetchSelected()
							}
						/>
					)}
				</Form.Group>
				{!isHaveBidRequest(bidInfo) && !campaign.internet_yandex.image && (
					<Button
						variant={'light'}
						className={'w-100'}
						onClick={handleCreateMaterial}
					>
						Создать материал
					</Button>
				)}
			</div>
		</>
	)
}

export default UploadYandex
