import { Operations } from '../../../stores/ADMarketTypes.types'
import IntlFormatter from '../../../utils/intl-money-formatter'
import React from 'react'
import moment from 'moment'

type OperationInfo = {
	title: string
	payment_method: string
	amount: string
	status: JSX.Element
	date: string
}

const getOperationInfo = (
	operation: Operations,
	statusFontSize = '14px'
): OperationInfo => {
	const { campaign_name } = operation
	const isPayment = operation.payment !== null
	const status =
		OPERATION_STATUSES[
			isPayment ? operation.payment.status : operation.invoice.status
		] || DEFAULT_STATUS

	return {
		title: `Оплата рекламной кампании "${campaign_name}"`,
		amount: IntlFormatter.format(
			operation[isPayment ? 'payment' : 'invoice'].amount.toString()
		),
		payment_method: isPayment ? 'Оплата картой' : 'Банковский перевод',
		status: (
			<span
				className={'mb-0'}
				style={{ color: status.color, fontSize: statusFontSize }}
			>
				{status.text}
			</span>
		),
		date: moment(
			isPayment
				? operation.payment.created_at
				: operation.invoice.created_at
		).format('DD.MM.YYYY HH:mm'),
	}
}

const DEFAULT_STATUS = { text: 'Неизвестно', color: '#DC3545' }

const OPERATION_STATUSES = {
	confirmed: { text: 'Оплачено', color: '#28A745' },
	reject: { text: 'Отклонен', color: '#DC3545' },
	canceled: { text: 'Отменен', color: '#DC3545' },
	refunded: { text: 'Возврат', color: '#007BFF' },
	send: { text: 'Выставлен', color: '#007BFF' },
	new: { text: 'Новый', color: '#007BFF' },
}

export default getOperationInfo
