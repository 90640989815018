import React from 'react'
import { Card } from 'react-bootstrap'
import s from './advTypeSummaryInfo.module.scss'
import useCampaignData from 'src/stores/campaignData'
import { useMemo } from 'react'
import IntlFormatter from 'src/utils/intl-money-formatter'

type Props = {
	advType: 'indoor' | 'outdoor' | 'internet'
}
export const AdvTypeSummaryInfo = ({ advType }: Props) => {
	const [campaign, campaignInterface] = useCampaignData()

	const totalAdvTypeBudget = useMemo(() => {
		let totalBudget = 0
		if (advType === 'indoor') {
			campaign.indoor_bookings.forEach(
				(booking) => (totalBudget += booking.booking_budget || 0)
			)
		}
		if (advType === 'outdoor') {
			campaign.outdoor_bookings.forEach(
				(booking) => (totalBudget += booking.booking_budget || 0)
			)
			campaign.districtbookings.forEach((booking) => {
				const amount = parseFloat(booking.amount)
				if (!isNaN(amount)) {
					totalBudget += amount
				}
			})
		}
		if (advType === 'internet') {
			if (campaign.internet_yandex.is_selected) {
				totalBudget += campaign.internet_yandex.amount || 0
			}
			if (campaign.internet_vk.is_selected) {
				totalBudget += campaign.internet_vk.amount || 0
			}
		}

		return totalBudget
	}, [campaign])
	return (
		<Card className={s.advTypeSummaryInfo}>
			<div className="d-flex justify-content-between">
				<h5>Стоимость всего:</h5>
				<h5>{IntlFormatter.format(totalAdvTypeBudget)}</h5>
			</div>
		</Card>
	)
}
