import {
	CampaignInfoType,
	DistrictBookingsCreativeType,
} from 'src/stores/ADMarketTypes.types'
import {
	AdvItemState,
	AdvMediaType,
	DistrictBookingItem,
	DistrictBookingMediaFormat,
	PlacementViewModel,
} from '../types'
import { defaultPlacementImage } from '../constants/defaultPlacementImage'

export const districtMapper = (
	mediaFormat: AdvMediaType[],
	campaign: CampaignInfoType
): DistrictBookingItem[] => {
	const { districtbookings } = campaign

	if (districtbookings.length === 0) {
		return []
	}
	//получим список медиаформатов используемых для districtBooking
	const allMediaFormatsWithDistrictBooking: AdvMediaType[] = []

	for (let i = 0; i < mediaFormat.length; i++) {
		if (
			mediaFormat[i].placements.find(
				(placement) => placement.districts.length > 0
			)
		) {
			allMediaFormatsWithDistrictBooking.push(mediaFormat[i])
			continue
		}
	}

	if (allMediaFormatsWithDistrictBooking.length === 0) {
		return []
	}

	const district: DistrictBookingItem[] = districtbookings.map(
		(districtBooking) => {
			const districtBookingMediaFormats: DistrictBookingMediaFormat[] = []

			for (
				let i = 0;
				i < allMediaFormatsWithDistrictBooking.length;
				i++
			) {
				/**
				 * если в списке всех медиа форматов с DistrictBooking есть формат
				 * у которого одно из мест принадлежит текущему итерируемому districtBooking,то нужно
				 * 1. нужно связать этот медиа формат с текущим districtBooking
				 * 2. медиа формату добавить креатив который используется в districtBooking
				 * 3. взять все места в этом медиа формате и найти принадлежащие текущему districtBooking
				 */
				const mediaFormatShouldBeChecked =
					allMediaFormatsWithDistrictBooking[i].placements.find(
						(placement) =>
							placement.districts[0]?.id === districtBooking.id
					)
				if (mediaFormatShouldBeChecked) {
					const allIncludedPlacements =
						allMediaFormatsWithDistrictBooking[i].placements.reduce(
							(acc: PlacementViewModel[], placement) => {
								if (
									placement.districts[0]?.id ===
									districtBooking.id
								) {
									acc.push({
										address: placement.address,
										id: placement.id,
										image:
											placement.image ||
											defaultPlacementImage,
										name: placement.name,
									})
								}
								return acc
							},
							[]
						)
					const creative = mediaFormatShouldBeChecked.districts[0]
						.creatives[0] as Omit<
						DistrictBookingsCreativeType,
						'edit_vinstant'
					>
					districtBookingMediaFormats.push({
						id: allMediaFormatsWithDistrictBooking[i].id,
						media_type_name:
							allMediaFormatsWithDistrictBooking[i].name,
						placements: allIncludedPlacements,
						media: creative,
						media_requirements: {
							age_restriction_requirements:
								allMediaFormatsWithDistrictBooking[i]
									.age_restriction_requirements,
							color_model:
								allMediaFormatsWithDistrictBooking[i]
									.color_model,
							codec: allMediaFormatsWithDistrictBooking[i].codec,
							disclaimer_requirements:
								allMediaFormatsWithDistrictBooking[i]
									.disclaimer_requirements,
							duration:
								allMediaFormatsWithDistrictBooking[i].duration,
							image_formats:
								allMediaFormatsWithDistrictBooking[i]
									.image_formats,
							image_max_size:
								allMediaFormatsWithDistrictBooking[i]
									.image_max_size,
							max_bitrate:
								allMediaFormatsWithDistrictBooking[i]
									.max_bitrate,
							max_dpi:
								allMediaFormatsWithDistrictBooking[i].max_dpi,
							max_fps:
								allMediaFormatsWithDistrictBooking[i].max_fps,
							max_height:
								allMediaFormatsWithDistrictBooking[i]
									.max_height,
							max_width:
								allMediaFormatsWithDistrictBooking[i].max_width,
							min_bitrate:
								allMediaFormatsWithDistrictBooking[i]
									.min_bitrate,
							min_dpi:
								allMediaFormatsWithDistrictBooking[i].min_dpi,
							min_fps:
								allMediaFormatsWithDistrictBooking[i].min_fps,
							min_height:
								allMediaFormatsWithDistrictBooking[i]
									.min_height,
							min_width:
								allMediaFormatsWithDistrictBooking[i].min_width,
							is_image:
								allMediaFormatsWithDistrictBooking[i].is_image,
							is_video:
								allMediaFormatsWithDistrictBooking[i].is_video,
							other_requirements:
								allMediaFormatsWithDistrictBooking[i]
									.other_requirements,
							video_formats:
								allMediaFormatsWithDistrictBooking[i]
									.video_formats,
							video_max_size:
								allMediaFormatsWithDistrictBooking[i]
									.video_max_size,
							with_audio_track:
								allMediaFormatsWithDistrictBooking[i]
									.with_audio_track,
							additional_info:
								allMediaFormatsWithDistrictBooking[i]
									.additional_info,
							aspect_ratio:
								allMediaFormatsWithDistrictBooking[i]
									.aspect_ratio,
							external_link:
								allMediaFormatsWithDistrictBooking[i]
									.external_link,
						},
						external_id:
							allMediaFormatsWithDistrictBooking[
								i
							].external_id?.toLocaleLowerCase() || '',
					})
				}
			}

			return {
				adjustment_msg: districtBooking.adjustment_msg,
				advFormats: districtBookingMediaFormats,
				amount: districtBooking.amount,
				id: districtBooking.id,
				city_id: districtBooking.city_id,
				city_name: districtBooking.city_name,
				reject_msg: districtBooking.reject_msg,
				is_docs_required: districtBooking.is_docs_required,
				state: districtBooking.state as unknown as AdvItemState,
			}
		}
	)

	return district
}

//нужно получить список медиаформатов для конкретного districtBooking и какие места входят в этот districtbooking

//если в медиаформате есть хотя бы одно место где districtBooking[0].id совпадает с итерируемым districtBooking.id то нужно запомнить этот формат
//нужно проверить

// const mediaFormatsWithCurrentDistrictBooking: DistrictBookingMediaFormat[] =
// 	allMediaFormatsWithDistrictBooking.map((mediaFormat) => {
// 		const districtBookingPlacements = mediaFormat.placements.filter(
// 			(placement) =>
// 				placement.districts[0].id === districtBooking.id
// 		)

// 		const districtBookingMediaFormat: DistrictBookingMediaFormat = {
// 			id: mediaFormat.id,
// 			media_type_name: mediaFormat.name,
// 			media_requirements: {
// 				age_restriction_requirements:
// 					mediaFormat.age_restriction_requirements,
// 				color_model: mediaFormat.color_model,
// 				codec: mediaFormat.codec,
// 				disclaimer_requirements:
// 					mediaFormat.disclaimer_requirements,
// 				duration: mediaFormat.duration,
// 				image_formats: mediaFormat.image_formats,
// 				image_max_size: mediaFormat.image_max_size,
// 				max_bitrate: mediaFormat.max_bitrate,
// 				max_dpi: mediaFormat.max_dpi,
// 				max_fps: mediaFormat.max_fps,
// 				max_height: mediaFormat.max_height,
// 				max_width: mediaFormat.max_width,
// 				min_bitrate: mediaFormat.min_bitrate,
// 				min_dpi: mediaFormat.min_dpi,
// 				min_fps: mediaFormat.min_fps,
// 				min_height: mediaFormat.min_height,
// 				min_width: mediaFormat.min_width,
// 				is_image: mediaFormat.is_image,
// 				is_video: mediaFormat.is_video,
// 				other_requirements: mediaFormat.other_requirements,
// 				video_formats: mediaFormat.video_formats,
// 				video_max_size: mediaFormat.video_max_size,
// 				with_audio_track: mediaFormat.with_audio_track,
// 				additional_info: mediaFormat.additional_info,
// 				aspect_ratio: mediaFormat.aspect_ratio,
// 				external_link: mediaFormat.external_link,
// 			},
// 			placements: districtBookingPlacements.map((placement) => ({
// 				address: placement.address,
// 				id: placement.id,
// 				image: placement.image,
// 				name: placement.name,
// 			})),
// 			media: 'https://storage.yandexcloud.net/admarket-media-dev/districtbookingcreative/1a/86/1a8675d9-7d60-48ee-b1f1-575fe0e56641.mp4',
// 		}
// 		return districtBookingMediaFormat
// 	})
