import { minMax } from 'src/components/_client/create-campaign/Step3-Upload/DataUpload'
import { MediaRequirements } from 'src/stores/technical-requirements-store'

export const prepareMediaRequirementsData = (
	mediaRequierments: MediaRequirements
) => {
	let availableMediaTypes: string[] = []

	if (mediaRequierments.is_image) {
		availableMediaTypes.push('Изображение')
	}
	if (mediaRequierments.is_video) {
		availableMediaTypes.push('Видео')
	}
	const data = {
		Тип: availableMediaTypes.join(', '),
		['Допустимый формат']: [
			...mediaRequierments.image_formats,
			...mediaRequierments.video_formats,
		],
		Ширина: mediaRequierments?.max_width?.toString(),
		Высота: mediaRequierments?.max_height?.toString(),
		['Размер видео']:
			mediaRequierments?.is_video && mediaRequierments?.video_max_size
				? `не более ${mediaRequierments?.video_max_size} МБ`
				: null,
		['Размер изображения']:
			mediaRequierments.is_image && mediaRequierments?.image_max_size
				? `не более ${mediaRequierments?.image_max_size} МБ`
				: null,
		['Соотношение сторон']: mediaRequierments?.aspect_ratio,
		['Цветовая модель']:
			mediaRequierments.is_image && mediaRequierments?.color_model
				? mediaRequierments?.color_model
				: null,
		['Точек на дюйм']:
			mediaRequierments?.is_image &&
			(mediaRequierments?.max_dpi || mediaRequierments?.min_dpi)
				? minMax(
						mediaRequierments?.min_dpi,
						mediaRequierments?.max_dpi,
						'dpi'
				  )
				: null,
		['Кодек']:
			mediaRequierments?.is_video && mediaRequierments.codec
				? mediaRequierments.codec
				: null,
		['Кадровая частота']:
			mediaRequierments?.is_video &&
			(mediaRequierments?.min_fps || mediaRequierments?.max_fps)
				? minMax(
						mediaRequierments?.min_fps,
						mediaRequierments?.max_fps,
						'fps'
				  )
				: null,
		['Битрейт']:
			mediaRequierments?.is_video &&
			(mediaRequierments?.min_bitrate || mediaRequierments?.max_bitrate)
				? minMax(
						mediaRequierments?.min_bitrate,
						mediaRequierments?.max_bitrate,
						'Мбит/с'
				  )
				: null,
		['Длительность']:
			mediaRequierments?.is_video && mediaRequierments?.duration
				? `${mediaRequierments?.duration} секунд`
				: null,
		['Звуковая дорожка']:
			mediaRequierments?.is_video && mediaRequierments?.with_audio_track
				? 'Да'
				: 'Нет',
		['Требования дисклеймера']: mediaRequierments?.disclaimer_requirements
			? mediaRequierments?.disclaimer_requirements?.split('\n')
			: null,
		['Дополнительные требования']: mediaRequierments?.other_requirements
			? mediaRequierments?.other_requirements?.split('\n')
			: null,
		['Требования возрастного ограничения']:
			mediaRequierments?.age_restriction_requirements
				? mediaRequierments?.age_restriction_requirements?.split('\n')
				: null,
	}

	for (const key in data) {
		if (!data[key]) {
			delete data[key]
		}
	}

	return {
		...data,
	} as { [key: string]: string | string[] }
}
