import { ActionButtonClickProps } from './Context.types'
import useProviderData from '../../../../stores/providerData'
import { useContext } from 'react'
import { ToastsContext } from '../../../UtilityComponents/ToastsContextProvider'

import {
	REUSABLE_MODALS,
	useReusableModals,
} from '../../../UtilityComponents/ReusableModals/ReusableModalsProvider'

const useActionsController = (refetch: Function) => {
	const [, providerInterface] = useProviderData()
	const showModal = useReusableModals()
	const { addToast } = useContext(ToastsContext)

	async function handleConfirmBooking(id) {
		await providerInterface.AcceptBooking(id)
		addToast({ text: 'Бронь подтверждена', type: 'success' })
		refetch()
	}
	async function handleCancelBooking(id) {
		await providerInterface.RejectBooking(id)
		addToast({ text: 'Бронь отклонена', type: 'danger' })
		refetch()
	}
	async function handleExportBooking(id) {
		await providerInterface.ExportBookings(id)
	}
	async function handleEditBooking(id) {
		const message = await showModal(REUSABLE_MODALS.getAdjustmentMessage)
		if (message) {
			await providerInterface.AdjustBooking(id, message)
			refetch()
		}
	}
	async function handleGroupOperations(
		action: 'accept' | 'edit' | 'reject' | 'download',
		ids: number[]
	) {
		if (action === 'edit') {
			//получить сообщение об исправлении
			const message = await showModal(
				REUSABLE_MODALS.getAdjustmentMessage
			)
			if (message) {
				providerInterface
					.GroupBookingsOperation(action, ids, message as string)
					.then(() => {
						addToast({ text: 'Успешно', type: 'success' })
						refetch()
					})
					.catch((e) => {
						addToast({ text: 'Ошибка', type: 'danger' })
					})
			}
		} else {
			providerInterface
				.GroupBookingsOperation(action, ids)
				.then(() => {
					addToast({ text: 'Успешно', type: 'success' })
					refetch()
				})
				.catch((e) => {
					addToast({ text: 'Ошибка', type: 'danger' })
				})
		}
	}

	return ({ action, id }: ActionButtonClickProps) => {
		if (Array.isArray(id)) {
			//group operations
			switch (action) {
				case 'accept':
					handleGroupOperations(action, id).then()
					break
				case 'edit':
					handleGroupOperations(action, id).then()
					break
				case 'download':
					handleExportBooking(id).then()
					break
				case 'reject':
					handleGroupOperations(action, id).then()
					break
			}
		} else {
			//single operation
			switch (action) {
				case 'accept':
					handleConfirmBooking(id).then()
					break
				case 'edit':
					handleEditBooking(id).then()
					break
				case 'download':
					handleExportBooking(id).then()
					break
				case 'reject':
					handleCancelBooking(id).then()
					break
			}
		}
	}
}

export default useActionsController
