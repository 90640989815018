import React, { FC } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { Formik } from 'formik'
import { InternetAdType } from '../../../../stores/ADMarketTypes.types'
import { ScrollToError } from '../../../UtilityComponents/ScrollToFormikError'
import FileInput from '../../../UtilityComponents/FileInput'
import useCampaignData from '../../../../stores/campaignData'
import { DocumentsUploadForm } from './DocumentsUploadForm'
import { useCampaignInfoStore } from '../store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from '../store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { EditVinstantInputButton } from '../common/edit-vinstant-input-button/EditVinstantInputButton'
import { EditVinstantProcessingWrapped } from '../common/edit-vinstant-processing/EditVinstantProcessing'

interface IEditInternetModal {
	show: boolean
	handleClose: () => void
	internetName: 'yandex' | 'vk'
	internet: InternetAdType
	onEditVinstantClick: () => void
	editVinstantStatus: string | undefined
}

const EditInternetModal: FC<IEditInternetModal> = ({
	show,
	handleClose,
	internetName,
	internet,
	onEditVinstantClick,
	editVinstantStatus,
}) => {
	const [campaign, campaignInterface] = useCampaignData()
	const initialValues = {
		heading: internet.heading,
		text: internet.text,
		link: internet.link,
		image: { src: internet.image, name: 'Текущее изображение' },
		// displayed_link: internet.displayed_link,
		// additional_heading: internet.additional_heading,
		// logo: { src: internet.logo, name: 'Текущий логотип' },
	}

	const internetVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: internetName === 'vk' ? 'vk_id' : 'yandex_id',
			itemId: internet.id.toString(),
		})
	)
	async function handleSubmit(values, { setErrors }) {
		const fd = new FormData()
		const copy = { ...values }
		delete copy?.image
		delete copy?.logo
		for (let key in copy) {
			fd.append(key, copy[key])
		}
		if (values.logo instanceof File) {
			fd.append('logo', values.logo)
		}
		if (values.image instanceof File) {
			fd.append('image', values.image)
		}
		if (campaign.state === 'moderation') {
			fd.append('state', 'inner_moderation')
		} else {
			fd.append('state', 'moderation')
		}

		try {
			if (internetName === 'vk')
				await campaignInterface.patchInternetVk(campaign.id, fd)
			if (internetName === 'yandex')
				await campaignInterface.patchInternetYandex(campaign.id, fd)

			handleClose()
		} catch (e: any) {
			const errors = {}
			for (const k in e) {
				errors[k] = e?.[k][0]
			}
			setErrors(errors)
		}
	}

	function handleValidation(values: typeof initialValues) {
		// const errors: any = {}
		// if (values.link !== '') {
		// 	if (!validateUrl(values.link))
		// 		errors.link = 'Введите правильный URL'
		// }
		// ;[
		// 	'heading',
		// 	'additional_heading',
		// 	'text',
		// 	'displayed_link',
		// 	'link',
		// ].forEach((name) => {
		// 	if (!values[name]) {
		// 		errors[name] = 'Поле не может быть пустым '
		// 	}
		// })
		// if (values.logo === null) errors['logo'] = 'Загрузите логотип'
		// if (values.image === null) errors['image'] = 'Загрузите изображение'
		// return errors
	}

	return (
		<Modal show={show} onHide={handleClose} backdrop={'static'}>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} style={{ marginLeft: '8px' }}>
					{internetName === 'vk' && 'Исправление рекламной сети VK'}
					{internetName === 'yandex' &&
						'Исправление рекламной сети Яндекса'}
				</Modal.Title>
			</Modal.Header>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validate={handleValidation}
				validateOnChange={false}
			>
				{({
					values,
					setFieldValue,
					handleSubmit,
					errors,
					setFieldError,
				}: any) => (
					<>
						<Modal.Body style={{ padding: '24px' }}>
							<Form className={'d-flex flex-column gap-3'}>
								<ScrollToError />
								<Form.Group>
									<Form.Label>
										Заголовок объявления
									</Form.Label>
									<Form.Control
										size={'lg'}
										value={values[`heading`]}
										onChange={(e) => {
											setFieldValue(
												`heading`,
												e.target.value
											)
											setFieldError(`heading`, null)
										}}
										isInvalid={!!errors[`heading`]}
										name={`heading`}
									/>
									<Form.Control.Feedback type="invalid">
										{errors[`heading`]}
									</Form.Control.Feedback>
								</Form.Group>

								<Form.Group>
									<Form.Label>Текст объявления</Form.Label>
									<Form.Control
										size={'lg'}
										value={values[`text`]}
										onChange={(e) => {
											setFieldValue(
												`text`,
												e.target.value
											)
											setFieldError(`text`, null)
										}}
										isInvalid={!!errors[`text`]}
										name={`text`}
										as={'textarea'}
									/>
									<Form.Control.Feedback type="invalid">
										{errors[`text`]}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>Ссылка</Form.Label>
									<Form.Control
										size={'lg'}
										value={values[`link`]}
										onChange={(e) => {
											setFieldValue(
												`link`,
												e.target.value
											)
											setFieldError(`link`, null)
										}}
										isInvalid={!!errors[`link`]}
										name={`link`}
									/>
									<Form.Text>
										Ссылки в рекламной кампании должны вести
										на страницы одного сайта
									</Form.Text>
									<Form.Control.Feedback type="invalid">
										{errors[`link`]}
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group>
									<Form.Label>
										Загрузка изображения
									</Form.Label>

									{editVinstantStatus === 'start' ? (
										<EditVinstantProcessingWrapped />
									) : (
										<FileInput
											ExtraButton={
												internetVinstantLink ? (
													<EditVinstantInputButton
														onClick={
															onEditVinstantClick
														}
													/>
												) : null
											}
											allowedExtensions={[
												'jpeg',
												'jpg',
												'png',
											]}
											onUpload={(file) => {
												setFieldValue('image', file)
												setFieldError('image', null)
											}}
											value={values.image}
											onRemove={() => {
												setFieldValue('image', null)
											}}
											size={20}
											descr={
												'Изображение - JPEG, PNG. Разрешение 1920 x 1080 px. Размер: до 5 МБ.'
											}
											error={errors.image}
										/>
									)}
								</Form.Group>
								{internet?.is_docs_required && (
									<DocumentsUploadForm
										adFormatId={internet.id}
										campaignId={campaign.id}
										title="Загрузка документов"
										type={internetName}
									/>
								)}
							</Form>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
								Отменить
							</Button>
							<Button
								variant="primary"
								onClick={handleSubmit}
								type={'submit'}
								disabled={withErrors(errors)}
							>
								Отправить на модерацию
							</Button>
						</Modal.Footer>
					</>
				)}
			</Formik>
		</Modal>
	)
}
const withErrors = (errors: Object): boolean => {
	if (errors === null) return false
	if (Object.keys(errors).length === 0) return false
	let not_null = false
	for (const [, value] of Object.entries(errors)) {
		if (value !== null) not_null = true
	}
	return not_null ? true : false
}

export default EditInternetModal
