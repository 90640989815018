import { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { BookingsTabsNames, OrderingVariant } from './Context.types'
import useProviderData from '../../../../stores/providerData'

type ReturnedValues = [
	init: BookingsQueryParams,
	update: ({}: BookingsQueryParams) => void
]

export const QueryNames = {
	selectedTab: 'state',
	limit: 'limit',
	offset: 'offset',
	filterByAd: 'adv_companies',
	filterByCompany: 'company',
	selectedBooking: 'id',
	searchString: 'search',
	ordering: 'ordering',
	filterByRegions: 'placement_region',
}
interface BookingsQueryParams {
	selectedTab: BookingsTabsNames
	limit: number
	offset: number
	filterByAd: string[]
	filterByCompany: string[]
	filterByRegions: string[]
	selectedBooking: number
	searchString: string
	ordering: OrderingVariant
}

const appendParam = (
	urlparams: URLSearchParams,
	key: string,
	value: string | number | string[] | number[]
): void => {
	if (Array.isArray(value)) {
		value.forEach((el) => urlparams.append(key, el.toString()))
	} else {
		urlparams.append(key, value.toString())
	}
}

const useBookingsQueryParams = (): ReturnedValues => {
	const [provider] = useProviderData()
	const history = useHistory()
	const location = useLocation()
	const getOnlyValidFilters = useCallback<
		(params: URLSearchParams) => {
			filterByAd: string[]
			filterByCompany: string[]
			filterByRegions: string[]
		}
	>(
		(params) => {
			if (!provider?.bookings_filters) {
				return {
					filterByAd: [],
					filterByCompany: [],
					filterByRegions: [],
				}
			}

			const ids: string[] = []
			const adv_ids: string[] = []
			const regions: string[] = []
			//getting company ids
			provider?.bookings_filters?.companies?.forEach((company) => {
				ids.push(company?.id?.toString())
			})
			//getting adv ids
			provider?.bookings_filters?.adv_campaigns?.forEach((company) => {
				adv_ids.push(company?.id?.toString())
			})

			//получение регионов
			provider?.bookings_filters?.regions?.forEach((region) => {
				regions.push(region?.id)
			})

			const url_company_ids = params.getAll(QueryNames.filterByCompany)
			const url_adv_ids = params.getAll(QueryNames.filterByAd)
			const url_regions = params.getAll(QueryNames.filterByRegions)

			return {
				filterByAd: url_adv_ids.filter((el) => adv_ids.includes(el)),
				filterByCompany: url_company_ids.filter((el) =>
					ids.includes(el)
				),
				filterByRegions: url_regions.filter((el) =>
					regions.includes(el)
				),
			}
		},
		[provider?.bookings_filters]
	)

	const initialValues = useMemo<BookingsQueryParams>(() => {
		const params = new URLSearchParams(location.search)
		history.push({ search: '' })
		const initTab = params.get(QueryNames.selectedTab) as BookingsTabsNames
		const ordering = params.get(QueryNames.ordering) as OrderingVariant

		const { filterByAd, filterByCompany, filterByRegions } =
			getOnlyValidFilters(params)

		return {
			selectedTab: initTab
				? Object.values(BookingsTabsNames).includes(initTab)
					? initTab
					: BookingsTabsNames.INNER_MODERATION
				: BookingsTabsNames.INNER_MODERATION,
			limit: parseInt(params.get(QueryNames.limit) || '10'),
			offset: parseInt(params.get(QueryNames.offset) || '0'),
			filterByAd: filterByAd,
			filterByCompany: filterByCompany,
			filterByRegions: filterByRegions,
			selectedBooking: parseInt(
				params.get(QueryNames.selectedBooking) || '0'
			),
			searchString: params.get(QueryNames.searchString) || '',
			ordering: ordering
				? Object.values(OrderingVariant).includes(ordering)
					? ordering
					: OrderingVariant.NULL
				: OrderingVariant.NULL,
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const update = (values: BookingsQueryParams) => {
		history.push({ search: '' })
		const newParams = new URLSearchParams('')
		for (const [key, value] of Object.entries(values)) {
			if (value || key === 'offset') {
				appendParam(newParams, QueryNames[key], value)
			} else {
				newParams.delete(QueryNames[key])
			}
		}
		history.push({ search: newParams.toString() })
	}

	return [initialValues, update]
}

export default useBookingsQueryParams
