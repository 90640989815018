import React, { useCallback } from 'react'
import { NotificationModel } from 'src/components/_moderator/types/ModerationRequestTypes'
import moment from 'moment'
import { getSenderByMessageMobile } from 'src/components/_moderator/utils/getSenderByMessage'
import { NotificationItemControl } from './NotificationItemControl'
import { NotificationActionType } from '../NotificationsContent'

type Props = {
	onViewClick: (item: NotificationModel) => void
	notification: NotificationModel
	isBorder: boolean
	setNotificationInfo: (
		notificationId: number,
		mode: NotificationActionType
	) => Promise<void>
}

export const NotificationItemMobile = ({
	notification,
	isBorder,
	onViewClick,
	setNotificationInfo,
}: Props) => {
	const handleEditOrDeleteClick = useCallback(
		(mode: NotificationActionType) => {
			setNotificationInfo(notification.id, mode)
		},
		[]
	)
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '0.5rem',
				padding: '1rem 0',
				borderBottom: isBorder ? '1px solid #ced4da' : 'none',
			}}
		>
			<div
				style={{
					wordWrap: 'break-word',
					fontFamily: 'Inter',
					fontSize: '14px',
					fontWeight: 400,
					lineHeight: '22px',
					letterSpacing: '-0.03em',
					textAlign: 'left',
				}}
			>
				{notification.title}
			</div>
			<div
				style={{
					fontFamily: 'Inter',
					fontSize: '12px',
					fontWeight: 400,
					lineHeight: '22px',
					letterSpacing: '-0.03em',
					textAlign: 'left',
				}}
			>
				<div style={{ display: 'flex' }}>
					<div
						style={{
							width: '50%',
							textAlign: 'left',
							color: '#6C757D',
						}}
					>
						Дата:
					</div>
					<div style={{ width: '50%', textAlign: 'left' }}>
						{moment(notification.created_at).format('L')}
					</div>
				</div>
				<div style={{ display: 'flex' }}>
					<div
						style={{
							width: '50%',
							textAlign: 'left',
							color: '#6C757D',
						}}
					>
						Отправлено по:
					</div>
					<div style={{ width: '50%', textAlign: 'left' }}>
						{getSenderByMessageMobile(notification)}
					</div>
				</div>
			</div>
			<NotificationItemControl
				onViewClick={() => onViewClick(notification)}
				editOrDeleteClick={handleEditOrDeleteClick}
			/>
		</div>
	)
}
