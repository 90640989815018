import React, { useContext } from 'react'
import { Button, Card } from 'react-bootstrap'
import { CampaignReportTable } from '../campaign-report-table/CampaignReportTable'
import { ReactComponent as ExcelIcon } from 'src/assets/images/xls.svg'
import s from './campaignReportTableCard.module.scss'
import { useParams } from 'react-router-dom'
import { downloadByRequest } from 'src/utils'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'

export const CampaignReportTableCard = (props: any) => {
	const { campaignId } = useParams<{ campaignId: string }>()

	const { addToast } = useContext(ToastsContext)

	const handleDownload = async () => {
		if (campaignId) {
			const url = `adv_companies/${campaignId}/final-stats/export/`

			const downloadResult = (await downloadByRequest(
				url,
				'Отчет.xlsx',
				'POST'
			)) as { success: boolean }

			if (!downloadResult?.success) {
				addToast({
					text: 'При загрузке отчета произошла ошибка',
					type: 'danger',
				})
			}
		}
	}

	return (
		<Card className={s.reportCard}>
			<div className={s.header}>
				<h3>Отчет о завершенной рекламной кампании</h3>
				<Button variant={'light'} onClick={handleDownload}>
					<ExcelIcon />
					<h6> Скачать отчет в Excel</h6>
				</Button>
			</div>

			<CampaignReportTable />
		</Card>
	)
}
