import React, { FC, useState } from 'react'
import Tooltip, { ITooltip } from './Tooltip'
import useResponsive from '../../utils/useResponsive'

export const FormLabelTooltipCommon: FC<ITooltip> = ({ ...props }) => {
	const { isMobile } = useResponsive()
	const [hover, setHover] = useState(false)

	const handleClick = () => {
		setHover(!hover)
	}
	return (
		<Tooltip {...props} placement={isMobile ? 'top' : props.placement}>
			<span
				className={`${hover ? 'text-black' : 'text-secondary'}`}
				style={{ cursor: 'pointer', ...props?.style }}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				onClick={handleClick}
			>
				{props.children}
			</span>
		</Tooltip>
	)
}
