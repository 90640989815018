import React from 'react'
import { CardWrapper } from '../../../../../common/card-wrapper/CardWrapper'
import IntlFormatter from 'src/utils/intl-money-formatter'
import s from './recBudget.module.scss'

type Props = {
	budget: {
		title: string
		id: string
		items: { title: string; value: string; isSelected: boolean }[]
	}[]
	total: {
		budget: string
		budget_vat: string
	}
}
export const TotalRecBudget = ({ budget, total }: Props) => {
	const filtredBudget = budget.filter((item) =>
		item.items.some((i) => i.isSelected)
	)

	return (
		<CardWrapper>
			<div className={s.content}>
				<h5 className={s.title}>Итоговый бюджет</h5>
				{!!filtredBudget.length && (
					<div className={s.budgets}>
						{filtredBudget.map((el) => (
							<BudgetItem
								key={el.id}
								items={el.items}
								format={el.title}
							/>
						))}
					</div>
				)}

				<div className={s.summary}>
					<div className={s.summaryItem}>
						<h6>Общая сумма</h6>
						<h6>{IntlFormatter.format(total.budget)}</h6>
					</div>
					<div className={s.summaryItem}>
						<h6>Общая сумма с НДС</h6>
						<h6>{IntlFormatter.format(total.budget_vat)}</h6>
					</div>
				</div>
			</div>
		</CardWrapper>
	)
}

type BudgetItemType = {
	title: string
	value: string
	isSelected: boolean
}

type BudgetItemProps = {
	items: BudgetItemType[]
	format: string
}

const BudgetItem = ({ format, items }: BudgetItemProps) => {
	const filtredItems = items.filter((item) => item.isSelected)

	return (
		<div className={s.budgetItem}>
			<div className={s.format}>{format}</div>

			<div className={s.budgetVariantsList}>
				{filtredItems.map((item) => (
					<div key={item.title} className={s.budgetItemVariant}>
						<div>{item.title}</div>
						<h6>{IntlFormatter.format(item.value)}</h6>
					</div>
				))}
			</div>
		</div>
	)
}
