import React from 'react'
import { Placeholder } from 'react-bootstrap'

export const BidRequestPreview = (props: any) => {
	return (
		<Placeholder
			animation="glow"
			className="d-flex gap-2 align-items-center"
		>
			<Placeholder
				style={{ borderRadius: '12px', width: '80px', height: '80px' }}
			/>
			<div className="d-flex align-self-center">
				<h6 style={{ width: '100px', margin: 0 }}>
					Создается дизайнером
				</h6>
			</div>
		</Placeholder>
	)
}
