import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import useResponsive from 'src/utils/useResponsive'
import { ReactComponent as ParallelSliders } from '../../../../../../assets/images/parallelSliders.svg'
import { ReactComponent as Map } from '../../../../../../assets/images/mapIcon.svg'

import s from '../providerFilter.module.scss'
import cn from 'classnames'

type MapAndFilterButtonProps = {
	isFiltersButton?: boolean
	isMapButton?: boolean
	onFilterButtonClick?: () => void
	onMapButtonClick?: () => void
	filtersCount?: number
}

export const MapAndFilterButton = ({
	isFiltersButton = true,
	isMapButton = true,
	onFilterButtonClick,
	onMapButtonClick,
	filtersCount,
}: MapAndFilterButtonProps) => {
	const { isDesktop } = useResponsive()

	const handleFilterButtonClick = () => {
		onFilterButtonClick && onFilterButtonClick()
	}
	const handleOnMapButtonClick = () => {
		onMapButtonClick && onMapButtonClick()
	}
	return (
		<>
			{isMapButton && (
				<Button
					variant={'light'}
					className={cn(s.iconButton)}
					onClick={handleOnMapButtonClick}
				>
					<Map
						style={{
							width: '16px',
							height: '16px',
						}}
					/>

					{isDesktop && 'Смотреть на карте'}
				</Button>
			)}

			{isFiltersButton && (
				<Button
					variant={'light'}
					onClick={handleFilterButtonClick}
					className={cn(s.iconButton)}
				>
					<ParallelSliders
						style={{
							width: '16px',
							height: '16px',
						}}
					/>

					{isDesktop && (
						<div>
							Фильтр
							{filtersCount && (
								<Badge
									style={{
										alignSelf: 'flex-end',
										marginLeft: '4px',
									}}
								>
									{filtersCount}
								</Badge>
							)}
						</div>
					)}
				</Button>
			)}
		</>
	)
}
