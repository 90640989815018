import React, { FC } from 'react'
import useQuery from '../../../../utils/useQuery'
import { BASE_URL } from '../../../../constants/api'
import { Form } from 'react-bootstrap'

interface IGenderSelector {
	value: number[]
	setFieldValue: (name: string, value: any) => null
	setFieldError: (name: string, error: any) => null
	error: string
}

const GenderSelector: FC<IGenderSelector> = ({
	value,
	setFieldValue,
	setFieldError,
}) => {
	const [genders] = useQuery<{ id: number; title: string }[]>({
		url: `${BASE_URL}/campaign/genders/`,
	})
	return (
		<Form.Group className={'mt-4'}>
			<Form.Label>Пол</Form.Label>
			<div>
				{genders?.map((gender) => (
					<Form.Check
						key={gender.id}
						inline
						label={gender.title}
						name={gender.id.toString()}
						type={'radio'}
						checked={value.includes(gender.id)}
						onClick={() => setFieldValue('genders', [gender.id])}
						id={`gender` + gender.id.toString()}
						readOnly
					/>
				))}
			</div>
		</Form.Group>
	)
}

export default GenderSelector
