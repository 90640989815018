import React, { useEffect } from 'react'
import { CardWrapper } from '../../../../../common/card-wrapper/CardWrapper'
import TVvideo from 'src/components/_client/create-campaign/Step2-ADformats/TVAd/TVvideo'

export const Adstream = () => {
	return (
		<CardWrapper>
			<TVvideo />
		</CardWrapper>
	)
}
