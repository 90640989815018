import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import useUserData from '../../stores/userData'
import ROUTES from '../../constants/Routes'
import ProviderSettingsPage from '../_provider/Settings/ProviderSettingsPage'

export default function SettingsCheckRoute({ ...passThrough }) {
	const [user] = useUserData()
	if (!user || !user.phone_number || !user.is_phone_confirmed) {
		return <Redirect to={ROUTES.ROUTE_AUTH} />
	}
	if (user.provider)
		return (
			<Route {...passThrough}>
				<ProviderSettingsPage />
			</Route>
		)

	if (!user.provider) return <Redirect to={ROUTES.ROUTE_CAMPAIGNS} />

	return <Redirect to={ROUTES.ROUTE_AUTH} />
}
