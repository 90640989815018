import { motion } from 'framer-motion'
import React, { FC, useCallback, useState } from 'react'
import { Button } from 'react-bootstrap'
import { CampaignStates } from 'src/stores/ADMarketTypes.types'
import useCampaignData from '../../../../stores/campaignData'
import IntlFormatter from '../../../../utils/intl-money-formatter'
import s from './payment.module.scss'
import { PaymentMessageData } from './PaymentMessage.data'
import { PaymentMessageType } from './PaymentMessages.types'
import usePaymentTime from './usePaymentTime'
import PaymentChooseModal from './PaymentChooseModal/PaymentChooseModal'
import useIsSideBarOpen from '../../../UtilityComponents/useIsSideBarOpen'
import openLinkInNewTab from '../../../../utils/openLinkInNewTab'
import useUserData from 'src/stores/userData'
import { UserConfig } from '../../create-campaign/Step2-ADformats/new-adformats-step/types'

const animation = {
	initial: { bottom: -100 },
	animate: { bottom: 40 },
	transition: { delay: 0.5 },
}

const PaymentBottom: FC = () => {
	const [campaign, campaignInterface] = useCampaignData()
	const { isTimePassed } = usePaymentTime()

	const [showPaymentChoose, setShowPaymentChoose] = useState(false)
	const isSideBarOpen = useIsSideBarOpen()

	const [userData, _] = useUserData()
	const config = userData?.config as UserConfig | undefined

	const getMessageType = useCallback<() => PaymentMessageType>(() => {
		if (
			campaign.state === CampaignStates.Payment_waiting &&
			!!config?.pay_link
		) {
			return PaymentMessageType.AGEN_PAYMENT
		}

		if (
			campaign.state === CampaignStates.Payment_waiting &&
			campaign?.active_invoices?.length !== 0 &&
			!campaign.active_invoices[0].external_url
		) {
			return PaymentMessageType.WAITING
		}

		if (campaign.state === CampaignStates.Confirmed)
			return PaymentMessageType.GO_TO_PAYMENT
		if (
			campaign.state === CampaignStates.Payment_waiting &&
			campaign?.active_invoices?.length !== 0
		) {
			return PaymentMessageType.PAYMENT_WITH_CREATED_INVOICE
		}
		if (
			campaign.state === CampaignStates.Payment_waiting &&
			campaign.active_payments.length === 0
		) {
			return PaymentMessageType.PAYMENT_NO_CHANGES
		}
		if (
			campaign.state === CampaignStates.Payment_waiting &&
			campaign.active_payments.length !== 0 &&
			campaign.active_invoices.length === 0
		) {
			return PaymentMessageType.GO_TO_ACTIVE_PAYMENT
		}
		return PaymentMessageType.DONT_SHOW
	}, [campaign, campaignInterface, config]) // eslint-disable-line react-hooks/exhaustive-deps

	const messageData = PaymentMessageData[getMessageType()]

	const handleBtnClick = async () => {
		if (getMessageType() === PaymentMessageType.AGEN_PAYMENT) {
			if (config?.pay_link) {
				window.open(config.pay_link, '_blank')
			}
		}

		if (getMessageType() === PaymentMessageType.GO_TO_PAYMENT) {
			await campaignInterface.moveCampaignToPayment()
			if (document.activeElement instanceof HTMLElement)
				document.activeElement.blur()
		}
		if (getMessageType() === PaymentMessageType.PAYMENT_NO_CHANGES) {
			setShowPaymentChoose(true)
			if (document.activeElement instanceof HTMLElement)
				document.activeElement.blur()
		}
		if (
			getMessageType() === PaymentMessageType.PAYMENT_WITH_CREATED_INVOICE
		) {
			if (document.activeElement instanceof HTMLElement)
				document.activeElement.blur()
			openLinkInNewTab(campaign.active_invoices[0]?.external_url)
		}

		if (getMessageType() === PaymentMessageType.GO_TO_ACTIVE_PAYMENT) {
			if (document.activeElement instanceof HTMLElement)
				document.activeElement.blur()
			window.location.assign(campaign.active_payments[0].payment_url)
		}
	}

	const shouldCheckTimer =
		!config?.pay_link && campaign.state === CampaignStates.Payment_waiting

	const isTimeCorrect = shouldCheckTimer ? !isTimePassed : true

	if (
		!messageData ||
		(!isTimeCorrect &&
			getMessageType() !== PaymentMessageType.GO_TO_PAYMENT)
	) {
		return null
	}

	return (
		<>
			<motion.div
				className={s.bottomAlertContainer}
				{...animation}
				style={{ paddingLeft: isSideBarOpen ? '240px' : 0 }}
			>
				<div role={'alert'} className={s.bottomAlert}>
					<div className={s.leftText}> {messageData.leftText}</div>
					{messageData?.rightText && (
						<div className={s.rightText}>
							{`Итоговая сумма к оплате: ${IntlFormatter.format(
								parseFloat(campaign.budget_vat.total)
							)}`}
						</div>
					)}
					<div className={s.btnContainer}>
						<Button
							variant={'primary'}
							onClick={handleBtnClick}
							disabled={
								messageData.type === PaymentMessageType.WAITING
							}
						>
							{messageData.buttonText}
						</Button>
					</div>
				</div>
			</motion.div>
			{showPaymentChoose && (
				<PaymentChooseModal
					show={showPaymentChoose}
					handleClose={() => setShowPaymentChoose(false)}
				/>
			)}
		</>
	)
}

export default PaymentBottom
