import React, { FC, useMemo } from 'react'
import { Badge, BadgeProps } from 'react-bootstrap'
import { BookingState } from '../../../../../stores/ADMarketTypes.types'

enum CustomBookingState {
	ProviderNotConfirmed = 'provider_not_confirmed',
}
type BookingBadgeType = {
	text: string
	variant: BadgeProps['bg']
}
const bookingBadgesData: Record<
	BookingState | CustomBookingState,
	BookingBadgeType
> = {
	adjustment: {
		text: 'На исправлении',
		variant: 'primary',
	},
	confirm: {
		text: 'Подтверждена',
		variant: 'success',
	},
	canceled: {
		text: 'Отменена',
		variant: 'danger',
	},
	completed: {
		text: 'Завершена',
		variant: 'success',
	},
	moderation: {
		text: 'На модерации',
		variant: 'primary',
	},
	inner_moderation: {
		text: 'На модерации',
		variant: 'primary',
	},
	reject: {
		text: 'Отклонена',
		variant: 'danger',
	},
	draft: {
		text: 'Черновик',
		variant: 'primary',
	},
	inner_confirm: {
		text: 'Подтверждена',
		variant: 'success',
	},
	provider_not_confirmed: {
		text: 'Требуется подтверждение',
		variant: 'warning',
	},
}

export const BookingBadge: FC<{
	state: BookingState
	providerConfirmed: boolean
}> = ({ state, providerConfirmed }) => {
	const bookingState: BookingState | CustomBookingState = useMemo(() => {
		if (state === BookingState.Completed && !providerConfirmed) {
			return CustomBookingState.ProviderNotConfirmed
		}
		return state
	}, [state, providerConfirmed])

	const { text, variant } = bookingBadgesData[bookingState]
	return (
		<Badge
			bg={variant}
			style={{
				// height: '18px',
				// padding: '3px 4px',
				// fontFamily: 'Inter',
				// fontStyle: 'normal',
				// fontWeight: 500,
				// fontSize: '12px',
				// lineHeight: '12px',
				// letterSpacing: '-0.05em',
				// marginLeft: '8px',
				height: 'fit-content',
			}}
		>
			{text}
		</Badge>
	)
}
