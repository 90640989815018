import React from 'react'
import cn from 'classnames'
import s from './mediaFormatsComponents.module.scss'
import useResponsive from 'src/utils/useResponsive'
import { Button } from 'react-bootstrap'
import { is } from 'immer/dist/internal'

type MediaFormatsHeaderProps = {
	setSelected: any
	handleModalShow: any
	setShowLocationQuickView: any
	isMediaFormats?: boolean
}

export const MediaFormatsHeader = ({
	handleModalShow,
	setSelected,
	setShowLocationQuickView,
	isMediaFormats,
}: MediaFormatsHeaderProps) => {
	const { isMobile } = useResponsive()
	return (
		<div className={cn(s.header, { [s.header_mobile]: isMobile })}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '10px',
					width: isMobile ? '100%' : 'auto',
				}}
			>
				{isMobile && <h5 style={{ margin: 0 }}>Медиа-форматы</h5>}

				{isMediaFormats && (
					<Button
						color="primary"
						size="sm"
						onClick={() => {
							setSelected('')
							handleModalShow()
						}}
					>
						Создать медиа-формат
					</Button>
				)}
			</div>

			{/* {!isMobile && (
				<Button
					variant={'light'}
					onClick={() => setShowLocationQuickView(true)}
				>
					<svg
						width="14"
						height="13"
						viewBox="0 0 14 13"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className={'me-1'}
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M13.3895 0.125843C13.5633 0.251144 13.6663 0.452376 13.6663 0.666672V10C13.6663 10.2525 13.5237 10.4834 13.2978 10.5963L9.29782 12.5963C9.11013 12.6901 8.88922 12.6901 8.70153 12.5963L4.94852 10.7198L1.21049 11.9658C1.00719 12.0336 0.783713 11.9995 0.609868 11.8742C0.436022 11.7489 0.333008 11.5476 0.333008 11.3333V2.00001C0.333008 1.71305 0.516628 1.45829 0.788856 1.36755L4.78886 0.034216C4.9257 -0.0113988 5.07365 -0.0113988 5.21049 0.034216L8.99967 1.29728L12.7889 0.034216C12.9922 -0.0335502 13.2156 0.000542571 13.3895 0.125843ZM8.33301 2.48051L5.66634 1.59162V9.58798L8.33301 10.9213V2.48051ZM9.66634 10.9213L12.333 9.58798V1.59162L9.66634 2.48051V10.9213ZM4.33301 9.5195V1.59162L1.66634 2.48051V10.4084L4.33301 9.5195Z"
							fill="#212529"
						/>
					</svg>
					Смотреть на карте
				</Button>
			)} */}
		</div>
	)
}
