import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'

const NoOperations: FC = ({}) => {
	return (
		<div
			className={
				'w-100 h-100 d-flex flex-column align-items-center justify-content-center gap-4'
			}
		>
			<svg
				width="40"
				height="40"
				viewBox="0 0 40 40"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M22 19.1716V10H18V19.1716C18 20.2324 18.4214 21.2499 19.1716 22L25.5858 28.4142L28.4142 25.5858L22 19.1716Z"
					fill="#6C757D"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40ZM20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36Z"
					fill="#6C757D"
				/>
			</svg>

			<h4 className={'mb-0'}>У вас пока не было операций</h4>
			<Link to={'/create-campaign'}>
				<Button variant={'primary'}>Создать кампанию</Button>
			</Link>
		</div>
	)
}

export default NoOperations
