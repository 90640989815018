import React, { useMemo } from 'react'
import { Form } from 'react-bootstrap'
import useResponsive from 'src/utils/useResponsive'
import s from './providerPagination.module.scss'

export const itemsPerPageLimit = [5, 10, 20, 30, 40]

export type ProviderPaginationProps = {
	limit: number
	offset: number
	setOffset: (offset: number) => void
	setLimit: (limit: number) => void
	total: number
}

export const ProviderPagination = ({
	limit,
	offset,
	setLimit,
	setOffset,
	total,
}: ProviderPaginationProps) => {
	const { isMobile, isDesktop } = useResponsive()

	const currentItems = useMemo<string>(() => {
		if (offset === 0 && limit !== 1)
			return `1 - ${limit > total ? total : limit} из ${total} позиций`
		if (offset + 1 === total) return `${offset + 1} из ${total} позиций`
		if (limit === 1) return `${offset + 1} из ${total} позиций`
		return `${offset + 1} - ${
			offset + limit < total ? offset + limit : total
		} из ${total} позиций`
	}, [offset, limit, total])

	const pageValue = useMemo<number>(() => {
		return offset / limit + 1
	}, [offset, limit])

	const pagesTotal = useMemo<number>(
		() => Math.ceil(total / limit),
		// [provider.bookings, offset, limit]
		[offset, limit, total]
	)

	const pages = useMemo<number[]>(
		() => new Array(pagesTotal || 1).fill(0),
		[pagesTotal]
	)

	const prevAvailable = useMemo<boolean>(() => {
		return pageValue <= pagesTotal && pageValue - 1 > 0
	}, [pageValue, pagesTotal])
	const nextAvailable = useMemo<boolean>(() => {
		return pageValue < pagesTotal
	}, [pageValue, pagesTotal])

	function onValuesChange(limit: number, offset: number) {
		setOffset(offset)
		setLimit(limit)
	}

	return (
		<div className={s.paginationContainer}>
			{!isMobile && (
				<>
					<div
						className={s.group}
						style={{
							paddingLeft: '12px',
							borderRight: '1px solid #CED4DA',
						}}
					>
						Показывать:
						<Form.Select
							className={s.select}
							value={limit}
							onChange={(ev) =>
								onValuesChange
									? onValuesChange(
											parseInt(ev.target.value),
											0
									  )
									: null
							}
						>
							{itemsPerPageLimit.map((el, index) => (
								<option key={index}>{el}</option>
							))}
						</Form.Select>
					</div>
					<div className={s.separator} />
				</>
			)}

			<div
				className={s.group}
				style={{
					paddingLeft: '12px',
					flex: '1 1 auto',
					justifyContent: 'center',
				}}
			>
				{currentItems}
			</div>
			<div className={s.separator} />
			{!isMobile && (
				<>
					<div
						className={s.group}
						style={{
							paddingRight: isDesktop ? '12px' : '0',
							borderLeft: '1px solid #CED4DA',
						}}
					>
						<Form.Select
							className={s.select}
							onChange={(ev) =>
								onValuesChange
									? onValuesChange(
											limit,
											limit *
												(parseInt(ev.target.value) - 1)
									  )
									: null
							}
							value={pageValue}
						>
							{pages.map((_, i) => (
								<option key={i}>{i + 1}</option>
							))}
						</Form.Select>
						{!isMobile && `из ${pagesTotal} страниц`}
					</div>
					<div className={s.separator} />
				</>
			)}

			<div
				className={
					prevAvailable ? `${s.group} ${s.withhover}` : `${s.group}`
				}
				style={{
					width: '42px',
					cursor: prevAvailable ? 'pointer' : 'default',
					maxWidth: '42px',
					borderLeft: '1px solid #CED4DA',
				}}
				onClick={() => {
					if (prevAvailable) {
						if (onValuesChange) {
							onValuesChange(limit, offset - limit)
						}
					}
				}}
			>
				<i className={'bi bi-arrow-left'} />
			</div>
			<div className={s.separator} />
			<div
				className={
					nextAvailable ? `${s.group} ${s.withhover}` : `${s.group}`
				}
				style={{
					width: '42px',
					cursor: nextAvailable ? 'pointer' : 'default',
					maxWidth: '42px',
					borderLeft: '1px solid #CED4DA',
				}}
				onClick={() => {
					if (nextAvailable) {
						if (onValuesChange) {
							onValuesChange(limit, offset + limit)
						}
					}
				}}
			>
				<i className={'bi bi-arrow-right'} />
			</div>
		</div>
	)
}
