import React from 'react'
import {
	CodeConfirmationFormData,
	CodeResendData,
	RegistrationCredentialsFormData,
	RegistrationStagesType,
} from '../../model/types'
import { CredentialsInput } from './registration-stages/CredentialsInput'
import { CodeConfirmationWrapper } from './registration-stages/CodeConfirmationWrapper'
import { registrationValidationSchema } from '../../model/schemas'
import { FormikHelpers } from 'formik'
import { PhoneConfirmationVariant } from 'src/stores/userData'

type Props = {
	stage: RegistrationStagesType
	phoneNumber: string
	expirationDateKey: string
	lastConfirmationVariantKey: string

	onEnterCredentials: (
		values: RegistrationCredentialsFormData,
		formikHelpers: FormikHelpers<RegistrationCredentialsFormData>
	) => Promise<void>

	onResendConfirmationCode: (values: CodeResendData) => Promise<void>
	setStage: (stage: RegistrationStagesType) => void
	onConfirmCode: (
		values: CodeConfirmationFormData,
		formikHelpers: FormikHelpers<CodeConfirmationFormData>,
		confirmationVariant: PhoneConfirmationVariant
	) => Promise<any>
}
export const RegistrationStages = ({
	stage,
	lastConfirmationVariantKey,
	onEnterCredentials,
	expirationDateKey,
	setStage,
	onConfirmCode,
	onResendConfirmationCode,
	phoneNumber,
}: Props) => {
	return (
		<div>
			{stage === RegistrationStagesType.CredentialsInput && (
				<CredentialsInput
					onSubmit={onEnterCredentials}
					validationSchema={registrationValidationSchema}
				/>
			)}

			{stage === RegistrationStagesType.VerificationCode && (
				<CodeConfirmationWrapper
					lastConfirmationVariantKey={lastConfirmationVariantKey}
					expirationDateKey={expirationDateKey}
					onBack={() =>
						setStage(RegistrationStagesType.CredentialsInput)
					}
					onResendConfirmationCode={onResendConfirmationCode}
					onSubmit={onConfirmCode}
					phoneNumber={phoneNumber}
				/>
			)}
		</div>
	)
}
