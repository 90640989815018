import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import useCampaignData from 'src/stores/campaignData'
import moment from 'moment'
import { ReactComponent as InfoIcon } from 'src/assets/images/info-ring.svg'
import s from './startDisclaimer.module.scss'

export const CampaignStartDisclaimer = () => {
	const [campaign, campaignInterface] = useCampaignData()

	// Конвертируем строки дат в объекты Moment
	const moderationMoment = moment(campaign.moderation_date as string)
	const startMoment = moment(campaign.start_date)
	const currentMoment = moment()

	// общее количество дней от даты модерации до старта
	const totalDays = startMoment.diff(moderationMoment, 'days')

	// количество прошедших дней
	const elapsedDays = currentMoment.diff(moderationMoment, 'days')

	//  количество оставшихся дней
	const remainingDays = startMoment.diff(currentMoment, 'days')

	// процент прогресса
	const progressPercentage = Math.min((elapsedDays / totalDays) * 100, 100)

	// сообщение-дисклеймер в зависимости от оставшихся дней
	const disclaimerMessage =
		remainingDays >= 10
			? 'До запуска вашей рекламы остается совсем мало времени. Снять размещение или поменять период кампании вы можете не менее чем за 7 календарных дней до старта кампании.'
			: 'До запуска вашей рекламы остается совсем мало времени. Сейчас места бронируются у партнёров и вы не можете снять размещение или внести изменения в текущую кампанию.'
	return (
		<div className={s.disclaimer}>
			<div className={s.progressBarContainer}>
				<ProgressBar
					className={s.progressBar}
					now={progressPercentage}
				/>
				<p className={s.progressBarText}>
					{`До запуска рекламы осталось ${remainingDays} дней`}
				</p>
			</div>

			<div className={s.alert}>
				<div className="d-flex align-items-start h-100">
					<InfoIcon />
				</div>
				<p>{disclaimerMessage}</p>
			</div>
		</div>
	)
}
