import React, { CSSProperties } from 'react'
import Select, { GroupBase, Props } from 'react-select'
import s from './AdMarketSelect.module.scss'

function AdMarketSelect<
	Option,
	IsMulti extends boolean = false,
	Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group> & { styles?: React.CSSProperties }) {
	return (
		<Select
			{...props}
			className={props.className + ' ' + s.admarketselect}
			noOptionsMessage={() => <span>Ничего не найдено</span>}
			styles={{
				control: (baseStyles, state) => ({
					...baseStyles,
					borderColor: state.isFocused ? '#86b7fe' : '#CED4DA',
					boxShadow: state.isFocused
						? '0 0 0 0.25rem rgb(13 110 253 / 25%)'
						: 'none',
					outline: 'none',
					fontSize: '20px',
					paddingLeft: '6px',
					backgroundColor: state.isDisabled ? '#e9ecef' : 'white',
					borderRadius: props?.styles?.borderRadius,
				}),
				indicatorSeparator: (baseStyles, state) => ({
					...baseStyles,
					display: 'none',
				}),
				singleValue: (base, state) => ({
					...base,
					color: state.isDisabled ? '#6c757d' : '#212529',
				}),
			}}
			theme={(theme) => ({
				...theme,
				colors: {
					...theme.colors,
					primary25: '#F5F5F5',
					primary: '#007BFF',
				},
			})}
			placeholder={props.placeholder || 'Выберите значение'}
		/>
	)
}

export default AdMarketSelect
