import { Center } from '../components/outdoor-mass-coverage/modals/MassCoverageModalMap'
import { ymaps } from '../components/outdoor-mass-coverage/modals/MassCoverageYandexMap'

type InitMap = {
	ref: HTMLDivElement | null
	center: Center
	radius?: number
	onMapClick: (e: any) => void
}
export const initMap = (props: InitMap) => {
	let myMap = new ymaps.Map(props.ref, {
		center: [props.center.lat, props.center.lon],
		controls: [],
		// Уровень масштабирования. Допустимые значения:
		// от 0 (весь мир) до 19.
		zoom: 13,
	})
	myMap.events.add('click', props.onMapClick)
	return myMap
}
