import React, { FC } from 'react'
import useCampaignData from '../../../stores/campaignData'
import { useHistory } from 'react-router-dom'
import moment from 'moment/moment'
const MobileFixedHeader: FC = () => {
	const [campaign] = useCampaignData()
	const history = useHistory()
	return (
		<div
			style={{
				height: '40px',
				position: 'fixed',
				background: '#fff',
				width: '100vw',
				borderBottom: '1px solid #CED4DA',
				zIndex: 2,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				fontSize: '12px',
			}}
		>
			<div
				style={{
					position: 'absolute',
					height: '100%',
					padding: '0 13px',
					top: 0,
					left: 0,
					fontSize: '20px',
					color: '#6C757D',
					display: 'flex',
					alignItems: 'center',
					cursor: 'pointer',
				}}
				onClick={() => history.push('/campaigns')}
			>
				<i className="bi bi-arrow-left" />
			</div>
			{`РК «${campaign.name}» от ${moment(campaign.created_at).format(
				'DD.MM.YY'
			)}`}
		</div>
	)
}

export default MobileFixedHeader
