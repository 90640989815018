import React, { useCallback, useMemo } from 'react'
import { MobileFiltersKeyType } from 'src/components/_provider/Bookings/filters/booking-filters-mobile/BookingsSettings'
import { BookingCampaingFilterContent } from 'src/components/_provider/Bookings/filters/components/filter-content/contents/BookingCampaingFilterContent'
import { BookingCompanyFilterContent } from 'src/components/_provider/Bookings/filters/components/filter-content/contents/BookingCompanyFilterContent'
import { ProviderCampaignsFilterContent } from './ProviderCampaignsFilterContent'
import { ProviderCompaniesFilterContent } from './ProviderCompaniesFilterContent '
import {
	PlacementFilterKeyType,
	PlacementFilterType,
} from '../../constants/placementFilters'
import { ProviderRegionsFilterContent } from './ProviderRegionsFilterContent'

type ProviderMobileFilterContentProps<T> = {
	filterKey: PlacementFilterKeyType | undefined
	onClose: () => void
	setFilter: (key: PlacementFilterKeyType, value: Array<string>) => void
	onChange?: (key: PlacementFilterKeyType, value: Array<string>) => void
	filters?: Array<PlacementFilterType>
}

export const ProviderMobileFilterContent = <T extends {}>({
	filterKey,
	onClose,
	setFilter,
	onChange,
	filters,
}: ProviderMobileFilterContentProps<T>) => {
	const onFilterSubmit = (
		key: PlacementFilterKeyType,
		value: Array<string>
	) => {
		setFilter(key, value)
		onClose()
	}

	const getInitialFilterItems = (filterKey: PlacementFilterKeyType) => {
		const filter = filters?.find((filter) => filter.key === filterKey)

		if (filter && filter.filterItems.length > 0) {
			return filter.filterItems
		} else {
			return undefined
		}
	}

	const filterContent = useMemo(() => {
		switch (filterKey) {
			case 'advertising_company':
				return (
					<ProviderCampaignsFilterContent
						selectedItems={getInitialFilterItems(
							'advertising_company'
						)}
						onChange={onChange}
						onClose={onClose}
						onFilterSubmit={onFilterSubmit}
					/>
				)
			case 'company':
				return (
					<ProviderCompaniesFilterContent
						selectedItems={getInitialFilterItems('company')}
						onChange={onChange}
						onClose={onClose}
						onFilterSubmit={onFilterSubmit}
					/>
				)
			case 'placement_region':
				return (
					<ProviderRegionsFilterContent
						selectedItems={getInitialFilterItems(
							'placement_region'
						)}
						onChange={onChange}
						onClose={onClose}
						onFilterSubmit={onFilterSubmit}
					/>
				)
			default:
				return <></>
		}
	}, [filterKey])

	return filterContent
}
