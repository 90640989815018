import { AdvFormats } from './commonTypes'

export enum AdvSelectorState {
	Disabled = 'disabled',
	Added = 'added',
	Pending = 'pending',
}
export type AdvSelectorData = {
	key: AdvFormats
	icon: React.FunctionComponent<
		React.SVGProps<SVGSVGElement> & {
			title?: string
		}
	>

	title: string
	totalAmount: number
	state: AdvSelectorState
}

type SpecialAdvSelectorProps = {
	defaultDisabled?: boolean
	infoLink?: string
}

/**
 * Special props for some adv formats
 */
export type SpecialAdvSelectorData = Partial<
	Record<AdvFormats, SpecialAdvSelectorProps>
>
