import React, { useEffect, useMemo, useState } from 'react'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { withPage } from 'src/components/page/Page'
import useHeaderTitle from 'src/components/page/useHeaderTitle'
import { DisplayIssuesCampaign } from 'src/components/_moderator/types/ModerationRequestTypes'
import { usePushObjectToQueryParams, useQueryParams } from 'src/utils'
import useResponsive from 'src/utils/useResponsive'
import {
	ProviderFilter,
	ProviderPagination,
} from 'src/components/_provider/Placements/components'
import { Spinner } from 'react-bootstrap'
import { IssuesContent } from './common/components/IssuesContent'
import { NoIssues } from './common/components/NoIssues'

type DisplayIssuesQueryParams = {
	limit: string
	offset: string
	ordering: string
	q: string
}

const DisplayIssuesModeration = (props: any) => {
	useHeaderTitle('Проблемы с показами')
	const { params } = useQueryParams<Partial<DisplayIssuesQueryParams>>()
	const [moder, moderInterface] = useModerationData()
	const pushObjectToQuery = usePushObjectToQueryParams()

	const [displayIssues, setDisplayIssues] = useState<
		Array<DisplayIssuesCampaign>
	>([])
	const { isMobile } = useResponsive()

	const [searchString, setSearchString] = useState(() => {
		return params.q || ''
	})
	const [total, setTotal] = useState(0)

	const searchVariants = useMemo(() => {
		if (displayIssues) {
			return displayIssues
				?.filter((el) => el.name.includes(searchString))
				.slice(0, 10)
				.map((adv) => adv?.name || '')
		} else return []
	}, [displayIssues])

	const [paginationModel, setPaginationModel] = useState<{
		limit: number
		offset: number
	}>({
		limit: Number(params?.limit) || 10,
		offset: Number(params?.offset) || 0,
	})

	const setLimit = (limit: number) => {
		setPaginationModel((prev) => ({ ...prev, limit: limit }))
	}
	const setOffset = (offset: number) => {
		setPaginationModel((prev) => ({ ...prev, offset: offset }))
	}

	const handleFetchDisplayIssues = async (queryString: string) => {
		const result = await moderInterface.getDisplayIssues(queryString)
		if (result?.results) {
			setDisplayIssues(result?.results)
		}
		setTotal(result?.count || 0)
	}

	useEffect(() => {
		const unionObject: Partial<DisplayIssuesQueryParams> = {
			limit: String(paginationModel.limit),
			offset: String(paginationModel.offset),
			q: searchString,
		}

		Object.keys(unionObject)?.forEach((el) => {
			if (!unionObject[el]) delete unionObject[el]
		})
		const queryString = pushObjectToQuery(unionObject)

		handleFetchDisplayIssues(queryString)
	}, [searchString, paginationModel])

	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				maxHeight: `calc(100vh - 65px)`,
				flexDirection: 'column',
			}}
		>
			{!isMobile && (
				<div style={{ borderBottom: '1px solid #CED4DA' }}>
					<ProviderFilter
						isFiltersButton={false}
						onSetFilter={() => {}}
						allFiltersSelected={false}
						isCreateButton={false}
						isMapButton={false}
						onSearchStringChange={setSearchString}
						onDeleteFilter={() => {}}
						onResetFilters={() => {}}
						onFilterButtonClick={() => {}}
						searchVariants={searchVariants || []}
						searchString={searchString}
					/>
				</div>
			)}

			<>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						height: '100%',
						flex: '1 1 auto',
						overflowY: 'auto',
					}}
				>
					{!moder.isModerLoading && !displayIssues.length ? (
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								height: '100%',
							}}
						>
							<NoIssues />
						</div>
					) : (
						<IssuesContent
							displayIssues={displayIssues}
							isLoading={moder.isModerLoading}
						/>
					)}

					{moder.isModerLoading && (
						<Spinner
							animation="border"
							style={{
								color: '#007BFF',
								alignSelf: 'center',
								justifySelf: 'center',
								marginBottom: '25%',
							}}
						/>
					)}
				</div>

				{!isMobile && (
					<div
						style={{
							borderTop: '1px solid #CED4DA',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<div
							style={{
								maxWidth: 'max-content',
							}}
						>
							<ProviderPagination
								limit={paginationModel.limit}
								offset={paginationModel.offset}
								setLimit={setLimit}
								setOffset={setOffset}
								total={total}
							/>
						</div>
					</div>
				)}
			</>
		</div>
	)
}
export default withPage({ noSidebar: false, isProvider: false })(
	DisplayIssuesModeration
)
