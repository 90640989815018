import React from 'react'
import { PlacementsList, PlacementsListProps } from '../placements-list'
import {
	ProviderPagination,
	ProviderPaginationProps,
} from '../provider-pagination'
import useResponsive from 'src/utils/useResponsive'
import { PlacementsListContentHeader } from './PlacementsListContentHeader'
import { PlacementsImportExport } from '../placements-import-export/PlacementsImportExport'

type PlacementsListContentProps = {} & ProviderPaginationProps &
	PlacementsListProps

export const PlacementsListContent = (props: PlacementsListContentProps) => {
	const { isDesktop, isMobile } = useResponsive()
	return (
		<div
			id={'list-layout'}
			style={{
				maxWidth: isDesktop ? '50%' : '100%',
				height: '100%',
				width: isDesktop ? '50%' : '100%',
				display: 'flex',
				flexDirection: 'column',
				borderRight: isDesktop ? '1px solid #CED4DA' : 'none',
			}}
		>
			{/* {!isMobile && <PlacementsListContentHeader />} */}
			<div
				style={{ borderTop: '1px solid #CED4DA', padding: '18px 24px' }}
			>
				<PlacementsImportExport />
			</div>
			<PlacementsList {...props} />
			<ProviderPagination {...props} />
		</div>
	)
}
