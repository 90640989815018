const ActiveBillboard =
	'<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
	'                        <path fill-rule="evenodd" clip-rule="evenodd"' +
	'                        d="M5 13V7H19V13H13H11H5ZM11 15H4C3.44772 15 3 14.5523 3 14V6C3 5.44772 3.44772 5 4' +
	'                        5H20C20.5523 5 21 5.44772 21 6V14C21 14.5523 20.5523 15 20 15H13V19H11V15Z" fill="#212529"/>' +
	'                    </svg>'

const ActiveCityformat =
	'<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
	'                        <path fill-rule="evenodd" clip-rule="evenodd"' +
	'                        d="M8 16V5H16V16H13H11H8ZM11 18H7C6.44771 18 6 17.5523 6 17V4C6 3.44772 6.44772 3 7' +
	'                        3H17C17.5523 3 18 3.44772 18 4V17C18 17.5523 17.5523 18 17 18H13V21H11V18Z" fill="#212529"/>' +
	'                    </svg>'

const UnActiveBillboard =
	'<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
	'                        <path fill-rule="evenodd" clip-rule="evenodd"' +
	'                        d="M19 7V13H13H12H11V19H13V15H20C20.5523 15 21 14.5523 21 14V6C21 5.44772 20.5523 5 20' +
	'                        5H4C3.44772 5 3 5.44772 3 6V12H5V7H19Z"' +
	'                        fill="#6C757D"/>' +
	'                        <path d="M3.70718 21.7072L6.00009 19.4143L8.293 21.7072L9.70721 20.293L7.4143 18.0001L9.70718' +
	'                        15.7072L8.29297 14.293L6.00009 16.5858L3.70721 14.293L2.293' +
	'                        15.7072L4.58588 18.0001L2.29297 20.293L3.70718 21.7072Z" fill="#6C757D"/>' +
	'                        </svg>'

const UnActiveCityformat =
	'<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">' +
	'                    <path fill-rule="evenodd" clip-rule="evenodd"' +
	'                    d="M16 5V16H13H11V18V21H13V18H17C17.5523 18 18 17.5523 18 17V4C18 3.44772 17.5523 3 17' +
	'                    3H7C6.44772 3 6 3.44772 6 4V13H8V5H16Z" fill="#6C757D"/>' +
	'                    <path d="M3.70718 21.7072L6.00009 19.4143L8.293 21.7072L9.70721 20.293L7.4143' +
	'                    18.0001L9.70718 15.7072L8.29297 14.293L6.00009 16.5858L3.70721 14.293L2.293 15.7072L4.58588' +
	'                    18.0001L2.29297 20.293L3.70718 21.7072Z" fill="#6C757D"/>' +
	'                    </svg>'

export default function getPlacementIcon(
	type: 'cityformat' | 'billboard',
	is_active: boolean
): string {
	return is_active
		? type === 'cityformat'
			? ActiveCityformat
			: ActiveBillboard
		: type === 'cityformat'
		? UnActiveCityformat
		: UnActiveBillboard
}
