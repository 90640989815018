import React from 'react'
import { Container, Row } from 'react-bootstrap'

const InvalidProviderInvite: React.FC = () => {
	return (
		<Container style={{ marginTop: '20rem' }}>
			<Row className="justify-content-md-center">
				<Row
					md="auto"
					className="justify-content-md-center"
					style={{ fontSize: '60px' }}
				>
					️👀
				</Row>
				<Row md="auto" className="justify-content-md-center">
					К сожалению, данное приглашение больше не действительно.
				</Row>
				<Row md="auto" className="justify-content-md-center">
					Вы можете запросить новое приглашение у оператора.
				</Row>
			</Row>
		</Container>
	)
}
export default InvalidProviderInvite
