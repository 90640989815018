import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import useCampaignData from '../../../../../stores/campaignData'
import { BannerIcon, VideoIcon } from '../../Step2-ADformats/TVAd/TVSelector'
import useResponsive from '../../../../../utils/useResponsive'
import { Button, Modal } from 'react-bootstrap'
import BottomSheet from '../../../../UtilityComponents/Bottomsheet'
import { AdStreamMediaRequirements } from './TvUploadConstants'
import { channelAdv, teletargetFilters } from '../../../../../constants/api'
import DetailItem from '../../../../../utils/DetailItem'
import bannerVariantsData from '../../Step2-ADformats/TVAd/bannerVariants.data'
import ImageFullScreenWrapper from '../../../../UtilityComponents/ImageFullScreenWrapper'
import useBidRequest, { bidInfo } from '../CreateCreative/useBidRequest'
import CreativeFileInput from '../../../../UtilityComponents/CreativeFileInput'
import LegalInformation from 'src/utils/LegalInformation'
import s from '../DataUpload.module.scss'
import { PlacementType } from 'src/stores/ADMarketTypes.types'
import { TemplatesModal } from '../components'
import useNotificationData from 'src/stores/notificationsData'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { OrderLayoutModal } from '../components/order-layout-modal'
import { CreateCreativeFields } from '../CreateCreative/CreateCreativeForm'
import { NewContentCreatedStatusType } from 'src/stores/types/newContendCreatedType'
import { downloadByRequest } from 'src/utils'
import { EditVinstantModal } from 'src/components/_client/campaign-info/common/edit-vinstant-modal/EditVinstantModal'
import { useCampaignInfoStore } from 'src/components/_client/campaign-info/store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from 'src/components/_client/campaign-info/store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { EditVinstantProcessingWrapped } from 'src/components/_client/campaign-info/common/edit-vinstant-processing/EditVinstantProcessing'
import { InputExtraButtons } from '../components/outdoor/common/input-extra-buttons/InputExtraButtons'

interface ITvUploadItem {
	adName: 'teletarget' | 'adstream' | 'tel'
	teletarget_banner_type?: 'l_banner' | 'small_banner'
	onChange?: (fileUrl: string) => any
	ExtraButton?: React.ReactNode
}

const TvUploadItem: FC<ITvUploadItem> = ({
	adName,
	teletarget_banner_type,
	onChange,
	ExtraButton,
}) => {
	const { isMobile } = useResponsive()
	const [campaign, campaignInterface] = useCampaignData()
	const [editVinstantOpen, setEditVinstantOpen] = useState(false)

	const [_, notificationsInterface] = useNotificationData()
	const createdMaterialStatus = notificationsInterface.getNewContentCreated(
		'adstream'
	) as NewContentCreatedStatusType | undefined
	const editVinstantKey = String(campaign.adstream?.id)
	const adstreamVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'adstream_id',
			itemId: editVinstantKey,
		})
	)
	const [showDesktopModal, setShowDesktopModal] = useState<
		false | 'requirement'
	>(false)
	const [showBottomModal, setShowBottomModal] = useState<
		false | 'requirement'
	>(false)

	const [modalOpen, setModalOpen] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [loadingInProgress, setLoadingInProgress] = useState(false)

	const isTeletarget = adName === 'teletarget'
	const isVideo = adName === 'adstream'

	const file = isTeletarget
		? campaign.teletarget[
				teletarget_banner_type === 'small_banner' ? 'image' : 'l_image'
		  ]
		: campaign.adstream.image

	const { isHaveBidRequest } = useBidRequest()

	const bidInfo: bidInfo = {
		id: isTeletarget ? campaign.teletarget.id : campaign.adstream.id,
		type: isTeletarget ? 'teletarget' : 'adstream',
	}
	const ModalTitle = useMemo(() => {
		if (isMobile) {
			switch (showBottomModal) {
				case 'requirement':
					if (adName === 'teletarget') {
						if (teletarget_banner_type === 'small_banner')
							return 'Требования к баннеру 750 x 100 px'
						if (teletarget_banner_type === 'l_banner')
							return 'Требования к L-баннеру'
						return ''
					}
					if (adName === 'adstream')
						return 'Технические требования к видеофайлу'
					return ''
				default:
					return ''
			}
		} else {
			switch (showDesktopModal) {
				case 'requirement':
					if (adName === 'teletarget') {
						if (teletarget_banner_type === 'small_banner')
							return 'Требования к баннеру 750 x 100 px'
						if (teletarget_banner_type === 'l_banner')
							return 'Требования к L-баннеру'
						return ''
					}
					if (adName === 'adstream')
						return 'Технические требования к видеофайлу'
					return ''
				default:
					return ''
			}
		}
	}, [isMobile, showDesktopModal, showBottomModal, adName])
	const { addToast } = useContext(ToastsContext)

	const handleCreateMaterial = async () => {
		setModalOpen(true)
	}
	const closeModal = () => {
		setModalOpen(false)
	}

	const virtualPlacement = {
		name: 'adstream.ru',
		size: 'до 20 МБ',
		media_type: {
			video_formats: ['MP4'],
			image_formats: [] as string[],
			min_width: 1920,
			min_height: 1080,
			aspect_ratio: '16:9',
		},
		image: 'https://storage.yandexcloud.net/admarket-images/static-img/tv_4_11zon.jpg',
		placement_type: {
			name: 'AdStream-реклама',
		},
	} as Omit<PlacementType, 'placement_showing_counts'>
	const [isOrderLayoutModal, setIsOrderLayoutModal] = useState(false)

	const handleIsOrderLayoutOpen = () => {
		setModalOpen(false)
		setIsOrderLayoutModal(true)
	}
	const handleOrderLayoutSubmit = async (values: CreateCreativeFields) => {
		for (let key in values) {
			if (
				values[key] === null ||
				values[key] === undefined ||
				values[key] === ''
			) {
				delete values[key]
			}
		}

		await campaignInterface.createNewBidRequest(values, {
			adstream_id: campaign.adstream.id.toString(),
		})
		setIsOrderLayoutModal(false)
	}

	const handleStatusHasChanged = async (
		status: NewContentCreatedStatusType
	) => {
		if (status === 'start') {
			setLoadingInProgress(true)
		}
		if (status === 'end') {
			setLoadingInProgress(false)

			await campaignInterface.refetchSelected()

			addToast({
				text: `Загрузка контента для ${virtualPlacement.placement_type.name} завершена`,
				type: 'success',
			})

			notificationsInterface.setNewContendCreated({
				adstream: 'pending',
			})
		}
	}
	const handleUpload = () => {
		if (campaign.adstream.image) {
			downloadByRequest(
				`adv_companies/${campaign.id}/channel_creative/download/`,
				campaign.adstream.image
			)
		}
	}
	useEffect(() => {
		if (!!createdMaterialStatus) {
			handleStatusHasChanged(createdMaterialStatus)
		}
	}, [createdMaterialStatus])

	useEffect(() => {
		let timeoutId: NodeJS.Timeout

		if (isLoading) {
			timeoutId = setTimeout(() => {
				setIsLoading(false)
			}, 3000)
		}

		return () => clearTimeout(timeoutId)
	}, [isLoading])
	return (
		<>
			{modalOpen && (
				<TemplatesModal
					getOrderLayoutModal={handleIsOrderLayoutOpen}
					newContentKey="adstream"
					placement={virtualPlacement}
					instanceId={campaign.adstream.id.toString()}
					clsName={'adstreamadversting'}
					campaingId={campaign.id}
					closeModal={closeModal}
					modalOpen={modalOpen}
					screenResolution={'1920*1080'}
				/>
			)}
			<EditVinstantModal
				newContentKey={'adstream'}
				editVinstantLink={adstreamVinstantLink}
				onClose={() => setEditVinstantOpen(false)}
				open={editVinstantOpen}
			/>
			{isOrderLayoutModal && (
				<OrderLayoutModal
					onSubmit={handleOrderLayoutSubmit}
					open={isOrderLayoutModal}
					onClose={() => setIsOrderLayoutModal(false)}
				/>
			)}
			<div className={'d-flex flex-column'}>
				<div className={'d-flex justify-content-between mb-3'}>
					{isVideo && (
						<h5 className={'mb-0 w-75'} style={{ maxWidth: '90%' }}>
							Показ видеоролика при переключении канала
						</h5>
					)}
					{isTeletarget && (
						<h5 className={'mb-0'} style={{ maxWidth: '90%' }}>
							Показ всплывающего баннера при просмотре канала
						</h5>
					)}
					{isVideo && <VideoIcon />}
					{isTeletarget && <BannerIcon />}
				</div>
				<div>
					<div className={'mb-2'}>
						{isVideo && 'Загрузка видеоролика'}
						{isTeletarget &&
							teletarget_banner_type === 'small_banner' &&
							'Загрузка баннера'}
						{isTeletarget &&
							teletarget_banner_type === 'l_banner' &&
							'Загрузка L-баннера'}
					</div>
					{createdMaterialStatus === 'start' ? (
						<EditVinstantProcessingWrapped />
					) : (
						<CreativeFileInput
							ExtraButton={
								<InputExtraButtons
									onEditVinstant={
										adstreamVinstantLink
											? () => setEditVinstantOpen(true)
											: undefined
									}
									onUpload={handleUpload}
								/>
							}
							bidInfo={bidInfo}
							loadingInProgress={loadingInProgress}
							initialValue={{
								src: isVideo
									? campaign.adstream.image
									: campaign.teletarget[
											`${
												teletarget_banner_type ===
												'small_banner'
													? 'image'
													: 'l_image'
											}`
									  ],
								name: 'Текущее изображение',
							}}
							updateUrl={
								isVideo
									? channelAdv.params(campaign.id)
									: teletargetFilters.params(campaign.id)
							}
							removeMethod={'PATCH'}
							nameInBody={
								isTeletarget
									? teletarget_banner_type === 'small_banner'
										? 'image'
										: 'l_image'
									: 'image'
							}
							description={
								isVideo
									? '1920 × 1080 px, MP4, наличие звуковой дорожки, длительность от 5 до 30 секунд, не более 15 МБ'
									: teletarget_banner_type === 'small_banner'
									? '750 × 100 px, в формате PNG'
									: '1280 х 720 px, в формате PNG'
							}
							allowedExtension={
								isVideo ? ['MP4'] : ['PNG', 'MP4']
							}
							onFileLoad={(data) => {
								onChange?.(data.image)
								campaignInterface.refetchSelected()
							}}
							onFileDelete={async () =>
								await campaignInterface.refetchSelected()
							}
							maxSizeMb={15}
						/>
					)}
				</div>

				<div
					className={isMobile ? 'd-grid' : 'd-flex'}
					style={{
						gap: isMobile ? '8px' : '16px',
						marginTop: '16px',
					}}
				>
					{!isHaveBidRequest(bidInfo) && !file && (
						<Button
							variant={'light'}
							onClick={handleCreateMaterial}
							style={{
								width: '100%',
							}}
						>
							Создать материал
						</Button>
					)}

					<Button
						variant={'light'}
						onClick={() => {
							if (isMobile) {
								setShowBottomModal('requirement')
							} else {
								setShowDesktopModal('requirement')
							}
						}}
						style={{
							width: '100%',
						}}
					>
						Требования к материалу
					</Button>
				</div>
			</div>
			{/*Modals*/}
			{!isMobile && (
				<Modal
					show={showDesktopModal}
					onHide={() => setShowDesktopModal(false)}
					centered
					// size={'lg'}
					animation={true}
				>
					<Modal.Header closeButton>
						<Modal.Title as={'h5'} style={{ paddingLeft: '8px' }}>
							{ModalTitle}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className={'p-0'}>
						{showDesktopModal === 'requirement' && (
							<TvAdRequirements
								adName={adName}
								teletarget_banner_type={teletarget_banner_type}
							/>
						)}
						{/*{showDesktopModal === 'mediaplan' && (*/}
						{/*	<TvAdMediaPlan adName={adName} />*/}
						{/*)}*/}
					</Modal.Body>
				</Modal>
			)}
			{isMobile && (
				<BottomSheet
					show={Boolean(showBottomModal)}
					onHide={() => setShowBottomModal(false)}
					fullSize={true}
					bgOpacity={0.5}
					touchEvents={true}
				>
					{showBottomModal === 'requirement' && (
						<TvAdRequirements
							adName={adName}
							modalTitle={ModalTitle}
							teletarget_banner_type={teletarget_banner_type}
						/>
					)}
					{/*{showBottomModal === 'mediaplan' && (*/}
					{/*	<TvAdMediaPlan*/}
					{/*		adName={adName}*/}
					{/*		modalTitle={ModalTitle}*/}
					{/*	/>*/}
					{/*)}*/}
				</BottomSheet>
			)}
		</>
	)
}

const TvAdRequirements: React.FC<ITvUploadItem & { modalTitle?: string }> = ({
	adName,
	modalTitle,
	teletarget_banner_type,
}) => {
	const { isMobile } = useResponsive()
	const [{ teletarget }] = useCampaignData()
	const banner_data =
		teletarget_banner_type === 'small_banner'
			? bannerVariantsData.small_banner
			: bannerVariantsData.l_banner
	const fontSize = isMobile ? '14px' : '16px'
	if (adName === 'adstream')
		return (
			<div className={'position-relative'}>
				{isMobile && (
					<div
						style={{
							position: 'sticky',
							top: 0,
							left: 0,
							padding: '16px',
							borderBottom: '1px solid #CED4DA',
							backgroundColor: '#fff',
						}}
					>
						<h6 className={'mb-0'}>{modalTitle}</h6>
					</div>
				)}
				<div
					style={isMobile ? { padding: '16px' } : { padding: '24px' }}
				>
					<h5 className={'mb-3'}>Видео</h5>
					{AdStreamMediaRequirements.video.map((el, index, array) => (
						<div
							className={'w-100 d-flex justify-content-between'}
							key={index}
							style={{
								borderBottom:
									index === array.length - 1
										? ''
										: '1px solid #CED4DA',
								paddingBottom: '8px',
								marginTop: '8px',
								fontSize: fontSize,
							}}
						>
							<span
								className={'text-secondary'}
								style={{ maxWidth: '50%' }}
							>
								{el.name}
							</span>
							<span
								style={{ textAlign: 'right', maxWidth: '50%' }}
							>
								{el.value}
							</span>
						</div>
					))}
					<h5 className={'mb-3 mt-4'}>Аудио</h5>
					{AdStreamMediaRequirements.audio.map((el, index, array) => (
						<div
							className={'w-100 d-flex justify-content-between'}
							key={index}
							style={{
								borderBottom:
									index === array.length - 1
										? ''
										: '1px solid #CED4DA',
								paddingBottom: '8px',
								marginTop: '8px',
								fontSize: fontSize,
							}}
						>
							<span
								className={'text-secondary'}
								style={{ maxWidth: '50%' }}
							>
								{el.name}
							</span>
							<span
								style={{ textAlign: 'right', maxWidth: '50%' }}
							>
								{el.value}
							</span>
						</div>
					))}
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
						}}
					></div>

					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Юридическая информация:
						</span>
					</div>
					<div className={s.legalInformationWrapper}>
						<LegalInformation />
					</div>
				</div>
			</div>
		)
	if (adName === 'teletarget')
		return (
			<div className={'position-relative'}>
				{isMobile && (
					<div
						style={{
							position: 'sticky',
							top: 0,
							left: 0,
							padding: '16px',
							borderBottom: '1px solid #CED4DA',
							backgroundColor: '#fff',
						}}
					>
						<h6 className={'mb-0'}>{modalTitle}</h6>
					</div>
				)}
				<div
					style={isMobile ? { padding: '16px' } : { padding: '24px' }}
				>
					<ImageFullScreenWrapper>
						<img
							src={banner_data.guideImage}
							alt={''}
							style={isMobile ? { maxWidth: '100%' } : {}}
						/>
					</ImageFullScreenWrapper>
					<div
						className={'mt-4 mb-2'}
						style={{
							width: '492px',
							height: '72px',
							left: '24px',
							top: '325px',
							fontFamily: 'Inter',
							fontStyle: 'normal',
							fontWeight: 400,
							fontSize: '16px',
							lineHeight: '24px',
							letterSpacing: '-0.03em',
							color: '#212529',
						}}
					>
						{banner_data.requirements.desc}
					</div>
					<DetailItem
						left={'Длительность:'}
						right={banner_data.requirements.duration}
						bottomDivider={false}
						leftFixWidth={'160px'}
					/>
					<DetailItem
						left={'Формат:'}
						right={banner_data.requirements.format}
						bottomDivider={false}
						leftFixWidth={'160px'}
					/>
					<DetailItem
						left={'Что включает:'}
						right={
							<div className={'d-flex flex-column gap-2'}>
								{teletarget_banner_type === 'small_banner' ? (
									<p>
										{
											bannerVariantsData.small_banner
												.requirements.includes[0]
										}
									</p>
								) : (
									banner_data.requirements.includes.map(
										(e) => <li key={e}>{e}</li>
									)
								)}
							</div>
						}
						bottomDivider={false}
						leftFixWidth={'160px'}
					/>
					<DetailItem
						left={'Доступность:'}
						right={banner_data.requirements.accessibility}
						bottomDivider={false}
						leftFixWidth={'160px'}
					/>
					<DetailItem
						left={'Юридическая информация:'}
						right={''}
						bottomDivider={false}
						leftFixWidth={'200px'}
					/>
					<div className={s.legalInformationWrapper}>
						<LegalInformation />
					</div>

					<span className={'text-secondary'}>
						Дополнительная информация:
					</span>
					<p className={s.dopInfo}>
						Ознакомиться с требованиями вы также можете по{' '}
						<a href="https://www.21outdoor.ru/upload/medialibrary/fa3/21_vek_TV%20%D0%A2%D0%A2_6x3_vinyl_bez_polei.jpg">
							ссылке.
						</a>
					</p>
				</div>
			</div>
		)
	return <></>
}

// const TvAdMediaPlan: React.FC<ITvUploadItem & { modalTitle?: string }> = ({
// 	adName,
// 	modalTitle,
// }) => {
// 	const { isMobile } = useResponsive()
// 	const [campaign] = useCampaignData()
// 	const [adStreamChannels, setAdStreamChannels] = useState<any>()
// 	const { teletarget, adstream } = campaign
// 	useEffect(() => {
// 		if (adName !== 'adstream') return
// 		;(async () => {
// 			fetchAdstreamInfo().then((res) => setAdStreamChannels(res))
// 		})()
// 	}, []) // eslint-disable-line react-hooks/exhaustive-deps
// 	const computedValues = useMemo<{
// 		channels: string[]
// 	}>(() => {
// 		const c: string[] = []
// 		if (adName !== 'adstream') return { channels: c }
// 		campaign.adstream.selection_ids.forEach((id) => {
// 			const el = adStreamChannels?.sections.find((s) => s.id === id)
// 			if (el) c.push(el.name)
// 		})
// 		if (c.length !== 0) return { channels: c }
// 		return { channels: c }
// 	}, [adStreamChannels, adName]) // eslint-disable-line react-hooks/exhaustive-deps
// 	const h5Style = {
// 		marginBottom: 0,
// 		fontSize: '16px',
// 	}
// 	if (adName === 'adstream')
// 		return (
// 			<div className={'position-relative'}>
// 				{isMobile && (
// 					<div
// 						style={{
// 							position: 'sticky',
// 							top: 0,
// 							left: 0,
// 							padding: '16px',
// 							borderBottom: '1px solid #CED4DA',
// 							backgroundColor: '#fff',
// 						}}
// 					>
// 						<h6 className={'mb-0'}>{modalTitle} </h6>
// 					</div>
// 				)}
// 				<div
// 					style={isMobile ? { padding: '16px' } : { padding: '24px' }}
// 				>
// 					<section>
// 						<RowLabelValue
// 							label={'Частота показов в час:'}
// 							value={adstream.frequency}
// 							isFirst={true}
// 						/>
// 						<RowLabelValue
// 							label={'Регионы:'}
// 							value={spreadArray(
// 								campaign.show_regions.map((r) => r.title)
// 							)}
// 						/>
// 						<RowLabelValue
// 							label={'Каналы:'}
// 							value={spreadArray(computedValues.channels)}
// 						/>
// 					</section>
// 				</div>
// 			</div>
// 		)
// 	if (adName === 'teletarget')
// 		return (
// 			<div className={'position-relative'}>
// 				{isMobile && (
// 					<div
// 						style={{
// 							position: 'sticky',
// 							top: 0,
// 							left: 0,
// 							padding: '16px',
// 							borderBottom: '1px solid #CED4DA',
// 							backgroundColor: '#fff',
// 						}}
// 					>
// 						<h6 className={'mb-0'}>{modalTitle}</h6>
// 					</div>
// 				)}
// 				<div
// 					style={isMobile ? { padding: '16px' } : { padding: '24px' }}
// 				>
// 					<div
// 						className={'d-flex flex-column'}
// 						style={{ gap: '8px', marginBottom: '24px' }}
// 					>
// 						<DetailItem
// 							left={'Пол:'}
// 							right={spreadArray(
// 								campaign.genders.map((g) => g.title)
// 							)}
// 							leftFixWidth={'200px'}
// 							bottomDivider={false}
// 							fontSize={'16px'}
// 							padding={1}
// 						/>
// 						<DetailItem
// 							left={'Возраст:'}
// 							right={spreadArray(
// 								campaign.ages.map((a) => a.title)
// 							)}
// 							leftFixWidth={'200px'}
// 							bottomDivider={false}
// 							fontSize={'16px'}
// 							padding={1}
// 						/>
// 						<DetailItem
// 							left={'Регион показа:'}
// 							right={spreadArray(
// 								campaign.show_regions.map((r) => r.title)
// 							)}
// 							leftFixWidth={'200px'}
// 							bottomDivider={false}
// 							fontSize={'16px'}
// 							padding={1}
// 						/>
// 						{isMobile ? (
// 							<RowLabelValue
// 								label={'Формат баннера:'}
// 								value={
// 									<div>
// 										{(() => {
// 											const banner =
// 												bannerVariants[
// 													campaign.teletarget
// 														.image_type
// 												]
// 											if (!banner) return null
// 											return (
// 												<div>
// 													{banner.title}
// 													<ImageFullScreenWrapper>
// 														<div
// 															style={{
// 																width: '280px',
// 																marginTop:
// 																	'8px',
// 																overflow:
// 																	'hidden',
// 															}}
// 														>
// 															<img
// 																src={
// 																	banner.guideImage
// 																}
// 																alt={banner.key}
// 																style={{
// 																	objectFit:
// 																		'cover',
// 																	width: '280px',
// 																}}
// 															/>
// 														</div>
// 													</ImageFullScreenWrapper>
// 												</div>
// 											)
// 										})()}
// 									</div>
// 								}
// 							/>
// 						) : (
// 							<DetailItem
// 								left={'Формат баннера:'}
// 								right={
// 									<div>
// 										{(() => {
// 											const banner =
// 												bannerVariants[
// 													campaign.teletarget
// 														.image_type
// 												]
// 											if (!banner) return null
// 											return (
// 												<div>
// 													{banner.title}
// 													<ImageFullScreenWrapper>
// 														<div
// 															style={{
// 																width: '280px',
// 																marginTop:
// 																	'8px',
// 																overflow:
// 																	'hidden',
// 															}}
// 														>
// 															<img
// 																src={
// 																	banner.guideImage
// 																}
// 																alt={banner.key}
// 																style={{
// 																	objectFit:
// 																		'cover',
// 																	width: '280px',
// 																}}
// 															/>
// 														</div>
// 													</ImageFullScreenWrapper>
// 												</div>
// 											)
// 										})()}
// 									</div>
// 								}
// 								leftFixWidth={'200px'}
// 								bottomDivider={false}
// 								fontSize={'16px'}
// 								padding={1}
// 							/>
// 						)}
// 						<DetailItem
// 							left={'Бюджет кампании:'}
// 							right={IntlFormatter.format(
// 								campaign.teletarget.amount,
// 								false
// 							)}
// 							leftFixWidth={'200px'}
// 							bottomDivider={false}
// 							fontSize={'16px'}
// 							padding={1}
// 						/>
// 					</div>
// 				</div>
// 			</div>
// 		)
//
// 	return <></>
// }

export default TvUploadItem
