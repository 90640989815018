import React, { useMemo } from 'react'
import { PlacementType } from 'src/stores/ADMarketTypes.types'
import useResponsive from 'src/utils/useResponsive'
import { TemplateInfoHeader } from './TemplateInfoHeader'
import { TemplateInfoPropsList } from './TemplateInfoPropsList'
import { motion } from 'framer-motion'
import mockImage from '../../../../../../../assets/images/1920x1080.png'

type TemplateInfoPropsType = {
	placement: Omit<PlacementType, 'placement_showing_counts'>
	isCollapsed: boolean
}

const contentType = (
	placement: Omit<PlacementType, 'placement_showing_counts'>
) => {
	if (
		placement.media_type.image_formats.length !== 0 &&
		placement.media_type.video_formats.length === 0
	)
		return 'Изображение'
	if (
		placement.media_type.image_formats.length === 0 &&
		placement.media_type.video_formats.length !== 0
	)
		return 'Видео'
	if (
		placement.media_type.image_formats.length !== 0 &&
		placement.media_type.video_formats.length !== 0
	)
		return 'Изображение или видео'
}

const availableFormats = (
	placement: Omit<PlacementType, 'placement_showing_counts'>
): string => {
	const availableFormatsList = [
		...placement.media_type.image_formats,
		...placement.media_type.video_formats,
	]

	if (availableFormatsList.length === 0) return ''
	if (availableFormatsList.length === 1) return availableFormatsList[0]
	return availableFormatsList.join(', ')
}

export const TemplateInfo = ({
	placement,
	isCollapsed,
}: TemplateInfoPropsType) => {
	const { isMobile } = useResponsive()

	const displayProperties = useMemo(() => {
		return {
			Размер: placement.size,
			Тип: contentType(placement),
			'Допустимый формат': availableFormats(placement),
			Ширина: `${placement.media_type.min_width} px`,
			Высота: `${placement.media_type.min_height} px`,
			'Соотношение сторон': placement.media_type.aspect_ratio,
		}
	}, [placement])

	return (
		<motion.div
			style={{
				height: isCollapsed ? 0 : 'auto',
				width: isCollapsed ? 0 : '100%',
				maxWidth: '25rem',
				minHeight: isMobile ? 'auto' : '100%',
				borderRight: isMobile ? 'none' : '1px solid #CED4DA',
			}}
		>
			<TemplateInfoHeader
				imgURL={
					placement.image ||
					'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
				}
				name={placement.placement_type.name}
				address={placement.name}
			/>

			<TemplateInfoPropsList displayProperties={displayProperties} />
		</motion.div>
	)
}
