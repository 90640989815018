import React from 'react'
import s from './publictTransportSelector.module.scss'
import cn from 'classnames'
import { ReactComponent as Ok } from 'src/assets/images/ok.svg'
import { ReactComponent as MCR } from 'src/assets/images/railway-2.svg'
import { ReactComponent as MCD } from 'src/assets/images/transport.svg'
import { PublicTransportVariants } from 'src/components/_client/create-campaign/Step2-ADformats/new-adformats-step/types'

type Props = {
	title: string
	selectorKey: PublicTransportVariants
	isSelected: boolean
	description: string
	extraInfo: string
	onClick: (variant: PublicTransportVariants) => void
	disabled
	isLoading: boolean
}

export const PublicTransportVariantSelector = ({
	description,
	isSelected,
	onClick,
	selectorKey,
	title,
	disabled,
	extraInfo,
	isLoading,
}: Props) => {
	const handleSelectorClick = () => {
		if (disabled) return
		onClick(selectorKey)
	}
	const VariantIcon = selectorKey === PublicTransportVariants.MCR ? MCR : MCD
	return (
		<div
			className={cn({
				[s.format_selected]: isSelected,
				[s.format]: !isSelected,
				[s.format_disabled]: disabled || isLoading,
			})}
			onClick={handleSelectorClick}
		>
			<div className={cn(s.icon)}>
				{isSelected ? <Ok /> : <VariantIcon />}
			</div>
			<div className={s.title}>
				<div>{title}</div>

				<p>{description}</p>
				<p>{extraInfo}</p>
			</div>
		</div>
	)
}
