import React, { FC, useState } from 'react'
import { BookingType } from '../../../../stores/ADMarketTypes.types'
import s from './BookingAd.module.scss'
import { Button } from 'react-bootstrap'
import BookingStateView from './BookingStateView'
import useCampaignData from '../../../../stores/campaignData'
import DetailItem from '../../../../utils/DetailItem'
import ImageFullScreenWrapper from '../../../UtilityComponents/ImageFullScreenWrapper'
import IntlFormatter from '../../../../utils/intl-money-formatter'
import SmallBottomSheet from '../../../UtilityComponents/SmallBottomSheet'
import MobileBookingControl from './MobileBookingControl'

interface IMobileBookingCard {
	booking: BookingType
}

const MobileBookingCard: FC<IMobileBookingCard> = ({ booking }) => {
	const [campaign] = useCampaignData()
	const [showBottomSheet, setShowBottomSheet] = useState(false)
	const media =
		campaign.media.find((m) => m.booking_id === booking.id) ||
		campaign.media[0]
	return (
		<div className={s.bookingCardMobile}>
			<DetailButton onClick={() => setShowBottomSheet(true)} />
			<ImageFullScreenWrapper>
				<img
					src={
						booking.placement.image ||
						'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
					}
					alt={booking.placement.name}
					style={{
						width: '72px',
						height: '72px',
						objectFit: 'cover',
						borderRadius: '4px',
						marginBottom: '8px',
					}}
				/>
			</ImageFullScreenWrapper>
			<h5 className={'m-0'}> {booking.placement.placement_type.name}</h5>
			<h5 className={'mb-2'}> {booking.placement.name}</h5>
			<h5 className={'mb-3'}>
				{IntlFormatter.format(booking.placement_showing_count.ac_price)}
			</h5>

			<BookingStateView booking={booking} media={media} />
			<DetailItem
				left={<h5 style={{ fontSize: '14px' }}>Всего показов:</h5>}
				right={
					<h5 style={{ fontSize: '14px' }}>
						{booking.placement_showing_count.ac_days_count}
					</h5>
				}
				bottomDivider={false}
				leftSecondary={false}
			/>
			{/*BottomSheet*/}
			<SmallBottomSheet
				show={showBottomSheet}
				onHide={() => setShowBottomSheet(false)}
				height={'160px'}
				touchHeight={'25px'}
			>
				<MobileBookingControl booking={booking} />
			</SmallBottomSheet>
		</div>
	)
}

export const DetailButton = ({ onClick }: { onClick: () => any }) => (
	<Button variant={'light'} className={s.mobileDetailsBtn} onClick={onClick}>
		<svg
			width="4"
			height="14"
			viewBox="0 0 4 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.33337 1.66634C3.33337 2.40272 2.73642 2.99967 2.00004 2.99967C1.26366 2.99967 0.666708 2.40272 0.666708 1.66634C0.666708 0.929961 1.26366 0.333008 2.00004 0.333008C2.73642 0.333008 3.33337 0.929961 3.33337 1.66634ZM3.33337 12.333C3.33337 13.0694 2.73642 13.6663 2.00004 13.6663C1.26366 13.6663 0.666707 13.0694 0.666707 12.333C0.666708 11.5966 1.26366 10.9997 2.00004 10.9997C2.73642 10.9997 3.33337 11.5966 3.33337 12.333ZM3.33337 6.99967C3.33337 7.73605 2.73642 8.33301 2.00004 8.33301C1.26366 8.33301 0.666708 7.73605 0.666708 6.99967C0.666708 6.26329 1.26366 5.66634 2.00004 5.66634C2.73642 5.66634 3.33337 6.26329 3.33337 6.99967Z"
				fill="#212529"
			/>
		</svg>
	</Button>
)

export default MobileBookingCard
