import React, { useEffect, useState } from 'react'
import { withPage } from '../../page/Page'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Spinner } from 'react-bootstrap'
import useCampaignData from '../../../stores/campaignData'
import CampaignInfo from './CampaignInfo'
import { CreateCampaignPage } from '../create-campaign/CreateCampaignPage'
import { Loader } from 'src/components/_provider/Bookings/content/list/Loader'
import { useCampaignInfoStore } from './store/campaignInfo.store'
import { useTechnicalRequirementsStore } from 'src/stores/technical-requirements-store'
import { CampaignInfoType } from 'src/stores/ADMarketTypes.types'

const CampaignInfoController = (props) => {
	const [isLoading, setIsLoading] = useState(true)
	const [campaign, campaignInterface] = useCampaignData()
	const { id } = useParams<{ id?: string }>()

	//Фетчим данные по кампании с таким айдишником
	useEffect(() => {
		;(async () => {
			const refreshPayment = window.location.search.includes('payment')
			const result = (await campaignInterface.fetchCampaign(
				id,
				refreshPayment
			)) as CampaignInfoType

			if (refreshPayment) await campaignInterface.refetchList()
			setIsLoading(false)
		})()
	}, [id]) // eslint-disable-line react-hooks/exhaustive-deps

	//TODO в проекте много лоадеров по возможности надо заменить на готовый компонет <Loader />
	if (isLoading) return <Loader />

	if (campaign?.id && campaign.state === 'draft')
		return <CreateCampaignPage />

	if (campaign?.id) return <CampaignInfo />

	return <CampaignNotFound />
}

const CampaignNotFound = () => {
	const history = useHistory()
	return (
		<div
			className={
				'd-flex flex-column align-items-center justify-content-center'
			}
			style={{ height: '100%', textAlign: 'center' }}
		>
			<div style={{ fontSize: '40px', marginBottom: '24px' }}>👀</div>
			<h1 style={{ marginBottom: '16px' }}>
				Рекламная кампания не найдена
			</h1>
			<span className={'text-secondary'} style={{ marginBottom: '40px' }}>
				Скорее всего, она была удалена из системы Admarket
			</span>
			<Button
				onClick={() => {
					history.push('/')
				}}
			>
				На главную
			</Button>
		</div>
	)
}

export default withPage({ noSidebar: false, isProvider: false })(
	CampaignInfoController
)
