import React from 'react'
import s from './commonStyles.module.scss'
import cn from 'classnames'
import { Button } from 'react-bootstrap'

type CreateReportSelectedCountProps = {
	count: number
	onClick: () => void
	isAllSelected: boolean
	isCounter?: boolean
	style?: React.CSSProperties
	buttonStyle?: React.CSSProperties
}

export const CreateReportSelectedCount = ({
	count,
	onClick,
	isAllSelected,
	isCounter = true,
	style,
	buttonStyle,
}: CreateReportSelectedCountProps) => {
	return (
		<div
			id={'display_selected_count'}
			className={cn(s.selectedCount)}
			style={style}
		>
			{isCounter && (
				<div className={cn(s.selectedCountView)}>
					<div>Выбрано: </div>
					<span>{count}</span>
				</div>
			)}

			<Button
				onClick={onClick}
				className={cn(s.selectedCountButton)}
				variant="text"
				style={buttonStyle}
			>
				{isAllSelected ? 'Очистить все' : 'Выбрать все'}
			</Button>
		</div>
	)
}
