import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { CampaignInfoTypeBookingsSeparation } from 'src/stores/ADMarketTypes.types'
import useCampaignData from 'src/stores/campaignData'
import { Adstream } from './tv-variants/adstream/Adstream'
import { AdvFormats, TVVariants } from '../../../../types'
import { Teletarget } from './tv-variants/teletarget/Teletarget'
import { AdvFormatNav } from '../../../common/adv-format-nav/AdvFormatNav'
import { useAdformatsStore } from 'src/components/_client/create-campaign/Step2-ADformats/adformats-store/adformats.store'
import {
	massCoverageNoItemsErrorId,
	outdoorNoItemsErrorId,
} from '../../../../constants'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { AdvFormatVariantsContainer } from '../../../common/adv-format-variants-container/AdvFormatVariantsContainer'
import { TvPresentationCard } from './tv-presentation-card/TvPresentationCard'
import { authFetch } from 'src/utils/authFetch'
import { AdvFormatContent } from '../../../common/adv-format-content/AdvFormatContent'
import { AdvFormatContainer } from '../../../common/adv-format-container/AdvFormatContainer'

const initializeTvVariants = (
	campaign: CampaignInfoTypeBookingsSeparation
): TVVariants[] => {
	const variants = [] as TVVariants[]

	if (campaign.adstream.is_selected) {
		variants.push(TVVariants.Adstream)
	}
	if (campaign.teletarget.is_selected) {
		variants.push(TVVariants.Teletarget)
	}

	return variants
}

const TvVariantsForRender: Record<TVVariants, (props: any) => JSX.Element> = {
	adstream: Adstream,
	teletarget: Teletarget,
}

const toggleAdstreamSelectedState = async (data: {
	campaignId: string
	isSelected: boolean
}) => {
	await authFetch({
		url: `/core/api/v1/adv_companies/${data.campaignId}/channel-adv/`,
		method: 'PATCH',
		body: {
			is_selected: data.isSelected,
		},
	})
}

type Props = {
	goToSelectors: () => void
}
export const TvAdv = ({ goToSelectors }: Props) => {
	const [campaign, campaignInterface] = useCampaignData()

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const setAdFormatErrors = useAdformatsStore(
		(state) => state.setAdFormatErrors
	)
	const { addToast } = useContext(ToastsContext)
	const removeAdFormatErrorByErrorId = useAdformatsStore(
		(state) => state.removeAdFormatErrorByErrorId
	)
	const [tvVariants, setTvVariants] = useState<TVVariants[]>(() =>
		initializeTvVariants(campaign)
	)

	const handleVariantClick = useCallback(
		(variant: TVVariants) => {
			if (tvVariants.includes(variant)) {
				//действия при удалении варианта наружки

				//убрать ошибки из максимального охвата
				if (variant === TVVariants.Adstream) {
					removeAdFormatErrorByErrorId(
						'massCoverage',
						massCoverageNoItemsErrorId
					)
				}

				//убрать ошибки из гарантированных показов
				if (variant === TVVariants.Teletarget) {
					removeAdFormatErrorByErrorId(
						'garant',
						outdoorNoItemsErrorId
					)
				}

				//убрать компонент из стэка форм
				setTvVariants(tvVariants.filter((v) => v !== variant))
			} else {
				setTvVariants([variant, ...tvVariants])
			}
		},
		[tvVariants]
	)

	const handleAddFormat = async () => {
		try {
			setIsLoading(true)
			await toggleAdstreamSelectedState({
				campaignId: campaign.id,
				isSelected: tvVariants.includes(TVVariants.Adstream),
			})
			await campaignInterface.refetchSelected()
			addToast({
				text: 'ТВ-реклама изменена',
				type: 'success',
			})
			goToSelectors()
		} catch (e) {
			addToast({
				text: 'Ошибка изменения ТВ-рекламы',
				type: 'danger',
			})
		} finally {
			setIsLoading(false)
		}
	}
	const RenderedTvVariants = useMemo(
		() =>
			tvVariants.map((variant) => {
				return React.createElement(TvVariantsForRender[variant], {
					key: variant,
				})
			}),
		[tvVariants]
	)

	useEffect(() => {
		return () => {
			setAdFormatErrors('adstream', null)
		}
	}, [])
	return (
		<AdvFormatContainer>
			<AdvFormatContent>
				<TvPresentationCard
					onSelectorClick={handleVariantClick}
					selectedVariants={tvVariants}
				/>

				<AdvFormatVariantsContainer>
					{RenderedTvVariants}
				</AdvFormatVariantsContainer>
			</AdvFormatContent>

			<AdvFormatNav
				advFormat={AdvFormats.TV}
				advAddDisabled={isLoading}
				onBack={goToSelectors}
				onAddFormat={handleAddFormat}
				isFormatChanged={true}
				totalAdvVariants={tvVariants.length}
			/>
		</AdvFormatContainer>
	)
}
