import { Button } from 'react-bootstrap'
import ls from '../style.module.scss'
import { FormLabelTooltipCommon } from 'src/components/UtilityComponents'
import React from 'react'
import { NewContentCreatedKeysType } from 'src/stores/types/newContendCreatedType'
import { AdvStateType, ModerationModalButton } from './ModerationModalButton'

type AdvItemActionsProps = {
	setDetailedItem: () => void
	state: AdvStateType
	setAdvItemActionType: (
		action: 'moderation' | 'adjustment' | 'reject'
	) => void
}
export const AdvItemActions = ({
	setDetailedItem,
	state,
	setAdvItemActionType,
}: AdvItemActionsProps) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<Button variant={'light'} onClick={setDetailedItem}>
				Подробнее о месте
			</Button>

			<div className={ls.tooltipButtons}>
				<ModerationModalButton
					setAdvItemActionType={setAdvItemActionType}
					state={state}
					isModalButton={false}
				/>
			</div>
		</div>
	)
}
