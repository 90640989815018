import React, { useEffect, useMemo, useState } from 'react'
import { withPage } from '../../page/Page'
import useResponsive from '../../../utils/useResponsive'
import useCampaignData, {
	CampaignsListSortField,
} from '../../../stores/campaignData'
import HeaderWithTabs from './HeaderWithTabs'
import s from './CampaignList.module.scss'
import { Button, Container, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import useHeaderTitle from '../../page/useHeaderTitle'
import ClientStatistics from './ClientStatistics'
import { CampaignStates } from 'src/stores/ADMarketTypes.types'
import { ProviderPagination } from 'src/components/_provider/Placements/components'
import { usePushObjectToQueryParams, useQueryParams } from 'src/utils'

export function useFilteredCampaigns(campaigns, filter) {
	return useMemo(() => {
		if (!campaigns) return []
		if (filter === 'total') return campaigns
		return campaigns.filter((campaign) => campaign.state === filter)
	}, [campaigns, filter])
}
type CampaignsListQueryParams = {
	state: CampaignsListTabsKeys
	limit: string
	offset: string
	order_by: CampaignsListSortField
	order: 'asc' | 'desc'
}
export type CampaignsListTabsKeys = CampaignStates | 'total'

const CampaignsList = () => {
	const savedTab = localStorage.getItem('campaignsFilter') as CampaignStates
	useHeaderTitle('Рекламные кампании')

	const { params } = useQueryParams<Partial<CampaignsListQueryParams>>()
	const pushObjectToQuery = usePushObjectToQueryParams()

	const { isMobile, isTablet } = useResponsive()
	const history = useHistory()
	const [campaign, campaignInterface] = useCampaignData()
	const campaignsList = campaignInterface.list()

	const onCreateCampaign = async () => {
		campaignInterface.removeSelectedCampaign()
		history.push('/create-campaign/')
	}

	const [sortField, setSortField] = useState<
		CampaignsListSortField | undefined
	>(params.order_by || undefined)
	const [selectedTab, setSelectedTab] = useState<CampaignsListTabsKeys>(
		params?.state || savedTab || CampaignStates.Draft
	)
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>(
		params?.order || 'asc'
	)
	const [paginationModel, setPaginationModel] = useState<{
		limit: number
		offset: number
	}>({
		limit:
			params?.limit && !Number.isNaN(params?.limit) ? +params.limit : 10,
		offset:
			params?.offset && !Number.isNaN(params?.limit) ? +params.offset : 0,
	})

	const fetchCampaigns = async () => {
		const unionObject: Partial<CampaignsListQueryParams> = {
			state: selectedTab,
			order_by: sortField,
			order: sortField ? sortDirection : undefined,
			limit: String(paginationModel.limit),
			offset: String(paginationModel.offset),
		}

		Object.keys(unionObject)?.forEach((el) => {
			if (!unionObject[el]) delete unionObject[el]
		})
		pushObjectToQuery(unionObject)

		try {
			await campaignInterface.fetchCampaignsList({
				limit: paginationModel.limit,
				offset: paginationModel.offset,
				state: selectedTab,
				sortDirection: sortDirection,
				sortField: sortField,
			})
		} finally {
		}
	}

	const tabCount = useMemo(() => {
		const countKey = `count_${selectedTab}`
		if (typeof campaignsList === 'object' && campaignsList?.[countKey]) {
			return campaignsList[countKey] as number
		} else {
			return 0
		}
	}, [campaignsList, selectedTab])

	const handleTabChange = (tab: CampaignsListTabsKeys) => {
		setSelectedTab(tab)
		localStorage.setItem('campaignsFilter', tab)
	}

	const setLimit = (limit: number) => {
		setPaginationModel((prev) => ({ ...prev, limit: limit }))
	}
	const setOffset = (offset: number) => {
		setPaginationModel((prev) => ({ ...prev, offset: offset }))
	}

	useEffect(() => {
		fetchCampaigns()
	}, [selectedTab, sortField, sortDirection, paginationModel])
	return (
		<div className={s.container}>
			{(isMobile || isTablet) && (
				<h5 className={'mx-3 my-3'}>Рекламные кампании</h5>
			)}

			<HeaderWithTabs
				isActionButtons={campaignsList?.count_total > 0}
				onCreateCampaign={onCreateCampaign}
				campaigns={campaignsList}
				selectedTab={selectedTab}
				onTabChange={handleTabChange}
			/>

			<div className="d-flex flex-column justify-content-between h-100">
				<div style={{ height: '100%', width: '100%' }}>
					{campaignsList?.count_total === 0 ? (
						<div className="d-flex justify-content-center align-items-center w-100 h-100">
							<CampaignsEmpty
								onCreateCampaign={onCreateCampaign}
							/>
						</div>
					) : (
						<ClientStatistics
							campaigns={campaignsList.results}
							sortField={sortField}
							sortDirection={sortDirection}
							setSortField={setSortField}
							setSortDirection={setSortDirection}
						/>
					)}
				</div>

				{tabCount > 0 && (
					<ProviderPagination
						limit={paginationModel.limit}
						offset={paginationModel.offset}
						setLimit={setLimit}
						setOffset={setOffset}
						total={tabCount}
					/>
				)}
			</div>
		</div>
	)
}

function CampaignsEmpty({ onCreateCampaign }) {
	return (
		<Container fluid className={s.emptyCard} style={{}}>
			<h1 className={s.emptyIcon}>👋 Добро пожаловать!</h1>
			<div className={s.emptyText}>
				Создайте свою первую рекламную кампанию в AdMarket
			</div>
			<Button variant={'primary'} onClick={onCreateCampaign} size={'lg'}>
				🚀 Создать кампанию
			</Button>
		</Container>
	)
}

export default withPage({ noSidebar: false, isProvider: false })(CampaignsList)
