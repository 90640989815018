import React, { ReactElement, useMemo, useState } from 'react'
import { MapAndFilterButton } from './buttons/MapAndFilterButton'
import { CreateAndSearchButton } from './buttons/CreateAndSearchButton'
import { motion } from 'framer-motion'
import { FiltersItemListProps, FiltersState } from './filter-state/FiltersState'
import { RollUpAndResetButton } from './buttons/RollUpAndResetButton'

import s from './providerFilter.module.scss'
import cn from 'classnames'
import { PlacementFilterType } from '../../constants'
import useResponsive from 'src/utils/useResponsive'
import { PlacementFilterKeyType } from '../../constants/placementFilters'
import { ProviderModalFiltersView } from './ProviderModalFiltersView'

type ProviderFilterProps = {
	isMapButton?: boolean
	isFiltersButton?: boolean
	isCreateButton?: boolean
	onSearchStringChange?: (searchString: string) => void
	onCreateItemAction?: () => void
	onMapButtonClick?: () => void
	onResetFilters?: () => void
	onDeleteFilter?: (filterKey: PlacementFilterKeyType) => void
	onSetFilter: (key: PlacementFilterKeyType, value: Array<string>) => void
	onFilterButtonClick?: () => void
	createItemButtonTitle?: string
	filters?: Array<PlacementFilterType>
	searchVariants?: Array<string>
	searchString?: string
	setFilters?: React.Dispatch<React.SetStateAction<PlacementFilterType[]>>
	allFiltersSelected?: boolean
	children?: ReactElement<FiltersItemListProps<{}>>
}
export const ProviderFilter = ({
	isMapButton,
	isCreateButton,
	isFiltersButton = true,
	onSearchStringChange,
	onCreateItemAction,
	onMapButtonClick,
	onResetFilters,
	onDeleteFilter,
	onSetFilter,
	onFilterButtonClick,
	createItemButtonTitle,
	filters,
	searchVariants,
	searchString,
	setFilters,
	allFiltersSelected,
	children,
}: ProviderFilterProps) => {
	const [showCurrentFiltersRow, setShowCurrentFiltersRow] = useState(true)

	const [modalOpen, setModalOpen] = useState(false)

	const atLeastOneFilterSelected: boolean | undefined = useMemo(() => {
		return filters?.some((filter) => filter.filterItems.length > 0)
	}, [filters])

	const handleFilterButtonClick = () => {
		if (!atLeastOneFilterSelected) {
			if (!onFilterButtonClick) {
				setModalOpen(true)
			}

			setShowCurrentFiltersRow(true)
			return
		}

		if (!showCurrentFiltersRow && filters?.length !== 0) {
			setShowCurrentFiltersRow(true)
		} else {
			if (!onFilterButtonClick) {
				setModalOpen(true)
			}
		}
	}
	const filtersCount = filters?.filter(
		(filter) => filter.filterItems.length > 0
	).length

	const { isDesktop, isTablet } = useResponsive()
	return (
		<>
			<div id={'filter-wrapper'} className={cn(s.filterWrapper)}>
				<div id={'filter-action-line'} className={cn(s.filterRow)}>
					<div
						id={'filter-action-line-left'}
						className={cn(s.filterSide, {
							[s.filterSide_fullWidth]: isTablet,
						})}
					>
						<CreateAndSearchButton
							searchString={searchString}
							searchVariants={searchVariants}
							onCreateItem={onCreateItemAction}
							onSearchStringChange={onSearchStringChange}
							createItemButtonTitle={createItemButtonTitle}
							isCreateButton={isCreateButton}
						/>
					</div>

					<div
						id={'filter-action-line-right'}
						className={cn(s.filterSide)}
					>
						<MapAndFilterButton
							onMapButtonClick={onMapButtonClick}
							isMapButton={isMapButton}
							isFiltersButton={isFiltersButton}
							onFilterButtonClick={handleFilterButtonClick}
							filtersCount={
								filtersCount === 0 ? undefined : filtersCount
							}
						/>
					</div>
				</div>

				{showCurrentFiltersRow && isFiltersButton && (
					<motion.div
						id={'current-filters-line'}
						className={cn(s.filterRow)}
						initial={{ opacity: 1, y: 5 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 50 }}
						style={{ alignItems: 'flex-start' }}
					>
						{children && (
							<div
								id={'current-filters-state'}
								className={cn(s.filterSide)}
							>
								<FiltersState
									onFilterAdd={() => setModalOpen(true)}
									onDeleteFilter={onDeleteFilter}
									allFiltersSelected={allFiltersSelected}
									filters={filters!}
								>
									{children}
								</FiltersState>
							</div>
						)}

						<div
							id={'current-filters-line-actions'}
							className={cn(s.filterSide)}
							style={{ justifyContent: 'flex-end' }}
						>
							<RollUpAndResetButton
								isResetDisabled={!atLeastOneFilterSelected}
								onResetFilters={onResetFilters}
								setShowCurrentFiltersRow={
									setShowCurrentFiltersRow
								}
								showCurrentFiltersRow={showCurrentFiltersRow}
							/>
						</div>
					</motion.div>
				)}
			</div>

			{modalOpen && (
				<ProviderModalFiltersView
					setFilters={setFilters}
					filters={filters}
					onHide={() => setModalOpen(false)}
					onSetFilter={onSetFilter}
					open={modalOpen}
				/>
			)}
		</>
	)
}
