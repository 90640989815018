import { DistrictBookingsCreativeType } from 'src/stores/ADMarketTypes.types'

export enum VideoFormats {
	mpeg4 = 'mpeg4',
	mp4 = 'mp4',
	mov = 'mov',
	avi = 'avi',
}
export enum ImageFormats {
	jpeg = 'jpeg',
	png = 'png',
	pdf = 'pdf',
}
export enum ColorModel {
	rgb = 'rgb',
	hsl = 'hsl',
}
export enum Codec {
	xvid = 'xvid',
	h264 = 'h264',
}

export type MediaRequirements = {
	additional_info?: string
	is_image: boolean
	is_video: boolean
	video_formats: VideoFormats[]
	image_formats: ImageFormats[]
	min_height?: number
	max_height?: number
	min_width?: number
	max_width?: number
	aspect_ratio?: string
	color_model?: ColorModel
	codec?: Codec
	min_fps?: number
	max_fps?: number
	min_bitrate?: number
	max_bitrate?: number
	duration?: number
	with_audio_track: boolean
	min_dpi?: number
	max_dpi?: number
	image_max_size?: number
	video_max_size?: number
	external_link?: string
	external_id?: string
	disclaimer_requirements: string
	age_restriction_requirements: string
	other_requirements: string
}
export type AdvMediaType = {
	id: string
	name: string
	placements: PlacementResponseModel[]
} & MediaRequirements

export enum DoorType {
	indoor = 'indoor',
	outdoor = 'outdoor',
}

export type Garantid = {
	adjustment_msg: string
	booking_budget: string
	reject_msg: string
	state: AdvItemState
	fact_cost?: string
	id: number
	is_active: boolean
	is_docs_required: boolean
	placement_showing_count: {
		ac_days_count: number | null
		ac_price: number | null
		id: number
		name: string
		price: string
		showing_count_per_day: number | null
		video_duration: number
	}
	media_creative: MediaCreative | undefined
}
export type MediaCreative = {
	file: string
	file_name: string
	id: number
	is_self_download: false
}

export enum AdvItemState {
	Draft = 'draft',
	Inner_Moderation = 'inner_moderation',
	Moderation = 'moderation',
	Adjustment = 'adjustment',
	Confirm = 'confirm',
	Completed = 'completed',
	Reject = 'reject',
	Canceled = 'canceled',
	Inner_Confirm = 'inner_confirm',
}
export type Indoor = Garantid
export type Outdoor = Garantid

export type District = {
	adjustment_msg: string
	reject_msg: string
	state: AdvItemState
	is_docs_required: boolean
	fact_cost?: string
	center_lat: string
	center_lon: string
	id: number
	radius: number
	creatives: Omit<DistrictBookingsCreativeType, 'edit_vinstant'>
}
export type PlacementResponseModel = {
	id: string
	name: string
	lat: string
	lon: string
	description?: string
	image?: string
	ots?: string
	size?: string
	only_work_days: boolean
	works_on_holidays: boolean
	is_active: boolean
	activation_date?: string
	door_type: DoorType
	is_guaranteed: boolean
	screen_count: number
	address: string
	districts: District[]
	outdoors: Outdoor[]
	indoors: Indoor[]
}
