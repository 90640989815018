import React from 'react'
import ls from '../style.module.scss'
import { Accordion } from 'react-bootstrap'
import { ReactComponent as Internet } from '../../../../../../../../assets/images/internet.svg'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { YandexAccordionBody } from './accorion-bodies/YandexAccordionBody'
import { VkAccordionBody } from './accorion-bodies/VkAccordionBody'
import { CommonAdvAccordionsProps } from '../CampaignDetailedInfo'
import { BookingState } from 'src/stores/ADMarketTypes.types'
import { getAdjustmentMessage } from 'src/components/_moderator/utils'

export const InternetDetailedAccordion = ({
	setDetailedItem,
	setAdvItemActon,
	onEditVinstant,
}: CommonAdvAccordionsProps) => {
	const [moder, moderInterface] = useModerationData()

	return (
		<Accordion className={ls.accordion} defaultActiveKey="0">
			<Accordion.Item eventKey="0">
				<Accordion.Header as={'div'} className={ls.accordionHeader}>
					<Internet style={{ fill: '#212529' }} />
					<h6>Интернет реклама</h6>
				</Accordion.Header>

				{moder?.detailedCampaign?.internet_vk?.is_selected && (
					<VkAccordionBody
						onEditVinstant={onEditVinstant}
						adjusmentMessage={getAdjustmentMessage({
							state: moder.detailedCampaign.internet_vk
								.state as unknown as BookingState,
							adjusmentMessage:
								moder.detailedCampaign.internet_vk
									.adjustment_msg,
							rejectMessage:
								moder.detailedCampaign.internet_vk.reject_msg,
						})}
						setAdvItemActon={setAdvItemActon}
						setDetailedItem={setDetailedItem}
					/>
				)}
				{moder?.detailedCampaign?.internet_yandex?.is_selected && (
					<YandexAccordionBody
						onEditVinstant={onEditVinstant}
						adjusmentMessage={getAdjustmentMessage({
							state: moder.detailedCampaign.internet_yandex
								.state as unknown as BookingState,
							adjusmentMessage:
								moder.detailedCampaign.internet_yandex
									.adjustment_msg,
							rejectMessage:
								moder.detailedCampaign.internet_yandex
									.reject_msg,
						})}
						setAdvItemActon={setAdvItemActon}
						setDetailedItem={setDetailedItem}
					/>
				)}
			</Accordion.Item>
		</Accordion>
	)
}
