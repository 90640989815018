import React from 'react'
import { ImportProcessData, ImportState } from './PlacementsImportExport'
import { Button } from 'react-bootstrap'

type Props = {
	importProcessData: ImportProcessData | null
	confirmImport: (isConfirmed: boolean) => void
}
export const ImportConfirmation = ({
	importProcessData,
	confirmImport,
}: Props) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '0.5rem',
			}}
		>
			<div>{`Импорт рекламных носителей завершен ${
				importProcessData?.state !== ImportState.ERROR
					? 'успешно'
					: 'c ошибкой'
			}. Всего загружено ${importProcessData?.imported_placements} из ${
				importProcessData?.all_placements
			}`}</div>
			<div
				style={{
					display: 'flex',

					gap: '0.5rem',
				}}
			>
				<Button onClick={() => confirmImport(true)}>
					Подтвердить импорт
				</Button>
				<Button
					onClick={() => confirmImport(false)}
					variant="secondary"
				>
					Отменить
				</Button>
			</div>
		</div>
	)
}
