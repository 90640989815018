import { TvState } from '../../../../stores/ADMarketTypes.types'

type MessageProperties = {
	icon: string
	key: TvState
	title: string
	color: string
	progress: number
	message: string
}

type MessagePropertiesWithoutMessage = Omit<MessageProperties, 'message'>

enum TvType {
	'ADSTREAM' = 'adstream',
	'TELETARGET' = 'teletarget',
}

const TvDraftTemplate: MessagePropertiesWithoutMessage = {
	key: TvState.Draft,
	icon: 'bi bi-file-earmark',
	title: 'Черновик',
	color: '#6C757D',
	progress: 0,
}
const TvModerationTemplate: MessagePropertiesWithoutMessage = {
	key: TvState.Moderation,
	icon: 'bi bi-hourglass-split',
	title: 'На модерации',
	color: '#007BFF',
	progress: 33,
}
const TvAdjustmentTemplate: MessagePropertiesWithoutMessage = {
	key: TvState.Adjustment,
	icon: 'bi bi-pencil',
	title: 'На исправлении',
	color: '#007BFF',
	progress: 50,
}
const TvConfirmTemplate: MessagePropertiesWithoutMessage = {
	key: TvState.Confirm,
	icon: 'bi bi-check-circle',
	title: 'Подтверждено',
	color: '#28A745',
	progress: 100,
}
const TvRejectTemplate: MessagePropertiesWithoutMessage = {
	key: TvState.Reject,
	icon: 'bi bi-x-circle',
	title: 'Отклонено',
	color: '#DC3545',
	progress: 100,
}
const TvCanceledTemplate: MessagePropertiesWithoutMessage = {
	key: TvState.Canceled,
	icon: 'bi bi-dash-circle',
	title: 'Отменено',
	color: '#DC3545',
	progress: 100,
}

const MessagesData: {
	[key in TvState]?: { [key in TvType]?: MessageProperties }
} = {
	[TvState.Draft]: {
		[TvType.ADSTREAM]: {
			...TvDraftTemplate,
			message: 'Draft',
		},
		[TvType.TELETARGET]: {
			...TvDraftTemplate,
			message: 'Draft',
		},
	},
	[TvState.Moderation]: {
		[TvType.ADSTREAM]: {
			...TvModerationTemplate,
			message:
				'Проверяем видеофайл. Как только все проверим, отправим уведомление на почту.',
		},
		[TvType.TELETARGET]: {
			...TvModerationTemplate,
			message:
				'Проверяем изображение. Как только все проверим, отправим уведомление на почту.',
		},
	},
	[TvState.Inner_Moderation]: {
		[TvType.ADSTREAM]: {
			...TvModerationTemplate,
			message:
				'Проверяем видеофайл. Как только все проверим, отправим уведомление на почту.',
		},
		[TvType.TELETARGET]: {
			...TvModerationTemplate,
			message:
				'Проверяем изображение. Как только все проверим, отправим уведомление на почту.',
		},
	},
	[TvState.Adjustment]: {
		[TvType.ADSTREAM]: {
			...TvAdjustmentTemplate,
			message:
				'Сообщение от подрядчика, с указанием, что необходимо исправить, чтобы пройти модерацию.',
		},
		[TvType.TELETARGET]: {
			...TvAdjustmentTemplate,
			message:
				'Сообщение от подрядчика, с указанием, что необходимо исправить, чтобы пройти модерацию.',
		},
	},
	[TvState.Confirm]: {
		[TvType.ADSTREAM]: {
			...TvConfirmTemplate,
			message: 'Изображения подтверждены. Модерация завершена.',
		},
		[TvType.TELETARGET]: {
			...TvConfirmTemplate,
			message: 'Изображения подтверждены. Модерация завершена.',
		},
	},
	[TvState.Reject]: {
		[TvType.ADSTREAM]: {
			...TvRejectTemplate,
			message:
				'В размещении отклонено. Возможно ваш контент не соответствует правилам сервиса.',
		},
		[TvType.TELETARGET]: {
			...TvRejectTemplate,
			message:
				'В размещении отклонено. Возможно ваш контент не соответствует правилам сервиса.',
		},
	},
	[TvState.Canceled]: {
		[TvType.ADSTREAM]: {
			...TvCanceledTemplate,
			message: 'Вы отменили размещение.',
		},
		[TvType.TELETARGET]: {
			...TvCanceledTemplate,
			message: 'Вы отменили размещение.',
		},
	},
}

export { MessagesData, TvType }
