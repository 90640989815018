import moment, { Moment } from 'moment/moment'
import { CampaignInfoTypeBookingsSeparation } from '../../../../stores/ADMarketTypes.types'

function getNextPeriodDate(
	date,
	period: { weeks: number | string; month: number | string }
) {
	const nextDate = date.clone().add({ month: period.month })
	if (!period.weeks) {
		if (Math.abs(1 - nextDate.date()) < 7) {
			nextDate.date(1)
		} else {
			nextDate.date(1).subtract(1, 'day')
		}
		return nextDate
	} else {
		if (Math.abs(1 - nextDate.date()) < 7) {
			nextDate.add(1, 'month').date(1).subtract(1, 'day')
		} else {
			nextDate.add(2, 'weeks').date(1)
		}
		return nextDate
	}
}

export type DatePickerVariant = 'variant1' | 'variant2' | 'custom'
export type DatePickerPresetsType = {
	startDate: Moment | null
	endDate: Moment | null
	variant: DatePickerVariant
}

export const getDatePickerPresets = (settings: {
	week: number
	month: number
	customDate: {
		startDate: Moment | null
		endDate: Moment | null
	}
}): DatePickerPresetsType[] => {
	//.add(1, 'day') пропускаем текущий день
	let startDate = moment().add(settings.week, 'week').add(1, 'day')

	//multiplier умножает минимальный срок n раз
	const multiplier = 2

	//.subtract(1, 'days') потому что даты указываются включительно
	return [
		{
			startDate: startDate,
			endDate: startDate
				.clone()
				.add(settings.week, 'week')
				.subtract(1, 'days'),
			variant: 'variant1',
		},
		{
			startDate: startDate,
			endDate: startDate
				.clone()
				.add(settings.week * multiplier, 'week')
				.subtract(1, 'days'),
			variant: 'variant2',
		},
		{
			startDate: settings.customDate.startDate,
			endDate: settings.customDate.endDate,
			variant: 'custom',
		},
	]
}

function getStartDatePresets(period: {
	weeks: number | string
	month: number | string
}) {
	const today = moment().add(1, 'month').date(1)

	const output = [`${today.format('DD.MM.YY')}`]
	let lastDate = today
	for (let i = 0; i < 3; i++) {
		if (i % 2 === 0) {
			lastDate = getNextPeriodDate(lastDate, period)
			output[i / 2] += ` - ${lastDate.format('DD.MM.YY')}`
		} else {
			lastDate.add(1, 'day')
			output.push(lastDate.format('DD.MM.YY'))
		}
	}
	output.push('Указать свой')
	return output
}

const getInitialFromCampaign = (
	campaign: CampaignInfoTypeBookingsSeparation,
	type: 'duration' | 'getmonths' | 'getDurationString' | 'getStart' | 'getEnd'
): any => {
	if (campaign && Object.keys(campaign).length === 0) return false
	const { start_date, end_date } = campaign
	if (type === 'duration') {
		const s = moment(start_date)
		const e = moment(end_date)
		const duration = moment(e).diff(s, 'days') + 1
		const monthDiff = e.diff(s, 'month') + 1
		let plusWeeks = !s.add(monthDiff, 'month').subtract(1, 'day').isSame(e)
		if (duration >= 0 && duration <= 17) {
			//2 недели
			return 0
		} else if (duration > 17 && duration <= 32) {
			//1 месяц
			if (plusWeeks) return 3
			return 1
		} else if (duration > 32 && duration <= 62) {
			//2 месяца
			if (plusWeeks) return 3
			return 2
		} else {
			//Другое
			return 3
		}
	}
	if (type === 'getmonths') {
		const s = moment(start_date)
		const e = moment(end_date)

		const monthDiff = e.diff(s, 'month') + 1
		const dayDiff = e.diff(s, 'day') + 1
		let plusWeeks = !s.add(monthDiff, 'month').subtract(1, 'day').isSame(e)
		return {
			month: dayDiff <= 18 ? 0 : plusWeeks ? monthDiff - 1 : monthDiff,
			plusWeeks: dayDiff <= 18 ? false : plusWeeks,
			weeks: plusWeeks ? 2 : 0,
		}
	}
	if (type === 'getDurationString') {
		const s = moment(start_date)
		const e = moment(end_date)
		return `${s.format('DD.MM.YY')} - ${e.format('DD.MM.YY')}`
	}
	if (type === 'getStart') {
		const s = moment(start_date)
		return `${s.format('DD.MM.YYYY')}`
	}
	if (type === 'getEnd') {
		const e = moment(end_date)
		return `${e.format('DD.MM.YYYY')}`
	}
}

export { getInitialFromCampaign, getNextPeriodDate, getStartDatePresets }
