import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useProviderData from 'src/stores/providerData'
import { ProviderFilterCheckbox } from 'src/components/_provider/Bookings/filters/components/common/ProviderFilterCheckbox'
import { useQueryParams } from 'src/utils'
import {
	ProviderFilterContentBody,
	ProviderFilterContentFooter,
	ProviderFilterContentHeader,
} from 'src/components/_provider/Bookings'
import { PlacementFilterKeyType } from '../../constants/placementFilters'
import useResponsive from 'src/utils/useResponsive'

type ProviderCompaniesFilterContentProps = {
	onFilterSubmit: (key: PlacementFilterKeyType, value: Array<string>) => void
	onClose: () => void
	onChange?: (key: PlacementFilterKeyType, value: Array<string>) => void
	selectedItems?: Array<string>
}
type BaseProviderCompaniesQueryType = {
	company: string | string[] | undefined
}
export const ProviderCompaniesFilterContent = <
	T extends BaseProviderCompaniesQueryType
>({
	onFilterSubmit,
	onClose,
	selectedItems,
	onChange,
}: ProviderCompaniesFilterContentProps) => {
	const [provider] = useProviderData()

	const { params, pathName } =
		useQueryParams<BaseProviderCompaniesQueryType>()

	const [selectedCompanies, setSelectedCompanies] = useState(() => {
		if (selectedItems) return selectedItems

		if (params?.company) {
			if (Array.isArray(params?.company)) return params?.company
			return [params?.company]
		} else {
			return []
		}
	})

	const companies = provider?.bookings_filters?.companies

	const [searchString, setSearchString] = useState('')

	const filtredCompanies = useMemo(
		() =>
			companies?.filter((el) =>
				el.name.toLowerCase().includes(searchString.toLowerCase())
			),
		[searchString, companies]
	)

	const handleSelectedChange = (capmaignId: string) => {
		if (selectedCompanies.includes(capmaignId)) {
			setSelectedCompanies(
				selectedCompanies.filter((el) => el !== capmaignId)
			)
		} else {
			setSelectedCompanies([...selectedCompanies, capmaignId])
		}
	}

	const handleFilterSubmit = () => {
		onFilterSubmit('company', selectedCompanies)
	}
	const selectList = useMemo(
		() =>
			filtredCompanies?.map((el) => (
				<ProviderFilterCheckbox
					checked={selectedCompanies.includes(el.id)}
					onChange={() => handleSelectedChange(el.id)}
					text={el.name}
					key={el.id}
				/>
			)),
		[searchString, companies, selectedCompanies]
	)
	const handleChangeAll = useCallback(() => {
		if (selectedCompanies?.length >= filtredCompanies?.length) {
			return setSelectedCompanies([])
		} else {
			setSelectedCompanies(
				filtredCompanies?.map((campaign) => campaign.id)
			)
		}
	}, [selectedCompanies, companies, filtredCompanies])

	useEffect(() => {
		onChange && onChange('company', selectedCompanies)
	}, [selectedCompanies])

	const { isMobile } = useResponsive()
	return (
		<div
			id={'provider-filter-content-wrapper'}
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				width: '100%',
			}}
		>
			<ProviderFilterContentHeader
				onClick={handleChangeAll}
				isAllSelected={
					selectedCompanies.length >= filtredCompanies?.length
				}
				count={selectedCompanies.length}
				setSearchString={setSearchString}
			/>

			<ProviderFilterContentBody>
				<div>{selectList}</div>
			</ProviderFilterContentBody>

			{isMobile && (
				<ProviderFilterContentFooter
					onApplyFilters={handleFilterSubmit}
					onClose={onClose}
				/>
			)}
		</div>
	)
}
