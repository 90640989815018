import React from 'react'
import { Card } from 'react-bootstrap'
import s from './publicTransportPresentationCard.module.scss'
import { PublicTransportVariants } from '../../../../../types'
import { PublicTransportAdvHeader } from './p-t-adv-header/PublicTransportAdvHeader'
import { PublicTranportVariantsSelectors } from './p-t-variants-selectors/PublicTranportVariantsSelectors'
import { CardWrapper } from '../../../../common/card-wrapper/CardWrapper'

type Props = {
	selectedVariants: PublicTransportVariants[]
	onSelectorClick: (variant: PublicTransportVariants) => void
}
export const PublicTransportPresentationCard = ({
	onSelectorClick,
	selectedVariants,
}: Props) => {
	return (
		<CardWrapper>
			<div className={s.outdoorPresentationCardContent}>
				<PublicTransportAdvHeader />
				<PublicTranportVariantsSelectors
					isLoading={false}
					onSelectorClick={onSelectorClick}
					selectedVariants={selectedVariants}
				/>
			</div>
		</CardWrapper>
	)
}
