import React from 'react'
import { ReactComponent as SendNotify } from 'src/assets/images/send_notify.svg'
import s from './style.module.scss'
import cn from 'classnames'
import { IssueObjectType } from './CampaignIssueRow'

type ProblemInfoProps = {
	issue: IssueObjectType
	onNotificationClick: () => void
}
export const ProblemInfo = ({
	issue,
	onNotificationClick,
}: ProblemInfoProps) => {
	return (
		<tr className={cn(s.tRow)}>
			<td>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<div>{issue.name}</div>
					<div>{issue.place_name}</div>
				</div>
			</td>
			<td>{issue.problem}</td>
			<td onClick={onNotificationClick} className={cn(s.sendNotifyCell)}>
				<SendNotify />
			</td>
		</tr>
	)
}
