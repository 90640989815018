import moment from 'moment'
import React from 'react'
import DateInput, {
	DatePicker,
} from 'src/components/UtilityComponents/DropdownDatePicker'
import ModalDateInput from 'src/components/UtilityComponents/ModalDateInput'
import s from './createReportTwo.module.scss'
import baseStyle from '../createReportSteps.module.scss'
import {
	CreateReportBody,
	CreateReportHeader,
	CreateReportSelectedCount,
} from '../../common'
import { CreateReportDatePicker } from './CreateReportDatePicker'
import useResponsive from 'src/utils/useResponsive'
import cn from 'classnames'
import { Search } from 'src/components/UtilityComponents'
import FormLabelTooltip from 'src/components/UtilityComponents/FormLabelTooltip'

const tooltipText = `Можете оставить поля пустыми, тогда сформируется отчет за весь период`

export const CreateReportStepTwo = ({
	startDate,
	handleStartDateChange,
	endDate,
	handleEndDateChange,
}) => {
	const { isMobile } = useResponsive()

	return (
		<div
			className={cn(baseStyle.createReportStep, {
				[baseStyle.createReportStep_mobile]: isMobile,
			})}
		>
			<CreateReportHeader
				title="Период размещения"
				description="Укажите период, чтобы увидеть сколько показов и какой у вас расход бюджета ."
			>
				<div>
					<div style={{ display: 'flex', gap: '5px' }}>
						<p style={{ margin: '0 0 8px 0' }}>Период размещения</p>
						<FormLabelTooltip text={tooltipText} />
					</div>

					<CreateReportDatePicker
						endDate={endDate}
						handleEndDateChange={handleEndDateChange}
						handleStartDateChange={handleStartDateChange}
						startDate={startDate}
					/>
				</div>
			</CreateReportHeader>
		</div>
	)
}

export default CreateReportStepTwo
