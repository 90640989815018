import React, { useState } from 'react'
import cn from 'classnames'
import s from './helpNavigator.module.scss'
import useResponsive from 'src/utils/useResponsive'
import { ConditionArrowUpDown, HelpList } from '../help-list'
import { HandleActiveItemChangeType, HelpContentKey } from '../../constants'
import BottomSheet from 'src/components/UtilityComponents/Bottomsheet'
import { set } from 'immer/dist/internal'

type HelpNavigatorProps = {
	activeItem: HelpContentKey
	nestedItem: HelpContentKey | undefined
	changeActive: HandleActiveItemChangeType
}

const titles: { [key in HelpContentKey]: string } = {
	'campaign-creation': 'Создание кампании',
	'media-plan': 'Медиаплан',
	documents: 'Документы',
	outdoor: 'Внешняя реклама',
	indoor: 'Внутренняя реклама',
	internet: 'Интернет-реклама',
	legal: 'Создание кампании',
	person: 'Создание кампании',
	registration: 'Регистрация',
	statistics: 'Статистика',
	tv: 'ТВ-реклама',
}

export const HelpNavigator = ({
	activeItem,
	nestedItem,
	changeActive,
}: HelpNavigatorProps) => {
	const { isMobile, isTablet } = useResponsive()
	const [open, setOpen] = useState(false)

	const handleChange: HandleActiveItemChangeType = (
		itemId: HelpContentKey,
		nestedId?: HelpContentKey | undefined
	) => {
		setOpen(false)
		changeActive(itemId, nestedId)
	}
	return (
		<div style={{ position: 'relative' }}>
			<div className={cn(s.helpNavigator)}>
				<div
					className={cn(s.helpNavigatorContent, {
						[s.helpNavigatorContent_mobile]: isMobile,
					})}
				>
					<h4>Справка</h4>

					<button
						className={cn(s.button)}
						onClick={() => setOpen(!open)}
					>
						<div
							className={cn(s.buttonContent, {
								[s.buttonContent_mobile]: isMobile,
							})}
						>
							<p>
								{titles[nestedItem || ''] || titles[activeItem]}
							</p>
							<ConditionArrowUpDown open={open} />
						</div>
					</button>
				</div>
			</div>

			{open && isTablet && (
				<div className={cn(s.helpListWrapper)}>
					<HelpList
						changeActive={handleChange}
						activeItem={activeItem}
						nestedItem={nestedItem}
					/>
				</div>
			)}

			{open && isMobile && (
				<BottomSheet
					show={open}
					onHide={() => setOpen(false)}
					touchEvents={false}
				>
					<HelpList
						changeActive={handleChange}
						activeItem={activeItem}
						nestedItem={nestedItem}
					/>
				</BottomSheet>
			)}
		</div>
	)
}
