import React, { useMemo } from 'react'
import { Redirect, Route } from 'react-router-dom'
import useUserData from '../../stores/userData'
import ROUTES from '../../constants/Routes'
import { withPage } from '../page/Page'
import useResponsive from '../../utils/useResponsive'
import { ChatPage } from '../chat/ChatPage'

const UserChatPage = withPage({ noSidebar: false, isProvider: false })(ChatPage)
const ProviderChatPage = withPage({
	noSidebar: false,
	isProvider: true,
})(ChatPage)

export default function ChatCheckRoute({ ...passThrough }) {
	const [user] = useUserData()
	const { isMobile, isTablet } = useResponsive()
	const isDesktop = useMemo(
		() => !isMobile && !isTablet,
		[isMobile, isTablet]
	)
	if (!user || !user.phone_number || !user.is_phone_confirmed) {
		return <Redirect to={ROUTES.ROUTE_AUTH} />
	}
	if (user.provider)
		return !isDesktop ? (
			<Route {...passThrough}>
				<ProviderChatPage />
			</Route>
		) : (
			<Redirect to={ROUTES.ROUTE_PROVIDER} />
		)

	if (!user.provider)
		return !isDesktop ? (
			<Route {...passThrough}>
				<UserChatPage />
			</Route>
		) : (
			<Redirect to={ROUTES.ROUTE_CAMPAIGNS} />
		)
	return <Redirect to={ROUTES.ROUTE_AUTH} />
}
