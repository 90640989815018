import React, { useEffect, useState } from 'react'
import useDebounce from '../../utils/useDebounce'
import { authFetch } from '../../utils/authFetch'
import { Card, Dropdown, Form, InputGroup } from 'react-bootstrap'
import bem from '../../utils/bem'
import AdMarketDropdown from './Dropdown'
import {
	getAddressSuggestions,
	getCompanySuggestions,
} from '../../constants/api'
import '../../styles/autocomplete-input.scss'

const cn = bem('autocomplete-input')

export const AutocompleteInput = React.forwardRef(function AutocompleteInput(
	{
		onChange,
		value,
		onSelect,
		autocompleteType,
		placeholder,
		readOnly,
		filter = () => true,
		error,
		type = 'address',
		witchFindIcon = false,
		textarea = false,
		...pass
	}: any,
	ref
) {
	const [options, setOptions] = useState<any>(null)
	const debouncedValue = useDebounce(value, 300)
	const [, setFocused] = useState(false)

	useEffect(() => {
		;(async () => {
			if (debouncedValue) {
				const res: any = await authFetch({
					url:
						type === 'address'
							? getAddressSuggestions
							: getCompanySuggestions,
					method: 'GET',
					query: { query: debouncedValue },
				})
				setOptions(res)
			} else {
				setOptions(null)
			}
		})()
	}, [debouncedValue, type])
	return (
		<AdMarketDropdown
			trigger={
				<>
					{witchFindIcon && (
						<InputGroup style={{ width: 'inherit' }}>
							<InputGroup.Text
								style={{ backgroundColor: '#fff' }}
							>
								<i className="bi bi-search" />
							</InputGroup.Text>
							<Form.Control
								{...pass}
								size={'lg'}
								readOnly={readOnly}
								onChange={onChange}
								value={value}
								autoComplete={'off'}
								ref={ref}
								onFocus={() => {
									if (!readOnly) {
										setFocused(true)
									}
								}}
								onBlur={() => setFocused(false)}
								isInvalid={!!error}
								placeholder={
									witchFindIcon ? 'Начните вводить адрес' : ''
								}
								style={{ width: 'inherit', resize: 'none' }}
								as={textarea ? 'textarea' : undefined}
							/>
						</InputGroup>
					)}
					{!witchFindIcon && (
						<Form.Control
							{...pass}
							size={'lg'}
							readOnly={readOnly}
							onChange={onChange}
							value={value}
							autoComplete={'off'}
							ref={ref}
							onFocus={() => {
								if (!readOnly) {
									setFocused(true)
								}
							}}
							onBlur={() => setFocused(false)}
							isInvalid={!!error}
							as={textarea ? 'textarea' : undefined}
							style={{ resize: 'none' }}
							placeholder={placeholder}
						/>
					)}

					<Form.Control.Feedback type="invalid">
						{error}
					</Form.Control.Feedback>
				</>
			}
			menu={
				options && !readOnly
					? ({ close }) => (
							<Card className={cn('menu')}>
								{options.length ? (
									options.filter(filter).map((option, i) => (
										<Dropdown.Item
											onClick={() => {
												onSelect(option)
												close()
											}}
											key={i}
											className={cn('option')}
										>
											{type === 'address' ? (
												<AddressItem data={option} />
											) : (
												<CompanyItem data={option} />
											)}
										</Dropdown.Item>
									))
								) : (
									<Dropdown.Item>
										Данных по запросу не найдено
									</Dropdown.Item>
								)}
							</Card>
					  )
					: null
			}
			zIndex={5}
			closeOnTriggerClick={false}
			offset={0}
			menuWidth={'100%'}
		/>
	)
})

function AddressItem({ data }) {
	return (
		<div className={cn('option-name')} style={{ whiteSpace: 'pre-wrap' }}>
			{data.address}
		</div>
	)
}

function CompanyItem({ data }) {
	return (
		<>
			<div
				className={cn('option-name')}
				style={{ whiteSpace: 'pre-wrap' }}
			>
				{data.legal_name}
			</div>
			<div
				className={cn('option-data')}
				style={{ whiteSpace: 'pre-wrap' }}
			>
				ИНН {data.inn}, {data.legal_address}
			</div>
		</>
	)
}
