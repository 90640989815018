import React, { FC, useCallback, useEffect, useState } from 'react'
import { Button, Dropdown, Modal } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import useResponsive from '../../../../utils/useResponsive'
import SmallBottomSheet from '../../../UtilityComponents/SmallBottomSheet'
import useCampaignData from '../../../../stores/campaignData'
import IntlFormatter from '../../../../utils/intl-money-formatter'

enum Payment {
	PARAM_NAME = 'payment',
	SUCCESS = 'success',
	FAIL = 'fail',
}

const ModalData: {
	[key in Payment]?: { emoji: string; title: string; desc?: string }
} = {
	[Payment.SUCCESS]: {
		emoji: '👍',
		title: 'Рекламная кампания оплачена',
	},
	[Payment.FAIL]: {
		emoji: '😕',
		title: 'Платеж отклонен',
		desc:
			'Возможно, это техническая ошибка. \n' +
			'Попробуйте оплатить еще раз.',
	},
}

const PaymentResultModal: FC = () => {
	const history = useHistory()
	const location = useLocation()
	const { isMobile } = useResponsive()
	const [campaign] = useCampaignData()
	const [show, setShow] = useState<false | Payment.SUCCESS | Payment.FAIL>(
		false
	)

	useEffect(() => {
		setShow(() => {
			const params = new URLSearchParams(location.search)
			if (params.has(Payment.PARAM_NAME)) {
				const value = params.get(Payment.PARAM_NAME)
				if (value === Payment.SUCCESS) return Payment.SUCCESS
				if (value === Payment.FAIL) return Payment.FAIL
				removePaymentParam()
				return false
			} else return false
		})
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const removePaymentParam = useCallback(() => {
		const params = new URLSearchParams(location.search)
		params.delete(Payment.PARAM_NAME)
		history.replace({
			search: params.toString(),
		})
	}, [location.search]) // eslint-disable-line react-hooks/exhaustive-deps

	const handleClose = () => {
		setShow(false)
		removePaymentParam()
	}
	if (isMobile)
		return (
			<MobileModal
				onHide={handleClose}
				show={show}
				amount={
					campaign?.sum_payed_payments
						? campaign.sum_payed_payments / 100
						: 0
				}
			/>
		)
	if (!isMobile)
		return (
			<DesktopModal
				onHide={handleClose}
				show={show}
				amount={
					campaign?.sum_payed_payments
						? campaign.sum_payed_payments / 100
						: 0
				}
			/>
		)
	return null
}

function DesktopModal(props: {
	onHide: () => void
	show: Payment.SUCCESS | Payment.FAIL | false
	amount: number
}) {
	if (!props.show) return null
	return (
		<Modal
			show={true}
			onHide={props.onHide}
			centered
			backdrop={'static'}
			keyboard={false}
		>
			<Modal.Body
				role={'modal'}
				style={{
					padding: '40px',
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					textAlign: 'center',
				}}
			>
				<h1 style={{ fontSize: '40px' }}>
					{ModalData[props.show]?.emoji}
				</h1>
				<h5>{ModalData[props.show]?.title}</h5>
				{props.show === Payment.SUCCESS && (
					<h2>{IntlFormatter.format(props?.amount | 0)}</h2>
				)}
				{props.show === Payment.FAIL && (
					<p style={{ whiteSpace: 'pre-wrap' }}>
						{ModalData[props.show]?.desc}
					</p>
				)}
				<Button onClick={props.onHide}>Хорошо, спасибо</Button>
			</Modal.Body>
		</Modal>
	)
}

function MobileModal(props: {
	onHide: () => void
	show: Payment.SUCCESS | Payment.FAIL | false
	amount: number
}) {
	return (
		<SmallBottomSheet
			show={Boolean(props.show)}
			onHide={props.onHide}
			height={'245px'}
			touchHeight={'50px'}
		>
			{props.show ? (
				<div
					role={'alert'}
					style={{
						padding: '24px 16px 0 16px',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						textAlign: 'center',
					}}
				>
					<h1 style={{ fontSize: '40px', marginBottom: '8px' }}>
						{ModalData[props.show]?.emoji}
					</h1>
					<h5
						style={{
							fontWeight: 500,
							fontSize: '16px',
							marginBottom: '8px',
						}}
					>
						{ModalData[props.show]?.title}
					</h5>
					{props.show === Payment.SUCCESS && (
						<h2 style={{ fontSize: '24px', marginBottom: 0 }}>
							<h2>{IntlFormatter.format(props?.amount | 0)}</h2>
						</h2>
					)}
					{props.show === Payment.FAIL && (
						<p
							style={{ fontSize: '14px', marginBottom: 0 }}
							dangerouslySetInnerHTML={{
								__html: ModalData[props.show]?.desc as string,
							}}
						></p>
					)}
					<Dropdown.Divider
						className={'mt-4 mb-0'}
						style={{ width: '100vw' }}
					/>
					<Button onClick={props.onHide} className={'w-100 m-3'}>
						Хорошо, спасибо
					</Button>
				</div>
			) : null}
		</SmallBottomSheet>
	)
}

export default PaymentResultModal
