import React, { useState } from 'react'
import { CardWrapper } from '../../../../../../common/card-wrapper/CardWrapper'
import { AdvHeader } from '../../../../../../common/adv-header/AdvHeader'
import { ReactComponent as IndoorIcon } from 'src/assets/images/indoor.svg'
import { AdvRecSummary } from '../../../../../../common/adv-rec-summary/AdvRecSummary'
import s from './outdoors.module.scss'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { BookingsMapQuickView } from '../../../../../../common/bookings-map-quick-view/BookingsMapQuickView'
import ModalYandexMap from 'src/components/UtilityComponents/ModalYandexMap'
import useResponsive from 'src/utils/useResponsive'
import { BookingRec } from 'src/stores/types/recommendations'
import useCampaignData from 'src/stores/campaignData'

type Props = {
	bookings: BookingRec[]
	budget: string
}
export const IndoorsList = React.memo(({ bookings, budget }: Props) => {
	const [modalMapOpen, setModalMapOpen] = useState(false)
	const [campaign, _] = useCampaignData()
	const company = campaign.company

	const bookingsCoord = bookings.map((el) => ({
		lat: +el.placement.lat,
		lon: +el.placement.lon,
		title: el.placement.placement_type.name,
	}))
	const companyCoord =
		company.actual_location_lat && company.actual_location_lon
			? {
					lat: +campaign.company.actual_location_lat,
					lon: +campaign.company.actual_location_lon,
			  }
			: undefined

	const { isMobile } = useResponsive()
	return (
		<CardWrapper>
			<div className={s.content}>
				<AdvHeader
					Icon={IndoorIcon}
					title="Внутренняя реклама"
					description="Реклама внутри помещений общественного назначения — в торговых центрах, фитнес-центрах, лифтах, кинотеатрах, ресторанах."
				/>

				<BookingsMapQuickView
					bookingsCoord={bookingsCoord}
					bookingsType="indoor"
					companyCoord={companyCoord}
					styles={{
						borderRadius: isMobile ? '12px' : '16px',
						height: '250px',
						overflow: 'hidden',
					}}
					showOnMapClick={() => setModalMapOpen(true)}
				/>
				<AdvRecSummary
					summaryData={[
						{
							title: 'Всего рекламных мест:',
							value: `${bookings.length}`,
						},
						{
							title: 'Стоимость всего:',
							value: IntlFormatter.format(budget),
						},
					]}
				/>
			</div>

			{modalMapOpen && (
				<ModalYandexMap
					show={modalMapOpen}
					onHide={() => setModalMapOpen(false)}
					modalTitle="Выбранные места внутренней рекламы"
					fullScreen={true}
					yMapProps={{
						width: '100%',
						height: '100%',
						area: true,
						zoom: 12,
						withZoomControls: true,
						isMobile: isMobile,
						buisnessCoords: [0, 0],
						smallData: bookings.map((el) => ({
							lat: el.placement.lat,
							lon: el.placement.lon,
						})),
					}}
				/>
			)}
		</CardWrapper>
	)
})

IndoorsList.displayName = 'IndoorsList'
