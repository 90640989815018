import produce from 'immer'
import createStoreHook from '../utils/createStoreHook'
import { createStoreon } from 'storeon'
import makeStoreResettable from '../utils/makeStoreResettable'
import {
	acceptPhonePasswordRecoveryCode,
	acceptPhoneActivationCode,
	acceptPhoneChangeCode,
	acceptSmsActivationCode,
	changePassword,
	changeProviderProfile,
	getJWTtoken,
	login,
	providerCheckInvite,
	register,
	registerProvider,
	restorePassword,
	sendPhonePasswordRecoveryCode,
	sendPhoneActivationCode,
	sendPhoneChangeCode,
	sendSmsActivationCode,
	userData,
	sendSmsPasswordRecoverCode,
	acceptSmsPasswordRecoveryCode,
} from '../constants/api'
import { authFetch, plainFetch } from '../utils/authFetch'
import ROUTES from '../constants/Routes'
import { storeonDevtools } from 'storeon/devtools'
import { notificationDataStore } from './notificationsData'
import { campaignDataStoreInterface } from './campaignData'

export type PhoneConfirmationVariant = 'phone' | 'sms'
export type AcceptPhoneActivationCodeData = {
	variant: PhoneConfirmationVariant
	code: string
}
export type PasswordRecoveryCodeData = {
	variant: PhoneConfirmationVariant
	phone_number: string
	agency_alias?: string
}
export type AcceptRestorePasswordCodeData = {
	phone_number: string
	code: string
	variant: PhoneConfirmationVariant
	agency_alias?: string
}

type UserStoreState = {
	isModer: boolean
	token?: string
	jwt_token?: string
	tempToken?: string
	data: any
	ready: boolean
	isProviderInviteTokenValid: boolean
}

//за сколько времени JWT токек обновить до конца, милисекунды
const jwt_refetch_delta_time = 1000 * 60

export const userDataStore = createStoreon<UserStoreState, any>([
	(store) => {
		makeStoreResettable(store, {
			data: null,
			token: null,
			jwt_token: null,
			ready: false,
			isProviderInviteTokenValid: null,
			isModer: false,
		})

		store.on(
			'receive',
			produce((state, data) => {
				state.data = data
				state.ready = true
			})
		)

		store.on(
			'token',
			produce((state, token) => {
				state.token = token
			})
		)
		store.on(
			'jwt_token',
			produce((state, token) => {
				state.jwt_token = token
			})
		)

		store.on(
			'tempToken',
			produce((state, token) => {
				state.tempToken = token
			})
		)

		store.on(
			'logout',
			produce((state) => {
				state.data = {}
				state.token = null
				state.jwt_token = null
			})
		)
		store.on(
			'changeIsProviderInviteTokenValid',
			produce((state, value) => {
				state.isProviderInviteTokenValid = value
			})
		)
		store.on(
			'isModer',
			produce((state, value: boolean) => {
				state.isModer = value
			})
		)
	},
	process.env.NODE_ENV !== 'production' &&
		storeonDevtools({ name: 'admarket-userData' }),
])

export const userDataStoreInterface = {
	name: 'UserData',

	get() {
		return userDataStore.get().data
	},

	subscribe(callback) {
		return userDataStore.on('@changed', callback)
	},

	token() {
		return userDataStore.get().token
	},
	jwt_token() {
		return userDataStore.get().jwt_token
	},

	getIsModerState() {
		return userDataStore.get().isModer
	},
	setIsModerState(isModer: boolean) {
		return userDataStore.dispatch('isModer', isModer)
	},
	checkDataIsReady() {
		return userDataStore.get().ready
	},
	isProviderInviteTokenValid() {
		return userDataStore.get().isProviderInviteTokenValid
	},
	async fetch() {
		const storageToken = localStorage.getItem('admarket-token')

		if (storageToken) {
			userDataStore.dispatch('token', storageToken)
		} else if (!userDataStoreInterface.token()) {
			userDataStore.dispatch('receive', {})
		}

		if (userDataStoreInterface.token()) {
			try {
				//Операции с JWT Токеном
				await userDataStoreInterface.JWT_Check()
				const res = await authFetch<any>({
					method: 'GET',
					url: userData,
				})
				if (res?.is_moder) {
					userDataStore.dispatch('isModer', true)
				}
				userDataStore.dispatch('receive', res)
			} catch (e: any) {
				if (e.code === 401) {
					userDataStoreInterface.logout()
					window.location.href = ROUTES.ROUTE_AUTH
				} else {
					throw e
				}
			}
		}
	},
	async JWT_Create() {
		try {
			const { token, lifetime } = (await authFetch({
				url: getJWTtoken,
				method: 'POST',
			})) as { token: string; lifetime: number }
			const jwt_dead_time = new Date(
				Date.now().valueOf() + lifetime * 1000
			)
			localStorage.setItem('admarket-jwt_token', token)
			localStorage.setItem(
				'admarket-jwt_dead_time',
				jwt_dead_time.valueOf().toString()
			)
		} catch (e) {
			throw e
		}
	},
	async JWT_Check() {
		const jwt_token = localStorage.getItem('admarket-jwt_token')
		const jwt_dead_time = localStorage.getItem('admarket-jwt_dead_time')
		if (jwt_token) {
			if (jwt_dead_time != null) {
				const dead_date = new Date(parseInt(jwt_dead_time)).valueOf()
				const current_date = Date.now().valueOf()
				//сначала проверяем, живой ли вообще токен
				if (dead_date > current_date + jwt_refetch_delta_time) {
					//токен живой, ставим его и отложенный рефетч
					userDataStore.dispatch('jwt_token', jwt_token)
					const timeoutDelay =
						dead_date - current_date - jwt_refetch_delta_time - 1
					setTimeout(async () => {
						await userDataStoreInterface.JWT_Check()
					}, timeoutDelay)
				} else {
					//токен мертвый, запрашиваем новый
					await userDataStoreInterface.JWT_Create()
					await userDataStoreInterface.JWT_Check()
				}
			}
		} else {
			await userDataStoreInterface.JWT_Create()
			await userDataStoreInterface.JWT_Check()
		}
	},

	async login(
		body: { phone_number: string; password: string; agency_alias?: string },
		remember: boolean = false
	) {
		const { token } = (await plainFetch({
			method: 'POST',
			url: login,
			body,
		})) as { token: string }
		localStorage.setItem('admarket-token', token)
		if (!remember) {
			localStorage.setItem(
				'admarket-last-active',
				`${Number(new Date())}`
			)
		}
		userDataStore.dispatch('token', token)
		await userDataStoreInterface.fetch()

		campaignDataStoreInterface.fetch()
	},

	logout() {
		localStorage.removeItem('admarket-token')
		localStorage.removeItem('admarket-jwt_token')
		localStorage.removeItem('admarket-jwt_dead_time')
		localStorage.removeItem('admarket-last-active')
		userDataStore.dispatch('logout')
	},

	async register(body: {
		phone_number: string
		password: string
		utm_sources?: string
	}) {
		const { token } = (await plainFetch({
			method: 'POST',
			url: register,
			body,
		})) as { token: string }
		localStorage.setItem('admarket-last-active', `${Number(new Date())}`)
		userDataStore.dispatch('token', token)
		await userDataStoreInterface.fetch()
	},

	async sendPhoneActivationCode(variant: PhoneConfirmationVariant) {
		const url =
			variant === 'phone'
				? sendPhoneActivationCode
				: sendSmsActivationCode

		await authFetch({
			method: 'POST',
			url,
			body: {},
		})
	},

	async acceptPhoneActivationCode(data: AcceptPhoneActivationCodeData) {
		const url =
			data.variant === 'phone'
				? acceptPhoneActivationCode
				: acceptSmsActivationCode

		const body: { code: string } = {
			code: data.code,
		}
		await authFetch({
			method: 'POST',
			url: url,
			body: body,
		})
		const { token } = userDataStore.get()
		if (token) {
			localStorage.setItem('admarket-token', token)
		}
		await userDataStoreInterface.fetch()
	},

	async sendPasswordRecoveryCode(data: PasswordRecoveryCodeData) {
		let body = {
			phone_number: data.phone_number,
		}
		if (data.agency_alias) {
			body['agency_alias'] = data.agency_alias
		}

		const url =
			data.variant === 'phone'
				? sendPhonePasswordRecoveryCode
				: sendSmsPasswordRecoverCode
		await plainFetch({
			method: 'POST',
			url,
			body,
		})
	},

	async acceptPasswordRecoveryCode(data: AcceptRestorePasswordCodeData) {
		const url =
			data.variant === 'phone'
				? acceptPhonePasswordRecoveryCode
				: acceptSmsPasswordRecoveryCode
		const body = {
			phone_number: data.phone_number,
			code: data.code,
			agency_alias: data.agency_alias,
		}
		const { restore_password_token } = (await plainFetch({
			method: 'POST',
			url,
			body,
		})) as { restore_password_token: string }
		userDataStore.dispatch('tempToken', restore_password_token)
	},

	async restorePassword(body: { password: string }) {
		const token = userDataStore.get().tempToken
		await plainFetch({
			method: 'POST',
			url: restorePassword,
			body,
			headers: [['Authorization', `Token ${token}`]],
		})
		userDataStore.dispatch('tempToken', null)
		await userDataStoreInterface.fetch()
	},
	async changePassword(body: { password: string; old_password: string }) {
		try {
			await authFetch({ method: 'POST', url: changePassword, body })
		} catch (e: any) {
			return e.data
		}
	},

	async setEmail(email) {
		const res = await authFetch({
			method: 'PATCH',
			url: userData,
			body: { email },
		})
		userDataStore.dispatch('receive', res)
	},
	async checkProviderToken(invite_token) {
		try {
			const { email } = (await plainFetch({
				method: 'POST',
				url: providerCheckInvite,
				body: { invite_token },
			})) as { email: string }
			userDataStore.dispatch('receive', { email })
			userDataStore.dispatch(
				'changeIsProviderInviteTokenValid',
				invite_token
			)
		} catch (e) {
			// console.error(e);
			userDataStore.dispatch('changeIsProviderInviteTokenValid', false)
		}
	},
	async registerProvider(body: {
		invite_token: String
		fio: String
		phone_number: String
		password: String
	}) {
		const { token } = (await plainFetch({
			method: 'POST',
			url: registerProvider,
			body: {
				...body,
				invite_token:
					userDataStoreInterface.isProviderInviteTokenValid(),
			},
		})) as { token: string }
		localStorage.setItem('admarket-last-active', `${Number(new Date())}`)
		userDataStore.dispatch('token', token)
		await userDataStoreInterface.fetch()
	},
	async changeProviderName(name) {
		await authFetch({
			url: changeProviderProfile,
			method: 'PATCH',
			body: { name },
		})
		await userDataStoreInterface.fetch()
	},
	async changeUserFIO(body: {
		first_name: string
		last_name: string
		middle_name: string
	}) {
		const res = await authFetch({
			method: 'PATCH',
			url: userData,
			body: body,
		})
		userDataStore.dispatch('receive', res)
	},
	async changeUserPhone(new_phone) {
		try {
			await authFetch({
				method: 'POST',
				url: sendPhoneChangeCode,
				body: { new_phone },
			})
		} catch (e: any) {
			return e.data.new_phone[0]
		}
	},
	async acceptChangeUserPhone(new_phone, code) {
		try {
			await authFetch({
				method: 'POST',
				url: acceptPhoneChangeCode,
				body: { new_phone, code },
			})
			await userDataStoreInterface.fetch()
		} catch (e: any) {
			return e
		}
	},
}

userDataStore.on('logout', () => notificationDataStore.dispatch('logout', null))

const useUserData = createStoreHook(userDataStoreInterface)
export default useUserData
