import React from 'react'
import { PlacementType } from '../../../../../../../stores/ADMarketTypes.types'
import useResponsive from '../../../../../../../utils/useResponsive'
import { spreadArray } from '../../../../../../_provider/Mediaformats/MediaInfo'
import ImageFullScreenWrapper from '../../../../../../UtilityComponents/ImageFullScreenWrapper'
import { minMax } from '../BookingUpload'
import { PlaceRequirementsListItem } from './PlaceRequirementsListItem'
import { PlaceRequirementsHeader } from './PlaceRequirementsHeader'

export const PlaceRequirements = ({
	placement,
	noMobileHeader = false,
}: {
	placement: Omit<PlacementType, 'placement_showing_counts'>
	noMobileHeader?: boolean
}) => {
	const { media_type } = placement
	const { isMobile } = useResponsive()

	return (
		<>
			{isMobile && !noMobileHeader && <PlaceRequirementsHeader />}

			<div style={{ padding: isMobile ? '16px' : '' }}>
				<div className={'d-flex mb-3'}>
					<div className={'me-3'}>
						<ImageFullScreenWrapper>
							<img
								src={
									placement.image ||
									'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
								}
								alt={placement.name}
								style={{
									width: '80px',
									height: '80px',
									objectFit: 'cover',
									objectPosition: '50% 50%',
									borderRadius: '4px',
								}}
							/>
						</ImageFullScreenWrapper>
					</div>
					<div
						className={'d-flex flex-column justify-content-center'}
					>
						<h5 className={'mb-1'}>
							{placement?.placement_type.name}
						</h5>
						<p className={'m-0 text-secondary'}>
							{placement?.name}
						</p>
					</div>
				</div>
				<div
					className={'w-100 d-flex justify-content-between'}
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '8px',
						marginTop: '8px',
					}}
				>
					<span className={'text-secondary'}>Тип:</span>
					<span style={{ textAlign: 'right' }}>
						{media_type?.is_image && !media_type?.is_video
							? 'Изображение'
							: ''}
						{media_type?.is_video && !media_type?.is_image
							? 'Видео'
							: ''}
						{media_type?.is_video && media_type?.is_image
							? 'Изображение, видео'
							: ''}
					</span>
				</div>
				{(media_type?.video_formats || media_type?.image_formats) && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Допустимый формат:
						</span>
						<span style={{ textAlign: 'right' }}>
							{spreadArray([
								...(media_type?.image_formats &&
								media_type?.is_image
									? media_type?.image_formats
									: []),
								...(media_type?.video_formats &&
								media_type?.is_video
									? media_type?.video_formats
									: []),
							])}
						</span>
					</div>
				)}
				{(media_type?.min_width || media_type?.max_width) && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Ширина:</span>
						<span style={{ textAlign: 'right' }}>
							{minMax(
								media_type?.min_width,
								media_type?.max_width,
								'px'
							)}
						</span>
					</div>
				)}
				{(media_type?.min_height || media_type?.max_height) && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Высота:</span>
						<span style={{ textAlign: 'right' }}>
							{minMax(
								media_type?.min_height,
								media_type?.max_height,
								'px'
							)}
						</span>
					</div>
				)}
				{media_type?.image_max_size && media_type?.is_image && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Размер изображения:
						</span>
						<span style={{ textAlign: 'right' }}>
							не более {media_type?.image_max_size} МБ
						</span>
					</div>
				)}
				{media_type?.video_max_size && media_type?.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Размер видео:</span>
						<span style={{ textAlign: 'right' }}>
							не более {media_type?.video_max_size} МБ
						</span>
					</div>
				)}
				{media_type?.aspect_ratio && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Соотношение сторон:
						</span>
						<span style={{ textAlign: 'right' }}>
							{media_type?.aspect_ratio}
						</span>
					</div>
				)}
				{media_type?.color_model && media_type?.is_image && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Цветовая модель:
						</span>
						<span style={{ textAlign: 'right' }}>
							{media_type?.color_model.toUpperCase()}
						</span>
					</div>
				)}
				{(media_type?.min_dpi || media_type?.max_dpi) &&
					media_type?.is_image && (
						<div
							className={'w-100 d-flex justify-content-between'}
							style={{
								borderBottom: '1px solid #CED4DA',
								paddingBottom: '8px',
								marginTop: '8px',
							}}
						>
							<span className={'text-secondary'}>
								Точек на дюйм:
							</span>
							<span style={{ textAlign: 'right' }}>
								{minMax(
									media_type?.min_dpi,
									media_type?.max_dpi,
									'dpi'
								)}
							</span>
						</div>
					)}
				{media_type?.codec && media_type?.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Кодек:</span>
						<span style={{ textAlign: 'right' }}>
							{media_type?.codec}
						</span>
					</div>
				)}
				{(media_type?.min_fps || media_type?.max_fps) &&
					media_type?.is_video && (
						<div
							className={'w-100 d-flex justify-content-between'}
							style={{
								borderBottom: '1px solid #CED4DA',
								paddingBottom: '8px',
								marginTop: '8px',
							}}
						>
							<span className={'text-secondary'}>
								Кадровая частота:
							</span>
							<span style={{ textAlign: 'right' }}>
								{minMax(
									media_type?.min_fps,
									media_type?.max_fps,
									'fps'
								)}
							</span>
						</div>
					)}
				{(media_type?.min_bitrate || media_type?.max_bitrate) &&
					media_type?.is_video && (
						<div
							className={'w-100 d-flex justify-content-between'}
							style={{
								borderBottom: '1px solid #CED4DA',
								paddingBottom: '8px',
								marginTop: '8px',
							}}
						>
							<span className={'text-secondary'}>Битрейт:</span>
							<span style={{ textAlign: 'right' }}>
								{minMax(
									media_type?.min_bitrate,
									media_type?.max_bitrate,
									'Мбит/с'
								)}
							</span>
						</div>
					)}
				{media_type?.duration && media_type?.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Длительность:</span>
						<span style={{ textAlign: 'right' }}>
							{media_type?.duration} секунд
						</span>
					</div>
				)}
				{media_type?.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Звуковая дорожка:
						</span>
						<span style={{ textAlign: 'right' }}>
							{media_type?.with_audio_track ? 'Да' : 'Нет'}
						</span>
					</div>
				)}

				{media_type?.disclaimer_requirements && (
					<PlaceRequirementsListItem
						title={'Требования дисклеймера'}
						list={media_type?.disclaimer_requirements?.split('\n')}
					/>
				)}
				{media_type?.other_requirements && (
					<PlaceRequirementsListItem
						isBorderBottom={false}
						title={'Дополнительные требования'}
						list={media_type?.other_requirements?.split('\n')}
					/>
				)}
				{media_type?.age_restriction_requirements && (
					<PlaceRequirementsListItem
						isBorderBottom={false}
						title={'Требования возрастного ограничения'}
						list={media_type?.age_restriction_requirements?.split(
							'\n'
						)}
					/>
				)}
			</div>
		</>
	)
}
