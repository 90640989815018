import React, { useEffect, useMemo, useState } from 'react'
import ls from '../../style.module.scss'
import { Accordion } from 'react-bootstrap'
import { AdvItemInfo } from '../../common/AdvItemInfo'
import { RenderPreview } from 'src/components/UtilityComponents/ProgressFileInput'
import { AdvItemActions } from '../../common/AdvItemActions'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { NewContentCreatedKeysType } from 'src/stores/types/newContendCreatedType'
import { CampaignItemInfoField } from '../../common/CampaignItemInfoField'
import { AdvStateType } from '../../common/ModerationModalButton'
import { moderationApi } from 'src/components/_moderator/api/moderatorApi'
import { AdjusmentMessage } from '../../common/AdjusmentMessage'
import { DocsView } from '../../common/DocsView'
import useNotificationData from 'src/stores/notificationsData'
import { EditVinstantButton } from '../../common/EditVinstantButton'
import { EditVinstantModel } from '../../CampaignDetailedInfo'

type TvAccordionBodyProps = {
	onEditVinstant: (editModel: EditVinstantModel) => void
	setDetailedItem: React.Dispatch<
		React.SetStateAction<
			| {
					type: NewContentCreatedKeysType
					id: string | number
			  }
			| undefined
		>
	>
	setAdvItemActionType: (
		action: 'moderation' | 'adjustment' | 'reject'
	) => void
	adjusmentMessage: string
}

export const TvAccordionBody = ({
	setDetailedItem,
	setAdvItemActionType,
	adjusmentMessage,
	onEditVinstant,
}: TvAccordionBodyProps) => {
	const [moder, moderInterface] = useModerationData()
	const [channels, setChannels] = useState<
		Array<{ id: number; name: string }>
	>([])

	const adstream = moder.detailedCampaign.adstream

	const [, notificationsInterface] = useNotificationData()

	const vinstantEditStatus =
		notificationsInterface.getNewContentCreated('adstream')

	const [resolution, setResolution] = useState('')
	const getResolutionAndExtension = async () => {
		const extension = adstream?.image?.split('.').pop()

		const image = new Image()
		image.src = adstream.image || ''
		image.onload = function () {
			setResolution(`${image.width}x${image.height}.${extension}`)
		}
	}
	const getChannels = async () => {
		const result = await moderationApi.getAdstreamChannels()
		setChannels(result)
	}
	const handleSetDetailedItem = () => {
		setDetailedItem({ id: adstream.id, type: 'adstream' })
	}
	useEffect(() => {
		getResolutionAndExtension()
		getChannels()
	}, [])

	const filteredChannels = useMemo(
		() =>
			channels.filter((channel) =>
				adstream.selection_ids.includes(channel.id)
			),
		[channels]
	)
	const handleEditVinstant = (link: string) => {
		onEditVinstant({
			instanceId: adstream.id,
			newContentKey: 'adstream',
			vinstantLink: link,
		})
	}
	return (
		<Accordion.Body className={ls.accordionBody}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
					padding: '16px',
				}}
			>
				<div
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '16px',
					}}
				>
					<AdvItemInfo
						budget={parseFloat(adstream?.amount || '0')}
						clicks={adstream.click_count}
						expenses={adstream.expense}
						format={'Изображение'}
						shows={adstream.views_count}
						status={adstream.state}
					/>
				</div>
				{!!adjusmentMessage && (
					<AdjusmentMessage
						isDocRequired={adstream.is_docs_required}
						adjusmentMessage={adjusmentMessage}
					/>
				)}
				{!!adstream.docs.length && (
					<DocsView docs={adstream.docs} id={adstream.id} />
				)}

				<CampaignItemInfoField
					title="Каналы"
					mainStyle={{ justifyContent: 'flex-start', paddingTop: 0 }}
					textStyle={{ marginLeft: '8px' }}
					text={filteredChannels.map((el) => el.name).join(',')}
				/>

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '16px',
					}}
				>
					{adstream.image ? (
						<div
							style={{
								display: 'flex',
								gap: '0.5rem',
								alignItems: 'flex-start',
							}}
						>
							<div className={ls.mediaCreativePreview}>
								<RenderPreview
									style={{
										width: '60px',
										height: '60px',
										borderRadius: '4px',
										minHeight: '60px',
										minWidth: '60px',
									}}
									value={{
										name: '',
										src: adstream.image,
									}}
								/>
								<div>
									<div>Adstream</div>
									<span>{resolution}</span>
								</div>
							</div>
							<EditVinstantButton
								editVinstanLink={adstream.edit_vinstant}
								onEditVinstant={handleEditVinstant}
								vinstantEditStatus={vinstantEditStatus}
							/>
						</div>
					) : (
						<h5 style={{ margin: 0 }}>Нет креатива</h5>
					)}

					<AdvItemActions
						setAdvItemActionType={setAdvItemActionType}
						state={adstream.state as AdvStateType}
						setDetailedItem={handleSetDetailedItem}
					/>
				</div>

				{/* documents */}
			</div>
		</Accordion.Body>
	)
}
