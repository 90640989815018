import React from 'react'
import { StepElement } from './StepElement'
import { getHelpImg, helpImgList } from '../../../constants'

export const StatisticContent = (props: any) => {
	return (
		<>
			<h2>Статистика</h2>

			<section>
				<h4 style={{ marginBottom: '24px' }}>Просмотр статистики</h4>
				<ol>
					<StepElement
						number={1}
						title={
							'Для того чтобы посмотреть статистику по рекламной кампании необходимо нажать на плюс «+» в карточке рекламной кампании'
						}
						url={getHelpImg(helpImgList.stats[1])}
						alt=""
					/>
					<StepElement
						number={2}
						title={
							<>
								<p>
									Отображается список всех размещений по
									текущей рекламной кампании. В случае, если в
									статистике вы видите нули, это могут быть 2
									варианта событий:
								</p>

								<ul style={{ padding: 0, listStyle: 'unset' }}>
									<li
										style={{ display: 'flex ', gap: '4px' }}
									>
										<span>a. </span>
										<div>
											Статистика у партнера подгружается в
											систему с задержкой.
										</div>
									</li>
									<li
										style={{ display: 'flex ', gap: '4px' }}
									>
										<span>b. </span>
										<div>
											Размещение не состоялось по
											техническим причинам. Admarket
											автоматически вернет Вам деньги до
											16 числа следующего месяца.
										</div>
									</li>
								</ul>
							</>
						}
						alt=""
						url={getHelpImg(helpImgList.stats[2])}
					/>
				</ol>
			</section>
			<section>
				<h4 style={{ marginBottom: '24px' }}>Формирование отчета</h4>
				<p>Вы можете сформировать отчет в таблице Excel. Для этого:</p>
				<ol>
					<li style={{ display: 'flex ', gap: '10px' }}>
						<span>1. </span>
						<div>Перейдите на страницу «Рекламные кампании»</div>
					</li>
					<StepElement
						number={2}
						title="Нажмите на кнопку «Сформировать отчет» в правом верхнем углу."
						alt=""
						url={getHelpImg(helpImgList.stats.forming[2])}
					/>

					<StepElement
						number={3}
						title="Шаг 1. Выберите кампании из списка, для которых хотите сформировать отчет."
						alt=""
						url={getHelpImg(helpImgList.stats.forming[3])}
					/>

					<StepElement
						number={4}
						title="Шаг 2. Выберите период размещения"
						alt=""
						url={getHelpImg(helpImgList.stats.forming[4])}
					/>
					<StepElement
						number={5}
						title="Шаг 3. Выберите тип и формат размещения. После выбора нужных параметров, нажмите на кнопку сформировать отчет. Все данные будет доступны вам в таблице Excel."
						alt=""
						url={getHelpImg(helpImgList.stats.forming[5])}
					/>
				</ol>
			</section>
		</>
	)
}
