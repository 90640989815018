import React, { FC } from 'react'
import s from './InternetAd.module.scss'
import useCampaignData from '../../../../stores/campaignData'
import InternetCard from './InternetCard'
import useResponsive from '../../../../utils/useResponsive'
import MobileInternetCard from './MobileInternetCard'
import { AdvTypeSummaryInfo } from '../common/adv-type-summary-info/AdvTypeSummaryInfo'

const InternetAd: FC = () => {
	const [campaign] = useCampaignData()
	const { isMobile } = useResponsive()
	return (
		<div className={s.internetContainer}>
			{campaign.internet_yandex.is_selected &&
				(isMobile ? (
					<MobileInternetCard name={'yandex'} />
				) : (
					<InternetCard name={'yandex'} />
				))}
			{campaign.internet_vk.is_selected &&
				(isMobile ? (
					<MobileInternetCard name={'vk'} />
				) : (
					<InternetCard name={'vk'} />
				))}
			<AdvTypeSummaryInfo advType="internet" />
		</div>
	)
}

export default InternetAd
