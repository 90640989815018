import React, { FC, useMemo, useState } from 'react'
import { BookingDetails } from '../../../../../stores/ProviderTypes.types'
import DetailItem from '../../../../../utils/DetailItem'
import { Alert, Button, Dropdown } from 'react-bootstrap'
import ModalMediaFormatQuickView from './ModalMediaFormatQuickView'
import declOfNum from '../../../../../utils/declOfNum'
import moment from 'moment'
import IntlFormatter from '../../../../../utils/intl-money-formatter'
import { BookingBadge } from '../components/BookingBadge'
import s from './bookingdetails.module.scss'
import ProviderYandexMap from '../../../../UtilityComponents/ProviderYandexMap'
import ModalBookingLocation from './ModalBookingLocation'
import ProgressFileInput from '../../../../UtilityComponents/ProgressFileInput'
import BookingsButtons from './BookingsButtons'
import { BookingState } from '../../../../../stores/ADMarketTypes.types'
import { useBookingsContext } from '../../context/BookingsProvider'
import useResponsive from 'src/utils/useResponsive'
import ModalYandexMap from 'src/components/UtilityComponents/ModalYandexMap'
import { BookingOnMapModal } from './BookingOnMapModal'

interface IBookingsDetailsLayout {
	booking: BookingDetails
	onModalClose: () => void
}

const BookingsDetailsLayout: FC<IBookingsDetailsLayout> = ({
	booking,
	onModalClose,
}) => {
	const [showModalQuickView, setShowModalQuickView] = useState(false)
	const [showLocationQuickView, setShowLocationQuickView] = useState(false)
	const [showFiltersInfo, setShowFiltersInfo] = useState(true)
	const [showModal, setShowModal] = useState<boolean | string>(false)

	const start_date = useMemo(() => {
		return moment(booking?.advertising_company?.start_date, 'YYYY-MM-DD')
	}, [booking])
	const end_date = useMemo(() => {
		return moment(booking?.advertising_company?.end_date, 'YYYY-MM-DD')
	}, [booking])
	const duration_in_days = useMemo(() => {
		return end_date.diff(start_date, 'day') + 1
	}, [start_date, end_date])
	const duration_in_weeks = useMemo(() => {
		const col = Math.round(
			(moment(end_date).diff(moment(start_date), 'd') + 1) / 7
		)
		return `${col} ${declOfNum(col, ['неделя', 'недели', 'недель'])}`
	}, [start_date, end_date])

	const { filterByCompany, filterByAd, setFilterByAd, setFilterByCompany } =
		useBookingsContext()
	const filterCountLabel = useMemo(
		() => filterByAd.length + filterByCompany.length,
		[filterByAd, filterByCompany]
	)

	const { isMobile } = useResponsive()
	return (
		<div className={'h-100 d-flex flex-column'}>
			<div style={{ overflowY: 'auto', flex: '1 1 0', padding: '24px' }}>
				<div
					className={
						'd-flex justify-content-between align-items-center mb-3'
					}
				>
					<div
						className={s.bookingDetailsImage}
						dangerouslySetInnerHTML={{
							__html: booking?.placement?.placement_type?.image,
						}}
						style={{
							backgroundColor: GetBackgroundColorByState(
								booking.state
							),
						}}
					/>
					<BookingBadge
						state={booking.state}
						providerConfirmed={booking.provider_confirmed}
					/>
				</div>
				<h5 className={'mb-1'}>
					{booking.placement.placement_type.name}
				</h5>
				<h5 className={'mb-4'}>{booking.placement.name}</h5>
				{booking.adjustment_msg &&
					booking.state === BookingState.Adjustment && (
						<Alert>
							<i className="bi bi-info-circle-fill me-3" />
							{booking?.adjustment_msg}
						</Alert>
					)}
				<h6 className={'mb-3'}>Данные о рекламной кампании</h6>
				<Dropdown.Divider className={'m-0 p-0'} />
				<DetailItem
					left={'Название:'}
					right={booking.advertising_company.name}
					fontSize={'16px'}
				/>
				<DetailItem
					left={'Срок размещения:'}
					right={
						<>
							{duration_in_weeks} (
							{duration_in_days +
								' ' +
								declOfNum(duration_in_days, [
									'день',
									'дня',
									'дней',
								])}
							)
						</>
					}
					fontSize={'16px'}
				/>
				<DetailItem
					left={'Дата начала и окончания:'}
					right={
						<>
							{start_date.format('DD.MM.YY')} -{' '}
							{end_date.format('DD.MM.YY')}
						</>
					}
					fontSize={'16px'}
				/>
				{booking.placement.door_type === 'outdoor' && (
					<DetailItem
						left={'Стоимость в день:'}
						right={DisplayPlacementPriceString(
							booking?.placement_showing_count
								?.showing_count_per_day,
							booking?.placement_showing_count?.price
						)}
						fontSize={'16px'}
					/>
				)}
				{booking.placement.door_type === 'indoor' && (
					<DetailItem
						left={'Стоимость в день:'}
						right={DisplayInnerPlacementPriceString(
							booking?.placement.screen_count,
							booking?.placement_showing_count?.price
						)}
						fontSize={'16px'}
					/>
				)}
				<DetailItem
					left={'Стоимость за период:'}
					right={IntlFormatter.format(booking.booking_budget)}
					fontSize={'16px'}
				/>
				<h6 className={'mb-3 mt-4'}>Данные о заказчике</h6>
				<Dropdown.Divider className={'m-0 p-0'} />
				<DetailItem
					left={'Телефон:'}
					right={
						booking.advertising_company.company.owner.phone_number
					}
					fontSize={'16px'}
				/>
				<DetailItem
					left={'Email:'}
					right={booking.advertising_company.company.owner.email}
					fontSize={'16px'}
				/>
				<DetailItem
					left={'Название:'}
					right={
						booking?.advertising_company?.company?.legal_short_name
					}
					fontSize={'16px'}
				/>

				<DetailItem
					left={'Организация или ИП:'}
					right={booking.advertising_company.company.legal_name}
					fontSize={'16px'}
				/>
				<h6 className={'mb-3 mt-4'}>Данные о месте</h6>
				<Dropdown.Divider className={'m-0 p-0'} />
				<DetailItem
					left={'ID:'}
					right={booking.placement.id}
					fontSize={'16px'}
				/>

				{!!booking.placement.external_id && (
					<DetailItem
						left={'Метка:'}
						right={booking.placement.external_id}
						fontSize={'16px'}
					/>
				)}

				{!!booking.placement.geo_title && (
					<DetailItem
						left={'Регион:'}
						right={booking.placement.geo_title}
						fontSize={'16px'}
					/>
				)}

				<DetailItem
					left={'Медиа-формат:'}
					right={
						<>
							<a
								href="/"
								onClick={(e) => {
									e.preventDefault()
									setShowModalQuickView(true)
								}}
							>
								{booking.placement.media_type.name}
							</a>
						</>
					}
					fontSize={'16px'}
				/>
				{showModalQuickView && (
					<ModalMediaFormatQuickView
						media_format={booking.placement.media_type}
						show={showModalQuickView}
						onHide={() => setShowModalQuickView(false)}
					/>
				)}
				<DetailItem
					left={'Тип:'}
					right={booking.placement.placement_type.image_type}
					fontSize={'16px'}
				/>
				<DetailItem
					left={'Размер:'}
					right={booking.placement.size}
					fontSize={'16px'}
				/>
				<DetailItem
					left={'Рекламу увидят:'}
					right={parseFloat(booking.placement.ots) + ' чел./день'}
					fontSize={'16px'}
				/>
				<DetailItem
					left={'Длительность показа'}
					right={
						booking.placement.media_type.duration +
						' ' +
						declOfNum(booking.placement.media_type.duration, [
							'секунда',
							'секунды',
							'секунд',
						])
					}
					fontSize={'16px'}
					show={booking.placement.media_type.duration}
				/>
				<DetailItem
					left={'График показов:'}
					right={
						<>
							{booking.placement.only_work_days
								? 'Только рабочие дни, '
								: 'В рабочие и выходные дни, '}
							<br />{' '}
							{booking.placement?.works_on_holidays
								? 'включая праздничные'
								: 'не включая праздничные'}
						</>
					}
					fontSize={'16px'}
				/>
				<DetailItem
					ellipsis={true}
					left={'Месторасположение:'}
					right={
						<>
							{booking.placement.lat.replace('.', '°') + "'N"},{' '}
							{booking.placement.lon.replace('.', '°') + "'E"}
						</>
					}
					fontSize={'16px'}
					bottomDivider={false}
				/>

				<div
					style={{
						width: '100%',
						height: '200px',
						cursor: 'pointer',
					}}
					className={
						'rounded-2 overflow-hidden mt-4 mb-3 position-relative'
					}
				>
					<ProviderYandexMap
						width={'100%'}
						height={'100%'}
						zoom={15}
						singleCoords={[
							booking?.placement?.lat,
							booking?.placement?.lon,
							booking?.placement?.placement_type?.image,
						]}
						fixed
						onClick={() => setShowLocationQuickView(true)}
						bookingCoords={[
							+booking?.placement?.lat || 0,
							+booking?.placement?.lon || 0,
						]}
					/>
					<Button
						variant={'secondary'}
						size={'sm'}
						className={'position-absolute'}
						style={{ top: '75%', right: '15px' }}
						onClick={() => setShowLocationQuickView(true)}
					>
						Смотреть на карте
					</Button>
				</div>
				{showLocationQuickView && (
					<BookingOnMapModal
						bookingCoords={[
							+booking?.placement?.lat || 0,
							+booking?.placement?.lon || 0,
						]}
						open={showLocationQuickView}
						onClose={() => setShowLocationQuickView(false)}
					/>
				)}

				<h6 className={'mb-3 mt-4'}>Загруженные материалы</h6>
				<div
					style={{
						marginLeft: '-16px',
						marginTop: '-16px',
						flex: '1 1 auto',
					}}
				>
					<ProgressFileInput
						initialValue={{
							src:
								booking.media_creative?.file ||
								'https://storage.yandexcloud.net/admarket-media-dev/empty_VmO0bhA.jpg',
							name:
								booking.media_creative?.file_name ||
								'Изображения нет',
						}}
						updateUrl={''}
						nameInBody={''}
						onlyView
					/>
				</div>
			</div>

			<div
				style={{
					height: '80px',
					borderTop: '1px solid #CED4DA',
					maxWidth: '100% ',
				}}
			>
				<BookingsButtons
					onModalClose={onModalClose}
					booking={booking}
				/>
			</div>
		</div>
	)
}

const DisplayPlacementPriceString = (
	showing_count_per_day: number,
	price_per_day: string
): string => {
	if (!showing_count_per_day || !price_per_day) return 'Ошибка в расчете цены'
	return `${IntlFormatter.format(
		price_per_day
	)} за ${showing_count_per_day} ${declOfNum(showing_count_per_day, [
		'показ',
		'показа',
		'показов',
	])} в день`
}

export const DisplayInnerPlacementPriceString = (
	screen_count: number,
	price_per_day: string
): string => {
	if (!screen_count || !price_per_day) return 'Ошибка в расчете цены'
	return `${IntlFormatter.format(
		price_per_day
	)} за ${screen_count} ${declOfNum(screen_count, [
		'экран',
		'экрана',
		'экранов',
	])} в день`
}

const GetBackgroundColorByState = (state: string) => {
	switch (state) {
		case 'moderation':
			return '#007bff'
		case 'inner_moderation':
			return '#007bff'
		case 'confirm':
			return '#28A745'
		case 'adjustment':
			return '#007bff'
		case 'completed':
			return '#6C757D'
		case 'reject':
			return '#DC3545'
		case 'canceled':
			return '#DC3545'
		default:
			return '007bff'
	}
}

export default BookingsDetailsLayout
