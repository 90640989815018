import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import useUserData from '../../stores/userData'
import ROUTES from '../../constants/Routes'

export default function PrivateUserRoute({ children, ...passThrough }) {
	const [user, userInterface] = useUserData()

	const isModerState = userInterface.getIsModerState()
	if (
		!user ||
		!user.phone_number ||
		!user.is_phone_confirmed ||
		user.provider !== null ||
		isModerState
	) {
		return <Redirect to={ROUTES.ROUTE_AUTH} />
	}
	return <Route {...passThrough}>{children}</Route>
}
