import React from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { NotificationState } from 'src/components/_moderator/constants'
import useModerationData from 'src/components/_moderator/store/moderationStore'

type Props = {
	notificationIds: number[]
	open: boolean
	onClose: () => void
	onDelete: () => void
}
export const DeleteConfirm = ({
	notificationIds,
	onClose,
	onDelete,
	open,
}: Props) => {
	const [moder, moderInterface] = useModerationData()

	const isLoading = moder.isModerLoading

	const modalTitle = `Вы уверены, что хотите удалить ${
		notificationIds.length > 1 ? 'уведомления' : 'уведомление'
	}?`
	const confirmationText = `После этого действия вы не сможете ${
		notificationIds.length > 1 ? 'их' : 'его'
	} восстановить`

	return (
		<Modal
			show={open}
			onHide={onClose}
			centered
			size={'sm'}
			animation={true}
		>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} className={'ms-2'}>
					{modalTitle}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ padding: '24px' }}>
				{confirmationText}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="danger" onClick={onClose}>
					Нет, отменить
				</Button>
				<Button
					disabled={isLoading}
					variant="primary"
					onClick={onDelete}
				>
					Да, удалить
					{isLoading && (
						<Spinner
							style={{ marginLeft: '6px' }}
							size="sm"
							animation="border"
						/>
					)}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
