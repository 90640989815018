import React from 'react'
import s from './passReset.module.scss'

export const PassResetHeader = () => {
	return (
		<div className={s.passResetHeader}>
			<h3>Сброс пароля</h3>
		</div>
	)
}
