import React from 'react'
import s from './header.module.scss'
import { MediaFormatCounts } from 'src/stores/technical-requirements-store'

type MediaFormatStatisticsProps = {
	counts: MediaFormatCounts
}
export const MediaFormatStatistics = ({
	counts,
}: MediaFormatStatisticsProps) => {
	return (
		<div className={s.mediaFormatStatistics}>
			{!!counts.approved && (
				<p style={{ color: '#28A745' }}>
					Подтверждено: {counts.approved}
				</p>
			)}
			{!!counts.rejected && (
				<p style={{ color: '#DC3545' }}>Отклонено: {counts.rejected}</p>
			)}

			<p style={{ color: '#6C757D' }}>Рекламных мест: {counts.total}</p>
		</div>
	)
}
