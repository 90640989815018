import React from 'react'
import s from '../../style.module.scss'
import cn from 'classnames'
import { ModerAdvCompanyItemType } from 'src/components/_moderator/types/ModerationRequestTypes'
import moment from 'moment'
import { campaignStatus } from 'src/components/_moderator/constants'
import ls from '.././style.module.scss'

type CampaignItemInfoHeaderProps = {
	campaign: ModerAdvCompanyItemType
}
export const CampaignItemInfoHeader = ({
	campaign,
}: CampaignItemInfoHeaderProps) => {
	return (
		<div className={cn(ls.campaignItemInfoHeader)}>
			<h5>
				{`${campaign.name} от ${moment(campaign.created_at).format(
					'DD.MM.YYYY'
				)}`}
			</h5>
			<div
				style={{
					backgroundColor:
						campaignStatus?.[campaign.state]?.color || 'gray',
				}}
			>
				<p className={cn(s.campaignItemStatus)}>
					{campaignStatus?.[campaign.state]?.title || campaign.state}
				</p>
			</div>
		</div>
	)
}
