import React from 'react'
type TempalteImagesListHeaderProps = {
	isMobile: boolean
	isNoTemplates: boolean
}

export const TempalteImagesListHeader = ({
	isMobile,
	isNoTemplates,
}: TempalteImagesListHeaderProps) => {
	return (
		<>
			{isNoTemplates ? (
				<div>
					{isMobile ? (
						<p>Cоздайте свой материал</p>
					) : (
						<h5>Создайте свой материал</h5>
					)}
				</div>
			) : (
				<div>
					{isMobile ? (
						<p>Выберите шаблон или создайте свой</p>
					) : (
						<h5>
							Выберите один из шаблонов или создайте свой материал
						</h5>
					)}
				</div>
			)}
		</>
	)
}
