import { ReportBreadCrumbsInfo } from '../../types'
import { CampaignReportStore } from '../campaignReportStore'

export const getReportBreadCrumbsInfo = (
	store: CampaignReportStore
): ReportBreadCrumbsInfo => {
	if (store.report) {
		return {
			name: store.campaignName || '',
			id: store.campaignId || '',
		}
	}

	return null
}
