import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import s from './Profile.module.scss'
import { Link } from 'react-router-dom'
import useUserData from '../../../stores/userData'

const Profile = ({}) => {
	const [user, userInterface] = useUserData()
	const [isOpen, setIsOpen] = useState(false)

	return (
		<Dropdown
			show={isOpen}
			onToggle={(nextShow) => setIsOpen(nextShow)}
			style={{ width: 'fit-content' }}
		>
			<Dropdown.Toggle as={DropDownToggle}>
				<ProfileIcon />
				<span className={'noselect'}>
					{user?.first_name
						? `${user?.first_name} ${user?.last_name}`
						: user?.phone_number}
				</span>
				<ArrowIcon open={isOpen} />
			</Dropdown.Toggle>
			<Dropdown.Menu>
				{/*providerMenu*/}
				{user?.provider && (
					<>
						<Link
							to={'/profile/'}
							style={{ textDecoration: 'none' }}
						>
							<DropDownItem>
								<AccountIcon />
								<span className={'noselect'}>Аккаунт</span>
							</DropDownItem>
						</Link>
						<Link
							to={'/settings/'}
							style={{ textDecoration: 'none' }}
						>
							<DropDownItem>
								<SettingsIcon />
								<span className={'noselect'}>Настройки</span>
							</DropDownItem>
						</Link>
						<DropDownItem onClick={userInterface.logout}>
							<LogoutIcon />
							<span className={'noselect'}>Выйти</span>
						</DropDownItem>
					</>
				)}
				{/*userMenu*/}
				{!user?.provider && (
					<>
						<Link
							to={'/profile/'}
							style={{ textDecoration: 'none' }}
						>
							<DropDownItem>
								<AccountIcon />
								<span className={'noselect'}>
									Личный кабинет
								</span>
							</DropDownItem>
						</Link>
						<DropDownItem onClick={userInterface.logout}>
							<LogoutIcon />
							<span className={'noselect'}>Выйти</span>
						</DropDownItem>
					</>
				)}
			</Dropdown.Menu>
		</Dropdown>
	)
}

const DropDownToggle = React.forwardRef(
	({ children, onClick, show, ...props }: any, ref: any) => {
		return (
			<div ref={ref} onClick={onClick} className={s.displayContainer}>
				{children}
			</div>
		)
	}
)
DropDownToggle.displayName = ''

const DropDownItem = (props) => {
	return (
		<div className={s.dropdownitem} {...props}>
			{props.children}
		</div>
	)
}

const ProfileIcon = () => (
	<div className={s.profileIcon}>
		<svg
			width="40"
			height="40"
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="20" cy="20" r="20" fill="#6C757D" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M20 10C17.2386 10 15 12.2386 15 15C15 17.7614 17.2386 20 20 20C22.7614 20 25 17.7614 25 15C25 12.2386 22.7614 10 20 10ZM17 15C17 13.3431 18.3431 12 20 12C21.6569 12 23 13.3431 23 15C23 16.6569 21.6569 18 20 18C18.3431 18 17 16.6569 17 15Z"
				fill="white"
			/>
			<path
				d="M20.0001 21C14.5897 21 11.5 25.5983 11.5 30H13.5C13.5 26.4017 15.9731 23 20.0001 23C24.0271 23 26.5 26.4017 26.5 30H28.5C28.5 25.5983 25.4106 21 20.0001 21Z"
				fill="white"
			/>
		</svg>
	</div>
)

export const ArrowIcon = ({ open }) => (
	<div style={open ? { transform: 'rotate(180deg)' } : {}}>
		<svg
			width="12"
			height="6"
			viewBox="0 0 12 6"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.52867 5.60947L0.862 0.942809L1.80481 0L6.00007 4.19526L10.1953 0L11.1381 0.942809L6.47148 5.60947C6.21113 5.86982 5.78902 5.86982 5.52867 5.60947Z"
				fill="#949494"
			/>
		</svg>
	</div>
)

const AccountIcon = () => (
	<svg
		width="18"
		height="20"
		viewBox="0 0 18 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9 0C6.23858 0 4 2.23858 4 5C4 7.76142 6.23858 10 9 10C11.7614 10 14 7.76142 14 5C14 2.23858 11.7614 0 9 0ZM6 5C6 3.34315 7.34315 2 9 2C10.6569 2 12 3.34315 12 5C12 6.65685 10.6569 8 9 8C7.34315 8 6 6.65685 6 5Z"
			fill="#212529"
		/>
		<path
			d="M9.0001 11C3.58967 11 0.5 15.5983 0.5 20H2.5C2.5 16.4017 4.97308 13 9.0001 13C13.0271 13 15.5 16.4017 15.5 20H17.5C17.5 15.5983 14.4106 11 9.0001 11Z"
			fill="#212529"
		/>
	</svg>
)

const SettingsIcon = () => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M18.4999 11C18.4999 10.8 18.4999 10.6 18.4999 10.5L21.0999 8.6L20.9999 7.9C20.9999 7.8 20.7999 6.9 20.0999 5.7C19.3999 4.5 18.7999 4 18.6999 3.9L18.1999 3.5L15.1999 4.8C14.8999 4.6 14.5999 4.4 14.2999 4.3L13.8999 1L13.2999 0.8C13.1999 0.8 12.2999 0.5 10.9999 0.5C9.6999 0.5 8.7999 0.8 8.6999 0.8L8.0999 1L7.6999 4.3C7.3999 4.4 7.0999 4.6 6.7999 4.8L3.7999 3.5L3.2999 3.9C3.1999 4 2.5999 4.6 1.8999 5.8C1.2999 6.9 0.999902 7.8 0.999902 7.9L0.899902 8.5L3.4999 10.4C3.4999 10.6 3.4999 10.8 3.4999 10.9C3.4999 11 3.4999 11.3 3.4999 11.4L0.899902 13.3L0.999902 14.1C0.999902 14.2 1.1999 15.1 1.8999 16.3C2.5999 17.4 3.1999 18.1 3.2999 18.1L3.7999 18.5L6.7999 17.2C7.0999 17.4 7.3999 17.6 7.6999 17.7L8.0999 21L8.6999 21.2C8.7999 21.2 9.6999 21.5 10.9999 21.5C12.2999 21.5 13.1999 21.2 13.2999 21.2L13.8999 21L14.2999 17.8C14.5999 17.6 14.8999 17.5 15.1999 17.3L18.1999 18.6L18.6999 18.2C18.7999 18.1 19.4999 17.5 20.0999 16.4C20.6999 15.3 20.9999 14.4 20.9999 14.2L21.0999 13.6L18.4999 11.7C18.4999 11.4 18.4999 11.2 18.4999 11ZM18.7999 14.3C18.6999 14.6 18.4999 14.9 18.2999 15.3C18.0999 15.7 17.8999 16 17.6999 16.2L14.8999 15L14.3999 15.4C13.9999 15.8 13.4999 16 12.8999 16.2L12.2999 16.4L11.9999 19.5C11.3999 19.6 10.3999 19.6 9.7999 19.5L9.4999 16.4L8.9999 16.1C8.4999 15.9 7.9999 15.6 7.4999 15.3L6.9999 14.9L4.1999 16.1C3.9999 15.9 3.7999 15.6 3.5999 15.2C3.3999 14.8 3.1999 14.5 3.0999 14.2L5.5999 12.4L5.4999 11.8C5.4999 11.5 5.3999 11.2 5.3999 11C5.3999 10.8 5.3999 10.4 5.4999 10.2L5.5999 9.6L3.1999 7.7C3.2999 7.4 3.4999 7.1 3.6999 6.7C3.8999 6.3 4.0999 6 4.2999 5.8L7.0999 7L7.5999 6.6C7.9999 6.4 8.4999 6.1 8.9999 5.9L9.5999 5.7L9.8999 2.6C10.4999 2.5 11.4999 2.5 12.0999 2.6L12.3999 5.7L12.9999 5.9C13.4999 6.1 13.9999 6.4 14.4999 6.7L14.9999 7.1L17.7999 5.9C17.9999 6.1 18.1999 6.4 18.3999 6.8C18.5999 7.2 18.7999 7.5 18.8999 7.8L16.3999 9.6L16.4999 10.2C16.4999 10.5 16.5999 10.8 16.5999 11C16.5999 11.2 16.5999 11.6 16.4999 11.8L16.3999 12.4L18.7999 14.3Z"
			fill="#212529"
		/>
		<path
			d="M10.9999 13C12.1045 13 12.9999 12.1046 12.9999 11C12.9999 9.89543 12.1045 9 10.9999 9C9.89533 9 8.9999 9.89543 8.9999 11C8.9999 12.1046 9.89533 13 10.9999 13Z"
			fill="#212529"
		/>
	</svg>
)

const LogoutIcon = () => (
	<svg
		width="18"
		height="20"
		viewBox="0 0 18 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M1 0H16V2H3.85078L6.62469 4.21913C6.86191 4.4089 7 4.69622 7 5V16H16V18H7V19C7 19.3844 6.77965 19.7348 6.43319 19.9013C6.08672 20.0678 5.67548 20.021 5.37531 19.7809L0.375305 15.7809C0.13809 15.5911 0 15.3038 0 15V1C0 0.447715 0.447716 0 1 0ZM5 5.48062L2 3.08062V14.5194L5 16.9194V5.48062Z"
			fill="#212529"
		/>
		<path
			d="M17.7071 9.70706C17.8946 9.51952 18 9.26517 18 8.99995C18 8.73474 17.8946 8.48038 17.7071 8.29285L13.7071 4.29285L12.2929 5.70706L14.5858 7.99995L9 8V10L14.5858 9.99995L12.2929 12.2928L13.7071 13.7071L17.7071 9.70706Z"
			fill="#212529"
		/>
	</svg>
)

export default Profile
