import React, { useEffect, useMemo, useState } from 'react'
import s from './CampaignList.module.scss'
import { Badge, Button, Dropdown } from 'react-bootstrap'
import { AnimateSharedLayout, motion } from 'framer-motion'
import MobileTabsDropDown from './MobileTabsDropDown'
import { useHistory } from 'react-router-dom'
import { ReactComponent as XLSIcon } from '../../../assets/images/xls.svg'
import { CampaignStates } from 'src/stores/ADMarketTypes.types'
import { CampaignsListTabsKeys } from './CampaignsList'
import useResponsive from 'src/utils/useResponsive'

const InitialTabs: {
	count: number
	title: CampaignsListTabsKeys
	display: string
}[] = [
	{
		count: 0,
		display: 'Черновики',
		title: CampaignStates.Draft,
	},
	{
		count: 0,
		display: 'На модерации',
		title: CampaignStates.Moderation,
	},
	{
		count: 0,
		display: 'Ожидают оплаты',
		title: CampaignStates.Payment_waiting,
	},
	{
		count: 0,
		display: 'Оплачены',
		title: CampaignStates.Paid,
	},
	{
		count: 0,
		display: 'Ожидают старта',
		title: CampaignStates.Start_waiting,
	},
	{
		count: 0,
		display: 'Активные',
		title: CampaignStates.Active,
	},
	{
		count: 0,
		display: 'Завершены',
		title: CampaignStates.Completed,
	},
	{
		count: 0,
		display: 'Отменены',
		title: CampaignStates.Canceled,
	},
	{
		count: 0,
		display: 'Все',
		title: 'total',
	},
]

type Props = {
	onCreateCampaign: () => void
	campaigns: any
	selectedTab: CampaignsListTabsKeys
	onTabChange: (tab: CampaignsListTabsKeys) => void
	isActionButtons: boolean
}
const HeaderWithTabs = ({
	onCreateCampaign,
	campaigns,
	selectedTab,
	onTabChange,
	isActionButtons,
}: Props) => {
	const history = useHistory()
	const { isMobile } = useResponsive()

	const TabsWithCounts = useMemo(() => {
		if (!campaigns) return InitialTabs
		return InitialTabs.map((tab) => {
			return { ...tab, count: campaigns[`count_` + tab.title] }
		})
	}, [campaigns])

	const onCreateReport = async () => {
		history.push('/create-report/')
	}
	return (
		<>
			{!isMobile && (
				<div className={s.tabs}>
					<AnimateSharedLayout>
						{TabsWithCounts.map((tab) => {
							return (
								<motion.div
									key={tab.title}
									className={s.tab + ' noselect'}
									onClick={(e) => {
										onTabChange(tab.title)
									}}
									style={{
										position: 'relative',
										color:
											selectedTab === tab.title
												? '#007BFF'
												: '#6C757D',
									}}
								>
									{tab.display}
									<Badge
										bg={
											selectedTab === tab.title
												? 'primary'
												: 'secondary'
										}
									>
										{tab.count}
									</Badge>
									{selectedTab === tab.title && (
										<ActiveLine />
									)}
								</motion.div>
							)
						})}
					</AnimateSharedLayout>
				</div>
			)}

			{!isMobile && (
				<Dropdown.Divider
					className={'p-0 m-0'}
					style={{ color: '#ced4da' }}
				/>
			)}

			{isActionButtons && (
				<div className={s.header}>
					<Button onClick={onCreateCampaign}>Создать кампанию</Button>
					<Button
						style={{
							fontFamily: 'Inter',
							fontStyle: 'normal',
							fontWeight: 500,
							fontSize: '16px',
							lineHeight: '24px',
						}}
						onClick={onCreateReport}
						variant="light"
					>
						<XLSIcon style={{ marginTop: '-4px' }} /> Сформировать
						отчет
					</Button>
				</div>
			)}

			{isMobile && (
				<>
					<Dropdown.Divider className={'p-0 m-0'} />
					<MobileTabsDropDown
						tabs={TabsWithCounts}
						setSelectedTab={onTabChange}
						selectedTab={selectedTab}
					/>
				</>
			)}
		</>
	)
}

export const ActiveLine = () => {
	return (
		<motion.div
			layoutId={'active-line'}
			style={{
				width: '100%',
				height: '2px',
				position: 'absolute',
				bottom: '-2px',
				left: '0',
				backgroundColor: '#0D6EFD',
			}}
		/>
	)
}

export default HeaderWithTabs
