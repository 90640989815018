import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { campaignReportApi } from './campaignReportApi'
import {
	CampaignReportData,
	CampaignReportDetailedData,
	ReportDetailType,
} from '../types'
import { finalReportMapper } from '../utils'

type State = CampaignReportData &
	CampaignReportDetailedData & {
		isLoading: boolean
		campaignId: string | null
		campaignName: string | null
	}
type Actions = {
	fetchCampaignReport: (campaignId: string) => Promise<void>
	fetchDetailedReport: (
		campaignId: string,
		advFormat: ReportDetailType
	) => void
}

const defaultDetailedReport = {
	adstream: null,
	indoor: null,
	outdoor: null,
	district_booking: null,
	public_transport: null,
	vk: null,
	yandex: null,
}
const initialState: State = {
	detailedReport: {
		...defaultDetailedReport,
	},
	campaignName: null,
	campaignId: null,
	report: null,
	isLoading: false,
}
export type CampaignReportStore = State & Actions
export const useCampaignReportStore = create<CampaignReportStore>()(
	devtools(
		immer((set, get) => ({
			//state
			...initialState,

			//actions
			fetchCampaignReport: async (campaignId: string) => {
				if (get().campaignId === campaignId) {
					return
				}

				set((state) => {
					state.isLoading = true
				})

				const report = await campaignReportApi.fetchReport(campaignId)

				const parsedReport = finalReportMapper(report)

				set((state) => {
					state.campaignName = report.name
					state.campaignId = campaignId
					state.report = parsedReport
					state.isLoading = false
				})
			},
			fetchDetailedReport: async (
				campaignId: string,
				advFormat: ReportDetailType
			) => {
				const report = await campaignReportApi.fetchAdvReportDetail(
					campaignId,
					advFormat
				)

				switch (advFormat) {
					case 'indoor':
						const indoors = report?.booking?.filter(
							(booking) =>
								booking.placement.door_type === 'indoor'
						)
						set((state) => {
							state.detailedReport['indoor'] = indoors || null
						})
						break

					case 'outdoor':
						const outdoors = report?.booking?.filter(
							(booking) =>
								booking.placement.door_type === 'outdoor'
						)

						set((state) => {
							state.detailedReport['outdoor'] = outdoors || null
							state.detailedReport['district_booking'] =
								report.district_booking
						})
						break
					case 'internet':
						set((state) => {
							state.detailedReport['vk'] = report.vk
							state.detailedReport['yandex'] = report.yandex
						})
						break
					case 'publicTransport':
						set((state) => {
							state.detailedReport['public_transport'] =
								report.public_transport
						})
						break
					case 'tv':
						set((state) => {
							state.detailedReport['adstream'] = report.adstream
						})
						break
				}
			},
		}))
	)
)
