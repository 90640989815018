import React from 'react'

type ProviderFilterContentBodyProps = {
	children: React.ReactNode
}
export const ProviderFilterContentBody = ({
	children,
}: ProviderFilterContentBodyProps) => {
	return (
		<div
			id={'provider-filter-content-body'}
			style={{
				padding: '8px 16px ',
				overflowY: 'auto',
				flex: '1 1 auto',
			}}
		>
			{children}
		</div>
	)
}
