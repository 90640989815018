import React, { useMemo } from 'react'
import { CustomRange } from 'src/components/UtilityComponents'
import s from './itemsControl.module.scss'
import useUserData from 'src/stores/userData'
import { UserConfig } from 'src/components/_client/create-campaign/Step2-ADformats/new-adformats-step/types'

const defaultRadiusRange = {
	min: 500,
	max: 10000,
}

type Props = {
	radius: number
	value: string
	onRadiusChange: (value: number) => void
}
export const RadiusRange = ({ onRadiusChange, radius, value }: Props) => {
	const [userData, _] = useUserData()

	const config = userData?.config as UserConfig | undefined

	const radiusRange = useMemo(() => {
		return config
			? {
					min: config.min_radius_selected_place,
					max: config.max_radius_selected_place,
			  }
			: defaultRadiusRange
	}, [userData])
	return (
		<div className={s.rangeControl}>
			<p>Радиус вокруг выбранной точки</p>

			<div className={s.rangeControl_slider}>
				<CustomRange
					max={radiusRange.max}
					min={radiusRange.min}
					value={radius}
					onChange={onRadiusChange}
				/>
				<div className={s.rangeControl_value}>
					<p>{`${value} км`}</p>
				</div>
			</div>
		</div>
	)
}
