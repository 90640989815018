import React, { useCallback, useMemo, useState } from 'react'
import { ProviderBookingQueryParams } from '../../../booking-filters-mobile/BookingsSettings'
import useProviderData from 'src/stores/providerData'
import { ProviderFilterCheckbox } from 'src/components/_provider/Bookings/filters/components/common/ProviderFilterCheckbox'
import { useQueryParams } from 'src/utils'
import { ProviderFilterContentHeader } from '../../common/ProviderFilterContentHeader'
import { ProviderFilterContentBody } from '../../common/ProviderFilterContentBody'
import { ProviderFilterContentFooter } from '../../common/ProviderFilterContentFooter'

type BookingCompanyFilterContentProps = {
	onFilterSubmit: (queryParams: ProviderBookingQueryParams) => void
	onClose: () => void
}
export const BookingCompanyFilterContent = ({
	onFilterSubmit,
	onClose,
}: BookingCompanyFilterContentProps) => {
	const [provider] = useProviderData()

	const { params, pathName } =
		useQueryParams<Partial<ProviderBookingQueryParams>>()

	const [selectedCompanies, setSelectedCompanies] = useState(() => {
		if (params?.company) {
			if (Array.isArray(params?.company)) return params?.company
			return [params?.company]
		} else {
			return []
		}
	})

	const companies = provider?.bookings_filters?.companies

	const [searchString, setSearchString] = useState('')

	const filtredCompanies = useMemo(
		() =>
			companies?.filter((el) =>
				el.name.toLowerCase().includes(searchString.toLowerCase())
			),
		[searchString, companies]
	)

	const handleSelectedChange = (capmaignId: string) => {
		if (selectedCompanies.includes(capmaignId)) {
			setSelectedCompanies(
				selectedCompanies.filter((el) => el !== capmaignId)
			)
		} else {
			setSelectedCompanies([...selectedCompanies, capmaignId])
		}
	}

	const handleFilterSubmit = () => {
		onFilterSubmit({
			...params,
			company: selectedCompanies,
		} as ProviderBookingQueryParams)
	}
	const selectList = useMemo(
		() =>
			filtredCompanies?.map((el) => (
				<ProviderFilterCheckbox
					checked={selectedCompanies.includes(el.id)}
					onChange={() => handleSelectedChange(el.id)}
					text={el.name}
					key={el.id}
				/>
			)),
		[searchString, companies, selectedCompanies]
	)
	const handleChangeAll = useCallback(() => {
		if (selectedCompanies?.length >= filtredCompanies?.length) {
			return setSelectedCompanies([])
		} else {
			setSelectedCompanies(
				filtredCompanies?.map((campaign) => campaign.id)
			)
		}
	}, [selectedCompanies, companies, filtredCompanies])
	return (
		<div
			id={'provider-filter-content-wrapper'}
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				width: '100%',
			}}
		>
			<ProviderFilterContentHeader
				onClick={handleChangeAll}
				isAllSelected={
					selectedCompanies.length >= filtredCompanies?.length
				}
				count={selectedCompanies.length}
				setSearchString={setSearchString}
			/>

			<ProviderFilterContentBody>
				<div>{selectList}</div>
			</ProviderFilterContentBody>

			<ProviderFilterContentFooter
				onApplyFilters={handleFilterSubmit}
				onClose={onClose}
			/>
		</div>
	)
}
