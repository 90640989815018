import React from 'react'
import { Button } from 'react-bootstrap'
import useResponsive from 'src/utils/useResponsive'

type ProviderFilterContentFooterProps = {
	onApplyFilters: () => void
	onClose: () => void
}
export const ProviderFilterContentFooter = ({
	onApplyFilters,
	onClose,
}: ProviderFilterContentFooterProps) => {
	const { isMobile } = useResponsive()
	return (
		<div
			style={{
				padding: '8px 16px ',
				height: '100px',
				borderTop: '1px solid #CED4DA',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: isMobile ? 'column' : 'row',
					gap: '8px',
					width: '100%',
					justifyContent: 'flex-end',
				}}
			>
				<Button onClick={onClose} variant="secondary">
					Отменить
				</Button>
				<Button onClick={onApplyFilters}>Применить</Button>
			</div>
		</div>
	)
}
