import { Center } from '../components/outdoor-mass-coverage/modals/MassCoverageModalMap'
import { ymaps } from '../components/outdoor-mass-coverage/modals/MassCoverageYandexMap'

type PaintCircle = {
	map: any
	center: Center
	setCenter: (center: Center) => void
	radius?: number
}
export const paintCircle = ({
	center,
	map,
	setCenter,
	radius,
}: PaintCircle) => {
	if (map) {
		let circle = new ymaps.Circle(
			[
				[center.lat, center.lon], // Circle center coordinates (latitude, longitude)
				radius || 500, // Circle radius in meters
			],
			{},
			{
				strokeColor: '#007bff',
				strokeWidth: 2,
				opacity: 0.9,
				fillColor: '#007bff',
				fillOpacity: 0.2,
				cursor: 'default',
				draggable: false,
			}
		)

		let centerPlacemark = new ymaps.Placemark(
			circle.geometry.getCoordinates(),
			null,
			{
				preset: 'islands#governmentCircleIcon',
				iconColor: '#3b5998',
				draggable: true,
			}
		)

		centerPlacemark.events.add('dragend', function (event) {
			var newCoordinates = centerPlacemark.geometry.getCoordinates() as [
				number,
				number
			]
			setCenter({ lat: newCoordinates[0], lon: newCoordinates[1] })
			circle.geometry.setCoordinates(newCoordinates)
		})
		map?.geoObjects.add(centerPlacemark)
		map?.geoObjects.add(circle)

		return { circle, centerPlacemark }
	}
}
