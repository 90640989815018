import React from 'react'
import { HandleActiveItemChangeType, HelpContentKey, HelpListBaseItemType } from '../../constants'
import s from './helpList.module.scss'
import cn from 'classnames'
import { motion } from 'framer-motion'

type NestedListProps = {
	activeNestedItem: string | undefined
	itemId: HelpContentKey
	items: HelpListBaseItemType[]
	changeNestedActive: HandleActiveItemChangeType
	isParentActive: boolean
}
const list = {
	visible: { opacity: 1 },
	hidden: { opacity: 0 },
}

const motionItem = {
	visible: { opacity: 1, x: 0 },
	hidden: { opacity: 0, x: -100 },
}

export const NestedList = ({
	activeNestedItem,
	itemId,
	items,
	changeNestedActive,
	isParentActive,
}: NestedListProps) => {
	const changeActive = (nestedItemId: HelpContentKey) => {
		changeNestedActive(itemId, nestedItemId)
	}
	return (
		<motion.ul
			initial="hidden"
			animate="visible"
			variants={list}
			style={{ listStyleType: 'none', padding: 0 }}
		>
			{items.map((el) => (
				<NestedItem
					changeActive={() => changeActive(el.key)}
					isActive={activeNestedItem === el.key && isParentActive}
					item={el}
					key={el.key}
				/>
			))}
		</motion.ul>
	)
}
type NestedItemProps = {
	changeActive: () => void
	isActive: boolean
	item: HelpListBaseItemType
}
export const NestedItem = ({ changeActive, isActive, item }: NestedItemProps) => {
	return (
		<motion.li
			variants={motionItem}
			className={cn(s.listItem, {
				[s.covered]: isActive,
			})}
		>
			<div onClick={changeActive} className={cn(s.nestedItemContent)}>
				<h6 className={cn({ [s.bold]: isActive })}>{item.title}</h6>
			</div>
		</motion.li>
	)
}
