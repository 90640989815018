import React from 'react'
import { InfoAboutPlace } from 'src/components/_client/create-campaign/Step3-Upload/components/booking/BookingUploadItem'
import useCampaignData from 'src/stores/campaignData'

type Props = {
	bookingId: number
}
export const InfoAboutPlaceWrapper = ({ bookingId }: Props) => {
	const [campaign, campaignInterface] = useCampaignData()

	const booking = campaign?.booking?.find((b) => b.id === bookingId)

	return (
		<div>
			{booking?.placement && booking.placement_showing_count && (
				<InfoAboutPlace
					onHide={() => {}}
					placement={booking.placement}
					placement_showing_count={booking.placement_showing_count}
					withPrice={true}
				/>
			)}
		</div>
	)
}
