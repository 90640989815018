import React, { useMemo } from 'react'
import { InternetVariantSelector } from './internet-variant-selector/InternetVariantSelector'
import { InternetVariants, VariantState } from '../../../../../../types'
import s from './internetVariantsSelectors.module.scss'
import useUserData from 'src/stores/userData'
import { getInternetVariantsState } from 'src/components/_client/create-campaign/Step2-ADformats/new-adformats-step/utils'

type Props = {
	isLoading: boolean
	onSelectorClick: (variant: InternetVariants) => void
	selectedVariants: InternetVariants[]
}
export const InternetVariantsSelectors = ({
	isLoading,
	onSelectorClick,
	selectedVariants,
}: Props) => {
	const [userData, _] = useUserData()

	const enabledStates = useMemo(() => {
		return getInternetVariantsState(userData?.config)
	}, [userData])

	return (
		<div className={s.selectors}>
			<InternetVariantSelector
				disabled={enabledStates.yandex === VariantState.Disabled}
				onClick={onSelectorClick}
				isSelected={selectedVariants.includes(InternetVariants.Yandex)}
				selectorKey={InternetVariants.Yandex}
				isLoading={isLoading}
				title="Яндекс"
				description={
					'Авито, Mail.ru, Яндекс.Новости, Яндекс.Дзен, Яндекс.Спорт, Gismeteo и другие'
				}
			/>

			<InternetVariantSelector
				disabled={enabledStates.vk === VariantState.Disabled}
				onClick={onSelectorClick}
				isSelected={selectedVariants.includes(InternetVariants.Vk)}
				selectorKey={InternetVariants.Vk}
				isLoading={isLoading}
				title="VK"
				description={'ВКонтакте, Одноклассники, Юла, Mail.ru, MyTarget'}
			/>
		</div>
	)
}
