import { MediaRequirements } from 'src/stores/technical-requirements-store'

export const getMaxFileSize = (mediaRequierments: MediaRequirements) => {
	let imageMaxSize = 0
	let videoMaxSize = 0
	if (mediaRequierments.is_image && mediaRequierments.image_max_size) {
		imageMaxSize = mediaRequierments.image_max_size
	}

	if (mediaRequierments.is_video && mediaRequierments.video_max_size) {
		videoMaxSize = mediaRequierments.video_max_size
	}

	return Math.max(imageMaxSize, videoMaxSize)
}
