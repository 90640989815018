import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Button, Spinner } from 'react-bootstrap'
import {
	REUSABLE_MODALS,
	useReusableModals,
} from 'src/components/UtilityComponents/ReusableModals/ReusableModalsProvider'
import useResponsive from 'src/utils/useResponsive'

export const MediaPlanWatcher = (props: any) => {
	const [isLoading, setIsLoading] = useState(false)
	const showModal = useReusableModals()

	const handleMediaPlanClick = async () => {
		setIsLoading(true) // Показать спиннер загрузки перед открытием модального окна
		showModal(REUSABLE_MODALS.showMediaPlanModal)
	}
	const { isMobile } = useResponsive()
	useEffect(() => {
		let timeoutId: NodeJS.Timeout

		if (isLoading) {
			timeoutId = setTimeout(() => {
				setIsLoading(false)
			}, 3000)
		}

		return () => clearTimeout(timeoutId)
	}, [isLoading])

	return ReactDOM.createPortal(
		<>
			<Button
				variant={'light'}
				onClick={handleMediaPlanClick}
				className={'w-100'}
				disabled={isLoading}
				style={{ background: '#FFFFFF', marginBottom: '12px' }}
			>
				Смотреть медиаплан
				{isLoading && (
					<Spinner animation="border" size="sm" className="mw-2" />
				)}
			</Button>
		</>,
		// @ts-ignore:next-line
		document.getElementById(
			isMobile
				? 'create-campaign-stepper-wpapper'
				: 'create-campaign-stepper'
		)
	)
}
