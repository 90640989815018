import { CampaignInfoType } from 'src/stores/ADMarketTypes.types'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

export type AdFormatError = {
	field: string
	message: string
	id: string
	parentId?: string | number
}

export type AdFormat = {
	isSelected: boolean
	errors: AdFormatError[] | null
}

const defaultAdFormat: AdFormat = {
	isSelected: false,
	errors: null,
}

export type AdFormatKeys =
	| 'massCoverage'
	| 'garant'
	| 'indoorAd'
	| 'vk'
	| 'yandex'
	| 'mcr'
	| 'mcd'
	| 'adstream'

export type AdFormatsType = {
	[key in AdFormatKeys]: AdFormat
}

const adFormats: AdFormatsType = {
	massCoverage: defaultAdFormat,
	garant: defaultAdFormat,
	indoorAd: defaultAdFormat,
	vk: defaultAdFormat,
	yandex: defaultAdFormat,
	mcr: defaultAdFormat,
	mcd: defaultAdFormat,
	adstream: defaultAdFormat,
}

export const adFormatKeysTranslation: {
	[key in AdFormatKeys]: string
} = {
	adstream: 'ТВ-реклама',
	garant: 'Гарантированные показы',
	indoorAd: 'Внутренняя реклама',
	massCoverage: 'Максимальный охват',
	mcd: 'Общественный транспорт - МЦД',
	mcr: 'Общественный транспорт - МЦК',
	vk: 'ВК-реклама',
	yandex: 'Яндекс-реклама',
}
type State = {
	isLoading: boolean
} & typeof adFormats

type Actions = {
	initilizeSelectedState: (campaign: CampaignInfoType) => void
	toggleIsLoading: (isLoading: boolean) => void
	toggleAdFormatSelectedState: (
		adFormatKey: keyof typeof adFormats,
		isSelected: boolean
	) => void
	setAdFormatErrors: (
		adFormatKey: keyof typeof adFormats,
		errors: AdFormatError[] | null
	) => void
	removeAdFormatErrorByErrorId: (
		adFormatKey: AdFormatKeys,
		errorId: string
	) => void
	addNewAdFormatError: (
		adFormatKey: keyof typeof adFormats,
		error: AdFormatError
	) => void
	removeAdFormateByErrorParentId: (
		adFormatKey: AdFormatKeys,
		parentId: number | string
	) => void
}
export type AdformatsStore = State & Actions
export const useAdformatsStore = create<AdformatsStore>()(
	devtools(
		immer((set) => ({
			//state
			...adFormats,
			isLoading: false,

			//actions
			initilizeSelectedState: (campaign: CampaignInfoType) =>
				set((state) => {
					state.massCoverage.isSelected =
						campaign.district_adv_is_selected
					state.garant.isSelected = campaign.outdoor_adv_is_selected
					state.indoorAd.isSelected = campaign.indoor_adv_is_selected
					state.vk.isSelected = campaign.internet_vk.is_selected
					state.yandex.isSelected =
						campaign.internet_yandex.is_selected
					state.mcr.isSelected =
						campaign.public_transport?.is_selected || false
					state.mcd.isSelected = false
					state.adstream.isSelected = campaign.adstream.is_selected
				}),
			toggleIsLoading: (isLoading: boolean) =>
				set((state) => (state.isLoading = isLoading)),
			toggleAdFormatSelectedState: (
				adFormatKey: keyof typeof adFormats,
				isSelected: boolean
			) =>
				set((state) => {
					state[adFormatKey].isSelected = isSelected
				}),
			setAdFormatErrors: (
				adFormatKey: keyof typeof adFormats,
				errors: AdFormatError[] | null
			) =>
				set((state) => {
					state[adFormatKey].errors = errors
				}),
			addNewAdFormatError: (
				adFormatKey: keyof typeof adFormats,
				error: AdFormatError
			) =>
				set((state) => {
					if (state[adFormatKey].errors) {
						const currentError = state[adFormatKey].errors?.find(
							(err) => err.id === error.id
						)

						if (currentError) {
							state[adFormatKey].errors = state[
								adFormatKey
							].errors!.map((el) =>
								el.id !== error.id ? el : error
							)
						} else {
							state[adFormatKey].errors?.push(error)
						}
					} else {
						state[adFormatKey].errors = [error]
					}
				}),
			removeAdFormatErrorByErrorId: (
				adFormatKey: AdFormatKeys,
				errorId: string
			) =>
				set((state) => {
					const newErrors = state[adFormatKey].errors?.filter(
						(error) => error.id !== errorId
					)
					if (newErrors) {
						state[adFormatKey].errors = newErrors
					} else {
						state[adFormatKey].errors = null
					}
				}),
			removeAdFormateByErrorParentId: (
				adFormatKey: AdFormatKeys,
				parentId: number | string
			) => {
				set((state) => {
					const newErrors = state[adFormatKey].errors?.filter(
						(error) => error.parentId !== parentId
					)
					if (newErrors) {
						state[adFormatKey].errors = newErrors
					} else {
						state[adFormatKey].errors = null
					}
				})
			},
		}))
	)
)
