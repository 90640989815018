import { BASE_URL } from 'src/constants/api'
import { authFetch } from 'src/utils/authFetch'
import { ReportDetailType } from '../types/commonTypes'
import { DetailStatsResponse, FinalStatsResponse } from '../types'

export const campaignReportApi = {
	fetchReport: async (campaignId: string) => {
		const url = `${BASE_URL}/adv_companies/${campaignId}/final-stats/`

		const result = await authFetch<FinalStatsResponse>({
			url,
			method: 'GET',
		})

		return result
	},
	fetchAdvReportDetail: async (
		campaignId: string,
		advFormat: ReportDetailType
	) => {
		const url = `${BASE_URL}/adv_companies/${campaignId}/final-stats/detail/?direction=${advFormat}`

		const result = await authFetch<DetailStatsResponse>({
			url,
			method: 'GET',
		})

		return result
	},

	// downloadReport: async (campaignId: string) => {
	// 	const url = `${BASE_URL}/adv_companies/${campaignId}/final-stats/download/`

	// 	const result = await authFetch<any>({
	// 		url,
	// 		method: 'POST',
	// 	})

	// 	return result
	// },
}
