import React, { FC } from 'react'

interface INoOperationsWithFilters {
	resetFilters: () => void
}

const NoOperationsWithFilters: FC<INoOperationsWithFilters> = ({
	resetFilters,
}) => {
	return (
		<div
			className={
				'w-100 h-100 d-flex flex-column align-items-center justify-content-center gap-4'
			}
		>
			<h5 className={'mb-0'}>Операции не найдены</h5>
			<span className={'text-secondary'}>
				Вы можете изменить или{' '}
				<a
					href="/"
					onClick={(e) => {
						e.preventDefault()
						resetFilters()
					}}
				>
					сбросить
				</a>{' '}
				фильтры.
			</span>
		</div>
	)
}

export default NoOperationsWithFilters
