import React from 'react'
import { Form } from 'react-bootstrap'

import s from './style.module.scss'
type ItemCheckboxProps = {
	checked: boolean
	onCheckBoxClick: () => void
	style?: React.CSSProperties
}
export const ItemCheckbox = ({
	checked,
	onCheckBoxClick,
	style,
}: ItemCheckboxProps) => {
	return (
		<div
			className={s.itemCheckBox}
			onClick={() => {
				onCheckBoxClick()
			}}
			style={style}
		>
			<Form.Check checked={checked} onChange={(e) => {}} />
		</div>
	)
}
