import React, { FC, useState } from 'react'
import DetailItem from '../../../../utils/DetailItem'
import { Button, Dropdown } from 'react-bootstrap'
import {
	BookingState,
	BookingType,
} from '../../../../stores/ADMarketTypes.types'
import { useConfirm } from '../../../UtilityComponents/ConfirmModalProvider'
import useCampaignData from '../../../../stores/campaignData'
import MobileModal from '../../../UtilityComponents/MobileModal'
import { InfoAboutPlace } from '../../create-campaign/Step3-Upload/components/booking/BookingUploadItem'
import { PlaceRequirements } from '../../create-campaign/Step3-Upload/components/booking/place-requirements/PlaceRequirements'
import BookingCalendar from './BookingCalendar'
import ProgressFileInput from '../../../UtilityComponents/ProgressFileInput'

interface IMobileBookingControl {
	children?: React.ReactNode | React.ReactNode[]
	booking: BookingType
}

const MobileBookingControl: FC<IMobileBookingControl> = ({ booking }) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { confirm } = useConfirm()

	const [showMobileModal, setShowMobileModal] = useState<
		false | 'requirement' | 'info'
	>(false)
	const media =
		campaign.media.find((m) => m.booking_id === booking.id) ||
		campaign.media[0]

	async function DeletePlacementHandler() {
		const result = await confirm({
			text: 'После этого действия вы не сможете ее вернуть обратно',
			title: 'Вы уверены, что хотите удалить рекламное место из рекламной кампании?',
			closeButton: 'true',
			acceptText: 'Да, уверен',
			declineText: 'Нет, отменить',
			acceptVariant: 'danger',
		})
		if (result) {
			await campaignInterface.cancelBooking(booking?.id)
		}
	}

	const showDeleteButton =
		booking.state !== BookingState.Canceled &&
		booking.state !== BookingState.Reject

	return (
		<div style={{ padding: '20px 16px 16px' }}>
			<DetailItem
				left={
					<h5 className={'m-0'} style={{ fontSize: '16px' }}>
						Подробнее о месте
					</h5>
				}
				right={<ArrowRight />}
				leftSecondary={false}
				onClick={() => setShowMobileModal('info')}
			/>
			<DetailItem
				left={
					<h5 className={'m-0'} style={{ fontSize: '16px' }}>
						Требования к материалу
					</h5>
				}
				right={<ArrowRight />}
				leftSecondary={false}
				bottomDivider={false}
				onClick={() => setShowMobileModal('requirement')}
			/>
			{showDeleteButton && (
				<Button
					variant={'danger'}
					className={'w-100 mt-2'}
					onClick={DeletePlacementHandler}
				>
					<i className={'bi bi-trash me-1'} />
					Удалить
				</Button>
			)}
			<MobileModal
				onHide={() => setShowMobileModal(false)}
				show={Boolean(showMobileModal)}
				title={
					showMobileModal === 'requirement'
						? 'Технические требования к материалу'
						: 'Подробнее о месте'
				}
				zIndex={2000}
			>
				{showMobileModal === 'requirement' && (
					<PlaceRequirements
						placement={booking.placement}
						noMobileHeader={true}
					/>
				)}
				{showMobileModal === 'info' && (
					<>
						<InfoAboutPlace
							onHide={() => {}}
							placement={booking.placement}
							placement_showing_count={
								booking?.placement_showing_count
							}
							withPrice={true}
							noMobileHeader={true}
						/>
						<Dropdown.Divider className={'m-0 p-0 mb-3'} />
						<div style={{ width: '270px', margin: '0 auto' }}>
							<BookingCalendar booking={booking} />
						</div>
						<Dropdown.Divider className={'m-0 p-0 mb-3 mt-3'} />
						<div
							style={{
								marginLeft: '-16px',
								marginTop: '-16px',
							}}
						>
							<ProgressFileInput
								initialValue={{
									src:
										media?.file ||
										'https://storage.yandexcloud.net/admarket-media-dev/empty_VmO0bhA.jpg',
									name: media?.file_name || 'Изображения нет',
								}}
								updateUrl={''}
								nameInBody={''}
								onlyView
							/>
						</div>
					</>
				)}
			</MobileModal>
		</div>
	)
}

export const ArrowRight = () => (
	<svg
		width="7"
		height="11"
		viewBox="0 0 7 11"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M5.94285 6.23545L1.27618 10.9021L0.333374 9.95931L4.52864 5.76405L0.333374 1.56879L1.27618 0.625977L5.94285 5.29264C6.2032 5.55299 6.2032 5.9751 5.94285 6.23545Z"
			fill="#6C757D"
		/>
	</svg>
)

export default MobileBookingControl
