import React from 'react'
import { ReactComponent as Upload } from 'src/assets/images/upload.svg'
import { ReactComponent as Download } from 'src/assets/images/download.svg'
import { Button } from 'react-bootstrap'

type Props = {
	setModalType: (type: 'export' | 'import') => void
}

export const PlacementsImportExportActionSelect = ({ setModalType }: Props) => {
	return (
		<div
			style={{
				display: 'flex',
				gap: '1rem',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '0.5rem',
				}}
			>
				<Button
					variant={'light'}
					onClick={() => setModalType('import')}
					style={{
						display: 'flex',
						gap: '4px',
						alignItems: 'center',
					}}
				>
					<Upload
						viewBox="0 0 24 20"
						style={{ width: '1rem', height: '1rem' }}
					/>
					<span
						style={{
							fontFamily: 'Inter',
							fontSize: '16px',
							fontWeight: 500,
							lineHeight: '24px',
							letterSpacing: '-0.04em',
							textAlign: 'center',
						}}
					>
						Импортировать
					</span>
				</Button>
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '0.5rem',
				}}
			>
				<Button
					onClick={() => setModalType('export')}
					variant={'light'}
					style={{
						display: 'flex',
						gap: '4px',
						alignItems: 'center',
					}}
				>
					<Download
						viewBox="0 0 24 20"
						style={{ width: '1rem', height: '1rem' }}
					/>
					<span
						style={{
							fontFamily: 'Inter',
							fontSize: '16px',
							fontWeight: 500,
							lineHeight: '24px',
							letterSpacing: '-0.04em',
							textAlign: 'center',
						}}
					>
						Экспортировать
					</span>
				</Button>
			</div>
		</div>
	)
}
