import React, { FC } from 'react'
import useCampaignData from '../../../../stores/campaignData'
import useResponsive from '../../../../utils/useResponsive'
import { CampaignStates } from '../../../../stores/ADMarketTypes.types'
import declOfNum from '../../../../utils/declOfNum'
import usePaymentTime, { TIME_VARIABLES } from './usePaymentTime'

function calculateProgress(
	currentMinutes: number,
	isTimePassed: boolean,
	fullTime: number
) {
	if (isTimePassed) return 100
	const time_for_payment_in_minutes = fullTime / 60000
	return (
		((time_for_payment_in_minutes - currentMinutes) /
			time_for_payment_in_minutes) *
		100
	)
}

const PaymentTimeProgress: FC = () => {
	const [campaign] = useCampaignData()
	const { isMobile } = useResponsive()
	const {
		isTimePassed,
		formattedTimeString,
		remainingMinutes,
		timeType,
		fullTime,
	} = usePaymentTime()

	if (
		!campaign.to_payment_waiting_dt ||
		campaign.state !== CampaignStates.Payment_waiting ||
		isTimePassed
	)
		return null
	return (
		<div style={{ marginBottom: isMobile ? '16px' : '24px' }}>
			<div
				style={{
					width: '100%',
					backgroundColor: '#CED4DA',
					borderRadius: '8px',
					position: 'relative',
					height: '4px',
				}}
			>
				<div
					style={{
						position: 'absolute',
						backgroundColor: isTimePassed ? '#dc3545' : '#007BFF',
						borderRadius: '8px',
						top: 0,
						left: 0,
						width:
							calculateProgress(
								remainingMinutes as number,
								isTimePassed,
								fullTime
							) + '%',
						height: '4px',
						animation: 'width 1s linear',
					}}
				/>
			</div>
			<div
				className={isTimePassed ? 'text-danger' : 'text-primary'}
				style={{ paddingTop: isMobile ? '8px' : '16px' }}
			>
				{timeType === 'TIME_FOR_CHOOSE_PAYMENT_METHOD' &&
					`До окончания оплаты осталось: ${formattedTimeString}`}
				{timeType === 'TIME_FOR_INVOICE' &&
					`У вас есть ${formattedTimeString}, что бы оплатить счет.`}
			</div>
		</div>
	)
}

export default PaymentTimeProgress
