import React from 'react'
import cn from 'classnames'
import s from './mediaFormatsComponents.module.scss'
import { AnimateSharedLayout, motion } from 'framer-motion'
import { Badge } from 'react-bootstrap'
import { ActiveLine } from '../MediaformatsPage'
import useResponsive from 'src/utils/useResponsive'
import { is } from 'immer/dist/internal'
import MobileTabsDropDown from 'src/components/_client/campaigns-list/MobileTabsDropDown'

type MediaFormatsTabType = {
	count: number
	title: string
	key: string
}

type MediaFormatsTabsProps = {
	tabs: any
	setFilter: any
	filter: any
}
export const MediaFormatsTabs = ({
	filter,
	setFilter,
	tabs,
}: MediaFormatsTabsProps) => {
	const { isMobile } = useResponsive()

	if (isMobile) {
		return (
			<div style={{ borderBottom: '1px solid #CED4DA' }}>
				<MobileTabsDropDown
					tabs={tabs}
					setSelectedTab={setFilter}
					selectedTab={filter}
				/>
			</div>
		)
	}

	return (
		<div className={cn(s.tabs)}>
			<AnimateSharedLayout>
				{tabs.map((tab) => {
					return (
						<motion.div
							key={tab.title}
							className={cn(s.tab)}
							onClick={(e) => setFilter(tab.title)}
							style={{
								position: 'relative',
								color:
									filter === tab.title
										? '#007BFF'
										: '#6C757D',
							}}
						>
							{tab.display}
							<Badge
								bg={
									filter === tab.title
										? 'primary'
										: 'secondary'
								}
							>
								{tab.count}
							</Badge>
							{filter === tab.title && <ActiveLine />}
						</motion.div>
					)
				})}
			</AnimateSharedLayout>
		</div>
	)
}
