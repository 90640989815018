import React from 'react'
import { Button } from 'react-bootstrap'
import { NotificationActionType } from '../NotificationsContent'
type Props = {
	onViewClick: () => void
	editOrDeleteClick: (mode: NotificationActionType) => void
}

export const NotificationItemControl = ({
	onViewClick: onClick,
	editOrDeleteClick,
}: Props) => {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					gap: '0.5rem',
				}}
			>
				<Button
					variant="text"
					onClick={() => editOrDeleteClick('edit')}
					style={{
						width: '32px',
						height: '32px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<i
						className="bi bi-pencil"
						style={{ color: '#007BFF' }}
					></i>
				</Button>

				<Button
					onClick={() => editOrDeleteClick('delete')}
					variant="text"
					style={{
						width: '32px',
						height: '32px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<i className="bi bi-trash" style={{ color: '#dc3545' }} />
				</Button>
			</div>
			<div>
				<Button
					onClick={onClick}
					variant="text"
					style={{
						width: '32px',
						height: '32px',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<i
						className="bi bi-chevron-right"
						style={{ color: '#6C757D' }}
					></i>
				</Button>
			</div>
		</div>
	)
}
