import React from 'react'
import { CodeConfirmation } from '../../pass-reset/reset-stages/CodeConfirmation'
import { CodeConfirmationFormData, CodeResendData } from '../../../model/types'
import { FormikHelpers } from 'formik'
import { PhoneConfirmationVariant } from 'src/stores/userData'

type Props = {
	phoneNumber: string
	expirationDateKey: string
	lastConfirmationVariantKey: string

	onSubmit: (
		values: CodeConfirmationFormData,
		formikHelpers: FormikHelpers<CodeConfirmationFormData>,
		confirmationVariant: PhoneConfirmationVariant
	) => Promise<void>
	onResendConfirmationCode: (values: CodeResendData) => Promise<void>
	onBack: () => void
}

export const CodeConfirmationWrapper = ({
	phoneNumber,
	expirationDateKey,
	lastConfirmationVariantKey,
	onBack,
	onResendConfirmationCode,
	onSubmit,
}: Props) => {
	return (
		<CodeConfirmation
			lastConfirmationVariantKey={lastConfirmationVariantKey}
			onBack={onBack}
			onSubmit={onSubmit}
			expirationDateKey={expirationDateKey}
			phoneNumber={phoneNumber}
			onResendConfirmationCode={onResendConfirmationCode}
		/>
	)
}
