import React, { useEffect, useMemo, useState } from 'react'
import {
	CampaignListItem,
	PlacementsType,
	PlacementsWithSelectType,
	RelatedKeyType,
} from '../../../CreateReportPage'
import { authFetch } from 'src/utils/authFetch'
import { ContentFormats, MainContent, Placements } from './components'
import { DoorType } from 'src/stores/technical-requirements-store'

export type AdFormatNameType =
	| 'indoor'
	| 'outdoor'
	| 'internet'
	| 'tv'
	| 'publicTransport'

const createUrlForPlacementsFetch = (ids: string[], doorType: DoorType) => {
	const url = `/core/api/v1/adv_companies/used_placements/?${ids
		.map((id) => `ids=${id}`)
		.join('&')}&door_type=${doorType}`

	return url
}

const initialSelectors: Record<AdFormatNameType, boolean> = {
	indoor: false,
	internet: false,
	outdoor: false,
	tv: false,
	publicTransport: false,
}

const relatedKeysForAdformat: Record<AdFormatNameType, RelatedKeyType[]> = {
	indoor: ['indoor'],
	outdoor: ['outdoor'],
	publicTransport: ['publictransportadvertising'],
	internet: ['vkinternetadvertising', 'yandexinternetadvertising'],
	tv: ['adstreamadversting', 'teletargetadversting'],
}

type CreateReportStepThree = {
	contentFormats: Record<'video' | 'banner', boolean>
	setContentFormats: (formats: Record<'video' | 'banner', boolean>) => void
	outdoorPlacements: Array<PlacementsWithSelectType<number>>
	indoorPlacements: Array<PlacementsWithSelectType<number>>
	setIndoorPlacements: (
		placements: Array<PlacementsWithSelectType<number>>
	) => void
	setOutdoorPlacements: (
		placements: Array<PlacementsWithSelectType<number>>
	) => void
	campaignIds: Array<string>
	setRelatedKeysChange: (relatedKeys: Record<RelatedKeyType, boolean>) => void
	relatedKeys: Record<RelatedKeyType, boolean>
	campaignsList: CampaignListItem[]
}

export function CreateReportStepThree({
	campaignIds,
	indoorPlacements,
	outdoorPlacements,
	setIndoorPlacements,
	setOutdoorPlacements,
	setRelatedKeysChange,
	relatedKeys,
	contentFormats,
	setContentFormats,
	campaignsList,
}: CreateReportStepThree) {
	const [selectedFormats, setSelectedFormats] =
		useState<Record<AdFormatNameType, boolean>>(initialSelectors)

	const [isLoading, setIsLoading] = useState(false)

	const fetchOutdoors = async () => {
		setIsLoading(true)
		const selectedCampains = campaignsList.filter(
			(campaign) =>
				campaignIds.some((id) => id === campaign.id) &&
				(campaign.outdoor_adv_is_selected ||
					campaign.district_adv_is_selected)
		)

		const selectedCampaignsIds = selectedCampains.map(
			(campaign) => campaign.id
		)

		const url = createUrlForPlacementsFetch(
			selectedCampaignsIds,
			DoorType.outdoor
		)

		const result = await authFetch<PlacementsType<number>[]>({
			url: url,
			method: 'GET',
		})

		const placements = result.map((placement) => {
			return { ...placement, isSelected: true }
		})
		setOutdoorPlacements(placements)
		setIsLoading(false)
	}

	const fetchIndoors = async () => {
		setIsLoading(true)
		const selectedCampains = campaignsList.filter(
			(campaign) =>
				campaignIds.some((id) => id === campaign.id) &&
				campaign.indoor_adv_is_selected
		)

		const selectedCampaignsIds = selectedCampains.map(
			(campaign) => campaign.id
		)

		const url = createUrlForPlacementsFetch(
			selectedCampaignsIds,
			DoorType.indoor
		)

		const result = await authFetch<PlacementsType<number>[]>({
			url: url,
			method: 'GET',
		})

		const placements = result.map((placement) => {
			return { ...placement, isSelected: true }
		})
		setIndoorPlacements(placements)
		setIsLoading(false)
	}

	const handleIndoorPlacementsChange = (placementId: number) => {
		setIndoorPlacements(
			indoorPlacements.map((placement) =>
				placement.id === placementId
					? { ...placement, isSelected: !placement.isSelected }
					: placement
			)
		)
	}

	const handleOutdoorPlacementsChange = (placementId: number) => {
		setOutdoorPlacements(
			outdoorPlacements.map((placement) =>
				placement.id === placementId
					? { ...placement, isSelected: !placement.isSelected }
					: placement
			)
		)
	}
	const handleRelatedKeysChange = (relatedKey: string) => {
		const currentValue = relatedKeys[relatedKey as RelatedKeyType]

		setRelatedKeysChange({
			...relatedKeys,
			[relatedKey]: !currentValue,
		})
	}
	const handlerContentFormatChange = (format: 'video' | 'banner') => {
		const currentValue = contentFormats[format]
		setContentFormats({ ...contentFormats, [format]: !currentValue })
	}

	const handleAdFormatChange = (
		name: AdFormatNameType,
		isSelected: boolean
	) => {
		setSelectedFormats({
			...selectedFormats,
			[name]: isSelected,
		})

		const newRelatedKeys = { ...relatedKeys }

		relatedKeysForAdformat[name].forEach((key) => {
			newRelatedKeys[key] = isSelected
		})

		setRelatedKeysChange(newRelatedKeys)

		if (name === 'indoor') {
			if (!indoorPlacements.length) {
				fetchIndoors()
			}
		}
		if (name === 'outdoor') {
			if (!outdoorPlacements.length) {
				fetchOutdoors()
			}
		}
	}

	const disabledAdFormats = useMemo(() => {
		const disabledFormats = {
			indoor: true,
			outdoor: true,
			internet: true,
			tv: true,
			publicTransport: true,
		}

		const selectedCampaigns = campaignsList.filter((campaign) =>
			campaignIds.some((id) => id === campaign.id)
		)

		selectedCampaigns.forEach((campaign) => {
			if (campaign.indoor_adv_is_selected) {
				disabledFormats.indoor = false
			}
			if (
				campaign.outdoor_adv_is_selected ||
				campaign.district_adv_is_selected
			) {
				disabledFormats.outdoor = false
			}
			if (campaign.extra_data.inet_is_selected) {
				disabledFormats.internet = false
			}
			if (campaign.extra_data.tv_is_selected) {
				disabledFormats.tv = false
			}
			if (campaign.extra_data.transport_is_selected) {
				disabledFormats.publicTransport = false
			}
		})

		return disabledFormats
	}, [campaignsList, campaignIds])

	useEffect(() => {
		return () => {
			setIndoorPlacements([])
			setOutdoorPlacements([])
		}
	}, [])
	return (
		<div>
			<MainContent
				disabledAdFormats={disabledAdFormats}
				handleAdFormatChange={handleAdFormatChange}
			/>

			<Placements
				selectedFormats={selectedFormats}
				handleIndoorPlacementsChange={handleIndoorPlacementsChange}
				handleOutdoorPlacementsChange={handleOutdoorPlacementsChange}
				handleRelatedKeysChange={handleRelatedKeysChange}
				indoorPlacements={indoorPlacements}
				isLoading={isLoading}
				outdoorPlacements={outdoorPlacements}
				relatedKeys={relatedKeys}
			/>

			<ContentFormats
				contentFormats={contentFormats}
				handlerContentFormatChange={handlerContentFormatChange}
			/>
		</div>
	)
}
