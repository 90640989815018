import React, { FC, useMemo, useState } from 'react'
import {
	BookingState,
	BookingType,
	MediaForBookingType,
} from '../../../../stores/ADMarketTypes.types'
import { Button, Dropdown, Modal, Spinner } from 'react-bootstrap'
import useResponsive from '../../../../utils/useResponsive'
import BookingEdit from './BookingEdit'
import useCampaignData from '../../../../stores/campaignData'
import MobileModal from '../../../UtilityComponents/MobileModal'
import { getError } from 'src/utils/getError'
import useNotificationData from 'src/stores/notificationsData'
import { useCampaignInfoStore } from '../store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from '../store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { EditVinstantModal } from '../common/edit-vinstant-modal/EditVinstantModal'

interface IBookingStateView {
	booking: BookingType
	media: MediaForBookingType
}

type MessageProperties = {
	icon: string
	key: BookingState
	title: string
	color: string
	progress: number
	message: string
}

const MessagesData: { [key in BookingState]?: MessageProperties } = {
	[BookingState.Draft]: {
		key: BookingState.Draft,
		icon: 'bi bi-file-earmark',
		title: 'Черновик',
		color: '#6C757D',
		progress: 0,
		message: '',
	},
	[BookingState.Moderation]: {
		key: BookingState.Moderation,
		icon: 'bi bi-hourglass-split',
		title: 'На модерации',
		color: '#007BFF',
		progress: 33,
		message:
			'Проверяем ваши изображения и наличие свободных мест на эти даты. Как только все проверим, отправим уведомление на почту.',
	},
	[BookingState.Inner_Moderation]: {
		key: BookingState.Inner_Moderation,
		icon: 'bi bi-hourglass-split',
		title: 'На модерации',
		color: '#007BFF',
		progress: 33,
		message:
			'Проверяем ваши изображения и наличие свободных мест на эти даты. Как только все проверим, отправим уведомление на почту.',
	},
	[BookingState.Adjustment]: {
		key: BookingState.Adjustment,
		icon: 'bi bi-pencil',
		title: 'На исправлении',
		color: '#007BFF',
		progress: 50,
		message: 'Provider Message',
	},
	[BookingState.Confirm]: {
		key: BookingState.Confirm,
		icon: 'bi bi-check-circle',
		title: 'Подтверждено',
		color: '#28A745',
		progress: 100,
		message: 'Ваше изображение и бронь подтверждены.',
	},
	[BookingState.Completed]: {
		key: BookingState.Confirm,
		icon: 'bi bi-check-circle',
		title: 'Завершено',
		color: '#28A745',
		progress: 100,
		message: 'Показ рекламы для данного размещения успешно завершен.',
	},
	[BookingState.Reject]: {
		key: BookingState.Reject,
		icon: 'bi bi-x-circle',
		title: 'Отклонено',
		color: '#DC3545',
		progress: 100,
		message:
			'Бронь отклонена. Возможно рекламное место неисправно или занято на эту дату.',
	},
	[BookingState.Canceled]: {
		key: BookingState.Canceled,
		icon: 'bi bi-dash-circle',
		title: 'Отменено',
		color: '#DC3545',
		progress: 100,
		message: 'Вы отменили бронь.',
	},
}

const BookingStateView: FC<IBookingStateView> = ({ booking, media }) => {
	const { state, adjustment_msg } = booking
	const [isEditVinstantOpen, setIsEditVinstantOpen] = useState(false)

	const [campaign, campaignInterface] = useCampaignData()
	const [showDesktopEditModal, setShowDesktopEditModal] = useState(false)
	const data = MessagesData[state]
	const { isMobile } = useResponsive()
	const [newImage, setNewImage] = useState(null)
	const [fileError, setFileError] = useState(null)

	const [isLoading, setIsLoading] = useState(false)
	const [documentUploaded, setDocumentUploaded] = useState(false)

	const [_, notificationsInterface] = useNotificationData()

	const editCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated(booking.id.toString()) as
			| string
			| undefined

	const bookingVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'booking_id',
			itemId: booking.id.toString(),
		})
	)
	const handleEditVinstantModalClose = () => {
		setIsEditVinstantOpen(false)
		setShowDesktopEditModal(true)
	}

	const OnNewImage = (image) => {
		setNewImage(image)
	}

	const fontSize = useMemo<string>(
		() => (isMobile ? '14px' : '16px'),
		[isMobile]
	)

	if (!data) return <></>

	const sendToModeration = async () => {
		const nextBookingState =
			campaign.state === 'moderation' ? 'inner_moderation' : 'moderation'
		try {
			await campaignInterface.patchBooking(booking.id.toString(), {
				state: nextBookingState,
			})
		} catch (e) {}
	}

	const uploadNewImage = async () => {
		setNewImage(null)

		await campaignInterface.updateMedia(
			booking.media_creative?.id,
			newImage
		)
	}
	async function handleModerationClick() {
		setIsLoading(true)
		try {
			if (newImage) {
				await uploadNewImage()
			}
			if (documentUploaded || newImage) {
				await sendToModeration()
			}
			setShowDesktopEditModal(false)
		} catch (e: any) {
			const error = getError(e)
			setFileError(error?.data?.file?.[0])
			console.log('error', error)
		} finally {
			setIsLoading(false)
		}
	}

	function onClose() {
		setFileError(null)
		setShowDesktopEditModal(false)
	}

	const isDisabled = () => {
		if (isLoading) return true
		if (documentUploaded) return false
		if (newImage) return false
		return true
	}

	return (
		<div
			className={'d-flex flex-column'}
			style={{
				color: data.color,
				marginBottom: isMobile ? '8px' : '24px',
			}}
		>
			{booking.state !== BookingState.Completed && (
				<>
					<div className={'mb-3'} style={{ fontSize }}>
						<i
							className={data.icon}
							style={{ marginRight: '12px' }}
						/>
						{data.title}
					</div>
					<div
						className={'w-100 mb-3'}
						style={{
							height: '4px',
							borderRadius: '8px',
							backgroundColor: '#E9ECEF',
							position: 'relative',
						}}
					>
						<div
							style={{
								width: `${data.progress}%`,
								backgroundColor: data.color,
								borderRadius: '8px',
								height: '4px',
								position: 'absolute',
							}}
						></div>
					</div>
					<span style={{ fontSize }}>
						{state === BookingState.Adjustment
							? adjustment_msg
							: data.message}
					</span>
				</>
			)}

			{state === BookingState.Adjustment && (
				<Button
					variant={'primary'}
					className={'mt-3'}
					style={{ width: isMobile ? '100%' : 'fit-content' }}
					onClick={() => setShowDesktopEditModal(true)}
				>
					Исправить
				</Button>
			)}

			<EditVinstantModal
				newContentKey={booking?.id.toString()}
				editVinstantLink={bookingVinstantLink}
				onClose={handleEditVinstantModalClose}
				open={isEditVinstantOpen}
			/>

			{!isMobile && showDesktopEditModal && (
				<Modal
					show={showDesktopEditModal}
					onHide={onClose}
					backdrop="static"
					keyboard={false}
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title as={'h5'} className={'ms-2'}>
							Исправление рекламного места
						</Modal.Title>
					</Modal.Header>
					<Modal.Body style={{ padding: '24px' }}>
						<BookingEdit
							onEditVinstantClick={() =>
								setIsEditVinstantOpen(true)
							}
							editVinstantStatus={editCreativeVinstantStatus}
							onDocumentLoaded={setDocumentUploaded}
							booking={booking}
							media={media}
							fileError={fileError}
							setFileError={setFileError}
							onNewImage={OnNewImage}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={onClose}>
							Отменить
						</Button>
						<Button
							variant="primary"
							disabled={isDisabled()}
							onClick={handleModerationClick}
							style={{
								display: 'flex',
								gap: '8px',
								alignItems: 'center',
							}}
						>
							Отправить на модерацию
							{isLoading && (
								<Spinner
									size="sm"
									animation={'border'}
									variant={'text'}
								/>
							)}
						</Button>
					</Modal.Footer>
				</Modal>
			)}
			{isMobile && (
				<MobileModal
					show={showDesktopEditModal}
					onHide={onClose}
					title={'Исправление рекламного места'}
				>
					<div className={'d-flex flex-column h-100'}>
						<div style={{ flex: '1 1 auto' }}>
							<BookingEdit
								onEditVinstantClick={() =>
									setIsEditVinstantOpen(true)
								}
								editVinstantStatus={editCreativeVinstantStatus}
								onDocumentLoaded={setDocumentUploaded}
								booking={booking}
								fileError={fileError}
								setFileError={setFileError}
								media={media}
								onNewImage={OnNewImage}
								showAll
							/>
						</div>
						<Dropdown.Divider className={' mt-3 mb-3'} />
						<div className={'d- flex flex-column'}>
							<Button
								variant="secondary"
								onClick={onClose}
								className={'w-100 mb-2'}
							>
								Отменить
							</Button>
							<Button
								variant="primary"
								disabled={isDisabled()}
								onClick={handleModerationClick}
								className={'w-100 mb-3'}
								style={{
									display: 'flex',
									gap: '8px',
									alignItems: 'center',
								}}
							>
								Отправить на модерацию
								{isLoading && (
									<Spinner
										size="sm"
										animation={'border'}
										variant={'primary'}
									/>
								)}
							</Button>
						</div>
					</div>
				</MobileModal>
			)}
		</div>
	)
}

export default BookingStateView
