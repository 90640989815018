import React from 'react'
import { Button } from 'react-bootstrap'
import { Search } from 'src/components/UtilityComponents'
import s from './createReportOne.module.scss'
import cn from 'classnames'
import { CreateReportSelectedCount } from '../../common'

type CreateReportOneHeaderInputProps = {
	setSearchValue: (value: string) => void
	count: number
	onClick: () => void
	isAllSelected: boolean
}
export const CreateReportOneHeaderInput = ({
	setSearchValue,
	count,
	onClick,
	isAllSelected,
}: CreateReportOneHeaderInputProps) => {
	return (
		<div className={cn(s.stepOneHeaderInput)}>
			<Search
				minWidth="100%"
				placeholder="Поиск по названию"
				setSearchString={setSearchValue}
			/>

			<CreateReportSelectedCount
				isAllSelected={isAllSelected}
				count={count}
				onClick={onClick}
			/>
		</div>
	)
}
