import moment from 'moment'

export const getUpdatedString = (updatedAt: string) => {
	const currentDate = moment()
	const lastUpdateDate = moment(updatedAt)

	const daysDiff = currentDate.diff(lastUpdateDate, 'days')

	let updatedString = ''
	if (daysDiff === 1) {
		updatedString = 'Обновлено вчера'
	} else if (daysDiff > 1) {
		updatedString = `Обновлено ${daysDiff} дня назад`
	} else {
		updatedString = 'Обновлено сегодня'
	}

	return updatedString
}
