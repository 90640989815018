import React, { useState } from 'react'
import { Button, ButtonGroup, Modal, Spinner } from 'react-bootstrap'
import { BASE_URL } from 'src/constants/api'
import { authFetch } from 'src/utils/authFetch'
import downloadBlob from 'src/utils/downloadBlob'
import FileInput from 'src/components/UtilityComponents/FileInput'
import { ImportState, FileLoadResponse } from './PlacementsImportExport'

type ImportModalBodyProps = {
	onClose: () => void
	changeImportState: (importstate: ImportState) => void
}
export const ImportModalBody = ({
	changeImportState,
	onClose,
}: ImportModalBodyProps) => {
	const [isReplacement, setIsResplacement] = useState(false)

	const [file, setFile] = useState<File | null>(null)

	const [loadingInProgress, setLoadingInProgress] = useState(false)

	const [uploadError, setUploadError] = useState<string | null>(null)

	const downloadImportTemplate = async () => {
		const answ: Response = await authFetch({
			url: `/core/api/v1/provider/import_placements/download_template/`,
			method: 'GET',
			raw: true,
		})
		if (answ.ok) {
			const blob = await answ.blob()
			downloadBlob(blob, `Шаблон_импорта`, 'xlsx')
		} else {
			throw new Error('Ошибка при загрузке')
		}
	}
	const startImportProcess = async () => {
		try {
			const formData = new FormData()
			formData.append('is_replacement', isReplacement.toString())
			formData.append('file', file!)
			setLoadingInProgress(true)
			await authFetch<FileLoadResponse>({
				method: 'POST',
				url: `${BASE_URL}/provider/import_placements/`,
				body: formData,
			})
			changeImportState(ImportState.PROCESSING)
			onClose()
		} catch (e) {
			setUploadError('Something went wrong')
		} finally {
			setLoadingInProgress(false)
		}
	}

	return (
		<>
			<div
				className={'p-4'}
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '1.5rem',
				}}
			>
				<div>
					<p>Способ импорта</p>
					<ButtonGroup>
						<Button
							onClick={() => setIsResplacement(true)}
							variant={isReplacement ? 'primary' : 'secondary'}
						>
							С заменой существующих
						</Button>
						<Button
							onClick={() => setIsResplacement(false)}
							variant={isReplacement ? 'secondary' : 'primary'}
						>
							Без замены
						</Button>
					</ButtonGroup>
				</div>
				<div>
					<p>
						Чтобы импортировать рекламные носители, загрузите файл
						в формате .xls. Для этого скачайте шаблон, заполните
						данные по каждому носителю и загрузите файл.
					</p>
					<Button
						onClick={() => downloadImportTemplate()}
						variant={'light'}
					>
						Скачать шаблон
					</Button>
				</div>
				<div>
					<FileInput
						onUpload={(file: File) => setFile(file)}
						value={file}
						onRemove={() => setFile(null)}
						error={uploadError}
						size={20}
						allowedExtensions={['XLS', 'XLSX', 'ZIP']}
						descr="Формат XLS, Размер: до 20мб"
					/>
				</div>
			</div>
			<Modal.Footer style={{ padding: '1rem 1.5rem' }}>
				<div
					style={{
						display: 'flex',
						gap: '8px',
						height: '100%',
						width: '100%',
						alignItems: 'center',
						justifyContent: 'flex-end',
					}}
				>
					<Button
						disabled={loadingInProgress}
						onClick={onClose}
						variant="secondary"
					>
						Отменить
					</Button>
					<Button
						style={{ display: 'flex', gap: '0.5rem' }}
						disabled={!file || loadingInProgress}
						onClick={() => startImportProcess()}
					>
						Импортировать
						{loadingInProgress && (
							<Spinner size="sm" animation="border" />
						)}
					</Button>
				</div>
			</Modal.Footer>
		</>
	)
}
