import React, { useRef } from 'react'
import s from './Table.module.scss'
import cn from 'classnames'

export function Table({
	cols,
	data,
	headerCell,
	rounded,
	noHover,
	noBorder,
	noBorderLeft,
	noBorderRight,
	noBorderBottom,
	noBorderTop,
	wrapRow = (el, row) => el,
}: Record<any, any>) {
	return (
		<div
			className={cn(s.table, {
				[s.rounded]: rounded,
				[s.noBorder]: noBorder,
				[s.noBorderLeft]: noBorderLeft,
				[s.noBorderRight]: noBorderRight,
				[s.noBorderBottom]: noBorderBottom,
				[s.noBorderTop]: noBorderTop,
			})}
		>
			<div className={s.header}>
				{cols.map((col, i) => {
					const colEl = headerCell(col, i)
					return React.cloneElement(colEl, {
						col: col,
						width: col?.staticWidth
							? col.staticWidth
							: getWidth(cols, col),
						justify: colEl.props.justify || col.justify,
						maxWidth: col.staticWidth,
						key: col.key,
					})
				})}
			</div>
			{data.map((row, i) => {
				return wrapRow(
					<div className={cn(s.row, { [s.noHover]: noHover })}>
						{cols.map((col) => {
							const callOrVal = (propName) => {
								const val = col[propName]
								if (typeof val !== 'function') return val
								return val(row)
							}
							return (
								<Cell
									extraStyle={col.extraStyle || {}}
									staticWidth={col?.staticWidth}
									primaryHover={col?.primaryHover}
									content={(col.cell || emptyFn)(row)}
									left={(col.cellLeft || emptyFn)(row)}
									right={(col.cellRight || emptyFn)(row)}
									width={getWidth(cols, col)}
									faded={callOrVal('faded')}
									ellipsis={callOrVal('ellipsis')}
									noPadding={callOrVal('noPadding')}
									justify={callOrVal('justify')}
									className={callOrVal('className')}
									noBorder={callOrVal('noBorder')}
									noBorderRight={callOrVal('noBorderRight')}
									noBorderBottom={callOrVal('noBorderBottom')}
									direction={callOrVal('direction')}
									isHeader={callOrVal('isHeader')}
									id={col.key}
									key={col.key}
								/>
							)
						})}
					</div>,
					row,
					i
				)
			})}
		</div>
	)
}

export function Cell({
	content,
	left,
	right,
	width,
	faded,
	justify,
	direction,
	isHeader,
	noPadding,
	className,
	ellipsis,
	noBorder,
	noBorderRight,
	noBorderBottom,
	onClick,
	primaryHover,
	staticWidth,
	extraStyle,
	id,
}: Record<any, any>) {
	return (
		<div
			className={cn(s.cell, className, {
				[s.header]: isHeader,
				[s.faded]: faded,
				[s.noPadding]: noPadding,
				[s.noBorder]: noBorder,
				[s.noBorderRight]: noBorderRight,
				[s.noBorderBottom]: noBorderBottom,
				[s.primaryHover]: primaryHover,
			})}
			style={{
				// width: width + '%',
				minWidth: staticWidth ? staticWidth : width + '%',
				width: staticWidth ? staticWidth : width + '%',
				...extraStyle,
			}}
			onClick={onClick}
		>
			{(left || left === 0) && <div className={s.cellLeft}>{left}</div>}
			{(content || content === 0) && (
				<div
					className={cn(s.cellContent, { [s.ellipsis]: ellipsis })}
					style={{
						flexDirection:
							direction === 'column' ? 'column' : 'row',
						justifyContent:
							justify === 'right'
								? 'flex-end'
								: justify === 'center'
								? 'center'
								: void 0,
					}}
				>
					{content}
				</div>
			)}
			{(right || right === 0) && (
				<div className={s.cellRight}>{right}</div>
			)}
		</div>
	)
}

const getWidth = (cols, col) =>
	(100 * col.width) / cols.reduce((x, y) => x + y.width, 0)

const emptyFn = () => null
