import React from 'react'
import { Card, Spinner } from 'react-bootstrap'
import s from '../districts/districtsUpload.module.scss'
import { ReactComponent as TargetIcon } from 'src/assets/images/location-3.svg'
import { AnimatePresence, motion } from 'framer-motion'
import { useTechnicalRequirementsStore } from 'src/stores/technical-requirements-store'
import { useCampaignInfoStore } from 'src/components/_client/campaign-info/store/campaignInfo.store'
import { BookingFormatUpload } from './components/BookingFormatUpload'

type Props = {
	advType: 'indoor' | 'outdoor'
}
export const BookingsUploadByFormats = ({ advType }: Props) => {
	const bookings = useTechnicalRequirementsStore((state) => state[advType])
	const requirementsLoading = useTechnicalRequirementsStore(
		(state) => state.isLoading
	)
	const linksIsLoading = useCampaignInfoStore((state) => state.isLoading)

	const isLoading = requirementsLoading || linksIsLoading

	return (
		<Card className={s.distictsUploadContainer}>
			<HeaderWithDescription isLoading={isLoading} advType={advType} />

			<AnimatePresence>
				<motion.div
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					initial={{ opacity: 0 }}
					className={s.distictsUploadItemsContainer}
				>
					{bookings.map((format) => {
						return (
							<BookingFormatUpload
								key={format.id}
								format={format}
							/>
						)
					})}
				</motion.div>
			</AnimatePresence>
		</Card>
	)
}

const headerText: Record<'indoor' | 'outdoor', string> = {
	indoor: 'Внутренняя реклама',
	outdoor: 'Гарантированные показы',
}
export const HeaderWithDescription = (props: {
	isLoading: boolean
	advType: 'indoor' | 'outdoor'
}) => {
	return (
		<div className={s.headerWithDescrption}>
			<div className={s.headerTitle}>
				<div className="d-flex gap-2 align-items-center">
					<h5>{headerText[props.advType]} </h5>
					{props.isLoading && (
						<Spinner
							animation="border"
							size={'sm'}
							variant="primary"
						/>
					)}
				</div>

				<TargetIcon />
			</div>
			<p>
				Загрузите материал в каждую группу в соответствии с техническими
				требованиями
			</p>
		</div>
	)
}
