export const advCategories = {
	no_category: 'Не указано',
	avtoservisi: 'Автосервисы',
	veterinarnie_kliniki_i_zoomagazini: 'Ветеринарные клиники и зоомагазины',
	dosug_i_razvlecheniya_kinoteatri_teatri_vistavki_festivali_i_td:
		'Досуг и развлечения (кинотеатры, театры, выставки, фестивали и т.д.)',
	avtozapchasti: 'Автозапчасти',
	kosmetika: 'Косметика',
	odezhda_obuv_aksessuari: 'Одежда, обувь, аксессуары',
	sportivnie_tovari_khobbi_okhota_ribalka_turizm_i_td:
		'Спортивные товары, хобби (охота, рыбалка, туризм и т.д.)',
	strojmateriali: 'Стройматериалы',
	khozyajstvennie_tovari_dacha_sad_i_ogorod:
		'Хозяйственные товары, дача, сад и огород',
	magazini_prodovolstvennie: 'Магазины продовольственные',
	meditsinskie_uchrezhdeniya: 'Медицинские учреждения',
	obrazovatelnie_uchrezhdeniya_shkoli_detskie_sadi_detskie_tsentri:
		'Образовательные учреждения (школы, детские сады, детские центры)',
	obshchestvennoe_pitanie_restorani_bari_kafe:
		'Общественное питание (рестораны, бары, кафе)',
	oteli_doma_otdikha_turbazi: 'Отели, дома отдыха, турбазы',
	parikmakherskie_i_saloni_krasoti: 'Парикмахерские и салоны красоты',
	reklamnie_uslugi: 'Рекламные услуги',
	transportnie_uslugi_taksi_perevozki_i_td:
		'Транспортные услуги (такси, перевозки и т.д.)',
	fitnes_tsentri_i_spa_saloni: 'Фитнес-центры и спа-салоны',
	yuridicheskie_uslugi: 'Юридические услуги',
	drugoe: 'Другое',
	ritualnie_uslugi: 'Ритуальные услуги',
} as const

export type AdvCategoriesKeys = keyof typeof advCategories
export type AdvCategoriesValues = typeof advCategories[AdvCategoriesKeys]
