import React from 'react'
import { NewContentCreatedStatusType } from 'src/stores/types/newContendCreatedType'
import { ReactComponent as Edit } from 'src/assets/images/edit.svg'
import { Spinner } from 'react-bootstrap'

type Props = {
	onEditVinstant: (link: string) => void
	vinstantEditStatus: NewContentCreatedStatusType
	editVinstanLink: string | null
}
export const EditVinstantButton = ({
	onEditVinstant,
	vinstantEditStatus,
	editVinstanLink,
}: Props) => {
	return (
		<div>
			{editVinstanLink && (
				<div
					onClick={() => onEditVinstant(editVinstanLink)}
					style={{ maxHeight: '1rem', maxWidth: '1rem' }}
				>
					{vinstantEditStatus === 'start' ? (
						<Spinner
							animation="border"
							style={{
								maxHeight: '1rem',
								maxWidth: '1rem',
								color: '#007BFF',
							}}
						/>
					) : (
						<Edit
							style={{
								cursor: 'pointer',
								maxHeight: '1rem',
								maxWidth: '1rem',
								fill: '#007BFF',
							}}
						/>
					)}
				</div>
			)}
		</div>
	)
}
