import React from 'react'
import { Modal } from 'react-bootstrap'
import ProviderYandexMapNew from 'src/components/_provider/Bookings/content/details/ProviderYandexMapNew'
import s from '../../../Bookings/content/details/ModalBookingLocation.module.scss'
import ProviderYandexMap from 'src/components/UtilityComponents/ProviderYandexMap'
import YandexMap from 'src/components/UtilityComponents/YandexMap'
import useResponsive from 'src/utils/useResponsive'
type PlacementsMapProps = {
	show: boolean
	onHide: () => void
	placements: any
	modalTitle?: string
	singleCoords?: any
}

export const PlacementsMap = ({
	onHide,
	show,
	placements,
	modalTitle,
	singleCoords,
}: PlacementsMapProps) => {
	const { isMobile } = useResponsive()
	return (
		<Modal show={show} onHide={onHide} animation={false} fullscreen={true}>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'}>
					{modalTitle || 'Рекламные носители'}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body className={'p-0'}>
				<div
					style={{
						width: '100%',
						height: '100%',
						position: 'relative',
						transition: 'padding-left 0.4s linear',
						overflow: 'hidden',
					}}
				>
					<ProviderYandexMapNew
						width={'100%'}
						height={'100%'}
						withZoomControls={true}
						singleCoords={singleCoords}
						zoom={10}
						placements={placements}
					/>
				</div>
			</Modal.Body>
		</Modal>
	)
}
