import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import s from './variantsContainer.module.scss'
import cn from 'classnames'

type Props = {
	children: React.FunctionComponentElement<any>[]
}
export const AdvFormatVariantsContainer = ({ children }: Props) => {
	return (
		<AnimatePresence>
			{children.length > 0 && (
				<motion.div
					initial={{ opacity: 0, y: 0 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 0, transition: { duration: 0 } }}
					transition={{ duration: 0.3 }}
					className={cn(s.variantsContainer)}
				>
					{children}
				</motion.div>
			)}
		</AnimatePresence>
	)
}
