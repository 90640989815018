import React, { FC, useEffect, useState } from 'react'
import s from './Bookings.module.scss'
import BookingsTabs from './tabs/BookingsTabs'
import BookingsProvider, {
	useBookingsContext,
} from './context/BookingsProvider'
import BookingsFilters from './filters/BookingsFilters'
import ContentLayout from './content/ContentLayout'
import ReusableModalsProvider from '../../UtilityComponents/ReusableModals/ReusableModalsProvider'
import useHeaderTitle from '../../page/useHeaderTitle'
import useResponsive from 'src/utils/useResponsive'
import {
	BookingsSettings,
	ProviderBookingQueryParams,
} from './filters/booking-filters-mobile/BookingsSettings'
import { useLocation } from 'react-router-dom'
import { BookingsTabsNames } from './context/Context.types'
import { useQueryParams } from 'src/utils'
import useProviderData from 'src/stores/providerData'
import { NoBookingsInCurrentTab } from './content/list/NoBookingsInCurrentTab'

interface IBookingsPage {
	children?: React.ReactNode | React.ReactNode[]
}

const BookingsPage: FC<IBookingsPage> = ({}) => {
	useHeaderTitle('Брони')

	const { params } = useQueryParams<ProviderBookingQueryParams>()
	const [provider, providerInterface] = useProviderData()

	const { isMobile } = useResponsive()
	const {
		tabs,
		selectedTab: selectedTabFromContext,
		setSelectedTab: setSelectedTabFromContext,
	} = useBookingsContext()

	const [selectedTab, setSelectedTab] = useState<BookingsTabsNames>(() => {
		if (selectedTabFromContext) return selectedTabFromContext

		if (params?.state) return params.state
		else return BookingsTabsNames.ALL
	})

	const bookingsCount = (provider?.bookings?.results?.length || 0) as number

	return (
		<BookingsProvider>
			<div className={s.container}>
				{isMobile && (
					<h5
						style={{
							padding: '12px 16px',
							borderBottom: '1px solid #CED4DA',
							margin: 0,
						}}
					>
						Брони
					</h5>
				)}
				<BookingsTabs
					bookingsCount={bookingsCount}
					selectedTab={selectedTab}
					setSelectedTab={setSelectedTab}
				/>
				{isMobile && <BookingsSettings selectedTab={selectedTab} />}
				{!isMobile && <BookingsFilters />}
				{bookingsCount > 0 ? (
					<ContentLayout />
				) : (
					<NoBookingsInCurrentTab />
				)}
			</div>
		</BookingsProvider>
	)
}

export default BookingsPage
