import { BASE_URL } from 'src/constants/api'
import { authFetch } from 'src/utils/authFetch'
import {
	CreateNotificationBody,
	CreateRelatedNotificationBody,
	DetailedCampaignInfo,
	DisplayIssuesCampaign,
	FetchCompaniesResponseType,
	FetchNotificationResponseType,
	Manager,
	ModerAdvCompanyListType,
	NotificationDetails,
	NotificationModel,
	NotificationState,
	PatchAdvItemBodyType,
} from '../types/ModerationRequestTypes'

export const moderationApi = {
	getAdvCompanyList: async (queryString: string) => {
		const result = authFetch<ModerAdvCompanyListType>({
			url: `${BASE_URL}/moder/adv_company/?${queryString}`,
			method: 'GET',
		})

		return result
	},
	getDetailedCampaingInformation: async (id: string) => {
		const result = authFetch<DetailedCampaignInfo>({
			url: `${BASE_URL}/moder/adv_company/${id}/`,
			method: 'GET',
		})

		return result
	},
	getAdstreamChannels: async () => {
		const result = authFetch<Array<{ id: number; name: string }>>({
			url: `${BASE_URL}/tv_preroll/channels/`,
			method: 'GET',
		})

		return result
	},
	patchAdvItem: async (
		campaignId: string | number,
		type:
			| 'yandex'
			| 'vk'
			| 'adstream'
			| 'indoor'
			| 'outdoor'
			| 'districtbooking'
			| 'publicTransport',
		body: Partial<PatchAdvItemBodyType>,
		bookingId?: string
	) => {
		let url = `${BASE_URL}/moder/adv_company/${campaignId}/`
		switch (type) {
			case 'yandex':
				url = `${url}yandex/`
				break
			case 'vk':
				url = `${url}vk/`
				break
			case 'adstream':
				url = `${url}adstream/`
				break
			case 'indoor':
			case 'outdoor':
				url = `${url}booking/${bookingId}/`
				break
			case 'districtbooking':
				url = `${url}district_booking/${bookingId}/`
				break
			case 'publicTransport':
				url = `${url}transport-adv/`
		}

		const result = authFetch<PatchAdvItemBodyType & { id: string }>({
			url: url,
			method: 'PATCH',
			body: body,
		})

		return result
	},
	patchAdvCampaing: async (
		campaignId: string,
		body: {
			state_advertising: 'moderation' | 'adjustment' | 'reject'
			comment?: string
		}
	) => {
		const result = authFetch<Array<{ id: number; name: string }>>({
			url: `${BASE_URL}/moder/adv_company/${campaignId}/`,
			method: 'PATCH',
			body: body,
		})

		return result
	},
	managerConfirm: async (campaignId: string) => {
		const result = authFetch<any>({
			url: `${BASE_URL}/moder/adv_company/${campaignId}/manager_confirm/`,
			method: 'PATCH',
		})

		return result
	},

	fetchDisplayIssues: async (queryString: string) => {
		const result = authFetch<{
			count: number
			results: Array<DisplayIssuesCampaign>
		}>({
			url: `${BASE_URL}/moder/adv_company/problems_views/?${queryString}`,
			method: 'GET',
		})
		return result
	},
	fetchManagerNotifications: async (queryString: string) => {
		const result = await authFetch<FetchNotificationResponseType>({
			method: 'GET',
			url: `${BASE_URL}/moder/notify/?${queryString}`,
		})
		return result
	},
	fetchDetailedNotificationInformation: async (notificationId: number) => {
		const result = await authFetch<NotificationDetails & NotificationModel>(
			{
				method: 'GET',
				url: `${BASE_URL}/moder/notify/${notificationId}`,
			}
		)
		return result
	},
	createDraftNotification: async () => {
		const result = await authFetch<NotificationModel>({
			method: 'POST',
			url: `${BASE_URL}/moder/notify/`,
		})
		return result
	},
	createNotification: async (
		notificationId: number,
		body: CreateNotificationBody & Partial<CreateRelatedNotificationBody>
	) => {
		const result = await authFetch<NotificationDetails & NotificationModel>(
			{
				method: 'PUT',
				url: `${BASE_URL}/moder/notify/${notificationId}/`,
				body: body,
			}
		)
		return result
	},
	updateDraftNotification: async (
		notificationId: number,
		body: Partial<CreateNotificationBody>
	) => {
		const result = await authFetch<NotificationDetails & NotificationModel>(
			{
				method: 'PATCH',
				url: `${BASE_URL}/moder/notify/${notificationId}/`,
				body: body,
			}
		)
		return result
	},
	updateNotification: async (
		notificationId: number,
		body: CreateNotificationBody
	) => {
		const result = await authFetch<NotificationDetails & NotificationModel>(
			{
				method: 'PATCH',
				url: `${BASE_URL}/moder/notify/${notificationId}/`,
				body: body,
			}
		)
		return result
	},
	deleteNotification: async (notificationId: number) => {
		const result = await authFetch<{
			id: number
			state: NotificationState
		}>({
			method: 'DELETE',
			url: `${BASE_URL}/moder/notify/${notificationId}`,
		})
		return result
	},
	deleteBulkNotifications: async (body: { notify: number[] }) => {
		const result = await authFetch<{ delete_notify: number[] }>({
			method: 'POST',
			url: `${BASE_URL}/moder/notify/bulk_delete/`,
			body: body,
		})
		return result?.delete_notify
	},
	fetchManagers: async () => {
		const result = await authFetch<Manager[]>({
			method: 'GET',
			url: `${BASE_URL}/moder/notify/user/`,
		})
		return result
	},
	fetchCompanies: async () => {
		const result = await authFetch<FetchCompaniesResponseType>({
			method: 'GET',
			url: `${BASE_URL}/moder/notify/company/`,
		})
		return result
	},
}
