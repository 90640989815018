import React, { useMemo } from 'react'
import cn from 'classnames'
import baseStyle from '../../createReportSteps.module.scss'
import { CreateReportBody } from '../../../common'
import { CreateReportPlacementHeader, CreateReportPlacementsList } from '.'
import {
	PlacementsWithSelectType,
	RelatedKeyType,
} from '../../../../CreateReportPage'
import useResponsive from 'src/utils/useResponsive'
import { AdFormatNameType } from '../CreateReportStepThree'

type PlacementsProps = {
	outdoorPlacements: Array<PlacementsWithSelectType<number>>
	indoorPlacements: Array<PlacementsWithSelectType<number>>
	relatedKeys: Record<RelatedKeyType, boolean>
	isLoading: boolean
	handleIndoorPlacementsChange: (placementId: number) => void
	handleRelatedKeysChange: (relatedKey: string) => void
	handleOutdoorPlacementsChange: (placementId: number) => void
	selectedFormats: Record<AdFormatNameType, boolean>
}

export const Placements = ({
	indoorPlacements,
	outdoorPlacements,
	relatedKeys,
	handleIndoorPlacementsChange,
	isLoading,
	handleOutdoorPlacementsChange,
	handleRelatedKeysChange,
	selectedFormats,
}: PlacementsProps) => {
	const { isMobile } = useResponsive()

	const isFormatsBody = useMemo(() => {
		return Object.keys(selectedFormats).some((key) => {
			return selectedFormats[key]
		})
	}, [selectedFormats])
	return (
		<>
			{isFormatsBody && (
				<div
					className={cn(baseStyle.createReportStep, {
						[baseStyle.createReportStep_mobile]: isMobile,
					})}
					style={{ marginTop: '20px' }}
				>
					<CreateReportBody>
						<CreateReportPlacementHeader
							isLoading={isLoading}
							title="Места размещения"
						/>

						<div>
							{selectedFormats.indoor && (
								<CreateReportPlacementsList
									onChange={handleIndoorPlacementsChange}
									placements={indoorPlacements}
									title="Внутренняя реклама"
								/>
							)}
							{selectedFormats.outdoor && (
								<CreateReportPlacementsList
									onChange={handleOutdoorPlacementsChange}
									placements={outdoorPlacements}
									title="Наружная реклама"
								/>
							)}
							{selectedFormats.tv && (
								<CreateReportPlacementsList
									onChange={handleRelatedKeysChange}
									placements={[
										{
											id: 'adstreamadversting',
											isSelected:
												relatedKeys.adstreamadversting,
											placement_type: {
												name: 'AdStream',
											},
										},
										{
											id: 'teletargetadversting',
											isSelected:
												relatedKeys.teletargetadversting,
											placement_type: {
												name: 'Teletarget',
											},
										},
									]}
									title="TВ-реклама"
								/>
							)}

							{selectedFormats.internet && (
								<CreateReportPlacementsList
									onChange={handleRelatedKeysChange}
									placements={[
										{
											id: 'yandexinternetadvertising',
											isSelected:
												relatedKeys.yandexinternetadvertising,
											placement_type: {
												name: 'Яндекс.Директ',
											},
										},
										{
											id: 'vkinternetadvertising',
											isSelected:
												relatedKeys.vkinternetadvertising,
											placement_type: {
												name: 'VK Реклама',
											},
										},
									]}
									title="Интернет-реклама"
								/>
							)}
							{selectedFormats.publicTransport && (
								<CreateReportPlacementsList
									onChange={handleRelatedKeysChange}
									placements={[
										{
											id: 'publictransportadvertising',
											isSelected:
												relatedKeys.publictransportadvertising,
											placement_type: {
												name: 'Цифровые экраны на МЦК',
											},
										},
									]}
									title="Общественный транспорт"
								/>
							)}
						</div>
					</CreateReportBody>
				</div>
			)}
		</>
	)
}
