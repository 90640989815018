import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Formik } from 'formik'
import { Button, Form, InputGroup } from 'react-bootstrap'
import bem from '../../utils/bem'
import TimeFormatter from '../../utils/time-formatter'
import PhoneFormatter from '../../utils/phone-formatter'
import s from './authstyles/phoneConfirmation.module.scss'

import {
	AcceptPhoneActivationCodeData,
	PhoneConfirmationVariant,
} from 'src/stores/userData'
import { getError } from 'src/utils/getError'

const getConfirmationText = (
	variant: PhoneConfirmationVariant,
	phone: string
) => {
	const texts: Record<PhoneConfirmationVariant, string> = {
		phone: `В течение минуты на номер ${PhoneFormatter(
			phone
		)} поступит звонок. Введите последние 4 цифры номера, с которого звонят.`,
		sms: `В течение минуты на номер ${PhoneFormatter(
			phone
		)} придет СМС с кодом`,
	}

	return texts[variant]
}
const confirmationTimerText: Record<PhoneConfirmationVariant, string> = {
	phone: 'Звонок не поступил? ',
	sms: 'Смс не пришло? ',
}

type Props = {
	onSubmit: (data: AcceptPhoneActivationCodeData) => Promise<any>
	onCodeResend: (variant: PhoneConfirmationVariant) => Promise<any>
	onBack: () => void
	phone: string
	cn: any
	extraActions?: React.ReactNode
	extraActionsClassName?: string
}
export default function PhoneConfirmation({
	onSubmit,
	onCodeResend,
	onBack,
	phone,
	cn,
	extraActions,
	extraActionsClassName,
}: Props) {
	const [confirmationVariant, setConfirmationVariant] =
		useState<PhoneConfirmationVariant>('sms')

	const endTimer = useRef(Number(new Date()) + 60 * 1e3)
	const [timer, setTimer] = useState(60)
	const intervalRef = useRef<any>(0)
	const [codeError, setCodeError] = useState('')

	async function handleCodeResend(variant: PhoneConfirmationVariant) {
		try {
			await onCodeResend(variant)
			setConfirmationVariant(variant)
			endTimer.current = Number(new Date()) + 60 * 1e3
			setTimer(60)
		} catch (e) {
			const error = getError(e)

			if (error?.data && typeof error?.data?.detail === 'string') {
				setCodeError(error.data.detail)
			}
		}
	}

	async function handleSubmit(data) {
		try {
			await onSubmit({ ...data, variant: confirmationVariant })
		} catch (e: any) {
			if (e?.message) {
				setCodeError(e.message)
			}
			if (e.data.code) {
				setCodeError(e.data.code)
			}
		}
	}
	const confirmationText = useMemo(
		() => getConfirmationText(confirmationVariant, phone),
		[phone, confirmationVariant]
	)
	const timerText = useMemo(
		() =>
			`${confirmationTimerText[confirmationVariant]} Можно повторить через `,
		[confirmationVariant]
	)

	useEffect(() => {
		if (timer && !intervalRef.current) {
			intervalRef.current = setInterval(() => {
				setTimer((state) => {
					if (state < 1) {
						clearTimeout(intervalRef.current)
						intervalRef.current = null
						return 0
					} else {
						return Math.round(
							(endTimer.current - Number(new Date())) / 1e3
						)
					}
				})
			}, 1000)
		}
	}, [timer])

	useEffect(
		() => () => {
			if (intervalRef.current) {
				clearTimeout(intervalRef.current)
			}
		},
		[]
	)

	return (
		<Formik initialValues={{ code: '' }} onSubmit={handleSubmit}>
			{({ errors, values, handleSubmit, setFieldValue }: any) => (
				<div className={s.phoneConfirmationContainer}>
					<div className={s.phoneConfirmationBody}>
						<div className={s.confirmationText}>
							{confirmationText}
						</div>
						<div className={s.confirmationCode}>
							<Form.Label>Код подтверждения</Form.Label>
							<InputGroup hasValidation className="m-0">
								<Form.Control
									size="lg"
									type={'number'}
									placeholder={''}
									onChange={(e) => {
										const value = e.target.value
										if (value.length >= 5) {
											return (e.target.value =
												e.target.value.slice(0, 4))
										}
										setFieldValue('code', value)
										setCodeError('')
										if (value.length > 3) {
											handleSubmit()
										}
									}}
									isInvalid={!!errors.code || !!codeError}
									pattern="[0-9]*"
									inputMode="numeric"
								/>
								<Form.Control.Feedback type="invalid">
									{errors.code || codeError}
								</Form.Control.Feedback>
							</InputGroup>
							{!!timer && !errors.code && !codeError && (
								<Form.Text className={s.timerText}>
									{timerText}
									{TimeFormatter(timer)}
								</Form.Text>
							)}
						</div>
					</div>

					<div className={s.phoneConfirmationButtons}>
						{!timer && (
							<div className={s.phoneConfirmationVariants}>
								<Button
									variant="link"
									onClick={() => handleCodeResend('phone')}
								>
									Получить код по звонку
								</Button>
								<Button
									variant="link"
									onClick={() => handleCodeResend('sms')}
								>
									Прислать код из СМС
								</Button>
							</div>
						)}
						<div className={extraActionsClassName || ''}>
							<Button
								className={s.backButton}
								onClick={onBack}
								variant={'secondary'}
								size="lg"
							>
								<i className="bi bi-arrow-left" />
								Вернуться назад
							</Button>

							{extraActions}
						</div>
					</div>
				</div>
			)}
		</Formik>
	)
}
