import { TabType } from 'src/components/UtilityComponents/tabs'
import { ModerationNotificationsCountsType } from '../types/ModerationRequestTypes'

export type NotificationState = 'created' | 'draft'

export const getNotificationsTabsWithCount = (
	counts: ModerationNotificationsCountsType
): Array<TabType<NotificationState>> => {
	return [
		{
			count: counts?.count_created || 0,
			key: 'created',
			title: 'Созданные',
		},
		{ count: counts?.count_draft || 0, key: 'draft', title: 'Черновики' },
	]
}
