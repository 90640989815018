import React, { useEffect, useMemo, useState } from 'react'
import bem from '../../../utils/bem'
import { withPage } from '../../page/Page'
import '../../../styles/create-campaign-page.scss'
import { useHistory } from 'react-router-dom'
import BusinessDataForm from './Step0-Buisness/BusinessDataForm'
import { Card, Spinner } from 'react-bootstrap'
import CampaignDataForm from './Step1-Campaign/CampaignDataForm'
import useCampaignData from '../../../stores/campaignData'
import DataUpload from './Step3-Upload/DataUpload'
import useResponsive from '../../../utils/useResponsive'
import moment from 'moment'
import AdFormatSummary from './Step2-ADformats/ADFormatSummary'
import { StepType, Stepper } from './components'
import {
	checkDistrictBookingsPlacementsCount,
	checkIsAllAdvFormatsHasValidMedia,
	checkIsAtLeastOneFormatSelected,
} from 'src/utils'
import { AdvFormatsStep } from './Step2-ADformats/new-adformats-step/ui/AdvFormatsStep'

export const cn = bem('create-campaign-page')
export enum CreatingSteps {
	Step1 = 0,
	Step2 = 1,
	Step3 = 2,
	Step4 = 3,
}

export const STEPS: Array<StepType> = [
	{
		label: 'Данные о рекламной кампании',
		Component: CampaignDataForm,
	},
	{ label: 'Форматы рекламы', Component: AdvFormatsStep },
	{ label: 'Загрузка материалов', Component: DataUpload },
	{ label: 'Данные о бизнесе', Component: BusinessDataForm },
]

export function CreateCampaignPage() {
	const history = useHistory()
	const [campaign] = useCampaignData()
	const [step, setStep] = useState(0)

	const { Component, label } = useMemo(() => STEPS[step], [step])
	const [loading, setLoading] = useState(true)
	const [initialValues, setInitialValues] = useState<null | {}>(null)
	const [companyId, setCompanyId] = useState(() => {
		if (campaign?.id) return campaign?.company?.id
		return null
	})
	const { isMobile } = useResponsive()
	const firstAvailableStartDate = useMemo(() => {
		const today = moment()
		if (today.date() < 20) {
			today.add(1, 'month').date(1)
		} else {
			today.add(1, 'month').date(15)
		}
		return today
	}, [])

	const CheckCampaignStartDate = useMemo(() => {
		if (!campaign?.start_date) return true
		const start = moment(campaign.start_date, 'YYYY-MM-DD')
		const today = moment()
		const daysToStart = start.diff(today, 'days')

		if (daysToStart < 7) {
			return false
		}
		return true
	}, [campaign.start_date, firstAvailableStartDate])

	const availableStep = useMemo(() => {
		//Step-1 validation
		if (!campaign?.id) return CreatingSteps.Step1
		if (!CheckCampaignStartDate) return CreatingSteps.Step1

		//Step-1 - available, Step-2 validation
		const isDistrictBookingsPlacementsCountsValid =
			checkDistrictBookingsPlacementsCount(campaign)

		if (
			!checkIsAtLeastOneFormatSelected(campaign) ||
			!isDistrictBookingsPlacementsCountsValid
		) {
			return CreatingSteps.Step2
		}
		if (!campaign.is_budget_confirmed) {
			return CreatingSteps.Step2
		}
		//Step2 - available, Step-3 validation
		const isMediaValid = checkIsAllAdvFormatsHasValidMedia(campaign)

		if (!isMediaValid) {
			return CreatingSteps.Step3
		}
		//Step-3 - available, all Steps available
		return CreatingSteps.Step4
	}, [campaign, CheckCampaignStartDate])

	const handleStepClick = (step: number) => {
		setStep(step)
	}

	useEffect(() => {
		setLoading(false)

		setStep(availableStep)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [step])

	return (
		<div className={cn()}>
			<h1 className={cn('title')}>Создание рекламной кампании</h1>

			<div className={cn('body')}>
				{/* change steps */}
				{step === 0 ? (
					<Card className={cn('form')}>
						<h2
							className={cn('subtitle')}
							style={{ marginBottom: '8px' }}
						>
							{label}
						</h2>
						<div
							className={cn('separator')}
							style={{
								margin: 0,
								padding: 0,
								marginBottom: '24px',
							}}
						/>
						{loading ? (
							<div className={cn('spinner')}>
								<Spinner animation={'border'} />
							</div>
						) : (
							<Component
								cn={cn}
								companyId={campaign?.company?.id || companyId}
								onSetCompanyId={async (data) => {
									const { id, name } = data
									setCompanyId(id)
									setInitialValues({ name })
									setStep(1)
								}}
								onNextStep={() => setStep((step) => ++step)}
								onBack={() => {
									setStep((step) => --step)
								}}
								setStep={setStep}
								initialValues={initialValues}
							/>
						)}
					</Card>
				) : (
					<Component
						cn={cn}
						companyId={campaign?.company?.id || companyId}
						onSetCompanyId={async (data) => {
							const { id, name } = data
							setCompanyId(id)
							setInitialValues({ name })
							setStep(1)
						}}
						onNextStep={() => setStep((step) => ++step)}
						onBack={() => setStep((step) => --step)}
						setStep={setStep}
						initialValues={initialValues}
					/>
				)}

				<Stepper
					step={step}
					avaliableStep={availableStep}
					onStepClick={handleStepClick}
				/>
			</div>
		</div>
	)
}

export default withPage({ noSidebar: false, isProvider: false })(
	CreateCampaignPage
)
