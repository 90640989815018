import moment from 'moment'
import declOfNum from './declOfNum'

//Принимает время в секундах
const TimeFormatter = (time: number) => {
	const duration = moment.duration(time, 'seconds')
	const minutes = Math.floor(duration.asMinutes())
	const remainingSeconds = duration.seconds()

	return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
		.toString()
		.padStart(2, '0')}`
}

export default TimeFormatter
