import React, { useEffect, useMemo, useState } from 'react'
import bem from '../../../utils/bem'
import { Badge, Button, Modal } from 'react-bootstrap'
import useResponsive from '../../../utils/useResponsive'
import { Link } from 'react-router-dom'

import moment from 'moment'
import {
	CreateAndEditModal,
	PlacementTabs,
	PlacementsContent,
	PlacementsMap,
	ProviderFilter,
	ProviderMobileFilters,
} from './components'
import {
	PlacementFilterType,
	PlacementTabCountKeyType,
	PlacementTabKeyType,
	PlacementTabType,
	placementTabs,
} from './constants'
import { usePushObjectToQueryParams, useQueryParams } from 'src/utils'
import { intializePlacementFilters } from './utils'
import useProviderData from 'src/stores/providerData'
import useHeaderTitle from 'src/components/page/useHeaderTitle'
import { PlacementFilterKeyType } from './constants/placementFilters'
import { FiltersItemList } from './components/placements-filter/filter-state/FiltersItemList'

const cn = bem('reklamnie-nositeli-page')
export type PlacementPageQueryParamsType = {
	limit: string
	advertising_company: string | string[]
	company: string | string[]
	placement_region: string | string[]
	offset: string
	state: string
	door_type: string
	q: string
	in_archive: string
}

type PlacementsStatsType = Record<PlacementTabCountKeyType & 'count', number>

const getUpdatedTabs = (
	placementsStats: PlacementsStatsType
): Array<PlacementTabType> => {
	return placementTabs.map((tab) => {
		if (placementsStats?.[tab.countKey]) {
			return { ...tab, count: placementsStats[tab.countKey] }
		} else {
			return tab
		}
	})
}
const PlacementsPage = (props) => {
	useHeaderTitle('Рекламные носители')

	const { params } = useQueryParams<PlacementPageQueryParamsType>()
	const pushObjectToQuery = usePushObjectToQueryParams()

	const [provider, providerInterface] = useProviderData()

	const [searchString, setSearchString] = useState('')

	const [filters, setFilters] = useState<Array<PlacementFilterType>>(() =>
		intializePlacementFilters(params)
	)

	const [selectedItemId, setSelectedItemId] = useState<string | undefined>(
		undefined
	)

	const [modalOpen, setModalOpen] = useState(false)
	const [mapOpen, setMapOpen] = useState(false)

	const [paginationModel, setPaginationModel] = useState<{
		limit: number
		offset: number
	}>({ limit: 10, offset: 0 })

	const [selectedTab, setSelectedTab] = useState<PlacementTabKeyType>(() => {
		if (params.state) return params.state as PlacementTabKeyType
		else return 'all'
	})

	const advertisingCompanyIds = useMemo(() => {
		return filters.find((filter) => filter.key === 'advertising_company')
			?.filterItems
	}, [filters])

	const companiesIds = useMemo(() => {
		return filters.find((filter) => filter.key === 'company')?.filterItems
	}, [filters])

	const regionsIds = useMemo(() => {
		return filters.find((filter) => filter.key === 'placement_region')
			?.filterItems
	}, [filters])
	const { isMobile } = useResponsive()

	const handleSetFilter = (
		key: PlacementFilterKeyType,
		value: Array<string>
	) => {
		const newFilters = filters.map((filter) =>
			filter.key === key ? { ...filter, filterItems: value } : filter
		)
		setFilters(newFilters)
		setPaginationModel({ limit: 10, offset: 0 })
	}
	const handleResetFilters = () => {
		const newFilters = filters.map((filter) => {
			return { ...filter, filterItems: [] }
		})
		setFilters(newFilters)
	}
	const handleDeleteFilter = (filterKey: PlacementFilterKeyType) => {
		const newFilters = filters.map((filter) =>
			filter.key === filterKey ? { ...filter, filterItems: [] } : filter
		)
		setFilters(newFilters)
	}

	const handleSetTab = (tabKey: PlacementTabKeyType) => {
		setSelectedTab(tabKey)
		setPaginationModel({ limit: 10, offset: 0 })
	}

	const handleCreateOrEditItem = (itemId?: string) => {
		setSelectedItemId(itemId)
		setModalOpen(true)
	}
	const handleCloseModal = () => {
		setSelectedItemId(undefined)
		setModalOpen(false)
	}
	const searchVariants = provider?.placements?.results.map(
		(placement) => placement.name
	)
	const updatePlacementsInfo = async () => {
		const unionObject: Partial<PlacementPageQueryParamsType> = {
			advertising_company: advertisingCompanyIds,
			state:
				selectedTab === 'archive' || selectedTab === 'all'
					? ''
					: selectedTab,
			company: companiesIds,
			placement_region: regionsIds,
			limit: String(paginationModel.limit),
			offset: String(paginationModel.offset),
			q: searchString,
			in_archive: selectedTab === 'archive' ? 'true' : 'false',
		}
		const query = pushObjectToQuery(unionObject)

		await Promise.all([
			providerInterface.getCounts(),
			providerInterface.fetchPlacementsQuery(query),
		])
	}
	useEffect(() => {
		const unionObject: Partial<PlacementPageQueryParamsType> = {
			advertising_company: advertisingCompanyIds,
			state:
				selectedTab === 'archive' || selectedTab === 'all'
					? ''
					: selectedTab,
			company: companiesIds,
			placement_region: regionsIds,
			limit: String(paginationModel.limit),
			offset: String(paginationModel.offset),
			q: searchString,
			in_archive: selectedTab === 'archive' ? 'true' : 'false',
		}

		if (selectedTab === 'used') {
			delete unionObject.in_archive
		}
		const query = pushObjectToQuery(unionObject)

		providerInterface.fetchPlacementsQuery(query)
	}, [selectedTab, searchString, paginationModel, filters])

	return (
		<div className={cn()}>
			<PlacementTabs
				onCreate={handleCreateOrEditItem}
				selectedTab={selectedTab}
				setTab={handleSetTab}
				tabs={getUpdatedTabs(provider?.placements)}
			/>
			{isMobile && (
				<ProviderMobileFilters
					filters={filters}
					setFilter={handleSetFilter}
				/>
			)}
			{!isMobile && (
				<ProviderFilter
					allFiltersSelected={false}
					filters={filters}
					isCreateButton={true}
					isMapButton={true}
					onCreateItemAction={() => handleCreateOrEditItem()}
					onMapButtonClick={() => setMapOpen(true)}
					onSearchStringChange={setSearchString}
					onSetFilter={handleSetFilter}
					onDeleteFilter={handleDeleteFilter}
					onResetFilters={handleResetFilters}
					setFilters={setFilters}
					createItemButtonTitle="Создать рекламный носитель"
					searchVariants={searchVariants}
					searchString={searchString}
				>
					<FiltersItemList
						filters={filters}
						onDeleteFilter={handleDeleteFilter}
						searchObject={provider.bookings_filters}
					/>
				</ProviderFilter>
			)}

			<PlacementsContent
				updatePlacementsInfo={updatePlacementsInfo}
				onEdit={handleCreateOrEditItem}
				setPaginationModel={setPaginationModel}
				{...paginationModel}
				total={provider?.placements?.count || 0}
			/>

			<CreateAndEditModal
				updatePlacementsInfo={updatePlacementsInfo}
				onClose={handleCloseModal}
				open={modalOpen}
				setOpen={setModalOpen}
				placementId={selectedItemId}
			/>

			<PlacementsMap
				onHide={() => setMapOpen(false)}
				show={mapOpen}
				placements={provider?.placements?.results}
			/>
		</div>
	)
}

const NoContent = () => (
	<div
		className={
			'd-flex flex-column justify-content-center w-100 h-100 align-items-center'
		}
		style={{ gap: '16px', minHeight: 'calc(100vh - 300px)' }}
	>
		<div style={{ fontSize: '40px' }}>🙂</div>
		<div>
			<h4 className={'text-secondary'}>
				Нет ни одного рекламного носителя
			</h4>
		</div>
		<div>
			<Link to={'/provider/placements/create-placement'}>
				<Button>Создать рекламный носитель</Button>
			</Link>
		</div>
	</div>
)

export const PlacementBadge = ({
	status,
	is_active,
	activation_date,
	hover = false,
}) => {
	const statusText = () => {
		if (is_active) return 'used' === status ? 'Забронирован' : 'Свободен'
		if (!activation_date) return 'Неактивен'
		if (activation_date) {
			const date = moment(activation_date).format('DD.MM.YYYY')
			return 'Неактивен до ' + date
		}
	}

	return (
		<Badge
			bg={is_active ? ('used' === status ? 'primary' : 'secondary') : ''}
			style={
				is_active
					? {
							height: '18px',
							padding: '3px 4px',
							fontFamily: 'Inter',
							fontStyle: 'normal',
							fontWeight: 500,
							fontSize: '12px',
							lineHeight: '12px',
							letterSpacing: '-0.05em',
							marginLeft: '8px',
					  }
					: {
							height: '18px',
							padding: '3px 4px',
							fontFamily: 'Inter',
							fontStyle: 'normal',
							fontWeight: 500,
							fontSize: '12px',
							lineHeight: '12px',
							letterSpacing: '-0.05em',
							marginLeft: '8px',
							backgroundColor: hover ? '#fff' : '#f5f5f5',
							color: '#6C757D',
					  }
			}
		>
			{statusText()}
		</Badge>
	)
}

export default PlacementsPage

export const GetBackgroundColorByStateAndActive = (
	state: string,
	is_active: boolean
) => {
	if (!is_active) return '#6C757D'
	return state === 'used' ? '#007bff' : '#6C757D'
}
