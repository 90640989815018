import React, { useState } from 'react'
import { DisplayIssuesCampaign } from 'src/components/_moderator/types/ModerationRequestTypes'
import {
	CampaignIssueWrapper,
	IssueObjectType,
} from '../common/components/CampaignIssueWrapper'
import {
	AdvType,
	NotificationModel,
	NotifyItem,
} from '../common/components/IssuesContent'
import { CampaignInfo } from '../common/components/CampaignInfo'
import { IssueAccordion } from './IssueAccordion'
import { ReactComponent as Minus } from '../../../../../../assets/images/minus.svg'

type MobileIssuesProps = {
	displayIssues: DisplayIssuesCampaign[]
	channels: {
		id: number
		name: string
	}[]
	setNotifyItem: React.Dispatch<
		React.SetStateAction<NotificationModel | undefined>
	>
}
export const MobileIssues = ({
	displayIssues,
	channels,
	setNotifyItem,
}: MobileIssuesProps) => {
	return (
		<div>
			{displayIssues.map((el) => (
				<CampaignIssueWrapper
					key={el.id}
					WrappedComponent={MobileIssue}
					channels={channels}
					issue={el}
					setNotifyItem={setNotifyItem}
				/>
			))}
		</div>
	)
}

type MobileIssueProps = {
	issueObject: { [key in AdvType]: IssueObjectType[] }
	totalIssuesCount: number
	setNotifyItem: (notifyItem: NotifyItem) => void
	issue: DisplayIssuesCampaign
}
export const MobileIssue = ({
	issue,
	issueObject,
	setNotifyItem,
	totalIssuesCount,
}: MobileIssueProps) => {
	const [collapsed, setCollapsed] = useState(false)

	return (
		<div style={{ padding: '16px' }}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
					paddingBottom: '16px',
					borderBottom: '1px solid #CED4DA',
				}}
			>
				<div
					style={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<CampaignInfo issue={issue} />
					<div
						onClick={() => setCollapsed(!collapsed)}
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '16px',
							height: '16px',
							border: '1px solid #007BFF',
							borderRadius: '4px',
							margin: '8px',
						}}
					>
						{collapsed ? (
							<i
								style={{ color: '#007BFF' }}
								className="bi bi-plus"
							></i>
						) : (
							<Minus />
						)}
					</div>
				</div>
				{!collapsed && (
					<div
						id={'accordions-block'}
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '8px',
						}}
					>
						{Object.keys(issueObject).map((el) => (
							<IssueAccordion
								setNotifyItem={setNotifyItem}
								key={el}
								title={el as AdvType}
								issues={issueObject[el]}
							/>
						))}
					</div>
				)}
			</div>
		</div>
	)
}
