import React from 'react'
import { Dropdown } from 'react-bootstrap'
import s from '../../../garantid-card/garantid-media-format-card/components/garantid-item/garantidItem.module.scss'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { GarantidItemMenu } from 'src/components/common/garantid-card/garantid-media-format-card/components/garantid-item/GarantidItemMenu'
import { ReactComponent as EditIcon } from 'src/assets/images/edit.svg'
import { DistrictBookingItemModals } from '../DistrictBookingItemCard'
import cn from 'classnames'
import { ConditionArrowLeftRight } from 'src/components/UtilityComponents'
import useResponsive from 'src/utils/useResponsive'

type Props = {
	cityName: string
	cityBudget
	setModalType: (modalType: DistrictBookingItemModals) => void
	onRemove: () => void
	isRemoveButton: boolean
	isEditButton: boolean
}

export const DistrictBookingItemCardHeader = ({
	cityBudget,
	cityName,
	setModalType,
	onRemove,
	isRemoveButton,
	isEditButton,
}: Props) => {
	const { isMobile } = useResponsive()
	return (
		<div className={s.garandidCampaignItemHeader}>
			<div className={cn(s.garandidCampaignItemPlacement_bold)}>
				<div className={s.garandidCampaignPlacementInfo}>
					<h5 className="m-0">{cityName}</h5>
				</div>

				<h5 className="m-0">{IntlFormatter.format(cityBudget)}</h5>
			</div>

			<GarantidItemMenu mobileViewClassName={s.garandidItemMenuMobile}>
				{isEditButton && (
					<Dropdown.Item
						eventKey="3"
						className={s.garantidmMenuItem}
						onClick={() => setModalType('edit')}
						mobileAutoClose={true}
					>
						<EditIcon />
						<p>Исправить</p>
						{isMobile && (
							<ConditionArrowLeftRight
								open={false}
								styles={{ display: 'block' }}
							/>
						)}
					</Dropdown.Item>
				)}

				{isRemoveButton && (
					<Dropdown.Item
						eventKey="4"
						className={cn(
							s.garantidmMenuItem,
							s.garandidCampaignMenuItem_delete
						)}
						onClick={onRemove}
						mobileAutoClose={true}
					>
						<i className="bi bi-trash"></i>
						<p>Удалить размещение</p>
					</Dropdown.Item>
				)}
			</GarantidItemMenu>
		</div>
	)
}
