import { useEffect, useRef, useState } from 'react'
import { authFetch, plainFetch } from './authFetch'

type useQueryParams = {
	url: string
	AuthRequest?: boolean
	method?: 'GET' | 'POST' | 'PATCH' | 'DELETE'
	pag?: {
		limit: number
		offset: number
	}
	body?: {}
	cacheByUrl?: boolean
}

type useQueryResult<T> = [null | T, boolean, any]

const CACHE_TIME = 10000

export const cache: any = {}
export const promises: any = {}
const isInitialized: any = {}
const cacheTime: any = {}

export function getPromiseOrValue(url: string): any {
	return promises[url] || cache[url]
}

const useQuery = <T>({
	AuthRequest = true,
	url,
	cacheByUrl = false,
	method = 'GET',
	body = {},
}: useQueryParams): useQueryResult<T> => {
	const [data, setData] = useState<null | T>(cache[url] || null)
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [error, setError] = useState<any>(null)
	const urlRef = useRef(url)
	urlRef.current = url

	if (
		!isInitialized[urlRef.current] &&
		!promises[urlRef.current] &&
		cacheByUrl
	) {
		isInitialized[urlRef.current] = true
		promises[urlRef.current] = getRequestOrValue()
	}

	useEffect(() => {
		;(async () => {
			if (!url) return

			if (cacheByUrl) {
				if (cache[url]) {
					setData(cache[url])
				}
			} else setData(null)

			setIsLoading(true)
			setError(null)

			const promise = (cacheByUrl && promises[url]) || getRequestOrValue()

			try {
				promises[url] = promise
				let data: any = await promise
				cache[url] = data
				if (urlRef.current === url) {
					setData(data)
				}
			} catch (e) {
				console.log('err', e)
				setData(null)
				setError(e)
			} finally {
				setIsLoading(false)
				delete promises[url]
			}
		})()
	}, [url])

	function getRequestOrValue() {
		if (!cacheByUrl) return request(AuthRequest, url, method, body)

		const currentUrl = urlRef.current
		const cacheTimeDiff = Date.now() - cacheTime[currentUrl] || Infinity
		const isUpdate = cacheTimeDiff > CACHE_TIME

		let result
		if (isUpdate) {
			cacheTime[currentUrl] = Date.now()
			result = request(AuthRequest, url, method, body)
		} else result = cache[currentUrl]

		return result
	}

	return [data, isLoading, error]
}

function request(AuthRequest, url, method, body) {
	return AuthRequest
		? authFetch({
				url,
				method,
				body,
		  })
		: plainFetch({ url, method, body })
}

export default useQuery
