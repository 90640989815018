import React from 'react'
import {
	AdvFormats,
	AdvSelectorData,
	AdvSelectorState,
} from '../../../../types'
import s from './formatsSelector.module.scss'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { ReactComponent as GoRightIcon } from 'src/assets/images/go-right.svg'
import { ReactComponent as OkIcon } from 'src/assets/images/ok.svg'
import cn from 'classnames'
import useResponsive from 'src/utils/useResponsive'
type Props = {
	selector: AdvSelectorData
	onSelectFormat: (format: AdvFormats) => void
}
export const FormatsSelectorItem = ({ onSelectFormat, selector }: Props) => {
	const isStatusVisible = selector.state === AdvSelectorState.Added
	const isDisabled = selector.state === AdvSelectorState.Disabled

	const handleSelectFormat = () => {
		if (!isDisabled) {
			onSelectFormat(selector.key)
		}
	}
	const { isMobile } = useResponsive()

	const paddingY = isMobile ? '16px' : '12px'
	return (
		<div
			className={cn(s.selectorItem, {
				[s.selectorItem_disabled]: isDisabled,
			})}
			style={{
				paddingTop: isStatusVisible ? paddingY : 'auto',
				paddingBottom: isStatusVisible ? paddingY : 'auto',
			}}
			onClick={handleSelectFormat}
		>
			{isMobile ? (
				<MobileSelectorBody selector={selector} />
			) : (
				<DesktopSelectorBody selector={selector} />
			)}
		</div>
	)
}

type DesktopSelectorBodyProps = {
	selector: AdvSelectorData
}

export const DesktopSelectorBody = ({ selector }: DesktopSelectorBodyProps) => {
	const Icon = selector.icon
	const isStatusVisible = selector.state === AdvSelectorState.Added

	return (
		<div className={s.selectorInfo}>
			<Icon />

			<div className={s.selectorBody}>
				<h6>{selector.title}</h6>

				<div className={s.selectorStatus}>
					<h6>{IntlFormatter.format(selector.totalAmount)}</h6>
					{isStatusVisible && (
						<span className={s.addedStatus}>
							<OkIcon />
							Добавлено
						</span>
					)}
				</div>
			</div>

			<GoRightIcon className={s.goRightIcon} />
		</div>
	)
}

type MobileSelectorBodyProps = {
	selector: AdvSelectorData
}
export const MobileSelectorBody = ({ selector }: MobileSelectorBodyProps) => {
	const Icon = selector.icon
	const isStatusVisible = selector.state === AdvSelectorState.Added

	return (
		<div className={s.selectorInfo}>
			<div className={s.selectorBody}>
				<Icon />

				<div className={s.selectorStatus}>
					<h6>{selector.title}</h6>
					<h6>{IntlFormatter.format(selector.totalAmount)}</h6>
				</div>
				<GoRightIcon className={s.goRightIcon} />
			</div>
			{isStatusVisible && (
				<span className={s.addedStatus}>
					<OkIcon />
					Добавлено
				</span>
			)}
		</div>
	)
}
