import {
	NotificationAdvTypeChoises,
	NotificationModel,
	NotificationStateAdvChoises,
} from '../types/ModerationRequestTypes'

const TypeAdvCompanyTitles: Record<NotificationAdvTypeChoises, string> = {
	indoor: 'Внутренняя реклама',
	outdoor: 'Наружная реклама',
	internet: 'Интернет-реклама',
	tv: 'TV-реклама',
	transport: 'Общественный транспорт',
}
const StateAdvCompanyTitles: Record<NotificationStateAdvChoises, string> = {
	adjustment: 'на исправлении',
	canceled: 'отмененные',
	completed: 'завершенные',
	moderation: 'на модерации',
	reject: 'отклоненные',
	start_waiting: 'ожидают запуска',
}
export const getSenderByMessage = (notifyModel: NotificationModel) => {
	let items = [] as string[]
	switch (notifyModel.category) {
		case 'adv_company':
			items = notifyModel.extra_data.state_adv_company.map(
				(key) => StateAdvCompanyTitles[key]
			)

			break
		case 'adv_type':
			items = notifyModel.extra_data.type_adv_company.map(
				(key) => TypeAdvCompanyTitles[key]
			)
			break
		case 'client':
			items = Object.values(notifyModel.extra_data.company_names)
			break
		case 'related_item':
			{
				items = Object.values(notifyModel.extra_data.company_names)
			}
			break
		default:
			items = [] as string[]
			break
	}

	let title = ''

	switch (notifyModel.category) {
		case 'adv_company':
			if (items.length > 1) {
				title = 'Кампаниям'
			} else {
				title = 'Кампании'
			}
			break
		case 'adv_type':
			if (items.length > 1) {
				title = `${items.length} типам рекламы`
			} else {
				title = 'Типу рекламы'
			}
			break
		case 'client':
			if (items.length > 1) {
				title = `${items.length} клиентам`
			} else {
				title = `${items.length} клиенту`
			}
			break
		case 'related_item':
			if (items.length > 1) {
				title = `${items.length} клиентам`
			} else {
				title = `${items.length} клиенту`
			}
			break
		default:
			title = ``
			break
	}

	return {
		title: title,
		items: items,
	}
}

export const getSenderByMessageMobile = (notifyModel: NotificationModel) => {
	let sender = ''
	switch (notifyModel.category) {
		case 'adv_company':
			sender = 'Кампаниям'
			break
		case 'adv_type':
			const typeCounts = notifyModel.extra_data.type_adv_company.length
			sender = `${typeCounts} ${
				typeCounts === 1 ? 'типу' : 'типам'
			} рекламы`
			break
		case 'client':
		case 'related_item':
			const clientCounts = Object.values(
				notifyModel.extra_data.company_names
			).length

			sender = `${clientCounts} ${
				clientCounts % 10 === 1 ? 'клиенту' : 'клиентам'
			}`
			break
	}

	return sender
}
