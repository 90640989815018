import React from 'react'
import { Button } from 'react-bootstrap'
import { ReactComponent as RetryIcon } from '../../../../../../assets/images/retry.svg'

import s from '../providerFilter.module.scss'
import cn from 'classnames'

import { ConditionArrowUpDown } from 'src/components/UtilityComponents'

type RollUpAndResetButtonProps = {
	isResetDisabled?: boolean
	onResetFilters?: () => void
	setShowCurrentFiltersRow: (value: boolean) => void
	showCurrentFiltersRow: boolean
}

export const RollUpAndResetButton = ({
	onResetFilters,
	setShowCurrentFiltersRow,
	showCurrentFiltersRow,
	isResetDisabled = false,
}: RollUpAndResetButtonProps) => {
	const handleResetFilters = () => {
		onResetFilters && onResetFilters()
	}
	return (
		<>
			<Button
				variant={'light'}
				className={cn(s.iconButton)}
				onClick={() => setShowCurrentFiltersRow(!showCurrentFiltersRow)}
			>
				Свернуть
				<ConditionArrowUpDown open={showCurrentFiltersRow} />
			</Button>

			<Button
				disabled={isResetDisabled}
				variant={'light'}
				className={cn(s.iconButton)}
				onClick={handleResetFilters}
			>
				<RetryIcon style={{ width: '16px', height: '16px' }} />
			</Button>
		</>
	)
}
