import React from 'react'
import ProgressFileInput from 'src/components/UtilityComponents/ProgressFileInput'
import useCampaignData from 'src/stores/campaignData'
import TvControls from '../common/TvControls'
import { TvType } from '../TvStateView.types'

export const TeletargetItem = (props: any) => {
	const [campaign] = useCampaignData()
	return (
		<>
			{campaign.teletarget.is_small_banner &&
				!campaign.teletarget.is_l_banner && (
					<>
						<ProgressFileInput
							initialValue={{
								src:
									campaign.teletarget.image ||
									'https://storage.yandexcloud.net/admarket-media-dev/empty_VmO0bhA.jpg',
								name: 'Медиаматериал',
							}}
							updateUrl=""
							nameInBody=""
							onlyView
						/>
						<TvControls tvType={TvType.TELETARGET} />
					</>
				)}

			{campaign.teletarget.is_l_banner &&
				!campaign.teletarget.is_small_banner && (
					<>
						<ProgressFileInput
							initialValue={{
								src:
									campaign.teletarget.l_image ||
									'https://storage.yandexcloud.net/admarket-media-dev/empty_VmO0bhA.jpg',
								name: 'Медиаматериал',
							}}
							updateUrl=""
							nameInBody=""
							onlyView
						/>
						<TvControls tvType={TvType.TELETARGET} />
					</>
				)}

			{campaign.teletarget.is_l_banner &&
				campaign.teletarget.is_small_banner && (
					<>
						<ProgressFileInput
							initialValue={{
								src:
									campaign.teletarget.image ||
									'https://storage.yandexcloud.net/admarket-media-dev/empty_VmO0bhA.jpg',
								name: 'Медиаматериал',
							}}
							updateUrl=""
							nameInBody=""
							onlyView
						/>
						<TvControls tvType={TvType.TELETARGET} />

						<ProgressFileInput
							initialValue={{
								src:
									campaign.teletarget.l_image ||
									'https://storage.yandexcloud.net/admarket-media-dev/empty_VmO0bhA.jpg',
								name: 'Медиаматериал',
							}}
							updateUrl=""
							nameInBody=""
							onlyView
						/>
						<TvControls tvType={TvType.TELETARGET} />
					</>
				)}
		</>
	)
}
