import React from 'react'
import s from './style.module.scss'
import cn from 'classnames'
import { CampaignsList } from './campaigns-list/CampaignsList'
import { CampaignItemInfo } from './campaign-item-info/common/CampaignItemInfo'
import { ProviderPagination } from 'src/components/_provider/Placements/components'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { ModerAdvCompanyItemType } from 'src/components/_moderator/types/ModerationRequestTypes'

type ContentPropsType = {
	total: number
	limit: number
	offset: number
	selectedItem: ModerAdvCompanyItemType | undefined
	setSelectedItem: (adv: ModerAdvCompanyItemType | undefined) => void
	setPaginationModel: React.Dispatch<
		React.SetStateAction<{
			limit: number
			offset: number
		}>
	>
	isLoading: boolean
}
export const Content = ({
	limit,
	offset,
	setPaginationModel,
	total,
	selectedItem,
	setSelectedItem,
	isLoading,
}: ContentPropsType) => {
	const setLimit = (limit: number) => {
		setPaginationModel((prev) => ({ ...prev, limit: limit }))
	}
	const setOffset = (offset: number) => {
		setPaginationModel((prev) => ({ ...prev, offset: offset }))
	}

	return (
		<div className={cn(s.content)}>
			<CampaignsList
				setLimit={setLimit}
				setOffset={setOffset}
				total={total}
				limit={limit}
				offset={offset}
				isLoading={isLoading}
				selectedItemId={selectedItem?.id}
				setSelectedItem={setSelectedItem}
			/>

			<CampaignItemInfo isLoading={isLoading} campaign={selectedItem} />
		</div>
	)
}
