import React, { useMemo } from 'react'
import { DatePickerPresetsType } from './CampaignUtilsFunctions'
import moment from 'moment/moment'
import DateInput from '../../../UtilityComponents/DropdownDatePicker'
import { Alert, Button, ButtonGroup, Form } from 'react-bootstrap'
import ModalDateInput from '../../../UtilityComponents/ModalDateInput'
import FormLabelTooltip from 'src/components/UtilityComponents/FormLabelTooltip'

const minDaysAdvCampaignLength = 7
const daysBeforeCampaignStart = 7

const getFirstAvailableEndDay = (start_date: moment.Moment) => {
	//minDaysAdvCampaignLength - 1 поскольку дни указываются включительно
	return moment(start_date).add(minDaysAdvCampaignLength - 1, 'days')
}
type Props = {
	values: any
	setFieldValue: any
	cn: any
	errors: any
	firstAvaliableDate: any
	isMobile: any
	campaign: any
	presets: DatePickerPresetsType[]
	presetVariant: DatePickerPresetsType
	onPresetChange: (preset: DatePickerPresetsType) => void
	onValuesChange: (values: {
		field: 'start_date' | 'end_date'
		value: moment.Moment
	}) => void
}
const StartDatePicker = ({
	values,
	setFieldValue,
	cn,
	errors,
	firstAvaliableDate,
	isMobile,
	campaign,
	presets,
	presetVariant,
	onPresetChange,
	onValuesChange,
}: Props) => {
	const AlertInvalidDateFromCampaign = useMemo(() => {
		if (!campaign?.start_date) return false
		if (
			campaign?.start_date !==
			moment(values.start_date).format('YYYY-MM-DD')
		)
			return false
		const start = moment(campaign.start_date, 'YYYY-MM-DD')
		const daysToStart = start.diff(moment(), 'days')
		if (daysToStart < daysBeforeCampaignStart) {
			return true
		}
		return false
	}, [campaign.start_date, values.start_date]) // eslint-disable-line react-hooks/exhaustive-deps

	const firstStartDay = moment().add(daysBeforeCampaignStart, 'days')

	const handleValuesChange = (values: {
		field: 'start_date' | 'end_date'
		value: moment.Moment
	}) => {
		onValuesChange(values)
	}

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: isMobile ? 'column-reverse' : 'column',
					width: isMobile ? '100%' : 'max-content',
				}}
			>
				<div>
					<div
						style={{
							display: 'flex',
							gap: '8px',
							paddingBottom: '8px',
							alignItems: 'center',
						}}
					>
						<Form.Label className="d-flex m-0">
							Срок размещения
						</Form.Label>
						<FormLabelTooltip
							text={'Минимальный срок размещения — 1 неделя'}
						/>
					</div>
					<ButtonGroup
						className="mb-3"
						vertical={isMobile}
						style={{ width: isMobile ? '100%' : 'inherit' }}
					>
						{presets.map((el) => (
							<Button
								key={el.variant}
								variant={
									el.variant === presetVariant.variant
										? 'primary'
										: 'secondary'
								}
								onClick={() => {
									onPresetChange(el)
								}}
							>
								{el.variant !== 'custom'
									? `с ${el.startDate!.format(
											'D'
									  )} по ${el.endDate!.format(
											'D MMMM YYYY'
									  )}`
									: 'Указать свой'}
							</Button>
						))}
					</ButtonGroup>
				</div>

				{presetVariant.variant === 'custom' && (
					<div className={cn('dates')}>
						{isMobile ? (
							<ModalDateInput
								type={'start'}
								comparisonDay={moment().add(
									daysBeforeCampaignStart,
									'days'
								)}
								firstAvaliableDate={firstAvaliableDate}
								className="me-2"
								title={
									<div
										style={{
											display: 'flex',
											gap: '8px',
											paddingBottom: '8px',
											alignItems: 'center',
										}}
									>
										<span className=" m-0">Свой срок</span>
										<FormLabelTooltip
											text={
												'Даты указываются включительно'
											}
										/>
									</div>
								}
								value={moment(values.start_date).format(
									'DD.MM.YYYY'
								)}
								onChange={(date) =>
									handleValuesChange({
										field: 'start_date',
										value: moment(date, 'YYYY-MM-DD', true),
									})
								}
								error={errors.start_date}
							/>
						) : (
							<DateInput
								type={'start'}
								comparisonDay={moment().add(
									daysBeforeCampaignStart,
									'days'
								)}
								firstAvaliableDate={firstStartDay}
								className="me-3"
								title={
									<div
										style={{
											display: 'flex',
											gap: '8px',
											paddingBottom: '8px',
											alignItems: 'center',
										}}
									>
										<span className=" m-0">Свой срок</span>
										<FormLabelTooltip
											text={
												'Даты указываются включительно'
											}
										/>
									</div>
								}
								value={moment(values.start_date).format(
									'DD.MM.YYYY'
								)}
								onChange={(date) =>
									handleValuesChange({
										field: 'start_date',
										value: moment(date, 'YYYY-MM-DD', true),
									})
								}
								error={
									errors.start_date ||
									AlertInvalidDateFromCampaign
								}
							/>
						)}
						{isMobile ? (
							<ModalDateInput
								type={'end'}
								comparisonDay={getFirstAvailableEndDay(
									values.start_date
								)}
								disabled={
									!moment(
										values.start_date,
										'YYYY-MM-DD',
										true
									).isValid()
								}
								firstAvaliableDate={firstAvaliableDate}
								title={''}
								value={moment(values.end_date).format(
									'DD.MM.YYYY'
								)}
								onChange={(date) => {
									handleValuesChange({
										field: 'end_date',
										value: moment(date, 'YYYY-MM-DD', true),
									})
								}}
								error={errors.end_date}
							/>
						) : (
							<DateInput
								type={'end'}
								comparisonDay={getFirstAvailableEndDay(
									values.start_date
								)}
								firstAvaliableDate={firstStartDay.add(
									minDaysAdvCampaignLength,
									'days'
								)}
								disabled={
									!moment(
										values.start_date,
										'YYYY-MM-DD',
										true
									).isValid()
								}
								value={moment(values.end_date).format(
									'DD.MM.YYYY'
								)}
								onChange={(date) =>
									handleValuesChange({
										field: 'end_date',
										value: moment(date, 'YYYY-MM-DD', true),
									})
								}
								error={errors.end_date}
							/>
						)}
					</div>
				)}
			</div>

			{AlertInvalidDateFromCampaign && (
				<Alert variant={'danger'}>
					С выбора даты создания рекламной кампании прошло слишком
					много времени. Пожалуйста, выберите другие даты.
				</Alert>
			)}
		</>
	)
}

export default StartDatePicker
