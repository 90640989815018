import React from 'react'
import { Form } from 'react-bootstrap'

type Props = {
	setFieldValue: (
		field: 'provider_comment',
		value: string,
		shouldValidate?: boolean
	) => void
	value: string
}

export const ProviderComment = ({ setFieldValue, value }: Props) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFieldValue('provider_comment', e.target.value)
	}

	return (
		<Form.Group className="mb-3" controlId="textarea-comment">
			<Form.Label>Комментарий</Form.Label>
			<Form.Control
				as="textarea"
				rows={3}
				value={value}
				onChange={handleChange}
				style={{ borderRadius: '16px' }}
				placeholder="Оставить комментарий"
			/>
		</Form.Group>
	)
}
