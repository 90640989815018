import React, { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { MediaType, spreadArray } from '../../../Mediaformats/MediaInfo'
import { minMax } from '../../../../_client/create-campaign/Step3-Upload/DataUpload'

interface IModalMediaFormatQuickView {
	media_format: MediaType
	show: boolean
	onHide: () => void
}

const ModalMediaFormatQuickView: FC<IModalMediaFormatQuickView> = ({
	show,
	onHide,
	media_format: media,
}) => {
	return (
		<Modal
			style={{ background: 'rgba(0,0,0,0.5)' }}
			show={show}
			onHide={onHide}
			animation={false}
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} className={'ms-2'}>
					{media.name}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ padding: '32px 40px 32px 40px' }}>
				<div
					className={'w-100 d-flex justify-content-between'}
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '8px',
						marginTop: '8px',
					}}
				>
					<span className={'text-secondary'}>Тип:</span>
					<span style={{ textAlign: 'right' }}>
						{media.is_image && !media.is_video ? 'Изображение' : ''}
						{media.is_video && !media.is_image ? 'Видео' : ''}
						{media.is_video && media.is_image
							? 'Изображение, видео'
							: ''}
					</span>
				</div>
				{(media.video_formats || media.image_formats) && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Допустимый формат:
						</span>
						<span style={{ textAlign: 'right' }}>
							{spreadArray([
								...(media?.image_formats && media.is_image
									? media.image_formats
									: []),
								...(media?.video_formats && media.is_video
									? media.video_formats
									: []),
							])}
						</span>
					</div>
				)}
				{(media?.min_width || media?.max_width) && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Ширина:</span>
						<span style={{ textAlign: 'right' }}>
							{minMax(media.min_width, media.max_width, 'px')}
						</span>
					</div>
				)}
				{(media?.min_height || media?.max_height) && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Высота:</span>
						<span style={{ textAlign: 'right' }}>
							{minMax(media.min_height, media.max_height, 'px')}
						</span>
					</div>
				)}
				{media?.image_max_size && media.is_image && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Размер изображения:
						</span>
						<span style={{ textAlign: 'right' }}>
							не более {media.image_max_size} МБ
						</span>
					</div>
				)}
				{media?.video_max_size && media.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Размер видео:</span>
						<span style={{ textAlign: 'right' }}>
							не более {media.video_max_size} МБ
						</span>
					</div>
				)}
				{media?.aspect_ratio && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Соотношение сторон:
						</span>
						<span style={{ textAlign: 'right' }}>
							{media.aspect_ratio}
						</span>
					</div>
				)}
				{media?.duration && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Длительность показа:
						</span>
						<span style={{ textAlign: 'right' }}>
							{media.duration} секунд
						</span>
					</div>
				)}
				{media?.color_model && media.is_image && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Цветовая модель:
						</span>
						<span style={{ textAlign: 'right' }}>
							{media.color_model.toUpperCase()}
						</span>
					</div>
				)}
				{(media?.min_dpi || media?.max_dpi) && media.is_image && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Точек на дюйм:</span>
						<span style={{ textAlign: 'right' }}>
							{minMax(media.min_dpi, media.max_dpi, 'dpi')}
						</span>
					</div>
				)}
				{media?.codec && media.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Кодек:</span>
						<span style={{ textAlign: 'right' }}>
							{media.codec}
						</span>
					</div>
				)}
				{(media?.min_fps || media?.max_fps) && media.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Кадровая частота:
						</span>
						<span style={{ textAlign: 'right' }}>
							{minMax(media.min_fps, media.max_fps, 'fps')}
						</span>
					</div>
				)}
				{(media?.min_bitrate || media?.max_bitrate) && media.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>Битрейт:</span>
						<span style={{ textAlign: 'right' }}>
							{minMax(
								media.min_bitrate,
								media.max_bitrate,
								'Мбит/с'
							)}
						</span>
					</div>
				)}
				{media.is_video && (
					<div
						className={'w-100 d-flex justify-content-between'}
						style={{
							borderBottom: '1px solid #CED4DA',
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Звуковая дорожка:
						</span>
						<span style={{ textAlign: 'right' }}>
							{media.with_audio_track ? 'Да' : 'Нет'}
						</span>
					</div>
				)}

				<div
					className={'w-100 d-flex flex-column'}
					style={{
						paddingBottom: '8px',
						marginTop: '8px',
						// marginBottom: '24px',
					}}
				>
					<span className={'text-secondary'}>
						Дополнительная информация:
					</span>
					<span className={'mt-2'}>
						{/*есть только текст*/}
						{media?.additional_info &&
							!media?.external_link &&
							media?.additional_info}
						{/*есть только ссылка*/}
						{!media?.additional_info && media?.external_link && (
							<a href={media.external_link}>
								{media.external_link}
							</a>
						)}
						{/*есть ссылка и текст*/}
						{media?.additional_info && media?.external_link && (
							<>
								{media.additional_info} <br />
								<a href={media.external_link}>
									{media.external_link}
								</a>
							</>
						)}
						{/*нет ничего*/}
						{!media?.additional_info &&
							!media?.external_link &&
							'Нет'}
					</span>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default ModalMediaFormatQuickView
