import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { CampaignInfoTypeBookingsSeparation } from 'src/stores/ADMarketTypes.types'
import useCampaignData from 'src/stores/campaignData'
import { AdvFormats, InternetVariants } from '../../../../types'
import { AdvFormatNav } from '../../../common/adv-format-nav/AdvFormatNav'
import { useAdformatsStore } from 'src/components/_client/create-campaign/Step2-ADformats/adformats-store/adformats.store'
import {
	massCoverageNoItemsErrorId,
	outdoorNoItemsErrorId,
} from '../../../../constants'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { AdvFormatVariantsContainer } from '../../../common/adv-format-variants-container/AdvFormatVariantsContainer'
import { InternetPresentationCard } from './internet-presentation-card/InternetPresentationCard'
import { Vk } from './internet-variants/vk/Vk'
import { Yandex } from './internet-variants/yandex/Yandex'
import { authFetch } from 'src/utils/authFetch'
import { BASE_URL, internetYandex } from 'src/constants/api'
import { AdvFormatContent } from '../../../common/adv-format-content/AdvFormatContent'
import { AdvFormatContainer } from '../../../common/adv-format-container/AdvFormatContainer'

const initializeInternetVariants = (
	campaign: CampaignInfoTypeBookingsSeparation
): InternetVariants[] => {
	const variants = [] as InternetVariants[]

	if (campaign.internet_vk.is_selected) {
		variants.push(InternetVariants.Vk)
	}
	if (campaign.internet_yandex.is_selected) {
		variants.push(InternetVariants.Yandex)
	}

	return variants
}

const OutdoorVariantsForRender: Record<
	InternetVariants,
	(props: any) => JSX.Element
> = {
	vk: Vk,
	yandex: Yandex,
}

const toggleInternetSelectedState = async (data: {
	campaignId: string
	variant: InternetVariants
	isSelected: boolean
}) => {
	const formData = new FormData()

	formData.append('is_selected', String(data.isSelected))
	const internetUrl =
		data.variant === InternetVariants.Vk ? 'internet_vk' : 'internet_yandex'
	await authFetch<{ is_selected: boolean }>({
		method: 'PATCH',
		url: `${BASE_URL}/adv_companies/${data.campaignId}/${internetUrl}/`,
		body: formData,
	})
}
type Props = {
	goToSelectors: () => void
}
export const InternetAdv = ({ goToSelectors }: Props) => {
	const [campaign, campaignInterface] = useCampaignData()

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const setAdFormatErrors = useAdformatsStore(
		(state) => state.setAdFormatErrors
	)
	const { addToast } = useContext(ToastsContext)
	const removeAdFormatErrorByErrorId = useAdformatsStore(
		(state) => state.removeAdFormatErrorByErrorId
	)
	const [internetVariants, setInternetVariants] = useState<
		InternetVariants[]
	>(() => initializeInternetVariants(campaign))

	const handleVariantClick = useCallback(
		(variant: InternetVariants) => {
			if (internetVariants.includes(variant)) {
				//действия при удалении варианта наружки

				//убрать ошибки из максимального охвата
				if (variant === InternetVariants.Vk) {
					removeAdFormatErrorByErrorId(
						'vk',
						massCoverageNoItemsErrorId
					)
				}

				//убрать ошибки из гарантированных показов
				if (variant === InternetVariants.Yandex) {
					removeAdFormatErrorByErrorId(
						'yandex',
						outdoorNoItemsErrorId
					)
				}

				//убрать компонент из стэка форм
				setInternetVariants(
					internetVariants.filter((v) => v !== variant)
				)
			} else {
				setInternetVariants([variant, ...internetVariants])
			}
		},
		[internetVariants]
	)

	const handleAddFormat = async () => {
		const isVkSelected = internetVariants.includes(InternetVariants.Vk)
		const isYandexSelected = internetVariants.includes(
			InternetVariants.Yandex
		)

		try {
			setIsLoading(true)
			//confirm vk selected
			if (campaign.internet_vk.is_selected !== isVkSelected) {
				await toggleInternetSelectedState({
					campaignId: campaign.id,
					variant: InternetVariants.Vk,
					isSelected: internetVariants.includes(InternetVariants.Vk),
				})
			}

			//confirm yandex selected
			if (campaign.internet_yandex.is_selected !== isYandexSelected) {
				await toggleInternetSelectedState({
					campaignId: campaign.id,
					variant: InternetVariants.Yandex,
					isSelected: internetVariants.includes(
						InternetVariants.Yandex
					),
				})
			}

			await campaignInterface.refetchSelected()
			addToast({
				text: 'Интернет-реклама изменена',
				type: 'success',
			})
			goToSelectors()
		} catch (e) {
			addToast({
				text: 'Ошибка изменения интернет-рекламы',
				type: 'danger',
			})
		} finally {
			setIsLoading(false)
		}
	}
	const RenderedOutdoorVariants = useMemo(
		() =>
			internetVariants.map((variant) => {
				return React.createElement(OutdoorVariantsForRender[variant], {
					key: variant,
				})
			}),
		[internetVariants]
	)

	//remove all adv format errors
	useEffect(() => {
		return () => {
			setAdFormatErrors('vk', null)
			setAdFormatErrors('yandex', null)
		}
	}, [])
	return (
		<AdvFormatContainer>
			<AdvFormatContent>
				<InternetPresentationCard
					onSelectorClick={handleVariantClick}
					selectedVariants={internetVariants}
				/>
				<AdvFormatVariantsContainer>
					{RenderedOutdoorVariants}
				</AdvFormatVariantsContainer>
			</AdvFormatContent>

			<AdvFormatNav
				advFormat={AdvFormats.Internet}
				advAddDisabled={isLoading}
				onBack={goToSelectors}
				onAddFormat={handleAddFormat}
				isFormatChanged={true}
				totalAdvVariants={internetVariants.length}
			/>
		</AdvFormatContainer>
	)
}
