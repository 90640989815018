import React from 'react'
import { TvVariantSelector } from './tv-variant-selector/TvVariantSelector'
import { TVVariants } from '../../../../../../types'
import s from './tvVariantsSelectors.module.scss'

type Props = {
	isLoading: boolean
	onSelectorClick: (variant: TVVariants) => void
	selectedVariants: TVVariants[]
}
export const TvVariantsSelectors = ({
	isLoading,
	onSelectorClick,
	selectedVariants,
}: Props) => {
	return (
		<div className={s.selectors}>
			<TvVariantSelector
				disabled={false}
				onClick={onSelectorClick}
				isSelected={selectedVariants.includes(TVVariants.Adstream)}
				title="IPTV и SmartTV"
				description={'Реклама в сетях операторов IPTV и SmartTV'}
				selectorKey={TVVariants.Adstream}
				isLoading={isLoading}
				extraInfo="Лидер на рынке генерации, автоматизации, и учета трафика видео - рекламы"
			/>

			<TvVariantSelector
				disabled={true}
				onClick={onSelectorClick}
				description={'Интерактивная реклама на ТВ'}
				isSelected={selectedVariants.includes(TVVariants.Teletarget)}
				title="TeleTarget"
				selectorKey={TVVariants.Teletarget}
				isLoading={isLoading}
				extraInfo="Временно недоступно"
			/>
		</div>
	)
}
