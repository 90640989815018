import React, { useEffect, useState } from 'react'
import { DisplayIssuesCampaign } from 'src/components/_moderator/types/ModerationRequestTypes'
import s from './style.module.scss'
import cn from 'classnames'
import { CampaignIssueRow } from './CampaignIssueRow'
import { CampaignIssueWrapper } from '../common/components/CampaignIssueWrapper'
import { NotificationModel } from '../common/components/IssuesContent'

type IssuesTableProps = {
	channels: Array<{ id: number; name: string }>
	isLoading: boolean
	displayIssues: DisplayIssuesCampaign[]
	setNotifyItem: React.Dispatch<
		React.SetStateAction<NotificationModel | undefined>
	>
}

export const IssuesTable = ({
	displayIssues,
	isLoading,
	channels,
	setNotifyItem,
}: IssuesTableProps) => {
	return (
		<table style={{ borderTop: 'none', width: '100%' }}>
			<tbody>
				<tr className={cn(s.tRow)}>
					<th style={{ maxWidth: '200px', width: '200px' }}>
						Кампания
					</th>
					<th style={{ maxWidth: '200px', width: '200px' }}>
						Тип рекламы
					</th>
					<th colSpan={1} scope="colgroup">
						Место размещения
					</th>
					<th
						style={{ maxWidth: '200px', width: '240px' }}
						colSpan={1}
						scope="colgroup"
					>
						Проблема
					</th>
					<th
						style={{ width: '40px', borderRight: 'none' }}
						colSpan={1}
						scope="colgroup"
					></th>
				</tr>
				{!isLoading &&
					displayIssues.map((el) => (
						<CampaignIssueWrapper
							key={el.id}
							WrappedComponent={CampaignIssueRow}
							channels={channels}
							issue={el}
							setNotifyItem={setNotifyItem}
						/>
					))}
			</tbody>
		</table>
	)
}
