import React from 'react'
import bem from '../../utils/bem'
import { Col, Container, Row } from 'react-bootstrap'
import logo from '../../assets/images/logo.svg'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import '../../styles/auth-page.scss'
import RegisterForm from './RegisterForm'
import LoginForm from './LoginForm'
import RecoveryForm from './RecoveryForm'
import useUserData from '../../stores/userData'
import ROUTES from '../../constants/Routes'

const cn = bem('auth-page')

export default function AuthPage() {
	const [user] = useUserData()
	if (user.phone_number && user.is_phone_confirmed) {
		if (user?.is_moder) {
			return (
				<Redirect
					to={
						ROUTES.ROUTE_MODERATOR +
						ROUTES.ROUTE_CAMPAIGN_MODERATION
					}
				/>
			)
		}

		if (user.provider !== null) {
			return <Redirect to={ROUTES.ROUTE_PROVIDER} />
		}
		return <Redirect to={ROUTES.ROUTE_CAMPAIGNS} />
	}

	return (
		<div className={cn('')}>
			<Container className="flex-column h-100">
				<Row>
					<Col className={cn('logo')}>
						<a href="https://admarket.ru/">
							<img src={logo} alt={'logo'} />
						</a>
					</Col>
				</Row>
				<Row className="d-flex justify-content-center h-100">
					<Col className={cn('body')}>
						<HashRouter>
							<Switch>
								<Route path={'/register'}>
									<RegisterForm cn={cn} />
								</Route>
								<Route path={'/login'}>
									<LoginForm cn={cn} />
								</Route>
								<Route path={'/recovery'}>
									<RecoveryForm cn={cn} />
								</Route>
								<Route path={'/'}>
									<Redirect to={'/login'} />
								</Route>
							</Switch>
						</HashRouter>
					</Col>
				</Row>
			</Container>
		</div>
	)
}
