import React from 'react'
import { ReactComponent as Gears } from 'src/assets/images/gears.svg'
import s from './appLinks.module.scss'
import cn from 'classnames'

export const DemoLink = (props: any) => {
	return (
		<a
			rel="noreferrer"
			href="https://demo.admarket.ru/"
			target="_blank"
			className={cn(s.link)}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-start',
				}}
			>
				<Gears width={20} height={20} />
				<div style={{ marginLeft: '16px' }}>Смотреть демо</div>
			</div>
		</a>
	)
}
