import React from 'react'
import {
	defaultMessageData,
	MessageProperties,
	MessagesData,
} from 'src/components/_client/campaign-info/constants/messages-data'
import { StateWithMessage } from 'src/components/common/garantid-card/garantid-media-format-card/components/garantid-item/StateWithMessage'
import {
	AdvItemState,
	DistrictBookingItem,
} from 'src/stores/technical-requirements-store'
import s from '../../../garantid-card/garantid-media-format-card/components/garantid-item/garantidItem.module.scss'

type Props = {
	districtBooking: DistrictBookingItem
}
export const DistrictBookingItemStatus = ({ districtBooking }: Props) => {
	const { state, adjustment_msg, reject_msg } = districtBooking

	let stateData: MessageProperties =
		MessagesData[districtBooking.state] || defaultMessageData

	if (state === AdvItemState.Adjustment) {
		stateData.message = adjustment_msg
	}
	if (state === AdvItemState.Reject) {
		stateData.message = reject_msg
	}
	return (
		<div className={s.stateWithCreative}>
			<StateWithMessage
				stateData={stateData}
				styles={{ width: '100%' }}
			/>
		</div>
	)
}
