import React from 'react'
import { CardWrapper } from '../../../../../../common/card-wrapper/CardWrapper'
import { AdvHeader } from '../../../../../../common/adv-header/AdvHeader'
import s from './adstream.module.scss'
import { Form, FormGroup, InputGroup } from 'react-bootstrap'
import { NumericFormat } from 'react-number-format'
import InputWithEndText from 'src/components/UtilityComponents/InputWithEndText'
import { ReactComponent as AdstreamIcon } from 'src/assets/images/tv.svg'
import AdMarketMultiplyDropdown from 'src/components/UtilityComponents/ADMarketMultiplyDropdown/ADMarketMultiplyDropdown'
import { AdstreamRec } from 'src/stores/types/recommendations'
import useResponsive from 'src/utils/useResponsive'

type Props = {
	adstream: AdstreamRec
}
export const AdstreamList = ({ adstream }: Props) => {
	const channels = adstream.sections.map((section) => section.name)

	const { isMobile } = useResponsive()
	return (
		<CardWrapper>
			<div className={s.content}>
				<AdvHeader
					Icon={AdstreamIcon}
					title="AdStream"
					description="Реклама в сетях операторов IPTV и SmartTV. Лидер на рынке генерации, автоматизации и учета трафика видеорекламы"
				/>

				<div className={s.inputs}>
					<FormGroup>
						<Form.Label>Каналы для показа</Form.Label>
						<AdMarketMultiplyDropdown
							disabled={true}
							list={channels}
							selected={channels}
							error={''}
							onSelectedChange={() => {}}
							withSeparator
						/>
					</FormGroup>

					<div className={s.viewInputs}>
						<Form.Group className={s.controlBudget}>
							<Form.Label>Бюджет кампании</Form.Label>
							<div style={{ display: 'flex', gap: '0.5rem' }}>
								<NumericFormat
									id={`adstream_budget`}
									decimalScale={2}
									decimalSeparator=","
									value={adstream.budget}
									suffix=" ₽"
									thousandSeparator={' '}
									customInput={InputWithEndText}
									controlStyle={{
										minHeight: '40px',
										height: isMobile ? '40px' : '45px',
										fontSize: isMobile ? '16px' : '1.25rem',
										borderRadius: isMobile
											? '12px'
											: '16px',
									}}
									disabled={true}
									onChange={() => {}}
									onKeyDown={() => {}}
									containerStyle={{
										overflow: 'hidden',
										width: '100%',
									}}
								/>
							</div>
						</Form.Group>
					</div>
				</div>
			</div>
		</CardWrapper>
	)
}
