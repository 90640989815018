import React from 'react'
import { Login } from '../common/ui/login/Login'
import { useParams } from 'react-router-dom'
import { loginValidationSchema } from '../common/model/schemas'
import { FormikHelpers } from 'formik'
import { LoginFormData } from '../common/model/types'
import useUserData from 'src/stores/userData'
import yandexTracking from 'src/utils/yandexTracking'

export const ClientLogin = () => {
	const [_, userInterface] = useUserData()

	const headerTitle = 'Вход'

	const handleResetPassword = () => {
		window.location.href = `/auth/#/recovery`
	}

	const handleRegistration = () => {
		window.location.href = `/auth/#/register`
	}

	const handleSubmit = async (
		values: LoginFormData,
		formikHelpers: FormikHelpers<LoginFormData>
	) => {
		const formattedPhone = `+${values.phone_number.replace(/\D/g, '')}`

		try {
			await userInterface.login(
				{
					phone_number: formattedPhone,
					password: values.password,
				},
				values.rememberMe
			)
			yandexTracking.setUser(values)
		} catch (e: any) {
			if (typeof e?.data === 'object') {
				Object.keys(e.data).forEach((key) => {
					formikHelpers.setFieldError(key, e.data[key][0])
				})
			}
		} finally {
			formikHelpers.setSubmitting(false)
		}
	}

	return (
		<Login
			headerTitle={headerTitle}
			onSubmit={handleSubmit}
			validationSchema={loginValidationSchema}
			toResetPassword={handleResetPassword}
			toRegister={handleRegistration}
		/>
	)
}
