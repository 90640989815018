import React, { useState } from 'react'
import { InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import { Form, Button } from 'react-bootstrap'
import { Formik, Field, Form as FormikForm, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import s from './registerStages.module.scss'
import { RegistrationCredentialsFormData } from '../../../model/types'
import { RegistrationInfo } from '../registration-info/RegistrationInfo'
import { SubmitButton } from '../../common/SubmitButton'

type Props = {
	validationSchema: Yup.ObjectSchema<
		RegistrationCredentialsFormData,
		Yup.AnyObject,
		{
			phone_number: undefined
			password: undefined
		},
		''
	>
	onSubmit: (
		values: RegistrationCredentialsFormData,
		formikHelpers: FormikHelpers<RegistrationCredentialsFormData>
	) => Promise<void>
}

export const CredentialsInput = ({ validationSchema, onSubmit }: Props) => {
	const [showPassword, setShowPassword] = useState(false)
	const passwordHelperText =
		'Пароль должен содержать не менее восьми знаков, включать буквы, цифры и специальные символы'

	return (
		<div className={s.credentialsContainer}>
			<Formik<RegistrationCredentialsFormData>
				initialValues={{
					phone_number: '',
					password: '',
				}}
				validationSchema={validationSchema}
				validateOnBlur={false}
				validateOnChange={false}
				onSubmit={onSubmit}
			>
				{({ errors, touched, values, setFieldValue, isSubmitting }) => (
					<FormikForm>
						<div className={s.formContent}>
							<div className={s.inputs}>
								<Form.Group>
									<Form.Label>Телефон</Form.Label>
									<InputGroup hasValidation className="m-0">
										<InputMask
											autoComplete="off"
											mask={'+7 (999) 999-99-99'}
											value={values.phone_number}
											onChange={(e) => {
												setFieldValue(
													'phone_number',
													e.target.value
												)
											}}
											disabled={isSubmitting}
										>
											{(inputProps) => (
												<Form.Control
													name="customPhoneField"
													className={s.autofillNone}
													size="lg"
													type={'text'}
													placeholder={
														'+7 (999) 999-99-99'
													}
													style={{
														borderRadius: '16px',
														boxShadow: 'none',
														borderColor: '#CED4DA',
														backgroundColor:
															'white',
													}}
													isInvalid={
														touched.phone_number &&
														!!errors.phone_number
													}
													{...inputProps}
												/>
											)}
										</InputMask>

										<Form.Control.Feedback type="invalid">
											{errors.phone_number}
										</Form.Control.Feedback>
									</InputGroup>
								</Form.Group>

								<div className={s.passwordContainer}>
									<Form.Group controlId="password">
										<Form.Label>Пароль</Form.Label>
										<InputGroup
											className="d-flex flex-nowrap"
											style={{
												height: '48px',
												margin: 0,
											}}
										>
											<Form.Control
												disabled={isSubmitting}
												type={
													showPassword
														? 'text'
														: 'password'
												}
												placeholder="••••"
												aria-label="Пароль"
												aria-describedby="show-pass"
												isInvalid={!!errors.password}
												style={{
													borderRight: 'none',
													borderColor: '#CED4DA',
													borderTopLeftRadius: '16px',
													borderBottomLeftRadius:
														'16px',
													boxShadow: 'none',
													backgroundColor: 'white',
												}}
												className={s.autofillNone}
												onChange={(e) => {
													setFieldValue(
														'password',
														e.target.value
													)
												}}
											/>
											<OverlayTrigger
												placement={'top'}
												overlay={
													<Tooltip id={`tooltip-top`}>
														{showPassword
															? 'Скрыть пароль'
															: 'Показать пароль'}
													</Tooltip>
												}
											>
												<Button
													onClick={() =>
														setShowPassword(
															(state) => !state
														)
													}
													disabled={isSubmitting}
													variant="text"
													id="show-pass"
													style={{
														borderLeft: 'none',
														borderColor: '#CED4DA',
														borderTopRightRadius:
															'16px',
														borderBottomRightRadius:
															'16px',
														backgroundColor:
															'white',
														boxShadow: 'none',
													}}
												>
													{showPassword ? (
														<i
															className="bi bi-eye-slash"
															style={{
																fontSize:
																	'24px',
															}}
														/>
													) : (
														<i
															className="bi bi-eye"
															style={{
																fontSize:
																	'24px',
															}}
														/>
													)}
												</Button>
											</OverlayTrigger>
										</InputGroup>

										{errors.password ? (
											<Form.Control.Feedback
												type="invalid"
												style={{
													display: errors.password
														? 'block'
														: 'none',
												}}
											>
												{errors.password}
											</Form.Control.Feedback>
										) : (
											<span
												className={s.passwordHelperText}
											>
												{passwordHelperText}
											</span>
										)}
									</Form.Group>
								</div>
							</div>

							<SubmitButton
								disabled={isSubmitting}
								text="Продолжить"
							/>
						</div>
					</FormikForm>
				)}
			</Formik>

			<RegistrationInfo />
		</div>
	)
}
