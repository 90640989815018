import React, { FC, useState } from 'react'
import {
	BookingType,
	MediaForBookingType,
} from '../../../../stores/ADMarketTypes.types'
import { ObjectValue } from '../../../UtilityComponents/ProgressFileInput'
import ImageFullScreenWrapper from '../../../UtilityComponents/ImageFullScreenWrapper'
import {
	MediaType,
	spreadArray,
} from '../../../_provider/Mediaformats/MediaInfo'
import { Dropdown } from 'react-bootstrap'
import DetailItem from '../../../../utils/DetailItem'
import { minMax } from '../../create-campaign/Step3-Upload/DataUpload'
import FileInput from '../../../UtilityComponents/FileInput'
import { DocumentsUploadForm } from '../internet/DocumentsUploadForm'
import useCampaignData from 'src/stores/campaignData'
import { EditVinstantInputButton } from '../common/edit-vinstant-input-button/EditVinstantInputButton'
import { EditVinstantProcessingWrapped } from '../common/edit-vinstant-processing/EditVinstantProcessing'
import { useCampaignInfoStore } from '../store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from '../store/campaignInfo-store-selectors/campaignInfo.store.selectors'

interface IBookingEdit {
	booking: BookingType
	media: MediaForBookingType
	fileError?: string | null
	setFileError: (e: any) => void
	onNewImage: (image: any) => void
	showAll?: boolean
	onDocumentLoaded?: React.Dispatch<React.SetStateAction<boolean>>
	onEditVinstantClick: () => void
	editVinstantStatus: string | undefined
	vinstantLinkState?: boolean
}

const BookingEdit: FC<IBookingEdit> = ({
	booking,
	media,
	fileError,
	setFileError,
	onNewImage,
	showAll,
	onDocumentLoaded,
	editVinstantStatus,
	onEditVinstantClick,
	vinstantLinkState = true,
}) => {
	const [campaign, campaignInterface] = useCampaignData()

	const bookingVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'booking_id',
			itemId: booking.id.toString(),
		})
	)

	const init = { src: media?.file, name: media?.file_name }
	const [value, setValue] = useState<ObjectValue | null>(init)
	const handleUpload = (file) => {
		try {
			setValue(file)
			onNewImage(file)
		} catch (e) {
			setValue(null)
			console.log(e)
		}
	}

	return (
		<div>
			<div className={'d-flex mb-4 align-items-center'}>
				<div className={'me-3'}>
					<ImageFullScreenWrapper>
						<img
							src={
								booking.placement.image ||
								'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
							}
							alt="media"
							style={{
								width: '80px',
								height: '80px',
								borderRadius: '8px',
								objectFit: 'fill',
							}}
						/>
					</ImageFullScreenWrapper>
				</div>
				<div>
					<h5 className={'mb-2'}>
						{booking.placement.placement_type.name}
					</h5>
					<div className={'text-secondary'}>
						{booking.placement.name}
					</div>
				</div>
			</div>
			<h5>Технические требования</h5>
			<Dropdown.Divider className={'m-0 p-0'} />
			<RenderRequirements
				mediatype={booking.placement.media_type}
				showAll={showAll || false}
			/>

			{editVinstantStatus === 'start' ? (
				<EditVinstantProcessingWrapped />
			) : (
				<FileInput
					ExtraButton={
						vinstantLinkState && bookingVinstantLink ? (
							<EditVinstantInputButton
								onClick={onEditVinstantClick}
							/>
						) : null
					}
					onUpload={handleUpload}
					value={value}
					onRemove={() => {
						setValue(null)
						onNewImage(null)
						setFileError(null)
					}}
					error={fileError}
					size={booking.placement.media_type.image_max_size || 10}
					allowedExtensions={[
						...(booking.placement.media_type?.image_formats &&
						booking.placement.media_type.is_image
							? booking.placement.media_type.image_formats
							: []),
						...(booking.placement.media_type?.video_formats &&
						booking.placement.media_type.is_video
							? booking.placement.media_type.video_formats
							: []),
					]}
					descr={`Допустимые форматы — ${spreadArray([
						...(booking.placement.media_type?.image_formats
							? booking.placement.media_type.image_formats
							: []),
						...(booking.placement.media_type?.video_formats
							? booking.placement.media_type.video_formats
							: []),
					])}. Ширина: ${minMax(
						booking.placement.media_type.min_width,
						booking.placement.media_type.max_width,
						'px'
					)}, высота: ${minMax(
						booking.placement.media_type.min_height,
						booking.placement.media_type.max_height,
						'px'
					)} , не более 20 МБ. Длительность — ${
						booking.placement_showing_count.video_duration
					} секунд.`}
				/>
			)}

			{booking?.is_docs_required && (
				<DocumentsUploadForm
					onDocumentLoaded={onDocumentLoaded}
					adFormatId={booking.id}
					campaignId={campaign.id}
					title="Загрузить документы"
					type="booking"
				/>
			)}
		</div>
	)
}

export const RenderRequirements: FC<{
	mediatype: MediaType
	showAll: boolean
}> = ({ mediatype: media, showAll }) => {
	const [showMore, setShowMore] = useState(false)

	const defaultProps = {
		bottomDivider: true,
		leftSecondary: true,
		fontSize: '16px',
	}
	return (
		<div>
			<DetailItem
				left={'Тип:'}
				right={
					<>
						{media.is_image && !media.is_video ? 'Изображение' : ''}
						{media.is_video && !media.is_image ? 'Видео' : ''}
						{media.is_video && media.is_image
							? 'Изображение, видео'
							: ''}
					</>
				}
				{...defaultProps}
			/>{' '}
			<DetailItem
				left={'Допустимый формат:'}
				right={
					<>
						{spreadArray([
							...(media?.image_formats && media.is_image
								? media.image_formats
								: []),
							...(media?.video_formats && media.is_video
								? media.video_formats
								: []),
						])}
					</>
				}
				{...defaultProps}
			/>{' '}
			<DetailItem
				left={'Ширина:'}
				right={<>{minMax(media?.min_width, media?.max_width, 'px')}</>}
				show={Boolean(media?.min_width || media?.max_width)}
				{...defaultProps}
			/>{' '}
			<DetailItem
				left={'Высота:'}
				right={
					<>{minMax(media?.min_height, media?.max_height, 'px')}</>
				}
				show={Boolean(media?.min_height || media?.max_height)}
				{...defaultProps}
			/>
			<DetailItem
				left={'Размер изображения:'}
				right={<> не более {media?.image_max_size} МБ</>}
				show={Boolean(media?.image_max_size)}
				{...defaultProps}
			/>
			{(showMore || showAll) && (
				<>
					<DetailItem
						left={'Размер видео:'}
						right={<> не более {media?.video_max_size} МБ</>}
						show={Boolean(media?.video_max_size)}
						{...defaultProps}
					/>
					<DetailItem
						left={'Соотношение сторон:'}
						right={<>{media?.aspect_ratio}</>}
						show={Boolean(media?.aspect_ratio)}
						{...defaultProps}
					/>
					<DetailItem
						left={'Цветовая модель:'}
						right={<>{media?.color_model?.toUpperCase()}</>}
						show={Boolean(media?.color_model && media?.is_image)}
						{...defaultProps}
					/>
					<DetailItem
						left={'Точек на дюйм:'}
						right={
							<>{minMax(media?.min_dpi, media?.max_dpi, 'dpi')}</>
						}
						show={Boolean(
							(media?.min_dpi || media?.max_dpi) &&
								media?.is_image
						)}
						{...defaultProps}
					/>
					<DetailItem
						left={'Кодек:'}
						right={<> {media?.codec}</>}
						show={Boolean(media?.codec && media?.is_video)}
						{...defaultProps}
					/>
					<DetailItem
						left={'Кадровая частота:'}
						right={
							<>{minMax(media?.min_fps, media?.max_fps, 'fps')}</>
						}
						show={Boolean(
							(media?.min_fps || media?.max_fps) &&
								media?.is_video
						)}
						{...defaultProps}
					/>
					<DetailItem
						left={'Битрейт:'}
						right={
							<>
								{minMax(
									media?.min_bitrate,
									media?.max_bitrate,
									'Мбит/с'
								)}
							</>
						}
						show={Boolean(
							(media?.min_bitrate || media?.max_bitrate) &&
								media?.is_video
						)}
						{...defaultProps}
					/>
					<DetailItem
						left={'Длительность:'}
						right={<>{media?.duration} секунд</>}
						show={Boolean(media?.duration && media?.is_video)}
						{...defaultProps}
					/>
					<DetailItem
						left={'Звуковая дорожка:'}
						right={<> {media?.with_audio_track ? 'Да' : 'Нет'}</>}
						show={Boolean(media?.is_video)}
						{...defaultProps}
					/>
					<div
						className={'w-100 d-flex flex-column'}
						style={{
							paddingBottom: '8px',
							marginTop: '8px',
						}}
					>
						<span className={'text-secondary'}>
							Дополнительная информация:
						</span>
						<span className={'mt-2'}>
							{/*есть только текст*/}
							{media?.additional_info &&
								!media?.external_link &&
								media?.additional_info}
							{/*есть только ссылка*/}
							{!media?.additional_info &&
								media?.external_link && (
									<a href={media?.external_link}>
										{media?.external_link}
									</a>
								)}
							{/*есть ссылка и текст*/}
							{media?.additional_info && media?.external_link && (
								<>
									{media?.additional_info} <br />
									<a href={media?.external_link}>
										{media?.external_link}
									</a>
								</>
							)}
							{/*нет ничего*/}
							{!media?.additional_info &&
								!media?.external_link &&
								'Нет'}
						</span>
					</div>
				</>
			)}
			{!showAll && (
				<div
					className={
						'w-100 d-flex justify-content-center align-items-center'
					}
					style={{
						marginTop: '12px',
						cursor: 'pointer',
						marginBottom: '28px',
					}}
					onClick={(e) => {
						e.preventDefault()
						setShowMore(!showMore)
					}}
				>
					<svg
						width="12"
						height="7"
						viewBox="0 0 12 7"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						style={{
							marginRight: '8px',
							transform: `rotate(${!showMore ? '0' : '180'}deg)`,
						}}
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M5.52848 6.10974L0.861816 1.44307L1.80463 0.500259L5.99989 4.69552L10.1952 0.500259L11.138 1.44307L6.47129 6.10974C6.21094 6.37008 5.78883 6.37008 5.52848 6.10974Z"
							fill="#007BFF"
						/>
					</svg>

					<a
						className={'text-primary text-decoration-none'}
						style={{
							color: '#007BFF',
							fontWeight: 500,
						}}
						href={'/'}
					>
						{showMore ? 'Скрыть' : 'Показать полностью'}
					</a>
				</div>
			)}
		</div>
	)
}

export default BookingEdit
