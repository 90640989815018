import React from 'react'
import { Search, SearchWithVariants } from 'src/components/UtilityComponents'
import { Button } from 'react-bootstrap'
import useResponsive from 'src/utils/useResponsive'
import useProviderData from 'src/stores/providerData'

type CreateAndSearchButtonProps = {
	isCreateButton?: boolean
	isSearch?: boolean
	onCreateItem?: () => void
	onSearchStringChange?: (searchString: string) => void
	createItemButtonTitle?: string | JSX.Element
	searchVariants?: string[]
	searchString?: string
}
export const CreateAndSearchButton = ({
	isCreateButton = true,
	isSearch = true,
	onCreateItem,
	onSearchStringChange,
	createItemButtonTitle,
	searchVariants,
	searchString,
}: CreateAndSearchButtonProps) => {
	const handleCreateItemAction = () => {
		onCreateItem && onCreateItem()
	}
	const handleSearchStringChange = (value: string) => {
		onSearchStringChange && onSearchStringChange(value)
	}

	const { isDesktop } = useResponsive()
	return (
		<>
			{isCreateButton && (
				<Button
					onClick={handleCreateItemAction}
					id={'create-item-button'}
				>
					{createItemButtonTitle || ''}
				</Button>
			)}
			{isSearch && (
				<SearchWithVariants
					searchString={searchString}
					minWidth={isDesktop ? '400px' : '50%'}
					setSearchString={handleSearchStringChange}
					variants={searchVariants}
				/>
			)}
		</>
	)
}
