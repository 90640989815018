import React from 'react'
import { useHistory } from 'react-router-dom'
import { offerta, personalDataAgreement } from 'src/constants/links'
import s from './authstyles/signUpForm.module.scss'

export const SignUpInfo = () => {
	const history = useHistory()

	return (
		<div className={s.signUpInfo}>
			<div>
				Нажимая на кнопку «Продолжить», вы принимаете{' '}
				<a target="blank" href={offerta}>
					условия договора оферты
				</a>{' '}
				и даете согласие на{' '}
				<a target="blank" href={personalDataAgreement}>
					обработку персональных данных
				</a>
			</div>
			<div>
				{`Уже есть аккаунт? `}
				<span onClick={() => history.push('/login')}>Войти</span>
			</div>
		</div>
	)
}
