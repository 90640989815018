import React, { useCallback, useRef, useState } from 'react'
import bem from '../../utils/bem'
import { Alert, Button } from 'react-bootstrap'
import '../../styles/toast-notification.scss'
import { AnimatePresence, motion } from 'framer-motion'

export const ToastsContext = React.createContext<any>(null)
ToastsContext.displayName = 'ToastsContext'

const cn = bem('toast-notification')
interface IaddToast {
	text: string
	type:
		| 'primary'
		| 'secondary'
		| 'success'
		| 'danger'
		| 'warning'
		| 'info'
		| 'light'
		| 'dark'
	withAction?: boolean
	actionTitle?: string
	actionCallBack?: any
}
export default function ToastsContextProvider({ children }) {
	const [toastsState, setToastsState] = useState({
		text: '',
		type: '',
		withAction: false,
		actionTitle: '',
		actionCallBack: () => null,
	})
	const timeoutRef = useRef<any>(null)

	const addToast = useCallback(
		({
			text,
			type = 'success',
			withAction = false,
			actionTitle = '',
			actionCallBack = () => null,
		}: IaddToast) => {
			setToastsState({
				text,
				type,
				withAction,
				actionTitle,
				actionCallBack,
			})
			timeoutRef.current = setTimeout(() => {
				setToastsState((p) => ({
					...p,
					text: '',
				}))
			}, 3000)
		},
		[]
	)

	const dropAllToasts = useCallback(() => {
		setToastsState((p) => ({
			...p,
			text: '',
		}))
	}, [])

	return (
		<ToastsContext.Provider
			value={{
				...toastsState,
				setToastsState,
				addToast,
				dropAllToasts,
			}}
		>
			{children}
			<AnimatePresence>
				{!!toastsState.text && (
					<motion.div
						className={cn(null)}
						animate={{ y: 0 }}
						initial={{ y: -20 }}
						exit={{ y: -100 }}
					>
						<Alert variant={toastsState.type}>
							<div className={cn('toast')}>
								{toastsState.text}
								{toastsState.withAction && (
									<Button
										variant={toastsState.type}
										onClick={toastsState.actionCallBack}
										size={'sm'}
									>
										{toastsState.actionTitle}
									</Button>
								)}
								<div className={cn('close')}>
									<i
										className="bi bi-x"
										style={{ fontSize: '22px' }}
										onClick={() => {
											if (timeoutRef.current) {
												clearTimeout(timeoutRef.current)
												timeoutRef.current = null
											}
											setToastsState((p) => ({
												...p,
												text: '',
											}))
										}}
									/>
								</div>
							</div>
						</Alert>
					</motion.div>
				)}
			</AnimatePresence>
		</ToastsContext.Provider>
	)
}
