import React from 'react'
import { MassCoverageItem } from './MassCoverageItem'
import { CardWrapper } from '../../../../../../common/card-wrapper/CardWrapper'
import { ReactComponent as Radius } from 'src/assets/images/radius.svg'
import { AdvHeader } from '../../../../../../common/adv-header/AdvHeader'
import s from './massCoverageList.module.scss'
import { AdvRecSummary } from '../../../../../../common/adv-rec-summary/AdvRecSummary'
import { DistrictBookingRec } from 'src/stores/types/recommendations'
import IntlFormatter from 'src/utils/intl-money-formatter'

type Props = {
	items: DistrictBookingRec[]
	budget: string
}
export const MassCoverageList = ({ items, budget }: Props) => {
	const totalPlacements = items.reduce(
		(acc, item) => acc + item.placements.length,
		0
	)

	return (
		<CardWrapper>
			<div className={s.content}>
				<AdvHeader
					Icon={Radius}
					title="Наружная реклама. Максимальный охват"
					description="Динамическое распределение показов в рамках бюджета
						рекламной кампании по всем свободным рекламным местам в
						выбранных городах"
				/>

				<div className={s.itemsList}>
					{items.map((districtBooking) => (
						<MassCoverageItem
							key={districtBooking.city_id}
							districtBooking={districtBooking}
						/>
					))}
				</div>

				<AdvRecSummary
					summaryData={[
						{
							title: 'Всего рекламных мест:',
							value: `${totalPlacements}`,
						},
						{
							title: 'Общий бюджет:',
							value: IntlFormatter.format(budget),
						},
					]}
				/>
			</div>
		</CardWrapper>
	)
}
