import React, { useRef, useEffect, useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'

const Portal = ({
	children,
	position = 'bottom',
	marginLeft = 0,
	zIndex = void 7,
	isOpened,
	onLayerMouseDown,
	onClose,
}: Record<any, any>) => {
	const childContainerRef = useRef(null)
	const positionDivRef = useRef(null)

	useEffect(() => {
		// document.body.style.overflow = isOpened ? 'hidden' : 'auto'

		if (isOpened && positionDivRef.current) setPosition()
	}, [isOpened, position, positionDivRef.current, childContainerRef.current])

	// useEffect(() => {
	// 	if (!isOpened) return

	// 	const scrollParents = getAllScrollableParents(positionDivRef.current!)
	// 	scrollParents.map((el) => {
	// 		console.log(el)
	// 		el.addEventListener('scroll', setPosition)
	// 	})

	// 	return () => {
	// 		scrollParents.forEach((el) => {
	// 			el.removeEventListener('scroll', setPosition)
	// 		})
	// 	}
	// }, [isOpened])

	function setPosition() {
		const positionRect = (
			positionDivRef.current as any
		).getBoundingClientRect()
		const childContainerRect = (
			childContainerRef as any
		).current.getBoundingClientRect()

		let left =
			positionRect.x - (childContainerRect.width - positionRect.width)
		if (position === 'center') {
			left = left + (childContainerRect.width - positionRect.width) / 2
		}
		left += marginLeft

		// @ts-ignore
		childContainerRef.current.style.top = `${
			positionRect.y + positionRect.height + 10
		}px`
		// @ts-ignore
		childContainerRef.current.style.left = `${left}px`
		// @ts-ignore
		childContainerRef.current.style.opacity = 1
	}

	return (
		<>
			<div ref={positionDivRef} />
			{isOpened &&
				createPortal(
					<div
						style={{
							position: 'fixed',
							top: '0px',
							left: '0px',
							right: '0px',
							bottom: '0px',
							zIndex: zIndex,
						}}
						onMouseDown={onLayerMouseDown}
						onClick={(e) => {
							if (e.target !== e.currentTarget) return
							onClose()
						}}
					>
						<div
							style={{
								position: 'absolute',
								opacity: '0',
								transition: 'opacity 0.25s ease-in-out',
								width: 'fit-content',
								height: 'fit-content',
							}}
							// @ts-ignore
							ref={childContainerRef}
						>
							{children}
						</div>
					</div>,
					document.body
				)}
		</>
	)
}

export default Portal

// function getAllScrollableParents(el: HTMLElement) {
// 	const result: HTMLElement[] = []
// 	iterateParents(el, (parent) => {
// 		if (isScrollable(parent)) result.push(parent)
// 	})
// 	return result
// }

// function iterateParents(el: HTMLElement, cb: (el: HTMLElement) => any) {
// 	cb(el)
// 	if (el.parentElement) iterateParents(el.parentElement, cb)
// }

// function isScrollable(element) {
// 	return (
// 		element.scrollWidth > element.clientWidth ||
// 		element.scrollHeight > element.clientHeight
// 	)
// }
