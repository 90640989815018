import React, { useMemo } from 'react'
import { PlacementShort } from 'src/stores/ADMarketTypes.types'
import { ReactComponent as Warning } from 'src/assets/images/warningRing.svg'
import s from './itemsControl.module.scss'
import { Address } from '../../modals/MassCoverageModalMap'

type Props = {
	placements: PlacementShort[]
	onPlacementClick: (placement: PlacementShort) => void
	addedPlacements: PlacementShort[]
	searchAddresses: Address[] | null
	onAddressClick: (address: Address) => void
}

const statusStyle: React.CSSProperties = {
	minWidth: 'max-content',
	padding: 0,
	margin: 0,
	fontFamily: 'Inter',
	fontSize: '12px',
	fontWeight: 500,
	lineHeight: '12px',
	letterSpacing: '-0.03em',
	textAlign: 'right',
}
export const LeftPanelPlacementList = ({
	placements,
	onPlacementClick,
	addedPlacements,
	searchAddresses,
	onAddressClick,
}: Props) => {
	const addedPlacementsIds = useMemo(() => {
		return addedPlacements.map((el) => el.pk)
	}, [addedPlacements])

	const isAnyPlacements =
		placements.length > 0 || (searchAddresses && searchAddresses.length > 0)

	return (
		<>
			{!isAnyPlacements ? (
				<div
					style={{
						height: '100%',
						minHeight: '300px',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						alignItems: 'center',
						color: '#6C757D',
					}}
				>
					<Warning fill="#6C757D" />
					<p>Мест не найдено</p>
				</div>
			) : searchAddresses ? (
				<AddressList
					addresses={searchAddresses}
					onAddressClick={onAddressClick}
				/>
			) : (
				<PlacementsList
					addedPlacementsIds={addedPlacementsIds}
					onPlacementClick={onPlacementClick}
					placements={placements}
				/>
			)}
		</>
	)
}

type AddressListProps = {
	addresses: Address[]
	onAddressClick: (address: Address) => void
}
export const AddressList = ({
	addresses,
	onAddressClick,
}: AddressListProps) => {
	return (
		<div className={s.placementsList}>
			{addresses.map((el) => (
				<div
					onClick={() => onAddressClick(el)}
					key={el.address}
					className={s.placementItemWrapper}
				>
					<div className={s.placementItem}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'flex-end',
							}}
						>
							<div
								className={s.placementItem_secondary}
								style={{ color: 'black' }}
							>
								{el.address}
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	)
}

type PlacementsListProps = {
	placements: PlacementShort[]
	onPlacementClick: (placement: PlacementShort) => void
	addedPlacementsIds: number[]
}
export const PlacementsList = ({
	placements,
	onPlacementClick,
	addedPlacementsIds,
}: PlacementsListProps) => {
	return (
		<div className={s.placementsList}>
			{placements.map((el) => (
				<div
					onClick={() => onPlacementClick(el)}
					key={el.pk}
					className={s.placementItemWrapper}
				>
					<div className={s.placementItem}>
						<p className={s.placementItem_primary}>{el.name}</p>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'flex-end',
							}}
						>
							<div
								className={s.placementItem_secondary}
								style={{ color: '#6C757D' }}
							>
								{el.placement_type_name}
							</div>
							{addedPlacementsIds.includes(el.pk) && (
								<div
									style={{
										height: 'min-content',
										borderRadius: '4px',
										padding: '3px 4px',
										color: 'white',
										backgroundColor: '#28A745',
									}}
								>
									<p style={statusStyle}>Добавлено</p>
								</div>
							)}
						</div>
					</div>
				</div>
			))}
		</div>
	)
}
