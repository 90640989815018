import React from 'react'
import { Button } from 'react-bootstrap'
import s from '../CreatreportPage.module.scss'
import useResponsive from 'src/utils/useResponsive'

type BottomButtonsProps = {
	currentStep: number
	goToPreviousStep: () => void
	isOneStepSelect: boolean
	goToNextStep: () => void
	download: () => void
	downloadDisabled: boolean
}

export const BottomButtons = ({
	currentStep,
	download,
	goToNextStep,
	goToPreviousStep,
	isOneStepSelect,
	downloadDisabled,
}: BottomButtonsProps) => {
	const { isMobile } = useResponsive()
	return (
		<div
			style={{
				display: isMobile ? 'block' : 'flex',
				padding: isMobile ? '16px 8px' : '24px 0',
				justifyContent: 'space-between',
				width: '100%',
			}}
		>
			{currentStep !== 0 && !isMobile && (
				<Button variant="secondary" onClick={goToPreviousStep}>
					Назад
				</Button>
			)}
			<div style={{ marginLeft: 'auto' }}>
				{currentStep === 0 || currentStep === 1 ? (
					<Button
						disabled={isOneStepSelect}
						variant="primary"
						onClick={goToNextStep}
						style={{ minWidth: isMobile ? '100%' : 'auto' }}
					>
						Продолжить
					</Button>
				) : null}

				{currentStep === 2 && (
					<Button
						disabled={downloadDisabled}
						style={{ width: isMobile ? '100%' : 'auto' }}
						onClick={() => download()}
						variant="primary"
					>
						Сформировать отчет
					</Button>
				)}
			</div>
		</div>
	)
}
