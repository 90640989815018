import React, { useMemo, useState } from 'react'
import useProviderData from 'src/stores/providerData'
import { useBookingsContext } from '../../context/BookingsProvider'
import { providerBookingInfo } from 'src/constants/api'
import { authFetch } from 'src/utils/authFetch'
import { BookingDetails } from 'src/stores/ProviderTypes.types'
import SidebarPlacementDetail from './SidebarPlacementDetail'
import s from '../list/list.module.scss'
import { Loader } from './Loader'

const SidebarPlacementsList = ({ placements, onSelect, isLoading }) => {
	const [placement, setPlacement] = useState(null)

	return (
		<div className={s.bannerFrame}>
			{isLoading ? (
				<Loader />
			) : !placements?.length ? (
				<div className={s.placeholder}>Не найдено мест размещения</div>
			) : (
				placements?.map((place, index) => {
					return (
						<div
							key={index + 1}
							className={`${s.contentMain}`}
							onClick={() => {
								setPlacement(place)
								onSelect(place)
							}}
						>
							<div className={s.iconFrame}>
								<div
									className={`${s.bannerType} ${
										place.booking_count === 0
											? s.grayBillboardd
											: ''
									}`}
								>
									<div
										className={`${s.uniQon} ${
											place.booking_count === 0
												? s.uniQonNoAdvertising
												: s.uniQonAdvertising
										}`}
										style={{
											width: '40px',
											height: '40px',
										}}
									>
										<div
											className={`${s.uniQon} ${
												place.booking_count === 0
													? s.uniQonNoAdvertising
													: s.uniQonAdvertising
											}`}
											dangerouslySetInnerHTML={{
												__html: place.placement_type.image.replace(
													'fill="#212529"',
													'fill="white"'
												),
											}}
											style={{
												width: '37px',
												height: '37px',
											}}
										/>
									</div>
								</div>
								<div className={s.bannerState}>
									<div
										className={`${s.bannerBadge} ${
											place.booking_count === 0
												? s.grayBadge
												: ''
										}`}
									>
										<span>
											{place.booking_count}{' '}
											{getBookingWord(
												place.booking_count
											)}
										</span>
									</div>
								</div>
							</div>
							<h1 className={s.bannerTitle}>
								{place.placement_type.name}
								<br />
								{place.name}
							</h1>
							{index !== placements.length - 1 && (
								<div className={s.line}></div>
							)}
						</div>
					)
				})
			)}
			<SidebarPlacementDetail
				placement={placement}
				onBack={() => setPlacement(null)}
			/>
		</div>
	)
}

export function getBookingWord(number) {
	const lastDigit = number % 10
	const lastTwoDigits = number % 100

	if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
		return 'броней'
	}

	if (lastDigit === 1) {
		return 'бронь'
	}

	if (lastDigit >= 2 && lastDigit <= 4) {
		return 'брони'
	}

	return 'броней'
}

export default SidebarPlacementsList
