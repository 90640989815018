import React from 'react'
import useResponsive from 'src/utils/useResponsive'

type TemplateInfoHeaderPropsType = {
	imgURL: string
	address: string
	name: string
}

export const TemplateInfoHeader = ({
	address,
	imgURL,
	name,
}: TemplateInfoHeaderPropsType) => {
	const { isMobile } = useResponsive()
	return (
		<div
			style={{
				margin: isMobile ? '1.5rem' : 'none',
				display: 'flex',
				flexDirection: isMobile ? 'row' : 'column',
				alignItems: isMobile ? 'center' : 'flex-start',
				justifyContent: 'flex-start',
			}}
		>
			<img
				src={imgURL}
				style={{
					objectFit: 'cover',
					width: isMobile ? '5rem' : '100%',
					height: isMobile ? '5rem' : '100%',
					borderRadius: isMobile ? '8px' : 'none',
					maxHeight: '15rem',
				}}
				alt=""
			/>

			<div
				style={{
					margin: isMobile ? '0 0 0 1rem' : '1.5rem ',
				}}
			>
				<h5 style={{ marginBottom: '4px', padding: 0 }}>{name}</h5>
				<p style={{ color: '#6C757D', margin: 0, padding: 0 }}>
					{address}
				</p>
			</div>
		</div>
	)
}
