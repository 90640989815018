import { Placemark } from '../components/outdoor-mass-coverage/modals/MassCoverageModalMap'
import { ymaps } from '../components/outdoor-mass-coverage/modals/MassCoverageYandexMap'

type PaintItems = {
	map: any
	objectManagerJSON: {
		type: string
		features: Placemark[]
	}
	onItemClick: (placementId: number) => void
}

export const paintItems = ({
	map,
	objectManagerJSON,
	onItemClick,
}: PaintItems) => {
	const MyIconContentLayout = ymaps?.templateLayoutFactory?.createClass(
		'<div style="color: #000000; font-size: 16px; padding: 12px 4px;line-height: 20px; border: 2px solid #CED4DA; background: #FFFFFF; border-radius: 8px; transform: translateY(-40%); position:relative; font-weight: 500;">{{ properties.geoObjects.length }}' +
			'<div style="color: white; position:absolute; left: 100%; top: 100%; transform: translateX(-60%) translateY(-60%);border: 2px solid #FFFFFF; width: 24px; height: 24px; display: flex; align-items: center; justify-content: center; background-color:#007BFF; border-radius: 50%">' +
			'<i class="bi bi-plus d-flex align-items-center" />' +
			'</div>' +
			'</div>'
	)

	const CustomBalloonContentLayout =
		ymaps?.templateLayoutFactory?.createClass(
			[
				"<div id='ymaps_cluster_baloon_content'>",
				"<div class='ymaps_cluster_baloon_content_container'>",
				"<div class='ymaps_cluster_baloon_content_header'> " +
					"<div class='ymaps_cluster_baloon_content_title'></div></div>",
				"<div class='ymaps_cluster_baloon_content_places'>",
				'{% for geoObject in properties.geoObjects %}',
				'<div class="ymaps_cluster_baloon_content_place" data-id="{{geoObject.properties.id}}" data-state="{{geoObject.properties.state}}">',
				'<div class="ymaps_cluster_baloon_content_place_icon">            {{ geoObject.properties.icon|default: geoObject.properties.icon|raw }}</div>',
				'<div class="ymaps_cluster_baloon_content_place_data">',
				"<div class='ymaps_cluster_baloon_content_place_data_title'>{{geoObject.properties.placement_type_name}}</div>",
				"<div class='ymaps_cluster_baloon_content_place_data_size'>{{geoObject.properties.size}}</div>",
				'</div>',
				'</div>',
				'{% endfor %}',
				'</div>',
				'</div>',
				'</div>',
			].join('')
		)
	const objectManager = new ymaps.ObjectManager({
		clusterize: true,
		gridSize: 128,
		hasHint: false,
		clusterIconContentLayout: MyIconContentLayout,
		clusterBalloonContentLayout: CustomBalloonContentLayout,
		clusterBalloonShadow: true,
		clusterIcons: [
			{
				href: '',
				size: [40, 40],
				offset: [-20, -20],
			},
			{
				href: '',
				size: [60, 60],
				offset: [-30, -30],
			},
		],
	})

	objectManager.clusters.events.add(['click'], (e: any) => {
		var objectId = e.get('objectId')
	})

	objectManager.objects.events.add('click', (e) => {
		onItemClick(e.get('objectId'))
	})

	objectManager.add(objectManagerJSON)

	map.geoObjects.add(objectManager)
}
