import React from 'react'
import s from './templateImage.module.scss'
import { ReactComponent as Image1Icon } from 'src/assets/images/picture-1.svg'
import { CreativeTemplateKeysType } from '../../../../constants'
import { getSize } from './TemplateImage'
import cn from 'classnames'

type YourTemlateFormPropType = {
	screenResolution: CreativeTemplateKeysType
	onClick: () => void
}
export const YourTemlateForm = ({
	screenResolution,
	onClick,
}: YourTemlateFormPropType) => {
	return (
		<div
			onClick={onClick}
			className={cn(s.image_container, s.yourTemlateForm)}
			style={{
				...getSize(screenResolution),
			}}
		>
			<Image1Icon />

			<p
				style={{
					fontFamily: 'Inter',
					fontSize: '16px',
					fontWeight: 500,
					lineHeight: '20px',
					letterSpacing: '-0.03em',
					textAlign: 'center',
				}}
			>
				Cоздать свой материал
			</p>
		</div>
	)
}
