import { UserConfig, VariantState } from '../types'
import { InternetVariantsState } from '../types/internetTypes'

const defaultInternetVariantsState: InternetVariantsState = {
	vk: VariantState.Enabled,
	yandex: VariantState.Enabled,
}
export const getInternetVariantsState = (
	userConfig: UserConfig | undefined
): InternetVariantsState => {
	if (!userConfig) {
		return defaultInternetVariantsState
	}

	return {
		vk: userConfig?.is_enable_vk
			? VariantState.Enabled
			: VariantState.Disabled,
		yandex: userConfig?.is_enable_yandex
			? VariantState.Enabled
			: VariantState.Disabled,
	}
}
