import React, { useEffect } from 'react'
import { useAdformatsStore } from 'src/components/_client/create-campaign/Step2-ADformats/adformats-store/adformats.store'
import useCampaignData from 'src/stores/campaignData'
import { ReactComponent as Target } from 'src/assets/images/location-3.svg'
import BookingAD from 'src/components/_client/create-campaign/Step2-ADformats/BookingsAD/BookingAD'
import { CardWrapper } from '../../../../../common/card-wrapper/CardWrapper'
import { outdoorNoItemsErrorId } from 'src/components/_client/create-campaign/Step2-ADformats/new-adformats-step/constants'

export const GarantViews = () => {
	const [campaign, campaignInterface] = useCampaignData()

	const setAdFormatErrors = useAdformatsStore(
		(state) => state.setAdFormatErrors
	)
	const addNewAdFormatError = useAdformatsStore(
		(state) => state.addNewAdFormatError
	)

	useEffect(() => {
		//когда компонент вмонтирован проверяй наличие размещений
		if (
			campaign.booking.filter(
				(el) => el.placement.door_type === 'outdoor'
			).length === 0
		) {
			addNewAdFormatError('garant', {
				field: 'Не добавлены места',
				id: outdoorNoItemsErrorId,
				message: 'Не добавлены места',
			})
		} else {
			setAdFormatErrors('garant', null)
		}
	}, [campaign])

	useEffect(() => {
		return () => {
			setAdFormatErrors('garant', null)
		}
	}, [])
	return (
		<CardWrapper>
			<div
				id={outdoorNoItemsErrorId}
				className={'d-flex justify-content-between'}
				style={{ marginBottom: '8px' }}
			>
				<h4 className={'mb-0'}>Гарантированные показы</h4>
				<div>
					<Target />
				</div>
			</div>
			<div className={'mb-3'}>
				Данное размещение доступно минимум на 2 недели. Выгодно для тех
				кому нужен гарантированный показ в заданный промежуток времени
				на выбранном экране.
			</div>

			<BookingAD
				isHeader={false}
				isHiddenContent={false}
				onError={() => {}}
				setIsLoading={() => {}}
				bookings_type="outdoor"
			/>
		</CardWrapper>
	)
}
