import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'

const ButtonGroupBooleanSwitch = ({
	elements,
	initialValue,
	setFieldValue,
	vertical = false,
	reverse = false,
	size = undefined,
	disabled = false,
	hardWidth = false,
	readOnly = false,
	onChangeCB = () => {},
	...props
}) => {
	// @ts-ignore
	//Первым параметров в elements передается лейбл для true, второй - для false
	//Если reverse - true, то наоборот
	const [active, setActive] = useState(() => {
		if (initialValue === null) return null
		if (reverse) {
			return initialValue ? elements[0] : elements[1]
		} else {
			return initialValue ? elements[1] : elements[0]
		}
	})
	const [els] = useState(reverse ? elements.slice(0).reverse() : elements)

	useEffect(() => {
		if (initialValue === null) {
			// setFieldValue(props.name, false);
		} else {
			setFieldValue(props.name, initialValue)
			if (reverse) {
				setActive(initialValue ? elements[0] : elements[1])
			} else {
				setActive(initialValue ? elements[1] : elements[0])
			}
		}
	}, [initialValue, props.name, setFieldValue])
	return (
		<ButtonGroup size={size} vertical={vertical}>
			{els.map((el, i) => (
				<Button
					variant={el === active ? 'primary' : 'secondary'}
					onClick={() => {
						if (readOnly || el === active) return
						setActive(el)
						let val = false
						if (reverse) {
							val = i === 1
						} else {
							val = i === 0
						}
						setFieldValue(props.name, val)
						if (onChangeCB) {
							// @ts-ignore
							onChangeCB()
						}
					}}
					key={i}
					style={hardWidth ? { minWidth: '50%' } : {}}
					disabled={disabled}
					className={disabled ? 'btn-group-disabled' : ''}
				>
					{el}
				</Button>
			))}
		</ButtonGroup>
	)
}

export default ButtonGroupBooleanSwitch
