import React, { useState } from 'react'
import s from './modals.module.scss'
import cn from 'classnames'
import { InfoField } from '../common'
import {
	NotificationDetails,
	NotificationModel,
} from 'src/components/_moderator/types/ModerationRequestTypes'
import { getUpdatedString } from 'src/components/_moderator/utils'
import moment from 'moment'

type Props = {
	notification: NotificationModel & NotificationDetails
	items: string[]
	title: string
	manager: string | undefined
}

export const NotificationInfoContent = ({
	notification,
	items,
	title,
	manager,
}: Props) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				gap: '1.5rem',
				justifyContent: 'space-between',
			}}
		>
			<div className={s.notificationInfoBody}>
				<InfoField header="Заголовок" text={notification.title} />
				<InfoField
					header="Основной текст"
					text={notification.description}
				/>
				<InfoField header="Отправить по" text={''}>
					<div
						style={{
							display: 'flex',
							flexDirection: items.length < 5 ? 'column' : 'row',
						}}
					>
						{items.length < 5 && items.length > 0 && (
							<span
								className={s.itemList}
								style={{ color: '#212529' }}
							>
								{`${title}:`}
							</span>
						)}

						<ItemsList
							title={title}
							items={items}
							notification={notification}
						/>
					</div>
				</InfoField>
				<InfoField header="Менеджер" text={manager ? manager : ''} />
			</div>

			<div className={s.notificationInfoDate}>
				<div>{`Создано ${moment(notification.created_at).format(
					'DD MMMM YYYY [года]'
				)}`}</div>
				<div>{getUpdatedString(notification.updated_at)}</div>
			</div>
		</div>
	)
}
const ItemsList = ({
	title,
	items,
}: {
	title: string
	items: string[]
	notification: NotificationModel
}) => {
	const [open, setOpen] = useState(false)

	const limit = open ? Infinity : 11

	if (items.length > 5) {
		return (
			<div
				style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
			>
				<div className={s.itemList}>
					<span style={{ color: '#212529' }}>{`${title}:  `}</span>
					{items.filter((el, i) => i < limit).join(', ')}
				</div>

				{items.length > 11 && (
					<div
						style={{
							width: 'max-content',
							color: '#007BFF',
							border: 'none',
							cursor: 'pointer',
							padding: '6px 12px',
						}}
						onClick={() => setOpen(!open)}
					>
						{open ? 'Скрыть' : 'Показать всеx'}
					</div>
				)}
			</div>
		)
	}
	return (
		<ul className={s.itemList}>
			{items.map((item) => (
				<li key={item}>{item}</li>
			))}
		</ul>
	)
}
