import React from 'react'
import { Spinner } from 'react-bootstrap'

export const Loader = () => (
	<div
		className={
			'd-grid h-100 w-100 align-items-center justify-content-center'
		}
	>
		<Spinner animation={'border'} variant={'primary'} />
	</div>
)
