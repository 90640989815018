import React, { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useResponsive from 'src/utils/useResponsive'
import s from './headerChat.module.scss'
import { motion } from 'framer-motion'
import { ReactComponent as ChatIcon } from 'src/assets/images/chat.svg'
import ROUTES from 'src/constants/Routes'

export const HeaderChat = (props: any) => {
	const [desktopOpen, setDesktopOpen] = useState(false)

	const history = useHistory()
	const location = useLocation()
	const { isDesktop } = useResponsive()

	const isChatPage = useMemo(() => {
		return location.pathname.includes('support') || desktopOpen
	}, [location, desktopOpen])

	function handleNotificationClick() {
		if (isDesktop) return setDesktopOpen((p) => !p)
		return isChatPage ? history.goBack() : history.push(ROUTES.ROUTE_CHAT)
	}
	return (
		<>
			<div style={{}}>
				<div
					className={s.chatIcon}
					onClick={handleNotificationClick}
					style={
						isChatPage
							? {
									backgroundColor: '#F5F5F5',
							  }
							: {}
					}
				>
					<ChatIcon />
				</div>
				{desktopOpen && (
					<motion.div
						className={s.container}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						initial={{ opacity: 0 }}
						transition={{ duration: 0.2 }}
					>
						<iframe
							width="100%"
							height={'100%'}
							src="https://jivo.chat/O816map4hs"
						></iframe>
					</motion.div>
				)}
			</div>
		</>
	)
}
