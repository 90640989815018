import React, { useEffect, useState } from 'react'
import bem from '../../../utils/bem'
import { Col, Container, Row } from 'react-bootstrap'
import logo from '../../../assets/images/logo.svg'
import '../../../styles/invite-provider.scss'
import FieldsForm from '../../auth/FieldsForm'
import validatePhoneAndPassword from '../../auth/validatePhoneAndPassword'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import ROUTES from '../../../constants/Routes'
import InvalidProviderInvite from './InvalidProviderInvite'
import useUserData from '../../../stores/userData'
import { offerta, personalDataAgreement } from '../../../constants/links'
import useAuth from '../../auth/useAuth'
import yandexTracking from '../../../utils/yandexTracking'
import PhoneConfirmation from '../../auth/PhoneConfirmation'

const cn = bem('provider-create')

const InviteProviderPage: React.FC = () => {
	const [user, userInterface] = useUserData()
	const [step, setStep] = useState(0)
	const history = useHistory()
	const { search } = useLocation()
	const isProviderTokenValid = userInterface.isProviderInviteTokenValid()
	const { errors, handleSubmit } = useAuth(async (data) => {
		await userInterface.registerProvider(data)
		yandexTracking.setUser(data)
		await yandexTracking.reachGoal('set_phone_and_pass')
	})

	async function handlePhoneConfirmation({ code }) {
		await userInterface.acceptPhoneActivationCode({ code })
		await yandexTracking.reachGoal('accept_phone')
		history.push('/provider/')
	}

	async function handleCodeResend() {
		try {
			await userInterface.sendPhoneActivationCode()
		} catch (e) {
			console.log('-> e', e)
		}
	}

	useEffect(() => {
		const query = new URLSearchParams(search)
		const invite_token = query.get('token')
		userInterface.checkProviderToken(invite_token)
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (user.phone_number && !user.is_phone_confirmed && step !== 1) {
			setStep(1)
		}
	}, [user, step])
	if (user.phone_number && user.is_phone_confirmed) {
		return <Redirect to={ROUTES.ROUTE_AUTH} />
	}
	if (isProviderTokenValid === null) return null
	if (!isProviderTokenValid) return <InvalidProviderInvite />
	return (
		<div className={cn('')}>
			<Container>
				<Row>
					<Col className={cn('logo')}>
						<img src={logo} alt={'logo'} />
					</Col>
				</Row>
				<Row className="d-flex justify-content-center">
					<Col className={cn('body')}>
						{step === 0 && (
							<>
								<h1 className={cn('header')}>
									Создание аккаунта
								</h1>
								<br />
								<br />
								<br />
								<FieldsForm
									onValidate={validatePhoneAndPassword}
									errors={errors}
									fields={[
										{
											name: 'fio',
											autoComplete: 'name',
											label: 'ФИО',
											placeholder: 'Иванов Иван Иванович',
										},
										{
											name: 'phone_number',
											autoComplete: 'tel',
											label: 'Телефон',
											placeholder: '+7 (900) 000-00-00',
										},
										{
											name: 'password',
											autoComplete: 'current-password',
											label: 'Пароль',
											toggleVisibility: true,
											placeholder: '••••••••••',
										},
									]}
									submitLabel={'Продолжить'}
									onSubmit={handleSubmit}
									cn={cn}
								/>
								<span className={cn('desc')}>
									Нажимая на кнопку «Продолжить», вы
									принимаете условия
									<a href={offerta}> договора оферты </a>и
									даете{' '}
									<a href={personalDataAgreement}>
										согласие на обработку персональных
										данных
									</a>
								</span>
								<span className={cn('desc', 'mt24')}>
									Уже есть аккаунт?
									<a
										onClick={(e) => {
											e.preventDefault()
											history.push(ROUTES.ROUTE_AUTH)
										}}
										href={'/'}
									>
										{' '}
										Войти
									</a>
								</span>
							</>
						)}
						{step === 1 && (
							<PhoneConfirmation
								onCodeResend={handleCodeResend}
								onSubmit={handlePhoneConfirmation}
								onBack={() => {
									userInterface.logout()
									setStep(0)
								}}
								phone={user.phone_number}
								cn={cn}
							/>
						)}
					</Col>
				</Row>
			</Container>
		</div>
	)
}
export default InviteProviderPage
