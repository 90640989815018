import React, { useEffect, useState } from 'react'
import { DistrictType } from '../../types'
import useCampaignData from 'src/stores/campaignData'
import { MassCoverageItemsList } from './mass-coverage-items-list/MassCoverageItemsList'
import { ReactComponent as Radius } from 'src/assets/images/radius.svg'
import s from './ourdoorMassCoverage.module.scss'
import { useAdformatsStore } from '../../../adformats-store/adformats.store'

type OutdoorCityMethodProps = {
	districts: Array<DistrictType>
	setGlobalIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}
const massCoverageNoItemsErrorId = 'massCoverage_no_items'

export const OutdoorMassCoverage = ({
	districts,
	setGlobalIsLoading,
}: OutdoorCityMethodProps) => {
	const [campaign, campaignInterface] = useCampaignData()

	const addNewAdFormatError = useAdformatsStore(
		(state) => state.addNewAdFormatError
	)
	const removeAdFormatErrorByErrorId = useAdformatsStore(
		(state) => state.removeAdFormatErrorByErrorId
	)

	const [isTemplate, setIsTemplate] = useState(() => {
		return campaign.districtbookings.length === 0
	})

	useEffect(() => {
		if (campaign.districtbookings.length === 0 && !isTemplate) {
			addNewAdFormatError('massCoverage', {
				field: 'Не добавлены города',
				id: massCoverageNoItemsErrorId,
				message: 'Не добавлены города',
			})
		} else {
			removeAdFormatErrorByErrorId(
				'massCoverage',
				massCoverageNoItemsErrorId
			)
		}
	}, [campaign.districtbookings.length, isTemplate])
	return (
		<div id={massCoverageNoItemsErrorId}>
			<div className={s.ourdoorMassCoverage}>
				<div className={s.ourdoorMassCoverageHeader}>
					<div className={s.ourdoorMassCoverageHeader_primary}>
						<h5>Mаксимальный охват</h5>
						<Radius />
					</div>
					<p>
						Распределение показов в рамках бюджета рекламной
						кампании по всем свободным экранам в выбранных городах.
						Выгодно для тех, кому важна минимальная стоимость за 1
						выход ролика.
					</p>
				</div>

				<MassCoverageItemsList
					isTemplate={isTemplate}
					setIsTemplate={setIsTemplate}
					setGlobalIsLoading={setGlobalIsLoading}
					districtbookings={campaign.districtbookings}
					districts={districts}
				/>
			</div>
		</div>
	)
}
