import { CampaignInfoTypeBookingsSeparation } from 'src/stores/ADMarketTypes.types'
import { AdvFormats } from '../types'

export const getAdvFormatTotalAmount = (
	format: AdvFormats,
	campaign: CampaignInfoTypeBookingsSeparation
): number => {
	let totalAmount = 0

	switch (format) {
		case AdvFormats.Outdoor:
			if (
				campaign.district_adv_is_selected &&
				campaign.districtbookings.length
			) {
				campaign.districtbookings.forEach((district) => {
					totalAmount += parseFloat(district.amount || '0')
				})
			}

			if (campaign.outdoor_adv_is_selected) {
				campaign.booking
					.filter(
						(booking) => booking.placement.door_type === 'outdoor'
					)
					.forEach((booking) => {
						totalAmount += booking?.booking_budget || 0
					})
			}
			break

		case AdvFormats.Indoor:
			if (campaign.indoor_adv_is_selected) {
				campaign.booking
					.filter(
						(booking) => booking.placement.door_type === 'indoor'
					)
					.forEach((booking) => {
						totalAmount += booking?.booking_budget || 0
					})
			}
			break

		case AdvFormats.PublicTransport:
			if (campaign.public_transport?.is_selected) {
				totalAmount += parseFloat(
					campaign.public_transport?.amount || '0'
				)
			}
			break
		case AdvFormats.Internet:
			if (campaign.internet_vk.is_selected) {
				totalAmount += campaign.internet_vk?.amount || 0
			}
			if (campaign.internet_yandex.is_selected) {
				totalAmount += campaign.internet_yandex?.amount || 0
			}
			break
		case AdvFormats.TV:
			if (campaign.adstream.is_selected) {
				totalAmount += parseFloat(campaign.adstream.amount || '0')
			}
			break
	}

	return totalAmount
}
