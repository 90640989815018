import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ReactComponent as XLS } from 'src/assets/images/xls.svg'

export const NoImage = () => (
	<div
		style={{
			width: '80px',
			height: '80px',
			minWidth: '80px',
			minHeight: '80px',
			background: '#F5F5F5',
			borderRadius: '4px',
			border: '1px solid #CED4DA',
		}}
		className={
			'd-flex justify-content-center align-items-center text-secondary mt-2'
		}
	>
		Нет
	</div>
)
export const PlaceholderPDF = ({ link }) => (
	<a
		style={{
			width: '80px',
			height: '80px',
			minWidth: '80px',
			minHeight: '80px',
			background: '#F5F5F5',
			borderRadius: '4px',
			border: '1px solid #CED4DA',
		}}
		className={
			'd-flex justify-content-center align-items-center text-secondary'
		}
		href={link}
		target={'_blank'}
		rel={'noreferrer'}
	>
		PDF
	</a>
)

export const PlaceholderXLS = ({ link }) => (
	<a
		style={{
			width: '48px',
			height: '48px',
			minWidth: '48px',
			minHeight: '48px',
			background: '#F5F5F5',
			borderRadius: '4px',
			border: '1px solid #CED4DA',
		}}
		className={
			'd-flex justify-content-center align-items-center text-secondary'
		}
		href={link}
		target={'_blank'}
		rel={'noreferrer'}
	>
		<XLS style={{ width: '24px', height: '24px' }} />
	</a>
)
type PlaceholderVIDEOProps = {
	link: any
	style?: React.CSSProperties
}
export const PlaceholderVIDEO = ({ link, style }: PlaceholderVIDEOProps) => {
	const [open, setOpen] = useState(false)

	return (
		<>
			<a
				style={{
					width: '80px',
					height: '80px',
					minWidth: '80px',
					minHeight: '80px',
					background: '#F5F5F5',
					borderRadius: '4px',
					border: '1px solid #CED4DA',
					cursor: 'pointer',
					...style,
				}}
				className={'d-flex justify-content-center align-items-center'}
				onClick={() => setOpen(true)}
				rel={'noreferrer'}
			>
				<video
					style={{
						width: '80px',
						height: '80px',
						minWidth: '80px',
						minHeight: '80px',
						...style,
					}}
					src={link}
				/>
			</a>
			<Modal show={open} onHide={() => setOpen(false)} centered>
				<video controls src={link} />
			</Modal>
		</>
	)
}
