export enum PaymentMessageType {
	GO_TO_PAYMENT,
	PAYMENT_NO_CHANGES,
	PAYMENT_WITH_CREATED_INVOICE,
	DONT_SHOW,
	GO_TO_ACTIVE_PAYMENT,
	WAITING,
	AGEN_PAYMENT,
}

export type BottomMessageType = {
	type: PaymentMessageType
	leftText: string
	buttonText: string
	rightText?: boolean
}
