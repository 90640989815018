import React from 'react'
import { PublicTransportVariants } from '../../../../../../types'
import s from './publicTransportVariantsSelectors.module.scss'
import { PublicTransportVariantSelector } from './p-t-variant-selector/PublicTransportVariantSelector'

type Props = {
	isLoading: boolean
	onSelectorClick: (variant: PublicTransportVariants) => void
	selectedVariants: PublicTransportVariants[]
}
export const PublicTranportVariantsSelectors = ({
	isLoading,
	onSelectorClick,
	selectedVariants,
}: Props) => {
	return (
		<div className={s.selectors}>
			<PublicTransportVariantSelector
				isLoading={isLoading}
				extraInfo="Средний пассажиропоток: > 58 млн. в месяц."
				disabled={false}
				onClick={onSelectorClick}
				isSelected={selectedVariants.includes(
					PublicTransportVariants.MCR
				)}
				description={
					'64 экрана на 30 станциях Московского Центрального Кольца.'
				}
				title="Москва – МЦК"
				selectorKey={PublicTransportVariants.MCR}
			/>

			<PublicTransportVariantSelector
				disabled={true}
				extraInfo="Временно недоступно"
				onClick={onSelectorClick}
				description={
					'Экраны на станциях и в поездах Московских Центральных Диаметров.'
				}
				isSelected={selectedVariants.includes(
					PublicTransportVariants.MCD
				)}
				title="Москва – МЦД"
				selectorKey={PublicTransportVariants.MCD}
				isLoading={isLoading}
			/>
		</div>
	)
}
