import { BookingState } from 'src/stores/ADMarketTypes.types'
import BookingOperationsButtons, {
	IOperationsButtons,
} from '../content/BookingOperationsButtons'
import { useMemo } from 'react'

type Output = {
	buttons: IOperationsButtons[]
}
const useBookingsOperations = (state: BookingState): Output => {
	const Filtered = useMemo(() => {
		return BookingOperationsButtons.filter((button) =>
			button.availableFor.includes(state)
		)
	}, [state])

	return { buttons: Filtered }
}

export default useBookingsOperations
