export default function validatePhoneAndPassword(values) {
	const errors: any = {}
	if (!values.phone_number) {
		errors.phone_number = 'Введите номер телефона'
	}
	if (!values.password || values.password.length < 8) {
		errors.password = 'Пароль не может быть короче 8 символов'
	}

	return errors
}
