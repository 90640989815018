import React from 'react'
import s from './registration.module.scss'

export const RegistrationHeader = (props: any) => {
	return (
		<div className={s.registrationHeader}>
			<h3 className="text-2xl font-bold">Создание аккаунта</h3>
		</div>
	)
}
