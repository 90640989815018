import React, { useCallback, useState } from 'react'
import {
	CreateReportBody,
	CreateReportHeader,
	ReportCheckBox,
} from '../../common'
import { CreateReportOneHeaderInput } from './CreateReportOneHeaderInput'
import useResponsive from 'src/utils/useResponsive'
import s from '../createReportSteps.module.scss'
import cn from 'classnames'
import { CampaignListItem } from '../../../CreateReportPage'

type CreateReportStepOneProps = {
	setCampaignIds: (value: string[]) => void
	campaignIds: string[]
	campaignList: CampaignListItem[]
}

export const CreateReportStepOne = ({
	campaignIds,
	setCampaignIds,
	campaignList,
}: CreateReportStepOneProps) => {
	const { isMobile } = useResponsive()

	const [searchString, setSearchString] = useState('')

	const handleSelectChange = useCallback(
		(id: string) => {
			const index = campaignIds.indexOf(id)
			if (index === -1) {
				return setCampaignIds([...campaignIds, id])
			} else {
				return setCampaignIds(
					campaignIds.filter((campaignId) => campaignId !== id)
				)
			}
		},
		[campaignIds]
	)

	const headerInputAction = useCallback(() => {
		if (campaignIds?.length === campaignList?.length) {
			return setCampaignIds([])
		} else {
			setCampaignIds(campaignList.map((campaign) => campaign.id))
		}
	}, [campaignIds, campaignList])

	const selectList = campaignList
		?.filter(
			(campaign) =>
				campaign?.name?.includes(searchString) || searchString === ''
		)
		?.map((campaign) => (
			<ReportCheckBox
				key={campaign.id}
				text={campaign.name}
				onChange={() => handleSelectChange(campaign.id)}
				checked={campaignIds.some((c) => c === campaign.id)}
			/>
		))

	return (
		<div
			className={cn(s.createReportStep, {
				[s.createReportStep_mobile]: isMobile,
			})}
			id="create-report-step-one-content"
		>
			<CreateReportHeader
				description={`Выберите кампании, для которых вы хотите создать отчет`}
				title={'Рекламные кампании'}
			>
				<CreateReportOneHeaderInput
					setSearchValue={setSearchString}
					isAllSelected={campaignIds?.length === campaignList?.length}
					count={campaignIds?.length || 0}
					onClick={headerInputAction}
				/>
			</CreateReportHeader>

			{selectList?.length > 0 && <hr style={{ margin: '0 0 8px 0' }} />}

			<CreateReportBody>
				<div id="ReportCheckBox_list">{selectList}</div>
			</CreateReportBody>
		</div>
	)
}
