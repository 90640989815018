import React, { FC, useState } from 'react'
import {
	InternetAdType,
	InternetState,
} from '../../../../stores/ADMarketTypes.types'
import useCampaignData from '../../../../stores/campaignData'
import DetailItem from '../../../../utils/DetailItem'
import { Button, Dropdown } from 'react-bootstrap'
import { ArrowRight } from '../bookings/MobileBookingControl'
import { useConfirm } from '../../../UtilityComponents/ConfirmModalProvider'
import MobileModal from '../../../UtilityComponents/MobileModal'
import RowLabelValue from '../../../../utils/RowLabelValue'
import { prettyThousands } from '../../../_provider/Mediaformats/MediaInfo'
import IntlFormatter from '../../../../utils/intl-money-formatter'
import ProgressFileInput from '../../../UtilityComponents/ProgressFileInput'
import { removeHttps } from '../../create-campaign/Step2-ADformats/InternetAD/URLutils'
import VkButtonTextMap from '../../create-campaign/Step3-Upload/Internet/VkButtonText.data'
import { downloadByRequest } from 'src/utils'

interface IMobileInternetControls {
	internet: InternetAdType
	name: 'vk' | 'yandex'
	onClose: () => void
}

const MobileInternetControls: FC<IMobileInternetControls> = ({
	internet,
	name,
	onClose,
}) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { confirm } = useConfirm()

	const [showModal, setShowModal] = useState(false)
	const showDeleteButton =
		internet.state !== InternetState.Canceled &&
		internet.state !== InternetState.Reject

	async function DeleteInternetHandler() {
		const title =
			name === 'yandex'
				? 'Вы уверены, что хотите удалить рекламную сеть Яндекса из рекламной кампании?'
				: 'Вы уверены, что хотите удалить рекламную сеть VK из рекламной кампании?'
		const result = await confirm({
			text: 'После этого действия вы не сможете ее вернуть обратно',
			title,
			closeButton: 'true',
			acceptText: 'Да, уверен',
			declineText: 'Нет, отменить',
			acceptVariant: 'danger',
		})
		if (result) {
			const fd = new FormData()
			fd.append('to_canceled', 'true')
			await campaignInterface.patchInternetYandex(campaign.id, fd)
		}
	}
	const handleUpload = () => {
		const urlPrefix = name == 'vk' ? 'vk_creative' : 'yandex_creative'
		if (internet?.image) {
			downloadByRequest(
				`adv_companies/${campaign.id}/${urlPrefix}/download/`,
				internet.image
			)
		}
		onClose()
	}
	return (
		<div style={{ padding: '24px 16px 16px 16px' }}>
			<DetailItem
				left={
					<h5 className={'m-0'} style={{ fontSize: '16px' }}>
						Смотреть условия размещения{' '}
					</h5>
				}
				right={<ArrowRight />}
				leftSecondary={false}
				bottomDivider={false}
				onClick={() => setShowModal(true)}
			/>
			{internet.image && (
				<DetailItem
					left={
						<h5 className={'m-0'} style={{ fontSize: '16px' }}>
							Выгрузить материал
						</h5>
					}
					right={<ArrowRight />}
					leftSecondary={false}
					bottomDivider={false}
					onClick={handleUpload}
				/>
			)}
			{showDeleteButton && (
				<Button
					variant={'danger'}
					className={'w-100 mt-2'}
					onClick={DeleteInternetHandler}
				>
					<i className={'bi bi-trash me-1'} />
					Удалить
				</Button>
			)}
			<MobileModal
				onHide={() => setShowModal(false)}
				show={showModal}
				title={'Условия размещения'}
				zIndex={2000}
			>
				<section className={'p-0'}>
					<RenderTerms internet={internet} name={name} />
				</section>
			</MobileModal>
		</div>
	)
}

const RenderTerms = ({
	internet,
	name,
}: {
	internet: InternetAdType
	name: 'vk' | 'yandex'
}) => {
	return (
		<>
			<RowLabelValue
				label={'Рекламируемый сайт'}
				value={
					<a href={internet.link || ''}>
						{removeHttps(internet.link || '')}
					</a>
				}
			/>
			<RowLabelValue
				label={'Возрастная маркировка'}
				value={'0+'}
				show={name === 'vk'}
			/>
			<RowLabelValue label={'Заголовок'} value={internet.heading} />
			<RowLabelValue
				label={name === 'yandex' ? 'Текст объявления' : 'Описание'}
				value={internet.text}
			/>
			<RowLabelValue
				label={'Надпись на кнопке'}
				value={VkButtonTextMap.get(internet?.button_text || '')}
				show={name === 'vk'}
			/>
			<RowLabelValue
				label={'Бюджет кампании'}
				value={IntlFormatter.format(internet.amount, false)}
			/>
			<RowLabelValue
				label={'Оплата'}
				show={name === 'yandex'}
				value={'За клики'}
			/>
			<RowLabelValue label={'Количество кликов'} value={'40 055'} />
			<RowLabelValue
				label={'Рекламу увидят'}
				value={'~ 1 500 человек'}
				show={name === 'yandex'}
			/>
			<RowLabelValue
				label={'Стоимость за 1 000 показов'}
				value={IntlFormatter.format('1046.53', false)}
				show={name === 'vk'}
			/>
			<RowLabelValue
				label={'Прогнозная стоимость:'}
				value={'52 326,53 ₽'}
			/>
			<Dropdown.Divider className={'m-0 mt-3 mb-3 p-0'} />
			<div
				style={{
					marginLeft: '-16px',
					marginTop: '-16px',
					flex: '1 1 auto',
				}}
			>
				<ProgressFileInput
					initialValue={{
						src:
							internet?.image ||
							'https://storage.yandexcloud.net/admarket-media-dev/empty_VmO0bhA.jpg',
						name: 'Изображение',
					}}
					updateUrl={''}
					nameInBody={''}
					onlyView
				/>
			</div>
		</>
	)
}

export default MobileInternetControls
