import { motion } from 'framer-motion'
import React, { useCallback, useEffect, useState } from 'react'
import { Button, ButtonGroup, Card } from 'react-bootstrap'
import { DistrictBookingMediaFormat } from 'src/stores/technical-requirements-store'
import s from './districtBookingEdit.module.scss'
import { CollapseCardButton } from 'src/components/common/garantid-card/garantid-media-format-card/components/common/CollapseButton'
import cn from 'classnames'
import useNotificationData from 'src/stores/notificationsData'
import {
	useCampaignInfoStore,
	VinstanCreativeExternalType,
} from 'src/components/_client/campaign-info/store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from 'src/components/_client/campaign-info/store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { MediaFormatRequirements } from 'src/components/common/garantid-card/garantid-media-format-card/components/common/MediaFormatRequirements'

import { FormatUpload } from './FormatUpload'
import { ExteranalTypesState } from './DistrictBookingEdit'
import { BidRequestPreview } from 'src/components/common/garantid-card/garantid-media-format-card/components/common/BidRequestPreview'

type ComponentType = 'upload' | 'requirements'

type DistrictFormatEditCardProps = {
	format: DistrictBookingMediaFormat
	districtBookingId: number
	onEditVinstant: (data: { contentKey: string; link: string }) => void
	setExternalTypesState: (state: ExteranalTypesState) => void
}

export const DistrictFormatEditCard = ({
	format,
	districtBookingId,
	onEditVinstant,
	setExternalTypesState,
}: DistrictFormatEditCardProps) => {
	const [_, notificationsInterface] = useNotificationData()

	const [open, setOpen] = useState(false)
	const [componentType, setComponentType] = useState<ComponentType>('upload')

	const editVinstantKey = districtBookingId + `${format.media?.external_type}`

	const editCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated(editVinstantKey)
	const isVinstantLoading = editCreativeVinstantStatus === 'start'
	const editVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'booking_id',
			itemId: districtBookingId.toString(),
			external_type: format.media
				?.external_type as VinstanCreativeExternalType,
		})
	)
	const handleEditVinstant = useCallback(() => {
		if (editVinstantLink) {
			onEditVinstant({
				contentKey: editVinstantKey,
				link: editVinstantLink,
			})
		}
	}, [editVinstantKey, editVinstantLink])

	const overlayText = open ? 'Свернуть' : 'Развернуть'

	return (
		<motion.div
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			initial={{ opacity: 0 }}
		>
			<Card className={cn(s.editCard, { [s.noGap]: !open })}>
				<Card.Header className={cn(s.editCardHeader)}>
					<h6 className="m-0">{format.media_type_name}</h6>
					<CollapseCardButton
						onClick={() => setOpen(!open)}
						open={open}
						overlayText={overlayText}
					/>
				</Card.Header>
				<motion.div
					initial={{ height: 0, opacity: 0 }}
					animate={{
						height: open ? 'auto' : 0,
						opacity: open ? 1 : 0,
					}}
					transition={{
						duration: componentType === 'upload' ? 0.5 : 0.75,
					}}
					style={{ overflow: 'hidden' }}
				>
					<Card.Body className="pt-0  d-flex flex-column gap-2">
						<ButtonGroup className="mb-2 w-100">
							<Button
								variant={
									componentType === 'upload'
										? 'secondary'
										: 'light'
								}
								onClick={() => setComponentType('upload')}
							>
								Загрузка
							</Button>
							<Button
								variant={
									componentType === 'requirements'
										? 'secondary'
										: 'light'
								}
								onClick={() => setComponentType('requirements')}
							>
								Требования
							</Button>
						</ButtonGroup>

						<motion.div
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							initial={{ opacity: 0 }}
						>
							<MediaFormatRequirements
								styles={{
									padding: 0,
									visibility:
										componentType === 'requirements'
											? 'visible'
											: 'hidden',
									height:
										componentType === 'requirements'
											? 'auto'
											: 0,
								}}
								mediaRequierments={format.media_requirements}
							/>

							{format.media ? (
								<FormatUpload
									setExternalTypesState={
										setExternalTypesState
									}
									openVinstantEditor={handleEditVinstant}
									styles={{
										visibility:
											componentType === 'upload'
												? 'visible'
												: 'hidden',
										height:
											componentType === 'upload'
												? 'auto'
												: 0,
									}}
									mediaRequierments={
										format.media_requirements
									}
									districtBookingId={districtBookingId}
									externalType={format.media.external_type}
									media={format.media}
									editVinstantLink={editVinstantLink}
									isVinstantLoading={isVinstantLoading}
								/>
							) : (
								<BidRequestPreview />
							)}
						</motion.div>
					</Card.Body>
				</motion.div>
			</Card>
		</motion.div>
	)
}
