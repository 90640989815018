import React, { useState } from 'react'
import {
	DistrictBookingItem,
	DistrictBookingMediaFormat,
} from 'src/stores/technical-requirements-store'
import ls from './districtBookingEdit.module.scss'
import { RenderPreview } from 'src/components/UtilityComponents/ProgressFileInput'
import s from '../components.module.scss'
import { DocumentsUploadForm } from 'src/components/_client/campaign-info/internet/DocumentsUploadForm'
import useCampaignData from 'src/stores/campaignData'
import { DistrictFormatEditCard } from './DistrictFormatEditCard'
import { DistrictBookingEditFooter } from './DistrictBookingEditFooter'
import { AnimatePresence } from 'framer-motion'
import { Bid_Response } from 'src/stores/ADMarketTypes.types'

const districtBookingImageDefault =
	'https://storage.yandexcloud.net/admarket-images/static-img/outdoor_26_11zon.jpg'

export type ExteranalTypesState = {
	[key: string]: 'downloaded' | 'removed' | 'error' | 'loading'
}

const getInitialExternalTypesState = (
	mediaFormats: DistrictBookingMediaFormat[],
	bidRequests: Bid_Response[] | undefined
): ExteranalTypesState => {
	const state: ExteranalTypesState = {} as ExteranalTypesState

	mediaFormats.forEach((format) => {
		if (format.media?.external_type) {
			state[format.media.external_type] = 'downloaded'
		}
		if (bidRequests && bidRequests.length > 0) {
			bidRequests.forEach((bidRequest) => {
				state[bidRequest.type || '0'] = 'downloaded'
			})
		}
	})
	return state
}
type Props = {
	districtBooking: DistrictBookingItem
	onEditVinstant: (data: { contentKey: string; link: string }) => void
	onClose: () => void
}
export const DistrictBookingEdit = ({
	districtBooking,
	onEditVinstant,
	onClose,
}: Props) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [isLoading, setIsLoading] = useState(false)
	const bidRequests = campaign.bid_requests['districtbooking']?.filter(
		(el) => el.id === districtBooking.id
	)

	const [externalTypesState, setExternalTypesState] =
		useState<ExteranalTypesState>(
			getInitialExternalTypesState(
				districtBooking.advFormats,
				bidRequests
			)
		)

	const { is_docs_required, advFormats } = districtBooking

	const virtualPlacement = {
		name: 'Максимальный охват',
		address: districtBooking.city_name,
		image: districtBookingImageDefault,
	}

	const handleSetExternalTypeState = (newStateItem: ExteranalTypesState) => {
		setExternalTypesState((prevState) => ({
			...prevState,
			...newStateItem,
		}))
	}
	return (
		<div className={ls.districtBookingEditContainer}>
			<div className={ls.districtBookingEdit}>
				<div className={s.placement}>
					<RenderPreview
						style={{
							height: '80px',
							width: '80px',
							borderRadius: '12px',
						}}
						value={{
							name: virtualPlacement.name,
							src: virtualPlacement.image,
						}}
					/>
					<div className={ls.placementAddress}>
						<h5>{virtualPlacement.name}</h5>
						<h5>{virtualPlacement.address}</h5>
					</div>
				</div>
				<AnimatePresence>
					{advFormats.map((format) => (
						<DistrictFormatEditCard
							setExternalTypesState={handleSetExternalTypeState}
							onEditVinstant={onEditVinstant}
							districtBookingId={districtBooking.id}
							key={format.id}
							format={format}
						/>
					))}
				</AnimatePresence>

				{is_docs_required && (
					<DocumentsUploadForm
						styles={{ justifySelf: 'flex-end' }}
						adFormatId={districtBooking.id}
						campaignId={campaign.id}
						type="districtbooking"
						title="Загрузить документы"
					/>
				)}
			</div>
			<DistrictBookingEditFooter
				dictrictBookingId={districtBooking.id}
				onClose={onClose}
				externalTypesState={externalTypesState}
				isLoading={isLoading}
			/>
		</div>
	)
}
