import React from 'react'
import s from './campaignReportTable.module.scss'
import { HeaderGroup } from 'react-table'
import { ReportTableData } from '../../types'

const headerCells = {
	firstRow: [
		{
			title: 'Формат рекламы',
			height: '96px',
			rowSpan: 2,
			width: '548px',
		},
		{
			title: 'Показы',
			colSpan: 2,
			height: '48px',
		},
		{
			title: 'Стоимость без НДС',
			colSpan: 2,
			height: '48px',
		},
	],
	secondRow: [
		{
			title: 'Прогнозные',
			colSpan: 1,
			height: '48px',
			rowSpan: 1,
		},
		{
			title: 'Фактические',
			height: '48px',
			colSpan: 1,
		},
		{
			title: 'Прогнозная',
			height: '48px',
			colSpan: 1,
		},
		{
			title: 'Фактическая',
			height: '48px',
			colSpan: 1,
		},
	],
}

type Props = {
	headerGroups: HeaderGroup<ReportTableData>[]
}
export const ReportTableHead = ({ headerGroups }: Props) => {
	return (
		<thead className={s.header}>
			<tr>
				{headerCells.firstRow.map((cell) => (
					<HeadCell
						colSpan={cell?.colSpan}
						title={cell.title}
						height={cell.height}
						key={cell.title}
						rowSpan={cell?.rowSpan}
						styles={{
							width: cell?.width,
						}}
					/>
				))}
			</tr>
			{/* Второй ряд заголовков */}
			<tr>
				{headerCells.secondRow.map((cell) => (
					<HeadCell
						colSpan={cell?.colSpan}
						title={cell.title}
						height={cell.height}
						key={cell.title}
						rowSpan={cell?.rowSpan}
					/>
				))}
			</tr>
		</thead>
	)
}

type HeadCellProps = {
	title: string
	height?: string
	styles?: React.CSSProperties
	colSpan?: number
	rowSpan?: number
}
const HeadCell = ({
	title,
	styles,
	colSpan,
	height,
	rowSpan,
}: HeadCellProps) => {
	return (
		<th
			className={s.headerCell}
			style={{ height, ...styles }}
			colSpan={colSpan}
			rowSpan={rowSpan}
		>
			<div className="w-100 h-100 d-flex align-items-start">
				<h6 className="m-0">{title}</h6>
			</div>
		</th>
	)
}
