import React from 'react'
import { Dropdown } from 'react-bootstrap'
import cn from 'classnames'
import s from './DetailItem.module.scss'

type IDetailItem = {
	left: string | number | React.ReactNode
	right: string | number | React.ReactNode
	bottomDivider?: boolean
	fontSize?: string
	leftSecondary?: boolean
	show?: boolean
	leftFixWidth?: false | string
	padding?: number
	ellipsis?: boolean
	breakWord?: boolean
	styles?: React.CSSProperties
}

type propsType = IDetailItem &
	Omit<
		React.DetailedHTMLProps<
			React.HTMLAttributes<HTMLDivElement>,
			HTMLDivElement
		>,
		keyof IDetailItem | 'className' | 'style'
	>

const DetailItem = ({
	left,
	right,
	bottomDivider = true,
	fontSize = '14px',
	leftSecondary = true,
	show = true,
	leftFixWidth = false,
	padding = 2,
	ellipsis,
	breakWord,
	...other
}: propsType) => {
	if (!show) return null
	const baseClass = leftFixWidth
		? `d-flex w-100 pt-${padding} pb-${padding}`
		: `d-flex justify-content-between w-100 pt-${padding} pb-${padding}`
	return (
		<>
			<div
				className={cn(baseClass)}
				{...other}
				style={{
					borderBottom: bottomDivider ? '1px solid #CED4DA' : 'none',
					...other.styles,
				}}
			>
				<div
					className={leftSecondary ? 'text-secondary' : ''}
					style={
						leftFixWidth
							? { width: leftFixWidth, minWidth: leftFixWidth }
							: {}
					}
				>
					{left}
				</div>
				<div
					style={{
						...(ellipsis
							? {
									overflow: 'hidden',
									textOverflow: 'ellipsis',
							  }
							: {}),
						...(breakWord
							? {
									wordBreak: 'break-word',
							  }
							: {}),
						...(leftFixWidth
							? {
									flex: '1 1 auto',
									whiteSpace: 'break-spaces',
							  }
							: { textAlign: 'right' }),
					}}
				>
					{right}
				</div>
			</div>
		</>
	)
}

export function DetailItemNew({
	left,
	right,
	noBorder,
	size,
}: {
	left: any
	right: any
	noBorder?: boolean
	size?: 16
}) {
	return (
		<div
			className={cn(s.item, {
				[s.noBorder]: noBorder,
				[s[`size-${size}`]]: size,
			})}
		>
			<div className={s.left}>{left}</div>
			<div className={s.right}>{right}</div>
		</div>
	)
}

export default DetailItem
