import { FormikErrors } from 'formik'
import React, { useMemo, useState } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import cn from 'classnames'
import s from './modals.module.scss'
import {
	Manager,
	NotificationCategory,
	NotificationDetails,
	NotificationModel,
} from 'src/components/_moderator/types/ModerationRequestTypes'
import { SingleValue } from 'react-select'
import { SubmitValue } from 'src/components/_moderator/types/SubmitValue'
import { NotificationEditContent } from './NotificationEditContent'
import useResponsive from 'src/utils/useResponsive'
import MobileModal from 'src/components/UtilityComponents/MobileModal'

type OptionType = {
	value: string
	label: string
}

type Props = {
	updateDraftNotification: (values: Partial<SubmitValue>) => Promise<void>
	onSubmit: (values: SubmitValue) => void
	onClose: () => void
	onDelete: () => void
	notification: (NotificationModel & NotificationDetails) | undefined
	open: boolean
	mode: 'edit' | 'create'
	isLoading: boolean
	managers: Manager[]
}

type FormType = {
	header: string
	text: string
	senderType: 'client' | 'adv_company' | 'adv_type'
	senderItems: string[]
	manager: SingleValue<OptionType> | undefined
}
const requiredFieldMessage = 'Обязательное поле'

export const NotificationEdit = ({
	notification,
	onClose,
	open,
	mode,
	onSubmit,
	onDelete,
	isLoading,
	updateDraftNotification,
	managers: managersList,
}: Props) => {
	const { isMobile } = useResponsive()

	const [closeNeeded, setCloseNeeded] = useState(false)

	const handleSubmit = (values: {
		header: string
		text: string
		senderType: NotificationCategory
		senderItems: string[]
		manager: SingleValue<OptionType> | undefined
	}) => {
		onSubmit(values)
	}

	const allManagers = useMemo(() => {
		return managersList.map((el) => ({
			label: `${el?.first_name} ${el.last_name}`,
			value: el.id,
		}))
	}, [managersList])

	const defaultManager = useMemo((): SingleValue<OptionType> | undefined => {
		if (notification?.owner && allManagers.length > 0) {
			const manager = allManagers.find(
				(el) => el.value === notification.owner
			)
			return manager
		}
	}, [notification, allManagers])

	const handleManagerChange = (
		manager: SingleValue<OptionType>,
		setFieldValue: (
			field: string,
			value: SingleValue<OptionType>,
			shouldValidate?: boolean | undefined
		) => void
	) => {
		if (manager) {
			setFieldValue('manager', manager)
		}
	}

	const handleSenderItemsChange = (
		items: string[],
		setFieldValue: (
			field: string,
			value: any,
			shouldValidate?: boolean | undefined
		) => void
	) => {
		setFieldValue('senderItems', items)
	}

	const getInitialItems = () => {
		switch (notification?.category) {
			case 'adv_company':
				return notification?.extra_data.state_adv_company
			case 'adv_type':
				return notification?.extra_data.type_adv_company
			case 'client':
			case 'related_item':
				return Object.keys(notification?.extra_data.company_names)
			default:
				return []
		}
	}

	const getInitialErrors = (): FormikErrors<FormType> | undefined => {
		const initialItems = getInitialItems()

		return {
			senderItems:
				initialItems.length === 0 ? requiredFieldMessage : undefined,
			header: !notification?.title ? requiredFieldMessage : undefined,
			text: !notification?.description ? requiredFieldMessage : undefined,
			manager: !notification?.owner ? requiredFieldMessage : undefined,
		}
	}
	const handleValidate = (
		values: FormType
	): void | object | Promise<FormikErrors<FormType>> => {
		let errors: FormikErrors<FormType> = {}

		if (!values.header.trim().length) {
			errors.header = requiredFieldMessage
		}
		if (!values.text.trim().length) {
			errors.text = requiredFieldMessage
		}
		if (!values.manager) {
			errors.manager = requiredFieldMessage
		}
		if (values.senderItems.length === 0) {
			errors.senderItems = requiredFieldMessage
		}

		return errors
	}

	return isMobile ? (
		<MobileModal
			show={open}
			onHide={() => setCloseNeeded(true)}
			title={
				mode === 'edit'
					? 'Редактировать уведомление'
					: 'Создать уведомление'
			}
			paddingContent={'0'}
			contentStyles={{ display: 'flex', paddingBottom: '0.5rem' }}
			enableTouching={false}
			defaultTouchingSetup={false}
		>
			{!notification ? (
				<div
					style={{
						height: '100%',
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Spinner animation="border" style={{ color: '#007BFF' }} />
				</div>
			) : (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						maxHeight: '100%',
						width: '100%',
					}}
				>
					<NotificationEditContent
						onClose={onClose}
						allManagers={allManagers}
						updateDraftNotification={updateDraftNotification}
						errors={getInitialErrors()}
						manager={defaultManager}
						notification={notification}
						closeNeeded={closeNeeded}
						onManagerChange={handleManagerChange}
						onSenderItemsChange={handleSenderItemsChange}
						onSubmit={handleSubmit}
						senderItems={getInitialItems()}
						validate={handleValidate}
						isLoading={isLoading}
						mode={mode}
						onDelete={onDelete}
					/>
				</div>
			)}
		</MobileModal>
	) : (
		<Modal
			backdrop={'static'}
			keyboard={false}
			show={open}
			onHide={() => setCloseNeeded(true)}
			centered
			size={'sm'}
			animation={true}
			contentClassName={cn({
				[s.invisibleModal]: !notification,
			})}
		>
			{!notification ? (
				<Spinner animation="border" style={{ color: '#007BFF' }} />
			) : (
				<>
					<Modal.Header closeButton>
						<Modal.Title
							as={'h5'}
							className={'ms-2 d-flex align-items-center'}
						>
							{mode === 'edit'
								? 'Редактировать уведомление'
								: 'Создать уведомление'}
						</Modal.Title>
					</Modal.Header>
					<NotificationEditContent
						allManagers={allManagers}
						errors={getInitialErrors()}
						manager={defaultManager}
						notification={notification}
						closeNeeded={closeNeeded}
						onClose={onClose}
						updateDraftNotification={updateDraftNotification}
						onManagerChange={handleManagerChange}
						onSenderItemsChange={handleSenderItemsChange}
						onSubmit={handleSubmit}
						senderItems={getInitialItems()}
						validate={handleValidate}
						isLoading={isLoading}
						mode={mode}
						onDelete={onDelete}
					/>
				</>
			)}
		</Modal>
	)
}
