import React from 'react'
import s from './passReset.module.scss'

type Props = {
	toRegister: () => void
	toLogin: () => void
}
export const PassResetFooter = ({ toLogin, toRegister }: Props) => {
	return (
		<div className={s.passResetFooter}>
			<span onClick={toRegister}>Зарегистрироваться</span>
			<p>или</p>
			<span onClick={toLogin}>Войти</span>
		</div>
	)
}
