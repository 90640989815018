import React from 'react'
import { CardWrapper } from '../../../../../common/card-wrapper/CardWrapper'
import YandexDirectAd from 'src/components/_client/create-campaign/Step2-ADformats/InternetAD/YandexDirectAd'

export const Yandex = () => {
	return (
		<CardWrapper>
			<YandexDirectAd />
		</CardWrapper>
	)
}
