import React, { useMemo } from 'react'
import {
	MobileFilters,
	MobileFiltersKeyType,
	ProviderBookingQueryParams,
} from '../../booking-filters-mobile/BookingsSettings'
import useProviderData from 'src/stores/providerData'
import { usePushObjectToQueryParams } from 'src/utils'
import { BookingCampaingFilterContent } from './contents/BookingCampaingFilterContent'
import { BookingCompanyFilterContent } from './contents/BookingCompanyFilterContent'

type BookingFiltersContentProps = {
	filterKey: MobileFiltersKeyType | undefined
	onClose: () => void
	fetchFiltredBookings: (
		queryParams: Partial<ProviderBookingQueryParams>
	) => Promise<void>
}

export const BookingFiltersContent = ({
	filterKey,
	onClose,
	fetchFiltredBookings,
}: BookingFiltersContentProps) => {
	const onFilterSubmit = async (
		queryParams: Partial<ProviderBookingQueryParams>
	) => {
		fetchFiltredBookings(queryParams)

		onClose()
	}

	const filterContent = useMemo(() => {
		switch (filterKey) {
			case 'adv_companies':
				return (
					<BookingCampaingFilterContent
						onClose={onClose}
						onFilterSubmit={onFilterSubmit}
					/>
				)
			case 'company':
				return (
					<BookingCompanyFilterContent
						onClose={onClose}
						onFilterSubmit={onFilterSubmit}
					/>
				)
			default:
				return <></>
		}
	}, [filterKey])

	return filterContent
	// <BookingCampaingFilterContent
	// 	onClose={onClose}
	// 	onFilterSubmit={onFilterSubmit}
	// />
}
