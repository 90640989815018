import React from 'react'
import s from './templateImage.module.scss'
import cn from 'classnames'
import { CreativeTemplateKeysType } from '../../../../constants'
import { getSize } from './TemplateImage'
import { ReactComponent as DesignerHelpIcon } from 'src/assets/images/design.svg'

type DesignerHelpProps = {
	onClick: () => void
	screenResolution: CreativeTemplateKeysType
}
export const DesignerHelp = ({
	onClick,
	screenResolution,
}: DesignerHelpProps) => {
	return (
		<div
			onClick={onClick}
			className={cn(s.image_container, s.yourTemlateForm)}
			style={{
				...getSize(screenResolution),
			}}
		>
			<DesignerHelpIcon />
			<p
				style={{
					fontSize: '16px',
					fontWeight: 500,
					lineHeight: '20px',
					letterSpacing: '-0.03em',
				}}
			>
				Заказать макет
			</p>
		</div>
	)
}
