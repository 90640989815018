import React from 'react'
import { Button } from 'react-bootstrap'
import Tooltip from 'src/components/UtilityComponents/Tooltip'
import { ReactComponent as EditIcon } from 'src/assets/images/edit.svg'
import s from './editVinstantInputButton.module.scss'

type Props = {
	onClick: () => void
	disabled?: boolean
	variant?: string
	styles?: React.CSSProperties
}

export const EditVinstantInputButton = ({
	onClick,
	disabled,
	variant = 'text',
	styles,
}: Props) => {
	return (
		<Tooltip text="Редактировать материал" placement="left">
			<Button
				disabled={disabled}
				variant={variant}
				onClick={onClick}
				className={s.button}
				style={styles}
			>
				<EditIcon />
			</Button>
		</Tooltip>
	)
}
