import React, { useEffect, useRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import ProviderYandexMap from '../../UtilityComponents/ProviderYandexMap'
import useDebounce from '../../../utils/useDebounce'
import { AutocompleteInput } from '../../UtilityComponents/AutocompleteInput'
import { AnimatePresence, motion } from 'framer-motion'

interface IProviderPlacementPicker {
	show: boolean
	handleClose: () => void
	errors: any
	currentCoords?: any[] | null
	setFieldValue?: any
}

const ProviderPlacementPicker: React.FC<IProviderPlacementPicker> = ({
	show,
	handleClose,
	errors,
	currentCoords,
	setFieldValue,
	...props
}) => {
	const [adress, setAdress] = useState(currentCoords ? '' : 'Москва')
	const debouncedAddress = useDebounce(adress, 500)
	const [tmpCoords, setTmpCoords] = useState(
		currentCoords ? currentCoords : []
	)
	const [isAcceptButton, setIsAcceptButton] = useState(false)
	const debouncedIsAcceptButton = useDebounce(isAcceptButton, 100)
	const autoShowTimeout = useRef<ReturnType<typeof setTimeout>>()
	useEffect(() => {
		if (debouncedIsAcceptButton)
			return clearTimeout(autoShowTimeout.current as NodeJS.Timeout)

		autoShowTimeout.current = setTimeout(() => {
			setIsAcceptButton(true)
		}, 3000)
	}, [debouncedIsAcceptButton])
	return (
		<Modal show={show} onHide={handleClose} fullscreen animation={false}>
			<Modal.Header closeButton style={{ padding: '0.5rem' }}>
				<Modal.Title as={'h5'} className={'ms-2'}>
					Месторасположение рекламного носителя
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				className={'p-0'}
				style={{
					width: '100%',
					height: '100%',
					position: 'relative',
					overflow: 'hidden',
				}}
			>
				<ProviderYandexMap
					width={'100%'}
					height={'100%'}
					withZoomControls
					adress={debouncedAddress}
					onAddressFound={(d) => setTmpCoords(d)}
					fixed={false}
					zoom={13}
					currentPickerCoords={currentCoords || null}
					onAdressByCoords={(s) => setAdress(s)}
					setAcceptButtonState={setIsAcceptButton}
				/>
				<div
					style={{ position: 'absolute', top: '24px', left: '24px' }}
				>
					<AutocompleteInput
						value={adress}
						onChange={(e) => {
							setAdress(e.target.value)
						}}
						onSelect={(data) => {
							// clearError();
							setAdress(data.address)
							// setFieldValue("actual_location", data.address);
						}}
						// error={errors}
						type={'address'}
						name={'123'}
						witchFindIcon={true}
					/>
				</div>
				<AnimatePresence>
					{debouncedIsAcceptButton && (
						<motion.div
							style={{
								position: 'fixed',
								top: '110%%',
								left: '50%',
								transform: 'translate(-50%,-10%)',
							}}
							animate={{ top: '94%' }}
							initial={{ top: '110%' }}
							transition={{ type: 'spring', bounce: 0.4 }}
							exit={{ top: '110%' }}
						>
							<Button
								variant={'primary'}
								onClick={() => {
									setFieldValue('lat', tmpCoords[0])
									setFieldValue('lon', tmpCoords[1])
									handleClose()
								}}
							>
								Подтвердить выбор
							</Button>
						</motion.div>
					)}
				</AnimatePresence>
			</Modal.Body>
		</Modal>
	)
}

export default ProviderPlacementPicker
