import React, { FC, useState } from 'react'
import useCampaignData from '../../../../stores/campaignData'
import s from './InternetAd.module.scss'
import { DetailButton } from '../bookings/MobileBookingCard'
import InternetStateView from './InternetStateView'
import {
	VkIcon,
	YandexIcon,
} from '../../create-campaign/Step2-ADformats/InternetAD/InternetSelector'
import SmallBottomSheet from '../../../UtilityComponents/SmallBottomSheet'
import MobileInternetControls from './MobileInternetControls'
import IntlFormatter from '../../../../utils/intl-money-formatter'
import YandexDirectPreview from './internet-previews/YandexDirectPreview'
import VkPreview from './internet-previews/VkPreview'

interface IMobileInternetCard {
	name: 'yandex' | 'vk'
}

const MobileInternetCard: FC<IMobileInternetCard> = ({ name }) => {
	const [campaign] = useCampaignData()
	const [showBottomSheet, setShowBottomSheet] = useState(false)

	const internet =
		name === 'yandex' ? campaign.internet_yandex : campaign.internet_vk

	return (
		<div className={s.mobileInternetCard}>
			<DetailButton onClick={() => setShowBottomSheet(true)} />
			{name === 'yandex' && <YandexIcon />}
			{name === 'vk' && <VkIcon />}
			<h5 className={'mb-2 mt-2'}>
				{name === 'yandex' && 'Яндекс'}
				{name === 'vk' && 'VK Реклама'}{' '}
			</h5>
			<h5 className={'mb-3'}>
				{IntlFormatter.format(internet.amount || 0)}
			</h5>
			<InternetStateView internet={internet} name={name} />
			{name === 'yandex' && <YandexDirectPreview />}
			{name === 'vk' && <VkPreview />}{' '}
			<span
				className={'text-secondary w-100 text-center mt-2'}
				style={{ fontSize: '12px' }}
			>
				Итоговый вид объявления, размер и расположение может отличаться
			</span>
			{/*BottomSheet*/}
			<SmallBottomSheet
				show={showBottomSheet}
				onHide={() => setShowBottomSheet(false)}
				height={'170px'}
				touchHeight={'25px'}
			>
				<MobileInternetControls
					onClose={() => setShowBottomSheet(false)}
					internet={internet}
					name={name}
				/>
			</SmallBottomSheet>
		</div>
	)
}

export default MobileInternetCard
