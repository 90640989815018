export const AdStreamMediaRequirements = {
	video: [
		{ name: 'Формат:', value: 'MP4' },
		{ name: 'Формат видео:', value: 'H.264 / HP / 4:2:0' },
		{ name: 'Кодек:', value: 'h264 + aac' },
		{ name: 'Профиль кодека:', value: 'high' },
		{ name: 'Разрешение:', value: '1920 * 1080 px' },
		{ name: 'Частота кадров:', value: 'fps 25 (максимум 30!)' },
		{
			name: 'GOP фиксированный:',
			value: 'fps*3 (например, если частота кадров 25, то gop — 75)',
		},
		{
			name: 'Опция iframe автоматического определения сцены:',
			value: 'выключена',
		},
		{ name: 'Соотношение сторон:', value: '16:9' },
		{ name: 'Битрейт:', value: ' 2-4 Мбит/с (лучше CBR)' },
	],
	audio: [
		{ name: 'Кодек:', value: 'HE-AAC v1' },
		{
			name: 'Формат сигнала Embedded in video:',
			value: 'Group / Pair 1 / 1',
		},
		{ name: 'Битрейт:', value: '96 Кбит/с' },
		{ name: 'Частота дискретизации:', value: '48 кГц' },
		{ name: 'Режим кодирования:', value: 'Stereo (2/0)' },
		{ name: 'Уровень LKFS:', value: '-23 ± 0.5' },
	],
}

export const TeletargetMediaRequirements = {}
