import React, { useEffect, useMemo, useState } from 'react'
import { Tabs } from 'src/components/UtilityComponents/tabs'
import {
	NotificationState,
	getNotificationsTabsWithCount,
} from 'src/components/_moderator/constants'
import { withPage } from 'src/components/page/Page'
import useHeaderTitle from 'src/components/page/useHeaderTitle'
import { usePushObjectToQueryParams, useQueryParams } from 'src/utils'
import { NotificationsContent } from './components'
import useModerationData from 'src/components/_moderator/store/moderationStore'

type NotificationModerationQueryParams = {
	state: NotificationState
	limit: string
	offset: string
	ordering: string
	q: string
}

const NotificationsModeration = (props: any) => {
	useHeaderTitle('Уведомления')
	const { params } =
		useQueryParams<Partial<NotificationModerationQueryParams>>()
	const pushObjectToQuery = usePushObjectToQueryParams()

	const [moder, moderInterface] = useModerationData()

	const [selectedTab, setSelectedTab] = useState<NotificationState>(() => {
		if (params.state) return params.state as NotificationState
		else return 'created'
	})
	const [paginationModel, setPaginationModel] = useState<{
		limit: number
		offset: number
	}>({ limit: 100, offset: 0 })

	const tabs = useMemo(
		() => getNotificationsTabsWithCount(moder.notificationCount),
		[moder.notificationCount, selectedTab]
	)

	const handleSetTab = (tabKey: NotificationState) => {
		setSelectedTab(tabKey)
		setSelectedNotificationsIds([])
		setPaginationModel({ limit: 100, offset: 0 })
	}

	const [selectedNotificationsIds, setSelectedNotificationsIds] = useState<
		number[]
	>([])

	useEffect(() => {
		const unionObject: Partial<NotificationModerationQueryParams> = {
			state: selectedTab,
			limit: String(paginationModel.limit),
			offset: String(paginationModel.offset),
		}

		Object.keys(unionObject)?.forEach((el) => {
			if (!unionObject[el]) delete unionObject[el]
		})
		const queryString = pushObjectToQuery(unionObject)

		moderInterface.getModerNotifications(queryString)
	}, [paginationModel, selectedTab])

	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				maxHeight: `calc(100vh - 65px)`,
				flexDirection: 'column',
			}}
		>
			<NotificationsContent
				setTab={handleSetTab}
				tabs={tabs}
				selectedTab={selectedTab}
				isDeleteActive={selectedNotificationsIds.length > 0}
				selectedNotificationsIds={selectedNotificationsIds}
				setSelectedNotificationsIds={setSelectedNotificationsIds}
				notificationsData={moder.notificationsList}
			/>
		</div>
	)
}

export default withPage({ noSidebar: false, isProvider: false })(
	NotificationsModeration
)
