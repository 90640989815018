import React from 'react'
import { ReactComponent as Notification } from '../../../../../../../assets/images/notification.svg'
import s from './common.module.scss'
import cn from 'classnames'
import { Button } from 'react-bootstrap'

type Props = {
	onCreate: () => void
}
export const NoNotifications = ({ onCreate }: Props) => {
	return (
		<div className={cn(s.noNotify)}>
			<Notification />
			<p>Нет ни одного уведомления</p>

			<Button onClick={onCreate} variant="primary">
				Создать уведомление
			</Button>
		</div>
	)
}
