import React, { useEffect, useRef } from 'react'
import s from './mapQuick.module.scss'

// @ts-ignore:next-line
export const ymaps = window?.ymaps

/**
 * Функция для создания кастомного макета иконки компании
 * @returns {any} Макет для иконки компании
 */
const createCompanyIconLayout = () => {
	const backgroundColor = '#212529'
	const svgColor = '#fff'

	const iconSvg = `
    <svg width="20" height="20" viewBox="0 0 24 24" fill="${svgColor}" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 7C9.79086 7 8 8.79086 8 11C8 13.2091 9.79086 15 12 15C14.2091 15 16 13.2091 16 11C16 8.79086 14.2091 7 12 7ZM10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13C10.8954 13 10 12.1046 10 11Z" fill="inherit"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C7.02944 2 3 6.02944 3 11C3 14.3868 4.96368 17.2529 6.91789 19.2071C7.90756 20.1968 8.93174 20.9907 9.78487 21.5427C10.2107 21.8182 10.6063 22.0415 10.9442 22.2002C11.1127 22.2792 11.2794 22.3482 11.437 22.3993C11.5772 22.4447 11.7809 22.5 12 22.5C12.2191 22.5 12.4228 22.4447 12.563 22.3993C12.7206 22.3482 12.8873 22.2792 13.0558 22.2002C13.3937 22.0415 13.7893 21.8182 14.2151 21.5427C15.0683 20.9907 16.0924 20.1968 17.0821 19.2071C19.0363 17.2529 21 14.3868 21 11C21 6.02944 16.9706 2 12 2ZM5 11C5 7.13401 8.13401 4 12 4C15.866 4 19 7.13401 19 11C19 13.6132 17.4637 15.9971 15.6679 17.7929C14.7826 18.6782 13.8692 19.3843 13.1286 19.8636C12.7576 20.1036 12.4422 20.2788 12.206 20.3897C12.1207 20.4297 12.0521 20.4583 12 20.4778C11.9479 20.4583 11.8794 20.4297 11.794 20.3897C11.5578 20.2788 11.2424 20.1036 10.8714 19.8636C10.1308 19.3843 9.21744 18.6782 8.33211 17.7929C6.53632 15.9971 5 13.6132 5 11ZM11.9067 20.5081L11.9112 20.5071C11.9082 20.5078 11.9067 20.5081 11.9067 20.5081ZM12.0933 20.5081L12.0888 20.5071L12.091 20.5076L12.0933 20.5081Z" fill="inherit"/>
    </svg>`

	return ymaps.templateLayoutFactory.createClass(
		`<div style="
			background: ${backgroundColor};
			color: white;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			box-shadow: 0 2px 5px rgba(0,0,0,0.3);
			font-size: 16px;
			font-weight: bold;">
			 ${iconSvg}
		</div>`
	)
}

/**
 * Функция для создания кастомного макета иконок бронирований
 * @param {string} bookingsType - Тип бронирования ('indoor' или 'outdoor')
 * @returns {any} Макет для иконки бронирования
 */
const createBookingIconLayout = (bookingsType: 'indoor' | 'outdoor') => {
	const backgroundColor = '#007BFF'
	const svgColor = '#fff'

	// SVG strings for the icons
	const OutdoorSvgString = `<svg width="20" height="20" viewBox="0 0 24 24" fill="${svgColor}" xmlns="http://www.w3.org/2000/svg">
	  <path fill-rule="evenodd" clip-rule="evenodd" d="M5 13V7H19V13H13H11H5ZM11 15H4C3.44772 15 3 14.5523 3 14V6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6V14C21 14.5523 20.5523 15 20 15H13V21H11V15Z" fill="inherit"/>
	</svg>`

	const IndoorSvgString = `<svg width="20" height="20" viewBox="0 0 24 24" fill="${svgColor}" xmlns="http://www.w3.org/2000/svg">
	  <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16V5H16V16H13H11H8ZM11 18H7C6.44772 18 6 17.5523 6 17V4C6 3.44772 6.44772 3 7 3H17C17.5523 3 18 3.44772 18 4V17C18 17.5523 17.5523 18 17 18H13V21H11V18Z" fill="inherit"/>
	</svg>`

	// Choose the correct SVG string based on the booking type
	const iconSvg =
		bookingsType === 'indoor' ? IndoorSvgString : OutdoorSvgString

	// Create the custom layout for the icon
	return ymaps.templateLayoutFactory.createClass(
		`<div style="
		background: ${backgroundColor};
		color: white;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		box-shadow: 0 2px 5px rgba(0,0,0,0.3);
		font-size: 14px;
		font-weight: bold;
		position: relative;">
		<div style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
		  ${iconSvg} 
		</div>
	  </div>`
	)
}

/**
 * Функция для добавления маркера компании на карту
 * @param {any} geoCollection - Коллекция объектов карты
 * @param {object} companyCoord - Координаты компании
 * @param {any} iconLayout - Макет для иконки компании
 */
const addCompanyPlacemark = (
	geoCollection: any,
	companyCoord: { lat: number; lon: number },
	iconLayout: any
) => {
	const companyPlacemark = new ymaps.Placemark(
		[companyCoord.lat, companyCoord.lon],
		{
			hintContent: 'Адрес вашего бизнеса',
			balloonContent: 'Адрес вашего бизнеса',
		},
		{
			iconLayout,
			iconShape: {
				type: 'Circle',
				coordinates: [20, 20],
				radius: 20,
			},
		}
	)
	geoCollection.add(companyPlacemark)
}

/**
 * Функция для добавления маркеров бронирований на карту
 * @param {any} geoCollection - Коллекция объектов карты
 * @param {Array} bookingsCoord - Массив координат бронирований
 * @param {any} iconLayout - Макет для иконки бронирований
 */
const addBookingPlacemarks = (
	geoCollection: any,
	bookingsCoord: { lat: number; lon: number; title: string }[],
	iconLayout: any
) => {
	bookingsCoord.forEach(({ lat, lon, title }, index) => {
		const bookingPlacemark = new ymaps.Placemark(
			[lat, lon],
			{
				hintContent: title,
				balloonContent: title,
				index: index + 1,
			},
			{
				iconLayout,
				iconShape: {
					type: 'Circle',
					coordinates: [15, 15],
					radius: 15,
				},
			}
		)
		geoCollection.add(bookingPlacemark)
	})
}

type Props = {
	companyCoord?: { lat: number; lon: number }
	bookingsCoord: { lat: number; lon: number; title: string }[]
	bookingsType: 'indoor' | 'outdoor'
	styles?: React.CSSProperties
	showOnMapClick?: () => void
}
export const BookingsMapQuickView = ({
	bookingsCoord,
	bookingsType,
	companyCoord,
	styles,
	showOnMapClick,
}: Props) => {
	const mapContainerRef = useRef<HTMLDivElement | null>(null)

	const startCenter = bookingsCoord.length
		? [bookingsCoord[0].lat, bookingsCoord[0].lon]
		: [0, 0]
	useEffect(() => {
		if (!ymaps || !mapContainerRef.current) return

		ymaps.ready(() => {
			const map = new ymaps.Map(
				mapContainerRef.current,
				{
					center: startCenter,
					zoom: 10,
					controls: [],
				},
				{
					suppressMapOpenBlock: true, // Подавляем блок открытия карты в приложении
					copyrightsProvider: false, // Отключаем копирайты
				}
			)

			if (showOnMapClick) {
				// Создаем кастомную кнопку
				const customButton = new ymaps.control.Button({
					data: {
						content: 'Смотреть на карте', // Текст кнопки
						title: 'Нажмите, чтобы выполнить действие', // Всплывающая подсказка
					},
					options: {
						layout: ymaps.templateLayoutFactory.createClass(
							// Если кнопка не нажата, применяется CSS стиль 'myButton'.
							// Если кнопка нажата, к ней применятся CSS-стили 'myButton' и 'myButtonSelected'.

							`<div class='${s.openFullMapButton}' title='{{ data.title }}'>` +
								'{{ data.content }}' +
								'</div>'
						),
						selectOnClick: false, // Если true, кнопка будет переключаемой
						maxWidth: 150,
						position: { bottom: 10, left: 10 }, // Позиция кнопки на карте
					},
				})

				// Добавляем обработчик клика для кнопки
				customButton.events.add('click', () => {
					showOnMapClick()
				})
				// Добавляем кнопку на карту
				map.controls.add(customButton)
			}

			const geoCollection = new ymaps.GeoObjectCollection()

			if (companyCoord) {
				// Создаем и добавляем иконку компании
				const companyIconLayout = createCompanyIconLayout()
				addCompanyPlacemark(
					geoCollection,
					companyCoord,
					companyIconLayout
				)
			}

			// Создаем и добавляем иконки бронирований
			const bookingIconLayout = createBookingIconLayout(bookingsType)
			addBookingPlacemarks(
				geoCollection,
				bookingsCoord,
				bookingIconLayout
			)

			// Добавляем коллекцию объектов на карту
			map.geoObjects.add(geoCollection)

			// Устанавливаем границы карты, чтобы вместить все объекты
			const bounds = geoCollection.getBounds()
			if (bounds) {
				map.setBounds(bounds, {
					checkZoomRange: true,
					zoomMargin: [20],
				})
			}
		})

		// Очистка карты при размонтировании компонента
		return () => {
			if (mapContainerRef.current) {
				mapContainerRef.current.innerHTML = ''
			}
		}
	}, [bookingsCoord, bookingsType, companyCoord])

	return <div ref={mapContainerRef} style={styles} />
}
