import React from 'react'
import { Form } from 'react-bootstrap'
import s from '../common.module.scss'
import cn from 'classnames'

type Props = {
	isSelected: boolean
	count: number
	title: string
	onChange: () => void
}
export const AdvChoisesItem = ({
	count,
	isSelected,
	title,
	onChange,
}: Props) => {
	return (
		<div
			className={cn(s.choisesItem, {
				[s.choisesItem_disabled]: count === 0,
			})}
			onClick={onChange}
		>
			<Form.Check
				onClick={onChange}
				checked={isSelected}
				type={'checkbox'}
				id={`${title}-choises-item`}
				label={`${title}`}
				disabled={count === 0}
			/>
			<p>{count}</p>
		</div>
	)
}
