import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useResponsive from 'src/utils/useResponsive'
import {
	GetBackgroundColorByStateAndActive,
	PlacementBadge,
} from '../../PlacementsPage'
import { ReactComponent as Arrow } from '../../../../../assets/images/arrow.svg'
import { GarantidItemMenu } from 'src/components/common/garantid-card/garantid-media-format-card/components/garantid-item/GarantidItemMenu'
import { Dropdown } from 'react-bootstrap'
import s from '../../../../common/garantid-card/garantid-media-format-card/components/garantid-item/garantidItem.module.scss'
import classNames from 'classnames'
export const RenderListItem = ({
	image,
	title,
	size,
	media_name,
	status,
	cn,
	selectedItem,
	setSelectedItem,
	setBottomModal,
	id,
	isTablet,
	activation_date,
	is_active,
	onDisable,
	onEnable,
	onDelete,
	onArchive,
	onFromArchive,
	in_archive,
	onEdit,
}) => {
	const { isDesktop, isMobile } = useResponsive()
	const [hover, setHover] = useState(false)
	const selected = id?.toString() === selectedItem?.toString()
	// const used = status === 'used'
	return (
		<div style={{ borderBottom: '1px solid #CED4DA', display: 'flex' }}>
			{/* {!isMobile && (
				<ItemCheckbox checked={false} onCheckBoxClick={() => {}} />
			)} */}
			<div
				className={cn('rekl-item', {
					selected: selected,
				})}
				onClick={() => {
					setSelectedItem(id.toString())
					setBottomModal(true)
				}}
				style={{
					cursor: 'pointer',
					position: 'relative',
				}}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
			>
				<div className={'first_row'}>
					<div
						className="image_container"
						dangerouslySetInnerHTML={{ __html: image }}
						style={{
							backgroundColor: GetBackgroundColorByStateAndActive(
								status,
								is_active
							),
						}}
					/>
					<div
						className={'data'}
						style={{
							cursor: 'pointer',
							opacity: !is_active ? 0.5 : 1,
						}}
					>
						<span>{id}</span>
						<span>
							{media_name}, {size}
						</span>
					</div>
					<div className={'badge'} style={{ opacity: 1 }}>
						<PlacementBadge
							status={status}
							is_active={is_active}
							activation_date={activation_date}
							hover={hover}
						/>
					</div>

					{isTablet && (
						<div
							className={
								'd-flex justify-content-center align-items-center'
							}
						>
							<Arrow
								fill={'#000'}
								style={{
									transform: 'rotate(180deg)',
									marginLeft: '30px',
								}}
							/>
						</div>
					)}
				</div>

				<div className="d-flex justify-content-between">
					<h6>{title}</h6>
					{isDesktop && (
						<GarantidItemMenu>
							{!in_archive && (
								<Dropdown.Item
									eventKey="1"
									className={classNames(
										s.garantidmMenuItem,
										s.garandidCampaignMenuItem_bordered
									)}
									onClick={() =>
										is_active ? onDisable(id) : onEnable(id)
									}
								>
									{is_active ? (
										<i className="bi bi-dash-circle" />
									) : (
										<i className="bi bi-plus-circle" />
									)}
									<p>
										{is_active ? 'Выключить' : 'Включить'}
									</p>
								</Dropdown.Item>
							)}

							{status !== 'used' && !in_archive && (
								<Dropdown.Item
									eventKey="2"
									className={classNames(s.garantidmMenuItem, {
										[s.garandidCampaignMenuItem_bordered]:
											status === 'used',
									})}
									onClick={() => onEdit(id)}
								>
									<i className="bi bi-pencil" />
									<p>Изменить</p>
								</Dropdown.Item>
							)}
							{status !== 'used' && !in_archive && (
								<Dropdown.Item
									eventKey="3"
									className={classNames(s.garantidmMenuItem, {
										[s.garandidCampaignMenuItem_bordered]:
											status === 'used',
									})}
									onClick={() => onArchive(id)}
								>
									<i className="bi bi-trash" />
									<p>Удалить</p>
								</Dropdown.Item>
							)}
							{in_archive && (
								<Dropdown.Item
									eventKey="4"
									className={classNames(s.garantidmMenuItem)}
									onClick={() => onFromArchive(id)}
								>
									<i className="bi bi-box-arrow-up" />
									<p>Восстановить</p>
								</Dropdown.Item>
							)}
							{in_archive && (
								<Dropdown.Item
									eventKey="5"
									className={classNames(s.garantidmMenuItem)}
									onClick={() => onDelete(id)}
								>
									<i className="bi bi-trash"></i>
									<p>Удалить навсегда</p>
								</Dropdown.Item>
							)}
						</GarantidItemMenu>
					)}
				</div>
			</div>
		</div>
	)
}
