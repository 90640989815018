import React, { FC } from 'react'
import s from './TvAd.module.scss'
import useCampaignData from '../../../../stores/campaignData'
import IntlFormatter from '../../../../utils/intl-money-formatter'
import { TvType } from './TvStateView.types'
import { AdstreamItemWrapper } from './adstream/AdstreamItemWrapper'
import { TeletargetItem } from './teletarget/TeletargetItem'

const TvAd: FC = () => {
	const [campaign] = useCampaignData()
	return (
		<div className={s.tvContainer}>
			{campaign.adstream.is_selected && <AdstreamItemWrapper />}
			{campaign.teletarget.is_selected && (
				<TeletargetItem name={TvType.TELETARGET} />
			)}
			<div className={s.tvBudget}>
				<h5 className={'mb-0'}>Всего за ТВ-рекламу:</h5>
				<h5 className={'mb-0'}>
					{IntlFormatter.format(
						(Number(campaign.teletarget.amount) || 0) +
							(Number(campaign.adstream.amount) || 0)
					)}
				</h5>
			</div>
		</div>
	)
}

export default TvAd
