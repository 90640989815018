import { useLocation } from 'react-router-dom'

export const useQueryParams = <T = {}>(): { params: T; pathName: string } => {
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search)
	const queryParams = {} as T

	for (let [key, value] of searchParams.entries()) {
		if (queryParams[key]) {
			if (Array.isArray(queryParams[key])) {
				queryParams[key].push(value)
			} else {
				queryParams[key] = [queryParams[key], value]
			}
		} else {
			queryParams[key] = value
		}
	}

	return { params: queryParams, pathName: location.pathname }
}
