import React, { useMemo, useState } from 'react'
import s from './CampaignList.module.scss'
import { Badge } from 'react-bootstrap'
import { ArrowIcon } from '../../page/Profile/Profile'
import BottomSheet from '../../UtilityComponents/Bottomsheet'
import { current } from 'immer'

//TODO надо сделать тип и поменять некорректные имена свойств в других имплементациях
type MobileTabsDropDownProps = {}
const MobileTabsDropDown = ({ tabs, setSelectedTab, selectedTab }) => {
	const [isOpen, setIsOpen] = useState(false)
	const currentTab = useMemo(() => {
		return tabs.find(
			(tab) => tab?.title === selectedTab || tab?.key === selectedTab
		)
	}, [tabs, selectedTab])

	return (
		<>
			<div className={s.mobileTabToggle} onClick={() => setIsOpen(true)}>
				<div className={s.currentMobileTab}>
					{currentTab?.display || currentTab?.title}
					<Badge bg={'primary'}>{currentTab.count}</Badge>
				</div>
				<ArrowIcon open={isOpen} />
			</div>

			<BottomSheet
				fullSize={false}
				show={isOpen}
				onHide={() => setIsOpen(false)}
				touchEvents={false}
			>
				<div style={{ marginTop: '8px' }}>
					{tabs.map((tab) => (
						<div
							key={tab.title}
							onClick={() => {
								setSelectedTab(tab?.key || tab?.title)
								setIsOpen(false)
							}}
							style={
								tab.title === selectedTab
									? {
											backgroundColor: '#f5f5f5',
											color: '#007BFF',
											padding: '8px 16px',
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
									  }
									: {
											padding: '8px 16px',
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'center',
									  }
							}
						>
							<div>
								<div className={s.MobileTab + ' noselect'}>
									{tab.display || tab.title}
									<Badge
										bg={
											selectedTab === tab.title ||
											selectedTab === tab.key
												? 'primary'
												: 'secondary'
										}
									>
										{tab.count}
									</Badge>
								</div>
							</div>
							{(selectedTab === tab.title ||
								selectedTab === tab.key) && (
								<div>
									<svg
										width="12"
										height="10"
										viewBox="0 0 12 10"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fillRule="evenodd"
											clipRule="evenodd"
											d="M11.638 1.87347L4.63796 8.87347C4.37761 9.13382 3.9555 9.13382 3.69515 8.87347L0.361816 5.54014L1.30463 4.59733L4.16655 7.45926L10.6952 0.930664L11.638 1.87347Z"
											fill="#212529"
										/>
									</svg>
								</div>
							)}
						</div>
					))}
				</div>
			</BottomSheet>
		</>
	)
}
export default MobileTabsDropDown
