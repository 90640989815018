import { defaultPlacementImage } from 'src/constants/defaultPlacementImageUrl'
import { BookingDetailedReportPhoto, StatLog } from '../types'

export const getBookingPhotosByStatLog = (statLog: StatLog[]) => {
	let previewUrl = ''

	const statLogWithPhotoReport = statLog.find(
		(item) => item.photo_reports.length > 0
	)

	if (statLogWithPhotoReport) {
		const photoReport = statLogWithPhotoReport.photo_reports[0]

		previewUrl = Object.values(photoReport)[0] as string
	} else {
		previewUrl = defaultPlacementImage
	}

	// получить все фото из отчетов дней букинга
	const photos: BookingDetailedReportPhoto[] = statLog.map((stat) => {
		return {
			date: stat.date,
			images: stat.photo_reports.map((report) => {
				return {
					url: Object.values(report)[0] as string,
					id: Object.keys(report)[0] as unknown as number,
				}
			}),
		}
	})

	//общее количество фото загруженных для этого дня
	const photoCount = photos.reduce((acc, item) => {
		return acc + item.images.length
	}, 0)

	return {
		photoCount,
		photos,
		previewUrl,
	}
}
