import React from 'react'
import s from '.././bookingFilter.module.scss'
import cn from 'classnames'

type ProviderFilterCheckboxProps = {
	checked: boolean
	onChange: () => void
	text: string
}
export const ProviderFilterCheckbox = ({
	checked,
	onChange,
	text,
}: ProviderFilterCheckboxProps) => {
	return (
		<div onClick={onChange} className={cn(s.providerCheckbox)}>
			<input type="checkbox" checked={checked} />
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{text}</span>
			</div>
		</div>
	)
}
