import React from 'react'
import s from './common.module.scss'

type Props = {
	header: string
	text: string
	children?: React.ReactNode
}
export const InfoField = ({ header, text, children }: Props) => {
	return (
		<div className={s.infoField}>
			<span>{header}</span>
			<p>{text}</p>
			{children}
		</div>
	)
}
