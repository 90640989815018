import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import logo from 'src/assets/images/logo.svg'
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom'
import useUserData from 'src/stores/userData'
import ROUTES from 'src/constants/Routes'
import bem from 'src/utils/bem'
import { AgencyLogin } from './AgencyLogin'
import { AgencyPassRecovery } from './AgencyPassRecovery'
import { AgencyRegistration } from './AgencyRegistration'
import useResponsive from 'src/utils/useResponsive'
import { RouteCard } from '../common/ui/common/RouteCard'

const cn = bem('auth-page')

export default function AgencyAuthPage() {
	const [user] = useUserData()

	const { isMobile } = useResponsive()

	if (user.phone_number && user.is_phone_confirmed) {
		if (user?.is_moder) {
			return (
				<Redirect
					to={
						ROUTES.ROUTE_MODERATOR +
						ROUTES.ROUTE_CAMPAIGN_MODERATION
					}
				/>
			)
		}

		if (user.provider !== null) {
			return <Redirect to={ROUTES.ROUTE_PROVIDER} />
		}
		return <Redirect to={ROUTES.ROUTE_CAMPAIGNS} />
	}

	return (
		<div className={cn('')}>
			<Container
				className="d-flex flex-column justify-content-between align-items-center h-100"
				style={{ padding: isMobile ? '16px' : 'auto' }}
			>
				<div className={cn('mainContent')}>
					<div className={cn('cardContainer')}>
						<div className={cn('logo')}>
							<a href="https://admarket.ru/">
								<img src={logo} alt={'logo'} />
							</a>
						</div>

						<div className={cn('body')}>
							<HashRouter>
								<RouteCard>
									<Switch>
										<Route path={'/register'}>
											<AgencyRegistration />
										</Route>
										<Route path={'/login/:agencyAlias'}>
											<AgencyLogin />
										</Route>
										<Route path={'/recovery/:agencyAlias'}>
											<AgencyPassRecovery />
										</Route>
										<Route path={'/'}>
											<Redirect to={'/login'} />
										</Route>
									</Switch>
								</RouteCard>
							</HashRouter>
						</div>
					</div>
				</div>

				{!isMobile && (
					<div className={cn('footer')}>
						<p>© 2024 ООО «AdMarket»</p>{' '}
						<p style={{ fontSize: '6px' }}>•</p>
						<span>Политика обработки персональных данных</span>
					</div>
				)}
			</Container>
		</div>
	)
}
