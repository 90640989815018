import React, { FC } from 'react'
import RowLabelValue from '../../../utils/RowLabelValue'
import { ForeCast } from '../../../stores/ADMarketTypes.types'
import useCampaignData from '../../../stores/campaignData'
import declOfNum from '../../../utils/declOfNum'
import { spreadArray } from '../../_provider/Mediaformats/MediaInfo'
import removeSecondsFromTime from '../../../utils/removeSecondsFromTime'

interface ICampaignSummaryInfo {
	mediaplan: ForeCast
}
const compressedArrayLength = 8
const CampaignSummaryInfo: FC<ICampaignSummaryInfo> = ({ mediaplan }) => {
	const [campaign, campaignInterface] = useCampaignData()

	const getCompressedShowRegions = () => {
		if (campaign.show_regions.length > compressedArrayLength) {
			const compressedShowRegions = campaign.show_regions.slice(
				0,
				compressedArrayLength
			)
			return (
				compressedShowRegions.map((e) => e.title).join(', ') +
				` и еще ${
					campaign.show_regions.length - compressedArrayLength
				} других`
			)
		} else {
			return campaign.show_regions.map((e) => e.title).join(', ')
		}
	}
	return (
		<>
			<RowLabelValue label={'Название кампании'} value={mediaplan.name} />
			<RowLabelValue
				label={'Дата начала и окончания'}
				value={campaignInterface.getCampaignPeriod()}
			/>
			<RowLabelValue
				label={'Срок размещения'}
				value={
					campaign.days_duration +
					' ' +
					declOfNum(campaign.days_duration, ['день', 'дня', 'дней'])
				}
			/>
			<RowLabelValue
				label={'Регион показа'}
				value={getCompressedShowRegions()}
			/>
			<RowLabelValue
				label={'Время показа (по мск)'}
				value={`c ${removeSecondsFromTime(
					campaign.start_time
				)} по ${removeSecondsFromTime(campaign.end_time)}`}
			/>
			<RowLabelValue
				label={'Пол'}
				value={spreadArray(campaign.genders.map((e) => e.title))}
			/>
			<RowLabelValue
				label={'Возраст'}
				value={spreadArray(campaign.ages.map((e) => e.title))}
			/>
		</>
	)
}

export default CampaignSummaryInfo
