import React, { FC, useState } from 'react'
import s from './list.module.scss'
import { Form } from 'react-bootstrap'
import { useBookingsContext } from '../../context/BookingsProvider'
import { OrderingVariant } from '../../context/Context.types'

interface IListHeader {
	children?: React.ReactNode | React.ReactNode[]
	checked: boolean
	onToggle: () => void
}

const ListHeader: FC<IListHeader> = ({ onToggle, checked }) => {
	const { ordering, onOrderingTabClick } = useBookingsContext()
	const [showIcon, setShowIcon] = useState<
		false | 'placement_name' | 'state'
	>(() => {
		switch (ordering) {
			case OrderingVariant.STATE_DESC:
				return 'state'
			case OrderingVariant.STATE_ASC:
				return 'state'
			case OrderingVariant.NAME_DESC:
				return 'placement_name'
			case OrderingVariant.NAME_ASC:
				return 'placement_name'
			case OrderingVariant.NULL:
				return false
		}
	})

	return (
		<div className={s.listHeader}>
			<div onClick={onToggle} style={{ cursor: 'pointer' }}>
				<Form.Check checked={checked} onChange={(e) => {}} />
			</div>
			<section
				onClick={() => {
					onOrderingTabClick('placement_name')
				}}
				onMouseEnter={() => setShowIcon('placement_name')}
				onMouseLeave={() => setShowIcon(false)}
			>
				Название
				{showIcon === 'placement_name' && (
					<SortIcon
						ordering={ordering}
						isActive={
							ordering === OrderingVariant.NAME_DESC ||
							ordering === OrderingVariant.NAME_ASC
						}
					/>
				)}
			</section>
			<div
				onClick={() => {
					onOrderingTabClick('state')
				}}
				onMouseEnter={() => setShowIcon('state')}
				onMouseLeave={() => setShowIcon(false)}
			>
				Статус
				{showIcon === 'state' && (
					<SortIcon
						ordering={ordering}
						isActive={
							ordering === OrderingVariant.STATE_DESC ||
							ordering === OrderingVariant.STATE_ASC
						}
					/>
				)}
			</div>
		</div>
	)
}

const SortIcon = ({
	ordering,
	isActive,
}: {
	ordering: OrderingVariant
	isActive: boolean
}) => {
	const isASC =
		ordering === OrderingVariant.NAME_ASC ||
		ordering === OrderingVariant.STATE_ASC
	const isDesc =
		ordering === OrderingVariant.NAME_DESC ||
		ordering === OrderingVariant.STATE_DESC

	const leftColor = isActive ? (isASC ? '#212529' : '#6C757D') : '#6C757D'
	const rightColor = isActive ? (isDesc ? '#212529' : '#6C757D') : '#6C757D'
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.33355 11.0574L6.86214 9.52876L7.80495 10.4716L5.13828 13.1382C4.87793 13.3986 4.45582 13.3986 4.19548 13.1382L1.52881 10.4716L2.47162 9.52876L4.00021 11.0574L4.00021 3.3335L5.33355 3.3335L5.33355 11.0574Z"
				fill={leftColor}
			/>
			<path
				d="M10.862 2.86225L8.19531 5.52892L9.13812 6.47173L10.6667 4.94313L10.6667 12.667H12.0001L12.0001 4.94313L13.5286 6.47173L14.4715 5.52892L11.8048 2.86225C11.5444 2.6019 11.1223 2.6019 10.862 2.86225Z"
				fill={rightColor}
			/>
		</svg>
	)
}

export default ListHeader
