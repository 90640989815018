import React, { useEffect, useState } from 'react'
import useDebounce from '../../../../utils/useDebounce'
import { Form, InputGroup } from 'react-bootstrap'
import FormLabelTooltip from '../../../UtilityComponents/FormLabelTooltip'
import { TOOLTIPS } from '../tooltips.data'
import { AutocompleteInput } from '../../../UtilityComponents/AutocompleteInput'
import YandexMap from '../../../UtilityComponents/YandexMap'
import ModalYandexMap from '../../../UtilityComponents/ModalYandexMap'

function AddressPicker({
	value,
	error,
	setFieldValue,
	cn,
	clearError,
	name,
	isMobile,
	disabled = false,
	textarea = false,
}) {
	const [pendingAddress, setPendingAddress] = useState(value)
	const debouncedAddress = useDebounce(pendingAddress, 500)
	const [show, setShow] = useState(false)
	const [buisnessCoords, setBuisnessCoords] = useState<number[]>([])
	function handleAddressFound([lat, lon]: number[]) {
		setFieldValue('actual_location_lat', lat)
		setFieldValue('actual_location_lon', lon)
		setFieldValue('actual_location', pendingAddress)
		setBuisnessCoords([lat, lon])
	}
	useEffect(() => {
		setPendingAddress(value)
	}, [value])
	return (
		<>
			<InputGroup hasValidation>
				<Form.Label>
					Фактический адрес вашего бизнеса{' '}
					<FormLabelTooltip text={TOOLTIPS.STEP_0.LOCATION} />
				</Form.Label>
				<AutocompleteInput
					value={pendingAddress}
					onChange={(e) => {
						setPendingAddress(e.target.value)
					}}
					onSelect={(data) => {
						clearError()
						setPendingAddress(data.address)
						setFieldValue('actual_location', data.address)
					}}
					error={error}
					type={'address'}
					name={name}
					readOnly={disabled}
					disabled={disabled}
					textarea={textarea}
				/>
			</InputGroup>
			{!!debouncedAddress && (
				<div className={cn('actual-location-map')}>
					<YandexMap
						address={debouncedAddress}
						onAddressFound={handleAddressFound}
						height={200}
						width={'100%'}
						fixed={true}
						zoom={14}
						area={false}
						onClick={() => setShow(true)}
					/>
					<div
						className={
							'w-100 d-flex justify-content-center text-primary'
						}
						style={{ marginTop: '12px', cursor: 'pointer' }}
					>
						<a
							onClick={(e) => {
								setShow(true)
								e.preventDefault()
							}}
							href={'/'}
						>
							Смотреть на карте
						</a>
					</div>
					<ModalYandexMap
						show={show}
						onHide={() => setShow(false)}
						modalTitle={'Фактический адрес вашего бизнеса'}
						yMapProps={{
							width: '100%',
							height: '100%',
							area: false,
							withZoomControls: true,
							buisnessCoords: buisnessCoords,
							isMobile: isMobile,
						}}
						fullScreen={true}
						isMobile={isMobile}
					></ModalYandexMap>
				</div>
			)}
		</>
	)
}

export default AddressPicker
