import React, { FC, useMemo } from 'react'
import moment, { Moment } from 'moment'
import {} from '../DropdownDatePicker'
import { DayPickerSingleDateController } from 'react-dates'
import Dropdown from '../Dropdown'
import s from './ADMarketDateInput.module.scss'

interface IAdMarketDateInput {
	value: Moment | null
	onChangeValue: (newValue: Moment) => void
	preText: string
}

const now = moment()

const AdMarketDateInput: FC<IAdMarketDateInput> = ({
	value,
	onChangeValue,
	preText,
}) => {
	const formattedValue = useMemo<string>(() => {
		if (!value) return preText

		return preText + moment(value).format('D MMM YYYY')
	}, [value])
	return (
		<div>
			<Dropdown
				menu={({ close }) => (
					<div>
						<DayPickerSingleDateController
							date={value}
							onDateChange={onChangeValue}
							onFocusChange={() => {
								close()
							}}
							hideKeyboardShortcutsPanel
							focused
							isOutsideRange={(innerDate) => {
								return (
									moment
										.duration(innerDate.diff(now))
										.asDays() > 0
								)
							}}
						/>
					</div>
				)}
				trigger={
					<button className={s.dateInput} tabIndex={1}>
						<i className="bi bi-calendar2-event me-2"></i>
						{value ? (
							formattedValue
						) : (
							<>
								{formattedValue}
								<span className={'text-secondary'}>
									Выберите дату
								</span>
							</>
						)}
					</button>
				}
				zIndex={50}
				offset={1}
				triggerWidth={'fit-content'}
			></Dropdown>
		</div>
	)
}

export default AdMarketDateInput
