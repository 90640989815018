import React, { MouseEvent, MouseEventHandler } from 'react'
import { Alert } from 'react-bootstrap'
import s from './alert.module.scss'
import { RecommendationsApplyInput } from 'src/stores/types/recommendations'

type Props = {
	isVk: boolean
	isYandex: boolean
	errors: RecommendationsApplyInput
	applyData: RecommendationsApplyInput
}

export const InputAlert = ({ isVk, isYandex, errors, applyData }: Props) => {
	const handleLinkClick = (
		e: React.MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
	) => {
		e.preventDefault()

		const targetId = e.currentTarget.getAttribute('href')
		if (targetId) {
			const targetElement = document.querySelector(targetId)

			if (targetElement) {
				targetElement.scrollIntoView({
					block: 'center',
					behavior: 'smooth',
				})
			}
		}
	}
	return (
		<Alert className={s.inputAlert}>
			<h6 style={{ color: '#155724' }}>
				Для применения рекоммендаций заполните следущие поля:
			</h6>

			<div className={s.alertList}>
				{isVk && (
					<div className={s.alertItem}>
						<div>VK-реклама: </div>
						<div>
							<a onClick={handleLinkClick} href={`#vk_link`}>
								Рекламируемый сайт
							</a>
							{errors?.vk_link || !applyData.vk_link ? (
								<i className="bi bi-x text-danger" />
							) : (
								<i className="bi bi-check text-success" />
							)}
						</div>
					</div>
				)}

				{isYandex && (
					<div className={s.alertItem}>
						<div>Yandex-Директ: </div>
						<div>
							<a onClick={handleLinkClick} href="#yandex_link">
								Рекламируемый сайт
							</a>

							{errors?.yandex_link || !applyData.yandex_link ? (
								<i className="bi bi-x text-danger" />
							) : (
								<i className="bi bi-check text-success" />
							)}
						</div>
					</div>
				)}
			</div>
		</Alert>
	)
}
