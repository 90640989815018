export enum AdvFormats {
	Outdoor = 'outdoor',
	Indoor = 'indoor',
	PublicTransport = 'publicTransport',
	Internet = 'internet',
	TV = 'tv',
}
export enum VariantState {
	Enabled = 'enabled',
	Disabled = 'disabled',
}
