import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useProviderData from 'src/stores/providerData'
import { ProviderFilterCheckbox } from 'src/components/_provider/Bookings/filters/components/common/ProviderFilterCheckbox'
import { useQueryParams } from 'src/utils'
import {
	ProviderFilterContentBody,
	ProviderFilterContentFooter,
	ProviderFilterContentHeader,
} from 'src/components/_provider/Bookings'
import { PlacementFilterKeyType } from '../../constants/placementFilters'
import useResponsive from 'src/utils/useResponsive'

type ProviderCampaignsFilterContentProps = {
	onFilterSubmit: (key: PlacementFilterKeyType, value: Array<string>) => void
	onClose: () => void
	onChange?: (key: PlacementFilterKeyType, value: Array<string>) => void
	selectedItems?: Array<string>
}
type BaseProviderCampiagnsQueryType = {
	advertising_company: string | string[] | undefined
}
export const ProviderCampaignsFilterContent = ({
	onFilterSubmit,
	onClose,
	onChange,
	selectedItems,
}: ProviderCampaignsFilterContentProps) => {
	const [provider] = useProviderData()

	const { params, pathName } =
		useQueryParams<BaseProviderCampiagnsQueryType>()

	const [selectedCampaigns, setSelectedCampaigns] = useState(() => {
		if (selectedItems) return selectedItems

		if (params?.advertising_company) {
			if (Array.isArray(params?.advertising_company))
				return params?.advertising_company
			return [params?.advertising_company]
		} else {
			return []
		}
	})

	const availableCampaigns = provider?.bookings_filters?.adv_campaigns

	const [searchString, setSearchString] = useState('')

	const filtredCampaigns = useMemo(
		() =>
			availableCampaigns?.filter((el) =>
				el.name.toLowerCase().includes(searchString.toLowerCase())
			),
		[searchString, location, availableCampaigns]
	)

	const handleSelectedChange = (capmaignId: string) => {
		if (selectedCampaigns.includes(capmaignId)) {
			setSelectedCampaigns(
				selectedCampaigns.filter((el) => el !== capmaignId)
			)
		} else {
			setSelectedCampaigns([...selectedCampaigns, capmaignId])
		}
	}

	const handleFilterSubmit = () => {
		onFilterSubmit('advertising_company', selectedCampaigns)
	}
	const selectList = useMemo(
		() =>
			filtredCampaigns?.map((el) => (
				<ProviderFilterCheckbox
					checked={selectedCampaigns.includes(el.id)}
					onChange={() => handleSelectedChange(el.id)}
					text={el.name}
					key={el.id}
				/>
			)),
		[searchString, availableCampaigns, selectedCampaigns]
	)
	const handleChangeAll = useCallback(() => {
		if (selectedCampaigns?.length >= filtredCampaigns?.length) {
			setSelectedCampaigns([])
		} else {
			setSelectedCampaigns(
				filtredCampaigns?.map((campaign) => campaign.id)
			)
		}
	}, [selectedCampaigns, availableCampaigns, filtredCampaigns])

	useEffect(() => {
		onChange && onChange('advertising_company', selectedCampaigns)
	}, [selectedCampaigns])

	const { isMobile } = useResponsive()
	return (
		<div
			id={'provider-filter-content-wrapper'}
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				width: '100%',
			}}
		>
			<ProviderFilterContentHeader
				onClick={handleChangeAll}
				isAllSelected={
					selectedCampaigns.length >= filtredCampaigns?.length
				}
				count={selectedCampaigns.length}
				setSearchString={setSearchString}
			/>

			<ProviderFilterContentBody>
				<div>{selectList}</div>
			</ProviderFilterContentBody>

			{isMobile && (
				<ProviderFilterContentFooter
					onApplyFilters={handleFilterSubmit}
					onClose={onClose}
				/>
			)}
		</div>
	)
}
