import moment from 'moment'
import { Moment } from 'moment'
import React, { ChangeEvent, useEffect, useState } from 'react'
import DateInput from 'src/components/UtilityComponents/DropdownDatePicker'
import ImageFullScreenWrapper from 'src/components/UtilityComponents/ImageFullScreenWrapper'
import s from './addPhotoReportItemForm.module.scss'
import { Button, Spinner } from 'react-bootstrap'
import { ReactComponent as DowloadIcon } from 'src/assets/images/download.svg'
import cn from 'classnames'
import { authFetch } from 'src/utils/authFetch'
import { BASE_URL } from 'src/constants/api'

type FileItemStatus = 'pending' | 'loading' | 'success' | 'error'
type FileItem = {
	file: File
	imageUrl: string
	status: FileItemStatus
	error: null | string
}

type Props = {
	startDate: Moment
	endDate: Moment
	bookingId: number
	onClose: () => void
	refetchBookingDetail: () => Promise<void>
}

const uploadFile = async (data: {
	file: File
	date: string
	releated_key: 'booking' | 'districtbooking' | 'publictransportadvertising'
	related_id: number
}): Promise<void> => {
	try {
		const formData = new FormData()

		formData.append('photo', data.file)
		formData.append('date', data.date)
		formData.append('releated_key', data.releated_key)
		formData.append('related_id', String(data.related_id))
		await authFetch({
			method: 'POST',
			url: `${BASE_URL}/provider/photo_report/`,
			body: formData,
		})
	} catch (error: any) {
		throw new Error('Ошибка загрузки')
	}
}

export const AddPhotoReportItem = ({
	endDate,
	startDate,
	onClose,
	bookingId,
	refetchBookingDetail,
}: Props) => {
	const [date, setDate] = useState('')
	const [files, setFiles] = useState<FileItem[]>([])
	const [errors, setErrors] = useState({
		date: !date ? 'Выберите дату' : '',
		files: !files.length ? 'Выберите файлы' : '',
	})

	const handleResetFormData = () => {
		setDate('')
		setFiles([])
		setErrors({ date: '', files: '' })
	}
	const handleValidate = () => {
		let isValid = true
		if (!date) {
			setErrors((prev) => ({ ...prev, date: 'Выберите дату' }))
			isValid = false
		} else if (!files.length) {
			setErrors((prev) => ({ ...prev, files: 'Выберите файлы' }))
			isValid = false
		}
		return isValid
	}
	const [isLoading, setIsLoading] = useState(false)

	const handleDateChange = (date: Moment) => {
		setDate(moment(date, 'YYYY-MM-DD', true).format('DD.MM.YYYY'))
		setErrors({ date: '', files: '' })
	}

	const handeFilesChange = (e: ChangeEvent<HTMLInputElement>) => {
		if (e.target.files) {
			setFiles(
				Array.from(e.target.files).map((file) => ({
					file,
					imageUrl: URL.createObjectURL(file),
					status: 'pending',
					error: null,
				}))
			)

			const fileInput = document.getElementById(
				'add_photo_report_input'
			) as HTMLInputElement | null
			if (fileInput) {
				fileInput.value = ''
			}
			setErrors({ date: '', files: '' })
		}
	}
	const updateFileStatus = (index: number, newStatus: Partial<FileItem>) => {
		setFiles((prevFiles) =>
			prevFiles.map((file, i) =>
				i === index ? { ...file, ...newStatus } : file
			)
		)
	}
	const handleUpload = async () => {
		const isValid = handleValidate()
		if (isValid) {
			setIsLoading(true)

			setErrors({ date: '', files: '' })

			// upload files
			const uploadPromises = files.map((fileItem, index) => {
				updateFileStatus(index, { status: 'loading', error: null })

				return uploadFile({
					date: moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
					file: fileItem.file,
					related_id: bookingId,
					releated_key: 'booking',
				})
					.then(() => {
						updateFileStatus(index, { status: 'success' })
					})
					.catch((error) => {
						updateFileStatus(index, {
							status: 'error',
							error: error?.message || 'some error occured',
						})

						throw error
					})
			})
			try {
				await Promise.all(uploadPromises)
				handleResetFormData()
			} catch (e) {
				console.log(e)
			} finally {
				await refetchBookingDetail()
				setIsLoading(false)
			}
		}
	}
	useEffect(() => {
		return () => {
			files.forEach((file) => URL.revokeObjectURL(file.imageUrl))
		}
	}, [])
	return (
		<div
			className="w-100"
			style={{
				border: '1px solid #CED4DA',
				borderRadius: '16px',
				padding: '12px',
				display: 'flex',
				flexDirection: 'column',
				gap: '16px',
			}}
		>
			<div className="d-flex align-items-start gap-4">
				<DateInput
					renderFocus
					firstAvaliableDate={startDate}
					className="w-50 "
					title={
						<div
							style={{
								display: 'flex',
								gap: '8px',
								paddingBottom: '8px',
								alignItems: 'center',
							}}
						>
							<span className=" m-0">Дата фиксации</span>
						</div>
					}
					value={date}
					onChange={handleDateChange}
					error={errors.date}
					minDate={startDate}
					maxDate={endDate.clone().add(1, 'day')}
				/>
				<div className="d-flex flex-column gap-1 w-50 ">
					<div className="d-flex flex-column gap-2 ">
						<p className="m-0">Выберите файлы</p>
						<label
							className={cn(s.feedback__label, {
								[s.labelDisabled]: isLoading || !date,
							})}
						>
							.jpg.png
							<input
								type="file"
								id="add_photo_report_input"
								className={s.feedback__file}
								onChange={handeFilesChange}
								multiple
								accept=".png, .jpg, .jpeg"
								disabled={isLoading || !date}
							/>
						</label>
					</div>
					{errors.files && !errors.date && (
						<span className={s.labelError}>{errors.files}</span>
					)}
				</div>
			</div>

			<div className="d-flex gap-4" style={{ flexWrap: 'wrap' }}>
				{files.map((file) => (
					<div
						key={file.file.name}
						style={{
							overflow: 'hidden',
							position: 'relative',
							display: 'flex',
							flexDirection: 'column',
							gap: '4px',
						}}
					>
						<ImageFullScreenWrapper>
							<img
								style={{
									borderRadius: '16px',
									maxWidth: '150px',
									maxHeight: ' 150px',
									width: '150px',
									height: '150px',
									minWidth: '150px',
									minHeight: ' 150px',
								}}
								src={file.imageUrl}
								alt={file.file.name}
							/>
						</ImageFullScreenWrapper>
						<div
							style={{
								position: 'absolute',
								top: 5,
								right: 10,
								cursor: 'pointer',
							}}
							onClick={() => {
								if (file.status === 'pending') {
									setFiles((prev) =>
										prev.filter((f) => f !== file)
									)
								}
							}}
						>
							{file.status === 'pending' && (
								<i
									style={{ color: '#DC3545' }}
									className="bi bi-trash"
								></i>
							)}
							{file.status === 'loading' && (
								<Spinner
									variant="primary"
									animation="border"
									size="sm"
								/>
							)}
							{file.status === 'error' && (
								<i
									style={{ color: '#DC3545' }}
									className="bi bi-x"
								></i>
							)}
							{file.status === 'success' && (
								<i
									style={{ color: '#28A745' }}
									className="bi bi-check"
								></i>
							)}
						</div>
						{<span className={s.labelError}>{file.error}</span>}
					</div>
				))}
			</div>

			<div className="d-flex align-items-center justify-content-center gap-2">
				<Button
					onClick={onClose}
					variant="secondary"
					className="d-flex align-items-center justify-content-center gap-2"
				>
					<h6 style={{ color: '#fff' }} className="m-0">
						Отменить
					</h6>
				</Button>
				<Button
					onClick={handleUpload}
					className="d-flex align-items-center justify-content-center gap-2"
					disabled={isLoading}
				>
					<DowloadIcon
						style={{ fill: '#fff', height: '16px', width: '16px' }}
					/>{' '}
					<h6 style={{ color: '#fff' }} className="m-0">
						Загрузить
					</h6>
				</Button>
			</div>
		</div>
	)
}
