import React, { useContext, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { GarantidItemMenu } from 'src/components/common/garantid-card/garantid-media-format-card/components/garantid-item/GarantidItemMenu'
import cn from 'classnames'
import s from 'src/components/common/garantid-card/garantid-media-format-card/components/garantid-item/garantidItem.module.scss'
import {
	AdvFormatBookingItem,
	GarantidItem,
	MediaRequirements,
	PlacementViewModel,
	useTechnicalRequirementsStore,
} from 'src/stores/technical-requirements-store'
import useResponsive from 'src/utils/useResponsive'
import { ConditionArrowLeftRight } from 'src/components/UtilityComponents'
import ImageFullScreenWrapper from 'src/components/UtilityComponents/ImageFullScreenWrapper'
import CreativeFileInput from 'src/components/UtilityComponents/CreativeFileInput'
import { deleteCompanyMedia, uploadCompanyMedia } from 'src/constants/api'
import {
	downloadByRequest,
	getFileDescription,
	getMaxFileSize,
} from 'src/utils'
import { TemplatesModal } from '../../../templates-modal'
import useNotificationData from 'src/stores/notificationsData'
import { CreativeTemplateKeysType } from '../../../../constants'
import { CreateCreativeFields } from '../../../../CreateCreative/CreateCreativeForm'
import { useCampaignInfoStore } from 'src/components/_client/campaign-info/store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from 'src/components/_client/campaign-info/store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import useCampaignData from 'src/stores/campaignData'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { PlacementType } from 'src/stores/ADMarketTypes.types'
import { EditVinstantModal } from 'src/components/_client/campaign-info/common/edit-vinstant-modal/EditVinstantModal'
import { OrderLayoutModal } from '../../../order-layout-modal'
import { CommonMediaModal } from 'src/components/common/garantid-card/garantid-media-format-card/components/common-media/modals/CommonMediaModal'
import { InfoAboutPlaceWrapper } from 'src/components/common/garantid-card/garantid-media-format-card/components/common/InfoAboutPlaceWrapper'
import { MediaFormatRequirements } from 'src/components/common/garantid-card/garantid-media-format-card/components/common/MediaFormatRequirements'
import { EditVinstantProcessingWrapped } from 'src/components/_client/campaign-info/common/edit-vinstant-processing/EditVinstantProcessing'
import { InputExtraButtons } from '../../common/input-extra-buttons/InputExtraButtons'
import { ReactComponent as PictureIcon } from 'src/assets/images/picture-1.svg'

type ModalType =
	| 'templates'
	| 'requirements'
	| 'editVinstant'
	| 'order'
	| 'placementInfo'

const modalTitles: Record<ModalType, string> = {
	requirements: 'Требования к загрузке',
	templates: 'Создание материала',
	order: 'Заказ материала',
	editVinstant: 'Редактирование винстанса',
	placementInfo: 'Подробнее о месте',
}

type Props = {
	booking: AdvFormatBookingItem<GarantidItem, PlacementViewModel>
	requirements: MediaRequirements
}
export const BookingUpload = ({ booking, requirements }: Props) => {
	const { item, placement } = booking
	const [campaign, campaignInterface] = useCampaignData()

	const placementShowingCount = campaign.booking.find(
		(el) => el.id === item.id
	)?.placement_showing_count

	const { addToast } = useContext(ToastsContext)

	const [modalType, setModalType] = useState<ModalType | null>(null)
	const [_, notificationsInterface] = useNotificationData()

	const fetchVinstantCreative = useCampaignInfoStore(
		(state) => state.fetchVinstantCreative
	)
	const fetchTechnicalRequirements = useTechnicalRequirementsStore(
		(state) => state.fetchTechnicalRequirements
	)

	const editVinstantKey = String(item.id)
	const editCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated(editVinstantKey)
	const editVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'booking_id',
			itemId: editVinstantKey,
		})
	)
	const screenResolution =
		`${requirements.max_width}*${requirements.max_height}` as CreativeTemplateKeysType

	const handleOrderLayoutSubmit = async (values: CreateCreativeFields) => {
		for (let key in values) {
			if (
				values[key] === null ||
				values[key] === undefined ||
				values[key] === ''
			) {
				delete values[key]
			}
		}
		if (item) {
			await campaignInterface.createNewBidRequest(values, {
				booking_id: String(item.id),
			})
		}
		setModalType(null)
	}
	const refetch = async () => {
		const campaign = await campaignInterface.refetchSelected()

		if (campaign) {
			await fetchTechnicalRequirements(campaign)
			await fetchVinstantCreative(campaign.id)
		}
	}

	const handleUpload = () => {
		if (item?.media_creative?.id) {
			downloadByRequest(
				`adv_company_media/${item.media_creative?.id}/download/`,
				item.media_creative?.file_name!
			)
		}
	}
	useEffect(() => {
		if (editCreativeVinstantStatus === 'end') {
			addToast({
				text: `Загрузка контента завершена ${placement.address}`,
				type: 'success',
			})
			notificationsInterface.setNewContendCreated({
				[editVinstantKey]: 'pending',
			})
			refetch()
		}
	}, [editCreativeVinstantStatus])

	return (
		<div>
			<PlacementInfoWithMenu
				isMedia={!!item?.media_creative?.file}
				setModal={setModalType}
				address={placement.address}
				image={placement.image}
				name={placement.name}
			/>
			{editCreativeVinstantStatus === 'start' ? (
				<EditVinstantProcessingWrapped />
			) : (
				<CreativeFileInput
					ExtraButton={
						item.media_creative?.is_self_download && (
							<InputExtraButtons
								onEditVinstant={
									editVinstantLink
										? () => setModalType('editVinstant')
										: undefined
								}
								onUpload={handleUpload}
							/>
						)
					}
					onlyView={item.media_creative?.is_self_download === false}
					loadingInProgress={false}
					bidInfo={{ id: item.id, type: 'booking' }}
					initialValue={{
						src: item.media_creative?.file || '',
						name: item.media_creative?.file_name || '',
					}}
					updateUrl={uploadCompanyMedia}
					removeUrl={deleteCompanyMedia.params(
						item.media_creative?.id || 0
					)}
					updateMethod={'POST'}
					nameInBody={'file'}
					additionalBody={{
						booking_id: item.id,
						is_self_download: true,
					}}
					description={getFileDescription(
						requirements,
						placementShowingCount
					)}
					allowedExtension={[
						...requirements.image_formats,
						...requirements.video_formats,
					]}
					maxSizeMb={getMaxFileSize(requirements)}
					onFileLoad={refetch}
					onFileDelete={refetch}
				/>
			)}

			<TemplatesModal
				closeModal={() => setModalType(null)}
				clsName="booking"
				instanceId={String(item.id)}
				modalOpen={!!modalType && modalType === 'templates'}
				newContentKey={editVinstantKey}
				screenResolution={screenResolution}
				getOrderLayoutModal={() => setModalType('order')}
				placement={
					campaign.booking.find((booking) => booking.id === item.id)
						?.placement as unknown as
						| Omit<PlacementType, 'placement_showing_counts'>
						| undefined
				}
			/>
			<EditVinstantModal
				editVinstantLink={editVinstantLink}
				newContentKey={editVinstantKey}
				onClose={() => setModalType(null)}
				open={modalType === 'editVinstant'}
			/>
			<OrderLayoutModal
				onSubmit={handleOrderLayoutSubmit}
				open={modalType === 'order'}
				onClose={() => setModalType(null)}
			/>
			<CommonMediaModal
				onClose={() => setModalType(null)}
				open={
					!!modalType &&
					modalType !== 'templates' &&
					modalType !== 'order' &&
					modalType !== 'editVinstant'
				}
				title={modalTitles[modalType || 'requirements']}
			>
				{modalType === 'placementInfo' && (
					<InfoAboutPlaceWrapper bookingId={item.id} />
				)}
				{modalType === 'requirements' && (
					<MediaFormatRequirements mediaRequierments={requirements} />
				)}
			</CommonMediaModal>
		</div>
	)
}

type PlacementPreviewProps = {
	name: string
	image: string
	address: string
	setModal: (
		modal:
			| 'templates'
			| 'requirements'
			| 'editVinstant'
			| 'order'
			| 'placementInfo'
	) => void
	isMedia: boolean
}
export const PlacementInfoWithMenu = ({
	image,
	name,
	address,
	setModal,
	isMedia,
}: PlacementPreviewProps) => {
	const { isMobile } = useResponsive()
	return (
		<div className={s.garandidCampaignItemHeader}>
			<div className={s.garandidCampaignItemPlacement}>
				<div className={'d-flex mb-3'}>
					<div className={'me-3'}>
						<ImageFullScreenWrapper>
							<img
								src={
									image ||
									'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
								}
								alt={name}
								style={{
									width: '80px',
									height: '80px',
									objectFit: 'cover',
									objectPosition: '50% 50%',
									borderRadius: '16px',
								}}
							/>
						</ImageFullScreenWrapper>
					</div>
					<div
						className={'d-flex flex-column justify-content-center'}
					>
						<h5 className={'mb-1'}>{address}</h5>
						<p className={'m-0 text-secondary'}>{name}</p>
					</div>
				</div>
			</div>
			<GarantidItemMenu mobileViewClassName={s.garandidItemMenuMobile}>
				<Dropdown.Item
					eventKey="1"
					className={cn(s.garantidmMenuItem, {
						[s.garandidCampaignMenuItem_bordered]: false,
					})}
					onClick={() => setModal('placementInfo')}
					mobileAutoClose={true}
				>
					<i className="bi bi-info-circle"></i>{' '}
					<p>Подробнее о месте</p>
					{isMobile && (
						<ConditionArrowLeftRight
							open={false}
							styles={{ display: 'block' }}
						/>
					)}
				</Dropdown.Item>
				<Dropdown.Item
					eventKey="2"
					className={cn(s.garantidmMenuItem, {
						[s.garandidCampaignMenuItem_bordered]: false,
					})}
					onClick={() => setModal('requirements')}
					mobileAutoClose={true}
				>
					<i className="bi bi-question-circle"></i>
					<p>Требования к загрузке</p>
					{isMobile && (
						<ConditionArrowLeftRight
							open={false}
							styles={{ display: 'block' }}
						/>
					)}
				</Dropdown.Item>
				{!isMedia && (
					<Dropdown.Item
						eventKey="3"
						className={cn(s.garantidmMenuItem, {
							[s.garandidCampaignMenuItem_bordered]: false,
						})}
						onClick={() => setModal('templates')}
						mobileAutoClose={true}
					>
						<PictureIcon />
						<p>Создать материал</p>
						{isMobile && (
							<ConditionArrowLeftRight
								open={false}
								styles={{ display: 'block' }}
							/>
						)}
					</Dropdown.Item>
				)}
			</GarantidItemMenu>
		</div>
	)
}
