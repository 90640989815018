import React, { FC, useEffect, useState } from 'react'
import { Moment } from 'moment/moment'
import useDebounce from '../../../utils/useDebounce'
import s from './user-operations.module.scss'
import { Form } from 'react-bootstrap'
import AdMarketDateInput from '../../UtilityComponents/ADMarketDateInput/ADMarketDateInput'

interface IOperationsFilters {
	onDebounceSearch: (str: string) => void
	dates: { start: Moment | null; end: Moment | null }
	setDates: React.Dispatch<
		React.SetStateAction<{ start: Moment | null; end: Moment | null }>
	>
	initialSearch: string
}

const OperationsFilters: FC<IOperationsFilters> = ({
	dates,
	setDates,
	onDebounceSearch,
	initialSearch,
}) => {
	const [search, setSearch] = useState(initialSearch)
	const debouncedSearch = useDebounce(search, 500)
	useEffect(() => {
		onDebounceSearch(debouncedSearch)
	}, [debouncedSearch]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (initialSearch === '') setSearch(initialSearch)
	}, [initialSearch])
	return (
		<div className={s.filtersContainer}>
			<Form.Group className={'position-relative'}>
				<Form.Control
					type={'text'}
					value={search}
					onChange={(e) => {
						setSearch(e.target.value)
					}}
					placeholder={'Поиск'}
				/>
				<i className="bi bi-search position-absolute"></i>
			</Form.Group>
			<AdMarketDateInput
				preText={'c '}
				value={dates.start}
				onChangeValue={(newValue) => {
					if (newValue.isSame(dates.start)) {
						setDates((p) => ({ ...p, start: null }))
					} else {
						setDates((p) => ({ ...p, start: newValue }))
					}
				}}
			/>
			<AdMarketDateInput
				preText={'по '}
				value={dates.end}
				onChangeValue={(newValue) => {
					{
						if (newValue.isSame(dates.end)) {
							setDates((p) => ({ ...p, end: null }))
						} else {
							setDates((p) => ({ ...p, end: newValue }))
						}
					}
				}}
			/>
		</div>
	)
}

export default OperationsFilters
