import React from 'react'
import {
	MoscowCentRingRequirementsArticle,
	ArticleItem,
} from './MoscowCentRingRequirementsArticle'
import useResponsive from 'src/utils/useResponsive'

export const MCRRussOutdoorArticle = (props: any) => {
	const { isMobile } = useResponsive()
	return (
		<MoscowCentRingRequirementsArticle
			title="Анимированный контент, изготовленный компанией Russ Outdoor"
			isBorder={isMobile ? true : false}
		>
			<>
				<ArticleItem title="Формат:" style={{ paddingTop: 0 }}>
					<div>
						PSD в слоях, как для печати 6х3 с разрешением 25
						px/inch, но не менее 640 х 896 px.
					</div>
					<div>
						Если макет выполнен в виде только векторной графики,
						принимаются форматы AI, EPS.
					</div>
				</ArticleItem>
				<ArticleItem title="Цветовая модель:">RGB</ArticleItem>
				<ArticleItem title="Дисклеймер:">
					При наличии, просьба предоставлять набранным текстом в виде
					текстового блока
				</ArticleItem>
				<ArticleItem title="Дополнительно:" style={{ border: 'none' }}>
					<ul>
						<li>
							Для подготовки анимации текста предоставить файлы
							шрифтов, которые использованы в макете
						</li>
						<li>
							При наличии скрытого фона, просьба его не обрезать
						</li>
					</ul>
				</ArticleItem>
			</>
		</MoscowCentRingRequirementsArticle>
	)
}
