import React, { Dispatch, SetStateAction } from 'react'
import {
	NotificationModel,
	NotificationState,
} from 'src/components/_moderator/types/ModerationRequestTypes'
import { NotificationActionType } from '../NotificationsContent'
import {} from '../../../../display-issues/common/components/IssuesContent'
import { ReactComponent as Delete } from 'src/assets/images/delete.svg'
import { NotificationItemMobile } from './NotificationItemMobile'

type Props = {
	selectedTab: NotificationState
	setNotificationInfo: (
		notificationId: number,
		mode: NotificationActionType
	) => Promise<void>
	notificationsData: NotificationModel[]
	setSelectedNotificationsIds: Dispatch<SetStateAction<number[]>>
	setRemovingId: Dispatch<SetStateAction<number | undefined>>
	selectedNotificationsIds: number[]
}

export const NotificationsListMobile = ({
	notificationsData,
	selectedTab,
	setNotificationInfo,
}: Props) => {
	const handleItemClick = (item: NotificationModel) => {
		if (selectedTab === 'draft') {
			setNotificationInfo(item.id, 'create')
		} else {
			setNotificationInfo(item.id, 'view')
		}
	}

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				padding: '0 1rem',
			}}
		>
			{notificationsData?.map((item, index) => (
				<NotificationItemMobile
					isBorder={
						notificationsData.length - 1 !== index ||
						notificationsData.length === 1
					}
					key={item.id}
					notification={item}
					onViewClick={handleItemClick}
					setNotificationInfo={setNotificationInfo}
				/>
			))}
		</div>
	)
}
