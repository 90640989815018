import React, { useMemo, useState } from 'react'
import { Card } from 'react-bootstrap'
import s from './garantidMediaFormatCard.module.scss'
import {
	AdvFormatBookingItem,
	AdvItemState,
	GarantidItem,
	MediaFormatViewModel,
	PlacementViewModel,
} from 'src/stores/technical-requirements-store'
import { CommonMedia } from './components/common-media/CommonMedia'
import { GarantidMediaFormatCardHeader } from './components/header/GarantidMediaFormatCardHeader'
import { GarantidCampaignItem } from './components/garantid-item/GarantidCampaignItem'
import { AnimatePresence } from 'framer-motion'

type Props = {
	mediaFormat: MediaFormatViewModel<GarantidItem, PlacementViewModel>
}

const checkIsAllBookingMediasTheSame = (
	bookingItems: AdvFormatBookingItem<GarantidItem, PlacementViewModel>[]
) => {
	const isNotMedia = bookingItems.find((el) => !el.item.media_creative?.file)

	if (isNotMedia) {
		return false
	}
	const allMedias = bookingItems.map((el) => el.item.media_creative!.file)

	return new Set(allMedias).size === 1
}

const checkIsTheSameDocsRequiredStatus = (
	bookingItems: AdvFormatBookingItem<GarantidItem, PlacementViewModel>[]
) => {
	const comparedStatus = bookingItems[0].item.is_docs_required

	return bookingItems.every(
		(el) => el.item.is_docs_required === comparedStatus
	)
}
export const GarantidMediaFormatCard = ({ mediaFormat }: Props) => {
	const isAllBookingsMediasTheSame = useMemo(
		() => checkIsAllBookingMediasTheSame(mediaFormat.booking_item),
		[mediaFormat]
	)
	const isTheSameDocsRequiredStatus = useMemo(
		() => checkIsTheSameDocsRequiredStatus(mediaFormat.booking_item),
		[mediaFormat]
	)
	const isAllBookingsInEditState = useMemo(
		() =>
			mediaFormat.booking_item.every(
				(booking) => booking.item.state === AdvItemState.Adjustment
			),
		[mediaFormat]
	)
	const isOnlyOneBooking = useMemo(
		() => mediaFormat.booking_item.length === 1,
		[mediaFormat]
	)
	const [open, setOpen] = useState(
		!isAllBookingsMediasTheSame || isOnlyOneBooking
	)

	const showCommonMedia =
		isAllBookingsMediasTheSame &&
		!open &&
		!!mediaFormat.booking_item[0]?.item?.id

	const editable =
		isTheSameDocsRequiredStatus &&
		showCommonMedia &&
		isAllBookingsInEditState

	const fakeMediaFormatName = `${mediaFormat.media_requirements?.max_width} × ${mediaFormat.media_requirements?.max_height} px`
	const mediaFormatName = mediaFormat.media_type_name
	return (
		<Card className={s.garantidMediaFormatCard}>
			<GarantidMediaFormatCardHeader
				isOpenButton={!isOnlyOneBooking}
				counts={mediaFormat.counts}
				mediaTypeName={mediaFormatName}
				open={open}
				setOpen={setOpen}
			/>
			{showCommonMedia && (
				<CommonMedia mediaFormat={mediaFormat} editable={editable} />
			)}
			{open && (
				<AnimatePresence>
					<div className={s.garantidItemsContainer}>
						{mediaFormat.booking_item.map((booking, index) => (
							<GarantidCampaignItem
								key={booking.item.id}
								bookingItem={booking}
								mediaRequirements={
									mediaFormat.media_requirements
								}
							/>
						))}
					</div>
				</AnimatePresence>
			)}
		</Card>
	)
}
