import React from 'react'

type DisplayPropertiesType = {
	Размер: string
	Тип: string | undefined
	'Допустимый формат': string
	Ширина: string
	Высота: string
	'Соотношение сторон': string | null
}
type TemplateInfoPropsListType = {
	displayProperties: DisplayPropertiesType
}

export const TemplateInfoPropsList = ({
	displayProperties,
}: TemplateInfoPropsListType) => {
	return (
		<div style={{ padding: '0 1.5rem', width: '100%' }}>
			<div>
				{Object.keys(displayProperties).map((el, index, array) => (
					<div
						className={'d-flex justify-content-between'}
						key={el}
						style={{
							borderTop:
								index === 0 ? '1px solid #CED4DA' : 'none',
							borderBottom: '1px solid #CED4DA',
							padding: '8px 0',

							fontSize: '14px',
						}}
					>
						<span className={'text-secondary'}>{el}</span>
						<span style={{ textAlign: 'right' }}>
							{displayProperties[el]}
						</span>
					</div>
				))}
			</div>
		</div>
	)
}
