import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { CampaignInfoTypeBookingsSeparation } from 'src/stores/ADMarketTypes.types'
import useCampaignData from 'src/stores/campaignData'
import { GarantViews } from './outdoor-variants/garant-views/GarantViews'
import { OutdoorPresentationCard } from './outdoor-presentation-card/OutdoorPresentationCard'
import { AdvFormats, OutdoorVariants } from '../../../../types'
import { MassCoverage } from './outdoor-variants/mass-coverage/MassCoverage'
import { AdvFormatNav } from '../../../common/adv-format-nav/AdvFormatNav'
import { useAdformatsStore } from 'src/components/_client/create-campaign/Step2-ADformats/adformats-store/adformats.store'
import {
	massCoverageNoItemsErrorId,
	outdoorNoItemsErrorId,
} from '../../../../constants'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { AdvFormatVariantsContainer } from '../../../common/adv-format-variants-container/AdvFormatVariantsContainer'
import { AdvFormatContent } from '../../../common/adv-format-content/AdvFormatContent'
import { AdvFormatContainer } from '../../../common/adv-format-container/AdvFormatContainer'

const initializeOutdoorVariants = (
	campaign: CampaignInfoTypeBookingsSeparation
): OutdoorVariants[] => {
	const variants = [] as OutdoorVariants[]

	if (campaign.district_adv_is_selected) {
		variants.push(OutdoorVariants.MassCoverage)
	}
	if (campaign.outdoor_adv_is_selected) {
		variants.push(OutdoorVariants.GarantViews)
	}

	return variants
}

const OutdoorVariantsForRender: Record<
	OutdoorVariants,
	(props: any) => JSX.Element
> = {
	garantViews: GarantViews,
	massCoverage: MassCoverage,
}

type Props = {
	goToSelectors: () => void
}
export const OutdoorAdv = ({ goToSelectors }: Props) => {
	const [campaign, campaignInterface] = useCampaignData()

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isFormatChanged, setIsFormatChanged] = useState(false)

	const setAdFormatErrors = useAdformatsStore(
		(state) => state.setAdFormatErrors
	)

	const { addToast } = useContext(ToastsContext)
	const removeAdFormatErrorByErrorId = useAdformatsStore(
		(state) => state.removeAdFormatErrorByErrorId
	)
	const [outdoorVariants, setOutdoorVariants] = useState<OutdoorVariants[]>(
		() => initializeOutdoorVariants(campaign)
	)

	const handleVariantClick = useCallback(
		(variant: OutdoorVariants) => {
			setIsFormatChanged(true)

			if (outdoorVariants.includes(variant)) {
				//действия при удалении варианта наружки

				//убрать ошибки из максимального охвата
				if (variant === OutdoorVariants.MassCoverage) {
					removeAdFormatErrorByErrorId(
						'massCoverage',
						massCoverageNoItemsErrorId
					)
				}

				//убрать ошибки из гарантированных показов
				if (variant === OutdoorVariants.GarantViews) {
					removeAdFormatErrorByErrorId(
						'garant',
						outdoorNoItemsErrorId
					)
				}

				//убрать компонент из стэка форм
				setOutdoorVariants(outdoorVariants.filter((v) => v !== variant))
			} else {
				setOutdoorVariants([variant, ...outdoorVariants])
			}
		},
		[outdoorVariants]
	)

	const handleAddFormat = async () => {
		const body = {
			outdoor_adv_is_selected: outdoorVariants.includes(
				OutdoorVariants.GarantViews
			),
			district_adv_is_selected: outdoorVariants.includes(
				OutdoorVariants.MassCoverage
			),
		}
		try {
			setIsLoading(true)
			await campaignInterface.patchCampaign(campaign.id, body)
			await campaignInterface.refetchSelected()
			addToast({
				text: 'Наружная реклама изменена',
				type: 'success',
			})
			goToSelectors()
		} catch (e) {
			addToast({
				text: 'Ошибка изменения наружой рекламы',
				type: 'danger',
			})
		} finally {
			setIsLoading(false)
		}
	}
	const RenderedOutdoorVariants = useMemo(
		() =>
			outdoorVariants.map((variant) => {
				return React.createElement(OutdoorVariantsForRender[variant], {
					key: variant,
				})
			}),
		[outdoorVariants]
	)

	//remove all adv format errors
	useEffect(() => {
		return () => {
			setAdFormatErrors('garant', null)
			setAdFormatErrors('massCoverage', null)
		}
	}, [])
	return (
		<AdvFormatContainer>
			<AdvFormatContent>
				<OutdoorPresentationCard
					onSelectorClick={handleVariantClick}
					selectedVariants={outdoorVariants}
				/>
				<AdvFormatVariantsContainer>
					{RenderedOutdoorVariants}
				</AdvFormatVariantsContainer>
			</AdvFormatContent>

			<AdvFormatNav
				advFormat={AdvFormats.Outdoor}
				advAddDisabled={isLoading}
				onBack={goToSelectors}
				onAddFormat={handleAddFormat}
				isFormatChanged={true}
				totalAdvVariants={outdoorVariants.length}
			/>
		</AdvFormatContainer>
	)
}
