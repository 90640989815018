import React, { useContext, useState } from 'react'
import {
	Button,
	Dropdown,
	Form,
	Modal,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap'
import { withPage } from '../../page/Page'
import s from './ProviderProfile.module.scss'
import { Formik } from 'formik'
import InputMask from 'react-input-mask'
import useUserData from '../../../stores/userData'
import { ToastsContext } from '../../UtilityComponents/ToastsContextProvider'
import PhoneConfirmation from '../../auth/PhoneConfirmation'
import bem from '../../../utils/bem'
import useResponsive from '../../../utils/useResponsive'

const cn = bem('auth-page')

const ProviderProfile = () => (
	<div className={s.profileContainer}>
		<h1>Аккаунт</h1>
		<section className={s.profileSection}>
			<h3>Персональная информация</h3>
			<Dropdown.Divider className={'m-0 p-0'} />
			<div className={s.personal}>
				<ChangePersonalInfoBlock />
				<ChanePhoneBlock />
			</div>
		</section>
		<section className={s.profileSection}>
			<h3>Безопасность</h3>
			<Dropdown.Divider className={'m-0 p-0'} />
			<ChangePasswordBlock />
		</section>
		<section className={s.profileSection}>
			<h3>Организация</h3>
			<Dropdown.Divider className={'m-0 p-0'} />
			<OrganizationBlock />
		</section>
	</div>
)

export default withPage({ noSidebar: false, isProvider: true })(ProviderProfile)

const ChangePersonalInfoBlock = () => {
	const [user, userInterface] = useUserData()
	const { addToast } = useContext(ToastsContext)
	const { isMobile } = useResponsive()

	const currentFIO = `${user.last_name} ${user.first_name} ${
		user.middle_name || ''
	}`.trim()

	async function handleValidate(values) {
		const errors: any = {}
		const { fio } = values
		const splitted = fio.trim().split(' ')
		if (splitted.length < 2) {
			errors.fio = 'Укажите корретные ФИО'
			return errors
		}
	}
	async function handleSubmit(values, { setSubmitting, setFieldValue }) {
		const { fio } = values
		const splitted = fio.trim().split(' ')
		const body: any = {}
		body.first_name = splitted[1]
		body.last_name = splitted[0]
		body.middle_name = ''
		if (splitted.length > 2) {
			splitted.forEach((el, index) => {
				if (index > 1) {
					body.middle_name += el + ' '
				}
			})
		}
		body.middle_name = body.middle_name.trim()
		await userInterface.changeUserFIO(body)
		addToast({
			text: 'Ваше ФИО успешно изменено',
			type: 'success',
			withAction: false,
		})
		setFieldValue('fio', values.fio.trim())
	}
	return (
		<div className={s.personalInfo}>
			<div
				style={
					isMobile
						? {
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginBottom: '16px',
						  }
						: { marginRight: '40px' }
				}
			>
				<svg
					width="80"
					height="80"
					viewBox="0 0 80 80"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect width="80" height="80" rx="40" fill="#6C757D" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M40 20C34.4772 20 30 24.4772 30 30C30 35.5228 34.4772 40 40 40C45.5228 40 50 35.5228 50 30C50 24.4772 45.5228 20 40 20ZM34 30C34 26.6863 36.6863 24 40 24C43.3137 24 46 26.6863 46 30C46 33.3137 43.3137 36 40 36C36.6863 36 34 33.3137 34 30Z"
						fill="white"
					/>
					<path
						d="M40.0002 42C29.1793 42 23 51.1966 23 60H27C27 52.8034 31.9462 46 40.0002 46C48.0542 46 53 52.8033 53 60H57C57 51.1967 50.8211 42 40.0002 42Z"
						fill="white"
					/>
				</svg>
			</div>
			<Formik
				initialValues={{
					fio: currentFIO,
				}}
				validate={handleValidate}
				onSubmit={handleSubmit}
				validateOnChange={false}
			>
				{({
					values,
					errors,
					handleSubmit,
					setErrors,
					setFieldValue,
				}) => (
					<Form onSubmit={handleSubmit} style={{ width: '100%' }}>
						<Form.Group style={{ marginBottom: '24px' }}>
							<Form.Label>ФИО</Form.Label>
							<Form.Control
								size="lg"
								type={'text'}
								value={values.fio}
								onChange={(e) => {
									setFieldValue('fio', e.target.value)
									setErrors({})
								}}
								isInvalid={!!errors.fio}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.fio}
							</Form.Control.Feedback>
						</Form.Group>
						<Button
							type="submit"
							disabled={values.fio === currentFIO}
						>
							Сохранить изменения
						</Button>
					</Form>
				)}
			</Formik>
		</div>
	)
}

export const ChanePhoneBlock = (props) => {
	const [user, userInterface] = useUserData()
	const [showModal, setShowModal] = useState(false)
	const [phoneForConfirm, setPhoneForConfirm] = useState('')
	const { addToast } = useContext(ToastsContext)
	async function handleValidate(values) {
		const errors: any = {}
		const new_phone = (values.phone_number = values.phone_number.replace(
			/[^\d+]/g,
			''
		))
		if (new_phone.length !== 12)
			errors.phone_number = 'Введите правильный номер'
		return errors
	}
	async function handleSubmit(values, { setSubmitting, setFieldError }) {
		const new_phone = (values.phone_number = values.phone_number.replace(
			/[^\d+]/g,
			''
		))
		const res = await userInterface.changeUserPhone(new_phone)
		if (res) return setFieldError('phone_number', res)
		setPhoneForConfirm(new_phone)
		setShowModal(true)
	}
	async function handlePhoneConfirmation({ code }) {
		const res = await userInterface.acceptChangeUserPhone(
			phoneForConfirm,
			code
		)
		if (res) {
			throw new Error(res.data.code[0])
		} else {
			setShowModal(false)
			addToast({ text: 'Ваш номер телефона успешно изменен' })
		}
	}

	async function handleCodeResend() {
		try {
			await userInterface.changeUserPhone(phoneForConfirm)
		} catch (e) {
			console.log('-> e', e)
		}
	}
	return (
		<div className={s.changePhone}>
			<Formik
				initialValues={{
					phone_number: user?.phone_number,
				}}
				validate={handleValidate}
				onSubmit={handleSubmit}
				validateOnChange={false}
			>
				{({
					values,
					errors,
					handleSubmit,
					setErrors,
					setFieldValue,
				}) => (
					<Form onSubmit={handleSubmit} style={{ width: '100%' }}>
						<Form.Group style={{ marginBottom: '24px' }}>
							<Form.Label>Телефон</Form.Label>
							<InputMask
								mask={'+7 (999) 999-99-99'}
								value={values.phone_number}
								onChange={(e) => {
									setFieldValue(
										'phone_number',
										e.target.value
									)
									setErrors({})
								}}
							>
								{(inputProps) => (
									<Form.Control
										size="lg"
										type={'text'}
										isInvalid={!!errors.phone_number}
										{...inputProps}
									/>
								)}
							</InputMask>
							<Form.Control.Feedback type="invalid">
								{errors.phone_number}
							</Form.Control.Feedback>
						</Form.Group>
						<Button
							type="submit"
							disabled={values.phone_number === user.phone_number}
						>
							Изменить телефон
						</Button>
					</Form>
				)}
			</Formik>
			{showModal && (
				<Modal
					show={showModal}
					onHide={() => setShowModal(false)}
					centered
					animation={true}
					backdrop="static"
					keyboard={false}
				>
					<Modal.Body>
						<PhoneConfirmation
							onSubmit={handlePhoneConfirmation}
							onCodeResend={handleCodeResend}
							onBack={() => setShowModal(false)}
							phone={phoneForConfirm}
							cn={cn}
						/>
					</Modal.Body>
				</Modal>
			)}
		</div>
	)
}

export const ChangePasswordBlock = () => {
	const [, userInterface] = useUserData()
	const [showPasswords, setShowPasswords] = useState({
		old_password: false,
		new_password: false,
	})
	const { addToast } = useContext(ToastsContext)

	async function handleValidate(values) {
		const errors: any = {}
		const { new_password } = values
		if (!new_password || new_password.length < 8) {
			errors.new_password = 'Пароль не может быть короче 8 символов'
		}
		return errors
	}
	async function handleSubmit(
		values,
		{ setSubmitting, setFieldError, resetForm }
	) {
		const answer = await userInterface.changePassword({
			password: values.new_password,
			old_password: values.old_password,
		})
		if (answer) {
			if (answer['old_password']) {
				setFieldError('old_password', answer['old_password'][0])
			}
			if (answer['password']) {
				setFieldError('new_password', answer['password'][0])
			}
		}
		if (!answer) {
			addToast({
				text: 'Ваш пароль успешно изменен',
				type: 'success',
				withAction: false,
			})
			resetForm()
			setTimeout(() => {
				userInterface.logout()
			}, 3000)
		}
	}
	return (
		<div className={s.changePassword}>
			<Formik
				initialValues={{
					old_password: '',
					new_password: '',
				}}
				validate={handleValidate}
				onSubmit={handleSubmit}
				validateOnChange={false}
			>
				{({
					values,
					errors,
					handleSubmit,
					setErrors,
					setFieldValue,
				}) => (
					<Form onSubmit={handleSubmit} style={{ width: '100%' }}>
						<div
							className={s.personal}
							style={{ marginBottom: '24px' }}
						>
							{' '}
							<Form.Group style={{ position: 'relative' }}>
								<Form.Label>Текущий пароль</Form.Label>
								<Form.Control
									size="lg"
									type={
										showPasswords.old_password
											? 'text'
											: 'password'
									}
									value={values.old_password}
									onChange={(e) => {
										setFieldValue(
											'old_password',
											e.target.value
										)
										setErrors({})
									}}
									isInvalid={!!errors.old_password}
									placeholder={'Введите текущий пароль'}
								/>
								{errors.old_password === undefined && (
									<div className={s.popover}>
										<OverlayTrigger
											placement={'top'}
											overlay={
												<Tooltip id={`tooltip-top`}>
													{showPasswords.old_password
														? 'Скрыть пароль'
														: 'Показать пароль'}
												</Tooltip>
											}
										>
											<div
												onClick={() =>
													setShowPasswords(
														(state) => {
															return {
																...state,
																old_password:
																	!state.old_password,
															}
														}
													)
												}
												style={{ marginTop: '3px' }}
											>
												{showPasswords.old_password ? (
													<ClosedEyeIcon />
												) : (
													<OpenEyeIcon />
												)}
											</div>
										</OverlayTrigger>
									</div>
								)}

								<Form.Control.Feedback type="invalid">
									{errors.old_password}
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group style={{ position: 'relative' }}>
								<Form.Label>Новый пароль</Form.Label>
								<Form.Control
									size="lg"
									type={
										showPasswords.new_password
											? 'text'
											: 'password'
									}
									value={values.new_password}
									onChange={(e) => {
										setFieldValue(
											'new_password',
											e.target.value
										)
										setErrors({})
									}}
									isInvalid={!!errors.new_password}
									placeholder={'Введите новый пароль'}
									autoComplete={'off'}
								/>
								{errors.new_password === undefined && (
									<div className={s.popover}>
										<OverlayTrigger
											placement={'top'}
											overlay={
												<Tooltip id={`tooltip-top`}>
													{showPasswords.new_password
														? 'Скрыть пароль'
														: 'Показать пароль'}
												</Tooltip>
											}
										>
											<div
												onClick={() =>
													setShowPasswords(
														(state) => {
															return {
																...state,
																new_password:
																	!state.new_password,
															}
														}
													)
												}
												style={{ marginTop: '2px' }}
											>
												{showPasswords.new_password ? (
													<ClosedEyeIcon />
												) : (
													<OpenEyeIcon />
												)}
											</div>
										</OverlayTrigger>
									</div>
								)}
								<Form.Control.Feedback type="invalid">
									{errors.new_password}
								</Form.Control.Feedback>
							</Form.Group>
						</div>

						<Button
							type="submit"
							disabled={
								values.old_password === '' &&
								values.new_password === ''
							}
						>
							Обновить пароль
						</Button>
					</Form>
				)}
			</Formik>
		</div>
	)
}

const OrganizationBlock = (props) => {
	const { addToast } = useContext(ToastsContext)
	const [user, userInterface] = useUserData()
	async function handleValidate(values) {
		const errors: any = {}
		if (values.name === '') errors.name = 'Поле не должно быть пустым'
		return errors
	}
	async function handleSubmit(values, { setSubmitting }) {
		await userInterface.changeProviderName(values.name)
		addToast({
			text: 'Название организации успешно изменено',
			type: 'success',
			withAction: false,
		})
	}
	return (
		<div className={s.organization}>
			<Formik
				initialValues={{
					name: user?.provider?.name,
				}}
				validate={handleValidate}
				onSubmit={handleSubmit}
				validateOnChange={false}
			>
				{({
					values,
					errors,
					handleSubmit,
					setErrors,
					setFieldValue,
				}) => (
					<Form onSubmit={handleSubmit} style={{ width: '100%' }}>
						<Form.Group style={{ marginBottom: '24px' }}>
							<Form.Label>Организация или ИП</Form.Label>
							<Form.Control
								size="lg"
								type={'text'}
								value={values.name}
								onChange={(e) => {
									setFieldValue('name', e.target.value)
									setErrors({})
								}}
								isInvalid={!!errors.name}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.name}
							</Form.Control.Feedback>
						</Form.Group>
						<Button
							type="submit"
							disabled={values.name === user?.provider?.name}
							variant={
								values.name === user?.provider?.name
									? 'secondary'
									: 'primary'
							}
						>
							Сохранить изменения
						</Button>
					</Form>
				)}
			</Formik>
		</div>
	)
}

export const ClosedEyeIcon = (props) => (
	<svg
		width="24"
		height="30"
		viewBox="0 0 24 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M20.0385 19.857C22.59 17.58 24 15 24 15C24 15 19.5 6.75 12 6.75C10.5594 6.75496 9.13508 7.05514 7.815 7.632L8.97 8.7885C9.94116 8.43473 10.9664 8.25252 12 8.25C15.18 8.25 17.8185 10.002 19.752 11.9355C20.6825 12.871 21.5167 13.8977 22.242 15C22.155 15.1305 22.059 15.2745 21.9495 15.432C21.447 16.152 20.7045 17.112 19.752 18.0645C19.5045 18.312 19.2465 18.5565 18.9765 18.7935L20.0385 19.857Z"
			fill="currentColor"
		/>
		<path
			d="M16.9455 16.764C17.2802 15.8277 17.3422 14.8157 17.1243 13.8456C16.9063 12.8755 16.4173 11.9873 15.7143 11.2842C15.0112 10.5812 14.123 10.0922 13.1529 9.87423C12.1828 9.65626 11.1707 9.71825 10.2345 10.053L11.469 11.2875C12.0455 11.205 12.6332 11.2578 13.1857 11.4419C13.7382 11.626 14.2402 11.9362 14.652 12.348C15.0638 12.7598 15.374 13.2618 15.5581 13.8143C15.7421 14.3668 15.795 14.9545 15.7125 15.531L16.9455 16.764ZM12.531 18.7125L13.764 19.9455C12.8278 20.2802 11.8157 20.3422 10.8456 20.1242C9.87554 19.9063 8.98729 19.4173 8.28423 18.7143C7.58117 18.0112 7.09221 17.1229 6.87424 16.1529C6.65628 15.1828 6.71827 14.1707 7.053 13.2345L8.2875 14.469C8.20499 15.0454 8.25786 15.6332 8.44194 16.1857C8.62602 16.7382 8.93624 17.2402 9.34801 17.652C9.75979 18.0637 10.2618 18.374 10.8143 18.558C11.3668 18.7421 11.9545 18.795 12.531 18.7125Z"
			fill="currentColor"
		/>
		<path
			d="M5.025 11.205C4.755 11.445 4.4955 11.688 4.248 11.9355C3.31747 12.871 2.48328 13.8977 1.758 15L2.0505 15.432C2.553 16.152 3.2955 17.112 4.248 18.0645C6.1815 19.998 8.8215 21.75 12 21.75C13.074 21.75 14.085 21.5505 15.03 21.21L16.185 22.368C14.8649 22.9448 13.4406 23.245 12 23.25C4.5 23.25 0 15 0 15C0 15 1.4085 12.4185 3.9615 10.143L5.0235 11.2065L5.025 11.205Z"
			fill="black"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M20.469 24.531L2.469 6.53099L3.531 5.46899L21.531 23.469L20.469 24.531Z"
			fill="currentColor"
		/>
	</svg>
)

export const OpenEyeIcon = (props) => (
	<svg
		width="24"
		height="30"
		viewBox="0 0 24 30"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M24 15C24 15 19.5 6.75 12 6.75C4.5 6.75 0 15 0 15C0 15 4.5 23.25 12 23.25C19.5 23.25 24 15 24 15ZM1.7595 15C2.48478 16.1023 3.31897 17.129 4.2495 18.0645C6.18 19.998 8.82 21.75 12 21.75C15.18 21.75 17.8185 19.998 19.752 18.0645C20.6825 17.129 21.5167 16.1023 22.242 15C21.5167 13.8977 20.6825 12.871 19.752 11.9355C17.8185 10.002 15.1785 8.25 12 8.25C8.82 8.25 6.1815 10.002 4.248 11.9355C3.31746 12.871 2.48327 13.8977 1.758 15H1.7595Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 11.25C11.0054 11.25 10.0516 11.6451 9.34835 12.3483C8.64509 13.0516 8.25 14.0054 8.25 15C8.25 15.9946 8.64509 16.9484 9.34835 17.6517C10.0516 18.3549 11.0054 18.75 12 18.75C12.9946 18.75 13.9484 18.3549 14.6517 17.6517C15.3549 16.9484 15.75 15.9946 15.75 15C15.75 14.0054 15.3549 13.0516 14.6517 12.3483C13.9484 11.6451 12.9946 11.25 12 11.25ZM6.75 15C6.75 13.6076 7.30312 12.2723 8.28769 11.2877C9.27226 10.3031 10.6076 9.75 12 9.75C13.3924 9.75 14.7277 10.3031 15.7123 11.2877C16.6969 12.2723 17.25 13.6076 17.25 15C17.25 16.3924 16.6969 17.7277 15.7123 18.7123C14.7277 19.6969 13.3924 20.25 12 20.25C10.6076 20.25 9.27226 19.6969 8.28769 18.7123C7.30312 17.7277 6.75 16.3924 6.75 15Z"
			fill="#212529"
		/>
	</svg>
)
