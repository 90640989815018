import React, { useCallback, useState } from 'react'
import BookingEdit from 'src/components/_client/campaign-info/bookings/BookingEdit'
import useCampaignData from 'src/stores/campaignData'
import s from './common.module.scss'
import { Button, Spinner } from 'react-bootstrap'
import useNotificationData from 'src/stores/notificationsData'
import { useCampaignInfoStore } from 'src/components/_client/campaign-info/store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from 'src/components/_client/campaign-info/store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { getError } from 'src/utils/getError'
import { useTechnicalRequirementsStore } from 'src/stores/technical-requirements-store'
import useResponsive from 'src/utils/useResponsive'
type Props = {
	bookingId: number
	onVinstantClick: () => void
	onClose: () => void
}
export const BookingEditWrapper = ({
	bookingId,
	onVinstantClick,
	onClose,
}: Props) => {
	const [isLoading, setIsLoading] = useState(false)
	const [campaign, campaignInterface] = useCampaignData()
	const [_, notificationsInterface] = useNotificationData()
	const fetchTechnicalRequirements = useTechnicalRequirementsStore(
		(state) => state.fetchTechnicalRequirements
	)

	const booking = campaign?.booking?.find((b) => b.id === bookingId)

	const bookingVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'booking_id',
			itemId: bookingId.toString(),
		})
	)

	//поля для остлеживания состояния загрузки документов
	const [isDocsChanged, setIsDocsChanged] = useState(false)
	//file который необходимо отправить при модерации
	const [media, setMedia] = useState<File | null>()
	const [mediaError, setMediaError] = useState('')

	//если сохраняется vinstantLinkState=true, то пользователь не трогал медиа - модерация доступна при media=null и не надо отправлять media
	const [vinstantLinkState, setVinstantLinkState] = useState(
		!!bookingVinstantLink
	)

	const editCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated(bookingId.toString()) as
			| string
			| undefined
	const isVinstantLoading = editCreativeVinstantStatus === 'start'

	const handleFileChange = useCallback((file: File) => {
		setMedia(file)
		setVinstantLinkState(false)
	}, [])

	const handleSetError = useCallback((error: string) => {
		setMediaError(error)
	}, [])

	const mediaModerationApproved = vinstantLinkState || media

	const moderationDisabled =
		isVinstantLoading ||
		!mediaModerationApproved ||
		!!mediaError ||
		isLoading

	const handleMoveToModeration = useCallback(async () => {
		setIsLoading(true)
		if (!booking || !booking.media_creative) {
			return
		}

		const nextBookingState =
			campaign.state === 'moderation' ? 'inner_moderation' : 'moderation'

		try {
			if (media) {
				await campaignInterface.patchSingleMedia({
					mediaId: booking.media_creative.id,
					file: media,
					isSelfDownload: true,
				})
			}
			await campaignInterface.patchBooking(
				booking.id.toString(),
				{
					state: nextBookingState,
				},
				false
			)
			const campaign = await campaignInterface.refetchSelected()
			if (campaign) {
				await fetchTechnicalRequirements(campaign)
			}

			onClose()
		} catch (e: any) {
			const error = getError(e)

			console.log(error)

			if (
				Array.isArray(error?.data?.file) &&
				error.data.file.length > 0
			) {
				setMediaError(error.data.file.join(', '))
			}
		} finally {
			setIsLoading(false)
		}
	}, [booking, media])

	const { isMobile } = useResponsive()
	return (
		<div className={s.bookingEditWrapper}>
			<div className={s.mediaRequirementsContainer}>
				{booking && booking.media_creative && (
					<BookingEdit
						vinstantLinkState={vinstantLinkState}
						onDocumentLoaded={setIsDocsChanged}
						booking={booking}
						editVinstantStatus={editCreativeVinstantStatus}
						media={booking.media_creative}
						onNewImage={handleFileChange}
						onEditVinstantClick={onVinstantClick}
						setFileError={handleSetError}
						fileError={mediaError}
					/>
				)}
			</div>

			<div className={s.bookingEditWrapperFooter}>
				<Button variant="secondary" onClick={onClose}>
					Отменить
				</Button>
				<Button
					variant="primary"
					disabled={moderationDisabled}
					onClick={handleMoveToModeration}
				>
					{isMobile ? 'На модерацию' : `Отправить на модерацию`}
					{isLoading && (
						<Spinner
							animation="border"
							size={'sm'}
							style={{ color: '#007BFF' }}
						/>
					)}
				</Button>
			</div>
		</div>
	)
}
