import React from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import { ReportDetailModalType } from '../../types'
import { useParams } from 'react-router-dom'
import { OutdoorDetails } from '../adv-format-details/OutdoorDetails'
import { useCampaignReportStore } from '../../store'
import { IndoorDetails } from '../adv-format-details/IndoorDetails'
import { InternetDetailsWrapper } from '../adv-format-details/InternetDetails'
import { PublicTransportDetailsWrapper } from '../adv-format-details/PublicTransportDetails'
import { TvDetailsWrapper } from '../adv-format-details/TvDetails'

type Props = {
	open: boolean
	onClose: () => void
	advFormat: ReportDetailModalType
}

const advReportDetailTitles: Record<ReportDetailModalType, string> = {
	indoor: 'внутренней рекламе',
	publicTransport: 'общественому транспорту',
	internet: 'интернет-рекламе',
	tv: 'ТВ-рекламе',
	outdoor: 'наружной рекламе',
}
export const ReportDetailModal = ({ advFormat, onClose, open }: Props) => {
	const { campaignId } = useParams<{ campaignId: string }>()

	const fetchDetailedReport = useCampaignReportStore(
		(store) => store.fetchDetailedReport
	)
	const handleFetchDetails = async (advFormat: ReportDetailModalType) => {
		if (campaignId) {
			await fetchDetailedReport(campaignId, advFormat)
		}
	}

	return (
		<Modal
			show={open}
			onHide={onClose}
			centered
			fullscreen
			animation={true}
		>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} className={'ms-2'}>
					{`Отчет по ${advReportDetailTitles[advFormat]}`}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body
				className={'p-0'}
				style={{
					position: 'relative',
					maxWidth: '100vw',
					overflow: 'hidden',
				}}
			>
				{advFormat === 'outdoor' && (
					<OutdoorDetails
						fetchDetails={() => handleFetchDetails('outdoor')}
					/>
				)}
				{advFormat === 'indoor' && (
					<IndoorDetails
						fetchDetails={() => handleFetchDetails('indoor')}
					/>
				)}
				{advFormat === 'internet' && (
					<InternetDetailsWrapper
						fetchDetails={() => handleFetchDetails('internet')}
					/>
				)}
				{advFormat === 'publicTransport' && (
					<PublicTransportDetailsWrapper
						fetchDetails={() =>
							handleFetchDetails('publicTransport')
						}
					/>
				)}
				{advFormat === 'tv' && (
					<TvDetailsWrapper
						fetchDetails={() => handleFetchDetails('tv')}
					/>
				)}
			</Modal.Body>
		</Modal>
	)
}
