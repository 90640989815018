import React from 'react'
import { Modal } from 'react-bootstrap'
import useResponsive from 'src/utils/useResponsive'
import CreateCreativeForm, {
	CreateCreativeFields,
} from '../../CreateCreative/CreateCreativeForm'

type OrderLayoutModalProps = {
	open: boolean
	onClose: () => void
	onSubmit: (values: CreateCreativeFields) => void
}
export const OrderLayoutModal = ({
	onClose,
	open,
	onSubmit,
}: OrderLayoutModalProps) => {
	const { isMobile } = useResponsive()

	return (
		<Modal
			show={open}
			onHide={onClose}
			centered
			backdrop="static"
			keyboard={false}
		>
			{/* {isBG && (
				<div
					className={'position-absolute w-100 h-100 top-0 start-0'}
					style={{
						backgroundColor: 'rgba(0,0,0,.5)',
						zIndex: 10000,
					}}
				></div>
			)} */}
			<Modal.Header closeButton>
				<Modal.Title as={'h5'}>Создание материала</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ padding: '24px' }}>
				<CreateCreativeForm onSubmit={onSubmit} onHide={onClose} />
			</Modal.Body>
		</Modal>
	)
}
