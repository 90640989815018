import React, { useState, useEffect } from 'react'

export const useTypewriter = (text: string, speed: number = 100) => {
	const [displayText, setDisplayText] = useState('')

	useEffect(() => {
		let currentIndex = 0
		const interval = setInterval(() => {
			setDisplayText((prev) => prev + text[currentIndex])
			currentIndex += 1

			if (currentIndex > text.length) {
				currentIndex = 0
				setDisplayText('')
			}
		}, speed)

		return () => clearInterval(interval)
	}, [text, speed])

	return displayText
}
