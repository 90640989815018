import React, { useEffect, useState } from 'react'
import {
	RightPanelHeader,
	RightPanelFooter,
	RightPanelPlacementsList,
} from './added-items-control'
import { PlacementShort } from 'src/stores/ADMarketTypes.types'
import { motion } from 'framer-motion'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import useResponsive from 'src/utils/useResponsive'

type Props = {
	addedPlacement: PlacementShort[]
	onRemoveAll: () => void
	onRemoveItem: (itemId: number) => void
	onSubmit: () => void
	isExpanded: boolean
	setIsExpanded: (isExpanded: boolean) => void
}
export const MassCoverageRightPanel = ({
	addedPlacement,
	onRemoveAll,
	onSubmit,
	onRemoveItem,
	isExpanded,
	setIsExpanded,
}: Props) => {
	return (
		<motion.div
			initial={{ x: '100%' }}
			animate={{ x: isExpanded ? '0%' : '100%' }}
			style={{
				width: '28%',
				maxWidth: '400px',
				position: 'absolute',
				height: '100%',
				maxHeight: '100%',
				top: 0,
				right: 0,
				zIndex: 9999,
				background: 'white',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<ToggleRightPannel
				count={addedPlacement.length}
				isExpanded={isExpanded}
				setIsExpanded={setIsExpanded}
			/>
			<RightPanelHeader onRemoveAll={onRemoveAll} />
			<RightPanelPlacementsList
				onRemoveItem={onRemoveItem}
				addedPlacement={addedPlacement}
			/>
			<RightPanelFooter
				onSubmit={onSubmit}
				itemsCount={addedPlacement.length}
			/>
		</motion.div>
	)
}

type TogglePannelProps = {
	isExpanded: boolean
	setIsExpanded: (isExpanded: boolean) => void
	count: number
}

export const ToggleRightPannel = ({
	isExpanded,
	setIsExpanded,
	count,
}: TogglePannelProps) => {
	const { isMobile } = useResponsive()
	const positionPreset: React.CSSProperties = isMobile
		? { top: 8, right: 8 }
		: {
				top: '24px',
				left: '-68px',
		  }
	return (
		<OverlayTrigger
			placement="left"
			overlay={(props) => {
				return isMobile ? (
					<></>
				) : (
					<Tooltip id="expand" {...props}>
						{isMobile ? '' : isExpanded ? 'Свернуть' : 'Развернуть'}
					</Tooltip>
				)
			}}
		>
			<div
				style={{
					position: 'absolute',
					width: '48px',
					height: '48px',
					zIndex: 1,
					borderRadius: '50%',
					backgroundColor: '#fff',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					cursor: 'pointer',
					...positionPreset,
				}}
				onClick={() => setIsExpanded(!isExpanded)}
			>
				<i
					style={{
						transition: 'transform .5s ease-in-out',
					}}
				>
					{!isExpanded ? (
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M2 8V2H16V8H10H8H2ZM8 10H1C0.447716 10 0 9.55229 0 9V1C0 0.447716 0.447715 0 1 0H17C17.5523 0 18 0.447715 18 1V9C18 9.55228 17.5523 10 17 10H10V16H8V10Z"
								fill="#212529"
							/>
						</svg>
					) : (
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							style={{ transform: 'rotate(-180deg)' }}
						>
							<path
								d="M3.4938 8.00012L9.34743 2.42896L7.96861 0.980225L0.310587 8.2687C-0.103212 8.66253 -0.103578 9.32238 0.309783 9.71667L7.96781 17.0214L9.34824 15.5742L3.50459 10.0001L19.9999 10.0012L20.0001 8.00118L11.7469 8.00065L3.4938 8.00012Z"
								fill="#212529"
							/>
						</svg>
					)}
				</i>
				{!isExpanded && (
					<div
						style={{
							position: 'absolute',
							width: '22px',
							height: '22px',
							backgroundColor: '#007BFF',
							borderRadius: '50%',
							color: '#fff',
							top: -3,
							textAlign: 'center',
							fontSize: '12px',
							paddingTop: '2px',
							right: 0,
						}}
					>
						{count}
					</div>
				)}
			</div>
		</OverlayTrigger>
	)
}
