import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import MobileModal from 'src/components/UtilityComponents/MobileModal'
import { ShowHideArrowButton } from './ShowHideArrowButton'
import { BookingFiltersList } from './BookingFiltersList'
import { BookingFiltersContent } from '../components/filter-content/BookingFiltersContent'
import { usePushObjectToQueryParams, useQueryParams } from 'src/utils'
import useProviderData from 'src/stores/providerData'

export type MobileFiltersTitleType = 'Кампании' | 'Заказчик' | 'Регион' | 'Сеть'
export type MobileFiltersKeyType =
	| 'adv_companies'
	| 'company'
	| 'regions'
	| 'chains'

export type MobileFilters = {
	count: number
	isSelected: boolean
	title: MobileFiltersTitleType
	key: MobileFiltersKeyType
}

const mobileFilters: Array<MobileFilters> = [
	{ count: 0, isSelected: false, title: 'Кампании', key: 'adv_companies' },
	{ count: 0, isSelected: false, title: 'Заказчик', key: 'company' },
	// { count: 0, isSelected: false, title: 'Регион', id: 'regions' },
	// { count: 0, isSelected: false, title: 'Сеть', id: 'chains' },
]
const getModalTitle = (key: MobileFiltersKeyType | undefined) => {
	switch (key) {
		case 'adv_companies':
			return 'Кампании'
		case 'company':
			return 'Заказчики'
		case 'regions':
			return 'Регион'
		case 'chains':
			return 'Cети'
		default:
			return 'фильтр не найден'
	}
}

enum BookingsTabsNames {
	INNER_MODERATION = 'inner_moderation',
	ADJUSTMENT = 'adjustment',
	MODERATION = 'moderation',
	DRAFT = 'draft',
	CONFIRM = 'confirm',
	COMPLETED = 'completed',
	REJECT = 'reject',
	CANCELED = 'canceled',
	ALL = 'all',
}

export type ProviderBookingQueryParams = {
	state: BookingsTabsNames
	limit: string
	offset: string
	adv_companies: Array<string> | string
	ordering: string
	company: Array<string> | string
}
//using params as useEffect dependency is invoking infinity loop, use this instead
const getFiltersUpdatedByParams = (
	filters: Array<MobileFilters>,
	params: ProviderBookingQueryParams
) => {
	return filters.map((filter) =>
		params[filter.key]
			? {
					...filter,
					count: Array.isArray(params[filter.key])
						? params[filter.key].length
						: 1,
			  }
			: { ...filter, count: 0 }
	)
}
type BookingsSettingsProps = {
	selectedTab: BookingsTabsNames
}
export const BookingsSettings = ({ selectedTab }: BookingsSettingsProps) => {
	const [open, setOpen] = useState(false)

	const pushObjectToHistory = usePushObjectToQueryParams()
	const { params } = useQueryParams<ProviderBookingQueryParams>()

	const [_, providerInterface] = useProviderData()

	const [filters, setFilters] = useState<Array<MobileFilters>>(mobileFilters)
	const [modalOpen, setModalOpen] = useState(false)

	const [currentFilter, setCurrentFilter] = useState<
		MobileFiltersKeyType | undefined
	>(undefined)

	const handleModalClose = () => {
		setCurrentFilter(undefined)
		setModalOpen(false)
	}

	const handleCurrentFilterChange = (key: MobileFiltersKeyType) => {
		setCurrentFilter(key)
		setModalOpen(true)
	}

	const fetchFiltredBookings = async (
		queryParams: Partial<ProviderBookingQueryParams>
	) => {
		await providerInterface.fetchBookings(
			queryParams.state,
			queryParams.limit,
			queryParams.offset,
			queryParams.adv_companies,
			queryParams.company
		)
		pushObjectToHistory(queryParams)
		const filtersWithUpdateCounts = filters.map((filter) =>
			queryParams[filter.key]
				? {
						...filter,
						count: Array.isArray(queryParams[filter.key])
							? queryParams[filter.key].length
							: 1,
				  }
				: filter
		)
		setFilters(filtersWithUpdateCounts)
	}

	useEffect(() => {
		if (Object.keys(params).length > 0) {
			fetchFiltredBookings({ ...params, state: selectedTab })
		}
	}, [selectedTab])
	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					borderBottom: '1px solid #CED4DA',
				}}
			>
				{open && (
					<BookingFiltersList
						filters={getFiltersUpdatedByParams(filters, params)}
						setCurrentFilter={handleCurrentFilterChange}
					/>
				)}
				<ShowHideArrowButton
					closeTitle="Свернуть"
					open={open}
					setOpen={setOpen}
					openTitle="Настройка фильтров"
				/>
			</div>

			<MobileModal
				enableTouching={false}
				defaultTouchingSetup={false}
				show={modalOpen}
				onHide={handleModalClose}
				title={getModalTitle(currentFilter)}
				styles={{
					padding: 0,
					maxHeight: '100vh',
					height: '100vh',
					display: 'flex',
					flexDirection: 'column',
				}}
				paddingContent="0"
				contentStyles={{
					overflow: 'hidden',
					height: '100%',
					flex: '1 1 auto',
				}}
			>
				<BookingFiltersContent
					fetchFiltredBookings={fetchFiltredBookings}
					onClose={handleModalClose}
					filterKey={currentFilter}
				/>
			</MobileModal>
		</>
	)
}
