import React from 'react'
import useResponsive from 'src/utils/useResponsive'
import { ReactComponent as BackIcon } from '../../../../assets/images/back.svg'

type ContentHeaderProps = {
	onClick: () => void
}

export const ContentHeader = ({ onClick }: ContentHeaderProps) => {
	const { isMobile } = useResponsive()

	if (!isMobile) {
		return <h1 style={{ marginBottom: '24px' }}>Формирование отчета</h1>
	}

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				borderBottom: '1px solid #CED4DA',
				padding: '12px',
				position: 'relative',
				background: '#fff',
			}}
		>
			<BackIcon
				onClick={onClick}
				style={{
					width: '16px',
					height: '16px',
					position: 'absolute',
					top: '50%',
					left: '10%',
					transform: 'translate(-50%, -50%)',
					cursor: 'pointer',
				}}
			/>
			<div>Формирования отчета</div>
		</div>
	)
}
