import React from 'react'
import { CardWrapper } from '../../../../../../common/card-wrapper/CardWrapper'
import { AdvHeader } from '../../../../../../common/adv-header/AdvHeader'
import { AdvRecSummary } from '../../../../../../common/adv-rec-summary/AdvRecSummary'
import IntlFormatter from 'src/utils/intl-money-formatter'
import s from './vk.module.scss'
import { Form } from 'react-bootstrap'
import { NumericFormat } from 'react-number-format'
import InputWithEndText from 'src/components/UtilityComponents/InputWithEndText'
import { ReactComponent as VkIcon } from 'src/assets/images/vk.svg'
import {
	CommonRec,
	RecommendationsApplyInput,
} from 'src/stores/types/recommendations'
import useResponsive from 'src/utils/useResponsive'

type Props = {
	vk: CommonRec
	onChangeApplyInput: (
		field: keyof RecommendationsApplyInput,
		value: string
	) => void
	link: string
	errors: RecommendationsApplyInput
}
export const VkList = ({ link, onChangeApplyInput, vk, errors }: Props) => {
	const { isMobile } = useResponsive()

	return (
		<CardWrapper>
			<div className={s.content}>
				<AdvHeader Icon={VkIcon} title="VK" />

				<div className={s.inputs}>
					<Form.Group>
						<Form.Label>Рекламируемый сайт</Form.Label>
						<Form.Control
							style={{
								minHeight: '40px',
								height: isMobile ? '40px' : '45px',
								fontSize: isMobile ? '16px' : '1.25rem',
								borderRadius: isMobile ? '12px' : '16px',
							}}
							id={'vk_link'}
							type={'text'}
							size={'lg'}
							value={link}
							onChange={(e) =>
								onChangeApplyInput(
									'vk_link',
									e.currentTarget.value.trim()
								)
							}
							placeholder={'Введите ссылку на сайт'}
							isInvalid={!!errors.vk_link}
						/>
						<Form.Control.Feedback type={'invalid'}>
							{errors.vk_link}
						</Form.Control.Feedback>
					</Form.Group>

					<div className={s.viewInputs}>
						<Form.Group className={s.controlBudget}>
							<Form.Label>Бюджет кампании</Form.Label>
							<div style={{ display: 'flex', gap: '0.5rem' }}>
								<NumericFormat
									id={`yandex_budget`}
									decimalScale={2}
									decimalSeparator=","
									value={vk.budget}
									suffix=" ₽"
									thousandSeparator={' '}
									customInput={InputWithEndText}
									controlStyle={{
										minHeight: '40px',
										height: isMobile ? '40px' : '45px',
										fontSize: isMobile ? '16px' : '1.25rem',
										borderRadius: isMobile
											? '12px'
											: '16px',
									}}
									disabled={true}
									onChange={() => {}}
									onKeyDown={() => {}}
									containerStyle={{
										overflow: 'hidden',
										width: '100%',
									}}
								/>
							</div>
						</Form.Group>
					</div>
				</div>

				<AdvRecSummary
					summaryData={[
						{ title: 'Количество кликов:', value: '323' },
						{
							title: 'Прогнозная стоимость:',
							value: IntlFormatter.format(53326),
						},
					]}
				/>
			</div>
		</CardWrapper>
	)
}
