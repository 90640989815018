import React from 'react'
import {
	NotificationCategory,
	NotificationDetails,
	NotificationModel,
} from 'src/components/_moderator/types/ModerationRequestTypes'
import { ClientSender } from './ClientSender'
import { CampaignSender } from './CampaignSender'
import { AdvTypeSender } from './AdvTypeSender'

export type CommonSenderProps = {
	detailedNotify: NotificationModel & NotificationDetails
	onChange: (items: string[]) => void
}

const Components: Record<NotificationCategory, React.FC<CommonSenderProps>> = {
	adv_company: CampaignSender,
	adv_type: AdvTypeSender,
	client: ClientSender,
	related_item: ClientSender,
}

type Props = {
	senderType: NotificationCategory
	detailedNotify: NotificationModel & NotificationDetails
	onChange: (items: string[]) => void
}
export const SenderTypeButtonsAdditional = ({
	senderType,
	detailedNotify,
	onChange,
}: Props) => {
	const Component = Components[senderType]

	return (
		<div>
			{<Component detailedNotify={detailedNotify} onChange={onChange} />}
		</div>
	)
}
