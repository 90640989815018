import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Formik } from 'formik'
import { Link, useHistory, useParams } from 'react-router-dom'
import useProviderData from '../../../../../stores/providerData'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import { ConnectedFocusError } from 'focus-formik-error'
import { authFetch } from '../../../../../utils/authFetch'
import { providerPlacementsTypes } from '../../../../../constants/api'
import ButtonGroupBooleanSwitch from '../../../../UtilityComponents/ButtonGroupBooleanSwitch'
import FileInput from '../../../../UtilityComponents/FileInput'
import ProviderYandexMap from '../../../../UtilityComponents/ProviderYandexMap'
import ProviderPlacementPicker from '../../ProviderPlacementPicker'
import { ToastsContext } from '../../../../UtilityComponents/ToastsContextProvider'
import declOfNum from '../../../../../utils/declOfNum'
import InputWithEndText from '../../../../UtilityComponents/InputWithEndText'
import AdMarketSelect from 'src/components/UtilityComponents/ADMarketSelect/AdMarketSelect'

import s from './style.module.scss'
import cn from 'classnames'
import useResponsive from 'src/utils/useResponsive'
import { useTypewriter } from 'src/utils/useTypewriter'

type PlacementTypes = { id: number; name: string; image: string }[]

const noPlacemarkImage = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fill-rule="evenodd" clip-rule="evenodd" d="M5 13V7H19V13H13H11H5ZM11 15H4C3.44772 15 3 14.5523 3 14V6C3 5.44772 3.44772 5 4 5H20C20.5523 5 21 5.44772 21 6V14C21 14.5523 20.5523 15 20 15H13V19H11V15Z" fill="#212529"/> </svg>`

type PlacementCreateAndEditProps = {
	placementId?: string
	onClose: () => void
	updatePlacementsInfo: () => Promise<void>
}

export const PlacementCreateAndEdit = ({
	placementId,
	onClose,
	updatePlacementsInfo,
}: PlacementCreateAndEditProps) => {
	const isEditing = !!placementId
	const history = useHistory()
	const [provider, providerInterface] = useProviderData()

	const text = useTypewriter('...', 1000)

	const [isPending, setIsPending] = useState(false)
	const [showModalMap, setShowModalMap] = useState(false)
	const [placementTypes, setPlacementTypes] = useState<null | PlacementTypes>(
		null
	)
	const mediaTypes = useMemo(() => {
		if (!provider.mediatypes) return []
		return provider.mediatypes.filter((e) => !e.in_archive)
	}, [provider.mediatypes])
	useEffect(() => {
		window.scrollTo(0, 0)
		;(async () => {
			const types: PlacementTypes = await authFetch({
				url: providerPlacementsTypes,
				method: 'GET',
			})
			setPlacementTypes(types)
		})()
	}, [])
	async function handleSubmit(values, { ...other }) {
		setIsPending(true)
		if (isEditing) {
			await handleSubmitEdit(values)
			setIsPending(false)
		} else {
			await handleSubmitCreate(values)
			setIsPending(false)
		}
		onClose()
	}
	async function handleSubmitCreate(values) {
		const body = { ...values }
		delete body['placement_showing_count']
		delete body['baloonIcon']
		const bodyFormData = new FormData()
		Object.entries(body).forEach(([key, value]) => {
			if (value || typeof value === 'boolean') {
				// @ts-ignore
				bodyFormData.append(key, value)
			}
		})
		try {
			await providerInterface.CreatePlacement(
				bodyFormData,
				values['placement_showing_count']
			)
			await updatePlacementsInfo()
		} catch (e) {
			console.log(e)
		}
	}
	async function handleSubmitEdit(values) {
		const body = { ...values }
		delete body['placement_showing_count']
		delete body['baloonIcon']
		const bodyFormData = new FormData()
		Object.entries(body).forEach(([key, value]) => {
			if (value || typeof value === 'boolean') {
				if (key === 'image') {
					if (value instanceof File) {
						bodyFormData.append(key, value)
					}
				} else {
					// @ts-ignore
					bodyFormData.append(key, value)
				}
			}
		})
		try {
			await providerInterface.EditPlacement(placementId, bodyFormData)
			await updatePlacementsInfo()
		} catch (e) {
			console.log(e)
		}
	}
	function handleValidate(values) {
		const errors: any = {}
		;[
			'name',
			'placement_type_id',
			'media_type_id',
			'size',
			'ots',
			'image',
		].forEach((key) => {
			if (!values[key]) {
				errors[key] = 'Обязательное поле'
			}
		})
		if (values.placement_showing_count.length === 0) {
			errors['placement_showing_count'] =
				'Необходимо добавить хотя бы одну цену за показ'
		}
		if (!values.lat) {
			errors['lat'] =
				'Необходимо указать месторасположение рекламного носителя'
		}
		return errors
	}
	const Initial = useMemo(() => {
		if (placementId) {
			const placement = provider?.placements?.results?.find(
				({ id, state }) =>
					parseInt(id) === parseInt(placementId) && state !== 'used'
			)
			if (!placement) {
				history.goBack()
				return {
					name: '',
					description: '',
					placement_type_id: '',
					media_type_id: '',
					size: '',
					ots: '',
					only_work_days: false,
					works_on_holidays: false,
					image: null,
					lat: null,
					lon: null,
					baloonIcon: null,
					placement_showing_count: [],
				}
			}
			return {
				name: placement.name,
				description: placement.description,
				placement_type_id: placement.placement_type.id,
				media_type_id: placement.media_type.id,
				size: placement.size,
				ots: placement.ots,
				only_work_days: placement.only_work_days,
				works_on_holidays: placement.works_on_holidays,
				image: {
					src:
						placement.image ||
						'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg',
					name: 'Текущее изображение',
				},
				lat: placement.lat,
				lon: placement.lon,
				baloonIcon: placement.placement_type.image,
				placement_showing_count: placement.placement_showing_count,
			}
		}
		return {
			name: '',
			description: '',
			placement_type_id: undefined,
			media_type_id: '',
			size: '',
			ots: '',
			only_work_days: false,
			works_on_holidays: false,
			image: null,
			lat: null,
			lon: null,
			baloonIcon: null,
			placement_showing_count: [],
		}
	}, [placementId, provider?.placements?.results]) // eslint-disable-line react-hooks/exhaustive-deps

	const { isMobile } = useResponsive()
	return (
		<div>
			<Formik
				initialValues={Initial}
				onSubmit={handleSubmit}
				validate={handleValidate}
				enableReinitialize={true}
				validateOnChange={false}
			>
				{({
					setFieldValue,
					values,
					handleSubmit,
					errors,
					setFieldError,
				}) => (
					<Form onSubmit={handleSubmit}>
						<ConnectedFocusError />
						<div className={cn(s.createAndEditForm)}>
							<div>
								<Form.Label>Название</Form.Label>
								<Form.Control
									size="lg"
									type={'text'}
									value={values.name}
									name={'name'}
									onChange={(e) => {
										setFieldError('name', undefined)
										setFieldValue('name', e.target.value)
									}}
									isInvalid={!!errors.name}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.name}
								</Form.Control.Feedback>
							</div>
							<div>
								<Form.Label>Описание</Form.Label>
								<Form.Control
									size="lg"
									type={'text'}
									value={values.description}
									name={'description'}
									onChange={(e) => {
										setFieldError('description', undefined)
										setFieldValue(
											'description',
											e.target.value
										)
									}}
									isInvalid={!!errors.description}
									as={'textarea'}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.description}
								</Form.Control.Feedback>
							</div>
							<div>
								<Form.Label>Медиа-формат</Form.Label>
								<AdMarketSelect
									name={'media_type_id'}
									id={'media_type_id'}
									options={mediaTypes?.map((el) => {
										return {
											value: el.id,
											label: el.name,
										}
									})}
									onChange={(e: any) => {
										setFieldError(
											'media_type_id',
											undefined
										)
										setFieldValue('media_type_id', e.value)
									}}
								/>
								<Form.Control.Feedback
									style={{ display: 'block' }}
									type="invalid"
								>
									{errors.media_type_id}
								</Form.Control.Feedback>
							</div>

							<div>
								<Form.Label>Тип рекламного носителя</Form.Label>
								<AdMarketSelect
									name={'placement_type_id'}
									options={placementTypes?.map((el) => {
										return {
											value: el.id,
											label: el.name,
										}
									})}
									onChange={(e) => {
										setFieldError(
											'placement_type_id',
											undefined
										)
										setFieldValue(
											'placement_type_id',
											e?.value
										)
									}}
									id={'placement_type_id'}
								/>
								<Form.Control.Feedback
									style={{ display: 'block' }}
									type="invalid"
								>
									{errors.placement_type_id}
								</Form.Control.Feedback>
							</div>
							<div className={'d-flex'}>
								<div className={'w-50 me-1'}>
									<Form.Label>Размер</Form.Label>
									<Form.Control
										size="lg"
										type={'text'}
										value={values.size}
										name={'size'}
										onChange={(e) => {
											setFieldError('size', undefined)
											setFieldValue(
												'size',
												e.target.value
											)
										}}
										isInvalid={!!errors.size}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.size}
									</Form.Control.Feedback>
									<Form.Text id="sizeHelpBlock" muted>
										В метрах (6 x 3 м) или дюймах
										(43`&quot;`)
									</Form.Text>
								</div>
								<div className={'w-50 ms-1'}>
									<Form.Label>Рекламу увидят</Form.Label>
									<Form.Control
										size="lg"
										type={'text'}
										value={values.ots}
										name={'ots'}
										onChange={(e) => {
											setFieldError('ots', undefined)
											setFieldValue('ots', e.target.value)
										}}
										isInvalid={!!errors.ots}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.ots}
									</Form.Control.Feedback>
								</div>
							</div>
							<div className={'w-100'}>
								<Form.Label>График показов</Form.Label>
								<div className="d-flex flex-column">
									<div className={'w-50'} />
									<ButtonGroupBooleanSwitch
										elements={[
											'Только в рабочие дни',
											'В рабочие и выходные дни',
										]}
										initialValue={values.only_work_days}
										setFieldValue={setFieldValue}
										name={'only_work_days'}
										reverse={true}
										hardWidth={true}
									/>
								</div>
							</div>
							<div className={'w-100 d-flex'}>
								<Form.Check
									value={values.works_on_holidays}
									checked={values.works_on_holidays}
									onChange={(e) => {
										setFieldError(
											'works_on_holidays',
											undefined
										)
										setFieldValue(
											'works_on_holidays',
											!values.works_on_holidays
										)
									}}
									className={'me-2'}
									name={'works_on_holidays'}
									label={'Включая праздничные дни'}
								/>
							</div>
							{
								<RenderPlacementShowingPrice
									showing_prices={
										values.placement_showing_count
									}
									placement_id={
										isEditing ? (placementId as any) : null
									}
									values={values}
									setFieldValue={setFieldValue}
									errors={errors}
									setFieldError={setFieldError}
								/>
							}

							<div>
								<Form.Label>Месторасположение</Form.Label>
								<div
									style={{
										width: '100%',
										height: '200px',
									}}
								>
									<ProviderYandexMap
										width={'100%'}
										height={'100%'}
										fixed
										zoom={14}
										singleCoords={
											values.lat
												? [
														values.lat,
														values.lon,
														values.baloonIcon
															? values.baloonIcon
															: noPlacemarkImage,
												  ]
												: null
										}
									/>
								</div>
								<Button
									className={'w-100 mt-3'}
									variant={'secondary'}
									onClick={() => {
										setFieldError('lat', '')
										setShowModalMap(true)
									}}
								>
									Указать на карте
								</Button>
								{errors.lat && (
									<div
										className={'invalid-feedback'}
										style={{
											display: 'block',
											marginTop: '5px',
										}}
									>
										{errors.lat}
									</div>
								)}
							</div>
							<div>
								<Form.Label>Загрузка фото</Form.Label>
								<FileInput
									onUpload={(file) => {
										setFieldValue(`image`, file)
										setFieldError(`image`, '')
									}}
									value={values.image ? values.image : null}
									onRemove={() =>
										setFieldValue(`image`, null)
									}
									size={10}
									descr={
										'Форматы — JPEG, JPG, PNG. Размер — не более 10 МБ.'
									}
									allowedExtensions={['JPEG', 'PNG', 'JPG']}
									error={errors.image}
								/>
							</div>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: isMobile ? 'column' : 'row',
								gap: '8px',
								padding: '16px',
								justifyContent: isMobile
									? 'center'
									: 'flex-end',
								borderTop: '1px solid #CED4DA',
							}}
						>
							<Button
								style={{
									minWidth: '200px',
									width: isMobile ? '100%' : 'auto',
								}}
								variant="secondary"
								onClick={onClose}
							>
								Отменить
							</Button>
							<Button
								variant="primary"
								type="submit"
								disabled={isPending}
								style={{
									minWidth: '200px',
									width: isMobile ? '100%' : 'auto',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									gap: '8px',
								}}
							>
								{isPending
									? `Cохраняем${text}`
									: isEditing
									? 'Сохранить изменения'
									: 'Создать'}
							</Button>
						</div>
						{showModalMap && (
							<ProviderPlacementPicker
								show={showModalMap}
								handleClose={() => setShowModalMap(false)}
								errors={errors}
								currentCoords={
									values.lat
										? [
												parseFloat(values.lat),
												parseFloat(values.lon),
										  ]
										: null
								}
								setFieldValue={setFieldValue}
							/>
						)}
					</Form>
				)}
			</Formik>
		</div>
	)
}

function RenderPlacementShowingPrice({
	showing_prices,
	placement_id = null,
	values,
	setFieldValue,
	errors,
	setFieldError,
}) {
	const [, providerInterface] = useProviderData()
	const { addToast } = useContext(ToastsContext)
	const [addModal, setAddModal] = useState<boolean>(false)
	async function handleDelete(id) {
		if (placement_id) {
			if (showing_prices.length === 1)
				return addToast({
					text: 'Нельзя удалить единственную цену',
					type: 'danger',
					withAction: false,
				})
			try {
				await providerInterface.deleteShowingPrice(id)
			} catch (e) {
				console.log(e)
			}
		} else {
			const tmp = [...values.placement_showing_count]
			tmp.splice(id, 1)
			setFieldValue('placement_showing_count', tmp)
		}
	}
	return (
		<div>
			<Form.Label>Цена за показы</Form.Label>
			{showing_prices.map((el, index) => (
				<div key={el?.id || index} className={'w-100 d-flex mb-2'}>
					<Form.Control
						size="lg"
						type={'text'}
						value={parseFloat(el?.price) + ' ₽'}
						disabled={true}
						className={'w-50 me-1'}
					/>
					<Form.Control
						size="lg"
						type={'text'}
						value={`За ${el?.showing_count_per_day} ${declOfNum(
							el?.showing_count_per_day,
							['показ', 'показа', 'показов']
						)} в день`}
						disabled={true}
						className={'w-50 ms-1'}
					/>
					<div
						style={{ padding: '16px', cursor: 'pointer' }}
						onClick={() => handleDelete(el?.id || index)}
					>
						<i className={'bi bi-trash'} />
					</div>
				</div>
			))}
			<Button
				variant={'secondary'}
				className={'w-100 mt-2'}
				onClick={() => {
					setAddModal(true)
					setFieldError('placement_showing_count', '')
				}}
			>
				<i className={'bi bi-plus'} />
				Добавить
			</Button>
			{errors.placement_showing_count && (
				<div
					className={'invalid-feedback'}
					style={{ display: 'block', marginTop: '5px' }}
				>
					{errors.placement_showing_count}
				</div>
			)}
			{addModal && (
				<ModalAddShowingPrice
					show={addModal}
					handleClose={() => setAddModal(false)}
					placementID={placement_id}
					values={values}
					setFieldValue={setFieldValue}
				/>
			)}
		</div>
	)
}

function ModalAddShowingPrice({
	show,
	handleClose,
	placementID = null,
	values,
	setFieldValue,
}) {
	const [showingCount, setShowingCount] = useState(30)
	const [price, setPrice] = useState<string>('1000')
	const [, providerInterface] = useProviderData()
	const rangeValues = useMemo(() => ({ min: 1, max: 300 }), [])
	const rangeLabels = useMemo(() => {
		return [rangeValues.min, 150, rangeValues.max]
	}, [rangeValues])

	const formattedValues = useMemo(() => {
		return {
			showingCount: showingCount,
			price: parseFloat(price.replace(',', '.')),
		}
	}, [price, showingCount])

	async function CreatePriceIfExist() {
		if (!placementID) return
		try {
			await providerInterface.createShowingPrice(placementID, {
				showing_count_per_day: formattedValues?.showingCount,
				price: formattedValues.price,
			})
			handleClose()
		} catch (e) {
			console.log(e)
		}
	}
	function CreatePriceNoExist() {
		if (placementID) return
		setFieldValue('placement_showing_count', [
			...values['placement_showing_count'],
			{
				showing_count_per_day: formattedValues?.showingCount,
				price: formattedValues.price,
			},
		])
		handleClose()
	}
	return (
		<Modal
			show={show}
			onHide={handleClose}
			animation={false}
			centered
			size={'lg'}
		>
			<Modal.Header closeButton>
				<Modal.Title as={'h4'}>
					Создание новой цены за показы
				</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ padding: '40px' }}>
				<div className={'d-flex gap-3'}>
					<div className={'w-50'} style={{ marginBottom: '24px' }}>
						<Form.Label>Цена за показы</Form.Label>
						<InputWithEndText
							type={'number'}
							endText={'₽'}
							value={price}
							onChange={(e) => {
								setPrice(e.target.value.replace(',', '.'))
							}}
						/>
					</div>
					<div className="w-50">
						<div style={{ marginBottom: '4px' }}>
							<Form.Label>Количество показов в день</Form.Label>
							<Form.Control
								size="lg"
								type={'number'}
								value={showingCount}
								onChange={(e) => {
									const newPrice = parseInt(e.target.value)
									const { max } = rangeValues
									setShowingCount(
										newPrice > max ? max : newPrice
									)
								}}
								min={rangeValues.min}
								max={rangeValues.max}
							/>
						</div>
						<div>
							<div
								className={
									'w-100 me-3 d-flex flex-column justify-content-center'
								}
							>
								<div
									className={
										'align-items-center d-flex flex-column'
									}
								>
									<Form.Range
										value={showingCount}
										onChange={(e) => {
											setShowingCount(
												parseInt(e.target.value)
											)
										}}
										min={rangeValues.min}
										max={rangeValues.max}
									/>
									<div
										className={
											'w-100 d-flex justify-content-between'
										}
										style={{ marginTop: '-3px' }}
									>
										{rangeLabels.map((label, index) => (
											<span
												key={label}
												className={'text-secondary'}
												style={{
													fontSize: '12px',
													marginLeft:
														index !== 0 &&
														index !==
															rangeLabels.length -
																1
															? '16px'
															: '',
												}}
											>
												{label}
											</span>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Отменить
				</Button>
				<Button
					variant="primary"
					onClick={
						placementID ? CreatePriceIfExist : CreatePriceNoExist
					}
					disabled={
						price === '' ||
						isNaN(showingCount) ||
						showingCount === 0
					}
				>
					Создать
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
