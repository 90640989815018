import React from 'react'
import cn from 'classnames'
import ls from '../style.module.scss'

type CampaignItemInfoFieldProps = {
	title: string
	text?: string
	mainStyle?: React.CSSProperties
	titleStyle?: React.CSSProperties
	textStyle?: React.CSSProperties
	children?: React.ReactNode
}
export const CampaignItemInfoField = ({
	text,
	title,
	mainStyle,
	textStyle,
	titleStyle,
	children,
}: CampaignItemInfoFieldProps) => {
	return (
		<div className={cn(ls.campaignItemInfoField)} style={mainStyle}>
			<div
				className={cn(ls.campaignItemInfoFieldName)}
				style={titleStyle}
			>
				{title}:
			</div>
			<div
				className={cn(ls.campaignItemInfoFieldValue)}
				style={textStyle}
			>
				{children || text}
			</div>
		</div>
	)
}
