import React from 'react'

const CancelCampaignIcon: React.FC = () => (
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M3.33333 0.333374C2.96514 0.333374 2.66667 0.631851 2.66667 1.00004V5.66671H4V1.66671H12V12.3334H8V13.6667H12.6667C13.0349 13.6667 13.3333 13.3682 13.3333 13V1.00004C13.3333 0.631851 13.0349 0.333374 12.6667 0.333374H3.33333Z"
			fill="#6C757D"
		/>
		<path
			d="M1.13812 13.4714L3.33343 11.2761L5.52875 13.4714L6.47155 12.5286L4.27624 10.3333L6.47145 8.13811L5.52865 7.1953L3.33343 9.39051L1.13822 7.1953L0.195413 8.13811L2.39062 10.3333L0.195312 12.5286L1.13812 13.4714Z"
			fill="#6C757D"
		/>
	</svg>
)

const DeleteCampaignIcon: React.FC = () => (
	<svg
		width="12"
		height="14"
		viewBox="0 0 12 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M2.66663 5.00004V12.3334H9.33329V5.00004H10.6666V13C10.6666 13.3682 10.3681 13.6667 9.99996 13.6667H1.99996C1.63177 13.6667 1.33329 13.3682 1.33329 13V5.00004H2.66663ZM5.33329 5.00004V11H3.99996V5.00004H5.33329ZM7.99996 5.00004V11H6.66663V5.00004H7.99996ZM7.99996 0.333374C8.28691 0.333374 8.54167 0.516994 8.63241 0.789222L9.14662 2.33271L11.3333 2.33337V3.66671H0.666626V2.33337L2.85263 2.33271L3.3675 0.789222C3.45825 0.516994 3.71301 0.333374 3.99996 0.333374H7.99996ZM7.51945 1.66671H4.48047L4.25796 2.33271H7.74129L7.51945 1.66671Z"
			fill="#6C757D"
		/>
	</svg>
)

const RepeatCampaignIcon: React.FC = () => (
	<svg
		width="14"
		height="15"
		viewBox="0 0 14 15"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.3333 1.00004C11.3333 0.631851 11.0348 0.333374 10.6666 0.333374H1.33329C0.965102 0.333374 0.666626 0.631851 0.666626 1.00004V13C0.666626 13.3682 0.965102 13.6667 1.33329 13.6667H4.66663V12.3334H1.99996V1.66671H9.99996V5.00004H11.3333V1.00004Z"
			fill="#6C757D"
		/>
		<path
			d="M9.99996 7.66671C9.48179 7.66671 9.00002 7.81384 8.59179 8.06829L7.88648 6.93678C8.50016 6.55426 9.22528 6.33337 9.99996 6.33337C12.2091 6.33337 14 8.12424 14 10.3334C14 12.5425 12.2091 14.3334 9.99996 14.3334C7.91009 14.3334 6.19455 12.7307 6.0154 10.6873L5.24417 11.5745L4.23789 10.6998L6.09351 8.56512C6.33506 8.28725 6.75615 8.2578 7.03402 8.49936L9.16866 10.355L8.29392 11.3613L7.34062 10.5326C7.4425 11.9123 8.5942 13 9.99996 13C11.4727 13 12.6666 11.8061 12.6666 10.3334C12.6666 8.86061 11.4727 7.66671 9.99996 7.66671Z"
			fill="#6C757D"
		/>
	</svg>
)

const ReturnToDraftIcon: React.FC = () => (
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11.3333 1.00004C11.3333 0.631851 11.0348 0.333374 10.6666 0.333374H1.33329C0.965102 0.333374 0.666626 0.631851 0.666626 1.00004V13C0.666626 13.3682 0.965102 13.6667 1.33329 13.6667H5.99996V12.3334H1.99996V1.66671H9.99996V5.66671H11.3333V1.00004Z"
			fill="#6C757D"
		/>
		<path
			d="M6.19522 9.86197L9.19522 6.86197L10.138 7.80478L8.2761 9.66671H14V11H8.2761L10.138 12.862L9.19522 13.8048L6.19522 10.8048C5.93487 10.5444 5.93487 10.1223 6.19522 9.86197Z"
			fill="#6C757D"
		/>
	</svg>
)

const EditCampaignIcon: React.FC = () => (
	<svg
		width="12"
		height="12"
		viewBox="0 0 12 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.13808 0.195262L11.8047 2.86193C12.0651 3.12228 12.0651 3.54439 11.8047 3.80474L4.47141 11.1381C4.37834 11.2311 4.25981 11.2946 4.13075 11.3204L0.797415 11.9871C0.330934 12.0803 -0.0803455 11.6691 0.0129507 11.2026L0.679617 7.86926C0.705429 7.74019 0.768866 7.62166 0.861933 7.52859L8.19527 0.195262C8.45562 -0.0650874 8.87773 -0.0650874 9.13808 0.195262ZM6.99963 3.276L1.94747 8.32867L1.51651 10.4835L3.67133 10.0525L8.72363 5L6.99963 3.276ZM8.66667 1.60948L7.94297 2.33333L9.6663 4.05667L10.3905 3.33333L8.66667 1.60948Z"
			fill="#6C757D"
		/>
	</svg>
)

export {
	RepeatCampaignIcon,
	ReturnToDraftIcon,
	CancelCampaignIcon,
	DeleteCampaignIcon,
	EditCampaignIcon,
}
