import React, { useEffect, useState } from 'react'
import { withPage } from 'src/components/page/Page'
import useHeaderTitle from 'src/components/page/useHeaderTitle'
import { usePushObjectToQueryParams, useQueryParams } from 'src/utils'
import useResponsive from 'src/utils/useResponsive'
import { ProviderFilter } from 'src/components/_provider/Placements/components'
import { Tabs } from 'src/components/UtilityComponents'
import useModerationData from '../../../store/moderationStore'
import {
	ModerAdvCompanyItemType,
	ModerationAdvStatus,
} from '../../../types/ModerationRequestTypes'
import { getModerationTabsWithCount } from '../../../constants'
import { Content } from './content/Content'
import useDebounce from 'src/utils/useDebounce'

type CampaignModerationQueryParams = {
	state: ModerationAdvStatus
	limit: string
	offset: string
	ordering: string
	q: string
}

const CampaignModeration = (props: any) => {
	useHeaderTitle('Рекламные кампании')

	const [moderState, moderInterface] = useModerationData()

	const [isLoading, setIsLoading] = useState(true)

	const [selectedItem, setSelectedItem] = useState(
		moderState.advList.length > 0 ? moderState.advList[0] : undefined
	)

	const debouncedSelectedItem: ModerAdvCompanyItemType | undefined =
		useDebounce(selectedItem, 500)

	const { params } = useQueryParams<Partial<CampaignModerationQueryParams>>()
	const pushObjectToQuery = usePushObjectToQueryParams()

	const [selectedTab, setSelectedTab] = useState<ModerationAdvStatus>(() => {
		if (params.state) return params.state as ModerationAdvStatus
		else return 'all'
	})

	const [searchString, setSearchString] = useState(() => {
		return params.q || ''
	})

	const [paginationModel, setPaginationModel] = useState<{
		limit: number
		offset: number
	}>({ limit: 10, offset: 0 })

	const handleSetTab = (tabKey: ModerationAdvStatus) => {
		setSelectedTab(tabKey)
		setPaginationModel({ limit: 20, offset: 0 })
	}
	const handleFetchAdvList = async (queryString: string) => {
		const advList = await moderInterface.fetchAdvList(queryString)
		if (advList) {
			setSelectedItem(advList[0])
		}
	}

	useEffect(() => {
		const unionObject: Partial<CampaignModerationQueryParams> = {
			state: selectedTab !== 'all' ? selectedTab : undefined,
			limit: String(paginationModel.limit),
			offset: String(paginationModel.offset),
			q: searchString,
		}

		Object.keys(unionObject)?.forEach((el) => {
			if (!unionObject[el]) delete unionObject[el]
		})
		const queryString = pushObjectToQuery(unionObject)
		handleFetchAdvList(queryString)
	}, [selectedTab, searchString, paginationModel])

	const { isMobile } = useResponsive()

	const tabs = getModerationTabsWithCount(moderState?.counts)
	const totalItems = moderState.counts?.count || 0
	const searchVariants = moderState?.advList
		?.filter((adv) =>
			adv?.name?.toLowerCase()?.includes(searchString.toLowerCase())
		)
		.slice(0, 10)
		.map((adv) => adv?.name || '')

	const handleFetchCampaignInfo = async () => {
		setIsLoading(true)
		await moderInterface.getDetailedCampaignInfo(debouncedSelectedItem?.id!)
		setIsLoading(false)
	}

	useEffect(() => {
		if (debouncedSelectedItem) {
			handleFetchCampaignInfo()
		}
	}, [debouncedSelectedItem])

	return (
		<div
			style={{
				width: '100%',
				height: '100%',
				display: 'flex',
				maxHeight: `calc(100vh - 65px)`,
				flexDirection: 'column',
			}}
		>
			<div>
				<Tabs<ModerationAdvStatus>
					selectedTab={selectedTab}
					setTab={handleSetTab}
					tabs={tabs}
				/>
			</div>

			{!isMobile && (
				<ProviderFilter
					isFiltersButton={false}
					onSetFilter={() => {}}
					allFiltersSelected={false}
					isCreateButton={false}
					isMapButton={false}
					onSearchStringChange={setSearchString}
					onDeleteFilter={() => {}}
					onResetFilters={() => {}}
					onFilterButtonClick={() => {}}
					searchVariants={searchVariants || []}
					searchString={searchString}
				/>
			)}

			<Content
				isLoading={isLoading}
				setSelectedItem={setSelectedItem}
				selectedItem={selectedItem}
				total={totalItems || 0}
				limit={paginationModel.limit}
				offset={paginationModel.offset}
				setPaginationModel={setPaginationModel}
			/>
		</div>
	)
}
export default withPage({ noSidebar: false, isProvider: false })(
	CampaignModeration
)
