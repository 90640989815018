import React, { FC } from 'react'
import { Form } from 'react-bootstrap'
import { CompanyType } from '../../../../stores/ADMarketTypes.types'
import { BusinessFormType, IndustryTypes } from './BusinessDataForm'
import AddressPicker from './AdressPicker'
import useResponsive from '../../../../utils/useResponsive'

interface ISelfBusy {
	isPreset: boolean
	companiesList: CompanyType[]
	presetCompany: CompanyType | null
	setPresetCompany: React.Dispatch<React.SetStateAction<CompanyType | null>>
	values: BusinessFormType
	setFieldError: Function
	setFieldValue: Function
	errors: { [key in keyof BusinessFormType]?: string }
	industryTypes: IndustryTypes
	cn: any
}

const SelfBusy: FC<ISelfBusy> = ({
	errors,
	values,
	setFieldError,
	setFieldValue,
	isPreset,
	cn,
}) => {
	const { isMobile } = useResponsive()
	return (
		<>
			<Form.Group className={'mt-3 mb-3'}>
				<Form.Label>ФИО</Form.Label>
				<Form.Control
					size={'lg'}
					isInvalid={!!errors.legal_name}
					value={values.legal_name}
					disabled={isPreset}
					type={'text'}
					onChange={(e) => {
						setFieldError('legal_name', null)
						setFieldValue('legal_name', e.target.value)
					}}
				/>
				<Form.Control.Feedback type={'invalid'}>
					{errors.legal_name}
				</Form.Control.Feedback>
				{!errors.legal_name && !isPreset && (
					<Form.Text>Обязательное поле</Form.Text>
				)}
			</Form.Group>
			<Form.Group className={'mt-3 mb-3'}>
				<Form.Label>ИНН</Form.Label>
				<Form.Control
					size="lg"
					isInvalid={!!errors.inn}
					value={values.inn}
					disabled={isPreset}
					type={'number'}
					className={'rounded'}
					onChange={(e) => {
						setFieldError('inn', null)
						setFieldValue('inn', e.target.value)
					}}
				/>
				<Form.Control.Feedback type="invalid">
					{errors.inn}
				</Form.Control.Feedback>
				{!errors.inn && !isPreset && (
					<Form.Text>Обязательное поле</Form.Text>
				)}
			</Form.Group>

			{
				<AddressPicker
					clearError={() => {
						setFieldError('actual_location', null)
						setFieldError('actual_location_lat', null)
						setFieldError('actual_location_lon', null)
					}}
					value={values.actual_location}
					setFieldValue={setFieldValue}
					cn={cn}
					name={'actual_location'}
					error={errors.actual_location}
					isMobile={isMobile}
					disabled={isPreset}
					textarea={true}
				/>
			}
		</>
	)
}

export default SelfBusy
