const openLinkInNewTab = (url: string): void => {
	const link = document.createElement('a')
	link.href = url
	link.setAttribute('target', '_blank')
	link.setAttribute('rel', 'noreferrer')
	document.body.appendChild(link)
	link.click()
	link?.parentNode?.removeChild(link)
}

export default openLinkInNewTab
