import React, { useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { FormingDocsItem } from './FormingDocsItem'
import s from './common.module.scss'
import cn from 'classnames'
import { moderationApi } from 'src/components/_moderator/api/moderatorApi'
import { ReactComponent as Warning } from '../../../../../../../../assets/images/warningRing.svg'

type FormingDocsType = {
	advType:
		| 'districtbooking'
		| 'outdoor'
		| 'indoor'
		| 'adstream'
		| 'vk'
		| 'yandex'
	id: string | number
	fact_cost: number | string
	budget: number | string
}

type FormingClosingDocsProps = { onClose: () => void }

export const FormingClosingDocs = ({ onClose }: FormingClosingDocsProps) => {
	const [{ detailedCampaign, isModerLoading }, moderInterface] =
		useModerationData()
	const [isLoading, setIsLoading] = useState(false)

	const [formingDocs, setFormingDocs] = useState<Array<FormingDocsType>>(
		() => {
			let docs: Array<FormingDocsType> = []

			if (detailedCampaign.district_booking.length > 0) {
				const districtBookingFormingDocs: Array<FormingDocsType> =
					detailedCampaign.district_booking.map((distictbooking) => {
						return {
							advType: 'districtbooking',
							budget: distictbooking.amount,
							id: distictbooking.id,
							fact_cost: distictbooking.fact_cost
								? parseFloat(distictbooking.fact_cost)
								: 0,
						}
					})
				docs = [...docs, ...districtBookingFormingDocs]
			}

			if (detailedCampaign.booking.length > 0) {
				const bookingsFormingDocs: Array<FormingDocsType> =
					detailedCampaign.booking.map((booking) => {
						return {
							advType: booking.placement.door_type,
							budget: booking.booking_budget || 0,
							id: booking.id,
							fact_cost: booking.fact_cost
								? parseFloat(booking.fact_cost)
								: 0,
							img: booking.placement.image,
						}
					})

				docs = [...docs, ...bookingsFormingDocs]
			}

			if (detailedCampaign.internet_vk.is_selected) {
				const vkFormingDoc: FormingDocsType = {
					advType: 'vk',
					budget: detailedCampaign.internet_vk.amount || 0,
					fact_cost: detailedCampaign.internet_vk.fact_cost
						? parseFloat(detailedCampaign.internet_vk.fact_cost)
						: 0,
					id: detailedCampaign.internet_vk.id,
				}
				docs.push(vkFormingDoc)
			}

			if (detailedCampaign.internet_yandex.is_selected) {
				const yandexFormingDoc: FormingDocsType = {
					advType: 'yandex',
					budget: detailedCampaign.internet_yandex.amount || 0,
					fact_cost: detailedCampaign.internet_yandex.fact_cost
						? parseFloat(detailedCampaign.internet_yandex.fact_cost)
						: 0,
					id: detailedCampaign.internet_yandex.id,
				}
				docs.push(yandexFormingDoc)
			}

			if (detailedCampaign.adstream.is_selected) {
				const adstreamFormingDocs: FormingDocsType = {
					advType: 'adstream',
					budget: detailedCampaign.adstream.amount || 0,
					fact_cost: detailedCampaign.adstream.fact_cost
						? parseFloat(detailedCampaign.adstream.fact_cost)
						: 0,
					id: detailedCampaign.adstream.id,
				}
				docs.push(adstreamFormingDocs)
			}
			return docs
		}
	)

	const handleFactCostChange = async (
		id: string | number,
		factCost: string,
		type:
			| 'yandex'
			| 'vk'
			| 'adstream'
			| 'indoor'
			| 'outdoor'
			| 'districtbooking'
	) => {
		setIsLoading(true)

		try {
			await moderationApi.patchAdvItem(
				detailedCampaign.id,
				type,
				{
					fact_cost: factCost,
				},
				id.toString()
			)

			const newDocs = formingDocs.map((doc) => {
				if (doc.id === id) {
					doc.fact_cost = factCost
				}
				return doc
			})
			setFormingDocs(newDocs)
		} catch {}
		setIsLoading(false)
	}

	const handleManagerConfirm = async () => {
		await moderInterface.sendManagerConfirm(detailedCampaign.id)
		onClose()
	}

	return (
		<>
			<div className={cn(s.formingDocsList)}>
				{formingDocs.map((doc) => (
					<FormingDocsItem
						onFactCostChange={(value: string) =>
							handleFactCostChange(doc.id, value, doc.advType)
						}
						budget={doc.budget}
						factCost={doc.fact_cost.toString()}
						key={doc.id}
						advType={doc.advType}
						id={doc.id}
					/>
				))}

				<div
					style={{
						background: '#F5F5F5',
						padding: '12px 16px',
						borderRadius: '8px',
					}}
				>
					<h6
						style={{
							color: 'green',
							margin: 0,
							display: 'flex',
							alignItems: 'center',
							gap: '8px',
						}}
					>
						<Warning
							style={{
								fill: 'orange',
								width: '48px',
								height: '48px',
							}}
						/>
						Проверьте привильность заполненных полей. Cформировать
						закрывающие документы можно только один раз.
					</h6>
				</div>
			</div>

			<Modal.Footer>
				<Button variant="secondary" onClick={onClose}>
					Отменить
				</Button>
				<Button
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '8px',
					}}
					disabled={
						isLoading ||
						detailedCampaign.is_manager_confirm ||
						isModerLoading
					}
					variant="primary"
					onClick={handleManagerConfirm}
				>
					Сформировать
					{(isLoading || isModerLoading) && (
						<Spinner animation="border" size="sm" />
					)}
				</Button>
			</Modal.Footer>
		</>
	)
}
