import React, { useMemo } from 'react'
import { MediaRequirements } from 'src/stores/technical-requirements-store'
import { prepareMediaRequirementsData } from 'src/utils'
import s from './common.module.scss'

type Props = {
	mediaRequierments: MediaRequirements
	styles?: React.CSSProperties
}

export const MediaFormatRequirements = ({
	mediaRequierments,
	styles,
}: Props) => {
	const data = useMemo(() => {
		return prepareMediaRequirementsData(mediaRequierments)
	}, [mediaRequierments])
	return (
		<div className={s.mediaRequirementsContainer} style={styles}>
			<div className={s.mediaRequirements}>
				{Object.keys(data).map((key) => (
					<MediaRequirementsField
						key={key}
						name={key}
						value={data[key]}
					/>
				))}
			</div>
		</div>
	)
}

type MediaRequirementsFieldProps = {
	name: string
	value: string | string[]
}
const MediaRequirementsField = ({
	name,
	value,
}: MediaRequirementsFieldProps) => {
	return (
		<div className={s.mediaRequirementsField}>
			<span>{name}</span>
			<div>
				{typeof value === 'string' ? (
					<p>{value}</p>
				) : (
					value.map((el, index) => <p key={index}>{el}</p>)
				)}
			</div>
		</div>
	)
}
