import React, { FC } from 'react'
import { ReusableModalProps } from '../../../../UtilityComponents/ReusableModals/ReusableModalsProvider'
import CreateCreativeForm, { CreateCreativeFields } from './CreateCreativeForm'
import MobileModal from '../../../../UtilityComponents/MobileModal'
import { useConfirm } from '../../../../UtilityComponents/ConfirmModalProvider'

const CreateCreativeMobileModal: FC<ReusableModalProps<CreateCreativeFields>> =
	({ show, onHide, onDone }) => {
		const { confirm } = useConfirm()
		const onSubmit = (values: CreateCreativeFields) => {
			onDone(values)
		}
		const onCancel = async () => {
			const answer = await confirm({
				title: 'Вы уверены, что хотите отменить создание материала?',
				text: 'После этого действия все заполненные поля сбросятся',
				closeButton: true,
				declineText: 'Нет, отменить',
				acceptText: 'Да, уверен',
				acceptVariant: 'danger',
			})
			answer && onHide()
		}

		return (
			<MobileModal
				onHide={onCancel}
				show={show}
				title={'Создание материала'}
			>
				<CreateCreativeForm onHide={onCancel} onSubmit={onSubmit} />
			</MobileModal>
		)
	}

export default CreateCreativeMobileModal
