import React from 'react'
import { ReactComponent as Left } from 'src/assets/images/go-left.svg'
import { ReactComponent as Right } from 'src/assets/images/go-right.svg'

type ConditionArrowLeftRightProps = {
	open: boolean
	variant?: 'short' | 'long'
	styles?: React.CSSProperties
}

export const ConditionArrowLeftRight = ({
	open,
	variant = 'short',
	styles,
}: ConditionArrowLeftRightProps) => {
	if (variant === 'long') {
		return open ? (
			<i className="bi bi-arrow-right"></i>
		) : (
			<i className="bi bi-arrow-left"></i>
		)
	}
	return open ? <Left style={styles} /> : <Right style={styles} />
}
