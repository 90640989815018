/* eslint-disable */

import React, { useCallback, useEffect, useRef, useState } from 'react'
import useDebounce from '../../utils/useDebounce'
import '../../styles/provider-map.scss'
import { MapPoint } from '../_provider/Bookings/filters/FilterButtons'
import s from '../_provider/Bookings/content/list/list.module.scss'
import { renderToString } from 'react-dom/server'
import icon_billboard from 'src/assets/images/icon_billboard.svg'

export interface IProviderYandexMap {
	width?: number | string
	height?: number | string
	fixed?: boolean
	mapPoints?: MapPoint[] // Add this line
	zoom?: number
	singleCoords?: any[] | null
	singleCoordsBigLabel?: boolean
	isMobile?: boolean
	withZoomControls?: boolean
	onClick?: any
	adress?: string
	onAddressFound?: any
	currentPickerCoords?: any[] | null
	onAdressByCoords?: (adress: string) => void
	setAcceptButtonState?: any
	placementsResult?: any
	bookingCoords?: [number, number]
}

// @ts-ignore
const ymaps = window.ymaps
const POLYGON_CENTER = [55.682246, 37.555671]

const ProviderYandexMap: React.FC<IProviderYandexMap> = ({
	width,
	height,
	fixed,
	mapPoints,
	zoom = 10,
	singleCoords,
	isMobile,
	withZoomControls,
	onClick,
	adress,
	onAddressFound,
	currentPickerCoords,
	onAdressByCoords,
	setAcceptButtonState,
	singleCoordsBigLabel = false,
	placementsResult,
	bookingCoords,
}) => {
	const mapRef = useRef(null)
	const mapObjectRef = useRef<any>(null)
	const [newAdressCoords, setNewAdressCoords] = useState([])
	const [debouncedNewAdressCoords] = useDebounce(newAdressCoords, 300)
	let placement_layout = null
	const [selectedCard, setSelectedCard] = useState<any>(null)

	useEffect(() => {
		ymaps.ready(() => {
			if (!mapRef.current) return

			mapObjectRef.current = new ymaps.Map(
				mapRef.current,
				{
					center: singleCoords ? singleCoords : POLYGON_CENTER,
					zoom,
					controls: [],
				},
				{
					searchControlProvider: 'yandex#search',
					yandexMapDisablePoiInteractivity: true,
					suppressMapOpenBlock: true,
					autoFitToViewport: 'always',
				}
			)

			if (withZoomControls) drawZoomControls()
			if (fixed) fixedMap()
			if (singleCoords) drawSingleCoords()
			if (bookingCoords) {
				drawBookingCoords()
			}
			if (onClick) {
				mapObjectRef.current.events.add('click', (e) => {
					onClick()
				})
			}
			if (bookingCoords) {
			}
			if (onAddressFound) {
				let currentZoom = zoom
				mapObjectRef.current.events.add('actiontick', function (e) {
					const placemark = mapObjectRef.current.geoObjects.get(0)
					if (!placemark) return
					if (
						parseInt(e.get('tick').zoom) !== currentZoom &&
						placemark
					) {
						currentZoom = e.get('tick').zoom
						mapObjectRef.current.setZoom(currentZoom)
						mapObjectRef.current.setCenter(
							placemark.geometry.getCoordinates()
						)
						setAcceptButtonState(true)
						return false
					}
					setAcceptButtonState(false)
					let tick = e.get('tick')
					placemark.geometry.setCoordinates(
						mapObjectRef.current.options
							.get('projection')
							.fromGlobalPixels(tick.globalPixelCenter, tick.zoom)
					)
					setNewAdressCoords(
						mapObjectRef.current.options
							.get('projection')
							.fromGlobalPixels(tick.globalPixelCenter, tick.zoom)
					)
				})
			}
			if (currentPickerCoords && onAdressByCoords) {
				placement_layout = ymaps.templateLayoutFactory.createClass(
					`<div id="ymaps_provider_placement_picker" ">
                        <div class="content">            
                <h6>{{properties.title}}</h6>
                <span class="index">{{properties.country}}</span>
                <span class="coords">Координаты: {{properties.coords}}</span>
                        </div>
                 </div>`
				)
				const placemark_adress = new ymaps.Placemark(
					currentPickerCoords,
					{
						coords: CoordsToString(currentPickerCoords),
						title: '',
						country: '',
					},
					{
						iconLayout: placement_layout,
						iconShape: {
							type: 'Rectangle',
							coordinates: [
								[0, 0],
								[255, 80],
							],
						},
						iconOffset: [-127, -90],
					}
				)
				mapObjectRef.current.geoObjects.removeAll()
				mapObjectRef.current.geoObjects.add(placemark_adress)
				mapObjectRef.current.setCenter(currentPickerCoords)
				// Возможно нужно оставить
				// ymaps.geocode(currentPickerCoords, { results: 1 }).then((res: any) => {
				//   const firstGeoObject = res.geoObjects.get(0);
				//   if (firstGeoObject) {
				//     onAdressByCoords(firstGeoObject.getAddressLine());
				//   }
				// });
			}
		})

		return () => {
			// if (mapObjectRef.current && mapRef.current) {
			//   if (mapObjectRef.current) mapObjectRef?.current?.destroy();
			// }
		}
	}, [])

	useEffect(() => {
		if (newAdressCoords.length === 0) return
		void onAddressFound?.(newAdressCoords)

		ymaps.ready(() => {
			const placemark = mapObjectRef.current.geoObjects.get(0)
			if (!placemark) return
			if (setAcceptButtonState) setAcceptButtonState(false)
			placemark.properties.set('coords', CoordsToString(newAdressCoords))
			ymaps.geocode(newAdressCoords, { results: 1 }).then((res: any) => {
				const firstGeoObject = res.geoObjects.get(0)
				if (firstGeoObject) {
					let fullAdress = firstGeoObject.getAddressLine()
					let country = firstGeoObject.getCountry() + ','
					//remove AdministrativeAreas
					firstGeoObject
						.getAdministrativeAreas()
						.forEach((str, index) => {
							fullAdress = fullAdress.replace(str + ',', ' ')
							country += ` ${str}${
								index ===
								firstGeoObject.getAdministrativeAreas().length -
									1
									? ''
									: ','
							}`
						})
					fullAdress = fullAdress.replace(
						firstGeoObject.getCountry() + ',',
						''
					)
					placemark.properties.set('title', fullAdress)
					placemark.properties.set('country', country)
					if (setAcceptButtonState) setAcceptButtonState(true)
				}
			})
		})
	}, [debouncedNewAdressCoords])
	const drawZoomControls = useCallback(() => {
		const ZoomLayout = ymaps.templateLayoutFactory.createClass(
				'<div>' +
					"<div id='ymaps_zoom-in' class='btn'><i class=\"bi bi-plus\"></i></div>" +
					"<div id='ymaps_zoom-out' class='btn'><i class=\"bi bi-dash\"></i></div>" +
					'</div>',
				{
					// Переопределяем методы макета, чтобы выполнять дополнительные действия
					// при построении и очистке макета.
					build: function () {
						// Вызываем родительский метод build.
						ZoomLayout.superclass.build.call(this)

						// Привязываем функции-обработчики к контексту и сохраняем ссылки
						// на них, чтобы потом отписаться от событий.
						this.zoomInCallback = ymaps.util.bind(this.zoomIn, this)
						this.zoomOutCallback = ymaps.util.bind(
							this.zoomOut,
							this
						)

						// Начинаем слушать клики на кнопках макета.
						// @ts-ignore
						document
							.getElementById('ymaps_zoom-in')
							.addEventListener('click', this.zoomInCallback)
						// @ts-ignore
						document
							.getElementById('ymaps_zoom-out')
							.addEventListener('click', this.zoomOutCallback)
					},

					clear: function () {
						ZoomLayout.superclass.clear.call(this)
					},

					zoomIn: function () {
						const map = this.getData().control.getMap()
						map.setZoom(map.getZoom() + 1, { checkZoomRange: true })
					},

					zoomOut: function () {
						const map = this.getData().control.getMap()
						map.setZoom(map.getZoom() - 1, { checkZoomRange: true })
					},
				}
			),
			zoomControl = new ymaps.control.ZoomControl({
				options: {
					layout: ZoomLayout,
					position: isMobile
						? {
								right: 8,
								top: 250,
						  }
						: {
								bottom: 24,
								left: 24,
						  },
				},
			})
		mapObjectRef.current.controls.add(zoomControl)
	}, [])

	const fixedMap = useCallback(() => {
		mapObjectRef.current.behaviors.disable([
			'drag',
			'dblClickZoom',
			'rightMouseButtonMagnifier',
			'multiTouch',
			'scrollZoom',
		])
	}, [])

	// Создание макета балуна
	var MyBalloonLayout = ymaps.templateLayoutFactory.createClass(
		`<div class="popover top">
	</div>`
	)

	var MyBalloonContentLayout = ymaps.templateLayoutFactory.createClass(
		'<div class="container text-white"><div class="column-md-6">$[properties.balloonHeader] </div>' +
			'<div class="column-md-6">$[properties.balloonContent] </div> </div>'
	)

	const drawSingleCoords = useCallback(() => {
		if (mapPoints && mapObjectRef.current) {
			mapObjectRef.current.geoObjects.removeAll()
			mapPoints.forEach((point) => {
				const placemark = new ymaps.Placemark(
					[point.lat, point.lon],
					{
						balloonContentBody: createBalloonContent(point),
						iconContent: createBalloonContent(point),
						type: point.placement_type,
						iconImageSize: point.size,
						iconImageOffset: [-point.lat[0] / 2, -point.lon[1] / 2],
					},
					{
						iconLayout: 'default#imageWithContent',
						iconImageOffset: [-6, -10], // смещение картинки
						balloonShadow: false,
						balloonLayout: MyBalloonLayout,
						balloonContentLayout: MyBalloonContentLayout,
						balloonPanelMaxMapArea: 0,

						// Не скрываем иконку при открытом балуне.
						hideIconOnBalloonOpen: false,
						// И дополнительно смещаем балун, для открытия над иконкой.
						balloonOffset: [-100, -230],
						balloonMaxWidth: 100,
					}
				)

				placemark.events.add('click', (e) => {
					// код для приближения к точке при клике
					const target = e.get('target')
					const coordinates = target.geometry.getCoordinates()
					mapObjectRef.current.setCenter(coordinates, zoom)

					setSelectedCard(point.id)
				})

				mapObjectRef.current.geoObjects.add(placemark)
			})
			mapObjectRef.current.setBounds(
				mapObjectRef.current.geoObjects.getBounds()
			)
		}
	}, [mapPoints])
	const drawBookingCoords = useCallback(() => {
		if (bookingCoords) {
			const placemark = new ymaps.Placemark(
				bookingCoords,
				{},
				{
					iconLayout: 'default#image',
					iconImageHref: icon_billboard,
					iconImageSize: [40, 40],
					iconImageOffset: [-20, -20],
					hintCloseTimeout: 100,
					hintOpenTimeout: 100,
				}
			)

			mapObjectRef.current.geoObjects.add(placemark)
		}
	}, [bookingCoords])
	const createBalloonContent = (point) => {
		const cardContent = `<div id="ymaps_baloon-container_{{properties.id}}" class="ymaps_baloon-container {{properties.state}} {{properties.focus}}"><div class="{{properties.state}} ymaps_booking-baloon {{properties.focus}}" data-focus="{{properties.focus}}"">
		
        <div class="icon">
		<div class="${s.baloonIcon}">
			${point.banner_im
				.replace('width="24" height="24"', 'width="24" height="24"')
				.replace('fill="#212529"', 'fill="white"')}
		</div>
        </div>
        <div class="data">
            <div class="title">
            ${point.placement_type}
			</div>
            <div class="size">
            ${point.advertising_company} ${getBookingWord(
			point.advertising_company
		)}
			</div>
        </div>
    </div>
    <div class="dot"></div>
</div>`
		return cardContent
	}

	// useEffect(() => {

	// 	ymaps.ready(() => {
	// 		drawSingleCoords();
	// 	});
	// }, [mapPoints]);

	const drawPlacementResult = useCallback(() => {
		if (placementsResult && mapObjectRef.current) {
			mapObjectRef.current.geoObjects.removeAll()
			placementsResult.forEach((result) => {
				const placemark = new ymaps.Placemark(
					[result.lat, result.lon],
					{
						balloonContentBody: createBalloonContent(result),
						iconContent: createBalloonContent(result),
					},
					{
						iconLayout: 'default#imageWithContent',
						// Своё изображение иконки метки.
						//   iconImageHref: 'js/map_metka.png', // картинка иконки
						//   iconImageSize: [39, 39], // размеры картинки
						iconImageOffset: [-6, -10], // смещение картинки
						balloonShadow: false,
						balloonLayout: MyBalloonLayout,
						balloonContentLayout: MyBalloonContentLayout,
						balloonPanelMaxMapArea: 0,

						// Не скрываем иконку при открытом балуне.
						hideIconOnBalloonOpen: false,
						// И дополнительно смещаем балун, для открытия над иконкой.
						balloonOffset: [-100, -230],
						balloonMaxWidth: 100,
					}
				)

				placemark.events.add('click', (e) => {
					const target = e.get('target')
					const coordinates = target.geometry.getCoordinates()
					mapObjectRef.current.setCenter(coordinates, zoom)
				})

				mapObjectRef.current.geoObjects.add(placemark)
			})
			mapObjectRef.current.setBounds(
				mapObjectRef.current.geoObjects.getBounds()
			)
		}
	}, [placementsResult])

	useEffect(() => {
		ymaps.ready(() => {
			drawPlacementResult()
			drawSingleCoords()
			drawBookingCoords()
		})
	}, [placementsResult, mapPoints, bookingCoords])

	//при получении адреса
	useEffect(() => {
		if (adress) {
			ymaps.ready(() => {
				ymaps.geocode(adress, { results: 1 }).then((res: any) => {
					if (onAddressFound && res.geoObjects.get(0)) {
						if (setAcceptButtonState) setAcceptButtonState(false)
						const coordinates =
							res.geoObjects.get(0).geometry._coordinates
						placement_layout =
							ymaps.templateLayoutFactory.createClass(
								`<div id="ymaps_provider_placement_picker" ">
                        <div class="content">            
                <h6>{{properties.title}}</h6>
                <span class="index">{{properties.country}}</span>
                <span class="coords">Координаты: {{properties.coords}}</span>
                        </div>
                 </div>`
							)
						const placemark_adress = new ymaps.Placemark(
							coordinates,
							{
								coords: CoordsToString(coordinates),
								title: '',
								country: '',
							},
							{
								iconLayout: placement_layout,
								iconShape: {
									type: 'Rectangle',
									coordinates: [
										[0, 0],
										[255, 80],
									],
								},
								iconOffset: [-127, -90],
							}
						)
						mapObjectRef.current.geoObjects.removeAll()
						mapObjectRef.current.geoObjects.add(placemark_adress)
						mapObjectRef.current.setCenter(coordinates)
						// mapObjectRef.current.setZoom(15);
						//Проверка на 2 одинаковых адреса подряд
						if (
							coordinates[0] === newAdressCoords[0] &&
							coordinates[1] === newAdressCoords[1]
						) {
							ymaps
								.geocode(coordinates, { results: 1 })
								.then((res: any) => {
									const firstGeoObject = res.geoObjects.get(0)
									if (firstGeoObject) {
										let fullAdress =
											firstGeoObject.getAddressLine()
										let country =
											firstGeoObject.getCountry() + ','
										//remove AdministrativeAreas
										firstGeoObject
											.getAdministrativeAreas()
											.forEach((str, index) => {
												fullAdress = fullAdress.replace(
													str + ',',
													' '
												)
												country += ` ${str}${
													index ===
													firstGeoObject.getAdministrativeAreas()
														.length -
														1
														? ''
														: ','
												}`
											})
										fullAdress = fullAdress.replace(
											firstGeoObject.getCountry() + ',',
											''
										)
										placemark_adress.properties.set(
											'title',
											fullAdress
										)
										placemark_adress.properties.set(
											'country',
											country
										)
									}
								})
						}
						setAcceptButtonState(true)
						setNewAdressCoords(coordinates)
					}
				})
			})
		}
	}, [adress])

	return <div style={{ width, height }} ref={mapRef} />
}

export default ProviderYandexMap

function getBookingWord(number) {
	const lastDigit = number % 10
	const lastTwoDigits = number % 100

	if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
		return 'броней'
	}

	if (lastDigit === 1) {
		return 'бронь'
	}

	if (lastDigit >= 2 && lastDigit <= 4) {
		return 'брони'
	}

	return 'броней'
}

function CoordsToString(coords: number[], fractionDigits = 4) {
	if (coords.length !== 2) return undefined
	const lat = coords[0].toFixed(fractionDigits)
	const lon = coords[1].toFixed(fractionDigits)
	return lat.replace('.', '°') + "'N" + ' ' + lon.replace('.', '°') + "'E"
}
