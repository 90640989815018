import React from 'react'
import { Button } from 'react-bootstrap'
import ImageFullScreenWrapper from 'src/components/UtilityComponents/ImageFullScreenWrapper'
import { PlacementShort } from 'src/stores/ADMarketTypes.types'
import s from './addedItemsControl.module.scss'

type Props = {
	addedPlacement: PlacementShort[]
	onRemoveItem: (itemId: number) => void
}
export const RightPanelPlacementsList = ({
	addedPlacement,
	onRemoveItem,
}: Props) => {
	return (
		<div className={s.addedList}>
			{addedPlacement.map((el) => (
				<div key={el.pk} className={s.addedItemWrapper}>
					<div className={s.addedItem}>
						<ImageFullScreenWrapper>
							<img
								className={s.addedItemImage}
								src={
									el.image_url ||
									'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
								}
								alt={el.size}
							/>
						</ImageFullScreenWrapper>
						<div className={s.itemInfoWraper}>
							<div className={s.itemInfo}>
								<div>
									<div>{el.name}</div>
								</div>

								<span style={{ color: '#6C757D' }}>{`${
									el.image_type === 'billboard'
										? 'Билборд'
										: 'Сити-формат'
								} ${el.size}`}</span>
							</div>
							<div
								style={{
									display: 'flex',
									alignItems: 'flex-start',
								}}
							>
								<Button
									className={s.removeItemButton}
									variant="text"
									onClick={() => onRemoveItem(el.pk)}
								>
									<i
										className="bi bi-trash"
										color="inherit"
									></i>
								</Button>
							</div>
						</div>
					</div>
				</div>
			))}
		</div>
	)
}
