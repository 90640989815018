import React from 'react'
import { FormatsSelector } from './FormatsSelector'
import { TotalCampaignBudget } from '../../total-budget/TotalCampaignBudget'
import { AdvFormatsNav } from '../../adv-formats-navigation/AdvFormatsNav'
import { AdvFormats } from '../../../../types'

type Props = {
	onSelectFormat: (format: AdvFormats) => void
	onBack: () => void
	onNextStep: () => void
	onRecommendationsClick: () => void
}
export const Formats = ({
	onBack,
	onNextStep,
	onSelectFormat,
	onRecommendationsClick,
}: Props) => {
	return (
		<>
			<FormatsSelector
				onSelectFormat={onSelectFormat}
				onRecommendationsClick={onRecommendationsClick}
			/>

			<TotalCampaignBudget />

			<AdvFormatsNav onBack={onBack} onNextStep={onNextStep} />
		</>
	)
}
