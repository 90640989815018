import React from 'react'
import { ImportProcessData, ImportState } from './PlacementsImportExport'

type Props = {
	importProcessData: ImportProcessData | null
}
export const ImportProcess = ({ importProcessData }: Props) => {
	const handledPersent =
		importProcessData?.state !== ImportState.ERROR
			? importProcessData?.imported_percent
			: 100
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '4px',
				fontFamily: 'Inter',
				fontSize: '12px',
				fontWeight: 400,
				lineHeight: '20px',
				letterSpacing: '-0.03em',
			}}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<div>Импортируем рекламные носители</div>
				<div>
					{importProcessData?.all_placements ? (
						<div>
							<span>
								{importProcessData.imported_placements}{' '}
							</span>
							<span style={{ color: '#6C757D' }}>
								{' '}
								из {importProcessData.all_placements}
							</span>
						</div>
					) : (
						<span>Вычисляем</span>
					)}
				</div>
			</div>
			<div
				className={'w-100'}
				style={{
					height: '4px',
					borderRadius: '8px',
					backgroundColor: '#E9ECEF',
					position: 'relative',
				}}
			>
				<div
					style={{
						width: `${handledPersent || 0}%`,
						backgroundColor: '#007BFF',
						borderRadius: '8px',
						height: '4px',
						position: 'absolute',
					}}
				></div>
			</div>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					color: '#6C757D',
				}}
			>
				<div>{`${handledPersent}%`}</div>
				<div>{`${importProcessData?.size_file} МБ`}</div>
			</div>
		</div>
	)
}
