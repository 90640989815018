import React from 'react'
import { FOOTER_LINKS } from '../../../Footer'
import cn from 'classnames'
import s from './clientSidebarFooter.module.scss'

export const ClientSidebarFooter = (props: any) => {
	return (
		<div className={cn(s.links)}>
			{FOOTER_LINKS.map((link) => (
				<a
					href={link.href}
					key={link.href}
					className={link.blue ? 'text-primary' : ''}
					target={'_blank'}
					rel="noreferrer"
				>
					{link.label}
				</a>
			))}
			<p className={'mt-4'}>© 2024 ООО «ЭдТех Инвест»</p>
		</div>
	)
}
