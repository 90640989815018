import React, { FC, useState } from 'react'
import {
	InternetAdType,
	InternetState,
} from '../../../../stores/ADMarketTypes.types'
import { Button, Modal } from 'react-bootstrap'
import { useConfirm } from '../../../UtilityComponents/ConfirmModalProvider'
import useCampaignData from '../../../../stores/campaignData'
import DetailItem from '../../../../utils/DetailItem'
import s from './InternetAd.module.scss'
import { removeHttps } from '../../create-campaign/Step2-ADformats/InternetAD/URLutils'
import VkButtonTextMap from '../../create-campaign/Step3-Upload/Internet/VkButtonText.data'
import IntlFormatter from '../../../../utils/intl-money-formatter'
import { EditVinstantModal } from '../common/edit-vinstant-modal/EditVinstantModal'
import { useCampaignInfoStore } from '../store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from '../store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import useNotificationData from 'src/stores/notificationsData'
import useResponsive from 'src/utils/useResponsive'
import { EditVinstantInputButton } from '../common/edit-vinstant-input-button/EditVinstantInputButton'

interface IInternetControls {
	internet: InternetAdType
	name: 'vk' | 'yandex'
}

const InternetControls: FC<IInternetControls> = ({ internet, name }) => {
	const { confirm } = useConfirm()

	const { isMobile } = useResponsive()
	const [campaign, campaignInterface] = useCampaignData()
	const [showTerms, setShowTerms] = useState(false)
	const showDeleteButton =
		internet.state !== InternetState.Canceled &&
		internet.state !== InternetState.Reject &&
		internet.state !== InternetState.Completed

	const [isEditVinstantOpen, setIsEditVinstantOpen] = useState(false)

	const internetVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: name === 'vk' ? 'vk_id' : 'yandex_id',
			itemId: internet.id.toString(),
		})
	)
	const [_, notificationsInterface] = useNotificationData()

	const editCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated(name)

	const showEditVinstantButton =
		internet.state === InternetState.Adjustment && !!internetVinstantLink

	const handleIsEditVinstantOpen = () => {
		if (editCreativeVinstantStatus === 'start') {
			return
		}

		if (isEditVinstantOpen) {
			setIsEditVinstantOpen(false)
		} else {
			if (showEditVinstantButton) {
				setIsEditVinstantOpen(true)
			}
		}
	}

	async function DeleteInternetHandler() {
		const title =
			name === 'yandex'
				? 'Вы уверены, что хотите удалить рекламную сеть Яндекса из рекламной кампании?'
				: 'Вы уверены, что хотите удалить рекламную сеть VK из рекламной кампании?'
		const result = await confirm({
			text: 'После этого действия вы не сможете ее вернуть обратно',
			title,
			closeButton: 'true',
			acceptText: 'Да, уверен',
			declineText: 'Нет, отменить',
			acceptVariant: 'danger',
		})
		if (result) {
			const fd = new FormData()
			fd.append('to_canceled', 'true')
			name === 'vk' &&
				(await campaignInterface.patchInternetVk(campaign.id, fd))
			name === 'yandex' &&
				(await campaignInterface.patchInternetYandex(campaign.id, fd))
		}
	}
	const detailItemProps = { leftFixWidth: '220px' }

	return (
		<div className={'d-flex gap-2'}>
			<div style={{ flex: '1 1 auto' }}>
				<Button variant={'light'} onClick={() => setShowTerms(true)}>
					Смотреть условия размещения
				</Button>
			</div>
			<div className={'d-flex gap-2'}>
				{showDeleteButton && (
					<Button
						variant={'light'}
						onClick={DeleteInternetHandler}
						className={s.trashBtn}
					>
						<svg
							width="12"
							height="14"
							viewBox="0 0 12 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M2.6665 5.00004V12.3334H9.33317V5.00004H10.6665V13C10.6665 13.3682 10.368 13.6667 9.99984 13.6667H1.99984C1.63165 13.6667 1.33317 13.3682 1.33317 13V5.00004H2.6665ZM5.33317 5.00004V11H3.99984V5.00004H5.33317ZM7.99984 5.00004V11H6.6665V5.00004H7.99984ZM7.99984 0.333374C8.28679 0.333374 8.54155 0.516994 8.63229 0.789222L9.1465 2.33271L11.3332 2.33337V3.66671H0.666504V2.33337L2.8525 2.33271L3.36738 0.789222C3.45812 0.516994 3.71288 0.333374 3.99984 0.333374H7.99984ZM7.51933 1.66671H4.48034L4.25784 2.33271H7.74117L7.51933 1.66671Z"
								fill="#212529"
							/>
						</svg>
					</Button>
				)}
			</div>

			<EditVinstantModal
				newContentKey={name}
				editVinstantLink={internetVinstantLink}
				onClose={() => setIsEditVinstantOpen(false)}
				open={isEditVinstantOpen}
			/>
			{showTerms && (
				<Modal
					show={showTerms}
					onHide={() => setShowTerms(false)}
					centered
					animation={true}
				>
					<Modal.Header closeButton>
						<Modal.Title as={'h5'} style={{ marginLeft: '8px' }}>
							Условия размещения
							{name === 'vk' && ' VK Реклама'}
							{name === 'yandex' && ' Яндекс'}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body style={{ padding: '40px' }}>
						<DetailItem
							left={'Рекламируемый сайт'}
							right={
								<a href={internet.link || ''}>
									{removeHttps(internet.link || '')}
								</a>
							}
							ellipsis
							{...detailItemProps}
						/>
						<DetailItem
							left={'Возрастная маркировка'}
							right={'0+'}
							show={name === 'vk'}
							{...detailItemProps}
						/>
						<DetailItem
							left={'Заголовок'}
							right={internet.heading}
							breakWord
							{...detailItemProps}
						/>
						<DetailItem
							left={
								name === 'yandex'
									? 'Текст объявления'
									: 'Описание'
							}
							right={internet.text}
							breakWord
							{...detailItemProps}
						/>
						<DetailItem
							left={'Надпись на кнопке'}
							right={VkButtonTextMap.get(
								internet?.button_text || ''
							)}
							show={name === 'vk'}
							{...detailItemProps}
						/>
						<DetailItem
							left={'Бюджет кампании'}
							right={IntlFormatter.format(internet.amount, false)}
							{...detailItemProps}
						/>
						<DetailItem
							left={'Оплата'}
							show={name === 'yandex'}
							right={'За клики'}
							{...detailItemProps}
						/>
						<DetailItem
							left={'Количество кликов'}
							right={'40 055'}
							{...detailItemProps}
						/>
						<DetailItem
							left={'Рекламу увидят'}
							right={'~ 1 500 человек'}
							show={name === 'yandex'}
							{...detailItemProps}
						/>
						<DetailItem
							left={'Стоимость за 1 000 показов'}
							right={IntlFormatter.format('1046.53', false)}
							show={name === 'vk'}
							{...detailItemProps}
						/>
						<DetailItem
							left={'Прогнозная стоимость:'}
							right={'52 326,53 ₽'}
							{...detailItemProps}
							bottomDivider={false}
						/>
					</Modal.Body>
				</Modal>
			)}
		</div>
	)
}

export default InternetControls
