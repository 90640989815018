import { motion } from 'framer-motion'
import React from 'react'
import { ConditionArrowUpDown } from 'src/components/UtilityComponents'

type ShowHideArrowButtonProps = {
	openTitle: string
	closeTitle: string
	open: boolean
	setOpen: (open: boolean) => void
}
export const ShowHideArrowButton = ({
	closeTitle,
	open,
	openTitle,
	setOpen,
}: ShowHideArrowButtonProps) => {
	return (
		<motion.div
			whileHover={{ scale: 1.1 }}
			whileTap={{ scale: 0.9 }}
			animate={{ y: open ? 0 : 0 }}
			transition={{ duration: 0.3 }}
			onClick={() => setOpen(!open)}
			style={{
				display: 'flex',
				margin: '6px 0',
				gap: '6px',
				color: '#6C757D',
			}}
		>
			<div>{open ? closeTitle : openTitle}</div>
			<ConditionArrowUpDown
				styles={{ width: '16px', fill: '#6C757D' }}
				variant="long"
				open={open}
			/>
		</motion.div>
	)
}
