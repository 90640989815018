import React from 'react'
import s from './NotificationsPage.module.scss'
import { useHistory } from 'react-router-dom'
import NotificationsContainer from './NotificationsContainer'
import useNotificationData from '../../stores/notificationsData'

const NotificationsPage = (props) => {
	const history = useHistory()
	const [notifications, notificationsInterface] = useNotificationData()
	return (
		<div className={s.container}>
			<header>
				<div className={s.goBack} onClick={() => history.goBack()}>
					<i className="bi bi-arrow-left" />
				</div>
				<span>Уведомления</span>
			</header>

			<section>
				{notifications.length !== 0 && (
					<div className={s.readAll}>
						<a
							href="/"
							onClick={(e) => {
								e.preventDefault()
								notificationsInterface.readAllNotifications()
							}}
						>
							Отметить все как прочитанное
						</a>
					</div>
				)}

				<NotificationsContainer />
			</section>
		</div>
	)
}

export default NotificationsPage
