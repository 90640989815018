import { motion } from 'framer-motion'
import React, { useMemo, useState } from 'react'
import { Badge, Spinner } from 'react-bootstrap'
import Portal from 'src/components/_client/campaigns-list/ClientStatistics/Portal'
import { DetailItemNew } from 'src/utils/DetailItem'
import IntlFormatter from 'src/utils/intl-money-formatter'
import useQuery from 'src/utils/useQuery'
import BookingsButtons from '../details/BookingsButtons'
import { Loader } from './Loader'
import { ReactComponent as More } from '../../../../../assets/images/more-vert.svg'
import s from './PlacementBookings.module.scss'
import moment from 'moment'
import { SidebarBookingDetail } from './SidebarBookingDetail'
import cn from 'classnames'

export function PlacementBookings({ placement }) {
	const [data, isLoading] = useQuery<any>({
		url: `/core/api/v1/provider/bookings/?placement=${placement.id}`,
		cacheByUrl: true,
	})
	const [selectedBooking, setSelectedBooking] = useState(null)

	if (isLoading) return <Loader />

	if (!data?.length)
		return (
			<div className={s.placeholder}>У этой поверхности нет броней</div>
		)

	return (
		<>
			{data.map((booking) => (
				<PlacementBookingItem
					booking={booking}
					onSelect={setSelectedBooking}
					active={selectedBooking === booking}
					key={booking.id}
				/>
			))}
			{selectedBooking && (
				<SidebarBookingDetail
					booking={selectedBooking}
					placement={placement}
					onHide={() => setSelectedBooking(null)}
				/>
			)}
		</>
	)
}

function PlacementBookingItem({ booking, active, onSelect }) {
	const [isActionsOpened, setIsActionsOpened] = useState(false)

	const start_date = moment(booking.advertising_company.start_date).format(
		'D '
	)
	const end_date = moment(booking.advertising_company.end_date).format(
		'D MMMM YYYY'
	)

	return (
		<motion.div
			initial={{ opacity: 0, y: -10 }} // Начальные значения стилей (невидимый и смещение по y)
			animate={{ opacity: 1, y: 0 }} // Значения стилей во время анимации (видимый и без смещения)
			transition={{ duration: 0.3 }} // Длительность анимации в секундах
			onClick={() => onSelect(booking)} // Обработчик клика
			className={cn(s.bookingItem, {
				[s.active]: active,
			})}
			key={booking.id}
		>
			<div className={s.bookingTop}>
				<div className={s.bookingTopLine}>
					<CampaignBadge state={booking.advertising_company.state} />

					<div
						className={s.moreFrame}
						onClick={(e) => {
							e.stopPropagation()
							if (!isActionsOpened) setIsActionsOpened(true)
						}}
					>
						<More className={s.more} />

						<Portal
							onClose={() => {
								setIsActionsOpened(false)
							}}
							isOpened={isActionsOpened}
							zIndex={1057}
						>
							<BookingsButtons booking={booking} vertical />
						</Portal>
					</div>
				</div>
				<h6>{booking.advertising_company.company.legal_short_name}</h6>
			</div>

			<DetailItemNew left={'ID:'} right={booking.placement.external_id} />
			<DetailItemNew
				left={'Срок размещения:'}
				right={`c ${start_date} по ${end_date}`}
			/>

			<DetailItemNew
				left={'Итоговый бюджет:'}
				right={IntlFormatter.format(booking?.amount)}
				noBorder
			/>
		</motion.div>
	)
}

const CampaignBadge = ({ state, ...props }) => {
	const bg = useMemo(() => {
		switch (state) {
			case 'moderation':
				return 'primary'
			case 'confirmed':
				return 'success'
			case 'payment_waiting':
				return 'primary'
			case 'active':
				return 'primary'
			case 'completed':
				return 'success'
			case 'deleted':
				return 'danger'
			case 'canceled':
				return 'danger'
			case 'draft':
				return 'secondary'
		}
	}, [state])
	const text = useMemo(() => {
		switch (state) {
			case 'moderation':
				return 'На модерации'
			case 'confirmed':
				return 'Модерация завершена'
			case 'payment_waiting':
				return 'Ожидает оплаты'
			case 'active':
				return 'Активная'
			case 'completed':
				return 'Завершена'
			case 'deleted':
				return 'Удалена'
			case 'canceled':
				return 'Отменена'
			case 'draft':
				return 'Черновик'
		}
	}, [state])
	return (
		<Badge
			bg={bg}
			style={{
				fontSize: '12px',
				fontWeight: 'normal',
				height: 'fit-content',
			}}
			{...props}
		>
			{text}
		</Badge>
	)
}
