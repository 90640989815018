import React, { useCallback, useMemo } from 'react'
import { AdvFormats } from '../../../types'
import TVSelector from '../../../../TVAd/TVSelector'
import { OutdoorAdv } from '../adv-formats/outdoor/OutdoorAdv'
import { BackToSelectorsCard } from './back-to-selectors-card/BackToSelectorsCard'
import s from './selectedAdvFormat.module.scss'
import { IndoorAdv } from '../adv-formats/indoor/IndoorAdv'
import { PublicTransportAdv } from '../adv-formats/public-transport/PublicTransportAdv'
import { InternetAdv } from '../adv-formats/internet/InternetAdv'
import { TvAdv } from '../adv-formats/tv/TvAdv'

type Props = {
	goToSelectors: () => void
	selectedAdvFormat: AdvFormats
	cn: (className: string) => void
	onBack: () => void
	onNextStep: () => void
}
export const SelectedAdvFormat = ({
	selectedAdvFormat,
	goToSelectors,
	...restProps
}: Props) => {
	const AdvFormat = useCallback(() => {
		switch (selectedAdvFormat) {
			case AdvFormats.Outdoor:
				return <OutdoorAdv goToSelectors={goToSelectors} />

			case AdvFormats.Indoor:
				return <IndoorAdv goToSelectors={goToSelectors} />

			case AdvFormats.Internet:
				return <InternetAdv goToSelectors={goToSelectors} />

			case AdvFormats.PublicTransport:
				return <PublicTransportAdv goToSelectors={goToSelectors} />

			case AdvFormats.TV:
				return <TvAdv goToSelectors={goToSelectors} />

			default:
				return <div></div>
		}
	}, [selectedAdvFormat])

	return (
		<div className={s.selectedAdvFormat}>
			<BackToSelectorsCard onBackClick={goToSelectors} />
			<AdvFormat />
		</div>
	)
}
