import { BASE_URL } from 'src/constants/api'
import { authFetch } from 'src/utils/authFetch'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

export enum CLS_NAME {
	booking = 'booking',
	vkinternetadvertising = 'vkinternetadvertising',
	yandexinternetadvertising = 'yandexinternetadvertising',
	teletargetadversting = 'teletargetadversting',
	adstreamadversting = 'adstreamadversting',
	districtbooking = 'districtbooking',
	publictransportadvertising = 'publictransportadvertising',
}
export enum VinstanCreativeExternalType {
	ss = 'ss',
	bb = 'bb',
	rb = 'rb',
	cf = 'cf',
	rc = 'rc',
	wd = 'wd',
	rd = 'rd',
}

export type VinstantCreativeAdFormatKeys =
	| 'teletarget_id'
	| 'adstream_id'
	| 'booking_id'
	| 'public_transport_id'
	| 'yandex_id'
	| 'vk_id'
	| 'banner_id'

export type VinstantCreative = Record<VinstantCreativeAdFormatKeys, string> & {
	id: number
	external_id: number
	cls_name: CLS_NAME
	instance_id: string
	redirect_url?: string
	template_id?: number
	external_type: VinstanCreativeExternalType
}
type State = {
	vinstantCreative: VinstantCreative[]
	isLoading: boolean
}
type Actions = {
	fetchVinstantCreative: (campaignId: string) => Promise<void>
	resetVinstantCreative: () => void
}

const initialState: State = {
	vinstantCreative: [],
	isLoading: false,
}
export type CampaignInfoStore = State & Actions
export const useCampaignInfoStore = create<CampaignInfoStore>()(
	devtools(
		immer((set) => ({
			//state
			...initialState,

			//actions
			fetchVinstantCreative: async (campaignId: string) => {
				set((state) => {
					state.isLoading = true
				})
				const result = await authFetch<Array<VinstantCreative>>({
					method: 'GET',
					url: `${BASE_URL}/vinstant/link_creative/${campaignId}/`,
				})
				if (Array.isArray(result)) {
					set((state) => {
						state.vinstantCreative = result
						state.isLoading = false
					})
				}
			},
			resetVinstantCreative: () => {
				set((state) => {
					state.vinstantCreative = []
				})
			},
		}))
	)
)
