import React from 'react'
import { BookingType } from 'src/stores/ADMarketTypes.types'
import { BaseAccordion } from './accordions'
import { ReactComponent as Vk } from 'src/assets/images/vk.svg'
import { ReactComponent as Yandex } from 'src/assets/images/yandex.svg'
import { Item } from './Item'
import { Spinner } from 'react-bootstrap'
import { AnimatePresence, motion } from 'framer-motion'

type Props = {
	detailedCampaign: any
	isLoading: boolean
}

const getFormat = (booking: BookingType) => {
	if (
		booking.placement.media_type.image_formats.length > 0 &&
		booking.placement.media_type.video_formats.length > 0
	) {
		return 'Изображение и видео'
	}
	if (booking.placement.media_type.image_formats.length > 0) {
		return 'Изображение'
	}
	if (booking.placement.media_type.video_formats.length > 0) {
		return 'Видео'
	}

	return 'Не определено'
}
export const ExpandedMobileStatistic = ({
	detailedCampaign,
	isLoading,
}: Props) => {
	const { booking, district_booking, vk, yandex, adstream } = detailedCampaign

	const indoor = booking.filter(
		(el) => el.placement.door_type === 'indoor'
	) as Array<any>
	const outdoor = booking.filter(
		(el) => el.placement.door_type === 'outdoor'
	) as Array<any>

	const atLeastOneFormatSelected =
		adstream.is_selected ||
		yandex.is_selected ||
		vk.is_selected ||
		detailedCampaign.indoor_adv_is_selected ||
		!!outdoor.length ||
		!!district_booking.length
	return (
		<AnimatePresence>
			<motion.div
				initial={{ height: 0, opacity: 0 }}
				animate={{ height: 'auto', opacity: 1 }}
				exit={{ height: 0, opacity: 0 }}
				transition={{ duration: 0.3 }}
				style={{
					padding: '0 1rem 1rem 1rem',
					display: 'flex',
					flexDirection: 'column',
					gap: '0.5rem',
				}}
			>
				{(!!outdoor.length || !!district_booking.length) && (
					<BaseAccordion header="Наружная реклама">
						<>
							{outdoor.map((el, index) => (
								<Item
									isBorder={
										outdoor.length - 1 !== index ||
										district_booking.length > 0
									}
									key={el.id}
									stats={{
										clickCount: el.click_count,
										expense: el.expense,
										format: getFormat(el),
										mainTitle:
											el.placement.placement_type.name,
										secondaryTitle: el.placement.name,
										viesCount: el.views_count,
									}}
								/>
							))}
						</>
						<>
							{district_booking.map((el) => (
								<Item
									key={el.id}
									stats={{
										clickCount: el.click_count,
										expense: el.expense,
										format: 'Видео',
										mainTitle: el.city_name,
										viesCount: el.views_count,
									}}
								/>
							))}
						</>
					</BaseAccordion>
				)}

				{!!indoor.length && (
					<BaseAccordion header="Внутренняя реклама">
						<>
							{indoor.map((el, index) => (
								<Item
									isBorder={indoor.length - 1 !== index}
									key={el.id}
									stats={{
										clickCount: el.click_count,
										expense: el.expense,
										format: getFormat(el),
										mainTitle:
											el.placement.placement_type.name,
										secondaryTitle: el.placement.name,
										viesCount: el.views_count,
									}}
								/>
							))}
						</>
					</BaseAccordion>
				)}
				{(vk?.is_selected || yandex?.is_selected) && (
					<BaseAccordion header="Интернет-реклама">
						<>
							{vk.is_selected && (
								<Item
									isBorder={true}
									icon={<Vk />}
									stats={{
										clickCount: vk.click_count,
										expense: vk.expense,
										format: 'Изображение',
										mainTitle: 'ВК-реклама',
										viesCount: vk.views_count,
									}}
								/>
							)}

							{yandex.is_selected && (
								<Item
									icon={<Yandex />}
									stats={{
										clickCount: yandex.click_count,
										expense: yandex.expense,
										format: 'Изображение',
										mainTitle: 'Яндекс-реклама',
										viesCount: yandex.views_count,
									}}
								/>
							)}
						</>
					</BaseAccordion>
				)}

				{adstream?.is_selected && (
					<BaseAccordion header="ТВ-реклама">
						<>
							<Item
								stats={{
									clickCount: adstream.click_count,
									expense: adstream.expense,
									format: 'Видео',
									mainTitle: 'Adstream',
									viesCount: adstream.views_count,
								}}
							/>
						</>
					</BaseAccordion>
				)}

				{!atLeastOneFormatSelected && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<p
							style={{
								width: '100%',
								margin: 0,
								textAlign: 'center',
							}}
						>
							Форматы рекламы не добавлены
						</p>
					</div>
				)}
			</motion.div>
		</AnimatePresence>
	)
}
