const ErrorMessage = 'Ошибка в получение цены'

type IntlType = {
	format: (
		price: string | number | null | undefined,
		nds?: boolean,
		precision?: number
	) => string
}

const format = (
	price: string | number | null | undefined,
	nds = true,
	precision?: number
) => {
	if (price !== 0 && !price) return ErrorMessage
	const isDigitsZero = Number.isSafeInteger(parseFloat(price as string))
	const IntlFormatter = new Intl.NumberFormat('ru-RU', {
		style: 'currency',
		currency: 'RUB',
		currencyDisplay: 'symbol',
		maximumFractionDigits: precision ? precision : isDigitsZero ? 0 : 2,
	})
	if (!nds) return IntlFormatter.format(price as number)
	return IntlFormatter.format(price as number) + ''
}
const IntlFormatter: IntlType = { format }
export default IntlFormatter
