import React from 'react'
import s from './createReportTwo.module.scss'
import cn from 'classnames'
import DateInput from 'src/components/UtilityComponents/DropdownDatePicker'
import moment from 'moment'

type CCreateReportDatePickerProps = {
	startDate: any
	handleStartDateChange: any
	handleEndDateChange: any
	endDate: any
}

export const CreateReportDatePicker = ({
	handleEndDateChange,
	handleStartDateChange,
	startDate,
	endDate,
}: CCreateReportDatePickerProps) => {
	return (
		<div style={{ display: 'flex', gap: '10px' }}>
			<div>
				<DateInput
					type={'start'}
					value={moment(startDate).format('DD.MM.YYYY')}
					onChange={(date) => {
						handleStartDateChange(moment(date).format('YYYY-MM-DD'))
					}}
					firstAvaliableDate={moment(Date.now())}
					error={null}
					withCheck={false}
				/>
			</div>

			<div>
				<DateInput
					type={'end'}
					value={moment(endDate).format('DD.MM.YYYY')}
					onChange={(date) =>
						handleEndDateChange(moment(date).format('YYYY-MM-DD'))
					}
					firstAvaliableDate={moment(Date.now())}
					error={null}
					withCheck={false}
				/>
			</div>
		</div>
	)
}

{
	/* <datalist id="end">
				{uniqueDatesEnd.map((date, index) => (
					<option key={index} value={date} />
				))}
			</datalist> */
}
