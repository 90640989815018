import React, { useCallback, useMemo, useState } from 'react'
import { EditVinstantInputButton } from 'src/components/_client/campaign-info/common/edit-vinstant-input-button/EditVinstantInputButton'
import { EditVinstantProcessingWrapped } from 'src/components/_client/campaign-info/common/edit-vinstant-processing/EditVinstantProcessing'
import FileInput from 'src/components/UtilityComponents/FileInput'
import { ObjectValue } from 'src/components/UtilityComponents/ProgressFileInput'
import {
	DistrictBookingExternalType,
	DistrictBookingsCreativeType,
} from 'src/stores/ADMarketTypes.types'
import useCampaignData from 'src/stores/campaignData'
import {
	MediaRequirements,
	useTechnicalRequirementsStore,
} from 'src/stores/technical-requirements-store'
import { getFileDescription, getMaxFileSize } from 'src/utils'
import { getError } from 'src/utils/getError'
import { ExteranalTypesState } from './DistrictBookingEdit'
import { useCampaignInfoStore } from 'src/components/_client/campaign-info/store/campaignInfo.store'

type FormatUploadProps = {
	editVinstantLink: string | null
	isVinstantLoading: boolean
	districtBookingId: number
	externalType: DistrictBookingExternalType
	media: Omit<DistrictBookingsCreativeType, 'edit_vinstant'>
	mediaRequierments: MediaRequirements
	openVinstantEditor: () => void
	setExternalTypesState: (state: ExteranalTypesState) => void
	styles?: React.CSSProperties
}

export const FormatUpload = ({
	mediaRequierments,
	editVinstantLink,
	isVinstantLoading,
	districtBookingId,
	externalType,
	media,
	openVinstantEditor,
	setExternalTypesState,
	styles,
}: FormatUploadProps) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [isLoading, setIsLoading] = useState(false)
	const [editVinstantLinkState, setEditVinstantLinkState] = useState(
		!!editVinstantLink
	)

	const fetchVinstantCreative = useCampaignInfoStore(
		(state) => state.fetchVinstantCreative
	)
	const fetchTechnicalRequirements = useTechnicalRequirementsStore(
		(state) => state.fetchTechnicalRequirements
	)
	const [mediaError, setMediaError] = useState('')
	const [creative, setCreative] = useState<ObjectValue | null>({
		name: media.file_name,
		src: media.file,
	})

	const maxSize = useMemo(() => {
		return getMaxFileSize(mediaRequierments)
	}, [mediaRequierments])

	const description = useMemo(() => {
		return getFileDescription(mediaRequierments)
	}, [mediaRequierments])
	const allowedExtensions = useMemo(() => {
		return [
			...mediaRequierments.image_formats,
			...mediaRequierments.video_formats,
		]
	}, [mediaRequierments])

	const handleRemove = useCallback(() => {
		setExternalTypesState({
			[externalType]: 'removed',
		})
		setEditVinstantLinkState(false)
		setMediaError('')
		setCreative(null)
	}, [])
	const handleUpload = async (file: File) => {
		try {
			setExternalTypesState({
				[externalType]: 'loading',
			})
			setMediaError('')
			setIsLoading(true)
			const result =
				(await campaignInterface.uploadDistrictBookingCreative({
					file: file,
					externalType: externalType,
					distictBookingId: districtBookingId,
				})) as any

			await fetchTechnicalRequirements(campaign)
			await fetchVinstantCreative(campaign.id)
			setExternalTypesState({
				[externalType]: 'downloaded',
			})
			setCreative({ src: result.file, name: result.file_name })
		} catch (e) {
			const error = getError(e)

			if (error?.data?.file) {
				if (typeof error.data.file === 'string') {
					setMediaError(error.data.file)
				}
			}
			setExternalTypesState({
				[externalType]: 'error',
			})
			return
		} finally {
			setIsLoading(false)
		}
	}
	return (
		<div style={styles}>
			{isVinstantLoading || isLoading ? (
				<EditVinstantProcessingWrapped />
			) : (
				<FileInput
					ExtraButton={
						editVinstantLinkState ? (
							<EditVinstantInputButton
								onClick={openVinstantEditor}
							/>
						) : null
					}
					error={mediaError}
					onUpload={handleUpload}
					value={creative}
					onRemove={handleRemove}
					size={maxSize}
					allowedExtensions={allowedExtensions}
					descr={description}
				/>
			)}
		</div>
	)
}
