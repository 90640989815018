import React from 'react'
import { Button } from 'react-bootstrap'
import { BookingState } from 'src/stores/ADMarketTypes.types'
import { ReactComponent as Repeat } from '../../../../../../../../assets/images/repeat.svg'
import { ReactComponent as Edit } from '../../../../../../../../assets/images/edit.svg'
import { ReactComponent as Clear } from '../../../../../../../../assets/images/clear-ring.svg'

import cn from 'classnames'
import s from '../style.module.scss'
import { FormLabelTooltipCommon } from 'src/components/UtilityComponents'
export type AdvStateType =
	| 'draft'
	| 'inner_moderation'
	| 'moderation'
	| 'adjustment'
	| 'confirm'
	| 'completed'
	| 'reject'

type ModerationModalButtonProps = {
	state: AdvStateType
	isModalButton?: boolean
	isLoading?: boolean
	setAdvItemActionType: (
		action: 'reject' | 'adjustment' | 'moderation'
	) => void
}
export const ModerationModalButton = ({
	state,
	isModalButton = true,
	setAdvItemActionType,
	isLoading = false,
}: ModerationModalButtonProps) => {
	const isMoveToModerationButton =
		state === 'adjustment' || state === 'reject'

	const isMoveToRejectButton =
		state === 'adjustment' ||
		state === 'moderation' ||
		state === 'inner_moderation'

	const isMoveToAdjustmentButton =
		state === 'inner_moderation' ||
		state === 'moderation' ||
		state === 'reject'

	return (
		<>
			{isMoveToModerationButton && (
				<button
					disabled={isLoading}
					onClick={() => setAdvItemActionType('moderation')}
					className={cn(s.moderationButtons, {
						[s.moderationButtons_iconsOnly]: !isModalButton,
					})}
					style={{
						color: '#007BFF',
					}}
				>
					{isModalButton ? (
						<Repeat style={{ fill: '#007BFF' }} />
					) : (
						<FormLabelTooltipCommon
							text="Вернуть на модерацию"
							placement="left"
							style={{ margin: 0 }}
						>
							<Repeat style={{ fill: '#007BFF' }} />
						</FormLabelTooltipCommon>
					)}

					{isModalButton && <p>Вернуть на модерацию</p>}
				</button>
			)}
			{isMoveToAdjustmentButton && (
				<button
					disabled={isLoading}
					onClick={() => setAdvItemActionType('adjustment')}
					className={cn(s.moderationButtons, {
						[s.moderationButtons_iconsOnly]: !isModalButton,
					})}
					style={{
						color: '#007BFF',
					}}
				>
					{isModalButton ? (
						<Edit />
					) : (
						<FormLabelTooltipCommon
							text="На исправление"
							placement="left"
							style={{ margin: 0 }}
						>
							<Edit />
						</FormLabelTooltipCommon>
					)}

					{isModalButton && <p>На исправление</p>}
				</button>
			)}
			{isMoveToRejectButton && (
				<button
					disabled={isLoading}
					onClick={() => setAdvItemActionType('reject')}
					className={cn(s.moderationButtons, {
						[s.moderationButtons_iconsOnly]: !isModalButton,
					})}
					style={{
						color: '#DC3545',
					}}
				>
					{isModalButton ? (
						<Clear />
					) : (
						<FormLabelTooltipCommon
							text="Отклонить"
							placement="left"
							style={{ margin: 0 }}
						>
							<Clear />
						</FormLabelTooltipCommon>
					)}

					{isModalButton && <p>Отклонить</p>}
				</button>
			)}
		</>
	)
}
