import { AdvFormats, AdvSelectorData, AdvSelectorState } from '../types'
import { advFormatsTitles } from './advFormatsTitles'
import { ReactComponent as OutdoorIcon } from 'src/assets/images/billboard_2.svg'
import { ReactComponent as InternetIcon } from 'src/assets/images/internet.svg'
import { ReactComponent as PublicTransportIcon } from 'src/assets/images/metro.svg'
import { ReactComponent as TVIcon } from 'src/assets/images/tv.svg'
import { ReactComponent as IndoorIcon } from 'src/assets/images/indoor.svg'

export const defaultAdvSelectors: AdvSelectorData[] = [
	{
		key: AdvFormats.Outdoor,
		state: AdvSelectorState.Pending,
		icon: OutdoorIcon,
		title: advFormatsTitles[AdvFormats.Outdoor],
		totalAmount: 0,
	},
	{
		key: AdvFormats.Indoor,
		state: AdvSelectorState.Pending,
		icon: IndoorIcon,
		title: advFormatsTitles[AdvFormats.Indoor],
		totalAmount: 0,
	},
	{
		key: AdvFormats.PublicTransport,
		state: AdvSelectorState.Pending,
		icon: PublicTransportIcon,
		title: advFormatsTitles[AdvFormats.PublicTransport],
		totalAmount: 0,
	},
	{
		key: AdvFormats.Internet,
		state: AdvSelectorState.Pending,
		icon: InternetIcon,
		title: advFormatsTitles[AdvFormats.Internet],
		totalAmount: 0,
	},
	{
		key: AdvFormats.TV,
		state: AdvSelectorState.Pending,
		icon: TVIcon,
		title: advFormatsTitles[AdvFormats.TV],
		totalAmount: 0,
	},
]
