import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useCampaignData from '../../../../../stores/campaignData'
import { Formik } from 'formik'
import { Button, Card, Dropdown, Form, Spinner } from 'react-bootstrap'
import useResponsive from '../../../../../utils/useResponsive'
import '../../../../../styles/internet-upload.scss'
import { ScrollToError } from '../../../../UtilityComponents/ScrollToFormikError'
import { AdIcon } from '../../Step2-ADformats/AdFormatSelector'
import s from '../DataUpload.module.scss'
import UploadYandex from './UploadYandex'
import UploadVk from './UploadVk'
import useBidRequest from '../CreateCreative/useBidRequest'
import {
	REUSABLE_MODALS,
	useReusableModals,
} from '../../../../UtilityComponents/ReusableModals/ReusableModalsProvider'
import { AutoPostingLegalInfo } from '../components/auto-posting-legal-info/AutoPostingLegalInfo'

export type InternetUploadForm = {
	yandex_heading: string
	yandex_text: string
	yandex_image: string
	vk_heading: string
	vk_text: string
	vk_button_text: string
	vk_image: string
}

const InternetUpload = ({
	isLoading,
	onStepBack,
	cn,
	onBack,
	isStepper,
	setGlobalStep,
	isLastStep,
	step,
	totalSteps,
	onNext,
}) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [isMediaPlanLoading, setIsMediaPlanLoading] = useState(false)
	const { isMobile } = useResponsive()
	const { isHaveBidRequest } = useBidRequest()
	const showModal = useReusableModals()

	const [isYandex, isVk] = useMemo(
		() => [
			campaign.internet_yandex.is_selected,
			campaign.internet_vk.is_selected,
		],
		[campaign]
	)

	const initialValues = useMemo<InternetUploadForm>(() => {
		return {
			vk_button_text: campaign.internet_vk.button_text || '',
			vk_text: campaign.internet_vk.text || '',
			vk_image: campaign.internet_vk.image || '',
			vk_heading: campaign.internet_vk.heading || '',
			yandex_heading: campaign.internet_yandex.heading || '',
			yandex_text: campaign.internet_yandex.text || '',
			yandex_image: campaign.internet_yandex.image || '',
		}
	}, [campaign])

	const handleSubmit = async (values) => {
		onNext()
		setIsMediaPlanLoading(false)
	}
	const handleValidation = (values) => {
		const errors: any = {}

		return errors
	}

	const withErrors = useCallback(
		(values: InternetUploadForm): boolean => {
			let res = false
			if (isYandex) {
				;['heading', 'text'].forEach((key) => {
					if (!values['yandex_' + key]) res = true
					if (
						values.yandex_text.length > 81 ||
						values.yandex_heading.length > 81
					)
						res = true
				})
				if (
					!(
						values.yandex_image ||
						isHaveBidRequest({
							id: campaign.internet_yandex.id,
							type: 'internet_yandex',
						})
					)
				)
					res = true
			}
			if (isVk) {
				;['heading', 'text'].forEach((key) => {
					if (!values['vk_' + key]) res = true
				})

				if (
					!(
						values.vk_image ||
						isHaveBidRequest({
							id: campaign.internet_vk.id,
							type: 'internet_vk',
						})
					)
				)
					res = true
				if (!values.vk_button_text) res = true
			}
			return res
		},
		[campaign]
	)
	useEffect(() => {
		let timeoutId: NodeJS.Timeout

		if (isMediaPlanLoading) {
			timeoutId = setTimeout(() => {
				setIsMediaPlanLoading(false)
			}, 3000)
		}

		return () => clearTimeout(timeoutId)
	}, [isMediaPlanLoading])

	const handleWatchMediaPlan = () => {
		setIsMediaPlanLoading(true)
		showModal(REUSABLE_MODALS.showMediaPlanModal).finally(() => {
			setIsMediaPlanLoading(false)
		})
	}

	return (
		<>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validate={handleValidation}
				validateOnChange={false}
				enableReinitialize={true}
			>
				{({
					values,
					setFieldValue,
					handleSubmit,
					errors,
					setFieldError,
				}: any) => (
					<Form onSubmit={handleSubmit}>
						<ScrollToError />

						<AutoPostingLegalInfo />

						<Card
							className={s.card}
							style={{ marginBottom: '8px' }}
						>
							<div>
								<div
									className={
										'd-flex justify-content-between mt-2'
									}
								>
									<h4 className={'mb-0'}>Интернет-реклама</h4>
									<AdIcon name={'internet'} />
								</div>
							</div>
						</Card>

						{campaign.internet_yandex.is_selected && (
							<Card className={s.card}>
								<UploadYandex
									errors={errors}
									setFieldError={setFieldError}
									setFieldValue={setFieldValue}
									values={values}
								/>
							</Card>
						)}
						{campaign.internet_vk.is_selected && (
							<Card className={s.card + ' mt-2'}>
								<UploadVk
									errors={errors}
									setFieldError={setFieldError}
									setFieldValue={setFieldValue}
									values={values}
								/>
							</Card>
						)}

						<div className={cn('buttons')}>
							<Button
								variant={'primary'}
								onClick={onNext}
								style={
									isMobile
										? { width: '100%', marginLeft: '10px' }
										: {}
								}
								disabled={
									isMediaPlanLoading ||
									withErrors(values) ||
									isLoading
								}
							>
								Продолжить
								{isLoading && (
									<Spinner
										style={{ marginLeft: '6px' }}
										size="sm"
										animation="border"
									/>
								)}
							</Button>
							<Button
								variant={'secondary'}
								onClick={() => {
									if (isStepper) {
										return step - 1 >= 0
											? onStepBack()
											: onBack()
									}
									return onBack()
								}}
							>
								Назад
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</>
	)
}
export default InternetUpload
