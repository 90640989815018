import React from 'react'
import { NavLink } from 'react-router-dom'
import cn from 'classnames'
import s from './appLinks.module.scss'

export type HelpLinkProps = {
	isActive: boolean
	to: string
	title: string
	children?: React.ReactNode
}

export const BottomLink = ({
	children,
	title,
	to,
	isActive,
}: HelpLinkProps) => {
	return (
		<NavLink
			title={title}
			to={to}
			replace
			className={cn(s.link, {
				[s.active]: isActive,
			})}
		>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'flex-start',
				}}
			>
				{children}
				<div style={{ marginLeft: '16px' }}>{title}</div>
			</div>
		</NavLink>
	)
}
