import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import useUserData from '../../stores/userData'
import ROUTES from '../../constants/Routes'
import ProviderProfile from '../_provider/Profile/ProviderProfile'
import ClientProfilePage from '../_client/profile/ClientProfilePage'

export default function ProfileCheckRoute({ ...passThrough }) {
	const [user] = useUserData()
	if (!user || !user.phone_number || !user.is_phone_confirmed) {
		return <Redirect to={ROUTES.ROUTE_AUTH} />
	}
	if (user.provider)
		return (
			<Route {...passThrough}>
				<ProviderProfile />
			</Route>
		)

	if (!user.provider)
		return (
			<Route {...passThrough}>
				<ClientProfilePage />
			</Route>
		)

	return <Redirect to={ROUTES.ROUTE_AUTH} />
}
