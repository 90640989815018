export type ProblemAdvType =
	| 'outdoor-garant'
	| 'outdoor-mass'
	| 'indoor'
	| 'internet'
	| 'tv'
	| 'public_transport'
export const problemAdvTitles: {
	[key in ProblemAdvType]: string
} = {
	'outdoor-garant': 'Наружная реклама - Гарантированные просмотры',
	'outdoor-mass': 'Наружная реклама - Максимальный охват',
	indoor: 'Внутренняя реклама',
	internet: 'Интернет-реклама',
	tv: 'TВ-реклама',
	public_transport: 'Общественный транспорт',
}
