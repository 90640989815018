import React, { FC, useEffect, useState } from 'react'
import { TvType } from '../TvStateView.types'
import { Button } from 'react-bootstrap'
import s from '../TvAd.module.scss'
import { useConfirm } from '../../../../UtilityComponents/ConfirmModalProvider'
import TvModalDesktop from '../TvModalDesktop'
import useCampaignData from 'src/stores/campaignData'
import { TvState } from 'src/stores/ADMarketTypes.types'
import { useReusableModals } from 'src/components/UtilityComponents/ReusableModals/ReusableModalsProvider'
import useResponsive from 'src/utils/useResponsive'

export enum TvModalContent {
	MediaPlan = 'mediaplan',
	Requirements = 'requirements',
}

interface ITvControls {
	tvType: TvType
}

const TvControls: FC<ITvControls> = ({ tvType }) => {
	const [modalContent, setModalContent] = useState<TvModalContent | false>(
		false
	)
	const [campaign, campaignInterface] = useCampaignData()
	const { confirm } = useConfirm()
	const [isLoading, setIsLoading] = useState(false)
	const showModal = useReusableModals()
	const { isMobile } = useResponsive()

	const handleDelete = async () => {
		const answ = await confirm({
			text: 'После этого действия вы не сможете ее вернуть обратно',
			title:
				tvType === TvType.ADSTREAM
					? 'Вы уверены, что хотите удалить показ видеоролика при переключении канала из рекламной кампании?'
					: 'Вы уверены, что хотите удалить показ всплывающего баннера при просмотре канала из рекламной кампании?',
			closeButton: 'true',
			acceptText: 'Да, уверен',
			declineText: 'Нет, отменить',
			acceptVariant: 'danger',
		})
		if (!answ) return
		if (tvType === TvType.ADSTREAM)
			await campaignInterface.saveVideoFilters(campaign.id, {
				to_canceled: true,
			})
		if (tvType === TvType.TELETARGET)
			await campaignInterface.saveTeletargetFilters(campaign.id, {
				to_canceled: true,
			})
	}
	const HideModal = () => setModalContent(false)

	const showDeleteButton =
		tvType === TvType.TELETARGET
			? campaign.teletarget.state !== TvState.Canceled &&
			  campaign.teletarget.state !== TvState.Reject
			: campaign.adstream.state !== TvState.Reject &&
			  campaign.adstream.state !== TvState.Canceled &&
			  campaign.adstream.state !== TvState.Completed

	useEffect(() => {
		let timeoutId: NodeJS.Timeout

		if (isLoading) {
			timeoutId = setTimeout(() => {
				setIsLoading(false)
			}, 3000)
		}

		return () => clearTimeout(timeoutId)
	}, [isLoading])

	return (
		<div className={'d-flex gap-2'}>
			<div style={{ flex: '1 1 auto', gap: '16px', display: 'flex' }}>
				<Button
					variant={'light'}
					onClick={() => {
						setModalContent(TvModalContent.Requirements)
					}}
					style={{ flex: '1 1 auto' }}
				>
					{tvType === TvType.ADSTREAM
						? 'Требования к видеофайлу'
						: 'Требования к файлу'}
				</Button>
			</div>
			<div>
				{showDeleteButton && (
					<Button
						variant={'light'}
						onClick={handleDelete}
						className={s.trashBtn}
					>
						<svg
							width="12"
							height="14"
							viewBox="0 0 12 14"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M2.6665 5.00004V12.3334H9.33317V5.00004H10.6665V13C10.6665 13.3682 10.368 13.6667 9.99984 13.6667H1.99984C1.63165 13.6667 1.33317 13.3682 1.33317 13V5.00004H2.6665ZM5.33317 5.00004V11H3.99984V5.00004H5.33317ZM7.99984 5.00004V11H6.6665V5.00004H7.99984ZM7.99984 0.333374C8.28679 0.333374 8.54155 0.516994 8.63229 0.789222L9.1465 2.33271L11.3332 2.33337V3.66671H0.666504V2.33337L2.8525 2.33271L3.36738 0.789222C3.45812 0.516994 3.71288 0.333374 3.99984 0.333374H7.99984ZM7.51933 1.66671H4.48034L4.25784 2.33271H7.74117L7.51933 1.66671Z"
								fill="#212529"
							/>
						</svg>
					</Button>
				)}
			</div>
			{modalContent && (
				<TvModalDesktop
					modalContent={modalContent}
					onClose={HideModal}
					tvType={tvType}
				/>
			)}
		</div>
	)
}

export default TvControls
