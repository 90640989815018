import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { InternetUploadForm } from './InternetUpload'
import useCampaignData from '../../../../../stores/campaignData'
import { VkIcon } from '../../Step2-ADformats/InternetAD/InternetSelector'
import { Button, Form } from 'react-bootstrap'
import FormLabelTooltip from '../../../../UtilityComponents/FormLabelTooltip'
import { InternetTooltips } from '../../Step2-ADformats/InternetAD/InternetTooltips'
import { internetVk } from '../../../../../constants/api'
import InputLengthCounter from '../../../../UtilityComponents/InputLengthCounter'
import AdMarketSelect from '../../../../UtilityComponents/ADMarketSelect/AdMarketSelect'
import VkButtonTextData from './VkButtonText.data'
import useBidRequest, { bidInfo } from '../CreateCreative/useBidRequest'
import CreativeFileInput from '../../../../UtilityComponents/CreativeFileInput'
import { TemplatesModal } from '../components'
import { PlacementType } from 'src/stores/ADMarketTypes.types'
import useNotificationData from 'src/stores/notificationsData'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { OrderLayoutModal } from '../components/order-layout-modal'
import { CreateCreativeFields } from '../CreateCreative/CreateCreativeForm'
import { NewContentCreatedStatusType } from 'src/stores/types/newContendCreatedType'
import { downloadByRequest } from 'src/utils'
import { EditVinstantModal } from 'src/components/_client/campaign-info/common/edit-vinstant-modal/EditVinstantModal'
import { useCampaignInfoStore } from 'src/components/_client/campaign-info/store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from 'src/components/_client/campaign-info/store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { EditVinstantProcessingWrapped } from 'src/components/_client/campaign-info/common/edit-vinstant-processing/EditVinstantProcessing'
import { InputExtraButtons } from '../components/outdoor/common/input-extra-buttons/InputExtraButtons'

interface IUploadVk {
	values: InternetUploadForm
	setFieldValue: (field_name: keyof InternetUploadForm, value: any) => null
	setFieldError: (field_name: keyof InternetUploadForm, error: any) => null
	errors: { [key in keyof InternetUploadForm]?: string }
}

const UploadVk: FC<IUploadVk> = ({
	values,
	setFieldValue,
	setFieldError,
	errors,
}) => {
	const [modalOpen, setModalOpen] = useState(false)
	const [editVinstantOpen, setEditVinstantOpen] = useState(false)

	const [loadingInProgress, setLoadingInProgress] = useState(false)

	const [campaign, campaignInterface] = useCampaignData()
	const { isHaveBidRequest } = useBidRequest()

	const bidInfo: bidInfo = {
		id: campaign.internet_vk.id,
		type: 'internet_vk',
	}

	const patchSingleField = async (
		field: keyof InternetUploadForm,
		value: string | number
	) => {
		const correctFieldName = field.replace(field.split('_')[0] + '_', '')
		const prevValue = campaign.internet_vk[correctFieldName]
		if (value === prevValue) return
		const formData = new FormData()
		formData.append(correctFieldName, value.toString())
		await campaignInterface.patchInternetVk(campaign.id, formData)
	}

	const closeModal = () => {
		setModalOpen(false)
	}

	const handleCreateMaterial = async () => {
		setModalOpen(true)
	}

	const virtualPlacement = {
		name: campaign.internet_vk.link as string,
		size: 'до 5 МБ',
		media_type: {
			video_formats: ['MPEG4', 'MP4', 'MOV'],
			image_formats: ['JPEG', 'JPG', 'PNG', 'PDF'],
			min_width: 1920,
			min_height: 1080,
			aspect_ratio: '16:9',
		},
		image: 'https://storage.yandexcloud.net/admarket-images/static-img/internet.png',
		placement_type: {
			name: 'VK-реклама',
		},
	} as Omit<PlacementType, 'placement_showing_counts'>

	const [_, notificationsInterface] = useNotificationData()

	const createdMaterialStatus = notificationsInterface.getNewContentCreated(
		'vk'
	) as NewContentCreatedStatusType | undefined
	const editVinstantKey = String(campaign.internet_vk?.id)
	const vkVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'vk_id',
			itemId: editVinstantKey,
		})
	)
	const { addToast } = useContext(ToastsContext)

	const [isOrderLayoutModal, setIsOrderLayoutModal] = useState(false)

	const handleOrderLayoutSubmit = async (values: CreateCreativeFields) => {
		for (let key in values) {
			if (!values[key]) {
				delete values[key]
			}
		}

		await campaignInterface.createNewBidRequest(values, {
			vk_id: campaign.internet_vk.id.toString(),
		})
		setIsOrderLayoutModal(false)
	}
	const handleIsOrderLayoutOpen = () => {
		setModalOpen(false)
		setIsOrderLayoutModal(true)
	}
	const handleStatusHasChanged = async (
		status: NewContentCreatedStatusType
	) => {
		if (status === 'start') {
			setLoadingInProgress(true)
		}
		if (status === 'end') {
			setLoadingInProgress(false)

			await campaignInterface.refetchSelected()

			addToast({
				text: `Загрузка контента для ${virtualPlacement.placement_type.name} завершена`,
				type: 'success',
			})

			notificationsInterface.setNewContendCreated({
				vk: 'pending',
			})
		}
	}
	const handleUpload = () => {
		if (campaign.internet_vk.image) {
			downloadByRequest(
				`adv_companies/${campaign.id}/vk_creative/download/`,
				campaign.internet_vk.image
			)
		}
	}
	useEffect(() => {
		if (!!createdMaterialStatus) {
			handleStatusHasChanged(createdMaterialStatus)
		}
	}, [createdMaterialStatus])
	return (
		<>
			{modalOpen && (
				<TemplatesModal
					getOrderLayoutModal={handleIsOrderLayoutOpen}
					newContentKey="vk"
					placement={virtualPlacement}
					instanceId={campaign.internet_vk.id.toString()}
					clsName={'vkinternetadvertising'}
					campaingId={campaign.id}
					closeModal={closeModal}
					modalOpen={modalOpen}
					screenResolution={'1920*1080'}
				/>
			)}
			<EditVinstantModal
				newContentKey={'vk'}
				editVinstantLink={vkVinstantLink}
				onClose={() => setEditVinstantOpen(false)}
				open={editVinstantOpen}
			/>
			{isOrderLayoutModal && (
				<OrderLayoutModal
					onSubmit={handleOrderLayoutSubmit}
					open={isOrderLayoutModal}
					onClose={() => setIsOrderLayoutModal(false)}
				/>
			)}
			<div className={'d-flex flex-column gap-4'}>
				<div className={'d-flex justify-content-between'}>
					<h5 className={'mb-0'}>VK Реклама</h5>
					<div>
						<VkIcon />
					</div>
				</div>
				{/*Heading*/}
				<Form.Group>
					<Form.Label>
						Что будете рекламировать?{' '}
						<FormLabelTooltip text={InternetTooltips.VK.heading} />
					</Form.Label>
					<Form.Control
						type={'text'}
						size={'lg'}
						value={values.vk_heading}
						onChange={(e) => {
							setFieldError('vk_heading', undefined)
							setFieldValue('vk_heading', e.target.value)
						}}
						onBlur={() => {
							if (values.vk_heading !== '') {
								patchSingleField(
									'vk_heading',
									values.vk_heading
								).then()
							} else {
								setFieldError('vk_heading', 'Введите заголовок')
							}
						}}
						isInvalid={!!errors.vk_heading}
						placeholder={'Не задан'}
						maxLength={25}
					/>
					<Form.Control.Feedback type={'invalid'}>
						{errors.vk_heading}
					</Form.Control.Feedback>
					<InputLengthCounter
						value={values.vk_heading}
						maxLength={25}
					/>
				</Form.Group>
				{/*Text*/}
				<Form.Group>
					<Form.Label>
						Описание вашего товара или услуги{' '}
						<FormLabelTooltip text={InternetTooltips.VK.text} />
					</Form.Label>
					<Form.Control
						type={'text'}
						size={'lg'}
						value={values.vk_text}
						onChange={(e) => {
							setFieldError('vk_text', undefined)
							setFieldValue('vk_text', e.target.value)
						}}
						onBlur={() => {
							if (values.vk_text !== '') {
								patchSingleField(
									'vk_text',
									values.vk_text
								).then()
							} else {
								setFieldError(
									'vk_text',
									'Введите текст объявления'
								)
							}
						}}
						isInvalid={!!errors.vk_text}
						placeholder={'Не задан'}
						maxLength={90}
					/>
					<Form.Control.Feedback type={'invalid'}>
						{errors.vk_text}
					</Form.Control.Feedback>
					<InputLengthCounter value={values.vk_text} maxLength={90} />
				</Form.Group>
				<Form.Group>
					<Form.Label>Рекламный материал</Form.Label>
					{createdMaterialStatus === 'start' ? (
						<EditVinstantProcessingWrapped />
					) : (
						<CreativeFileInput
							ExtraButton={
								<InputExtraButtons
									onEditVinstant={
										vkVinstantLink
											? () => setEditVinstantOpen(true)
											: undefined
									}
									onUpload={handleUpload}
								/>
							}
							maxSizeMb={5}
							allowedExtension={['jpg', 'png']}
							loadingInProgress={loadingInProgress}
							bidInfo={bidInfo}
							initialValue={{
								src: campaign.internet_vk.image,
								name: 'Текущий материал',
							}}
							updateUrl={internetVk.params(campaign.id)}
							nameInBody={'image'}
							description={
								'Изображения — JPG, PNG. Разрешение: 1920 x 1080 px. Размер: до 5 МБ.'
							}
							removeMethod={'PATCH'}
							onFileLoad={() =>
								campaignInterface.refetchSelected()
							}
							onFileDelete={() =>
								campaignInterface.refetchSelected()
							}
						/>
					)}
				</Form.Group>
				{/*<ModalInternetMediaRequirements internetName={'VK'} />*/}
				{!isHaveBidRequest(bidInfo) && !campaign.internet_vk.image && (
					<Button
						variant={'light'}
						className={'w-100'}
						onClick={handleCreateMaterial}
					>
						Создать материал
					</Button>
				)}
				<Form.Group>
					<Form.Label>
						Надпись на кнопке
						<FormLabelTooltip
							text={InternetTooltips.VK.button_text}
						/>
					</Form.Label>
					<AdMarketSelect
						value={
							{
								label: VkButtonTextData.get(
									values.vk_button_text
								),
								value: values.vk_button_text,
							} || null
						}
						options={VkButtonTextData.generateArray()}
						onChange={(newValue) => {
							setFieldError('vk_button_text', undefined)
							patchSingleField(
								'vk_button_text',
								newValue?.value || ''
							).then()
						}}
					/>
				</Form.Group>
			</div>
		</>
	)
}

export default UploadVk
