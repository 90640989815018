import React from 'react'

import s from './login.module.scss'

type Props = {
	toRegister: () => void
}
export const LoginFooter = ({ toRegister }: Props) => {
	return (
		<div className={s.loginFooter}>
			<p>Еще нет аккаунта?</p>
			<span onClick={toRegister}>Зарегистрироваться</span>
		</div>
	)
}
