import React, { useEffect } from 'react'
import { Spinner } from 'react-bootstrap'
import { withPage } from 'src/components/page/Page'
import s from './campaignReportPage.module.scss'
import { CampaignReportTableCard } from './ui/campagn-report-table-card/CampaignReportTableCard'
import { useParams } from 'react-router-dom'
import { useCampaignReportStore } from './store/campaignReportStore'
import { getReportBreadCrumbsInfo, getReportItemsCount } from './store'
import { CampaignReportBreadcrumbs } from './ui/breadcrumbs/CampaignReportBreadCrumbs'

export const CampaignReport = (props: any) => {
	const { campaignId } = useParams<{ campaignId: string }>()
	const fetchCampaignReport = useCampaignReportStore(
		(store) => store.fetchCampaignReport
	)
	const reportItemsCount = useCampaignReportStore(getReportItemsCount)
	const breadcrumbsInfo = useCampaignReportStore(getReportBreadCrumbsInfo)

	const isLoading = useCampaignReportStore((store) => store.isLoading)
	useEffect(() => {
		if (campaignId) {
			fetchCampaignReport(campaignId)
		}
	}, [])
	return (
		<div className={s.campaignReportPage}>
			{breadcrumbsInfo && (
				<CampaignReportBreadcrumbs breadcrumbsInfo={breadcrumbsInfo} />
			)}

			{isLoading ? (
				<div className="w-100 h-100 d-flex justify-content-center align-items-center">
					<Spinner
						animation="border"
						variant="primary"
						style={{ height: '64px', width: '64px' }}
					/>
				</div>
			) : reportItemsCount ? (
				<CampaignReportTableCard />
			) : (
				<div className="w-100 h-100 d-flex justify-content-center align-items-center">
					<NoItemsForReport />
				</div>
			)}
		</div>
	)
}
export const CampaignReportPage = withPage({
	noSidebar: false,
	isProvider: false,
})(CampaignReport)

const CampaignReportNotFound = () => {
	return <h1>Рекламная кампании не найдена</h1>
}

const NoItemsForReport = (props: any) => {
	return <h1>Нет данных для отчета</h1>
}
