import React from 'react'
import s from './registerInfo.module.scss'
import { offerta, personalDataAgreement } from 'src/constants/links'

export const RegistrationInfo = () => {
	return (
		<div className={s.registerInfo}>
			<div>
				Нажимая на кнопку «Продолжить», вы принимаете{' '}
				<a target="blank" href={offerta}>
					условия договора оферты
				</a>{' '}
				и даете согласие на{' '}
				<a target="blank" href={personalDataAgreement}>
					обработку персональных данных
				</a>
			</div>
		</div>
	)
}
