import React from 'react'
import s from './advRecSummary.module.scss'

type Props = {
	summaryData: { title: string; value: string }[]
}
export const AdvRecSummary = ({ summaryData }: Props) => {
	return (
		<div className={s.summaryList}>
			{summaryData.map((el) => (
				<div key={el.title} className={s.summaryItem}>
					<h6>{el.title}</h6>
					<p>{el.value}</p>
				</div>
			))}
		</div>
	)
}
