import React, { FC, memo, useEffect, useMemo, useState } from 'react'
import ProgressFileInput, { IProgressFileInput } from './ProgressFileInput'
import useCampaignData from '../../stores/campaignData'
import { Button } from 'react-bootstrap'
import useBidRequest, {
	bidInfo,
} from '../_client/create-campaign/Step3-Upload/CreateCreative/useBidRequest'

interface ICreativeFileInput {
	bidInfo: bidInfo
	loadingInProgress?: boolean
	ExtraButton?: React.ReactNode
}
const CreativeFileInput: FC<ICreativeFileInput & IProgressFileInput> = memo(
	({ bidInfo, loadingInProgress, ...progressFileInputProps }) => {
		const [campaign, campaignInterface] = useCampaignData()

		const { isHaveBidRequest } = useBidRequest()
		const [showBidRequest, setShowBidRequest] = useState(() =>
			isHaveBidRequest(bidInfo)
		)
		const isHaveFileUploaded = useMemo<boolean>(() => {
			return (
				progressFileInputProps.initialValue?.src !== null &&
				progressFileInputProps.initialValue?.src !== ''
			)
		}, [progressFileInputProps.initialValue])

		useEffect(() => {
			setShowBidRequest(isHaveBidRequest(bidInfo))
		}, [campaign.bid_requests, bidInfo])

		if (isHaveFileUploaded) {
			return <ProgressFileInput {...progressFileInputProps} />
		}
		const handleDeleteBidRequest = async () => {
			if (showBidRequest) {
				await campaignInterface.deleteBidRequest(
					showBidRequest.request_id
				)
			}
		}

		return showBidRequest ? (
			<div
				className={
					'd-flex w-100 justify-content-between align-items-center'
				}
				style={{
					border: '1px solid #CED4DA',
					borderRadius: '4px',
					padding: '10px 16px',
					gap: '16px',
				}}
			>
				<div>Материал будет создан с помощью специалиста Admarket</div>
				<Button variant={'secondary'} onClick={handleDeleteBidRequest}>
					Отменить
				</Button>
			</div>
		) : (
			<ProgressFileInput
				loadingInProgress={loadingInProgress}
				{...progressFileInputProps}
			/>
		)
	}
)
CreativeFileInput.displayName = 'CreativeFileInput'

export default CreativeFileInput
