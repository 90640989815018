import React, { useEffect, useState } from 'react'
import '../../../../../../styles/placements-page.scss'
import { Badge, Button } from 'react-bootstrap'
import { AnimateSharedLayout, motion } from 'framer-motion'
import { ActiveLine } from '../../../../Mediaformats/MediaformatsPage'
import useResponsive from 'src/utils/useResponsive'
import s from './placementTabs.module.scss'
import cn from 'classnames'
import MobileTabsDropDown from 'src/components/_client/campaigns-list/MobileTabsDropDown'
import { PlacementTabKeyType, PlacementTabType } from '../../../constants'
import useDebounce from 'src/utils/useDebounce'

type PlacementTabsProps = {
	tabs: Array<PlacementTabType>
	setTab: (tabKey: PlacementTabKeyType) => void
	selectedTab: PlacementTabKeyType
	onCreate?: () => void
}
//TODO сделать общий компонент для все табов в приложении
export const PlacementTabs = ({
	selectedTab,
	setTab,
	tabs,
	onCreate,
}: PlacementTabsProps) => {
	const { isMobile, isTablet } = useResponsive()
	const [debouncedTab, setDebouncedTab] =
		useState<PlacementTabKeyType>(selectedTab)

	const debounce = useDebounce(debouncedTab, 600)

	const handleCreate = () => {
		onCreate && onCreate()
	}

	useEffect(() => {
		setTab(debouncedTab)
	}, [debounce])

	return isMobile ? (
		<div>
			<div
				style={{
					width: '100%',
					borderBottom: '1px solid #CED4DA',
					padding: '12px 16px',
				}}
			>
				<h5>Рекламные носители</h5>
				<Button
					color="primary"
					size="sm"
					style={{ width: '100%' }}
					onClick={handleCreate}
				>
					Создать рекламный носитель
				</Button>
			</div>
			<div
				style={{
					borderBottom: '1px solid #CED4DA',
				}}
			>
				<MobileTabsDropDown
					tabs={tabs}
					setSelectedTab={setTab}
					selectedTab={selectedTab}
				/>
			</div>
		</div>
	) : (
		<div className={cn(s.placementTabs)}>
			<AnimateSharedLayout>
				{tabs.map((tab) => {
					return (
						<motion.div
							key={tab.key}
							className={cn(s.placementTab, {
								[s.activeTab]: debouncedTab === tab.title,
							})}
							onClick={(e) => setDebouncedTab(tab.key)}
						>
							{tab.title}
							<Badge
								bg={
									debouncedTab === tab.key
										? 'primary'
										: 'secondary'
								}
							>
								{tab.count}
							</Badge>
							{debouncedTab === tab.key && <ActiveLine />}
						</motion.div>
					)
				})}
			</AnimateSharedLayout>
		</div>
	)
}
