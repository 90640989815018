import React, { FC, useEffect, useMemo, useState } from 'react'
import { withPage } from '../../page/Page'
import useHeaderTitle from '../../page/useHeaderTitle'
import s from './user-operations.module.scss'
import NoOperations from './NoOperations'
import useUserOperations from './useUserOperations'
import { Moment } from 'moment'
import OperationsFilters from './OperationsFilters'
import { Spinner } from 'react-bootstrap'
import OperationsContainer from './OperationsList/OperationsContainer'
import NoOperationsWithFilters from './NoOperationsWithFilters'
import { RenderMobileAndTablet } from 'src/components/UtilityComponents/ResponsiveContainers/RenderMobileAndTablet'

const INITIAL_START_DATE = null
const INITIAL_END_DATE = null

const UserOperationsHistoryPage: FC = ({}) => {
	useHeaderTitle('История операций')
	const [dates, setDates] = useState<{
		start: Moment | null
		end: Moment | null
	}>({ end: INITIAL_END_DATE, start: INITIAL_START_DATE })
	const [search, setSearch] = useState<string>('')

	const [operations, isLoading, error] = useUserOperations({
		startDate: dates.start,
		endDate: dates.end,
		search,
	})

	const isNoFilters = useMemo(() => {
		return search === '' && dates.end === null && dates.start === null
	}, [search, dates.end, dates.start])

	const isUserOperationsTotalZero = useMemo(
		() => operations?.length === 0 && isNoFilters,
		[isNoFilters, operations] // eslint-disable-line react-hooks/exhaustive-deps
	)

	return (
		<div className={s.container}>
			{isUserOperationsTotalZero && <NoOperations />}
			{!isUserOperationsTotalZero && (
				<RenderMobileAndTablet>
					<h4 className={s.mobilePageTitle}>История операций</h4>
				</RenderMobileAndTablet>
			)}
			{!isUserOperationsTotalZero && (
				<OperationsFilters
					onDebounceSearch={(s) => setSearch(s)}
					dates={dates}
					setDates={setDates}
					initialSearch={search}
				/>
			)}
			{!isUserOperationsTotalZero && isLoading && (
				<div
					className={
						'd-grid h-100 w-100 align-items-center justify-content-center'
					}
				>
					<Spinner animation={'border'} variant={'primary'} />
				</div>
			)}
			{!isUserOperationsTotalZero &&
				!isLoading &&
				operations &&
				operations.length !== 0 && (
					<OperationsContainer operations={operations} />
				)}
			{!isUserOperationsTotalZero &&
				!isLoading &&
				operations?.length === 0 && (
					<NoOperationsWithFilters
						resetFilters={() => {
							setSearch('')
							setDates({
								end: INITIAL_END_DATE,
								start: INITIAL_START_DATE,
							})
						}}
					/>
				)}
		</div>
	)
}

export default withPage({ noSidebar: false, isProvider: false })(
	UserOperationsHistoryPage
)
