import { Formik } from 'formik'
import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import {
	Button,
	Card,
	Form,
	FormGroup,
	InputGroup,
	Modal,
} from 'react-bootstrap'

import { ScrollToError } from 'src/components/UtilityComponents/ScrollToFormikError'
import useCampaignData from 'src/stores/campaignData'

import useDebounce from 'src/utils/useDebounce'
import TvUploadItem from '../../../create-campaign/Step3-Upload/Tv/TVUploadItem'
import useResponsive from 'src/utils/useResponsive'
import { TvType } from '../TvStateView.types'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { useConfirm } from 'src/components/UtilityComponents/ConfirmModalProvider'
import { authFetch } from 'src/utils/authFetch'
import { channelAdv, companyToModeration } from 'src/constants/api'

export interface IEditTvModall {
	show: boolean
	handleClose: () => void
	tvType: TvType
}
const EditTvModalTeletarget: FC<IEditTvModall> = ({
	show,
	handleClose,
	tvType,
}) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { addToast } = useContext(ToastsContext)
	const { confirm } = useConfirm()
	const { isMobile } = useResponsive()
	const initialValues = {
		is_small_banner: campaign?.teletarget?.is_small_banner || false,
		is_l_banner: campaign?.teletarget?.is_l_banner || false,
		amount: campaign.teletarget?.amount
			? parseInt(campaign.teletarget.amount).toString()
			: '',
		image: campaign?.teletarget?.image,
	}
	const isTeletarget = campaign.teletarget
	const [formValues, setFormValues] = useState<{} | null>(null)
	const debouncedFormValues = useDebounce(formValues, 1000)
	const setFieldErrorRef = useRef()

	useEffect(() => {
		if (!debouncedFormValues) return
		// !!!!! этот запрос какой-то лишний, в форме только файлинпут, он делает всё что надо !!!!!
		// saveFilters(debouncedFormValues, setFieldErrorRef.current).then()
	}, [debouncedFormValues])

	async function saveFilters(initialValues, setFieldError) {
		const errors = await campaignInterface.saveTeletargetFilters(
			campaign?.id,
			initialValues
		)
		if (errors && setFieldError) {
			for (const [key, initialValues] of Object.entries(errors)) {
				setFieldError(key as any, initialValues as any)
			}
		}
	}

	function handleValidation(values) {
		const errors: any = {}
		if (!values.is_l_banner && !values.is_small_banner) {
			errors['is_l_banner'] =
				'Необходимо выбрать хотя бы один тип баннера'
		}
		if (Object.keys(errors).length === 0) setFormValues(values)
		return errors
	}

	async function handleSubmit(values) {
		const fd = new FormData()
		const copy = { ...values }
		for (let key in copy) {
			fd.append(key, copy[key])
		}

		if (
			values.image !== undefined &&
			(values.image as File) instanceof File
		) {
			fd.append('image', values.image as File)
		}
		if (values.is_small_banner) {
			fd.append('is_small_banner', values.is_small_banner)
		}
		try {
			await authFetch({
				url: channelAdv.params(campaign.id),
				method: 'PATCH',
				body: { state: 'inner_moderation' },
			})
			// await campaignInterface.saveTeletargetFilters(campaign.id, fd)
			handleClose()
		} catch (e: any) {
			console.log('e -> ', e)
		}
	}

	// const moveToModeration = async () => {
	// 	if (campaignInterface.isAllMediaUpload()) {
	// 		const answer = await confirm({
	// 			text: 'После этого действия, вы не сможете ее изменить',
	// 			title: 'Вы уверены, что хотите отправить рекламную кампанию на модерацию?',
	// 			closeButton: true,
	// 			acceptText: 'Да, уверен',
	// 			declineText: 'Нет, отменить',
	// 		})
	//     if (!answer) return false
	//     const is_budget_confirmed =
	//     await campaignInterface.isBudgetConfirmed()
	//   if (!is_budget_confirmed) {
	//     // return setGlobalStep(2)
	//   }
	// 		await campaignInterface.moveCampaignToModeration(campaign.id)
	// 		await yandexTracking.reachGoal('upload_to_moder')

	// 	} else {
	// 		addToast({
	// 			text: 'Необходимо загрузить медиаматериалы ко всем типам рекламам',
	// 			type: 'danger',
	// 			withAction: false,
	// 		})
	// 	}
	// }
	return (
		<Modal show={show} onHide={handleClose} backdrop={'static'}>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} style={{ marginLeft: '8px' }}>
					Исправление
				</Modal.Title>
			</Modal.Header>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validate={handleValidation}
				validateOnChange={true}
			>
				{({
					initialValues,
					setFieldValue,
					handleSubmit,
					errors,
					setFieldError,
				}: any) => (
					<>
						<Modal.Body style={{ padding: '24px' }}>
							<Form className={'d-flex flex-column gap-3'}>
								<ScrollToError />
								<div
									className={
										'd-flex justify-content-between mb-3'
									}
								>
									{/* <h5 className={'mb-0'} style={{ maxWidth: '90%' }}>
                    Показ всплывающего баннера при просмотре канала
                  </h5> */}
									{/* <BannerIcon /> */}
								</div>
								{/* <Form.Group style={{ marginBottom: 'margin' }}>
                  <Form.Label>Формат баннера</Form.Label>
                  <div className={'d-flex flex-column gap-4'}>
                    {Object.entries(bannerVariants).map(
                      ([key, bV]) => {
                        return (
                          <div
                            className={'d-flex gap-2'}
                            key={key}
                          >
                            <Form.Check
                              type={'checkbox'}
                              label={``}
                              id={bV.title}
                              checked={
                                key === 'small_banner'
                                  ? initialValues.is_small_banner
                                  : initialValues.is_l_banner
                              }
                              onChange={() => { }}
                              onClick={() => {
                                setFieldError(
                                  'is_l_banner',
                                  undefined
                                )
                                if (
                                  'small_banner' ===
                                  key
                                ) {
                                  setFieldValue(
                                    'is_small_banner',
                                    !initialValues.is_small_banner
                                  )
                                } else {
                                  setFieldValue(
                                    'is_l_banner',
                                    !initialValues.is_l_banner
                                  )
                                }
                              }}
                            />
                            <div
                              className={
                                'd-flex flex-column gap-1'
                              }
                            >
                              <p className={'m-0'}>
                                {bV.title}
                              </p>
                              <span
                                className={
                                  'text-secondary'
                                }
                              >
                                {bV.desc}
                              </span>
                              <img
                                src={bV.image}
                                className={
                                  'rounded mt-2'
                                }
                                alt={key}
                                style={{
                                  maxWidth: isMobile
                                    ? '160px'
                                    : '320px',
                                }}
                              />
                            </div>
                          </div>
                        )
                      }
                    )}
                  </div>
                </Form.Group> */}
								<FormGroup>
									{isTeletarget && (
										<>
											<>
												{campaign.teletarget
													.is_small_banner &&
													!campaign.teletarget
														.is_l_banner && (
														<>
															<TvUploadItem
																adName={
																	'teletarget'
																}
																teletarget_banner_type={
																	'small_banner'
																}
																onChange={(
																	url
																) => {
																	setFieldValue(
																		'image',
																		url
																	)
																}}
															/>
														</>
													)}

												{campaign.teletarget
													.is_l_banner &&
													!campaign.teletarget
														.is_small_banner && (
														<>
															<TvUploadItem
																adName={
																	'teletarget'
																}
																teletarget_banner_type={
																	'l_banner'
																}
															/>
														</>
													)}

												{campaign.teletarget
													.is_l_banner &&
													campaign.teletarget
														.is_small_banner && (
														<>
															<TvUploadItem
																adName={
																	'teletarget'
																}
																teletarget_banner_type={
																	'l_banner'
																}
															/>

															<TvUploadItem
																adName={'tel'}
																teletarget_banner_type={
																	'small_banner'
																}
															/>
														</>
													)}
											</>
										</>
									)}
								</FormGroup>
							</Form>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
								Отменить
							</Button>
							<Button
								variant="primary"
								onClick={handleSubmit}
								type={'submit'}
							>
								Отправить на модерацию
							</Button>
						</Modal.Footer>
					</>
				)}
			</Formik>
		</Modal>
	)
}

export default EditTvModalTeletarget
