import React, { FC, useMemo, useState } from 'react'
import useCampaignData from 'src/stores/campaignData'
import { Button, Card, Dropdown, Spinner } from 'react-bootstrap'
import { AdIcon } from '../../../Step2-ADformats/AdFormatSelector'
import s from '../../DataUpload.module.scss'
import useResponsive from 'src/utils/useResponsive'
import {
	REUSABLE_MODALS,
	useReusableModals,
} from 'src/components/UtilityComponents/ReusableModals/ReusableModalsProvider'
import { useTechnicalRequirementsStore } from 'src/stores/technical-requirements-store'
import { BookingsUploadByFormats } from '../outdoor/bookings/BookingsUploadByFormats'
import { AutoPostingLegalInfo } from '../auto-posting-legal-info/AutoPostingLegalInfo'

export const IndoorUpload: FC<any> = (props) => {
	const { isLoading, onBack, onNext } = props
	const [isMediaPlanLoading, setIsMediaPlanLoading] = useState(false)
	const showModal = useReusableModals()
	const [campaign] = useCampaignData()

	const indoor = useTechnicalRequirementsStore((state) => state.indoor)

	const isContinueDisabled = useMemo<boolean>(() => {
		const isEveryOutdoorBookingsHasMedia = indoor
			.flatMap((el) => el.booking_item)
			.every((booking) => booking.item.media_creative?.file)

		console.log(
			'map',
			indoor.flatMap((el) => el.booking_item)
		)

		return !isEveryOutdoorBookingsHasMedia
	}, [indoor])

	const handleMediaPlanClick = async () => {
		await showModal(REUSABLE_MODALS.showMediaPlanModal)
	}
	const { isMobile } = useResponsive()

	return (
		<div>
			<AutoPostingLegalInfo />

			<div className="d-flex flex-column gap-4">
				{campaign.indoor_adv_is_selected &&
					!!campaign.indoor_bookings.length && (
						<BookingsUploadByFormats advType="indoor" />
					)}
			</div>

			<div className={props.cn('buttons')}>
				<Button
					variant={'primary'}
					onClick={onNext}
					style={
						isMobile ? { width: '100%', marginLeft: '10px' } : {}
					}
					disabled={props.isLoading || isContinueDisabled}
				>
					{/* {isLastStep ? 'Последний шаг' : 'Продолжить'} */}
					Продолжить
					{isLoading && (
						<Spinner
							style={{ marginLeft: '6px' }}
							size="sm"
							animation="border"
						/>
					)}
				</Button>
				<Button variant={'secondary'} onClick={onBack}>
					Назад
				</Button>
			</div>
		</div>
	)
}
