import React, { useState } from 'react'
import { DistrictBookingCard, GarantidCard } from 'src/components/common'
import useCampaignData from 'src/stores/campaignData'
import { AdvTypeSummaryInfo } from '../common/adv-type-summary-info/AdvTypeSummaryInfo'

export const OutdoorInfo = (props: any) => {
	const [campaign, campaignInterface] = useCampaignData()

	return (
		<div className="d-flex flex-column gap-4">
			{campaign.outdoor_adv_is_selected && (
				<GarantidCard type="outdoor" />
			)}
			{campaign.district_adv_is_selected && <DistrictBookingCard />}
			<AdvTypeSummaryInfo advType="outdoor" />
		</div>
	)
}
