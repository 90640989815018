import { AnimateSharedLayout, motion } from 'framer-motion'
import React, { useCallback, useMemo, useState } from 'react'
import AdMarketDropdown from '../Dropdown'
import s from './ADMarketTreeDropDown.module.scss'
import { AdmarketTreeDropDownMenu } from './AdmarketTreeDropDownMenu'
import { RegionWithChildren } from 'src/components/_client/create-campaign/Step1-Campaign/RegionSelector'

type TreeValue = {} & {
	id: number
	title: string
	parent_id: number | null
}

interface ADMarketTreeDropDownProps {
	selectedIDs: number[]
	tree: TreeValue[]
	onChange: (ids: number[]) => void
	children: ({
		onChange,
		isItemInSelected,
	}: {
		onChange: (ids: number[]) => void
		isItemInSelected: (itemID: number) => boolean
		searchString: string
	}) => JSX.Element
	withSearch?: boolean
	displayCount?: number
	error: null | string
}

export function iterateTree(tree, cb) {
	for (let item of tree) {
		cb(item)
		if (item.children) iterateTree(item.children, cb)
	}
}

function filterRegionsByTitle(
	regions: RegionWithChildren[],
	search: string
): RegionWithChildren[] {
	// Функция проверяет, содержит ли title поисковую строку
	const matchesTitle = (title: string) =>
		title.toLowerCase().startsWith(search.toLowerCase())

	// Основная рекурсивная функция для фильтрации
	function filter(region: RegionWithChildren): RegionWithChildren | null {
		// Рекурсивно фильтруем детей
		const filteredChildren = region.children
			.map(filter) // Применяем фильтр ко всем детям
			.filter((child) => child !== null) as RegionWithChildren[] // Убираем null значения

		// Если текущий регион или хотя бы один из детей подходит под фильтр
		if (matchesTitle(region.title) || filteredChildren.length > 0) {
			return {
				...region,
				children: filteredChildren, // Сохраняем только отфильтрованных детей
			}
		}

		// Если регион и его дети не соответствуют фильтру, возвращаем null
		return null
	}

	// Применяем фильтр ко всем регионам на верхнем уровне
	return regions
		.map(filter) // Применяем фильтр ко всем элементам
		.filter((region) => region !== null) as RegionWithChildren[] // Убираем null значения
}
const ADMarketTreeDropDown = ({
	selectedIDs,
	onChange,
	withSearch = true,
	children,
	displayCount = 7,
	tree,
	error,
}: ADMarketTreeDropDownProps) => {
	const [search, setSearch] = useState<string>('')

	const isItemInSelected = useCallback(
		(itemID: number) => {
			return selectedIDs.includes(itemID)
		},
		[selectedIDs]
	)

	const selectedRegions = useMemo<TreeValue[]>(() => {
		const result: TreeValue[] = []
		iterateTree(tree, (val) => {
			if (selectedIDs.includes(val.id)) result.push(val)
		})
		return result
	}, [selectedIDs, tree])

	const searchedRegionsIds = useMemo(() => {
		const regionIds: number[] = []

		if (!search) {
			iterateTree(tree, (region) => {
				regionIds.push(region.id)
			})
		} else {
			const searchedRegions = filterRegionsByTitle(tree as any, search)

			iterateTree(searchedRegions, (region) => {
				regionIds.push(region.id)
			})
		}
		return regionIds
	}, [search, tree])

	const handleSelectAllVisible = () => {
		const selectedRegions = new Set([...selectedIDs, ...searchedRegionsIds])
		onChange(Array.from(selectedRegions))
	}
	const handleClearAllVisible = () => {
		const selectedRegions = selectedIDs.filter(
			(data) => !searchedRegionsIds.includes(data)
		)
		onChange(selectedRegions)
	}
	return (
		<>
			<AdMarketDropdown
				menu={({ close }) => (
					<AdmarketTreeDropDownMenu
						onSelectAll={handleSelectAllVisible}
						onClearAll={handleClearAllVisible}
						close={close}
						isItemInSelected={isItemInSelected}
						onChange={onChange}
						search={search}
						setSearch={setSearch}
						tree={tree}
						withSearch={withSearch}
					>
						{children}
					</AdmarketTreeDropDownMenu>
				)}
				zIndex={50}
				offset={0}
				menuWidth={'100%'}
			>
				<div
					id={'tree-dropdown'}
					className={`form-control form-control-lg noselect ${
						s.trigger
					} ${error ? 'is-invalid' : ''}`}
					tabIndex={1}
				>
					<AnimateSharedLayout>
						{selectedRegions &&
							selectedRegions.map((item, index) => {
								if (index > displayCount - 1) return null
								return (
									<motion.div
										key={item.id}
										className={s.itemSelected}
									>
										{item.title}
										<i
											className={
												'bi bi-x ps-1 text-secondary'
											}
											onClick={(e) => {
												e.stopPropagation()
												const new_value =
													selectedIDs.filter(
														(data) =>
															![
																item.id,
																item.parent_id,
															].includes(data)
													)
												onChange(new_value)
											}}
										/>
									</motion.div>
								)
							})}
						{selectedRegions.length > displayCount && (
							<motion.div className={s.itemSelected}>
								+{selectedRegions.length - displayCount}
							</motion.div>
						)}
						{selectedRegions.length === 0 && (
							<span className={'text-secondary'}>
								Выберите значения из выпадающего списка
							</span>
						)}
					</AnimateSharedLayout>
					{!error && (
						<div
							style={{
								position: 'absolute',
								top: 'calc(50% + 2)',
								right: 16,
							}}
						>
							<svg
								width="10"
								height="5"
								viewBox="0 0 10 5"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path d="M10 0H0L5 5L10 0Z" fill="#495057" />
							</svg>
						</div>
					)}
				</div>
			</AdMarketDropdown>
			{error && (
				<div
					className={'invalid-feedback'}
					style={{ display: 'block' }}
				>
					{error || 'Ошибка'}
				</div>
			)}
		</>
	)
}

export default ADMarketTreeDropDown
