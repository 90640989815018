import { DistrictBookingsType } from 'src/stores/ADMarketTypes.types'
import { authFetch } from 'src/utils/authFetch'
import { BASE_URL } from 'src/constants/api'

export const fetchDistricBookingDetails = async (districtBookingId: number) => {
	let res = await authFetch<DistrictBookingsType>({
		url: `${BASE_URL}/district_booking/${districtBookingId}/`,
		method: 'GET',
	})
	return res
}
