import React, { useMemo, useState } from 'react'
import s from '../../style.module.scss'
import cn from 'classnames'
import { ModerAdvCompanyItemType } from 'src/components/_moderator/types/ModerationRequestTypes'
import { Button, Modal, Spinner } from 'react-bootstrap'
import { CampaignItemInfoHeader } from './CampaignItemInfoHeader'
import { CampaignDetailedInfo } from '../CampaignDetailedInfo'
import { CampaignMainItemInfo } from './CampaignMainItemInfo'
import { BottomButtons } from './BottomButtons'
import { ModalBodyContent } from './ModalBodyContent'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { checkIsConfirmAvailable } from 'src/components/_moderator/utils'
import { CampaignStates } from 'src/stores/ADMarketTypes.types'

type CampaignItemInfoProps = {
	campaign: ModerAdvCompanyItemType | undefined
	isLoading: boolean
}

export const CampaignItemInfo = ({
	campaign,
	isLoading,
}: CampaignItemInfoProps) => {
	const [{ isModerLoading, detailedCampaign }, moderInterface] =
		useModerationData()
	const [action, setAction] = useState<
		'moderation' | 'adjustment' | 'reject' | 'completed' | undefined
	>()
	const minLength = 5
	const [adjustmentMessage, setAdjustmentMessage] = useState<string>('')

	const getModalTitle = () => {
		switch (action) {
			case 'moderation':
				return 'Подтверждение рекламной кампании'
			case 'adjustment':
				return 'Исправление рекламной кампании'
			case 'reject':
				return 'Отклонение рекламной кампании'
			case 'completed':
				return 'Формирование закрывающих документов'
			default:
				return ''
		}
	}

	const handleUpdateCampaignState = async () => {
		if (action !== 'completed') {
			const body = {
				// тут надо со временем по хорошему добавить public_transport но пока не реализовано 19.03.2024
				select_types: ['outdoor', 'indoor', 'internet', 'tv'],
				state_advertising: action as
					| 'moderation'
					| 'adjustment'
					| 'reject',
			}
			if (action !== 'moderation') {
				Object.assign(body, { comment: adjustmentMessage })
			}

			await moderInterface.updateAdvCampaign(campaign?.id || '', body)
			setAction(undefined)
		}
	}

	const isConfirmAvailable = useMemo(
		() => checkIsConfirmAvailable(detailedCampaign),
		[detailedCampaign]
	)
	const isSubmitDisabled =
		action !== 'completed' && action !== 'moderation'
			? adjustmentMessage?.length < minLength
			: false

	return (
		<>
			<div className={cn(s.campaignItemInfo)}>
				<div
					style={{ padding: '24px', flexGrow: 1, overflowY: 'auto' }}
				>
					{campaign && (
						<>
							<div>
								<CampaignItemInfoHeader campaign={campaign} />
								<CampaignMainItemInfo campaign={campaign} />
							</div>

							<div
								style={{
									marginTop: '24px',
									display: 'flex',
									flexDirection: 'column',
								}}
							>
								{isLoading ? (
									<Spinner
										style={{
											color: '#007BFF',
											alignSelf: 'center',
										}}
										animation={'border'}
									/>
								) : (
									<CampaignDetailedInfo />
								)}
							</div>
						</>
					)}
				</div>

				{campaign?.state !== CampaignStates.Start_waiting && (
					<BottomButtons
						isConfirmAvailable={isConfirmAvailable}
						isLoading={isModerLoading}
						action={campaign?.state as any}
						setAction={setAction}
					/>
				)}

				{!!action && (
					<Modal
						show={!!action}
						onHide={() => setAction(undefined)}
						centered
						size={'sm'}
						animation={true}
					>
						<Modal.Header closeButton>
							<Modal.Title as={'h5'} className={'ms-2'}>
								{getModalTitle()}
							</Modal.Title>
						</Modal.Header>

						<Modal.Body
							style={{
								width: '540px',
								padding: 0,
								margin: 0,
								position: 'relative',
							}}
						>
							<ModalBodyContent
								onClose={() => setAction(undefined)}
								adjustmentMessage={adjustmentMessage}
								setAdjustmentMessage={setAdjustmentMessage}
								action={action}
							/>
						</Modal.Body>

						{action !== 'completed' && (
							<Modal.Footer>
								<div
									style={{
										display: 'flex',
										gap: '8px',
										width: '100%',
										justifyContent: 'flex-end',
									}}
								>
									<Button
										variant="secondary"
										onClick={() => setAction(undefined)}
									>
										Отменить
									</Button>
									<Button
										variant="primary"
										disabled={isSubmitDisabled}
										onClick={handleUpdateCampaignState}
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '8px',
										}}
									>
										Отправить{' '}
										{isModerLoading && (
											<Spinner
												animation="border"
												size="sm"
											/>
										)}
									</Button>
								</div>
							</Modal.Footer>
						)}
					</Modal>
				)}
			</div>
		</>
	)
}
