import React, { useContext, useState } from 'react'
import { PassReset } from '../common/ui/pass-reset/PassReset'
import {
	CodeConfirmationFormData,
	CodeResendData,
	NewPasswordFormData,
	PassResetStagesType,
	PhoneInputFormData,
} from '../common/model/types'
import { FormikHelpers } from 'formik'
import useUserData, {
	PasswordRecoveryCodeData,
	PhoneConfirmationVariant,
} from 'src/stores/userData'
import { useParams } from 'react-router-dom'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import TimeFormatter from 'src/utils/time-formatter'

export const AgencyPassRecovery = () => {
	const codeResendCooldown = 60
	const defaultPhoneConfirmationVariant: PhoneConfirmationVariant = 'sms'
	const lastConfirmationVariantKey = 'agencyConfVariant'
	const expirationDateKey = 'newCodeSendingDate'

	const { agencyAlias } = useParams<{ agencyAlias: string }>()

	const { addToast } = useContext(ToastsContext)

	const [_, userInterface] = useUserData()

	const [stage, setStage] = useState<PassResetStagesType>(
		PassResetStagesType.PhoneInput
	)
	const [phoneNumber, setPhoneNumber] = useState('')

	const saveResendCooldown = () => {
		const newExpiredDate = new Date()
		newExpiredDate.setSeconds(
			newExpiredDate.getSeconds() + codeResendCooldown
		) // Устанавливаем время истечения

		sessionStorage.setItem(expirationDateKey, newExpiredDate.toISOString()) // Сохраняем дату истечения
	}

	const handleEnterPhoneNumber = async (
		values: PhoneInputFormData,
		formikHelpers: FormikHelpers<PhoneInputFormData>
	) => {
		try {
			// Получаем текущую дату и время для проверки состояния таймера
			const now = new Date()
			const newCodeSendingDate = sessionStorage.getItem(expirationDateKey)

			// Форматируем номер телефона, удаляя все нецифровые символы
			const formattedPhone = `+${values.phone_number.replace(/\D/g, '')}`
			const data: PasswordRecoveryCodeData = {
				phone_number: formattedPhone,
				variant: defaultPhoneConfirmationVariant,
			}

			// Вспомогательная функция для проверки, истек ли таймер
			const isTimerExpired = (date: string | null) => {
				return date ? now > new Date(date) : true
			}

			// Вспомогательная функция для обработки отправки кода подтверждения
			const handleCodeSending = async () => {
				await userInterface.sendPasswordRecoveryCode(data)
				saveResendCooldown()

				//сохраним confirmationVariant для child
				sessionStorage.setItem(
					lastConfirmationVariantKey,
					defaultPhoneConfirmationVariant
				)
				setPhoneNumber(values.phone_number)
				setStage(PassResetStagesType.CodeConfirmation)
			}

			// Проверяем, существует ли сохраненная дата отправки нового кода
			if (newCodeSendingDate) {
				const isDateExpired = isTimerExpired(newCodeSendingDate)

				// Проверяем, совпадает ли введенный номер телефона с ранее сохраненным
				if (values.phone_number === phoneNumber) {
					if (isDateExpired) {
						await handleCodeSending()
					} else {
						setStage(PassResetStagesType.CodeConfirmation)
						return
					}
				} else {
					if (isDateExpired) {
						await handleCodeSending()
					} else {
						// Определяем оставшееся время до истечения таймера
						const expirationDate = new Date(newCodeSendingDate)
						const remainingTime = Math.floor(
							(expirationDate.getTime() - now.getTime()) / 1000
						)
						// Устанавливаем ошибку для отображения пользователю
						formikHelpers.setFieldError(
							'phone_number',
							`Можно повторить через ${TimeFormatter(
								remainingTime
							)}`
						)
						return
					}
				}
			} else {
				await handleCodeSending()
			}
		} catch (e: any) {
			if (typeof e?.data === 'object') {
				Object.entries(e.data).forEach(([key, value]) => {
					if (key === 'detail') {
						formikHelpers.setFieldError(
							'phone_number',
							'Ошибка отправки'
						)
					}
					formikHelpers.setFieldError(
						key,
						Array.isArray(value) ? value[0] : value
					)
				})
			} else {
				formikHelpers.setFieldError('phone_number', 'Ошибка отправки')
			}
		} finally {
			formikHelpers.setSubmitting(false)
		}
	}

	const handleResendConfirmationCode = async (values: CodeResendData) => {
		try {
			const formattedPhone = `+${values.phone_number.replace(/\D/g, '')}`
			const data: PasswordRecoveryCodeData = {
				phone_number: formattedPhone,
				variant: values.confirmType,
			}

			await userInterface.sendPasswordRecoveryCode(data)
			saveResendCooldown()
			//сохраним последний сonfirmationVariant
			sessionStorage.setItem(
				lastConfirmationVariantKey,
				values.confirmType
			)
		} catch (e: any) {
			addToast({
				text: 'Ошибка отправки кода',
				type: 'danger',
			})
		}
	}

	const handleConfirmCode = async (
		values: CodeConfirmationFormData,
		formikHelpers: FormikHelpers<CodeConfirmationFormData>,
		confirmationVariant: PhoneConfirmationVariant
	) => {
		try {
			const formattedPhone = `+${phoneNumber.replace(/\D/g, '')}`
			await userInterface.acceptPasswordRecoveryCode({
				phone_number: formattedPhone,
				code: values.code,
				variant: confirmationVariant,
			})
			setStage(PassResetStagesType.NewPassword)
		} catch (e: any) {
			if (typeof e?.data === 'object') {
				Object.keys(e.data).forEach((key) => {
					if (key === 'detail') {
						formikHelpers.setFieldError('code', 'Ошибка отправки')
					}
					formikHelpers.setFieldError(key, e.data[key][0])
				})
			} else {
				formikHelpers.setFieldError('code', 'Ошибка отправки')
			}
		} finally {
			formikHelpers.setSubmitting(false)
		}
	}

	const handleEnterNewPassword = async (
		values: NewPasswordFormData,
		formikHelpers: FormikHelpers<NewPasswordFormData>
	) => {
		try {
			await userInterface.restorePassword({ password: values.password })
			addToast({ text: 'Ваш пароль изменен' })
			window.location.href = `/agency-auth/#/login/${agencyAlias}`
		} catch (e: any) {
			if (typeof e?.data === 'object') {
				Object.keys(e.data).forEach((key) => {
					if (key === 'detail') {
						formikHelpers.setFieldError(
							'password',
							'Ошибка отправки'
						)
					}
					formikHelpers.setFieldError(key, e.data[key][0])
				})
			} else {
				formikHelpers.setFieldError('password', 'Ошибка отправки')
			}
		} finally {
			formikHelpers.setSubmitting(false)
		}
	}

	const handleMoveToLogin = () => {
		window.location.href = `/agency-auth/#/login/${agencyAlias}`
	}
	const handleMoveToRegister = () => {
		window.location.href = `/auth/#/register`
	}
	return (
		<PassReset
			lastConfirmationVariantKey={lastConfirmationVariantKey}
			expirationDateKey={expirationDateKey}
			phoneNumber={phoneNumber}
			setStage={setStage}
			onResendConfirmationCode={handleResendConfirmationCode}
			onConfirmCode={handleConfirmCode}
			onEnterNewPassword={handleEnterNewPassword}
			onEnterPhoneNumber={handleEnterPhoneNumber}
			stage={stage}
			toLogin={handleMoveToLogin}
			toRegister={handleMoveToRegister}
		/>
	)
}
