import React, { useMemo } from 'react'
import { Form } from 'react-bootstrap'
import { SingleValue } from 'react-select'
import AdMarketSelect from 'src/components/UtilityComponents/ADMarketSelect/AdMarketSelect'
import {
	AdvCategoriesKeys,
	AdvCategoriesValues,
	advCategories,
} from '../constants'
import { DropdownIndicator } from './WatchTimeSelector'

type Topic = {
	label: AdvCategoriesValues
	value: AdvCategoriesKeys
}

const topics: Topic[] = Object.keys(advCategories).map((key) => ({
	label: advCategories[key as AdvCategoriesKeys],
	value: key as AdvCategoriesKeys,
}))

type Props = {
	advCategoryKey: AdvCategoriesKeys | null
	setFieldValue: (name: string, value: string | null) => null
}
export const TopicSelector = ({ advCategoryKey, setFieldValue }: Props) => {
	const defaultOption: Topic = useMemo(() => {
		if (advCategoryKey) {
			const topic = topics.find(
				(topic) => topic.value === advCategoryKey
			)!

			return topic
		} else {
			const noTopic = topics.find(
				(topic) => topic.value === 'no_category'
			)!
			return noTopic
		}
	}, [])

	const handleSelectChange = (newValue: SingleValue<any>) => {
		if (newValue?.value === 'no_category') {
			setFieldValue('topic', null)
			return
		}

		const advCategoryKey = newValue?.value as AdvCategoriesKeys | null

		setFieldValue('topic', advCategoryKey)
	}

	return (
		<Form.Group className={'mt-4'}>
			<Form.Label>Тематика рекламы</Form.Label>
			<AdMarketSelect
				defaultValue={defaultOption?.value || defaultOption}
				placeholder={
					defaultOption?.value !== 'no_category'
						? defaultOption?.label
						: 'Выберите значение из выпадающего списка'
				}
				components={{ DropdownIndicator }}
				options={topics as any}
				onChange={handleSelectChange}
			/>
		</Form.Group>
	)
}
