import React, { useEffect } from 'react'
import { useAdformatsStore } from 'src/components/_client/create-campaign/Step2-ADformats/adformats-store/adformats.store'
import useCampaignData from 'src/stores/campaignData'
import { ReactComponent as Target } from 'src/assets/images/location-3.svg'
import BookingAD from 'src/components/_client/create-campaign/Step2-ADformats/BookingsAD/BookingAD'
import { CardWrapper } from '../../../../../common/card-wrapper/CardWrapper'
import { outdoorNoItemsErrorId } from 'src/components/_client/create-campaign/Step2-ADformats/new-adformats-step/constants'
import { MoscowCentRing } from 'src/components/_client/create-campaign/Step2-ADformats/public-transport/components'

export const MCR = () => {
	return (
		<CardWrapper>
			<MoscowCentRing />
		</CardWrapper>
	)
}
