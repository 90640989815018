import React, { FC } from 'react'
import useCampaignData from '../../../stores/campaignData'
import { CampaignStates } from '../../../stores/ADMarketTypes.types'
import s from './CampaignInfo.module.scss'

type StateDataType = {
	title: string
	icon?: string
	class: any
	showMessage: boolean
	text: string
}

const state_data: { [key in CampaignStates]?: StateDataType } = {
	[CampaignStates.Draft]: {
		title: 'Черновик',
		class: s.campaignMessagePrimary,
		showMessage: false,
		text: '',
	},
	[CampaignStates.Moderation]: {
		title: 'На модерации',
		class: s.campaignMessagePrimary,
		showMessage: true,
		text: 'Проверяем заполненные данные и бронируем рекламные места на выбранные даты. Как только все проверим, отправим уведомление на почту.',
	},
	[CampaignStates.Start_waiting]: {
		title: 'Ожидает старта',
		class: s.campaignMessagePrimary,
		showMessage: false,
		text: '',
	},
	[CampaignStates.Payment_waiting]: {
		title: 'Ожидает оплаты',
		class: s.campaignMessagePrimary,
		showMessage: false,
		text: 'На вашу электронную почту отправлен счет на оплату. После того, как счет будет оплачен, вам придет уведомление по СМС, что ваша рекламная кампания запущена.',
	},
	[CampaignStates.Active]: {
		title: 'Активная',
		class: s.campaignMessagePrimary,
		showMessage: false,
		text: '',
	},
	[CampaignStates.Completed]: {
		title: 'Завершенная',
		class: s.campaignMessagePrimary,
		showMessage: false,
		text: '',
	},
	[CampaignStates.Canceled]: {
		title: 'Отмененная',
		class: s.campaignMessagePrimary,
		showMessage: false,
		text: '',
	},
	[CampaignStates.Confirmed]: {
		title: 'Модерация завершена',
		class: s.campaignMessagePrimary,
		showMessage: false,
		text: '',
	},
}

const CampaignMessage: FC = () => {
	const [campaign, campaignInterface] = useCampaignData()
	const { state } = campaign
	const data = state_data[state]
	if (!data) return null
	const isMessageNeedToShow = data.showMessage
	if (!isMessageNeedToShow) return null
	return (
		<div className={`${s.campaignMessage} ${data.class}`}>
			<i className={`bi ${data?.icon || 'bi-info-circle'} me-3 fs-5`} />
			{data.text}
		</div>
	)
}

export default CampaignMessage
