import React, { useMemo } from 'react'
import { Alert, Card, Container, ListGroup } from 'react-bootstrap'
import s from './buisness.module.scss'
import useCampaignData from 'src/stores/campaignData'
import { AdvFormats } from '../Step2-ADformats/new-adformats-step/types'
import { ReactComponent as WarningIcon } from 'src/assets/images/warningRing.svg'
import IntlFormatter from 'src/utils/intl-money-formatter'
const advFormatsTitles: Record<AdvFormats, string> = {
	outdoor: 'Наружная реклама',
	indoor: 'Внутренняя реклама',
	publicTransport: 'Общественный транспорт',
	internet: 'Интернет-реклама',
	tv: 'ТВ-реклама',
}

export const SelfBusyLimitCard = () => {
	const [campaign] = useCampaignData()

	const amounts = useMemo(() => {
		const localAmounts: Record<AdvFormats, number> = {
			outdoor: 0,
			indoor: 0,
			publicTransport: 0,
			internet: 0,
			tv: 0,
		}

		if (campaign.district_adv_is_selected) {
			localAmounts.outdoor += parseFloat(campaign.budget.district)
		}
		if (campaign.outdoor_adv_is_selected) {
			localAmounts.outdoor += parseFloat(campaign.budget.outdoor)
		}
		if (campaign.indoor_adv_is_selected) {
			localAmounts.indoor += parseFloat(campaign.budget.indoor)
		}
		if (campaign.public_transport?.is_selected) {
			localAmounts.publicTransport += parseFloat(
				campaign.budget.transport
			)
		}
		if (
			campaign.internet_vk?.is_selected ||
			campaign.internet_yandex.is_selected
		) {
			localAmounts.internet += parseFloat(campaign.budget.internet)
		}
		if (campaign.adstream.is_selected || campaign.teletarget.is_selected) {
			localAmounts.tv += parseFloat(campaign.budget.tv)
		}

		return localAmounts
	}, [campaign])

	return (
		<Card className={s.card}>
			<Container fluid className={s.limitCardContainer}>
				<ListGroup variant="flush" className={s.listGroup}>
					{Object.entries(amounts)
						.filter(([_, amount]) => amount > 0)
						.map(([format, amount]) => (
							<ListGroup.Item
								key={format}
								className={s.limitAmountItem}
							>
								<h6>
									{advFormatsTitles[format as AdvFormats]}:
								</h6>
								<h6>{IntlFormatter.format(amount)}</h6>
							</ListGroup.Item>
						))}
					<ListGroup.Item key={'total'} className={s.limitAmountItem}>
						<h5>Итого:</h5>
						<h5>{IntlFormatter.format(campaign.budget.total)}</h5>
					</ListGroup.Item>
				</ListGroup>

				<Alert className={s.alert}>
					<div>
						<WarningIcon />
					</div>
					<p>
						Физлицо не может размещать рекламу с бюджетом более
						400 000 ₽. Перейдите на шаг 2, чтобы уменьшить сумму.
					</p>
				</Alert>
			</Container>
		</Card>
	)
}
