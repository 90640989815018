import { BASE_URL } from 'src/constants/api'
import { authFetch } from 'src/utils/authFetch'
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import {
	MediaFormatViewModel,
	AdvMediaType,
	DistrictBookingItem,
	GarantidItem,
	PlacementViewModel,
} from './types'
import { districtMapper, indoorsMapper, outdoorMapper } from './mappers'
import { CampaignInfoType } from '../ADMarketTypes.types'

type State = {
	indoor: MediaFormatViewModel<GarantidItem, PlacementViewModel>[]
	outdoor: MediaFormatViewModel<GarantidItem, PlacementViewModel>[]
	district: DistrictBookingItem[]
	isLoading: boolean
}
type Actions = {
	fetchTechnicalRequirements: (campaign: CampaignInfoType) => Promise<void>
	resetAdvFormats: () => void
}

const initialState: State = {
	district: [],
	indoor: [],
	outdoor: [],
	isLoading: false,
}

export type TechnicalRequirementsStore = State & Actions

type ComposedAdvFormats = {
	indoor: MediaFormatViewModel<GarantidItem, PlacementViewModel>[]
	outdoor: MediaFormatViewModel<GarantidItem, PlacementViewModel>[]
	district: DistrictBookingItem[]
}

const composeMediaFormatsbyTechnicalRequirements = (
	mediaTypes: AdvMediaType[],
	campaign: CampaignInfoType
): ComposedAdvFormats => {
	const composedFormats = {
		indoor: indoorsMapper(mediaTypes),
		outdoor: outdoorMapper(mediaTypes),
		district: districtMapper(mediaTypes, campaign),
	}
	return composedFormats
}

export const useTechnicalRequirementsStore =
	create<TechnicalRequirementsStore>()(
		devtools(
			immer((set) => ({
				//state
				...initialState,

				//actions
				fetchTechnicalRequirements: async (
					campaign: CampaignInfoType
				) => {
					set((state) => {
						state.isLoading = true
					})

					try {
						const result = await authFetch<AdvMediaType[]>({
							method: 'GET',
							url: `${BASE_URL}/adv_companies/${campaign.id}/technical_requirements/`,
						})

						const composedFormats =
							composeMediaFormatsbyTechnicalRequirements(
								result,
								campaign
							)

						set((state) => {
							state.district = composedFormats.district
							state.indoor = composedFormats.indoor
							state.outdoor = composedFormats.outdoor
							state.isLoading = false
						})
					} catch (error) {}
				},
				resetAdvFormats: () => {
					set((state) => {
						state.district = []
						state.indoor = []
						state.outdoor = []
					})
				},
			}))
		)
	)
