import React, { FC, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

type contextFunction = (
	setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
) => React.ReactNode | React.ReactNode[]

interface IPickerDesktopLeftPannel {
	children?: React.ReactNode | React.ReactNode[] | contextFunction
}

const PickerDesktopLeftPannel: FC<IPickerDesktopLeftPannel> = ({
	children,
}) => {
	const [isExpanded, setIsExpanded] = useState(true)
	return (
		<div
			style={{
				width: '410px',
				maxWidth: '410px',
				transition: 'transform 0.3s ease-in-out',
				position: 'absolute',
				zIndex: 2,
				backgroundColor: '#fff',
				height: 'calc(100vh - 64px)',
				transform: `translateX(${isExpanded ? 0 : -410}px)`,
			}}
			// onMouseEnter={() => {
			//   debugger;
			// }}
		>
			<OverlayTrigger
				placement="right"
				overlay={(props) => (
					<Tooltip id="expand" {...props}>
						{isExpanded ? 'Свернуть' : 'Развернуть'}
					</Tooltip>
				)}
			>
				<div
					style={{
						position: 'absolute',
						top: '24px',
						right: '-68px',
						zIndex: 2,
						borderRadius: '50%',
						backgroundColor: '#fff',
						width: '48px',
						height: '48px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'pointer',
					}}
					onClick={() => setIsExpanded((p) => !p)}
				>
					<i
						style={{
							transform: isExpanded
								? 'rotate(0deg)'
								: 'rotate(-180deg)',
							transition: 'transform .5s ease-in-out',
							width: '24px',
						}}
					>
						<svg
							width="20"
							height="20"
							viewBox="0 0 20 20"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M3.4938 8.00012L9.34743 2.42896L7.96861 0.980225L0.310587 8.2687C-0.103212 8.66253 -0.103578 9.32238 0.309783 9.71667L7.96781 17.0214L9.34824 15.5742L3.50459 10.0001L19.9999 10.0012L20.0001 8.00118L11.7469 8.00065L3.4938 8.00012Z"
								fill="#212529"
							/>
						</svg>
					</i>
				</div>
			</OverlayTrigger>

			{typeof children === 'function'
				? children(setIsExpanded)
				: children}
		</div>
	)
}

export default PickerDesktopLeftPannel
