import React from 'react'
import { MoscowCentRingRequirementsArticle } from './MoscowCentRingRequirementsArticle'
import ImageFullScreenWrapper from 'src/components/UtilityComponents/ImageFullScreenWrapper'
import mcrExample from 'src/assets/images/mcrExample.png'
import useResponsive from 'src/utils/useResponsive'

export const MCRExampleArticle = (props: any) => {
	const { isMobile } = useResponsive()
	return (
		<MoscowCentRingRequirementsArticle
			title="Пример креатива"
			isBorder={false}
		>
			<ImageFullScreenWrapper fullWidth={isMobile}>
				<img style={{ paddingTop: '0.5rem' }} src={mcrExample} alt="" />
			</ImageFullScreenWrapper>
		</MoscowCentRingRequirementsArticle>
	)
}
