import React from 'react'
import { Modal } from 'react-bootstrap'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { NewContentCreatedKeysType } from 'src/stores/types/newContendCreatedType'
import { BookingType } from 'src/stores/ADMarketTypes.types'
import {
	ModalItemInfo,
	ModalItemInfoDistrictBooking,
} from '../common/ModalItemInfo'
import { AdvItemAction } from '../CampaignDetailedInfo'

type DetailedItemModalProps = {
	open: boolean
	onClose: () => void
	detailedItem: {
		type: NewContentCreatedKeysType
		id: string | number
	}
	setDetailedItem: React.Dispatch<
		React.SetStateAction<
			| {
					type: NewContentCreatedKeysType
					id: string | number
			  }
			| undefined
		>
	>
	setAdvItemActon: React.Dispatch<
		React.SetStateAction<AdvItemAction | undefined>
	>
}
export const DetailedItemModal = ({
	onClose,
	open,
	detailedItem,
	setDetailedItem,
	setAdvItemActon,
}: DetailedItemModalProps) => {
	const [{ detailedCampaign }] = useModerationData()
	const getFormat = (booking: BookingType) => {
		if (
			booking.placement.media_type.image_formats.length > 0 &&
			booking.placement.media_type.video_formats.length > 0
		) {
			return 'Изображение и видео'
		}
		if (booking.placement.media_type.image_formats.length > 0) {
			return 'Изображение'
		}
		if (booking.placement.media_type.video_formats.length > 0) {
			return 'Видео'
		}

		return 'Не определено'
	}

	const handleAdvItemActionChange = (advItemAction: AdvItemAction) => {
		setDetailedItem(undefined)
		setAdvItemActon(advItemAction)
	}

	//TODO - need to create an object instead of React.Element
	const getItem = () => {
		switch (detailedItem.type) {
			case 'yandex':
				return (
					<ModalItemInfo
						setAdvItemActionType={(action) =>
							handleAdvItemActionChange({
								action: action,
								rejectMessage:
									detailedCampaign.internet_yandex.reject_msg,
								adjustmentMessage:
									detailedCampaign.internet_yandex
										.adjustment_msg,
								isDocRequired:
									detailedCampaign.internet_yandex
										.is_docs_required,
								item: {
									id: detailedCampaign.internet_yandex.id,
									name: 'Yandex',
									placeName:
										detailedCampaign.internet_yandex.link ||
										'',
									src: 'https://storage.yandexcloud.net/admarket-images/static-img/internet.png',
								},
								type: 'yandex',
							})
						}
						budget={detailedCampaign.internet_yandex.amount || 0}
						clicksCount={
							detailedCampaign?.internet_yandex?.click_count
						}
						expenses={detailedCampaign.internet_yandex.expense}
						format="Изображение"
						src={detailedCampaign.internet_yandex.link || ''}
						state={detailedCampaign.internet_yandex.state}
						viewsCount={
							detailedCampaign?.internet_yandex?.views_count || 0
						}
						visibility="???"
					/>
				)
			case 'vk':
				return (
					<ModalItemInfo
						setAdvItemActionType={(action) =>
							handleAdvItemActionChange({
								action: action,
								rejectMessage:
									detailedCampaign.internet_vk.reject_msg,

								adjustmentMessage:
									detailedCampaign.internet_vk.adjustment_msg,
								isDocRequired:
									detailedCampaign.internet_vk
										.is_docs_required,
								item: {
									id: detailedCampaign.internet_vk.id,
									name: 'VK-реклама',
									placeName:
										detailedCampaign.internet_vk.link || '',
									src: 'https://storage.yandexcloud.net/admarket-images/static-img/internet.png',
								},
								type: 'vk',
							})
						}
						budget={detailedCampaign.internet_vk.amount || 0}
						clicksCount={detailedCampaign.internet_vk.click_count}
						expenses={detailedCampaign.internet_vk.expense}
						format="Изображение"
						src={detailedCampaign.internet_vk.link || ''}
						state={detailedCampaign.internet_vk.state}
						viewsCount={detailedCampaign?.internet_vk?.views_count}
						visibility="???"
					/>
				)
			case 'adstream':
				return (
					<ModalItemInfo
						setAdvItemActionType={(action) =>
							handleAdvItemActionChange({
								action: action,
								rejectMessage:
									detailedCampaign.adstream.reject_msg,
								adjustmentMessage:
									detailedCampaign.adstream.adjustment_msg ||
									'',
								isDocRequired:
									detailedCampaign.adstream.is_docs_required,
								item: {
									id: detailedCampaign.adstream.id,
									name: 'Adstream',
									placeName: '',
									src: 'https://storage.yandexcloud.net/admarket-images/static-img/tv_4_11zon.jpg',
								},
								type: 'adstream',
							})
						}
						budget={detailedCampaign?.adstream?.amount || 0}
						clicksCount={detailedCampaign.adstream.click_count}
						expenses={detailedCampaign.adstream.expense}
						format="Изображение"
						src={
							detailedCampaign.teletarget.image ||
							'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
						}
						state={detailedCampaign.teletarget.state}
						viewsCount={detailedCampaign.adstream.views_count}
						visibility="0"
					/>
				)
			case 'booking': {
				const booking = detailedCampaign?.booking.find(
					(el) => el.id === detailedItem.id
				)

				return (
					<ModalItemInfo
						setAdvItemActionType={(action) =>
							handleAdvItemActionChange({
								action: action,
								rejectMessage: booking?.reject_msg || '',
								adjustmentMessage:
									booking?.adjustment_msg || '',
								isDocRequired: !!booking?.is_docs_required,
								item: {
									id: booking?.id || '',
									name:
										booking?.placement.placement_type
											.name || '',
									placeName: booking?.placement.name || '',
									src:
										booking?.placement.image ||
										'https://storage.yandexcloud.net/admarket-images/static-img/outdoor_26_11zon.jpg',
								},
								type: booking?.placement.door_type!,
							})
						}
						name={booking?.placement.placement_type.name}
						address={booking?.placement.name}
						budget={booking!.booking_budget || 0}
						clicksCount={booking?.click_count || 0}
						expenses={booking?.expense || 0}
						format={getFormat(booking!)}
						src={booking!.placement.image || ''}
						state={booking?.state}
						viewsCount={booking?.views_count || 0}
						visibility={
							booking?.placement_showing_count?.showing_count_per_day.toString() ||
							''
						}
					/>
				)
			}

			case 'districtbooking': {
				const district_booking =
					detailedCampaign?.district_booking.find(
						(el) => el.id === detailedItem.id
					)

				return (
					<ModalItemInfoDistrictBooking
						setAdvItemActionType={(action) =>
							handleAdvItemActionChange({
								action: action,
								rejectMessage:
									district_booking?.reject_msg || '',
								adjustmentMessage:
									district_booking?.adjustment_msg || '',
								isDocRequired:
									!!district_booking?.is_docs_required,
								item: {
									id: district_booking?.id || '',
									name: '',
									placeName: '',
									src: 'https://storage.yandexcloud.net/admarket-images/static-img/outdoor_26_11zon.jpg',
								},
								type: 'districtbooking',
							})
						}
						districtbooking={district_booking!}
					/>
				)
			}
			case 'publicTransport':
				return (
					<ModalItemInfo
						setAdvItemActionType={(action) =>
							handleAdvItemActionChange({
								action: action,
								rejectMessage:
									detailedCampaign.public_transport
										.reject_msg,

								adjustmentMessage:
									detailedCampaign.public_transport
										.adjustment_msg,
								isDocRequired:
									detailedCampaign.public_transport
										.is_docs_required,
								item: {
									id:
										detailedCampaign.public_transport.id ||
										0,
									name: 'Цифровые экраны МЦК',
									placeName: '',
									src: '',
								},
								type: 'publicTransport',
							})
						}
						budget={detailedCampaign.public_transport.amount || 0}
						clicksCount={
							detailedCampaign.public_transport.click_count
						}
						expenses={detailedCampaign.public_transport.expense}
						format={
							detailedCampaign.public_transport.image
								? 'Изображение'
								: 'Видео'
						}
						src={''}
						state={detailedCampaign.public_transport.state}
						viewsCount={
							detailedCampaign?.public_transport?.views_count
						}
						visibility="???"
					/>
				)
		}
	}

	return (
		<Modal
			show={open}
			onHide={onClose}
			centered
			size={'sm'}
			animation={true}
		>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} className={'ms-2'}>
					Подробнее о месте
				</Modal.Title>
			</Modal.Header>

			<Modal.Body style={{ width: '540px', padding: 0, margin: 0 }}>
				{getItem()}
			</Modal.Body>
		</Modal>
	)
}
