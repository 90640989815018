import horizTempImg1 from 'src/assets/images/templates/horizontal/1-min.jpg'
import horizTempImg2 from 'src/assets/images/templates/horizontal/2-min.jpg'
import horizTempImg3 from 'src/assets/images/templates/horizontal/3-min.jpg'
import horizTempImg4 from 'src/assets/images/templates/horizontal/4-min.jpg'
import horizTempImg5 from 'src/assets/images/templates/horizontal/5-min.jpg'
import horizTempImg6 from 'src/assets/images/templates/horizontal/6-min.jpg'
import horizTempImg7 from 'src/assets/images/templates/horizontal/7-min.jpg'
import horizTempImg8 from 'src/assets/images/templates/horizontal/8-min.jpg'
import horizTempImg9 from 'src/assets/images/templates/horizontal/9-min.jpg'
import verticalTempImg1 from 'src/assets/images/templates/vertical/1-min.jpg'
import verticalTempImg2 from 'src/assets/images/templates/vertical/2-min.jpg'
import verticalTempImg3 from 'src/assets/images/templates/vertical/3-min.jpg'
import verticalTempImg4 from 'src/assets/images/templates/vertical/4-min.jpg'
import verticalTempImg5 from 'src/assets/images/templates/vertical/5-min.jpg'
import verticalTempImg6 from 'src/assets/images/templates/vertical/6-min.jpg'
import verticalTempImg7 from 'src/assets/images/templates/vertical/7-min.jpg'
import verticalTempImg8 from 'src/assets/images/templates/vertical/8-min.jpg'
import verticalTempImg9 from 'src/assets/images/templates/vertical/9-min.jpg'
import sq from 'src/assets/images/templates/maer/1-sq.jpg'

export type CreativeTemplateType = {
	url: string
	templateId: number
}

type NewRussTempalateKeysType = '640*880' | '2160*3940'

type MaerTemplateKeysType =
	| '720*760'
	| '960*1248'
	| '1024*1120'
	| '2304*1600'
	| '960*2016'
	| '896*1920'
	| '3008*960'
	| '1248*672'
	| '880*560'
	| '896*704'
	| '816*1408'
	| '1536*640'
	| '768*512'
	| '1056*608'
	| '1440*864'
	| '4200*600'
	| '960*960'
	| '288*704'
	| '1088*1280'
	| '600*448'
	| '2088*768'
	| '1120*432'
	| '624*672'
	| '1104*288'
	| '720*256'
	| '1280*640'
	| '940*300'
	| '576*320'
	| '704*384'
	| '1536*672'
	| '432*360'
	| '1056*576'
	| '1536*640'
	| '576*432'
	| '448*1024'
	| '1920*832'
	| '864*288'
	| '1740*280'
	| '1800*600'
export type CreativeTemplateKeysType =
	| NewRussTempalateKeysType
	| MaerTemplateKeysType
	| '1920*1080'
	| '1080*1920'
	| '1280*720'
	| '1248*416'
	| '1440*720'
	| '960*720'
	| '640*896'
	| '0'

type CreativeTemplatesType = {
	[key in CreativeTemplateKeysType]: ReadonlyArray<CreativeTemplateType>
}

const commonTemplatesVertical = [
	verticalTempImg1,
	verticalTempImg2,
	verticalTempImg3,
	verticalTempImg4,
	verticalTempImg5,
	verticalTempImg6,
	verticalTempImg7,
	verticalTempImg8,
	verticalTempImg9,
]

const commonTemplatesHorizontal = [
	horizTempImg1,
	horizTempImg2,
	horizTempImg3,
	horizTempImg4,
	horizTempImg5,
	horizTempImg6,
	horizTempImg7,
	horizTempImg8,
	horizTempImg9,
]

const maer = {
	sq_1: sq,
	vert_1: verticalTempImg1,
	hor_1: horizTempImg1,
}
export const creativeTemplates: CreativeTemplatesType = {
	'1080*1920': [
		{
			templateId: 16810,
			url: commonTemplatesVertical[0],
		},
		{
			templateId: 16811,
			url: commonTemplatesVertical[1],
		},
		{
			templateId: 16812,

			url: commonTemplatesVertical[2],
		},
		{
			templateId: 16813,
			url: commonTemplatesVertical[3],
		},
		{
			templateId: 16814,
			url: commonTemplatesVertical[4],
		},
		{
			templateId: 16815,
			url: commonTemplatesVertical[5],
		},
		{
			templateId: 16816,
			url: commonTemplatesVertical[6],
		},
		{
			templateId: 16817,
			url: commonTemplatesVertical[7],
		},
		{
			templateId: 16818,
			url: commonTemplatesVertical[8],
		},
		{
			templateId: 0,
			url: '',
		},
	],
	'1920*1080': [
		{
			templateId: 16801,
			url: commonTemplatesHorizontal[0],
		},
		{
			templateId: 16802,
			url: commonTemplatesHorizontal[1],
		},
		{
			templateId: 16803,
			url: commonTemplatesHorizontal[2],
		},
		{
			templateId: 16804,
			url: commonTemplatesHorizontal[3],
		},
		{
			templateId: 16805,
			url: commonTemplatesHorizontal[4],
		},
		{
			templateId: 16806,
			url: commonTemplatesHorizontal[5],
		},
		{
			templateId: 16807,
			url: commonTemplatesHorizontal[6],
		},
		{
			templateId: 16808,
			url: commonTemplatesHorizontal[7],
		},
		{
			templateId: 16809,
			url: commonTemplatesHorizontal[8],
		},
		{
			templateId: 0,
			url: '',
		},
	],
	'1280*720': [
		{
			templateId: 16819,
			url: commonTemplatesHorizontal[0],
		},
		{
			templateId: 16820,
			url: commonTemplatesHorizontal[1],
		},
		{
			templateId: 16821,
			url: commonTemplatesHorizontal[2],
		},
		{
			templateId: 16822,
			url: commonTemplatesHorizontal[3],
		},
		{
			templateId: 16823,
			url: commonTemplatesHorizontal[4],
		},
		{
			templateId: 1682,
			url: commonTemplatesHorizontal[5],
		},
		{
			templateId: 16825,
			url: commonTemplatesHorizontal[6],
		},
		{
			templateId: 16826,
			url: commonTemplatesHorizontal[7],
		},
		{
			templateId: 16827,
			url: commonTemplatesHorizontal[8],
		},
		{
			templateId: 0,
			url: '',
		},
	],
	'1248*416': [
		{
			templateId: 16899,
			url: commonTemplatesHorizontal[0],
		},
		{
			templateId: 16900,
			url: commonTemplatesHorizontal[1],
		},
		{
			templateId: 16901,
			url: commonTemplatesHorizontal[2],
		},
		{
			templateId: 16902,
			url: commonTemplatesHorizontal[3],
		},
		{
			templateId: 16903,
			url: commonTemplatesHorizontal[4],
		},
		{
			templateId: 16904,
			url: commonTemplatesHorizontal[5],
		},
		{
			templateId: 16905,
			url: commonTemplatesHorizontal[6],
		},
		{
			templateId: 16906,
			url: commonTemplatesHorizontal[7],
		},
		{
			templateId: 16907,
			url: commonTemplatesHorizontal[8],
		},
		{
			templateId: 0,
			url: '',
		},
	],
	'1440*720': [
		{
			templateId: 17143,
			url: commonTemplatesHorizontal[0],
		},
		{
			templateId: 17144,
			url: commonTemplatesHorizontal[1],
		},
		{
			templateId: 17145,
			url: commonTemplatesHorizontal[2],
		},
		{
			templateId: 17146,
			url: commonTemplatesHorizontal[3],
		},
		{
			templateId: 17147,
			url: commonTemplatesHorizontal[4],
		},
		{
			templateId: 17148,
			url: commonTemplatesHorizontal[5],
		},
		{
			templateId: 17149,
			url: commonTemplatesHorizontal[6],
		},
		{
			templateId: 17150,
			url: commonTemplatesHorizontal[7],
		},
		{
			templateId: 17151,
			url: commonTemplatesHorizontal[8],
		},
		{
			templateId: 0,
			url: '',
		},
	],
	'960*720': [
		{
			templateId: 17175,
			url: commonTemplatesHorizontal[0],
		},
		{
			templateId: 17176,
			url: commonTemplatesHorizontal[1],
		},
		{
			templateId: 17177,
			url: commonTemplatesHorizontal[2],
		},
		{
			templateId: 17178,
			url: commonTemplatesHorizontal[3],
		},
		{
			templateId: 17179,
			url: commonTemplatesHorizontal[4],
		},
		{
			templateId: 17180,
			url: commonTemplatesHorizontal[5],
		},
		{
			templateId: 17181,
			url: commonTemplatesHorizontal[6],
		},
		{
			templateId: 17182,
			url: commonTemplatesHorizontal[7],
		},
		{
			templateId: 17183,
			url: commonTemplatesHorizontal[8],
		},
		{
			templateId: 0,
			url: '',
		},
	],
	'640*896': [
		{
			templateId: 50259,
			url: commonTemplatesVertical[0],
		},
		{
			templateId: 50260,
			url: commonTemplatesVertical[1],
		},
		{
			templateId: 50261,

			url: commonTemplatesVertical[2],
		},
		{
			templateId: 50262,
			url: commonTemplatesVertical[3],
		},
		{
			templateId: 50263,
			url: commonTemplatesVertical[4],
		},
		{
			templateId: 50264,
			url: commonTemplatesVertical[5],
		},
		{
			templateId: 50265,
			url: commonTemplatesVertical[6],
		},
		{
			templateId: 50266,
			url: commonTemplatesVertical[7],
		},
		{
			templateId: 50267,
			url: commonTemplatesVertical[8],
		},
		{
			templateId: 0,
			url: '',
		},
	],

	'720*760': [
		{ templateId: 50374, url: maer.sq_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'960*1248': [
		{ templateId: 50375, url: maer.sq_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'1024*1120': [
		{ templateId: 50376, url: maer.sq_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'2304*1600': [
		{ templateId: 50377, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'960*2016': [
		{ templateId: 50378, url: maer.vert_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'896*1920': [
		{ templateId: 50379, url: maer.vert_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'3008*960': [
		{ templateId: 50380, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'1248*672': [
		{ templateId: 50381, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'880*560': [
		{ templateId: 50382, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'896*704': [
		{ templateId: 50383, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'816*1408': [
		{ templateId: 50384, url: maer.vert_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'1536*640': [
		{ templateId: 50385, url: maer.hor_1 },
		// { templateId: 50406, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'768*512': [
		{ templateId: 50386, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'1056*608': [
		{ templateId: 50387, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'1440*864': [
		{ templateId: 50388, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'4200*600': [
		{ templateId: 50389, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'960*960': [
		{ templateId: 50390, url: maer.sq_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'288*704': [
		{ templateId: 50391, url: maer.vert_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'1088*1280': [
		{ templateId: 50392, url: maer.vert_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'600*448': [
		{ templateId: 50393, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'2088*768': [
		{ templateId: 50394, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'1120*432': [
		{ templateId: 50395, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'624*672': [
		{ templateId: 50396, url: maer.sq_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'1104*288': [
		{ templateId: 50397, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'720*256': [
		{ templateId: 50398, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'1280*640': [
		{ templateId: 50399, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'940*300': [
		{ templateId: 50400, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'576*320': [
		{ templateId: 50401, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'704*384': [
		{ templateId: 50402, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'1536*672': [
		{ templateId: 50403, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'432*360': [
		{ templateId: 50404, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'1056*576': [
		{ templateId: 50405, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'576*432': [
		{ templateId: 50407, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'448*1024': [
		{ templateId: 50408, url: maer.vert_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'1920*832': [
		{ templateId: 50409, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'864*288': [
		{ templateId: 50410, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'1740*280': [
		{ templateId: 50411, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'1800*600': [
		{ templateId: 50412, url: maer.hor_1 },
		{
			templateId: 0,
			url: '',
		},
	],
	'640*880': [
		{
			templateId: 50928,
			url: commonTemplatesVertical[0],
		},
		{
			templateId: 50929,
			url: commonTemplatesVertical[1],
		},
		{
			templateId: 50930,
			url: commonTemplatesVertical[2],
		},
		{
			templateId: 50931,
			url: commonTemplatesVertical[3],
		},
		{
			templateId: 50932,
			url: commonTemplatesVertical[4],
		},
		{
			templateId: 50933,
			url: commonTemplatesVertical[5],
		},
		{
			templateId: 50934,
			url: commonTemplatesVertical[6],
		},
		{
			templateId: 50935,
			url: commonTemplatesVertical[7],
		},
		{
			templateId: 50936,
			url: commonTemplatesVertical[8],
		},
		{
			templateId: 0,
			url: '',
		},
	],
	'2160*3940': [
		{
			templateId: 50946,
			url: commonTemplatesVertical[0],
		},
		{
			templateId: 50947,
			url: commonTemplatesVertical[0],
		},
		{
			templateId: 50948,
			url: commonTemplatesVertical[0],
		},
		{
			templateId: 50949,
			url: commonTemplatesVertical[0],
		},
		{
			templateId: 50950,
			url: commonTemplatesVertical[0],
		},
		{
			templateId: 50951,
			url: commonTemplatesVertical[0],
		},
		{
			templateId: 50952,
			url: commonTemplatesVertical[0],
		},
		{
			templateId: 50953,
			url: commonTemplatesVertical[0],
		},
		{
			templateId: 50954,
			url: commonTemplatesVertical[0],
		},
		{
			templateId: 0,
			url: '',
		},
	],
	'0': [
		{
			templateId: 0,
			url: '',
		},
	],
}
