import React, { useContext, useEffect, useMemo, useState } from 'react'
import { MediaType } from './MediaInfo'
import '../../../styles/modalMediaformat.scss'
import bem from '../../../utils/bem'
import { Formik, useField } from 'formik'
import {
	Button,
	ButtonGroup,
	Col,
	Form,
	InputGroup,
	Modal,
	Row,
} from 'react-bootstrap'
import ButtonGroupBooleanSwitch from '../../UtilityComponents/ButtonGroupBooleanSwitch'

import { ConnectedFocusError } from 'focus-formik-error'
import useProviderData from '../../../stores/providerData'
import useResponsive from 'src/utils/useResponsive'
import MobileModal from 'src/components/UtilityComponents/MobileModal'
import { MediaFormatsForm } from './MediaFormatsForm'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'

const cn = bem('modal-media')
export const MEDIA_FORMATS = {
	image: ['PNG', 'JPEG', 'PDF'],
	video: ['MPEG4', 'MP4', 'MOV', 'AVI'],
	colorModel: ['RGB', 'HSL'],
	codec: ['Xvid', 'H264'],
}
type ModalMediaFormatProps = {
	show: boolean
	handleClose: () => void
	media?: MediaType
	setEdited?: any
}
const ModalMediaFormat: React.FC<ModalMediaFormatProps> = ({
	show,
	handleClose,
	media,
	setEdited,
}) => {
	const { addToast } = useContext(ToastsContext)

	const [startEditing, setstartEditing] = useState(false)
	const [submitDisabled, setSubmitDisabled] = useState(false)
	const [, ProviderInterface] = useProviderData()
	const Init = useMemo(() => {
		if (media) {
			let tmp = { ...media }
			MEDIA_FORMATS.image.forEach((el) => {
				tmp[el] = !!media.image_formats.find(
					(format) => format.toLowerCase() === el.toLowerCase()
				)
			})
			MEDIA_FORMATS.video.forEach((el) => {
				tmp[el] = !!media.video_formats.find(
					(format) => format.toLowerCase() === el.toLowerCase()
				)
			})
			// @ts-ignore
			delete tmp['state']
			// @ts-ignore
			delete tmp['in_archive']
			return tmp
		} else {
			let tmp = {
				additional_info: '',
				name: 'Медиаформат',
				is_image: true,
				is_video: true,
				video_formats: [],
				image_formats: [],
				min_height: '',
				max_height: '',
				min_width: '',
				max_width: '',
				aspect_ratio: '',
				color_model: '',
				codec: '',
				min_fps: '',
				max_fps: '',
				min_bitrate: '',
				max_bitrate: '',
				duration: '',
				with_audio_track: false,
				min_dpi: '',
				max_dpi: '',
				external_link: '',
				video_max_size: '',
				image_max_size: '',
			}
			MEDIA_FORMATS.image.forEach((el, i) => {
				tmp[el] = i <= 1
			})
			MEDIA_FORMATS.video.forEach((el, i) => {
				tmp[el] = i <= 1
			})
			return tmp
		}
	}, [media])
	async function handleSubmit(values) {
		setSubmitDisabled(true)
		let data = { ...values }
		data.image_formats = []
		data.video_formats = []
		delete data.id
		//Заполняем image formats и чистим ненужные данные
		MEDIA_FORMATS.image.forEach((el) => {
			if (data[el]) data.image_formats.push(el.toLowerCase())
			return delete data[el]
		})
		//заполняем video formats и чистим ненужные данные
		MEDIA_FORMATS.video.forEach((el) => {
			if (data[el]) data.video_formats.push(el.toLowerCase())
			return delete data[el]
		})
		//Обработка специфичных полей изображения
		if (!data.is_image) {
			;['color_model', 'min_dpi', 'max_dpi', 'image_max_size'].forEach(
				(el) => {
					return delete data[el]
				}
			)
		} else {
			data['color_model'] = data['color_model'].toLowerCase()
			data['min_dpi'] = ConvertToNumberOrNull(data['min_dpi'])
			data['max_dpi'] = ConvertToNumberOrNull(data['max_dpi'])
			data['image_max_size'] = ConvertToNumberOrNull(
				data['image_max_size']
			)
		}
		//Обработка специфичных полей видео
		if (!data.is_video) {
			;[
				'codec',
				'min_fps',
				'max_fps',
				'min_bitrate',
				'max_bitrate',
				'with_audio_track',
				'video_max_size',
			].forEach((el) => {
				return delete data[el]
			})
		} else {
			data['codec'] = data['codec'].toLowerCase()
			data['min_fps'] = ConvertToNumberOrNull(data['min_fps'])
			data['max_fps'] = ConvertToNumberOrNull(data['max_fps'])
			data['min_bitrate'] = ConvertToNumberOrNull(data['min_bitrate'])
			data['max_bitrate'] = ConvertToNumberOrNull(data['max_bitrate'])
			data['video_max_size'] = ConvertToNumberOrNull(
				data['video_max_size']
			)
		}
		//Обработка общих полей
		;[
			'min_height',
			'max_height',
			'min_width',
			'max_width',
			'duration',
		].forEach((el) => {
			data[el] = ConvertToNumberOrNull(data[el])
		})
		if (media) {
			await ProviderInterface.PatchMedia(values.id, data)
			addToast({
				text: `Медиа-формат "${media.name}" успешно изменен`,
				type: 'success',
			})
			// @ts-ignore
			handleClose()
		} else {
			await ProviderInterface.CreateMedia(data)
			addToast({
				text: `Создан новый медиа-формат`,
				type: 'success',
			})
			// @ts-ignore
			handleClose()
		}
		setSubmitDisabled(false)
	}
	function handleValidate(values) {
		setstartEditing(true)
		const errors: any = {}
		// Object.entries(values).forEach(([key, value]) => {
		//   if (value === "") {
		//     errors[key] = "Обязательное поле";
		//   }
		// });
		if (values.name === '') {
			errors.name = 'Обязательное поле'
		}
		if (!values.is_video && !values.is_image) {
			errors.notype = 'Необходимо выбрать хотя бы один тип'
		}
		if (values.is_image) {
			let flag = false
			MEDIA_FORMATS.image.forEach((el) => {
				if (values[el]) {
					flag = true
				}
			})
			if (!flag)
				errors['image_formats'] =
					'Необходимо выбрать хотя бы один формат для изображения'
		}
		if (values.is_video) {
			let flag = false
			MEDIA_FORMATS.video.forEach((el) => {
				if (values[el]) {
					flag = true
				}
			})
			if (!flag)
				errors['video_formats'] =
					'Необходимо выбрать хотя бы один формат для видео'
		}
		if (values.external_link !== '') {
			if (!values.external_link?.startsWith('https://')) {
				errors.external_link = 'Ссылка должна начинаться с https://'
			} else {
				if (!validateUrl(values.external_link))
					errors.external_link = 'Введите правильный URL'
			}
		}
		// console.log(JSON.stringify(values, null, 4));
		return errors
	}
	// @ts-ignore
	useEffect(() => {
		return () => setEdited('')
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const { isMobile } = useResponsive()

	return (
		<>
			{isMobile ? (
				<MobileModal
					enableTouching={false}
					defaultTouchingSetup={false}
					show={show}
					onHide={() => handleClose()}
					title={
						!media
							? 'Создание медиа-формата'
							: 'Редактирование медиа-формата'
					}
					paddingContent="0"
				>
					<MediaFormatsForm
						Init={Init}
						handleValidate={handleValidate}
						handleSubmit={handleSubmit}
						handleClose={handleClose}
						media={media}
						submitDisabled={submitDisabled}
					/>
				</MobileModal>
			) : (
				<Modal
					show={show}
					onHide={handleClose}
					backdrop={startEditing ? 'static' : true}
					keyboard={!startEditing}
					dialogClassName={cn()}
				>
					<Modal.Header closeButton>
						<Modal.Title>
							{!media
								? 'Создание медиа-формата'
								: 'Редактирование медиа-формата'}
						</Modal.Title>
					</Modal.Header>

					<MediaFormatsForm
						Init={Init}
						handleValidate={handleValidate}
						handleSubmit={handleSubmit}
						handleClose={handleClose}
						media={media}
						submitDisabled={submitDisabled}
					/>
				</Modal>
			)}
		</>
	)
}
export default ModalMediaFormat

export const ButtonGroupSwitch = ({
	elements,
	initialValue,
	setFieldValue,
	disabled,
	fullWidth = false,
	...props
}) => {
	// @ts-ignore
	const [active, setActive] = useState(
		initialValue ? initialValue : elements[0]
	)
	useEffect(() => {
		setFieldValue(props.name, initialValue ? initialValue : elements[0])
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	const { isMobile } = useResponsive()
	return (
		<ButtonGroup
			size={isMobile ? 'sm' : 'lg'}
			style={fullWidth ? { width: '100%' } : {}}
		>
			{elements.map((el, i) => (
				<Button
					variant={
						el.toUpperCase() === active.toUpperCase() && !disabled
							? 'primary'
							: 'secondary'
					}
					onClick={() => {
						setActive(el)
						setFieldValue(props.name, el)
					}}
					key={i}
					disabled={disabled}
					className={disabled ? 'btn-group-disabled' : ''}
				>
					{el}
				</Button>
			))}
		</ButtonGroup>
	)
}

const ConvertToNumberOrNull = (value) => {
	if (value === '') return null
	return parseInt(value)
}
export function validateUrl(value) {
	return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
		value
	)
}
