import { Column } from 'react-table'
import { ReportDetailWithStatLog, StatLogReportTableData } from '../types'
import moment from 'moment'
import React from 'react'

type StatAndLogAdFormats = 'yandex' | 'vk' | 'public_transport' | 'adstream'

const statAndLogAdFormatsTitles: Record<StatAndLogAdFormats, string> = {
	yandex: 'Яндекс.Директ',
	vk: 'VK Реклама',
	public_transport: 'Экраны МЦК',
	adstream: 'AdStream',
}

/**
 * @description Returns columns for detailed table
 */

export const getStatAnLogDetailTableColumns = (
	data: ReportDetailWithStatLog
): Column<StatLogReportTableData>[] => {
	const days = data.stat_log.map((stat) =>
		moment(stat.date, 'YYYY-MM-DD').format('D MMMM')
	)

	return [
		{
			Header: 'Способ показа',
			accessor: 'placement',
			Cell: ({ value }) => <p>{value}</p>,
			width: 340,
			minWidth: 340,
		},

		...days.map((day) => ({
			Header: day,
			columns: [
				{
					Header: 'Показы',
					accessor: (row) => row?.[day]?.views,
					Cell: ({ value }) => (
						<p>{value !== undefined ? value : 'N/A'}</p>
					),
					id: `${day}views`,
				},
				{
					Header: 'Клики',
					accessor: (row) => row?.[day]?.clicks,
					Cell: ({ value }) => (
						<p>{value !== undefined ? value : 'N/A'}</p>
					),
					id: `${day}clicks`,
				},
			],
		})),
	]
}

/**
 * @description Returns data for detailed table
 */
export const getStatAndlogDetailTableData = (
	data: {
		adv: StatAndLogAdFormats
		statLog: ReportDetailWithStatLog | null
	}[]
): StatLogReportTableData[] => {
	moment.locale('ru')

	const tableData: StatLogReportTableData[] = data.map((item) => {
		const tableRow = {
			placement: statAndLogAdFormatsTitles[item.adv],
		} as StatLogReportTableData

		for (const stat of item.statLog?.stat_log || []) {
			Object.assign(tableRow, {
				[moment(stat.date, 'YYYY-MM-DD').format('D MMMM')]: {
					views: stat.views_count,
					clicks:
						item.adv === 'public_transport' ||
						item.adv === 'adstream'
							? undefined
							: stat.clicks_count,
				},
			})
		}

		return tableRow
	})

	return tableData
}
