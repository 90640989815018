import React, { useState } from 'react'
import { InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import { Form, Button } from 'react-bootstrap'
import { Formik, Field, Form as FormikForm, FormikHelpers } from 'formik'
import * as Yup from 'yup'
import s from './login.module.scss'
import { LoginFormData } from '../../model/types'
import { SubmitButton } from '../common/SubmitButton'

type Props = {
	validationSchema: Yup.ObjectSchema<
		LoginFormData,
		Yup.AnyObject,
		{
			phone_number: undefined
			password: undefined
			rememberMe: undefined
		},
		''
	>
	onSubmit: (
		values: LoginFormData,
		formikHelpers: FormikHelpers<LoginFormData>
	) => void
	onResetPassword: () => void
}

export const LoginFormView = ({
	validationSchema,
	onSubmit,
	onResetPassword,
}: Props) => {
	const [showPassword, setShowPassword] = useState(false)

	return (
		<div>
			<Formik<LoginFormData>
				initialValues={{
					phone_number: '',
					password: '',
					rememberMe: false,
				}}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
			>
				{({ errors, touched, values, setFieldValue, isSubmitting }) => (
					<FormikForm>
						<div className={s.inputs}>
							<Form.Group>
								<Form.Label>Телефон</Form.Label>
								<InputGroup hasValidation className="m-0">
									<InputMask
										autoComplete="off"
										mask={'+7 (999) 999-99-99'}
										value={values.phone_number}
										onChange={(e) => {
											setFieldValue(
												'phone_number',
												e.target.value
											)
										}}
										disabled={isSubmitting}
									>
										{(inputProps) => (
											<Form.Control
												disabled={isSubmitting}
												name="customPhoneField"
												className={s.autofillNone}
												size="lg"
												type={'text'}
												placeholder={
													'+7 (999) 999-99-99'
												}
												style={{
													borderRadius: '16px',
													boxShadow: 'none',
													borderColor: '#CED4DA',
													background: 'white',
												}}
												isInvalid={
													touched.phone_number &&
													!!errors.phone_number
												}
												{...inputProps}
											/>
										)}
									</InputMask>

									<Form.Control.Feedback type="invalid">
										{errors.phone_number}
									</Form.Control.Feedback>
								</InputGroup>
							</Form.Group>

							<div className={s.passwordContainer}>
								<Form.Group controlId="password">
									<Form.Label>Пароль</Form.Label>
									<InputGroup
										className="d-flex flex-nowrap"
										style={{ height: '48px', margin: 0 }}
									>
										<Form.Control
											disabled={isSubmitting}
											type={
												showPassword
													? 'text'
													: 'password'
											}
											placeholder="••••"
											aria-label="Пароль"
											aria-describedby="show-pass"
											isInvalid={
												touched.password &&
												!!errors.password
											}
											style={{
												borderRight: 'none',
												borderColor: '#CED4DA',
												borderTopLeftRadius: '16px',
												borderBottomLeftRadius: '16px',
												background: 'white',
												boxShadow: 'none',
											}}
											className={s.autofillNone}
											onChange={(e) => {
												setFieldValue(
													'password',
													e.target.value
												)
											}}
										/>
										<OverlayTrigger
											placement={'top'}
											overlay={
												<Tooltip id={`tooltip-top`}>
													{showPassword
														? 'Скрыть пароль'
														: 'Показать пароль'}
												</Tooltip>
											}
										>
											<Button
												onClick={() =>
													setShowPassword(
														(state) => !state
													)
												}
												variant={'text'}
												disabled={isSubmitting}
												id="show-pass"
												style={{
													borderLeft: 'none',
													borderColor: '#CED4DA',
													borderTopRightRadius:
														'16px',
													borderBottomRightRadius:
														'16px',
													boxShadow: 'none',
												}}
											>
												{showPassword ? (
													<i
														className="bi bi-eye-slash"
														style={{
															fontSize: '24px',
														}}
													/>
												) : (
													<i
														className="bi bi-eye"
														style={{
															fontSize: '24px',
														}}
													/>
												)}
											</Button>
										</OverlayTrigger>
									</InputGroup>
									<Form.Control.Feedback
										type="invalid"
										style={{
											display: errors.password
												? 'block'
												: 'none',
										}}
									>
										{errors.password}
									</Form.Control.Feedback>
								</Form.Group>

								<span onClick={onResetPassword}>
									Не помню пароль
								</span>
							</div>

							<Form.Group
								controlId="formRememberMe"
								className="mb-3"
							>
								<Field
									disabled={isSubmitting}
									type="checkbox"
									name="rememberMe"
									className="form-check-input"
								/>
								<Form.Label className="form-check-label ms-2">
									Запомнить меня
								</Form.Label>
							</Form.Group>
						</div>

						<SubmitButton disabled={isSubmitting} text="Войти" />
					</FormikForm>
				)}
			</Formik>
		</div>
	)
}
