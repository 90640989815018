import React from 'react'
import { Modal } from 'react-bootstrap'
import MobileModal from 'src/components/UtilityComponents/MobileModal'
import useResponsive from 'src/utils/useResponsive'

export type CommonMediaModal = 'requirements' | 'edit'

type Props = {
	children: React.ReactNode
	onClose: () => void
	open: boolean
	title: string
}
export const CommonMediaModal = ({ children, onClose, open, title }: Props) => {
	const { isMobile } = useResponsive()
	return isMobile ? (
		<MobileModal
			onHide={onClose}
			show={open}
			title={title}
			defaultTouchingSetup={false}
			enableTouching={false}
			withCloseButton={true}
			paddingContent="0"
		>
			{children}
		</MobileModal>
	) : (
		<Modal
			show={open}
			onHide={onClose}
			centered
			size={'sm'}
			animation={true}
		>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} className="m-0">
					{title}
				</Modal.Title>
			</Modal.Header>
			{children}
		</Modal>
	)
}
