import { BookingState } from 'src/stores/ADMarketTypes.types'
import {
	AcceptBookingIcon,
	DownloadBookingIcon,
	EditBookingIcon,
	RejectBookingIcon,
} from './icons/ButtonsOperationsIcons'
import { ButtonVariant } from 'react-bootstrap/types'

export type BookingOperationButtonsActions =
	| 'accept'
	| 'edit'
	| 'reject'
	| 'download'
	| 'accept-report'
export interface IOperationsButtons {
	color: string
	text: string
	actionType: BookingOperationButtonsActions
	availableFor: BookingState[]
	icon: (props: Record<any, any>) => JSX.Element
	variant: ButtonVariant
}

const availableForAllStates: BookingState[] = [
	BookingState.Inner_Moderation,
	BookingState.Moderation,
	BookingState.Completed,
	BookingState.Reject,
	BookingState.Canceled,
	BookingState.Adjustment,
	BookingState.Canceled,
	BookingState.Confirm,
]
const availableInModeration: BookingState[] = [
	BookingState.Inner_Moderation,
	BookingState.Moderation,
]

const BookingOperationsButtons: IOperationsButtons[] = [
	{
		text: 'Подтвердить',
		color: '#007BFF',
		actionType: 'accept',
		availableFor: availableInModeration,
		icon: AcceptBookingIcon,
		variant: 'primary',
	},
	{
		text: 'Подвердить',
		color: '#007BFF',
		actionType: 'accept-report',
		availableFor: [BookingState.Completed],
		icon: AcceptBookingIcon,
		variant: 'primary',
	},
	{
		text: 'Исправить',
		color: '#007BFF',
		actionType: 'edit',
		availableFor: availableInModeration,
		icon: EditBookingIcon,
		variant: 'primary',
	},

	{
		text: 'Выгрузить в Excel',
		color: '#007BFF',
		actionType: 'download',
		availableFor: availableForAllStates,
		icon: DownloadBookingIcon,
		variant: 'secondary',
	},
	{
		text: 'Отклонить',
		color: '#DC3545',
		actionType: 'reject',
		availableFor: availableInModeration,
		icon: RejectBookingIcon,
		variant: 'danger',
	},
]

export default BookingOperationsButtons
