import React from 'react'
import IntlFormatter from 'src/utils/intl-money-formatter'
import s from './massCoverageItemsList.module.scss'
import { formattedNumber } from 'src/utils'

type MassCoverageStatisticProps = {
	placementsCount: number
	budgetSummary: number
}
export const MassCoverageStatistic = ({
	budgetSummary,
	placementsCount,
}: MassCoverageStatisticProps) => {
	return (
		<div className={s.massCoverageStatistic}>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					paddingBottom: '0.5rem',
					borderBottom: '1px solid #CED4DA',
				}}
			>
				<div>Всего мест:</div>
				<div>{formattedNumber(placementsCount)}</div>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					paddingTop: '0.5rem',
				}}
			>
				<div>Общий бюджет:</div>
				<div>{IntlFormatter.format(budgetSummary)}</div>
			</div>
		</div>
	)
}
