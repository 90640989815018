import React, { FC, useState } from 'react'
import s from './internet-previews.module.scss'
import useCampaignData from '../../../../../stores/campaignData'
import { removeHttps } from '../../../create-campaign/Step2-ADformats/InternetAD/URLutils'

type YandexPreviewType = 'ad' | 'search'

interface IYandexDirectPreview {
	initialType?: YandexPreviewType
	withButtons?: boolean
}

const YandexDirectPreview: FC<IYandexDirectPreview> = ({
	initialType = 'ad',
	withButtons = true,
}) => {
	const [{ internet_yandex: values }] = useCampaignData()
	const [type, setType] = useState<YandexPreviewType>(initialType)

	return (
		<div className={s.yandexContainer}>
			{withButtons && (
				<div className={s.btnContainer}>
					<button
						className={type === 'ad' ? s.blackBtn : s.greyBtn}
						onClick={() => setType('ad')}
					>
						Рекламная сеть
					</button>
					<button
						className={type === 'search' ? s.blackBtn : s.greyBtn}
						onClick={() => setType('search')}
					>
						Поиск
					</button>
				</div>
			)}
			{type === 'ad' && (
				<YandexAdTypePreview
					link={values.link || ''}
					text={values.text || ''}
					heading={values.heading || ''}
					image={values.image || ''}
				/>
			)}
			{type === 'search' && (
				<YandexSearchTypePreview
					link={values.link || ''}
					text={values.text || ''}
					heading={values.heading || ''}
					image={values.image || ''}
				/>
			)}
		</div>
	)
}

type ValuesForPreview = {
	link: string
	image: string
	heading: string
	text: string
}

const YandexAdTypePreview: React.FC<ValuesForPreview> = (props) => {
	return (
		<div className={s.yandex_ad_container}>
			<div className={s.yandex_ad_header}>
				<img src={props.image} alt={props.link} />
				<div>
					<span>{removeHttps(props.link)}</span>
					<p>реклама</p>
				</div>
			</div>
			<img
				src={props.image}
				alt={props.link}
				className={s.yandex_ad_image}
			/>
			<div className={s.yandex_ad_content}>
				<h1>{props.heading}</h1>
				<p>{props.text}</p>
			</div>
		</div>
	)
}
const YandexSearchTypePreview: React.FC<ValuesForPreview> = (props) => {
	return (
		<div className={s.yandex_search_container}>
			<div className={s.yandex_search_title}>
				<span>{props.heading}</span>
			</div>
			<div className={s.yandex_search_path}>
				<img src={props.image} alt={props.link} />
				<span>
					<b>{removeHttps(props.link)}</b>
					{'  > '}
					{props.heading}
				</span>
			</div>
			<div className={s.yandex_search_text}>
				<span className={'text-secondary'}>Реклама • </span>
				{props.text}
			</div>
		</div>
	)
}

export default YandexDirectPreview
