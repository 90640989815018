import { useLayoutEffect } from 'react'
import useResponsive from '../../utils/useResponsive'

const useHeaderTitle = (title: string) => {
	const { isDesktop } = useResponsive()
	useLayoutEffect(() => {
		const title_container = document.getElementById('header-page-title')
		if (title_container) title_container.innerHTML = isDesktop ? title : ''
		return () => {
			if (title_container) title_container.innerHTML = ''
		}
	}, [isDesktop]) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useHeaderTitle
