import { useEffect, useState } from 'react'
import { authFetch, plainFetch } from '../../../utils/authFetch'
import { Operations } from '../../../stores/ADMarketTypes.types'
import { BASE_URL } from '../../../constants/api'
import { Moment } from 'moment'

type SearchParams = {
	startDate: Moment | null
	endDate: Moment | null
	search: string
}

const useUserOperations = (
	params: SearchParams
): [operations: Operations[] | null, isLoading: boolean, error: any] => {
	const { endDate, startDate, search } = params
	const [data, setData] = useState<null | Operations[]>(null)
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [error, setError] = useState<any>(null)

	useEffect(() => {
		;(async () => {
			setData(null)
			setIsLoading(true)
			setError(null)
			const params = new URLSearchParams('')
			if (search) params.append('q', search.trim())
			if (startDate)
				params.append('start_date', startDate.format('YYYY-MM-DD'))
			if (endDate) params.append('end_date', endDate.format('YYYY-MM-DD'))
			try {
				const res = await authFetch<Operations[]>({
					url: `${BASE_URL}/operations/?${params.toString()}`,
					method: 'GET',
				})
				setData(res)
			} catch (e) {
				setIsLoading(false)
				setData(null)
				setError(e)
			} finally {
				setIsLoading(false)
			}
		})()
	}, [startDate, endDate, search])

	return [data, isLoading, error]
}

export default useUserOperations
