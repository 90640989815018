import React, { useEffect, useState } from 'react'
import s from './templateImage.module.scss'
import {
	CreativeTemplateKeysType,
	CreativeTemplateType,
} from '../../../../constants'
import { Button, Spinner } from 'react-bootstrap'
import useResponsive from 'src/utils/useResponsive'
import cn from 'classnames'
import ImageFullScreenWrapper from 'src/components/UtilityComponents/ImageFullScreenWrapper'

type TemplateImagePropsType = {
	template: CreativeTemplateType
	onChooseTemplate: () => void
	screenResolution: CreativeTemplateKeysType
	isLoading: boolean
	isSelected: boolean
	isAvailableVinstant: boolean
}

const squareTemplates = [
	'720*760',
	'960*1248',
	'1024*1120',
	'960*960',
	'624*672',
]

const verticalTemplates = [
	'1080*1920',
	'640*896',
	'960*2016',
	'896*1920',
	'816*1408',
	'288*704',
	'1088*1280',
	'448*1024',
]
export const getSize = (
	key: CreativeTemplateKeysType
): { width: string; height: string } => {
	const standardSize = { width: '15rem', height: '8.5rem' }
	const vertSize = { width: '8.5rem', height: '15rem ' }
	const squareSize = { width: '12rem', height: '12rem' }
	if (verticalTemplates.includes(key)) {
		return vertSize
	}
	if (squareTemplates.includes(key)) {
		return squareSize
	}

	return standardSize
}

export const TemplateImage = ({
	template,
	onChooseTemplate,
	screenResolution,
	isLoading,
	isSelected,
	isAvailableVinstant,
}: TemplateImagePropsType) => {
	const { isDesktop } = useResponsive()

	const [isHovered, setIsHovered] = useState(() => {
		if (isDesktop) {
			if (!isAvailableVinstant) {
				return true
			}
			return false
		} else {
			return true
		}
	})
	const [isZoomed, setIsZoomed] = useState(false)

	const handleMouseEnter = () => {
		if (!isZoomed && isAvailableVinstant) {
			setIsHovered(true)
		}
	}

	const handleMouseLeave = () => {
		if (isAvailableVinstant) {
			setIsHovered(false)
		}

		setIsZoomed(false)
	}

	const handleImageClick = (isZoomed: boolean) => {
		setIsZoomed(isZoomed)
	}

	return (
		<div
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			className={s.image_container}
			key={template.templateId}
			style={{ ...getSize(screenResolution), overflow: 'hidden' }}
		>
			<ImageFullScreenWrapper onClick={handleImageClick}>
				<img
					className={cn(s.image_item, {
						[s.image_item_hovered]: isHovered && !isZoomed,
					})}
					src={template.url}
					alt={'Шаблон' + template.templateId}
				/>
			</ImageFullScreenWrapper>

			{(!isDesktop || (isHovered && !isZoomed)) && (
				<Button
					disabled={isLoading || !isAvailableVinstant}
					onClick={onChooseTemplate}
					size="sm"
					className={cn(s.template_button, {
						[s.template_button_unavailable]: !isAvailableVinstant,
					})}
					variant={isAvailableVinstant ? 'primary' : 'text'}
				>
					{!isAvailableVinstant ? (
						'Временно недоступен'
					) : isSelected ? (
						<div>
							<Spinner
								size="sm"
								animation={'border'}
								variant={'primary'}
							/>
						</div>
					) : (
						'Выбрать'
					)}
				</Button>
			)}
		</div>
	)
}
