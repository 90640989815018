import React, { FC, useState } from 'react'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import useProviderData from '../../../stores/providerData'
import moment from 'moment'
import DateInput from '../../UtilityComponents/DropdownDatePicker'

interface IModalPlacementDisable {
	show: boolean
	placementId: string
	handleClose: () => void
	isTablet: boolean
	setSelectedItem: any
	updatePlacementsInfo: () => Promise<void>
}

const ModalPlacementDisable: FC<IModalPlacementDisable> = ({
	show,
	placementId,
	handleClose,
	isTablet,
	setSelectedItem,
	updatePlacementsInfo,
}) => {
	const [date, setDate] = useState(null)
	const [, providerInterface] = useProviderData()

	const handleDisable = async () => {
		const newId = await providerInterface.DisablePlacement(
			placementId,
			date
		)
		await updatePlacementsInfo()
		if (newId && !isTablet) setSelectedItem(newId)
		handleClose()
	}

	return (
		<Modal show={show} onHide={handleClose} centered>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} style={{ marginLeft: '8px' }}>
					Выключение рекламного носителя
				</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ padding: '40px 40px 16px 40px', zIndex: 10 }}>
				<p className={'mb-3'}>
					Рекламный носитель станет доступным для брони только после
					определенной даты или пока вы сами не включите его обратно.
				</p>
				<p className={'mb-1'}>Дата включения</p>
				<div
					style={{
						width: '270px',
						position: 'relative',
					}}
				>
					<DateInput
						value={moment(date).format('DD.MM.YYYY')}
						onChange={(date) => {
							setDate(date)
						}}
						error={undefined}
						firstAvaliableDate={moment()}
						withCheck={false}
						className={'w-100'}
					/>
					<div
						style={{
							position: 'absolute',
							top: 10,
							right: 19,
							zIndex: 10,
							cursor: date ? 'pointer' : 'default',
						}}
						onClick={(e) => {
							if (date) {
								e.stopPropagation()
								setDate(null)
							}
						}}
					>
						{date ? (
							<OverlayTrigger
								placement={'right'}
								overlay={
									<Tooltip id={`tooltip-top`}>
										Сбросить
									</Tooltip>
								}
							>
								<svg
									width="26"
									height="20"
									viewBox="0 0 26 20"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M13.0007 2.5C8.85855 2.5 5.50068 5.85786 5.50068 10C5.50068 10.2047 5.50886 10.4073 5.52488 10.6075L6.75752 9.02983L8.33354 10.2612L5.72157 13.6043C5.38156 14.0395 4.75312 14.1167 4.31792 13.7767L0.974609 11.1647L2.2059 9.58868L3.52034 10.6156C3.5073 10.412 3.50068 10.2067 3.50068 10C3.50068 4.75329 7.75398 0.5 13.0007 0.5C15.9034 0.5 18.5028 1.80306 20.2438 3.85258L18.7195 5.14742C17.3423 3.52612 15.2918 2.5 13.0007 2.5Z"
										fill="#212529"
									/>
									<path
										d="M22.481 9.38428L23.7955 10.4112L25.0267 8.83514L21.6835 6.2232C21.2483 5.8832 20.6198 5.96036 20.2798 6.39556L17.6678 9.73871L19.2438 10.9701L20.4765 9.39236C20.4925 9.59266 20.5007 9.7953 20.5007 10C20.5007 14.1421 17.1428 17.5 13.0007 17.5C10.7096 17.5 8.65911 16.4739 7.28185 14.8526L5.75758 16.1474C7.4986 18.1969 10.098 19.5 13.0007 19.5C18.2474 19.5 22.5007 15.2467 22.5007 10C22.5007 9.79324 22.4941 9.58793 22.481 9.38428Z"
										fill="#212529"
									/>
								</svg>
							</OverlayTrigger>
						) : (
							<svg
								width="18"
								height="18"
								viewBox="0 0 18 18"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M7 0V2H11V0H13V2H17C17.5523 2 18 2.44772 18 3V17C18 17.5523 17.5523 18 17 18H1C0.447715 18 0 17.5523 0 17V3C0 2.44772 0.447715 2 1 2H5V0H7ZM15.999 10H1.999L2 16H16L15.999 10ZM13 12C13.5523 12 14 12.4477 14 13C14 13.5523 13.5523 14 13 14C12.4477 14 12 13.5523 12 13C12 12.4477 12.4477 12 13 12ZM5 4H2L1.999 8H15.999L16 4H13V6H11V4H7V6H5V4Z"
									fill="#212529"
								/>
							</svg>
						)}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Отменить
				</Button>
				<Button variant="primary" onClick={handleDisable}>
					{date
						? `Выключить до ${moment(date).format('DD.MM.YYYY')}`
						: 'Выключить'}
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ModalPlacementDisable
