import React from 'react'
import s from './internetPresentationCard.module.scss'
import { InternetVariantsSelectors } from './internet-variants-selectors/InternetVariantsSelectors'
import { InternetVariants } from '../../../../../types'
import { InternetAdvHeader } from './internet-adv-header/InternetAdvHeader'
import { CardWrapper } from '../../../../common/card-wrapper/CardWrapper'

type Props = {
	selectedVariants: InternetVariants[]
	onSelectorClick: (variant: InternetVariants) => void
}
export const InternetPresentationCard = ({
	onSelectorClick,
	selectedVariants,
}: Props) => {
	return (
		<CardWrapper>
			<div className={s.outdoorPresentationCardContent}>
				<InternetAdvHeader />
				<InternetVariantsSelectors
					isLoading={false}
					onSelectorClick={onSelectorClick}
					selectedVariants={selectedVariants}
				/>
			</div>
		</CardWrapper>
	)
}
