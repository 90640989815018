import React, { useState } from 'react'
import f from '../../../../../../_client/create-campaign/Step2-ADformats/ADFormatSellector.module.scss'
import {
	AdIcon,
	SelectedIcon,
} from '../../../../../create-campaign/Step2-ADformats/AdFormatSelector'
import { AdFormatNameType } from '../CreateReportStepThree'

type CreateReportStepThreeAdFormatProps = {
	name: AdFormatNameType
	title: string
	onSelectedChange: (isSelected: boolean) => void
	disabled: boolean
}

export const CreateReportAdFormat = ({
	name,
	onSelectedChange,
	title,
	disabled,
}: CreateReportStepThreeAdFormatProps) => {
	const [isSelected, setIsSelected] = useState(false)

	const handleSelectedChange = () => {
		if (disabled) return
		onSelectedChange(!isSelected)
		setIsSelected(!isSelected)
	}
	return (
		<div
			className={isSelected ? f.formatSelected : f.format}
			onClick={handleSelectedChange}
			style={{
				opacity: disabled ? 0.5 : 1,
			}}
		>
			{/* Ваш код для отображения иконки и заголовка формата рекламы */}
			<div className={f.icon}>
				{isSelected && <SelectedIcon />}
				{!isSelected && <AdIcon name={name} />}
			</div>
			<span className={f.title + ' noselect'}>{title}</span>
		</div>
	)
}
