import React from 'react'
import { Badge, Button } from 'react-bootstrap'
import { ReactComponent as Cancel } from '../../../../../../assets/images/close cancel x.svg'
import s from '../providerFilter.module.scss'
import { PlacementFilterType } from '../../../constants'
import { PlacementFilterKeyType } from '../../../constants/placementFilters'

type FilterItemProps<T> = {
	filter: PlacementFilterType
	onDeleteFilter?: (filterKey: PlacementFilterKeyType) => void
	searchObject?: T
}

export const FilterItem = <T extends {}>({
	filter,
	onDeleteFilter,
	searchObject,
}: FilterItemProps<T>) => {
	const filterItemsCount = filter?.filterItems?.length

	const displayTitlesLimit = 2

	const handleDeleteFilter = (
		e: React.MouseEvent<HTMLDivElement, MouseEvent>
	) => {
		e.stopPropagation()
		onDeleteFilter && onDeleteFilter(filter.key)
	}

	const getTitleFromSearchObject = (itemid: string) => {
		const itemsArray = searchObject?.[filter?.searchKey || '']

		if (itemsArray && Array.isArray(itemsArray)) {
			return itemsArray.find((item) => item.id === itemid)?.name
		}
	}

	return (
		<Button
			variant={'light'}
			style={{ display: 'flex', alignItems: 'center' }}
		>
			<div style={{ alignSelf: 'flex-start' }}>{filter.title}</div>
			{filterItemsCount > displayTitlesLimit ? (
				<Badge
					style={{
						marginLeft: '4px',
					}}
				>
					{filterItemsCount}
				</Badge>
			) : (
				<div style={{ alignSelf: 'flex-start' }}>
					:{' '}
					{filter.filterItems
						.map((item) => getTitleFromSearchObject(item))
						.join(', ')}
				</div>
			)}

			<div onClick={handleDeleteFilter} className={s.deleteFilterButton}>
				<Cancel />
			</div>
		</Button>
	)
}
