import React, { Dispatch, SetStateAction } from 'react'
import { AdjusmentContent } from './AdjusmentContent'
import { FormingClosingDocs } from './FormingClosingDocs'

type ModalBodyContentProps = {
	action: 'moderation' | 'adjustment' | 'reject' | 'completed' | undefined
	setAdjustmentMessage: Dispatch<SetStateAction<string>>
	adjustmentMessage: string
	onClose: () => void
}
export const ModalBodyContent = ({
	action,
	setAdjustmentMessage,
	adjustmentMessage,
	onClose,
}: ModalBodyContentProps) => {
	return (
		<div>
			{action === 'moderation' && (
				<div
					style={{
						padding: '40px 30px',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<h5 style={{ margin: 0 }}>
						Отправить рекламную кампанию на оплату?
					</h5>
				</div>
			)}
			{(action === 'adjustment' || action === 'reject') && (
				<AdjusmentContent
					action={action}
					adjustmentMessage={adjustmentMessage}
					setAdjustmentMessage={setAdjustmentMessage}
				/>
			)}
			{action === 'completed' && <FormingClosingDocs onClose={onClose} />}
		</div>
	)
}
