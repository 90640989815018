import { useHistory } from 'react-router-dom'

export const usePushObjectToQueryParams = () => {
	const history = useHistory()

	const pushQueryParams = (queryParams) => {
		const searchParams = new URLSearchParams()

		for (const [key, value] of Object.entries(queryParams)) {
			if (Array.isArray(value)) {
				value.forEach((item) => searchParams.append(key, item))
			} else {
				searchParams.set(key, value as string)
			}
		}

		history.push({ search: `?${searchParams.toString()}` })

		return searchParams?.toString()
	}

	return pushQueryParams as (queryParams: {
		[key: string]: string | string[] | undefined
	}) => string
}
