import {
	CampaignInfoStore,
	VinstanCreativeExternalType,
	VinstantCreativeAdFormatKeys,
} from '../campaignInfo.store'

export const getVinstantLinkByAdAdFormat = (
	store: CampaignInfoStore,
	filter: {
		adFormatKey: VinstantCreativeAdFormatKeys
		itemId: string
		external_type?: VinstanCreativeExternalType
	}
): string | null => {
	const { adFormatKey, itemId, external_type } = filter
	const filteredVinstanCreatives = external_type
		? store.vinstantCreative.filter(
				(item) => item.external_type === external_type
		  )
		: store.vinstantCreative

	const adFormat = filteredVinstanCreatives.find(
		(item) => item[adFormatKey] == itemId
	)

	if (adFormat) {
		return adFormat.redirect_url || ''
	}

	return null
}
