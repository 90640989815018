import React from 'react'
import { Accordion } from 'react-bootstrap'
import s from '../../campaigns-moderation/content/campaign-item-info/style.module.scss'
import { AdvType, NotifyItem } from '../common/components/IssuesContent'
import { problemAdvTitles } from '../common/constants/problemAdvTitles'
import { IssueObjectType } from '../issues-table/CampaignIssueRow'
import ls from './mobileIssues.module.scss'
import { ReactComponent as SendNotify } from '../../../../../../assets/images/send_notify.svg'

type IssueAccordionProps = {
	title: AdvType
	issues: IssueObjectType[]
	setNotifyItem: (notifyItem: NotifyItem) => void
}
export const IssueAccordion = ({
	title,
	issues,
	setNotifyItem,
}: IssueAccordionProps) => {
	return (
		<Accordion className={s.accordion} defaultActiveKey="0">
			<Accordion.Item
				style={{ borderRadius: '8px', overflow: 'hidden' }}
				eventKey="0"
			>
				<Accordion.Header as={'div'} className={s.accordionHeader}>
					<h6>{problemAdvTitles[title]}</h6>
				</Accordion.Header>

				<Accordion.Body className={ls.advIssuesList}>
					{issues.map((issue) => (
						<AdvIssueMobile
							onNotification={setNotifyItem}
							key={issue.id}
							issue={issue}
						/>
					))}
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
}

type AdvIssueMobileProps = {
	issue: IssueObjectType
	onNotification: (notifyItem: NotifyItem) => void
}
export const AdvIssueMobile = ({
	issue,
	onNotification,
}: AdvIssueMobileProps) => {
	return (
		<div className={ls.advIssue}>
			<div
				style={{
					paddingBottom: '8px',
					borderBottom: '1px solid #CED4DA',
				}}
			>
				<p>{issue.name}</p>
				<p>{issue.placeName}</p>
			</div>

			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					padding: '8px 0',
					marginTop: '4px',
				}}
			>
				<h6>{issue.problem}</h6>
				<div
					onClick={() =>
						onNotification({
							id: issue.id,
							type: issue.type,
							name: issue.name,
							placeName: issue?.placeName,
						})
					}
				>
					<SendNotify fill="#007BFF" />
				</div>
			</div>
		</div>
	)
}
