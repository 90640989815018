import React from 'react'
import ls from '../../style.module.scss'
import { Accordion } from 'react-bootstrap'
import { BookingType } from 'src/stores/ADMarketTypes.types'
import { ImageTitle } from '../../common/ImageTitle'
import { AdvItemInfo } from '../../common/AdvItemInfo'
import { RenderPreview } from 'src/components/UtilityComponents/ProgressFileInput'
import { AdvItemActions } from '../../common/AdvItemActions'
import { AdvStateType } from '../../common/ModerationModalButton'
import { CommonAdvAccordionsProps } from '../../CampaignDetailedInfo'
import { AdjusmentMessage } from '../../common/AdjusmentMessage'
import {
	DocsListType,
	ExtraFields,
} from 'src/components/_moderator/types/ModerationRequestTypes'
import { DocsView } from '../../common/DocsView'
import useNotificationData from 'src/stores/notificationsData'
import { EditVinstantButton } from '../../common/EditVinstantButton'

type Props = CommonAdvAccordionsProps & {
	booking: BookingType & ExtraFields & DocsListType
	adjusmentMessage: string
}
export const OutdoorAccordionBody = ({
	booking,
	setDetailedItem,
	setAdvItemActon,
	adjusmentMessage,
	onEditVinstant,
}: Props) => {
	const [, notificationsInterface] = useNotificationData()

	const vinstantEditStatus = notificationsInterface.getNewContentCreated(
		booking.id
	)

	const getFormat = () => {
		if (
			booking.placement.media_type.image_formats.length > 0 &&
			booking.placement.media_type.video_formats.length > 0
		) {
			return 'Изображение и видео'
		}
		if (booking.placement.media_type.image_formats.length > 0) {
			return 'Изображение'
		}
		if (booking.placement.media_type.video_formats.length > 0) {
			return 'Видео'
		}

		return 'Не определено'
	}

	const name = booking?.media_creative?.file_name.split('_')?.[0]
	const resolutionAndExtension =
		booking?.media_creative?.file_name.split('_')?.[1]

	const handleSetDetailedItem = () => {
		setDetailedItem({ id: booking.id, type: 'booking' })
	}

	const handleSetAdvItemActionType = (
		action: 'moderation' | 'adjustment' | 'reject'
	) => {
		setAdvItemActon({
			action: action,
			type: 'outdoor',
			rejectMessage: booking.reject_msg,
			adjustmentMessage: booking.adjustment_msg,
			isDocRequired: booking.is_docs_required,
			item: {
				id: booking?.id,
				name: booking?.placement?.placement_type?.name,
				placeName: booking?.placement?.name,
				src: booking?.placement.image,
			},
		})
	}
	const handleEditVinstant = (link: string) => {
		onEditVinstant({
			instanceId: booking.id,
			newContentKey: booking.id.toString(),
			vinstantLink: link,
		})
	}
	return (
		<Accordion.Body className={ls.accordionBody}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
					padding: '16px',
				}}
			>
				<ImageTitle
					src={
						booking.placement.image ||
						'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
					}
					alt={booking.placement.placement_type.name}
					primeTitle={booking.placement.placement_type.name}
					secondaryTitle={booking.placement.name}
				/>
				<div
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '16px',
					}}
				>
					<AdvItemInfo
						budget={booking.booking_budget || 0}
						clicks={booking.click_count}
						expenses={booking.expense}
						format={getFormat()}
						shows={booking.views_count}
						status={booking.state}
					/>
				</div>

				{!!adjusmentMessage && (
					<AdjusmentMessage
						isDocRequired={booking.is_docs_required}
						adjusmentMessage={adjusmentMessage}
					/>
				)}
				{!!booking.docs.length && (
					<DocsView docs={booking.docs} id={booking.id} />
				)}

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '16px',
					}}
				>
					{booking.media_creative ? (
						<div
							style={{
								display: 'flex',
								gap: '0.5rem',
								alignItems: 'flex-start',
							}}
						>
							<div className={ls.mediaCreativePreview}>
								<RenderPreview
									style={{
										width: '60px',
										height: '60px',
										borderRadius: '4px',
										minHeight: '60px',
										minWidth: '60px',
									}}
									value={{
										name: booking.media_creative.file_name,
										src: booking.media_creative.file,
									}}
								/>
								<div>
									<div>{name}</div>
									<span>{resolutionAndExtension}</span>
								</div>
							</div>
							<EditVinstantButton
								editVinstanLink={booking.edit_vinstant}
								onEditVinstant={handleEditVinstant}
								vinstantEditStatus={vinstantEditStatus}
							/>
						</div>
					) : (
						<h5 style={{ margin: 0 }}>Нет креатива</h5>
					)}

					<AdvItemActions
						setAdvItemActionType={handleSetAdvItemActionType}
						state={booking.state as AdvStateType}
						setDetailedItem={handleSetDetailedItem}
					/>
				</div>
				{/* documents */}
			</div>
		</Accordion.Body>
	)
}
