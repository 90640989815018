import React from 'react'
import { Button } from 'react-bootstrap'
import s from './common.module.scss'

type Props = {
	onBack: () => void
	disabled: boolean
}
export const BackButton = ({ onBack, disabled }: Props) => {
	return (
		<Button
			onClick={onBack}
			variant="secondary"
			className={s.backButton}
			disabled={disabled}
		>
			<i className="bi bi-arrow-left" style={{ fontSize: '24px' }} />
			Вернуться назад
		</Button>
	)
}
