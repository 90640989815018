import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Badge } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useConfirm } from 'src/components/UtilityComponents/ConfirmModalProvider'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import moment from 'moment'
import useCampaignData, {
	CampaignsListSortField,
	campaignStatsStoreInterface,
} from 'src/stores/campaignData'
import { ClientStatisticsMobile } from './client-statistic-mobile/СlientStatisticsMobile'
import s from './ClientStatistics.module.scss'
import { StatisticsTable } from './StatisticsTable'
import { authFetch } from 'src/utils/authFetch'
import downloadBlob from 'src/utils/downloadBlob'
import { DocumentsModal } from './DocumentsModal'
import useResponsive from 'src/utils/useResponsive'

// ширина колонки таблицы по относительным единицам
// относительно 100% всей ширины (1:1 = 50:50, 1:1:2 = 25:25:50)

type Props = {
	campaigns: any
	sortField: CampaignsListSortField | undefined
	sortDirection: 'asc' | 'desc'
	setSortField: (setSortField: CampaignsListSortField | undefined) => void
	setSortDirection: (sortDirection: 'asc' | 'desc') => void
}

const ClientStatistics = ({
	campaigns,
	setSortDirection,
	setSortField,
	sortDirection,
	sortField,
}: Props) => {
	const { isMobile, isTablet } = useResponsive()

	const [campaign, campaignInterface] = useCampaignData()
	const { addToast } = useContext(ToastsContext)
	const { confirm } = useConfirm()
	const history = useHistory()

	const [expandedRows, setExpandedRows] = useState<number[]>([])
	const [addId, setAddId] = useState('')
	const [expandedData, setExpandedData] = useState({})
	const [campaignStat, setCampaignStat] = useState<{ [key: string]: any }>({})
	const [modalState, setModalState] = useState({})

	const [campaignIdForDocumnents, setCampaignIdForDocumnents] = useState<
		string | undefined
	>(undefined)

	const exportToExcel = async (campaign) => {
		const answ: Response = await authFetch({
			url: `/core/api/v1/adv_companies/stats/total/export/`,
			method: 'POST',
			body: {
				id: [campaign.id],
			},
			raw: true,
		})
		if (answ.ok) {
			const blob = await answ.blob()
			downloadBlob(
				blob,
				`Отчет по рекламной кампании ${campaign?.name}`,
				'xlsx'
			)
		} else {
			throw new Error('Ошибка при загрузке')
		}
	}

	const handleCampaign = async (id) => {
		const expandedIndex = expandedRows.indexOf(id)
		const newExpandedRows = [...expandedRows]

		if (expandedIndex === -1) {
			newExpandedRows.push(id)
			setExpandedRows(newExpandedRows)
			setAddId(id)

			try {
				await campaignStatsStoreInterface.fetchCampaignStats(id)
				const stats = campaignStatsStoreInterface.get()

				setCampaignStat((prevState) => {
					const updatedStat = { ...prevState }
					updatedStat[id] = { id, ...stats }
					return updatedStat
				})

				setExpandedData((prevData) => ({
					...prevData,
					[id]: {
						bookingAD: { ...bookingAD },
						internetAD: { ...internetAD },
						tvAD: { ...tvAD },
						districtbooking: { ...districtBookingAD },
						public_transport: { publicTransportAD },
						totals: { ...totals },
					},
				}))
			} catch (error) {
				console.error('Failed to fetch campaign stats:', error)
			}
		} else {
			newExpandedRows.splice(expandedIndex, 1)
			setAddId('')

			setExpandedData((prevData) => {
				const updatedData = { ...prevData }
				delete updatedData[id]
				return updatedData
			})

			setExpandedRows(newExpandedRows)
		}
	}

	//Функция перехода на конкретную компанию при нажатии на название
	const handleCampaignClick = (id, state) => {
		history.push('/campaigns/' + id)
	}
	//---------------------------------------------------------------------------------------------------------

	//Функция открытия и закрытия модального окна по id
	const toggleModal = (itemId) => {
		setModalState((prevState) => ({
			...prevState,
			[itemId]: !prevState[itemId],
		}))
	}
	const openModal = (itemId) => {
		const isModalOpen = Object.values(modalState).some((value) => value)

		if (!isModalOpen || modalState[itemId]) {
			toggleModal(itemId)
		}
	}
	//---------------------------------------------------------------------------------------------------------

	const publicTransportAD = useMemo(() => {
		if (campaignStat[addId]?.public_transport?.is_selected) {
			return campaignStat[addId].public_transport
		}

		return {}
	}, [campaignStat, addId])

	const districtBookingAD = useMemo(() => {
		let object = { districtbooking: [] as any[] }
		if (
			!campaignStat ||
			!campaignStat[addId] ||
			!campaignStat[addId]?.district_booking ||
			campaignStat[addId]?.district_booking?.length === 0
		) {
		} else {
			object.districtbooking = campaignStat[addId].district_booking
		}

		return { ...object }
	}, [campaignStat, addId])

	//Достаем данные из обьектов
	const bookingAD = useMemo(() => {
		let res: {
			indoor?: typeof campaignStat.booking[]
			outdoor?: typeof campaignStat.booking[]
		} = {}
		if (
			!campaignStat ||
			!campaignStat[addId] ||
			!campaignStat[addId].booking ||
			campaignStat[addId].booking.length === 0
		)
			return res

		campaignStat[addId].booking.forEach((b) => {
			if (b.placement.door_type === 'indoor') {
				if (!res.indoor) {
					res.indoor = []
				}
				res.indoor.push(b)
			}
			if (b.placement.door_type === 'outdoor') {
				if (!res.outdoor) {
					res.outdoor = []
				}
				res.outdoor.push(b)
			}
		})

		return { ...res }
	}, [campaignStat, addId])

	const internetAD = useMemo(() => {
		const res: { [k in 'vk' | 'yandex']?: typeof campaignStat.yandex } = {}
		if (
			campaignStat &&
			campaignStat[addId] &&
			campaignStat[addId].vk &&
			campaignStat[addId].vk.is_selected
		) {
			res.vk = campaignStat[addId].vk
		}
		if (
			campaignStat &&
			campaignStat[addId] &&
			campaignStat[addId].yandex &&
			campaignStat[addId].yandex.is_selected
		) {
			res.yandex = campaignStat[addId].yandex
		}
		return { ...res }
	}, [campaignStat, addId])

	const tvAD = useMemo(() => {
		const res: {
			[k in 'teletarget' | 'adstream']?:
				| typeof campaignStat.adstream
				| typeof campaignStat.teletarget
		} = {}
		if (
			campaignStat &&
			campaignStat[addId] &&
			campaignStat[addId].adstream &&
			campaignStat[addId].adstream.is_selected
		) {
			res.adstream = campaignStat[addId].adstream
		}
		if (
			campaignStat &&
			campaignStat[addId] &&
			campaignStat[addId].teletarget &&
			campaignStat[addId].teletarget.is_selected
		) {
			res.teletarget = campaignStat[addId].teletarget
		}
		return { ...res }
	}, [campaignStat, addId])

	const totals = useMemo<{
		shows: number
		amount: number
		clicks: number
		fact_cost: number
	}>(() => {
		const res = { shows: 0, amount: 0, clicks: 0, fact_cost: 0 }
		for (const [, value] of Object.entries(internetAD)) {
			res.shows += value.views_count ? value.views_count : 0
			res.amount += value.amount ? value.amount : 0
			res.clicks += value.click_count ? value.click_count : 0
			res.fact_cost += value.fact_cost ? value.fact_cost : 0
		}
		for (const [, value] of Object.entries(tvAD)) {
			res.shows += value.views_count ? value.views_count : 0
			res.amount += value.amount ? parseFloat(value.amount) : 0
			res.clicks += value.click_count ? value.click_count : 0
			res.fact_cost += value.fact_cost ? value.fact_cost : 0
		}
		for (const [, bookings] of Object.entries(bookingAD)) {
			for (let value of bookings) {
				res.shows += value.views_count ? value.views_count : 0
				res.amount += value.booking_budget ? value.booking_budget : 0
				res.clicks += value.click_count ? value.click_count : 0
				res.fact_cost += value.fact_cost ? value.fact_cost : 0
			}
		}
		for (const [, districtbooking] of Object.entries(districtBookingAD)) {
			for (let value of districtbooking) {
				res.shows += value.views_count ? value.views_count : 0
				res.amount += value.amount ? parseFloat(value.amount) : 0
				res.clicks += value.click_count ? value.click_count : 0
				res.fact_cost += value.fact_cost ? value.fact_cost : 0
			}
		}

		if (publicTransportAD?.is_selected) {
			res.shows += publicTransportAD.views_count
				? publicTransportAD.views_count
				: 0
			res.amount += publicTransportAD.amount
				? parseFloat(publicTransportAD.amount)
				: 0
			res.clicks += publicTransportAD.click_count
				? publicTransportAD.click_count
				: 0
			res.fact_cost += publicTransportAD.fact_cost
				? publicTransportAD.fact_cost
				: 0
		}
		return { ...res }
	}, [bookingAD, internetAD, tvAD, districtBookingAD])

	//Функция удаления компании
	const handleCampaignDelete = async (id) => {
		const answ = await confirm({
			text: 'После этого действия вы не сможете ее вернуть обратно',
			title: 'Удалить рекламную кампанию?',
			closeButton: true,
			acceptText: 'Да, уверен',
			declineText: 'Нет, отменить',
			acceptVariant: 'danger',
		})
		if (answ) {
			const errors: any = await campaignInterface.deleteCampaign(id)
			if (errors) {
				addToast({ text: errors?.data[0], type: 'danger' })
			} else {
				addToast({
					text: 'Рекламная кампания удалена',
					type: 'success',
				})
				if (modalState[id]) {
					delete modalState[id]
				}
			}
		}
	}

	const handleCampaignCancel = async (id) => {
		const answ = await confirm({
			text: 'После этого действия вы сможете только вернуть кампанию обратно в черновик или заново отправить на модерацию',
			title: 'Вы уверены, что хотите отменить рекламную кампанию?',
			closeButton: true,
			acceptText: 'Да, уверен',
			declineText: 'Нет, отменить',
			acceptVariant: 'danger',
		})
		if (answ) {
			const errors: any = await campaignInterface.cancelCampaign(id)
			if (errors) {
				addToast({ text: errors?.data[0], type: 'danger' })
			} else {
				addToast({
					text: 'Рекламная кампания отменена',
					type: 'success',
				})
			}
		}
	}
	//---------------------------------------------------------------------------------------------------------

	useEffect(() => {
		setExpandedData((prevData) => ({
			...prevData,
			[addId]: {
				bookingAD: { ...bookingAD },
				districtBooking: { ...districtBookingAD },
				internetAD: { ...internetAD },
				tvAD: { ...tvAD },
				public_transport: { ...publicTransportAD },
				totals: { ...totals },
			},
		}))
	}, [
		bookingAD,
		internetAD,
		tvAD,
		totals,
		districtBookingAD,
		publicTransportAD,
	])
	//-------------------------------------------------------------------------------------------

	return (
		<>
			{!isMobile && (
				<div className={s.tableFrame}>
					<StatisticsTable
						setCampaignIdForDocumnents={setCampaignIdForDocumnents}
						campaignIdForDocumnents={campaignIdForDocumnents}
						initData={campaigns}
						handleCampaign={handleCampaign}
						expandedRows={expandedRows}
						expandedData={expandedData}
						modalState={modalState}
						campaignStat={campaignStat}
						openModal={openModal}
						toggleModal={toggleModal}
						exportToExcel={exportToExcel}
						handleCampaignClick={handleCampaignClick}
						handleCampaignDelete={handleCampaignDelete}
						handleCampaignCancel={handleCampaignCancel}
						sortField={sortField}
						sortDirection={sortDirection}
						setSortField={setSortField}
						setSortDirection={setSortDirection}
					/>
				</div>
			)}

			{isMobile && (
				<ClientStatisticsMobile
					setCampaignIdForDocumnents={setCampaignIdForDocumnents}
					campaignIdForDocumnents={campaignIdForDocumnents}
					handleCampaignClick={handleCampaignClick}
					exportToExcel={exportToExcel}
					oneData={campaigns}
				/>
			)}

			{campaignIdForDocumnents && (
				<DocumentsModal
					campaignId={campaignIdForDocumnents}
					onClose={() => setCampaignIdForDocumnents(undefined)}
					open={!!campaignIdForDocumnents}
				/>
			)}
		</>
	)
}

//Функция проверки может ли компания быть удалена
export const isCampaignCanBeDeleted = (campaigns) => {
	const { state } = campaigns
	return state === 'draft' || state === 'canceled' || state === 'completed'
}

export const getStartEndString = (start, end) => {
	const s = moment(start, 'YYYY-MM-DD')
	const e = moment(end, 'YYYY-MM-DD')
	return `${s.format('DD.MM.YYYY')} - ${e.format('DD.MM.YYYY')}`
}

export const CampaignBadge = ({ state = 'draft', ...props }) => {
	const bg = useMemo(() => {
		switch (state) {
			case 'moderation':
				return 'primary'
			case 'paid':
			case 'confirmed':
				return 'success'
			case 'payment_waiting':
				return 'primary'
			case 'start_waiting':
				return 'primary'
			case 'active':
				return 'primary'
			case 'completed':
				return 'success'
			case 'deleted':
				return 'danger'
			case 'canceled':
				return 'danger'
			case 'draft':
				return 'secondary'
		}
	}, [state])

	const text = useMemo(() => {
		switch (state) {
			case 'moderation':
				return 'На модерации'
			case 'confirmed':
				return 'Модерация завершена'
			case 'payment_waiting':
				return 'Ожидает оплаты'
			case 'start_waiting':
				return 'Ожидает старта'
			case 'active':
				return 'Активная'
			case 'completed':
				return 'Завершена'
			case 'deleted':
				return 'Удалена'
			case 'canceled':
				return 'Отменена'
			case 'draft':
				return 'Черновик'
			case 'paid':
				return 'Оплачена'
		}
	}, [state])

	return (
		<Badge
			bg={bg}
			style={{
				fontSize: '12px',
				fontWeight: 'normal',
				height: 'fit-content',
				textAlign: 'right',
			}}
			{...props}
		>
			{text}
		</Badge>
	)
}

export default ClientStatistics
