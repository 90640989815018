export type HelpContentKey =
	| 'registration'
	| 'campaign-creation'
	| 'media-plan'
	| 'outdoor'
	| 'indoor'
	| 'internet'
	| 'tv'
	| 'statistics'
	| 'documents'
	| 'person'
	| 'legal'

export type HelpListBaseItemType = {
	title: string
	key: HelpContentKey
}

export type ActiveItemType = {
	itemId: HelpContentKey
	nestedId: HelpContentKey | undefined
}
export type HandleActiveItemChangeType = (
	itemId: HelpContentKey,
	nestedId?: HelpContentKey | undefined
) => void

export type HelpListNestedItemType = {
	nestedList?: HelpListBaseItemType[]
} & HelpListBaseItemType

export const helpList: HelpListNestedItemType[] = [
	{
		title: 'Регистрация',
		key: 'registration',
	},
	{
		key: 'campaign-creation',
		title: 'Создание кампании',
		nestedList: [
			{
				key: 'person',
				title: 'Для физических лиц',
			},
			{
				key: 'legal',
				title: 'Для юридических лиц',
			},
		],
	},
	{
		title: 'Медиаплан',
		key: 'media-plan',
	},
	{
		title: 'Наружная реклама',
		key: 'outdoor',
	},
	{
		title: 'Внутренняя реклама',
		key: 'indoor',
	},
	{
		title: 'Интернет-реклама',
		key: 'internet',
	},
	{
		title: 'ТВ-реклама',
		key: 'tv',
	},
	{
		title: 'Статистика',
		key: 'statistics',
	},
	{
		title: 'Документы',
		key: 'documents',
	},
]
