import React, { useEffect, useRef, useState } from 'react'
import { Cell, Row } from 'react-table'
import { CampaignViewsReportTableData } from '../CampaignViewsReportTable'
import { Button, Form } from 'react-bootstrap'
import { NumericFormat } from 'react-number-format'
import { ReactComponent as SchemeIcon } from 'src/assets/images/scheme-2.svg'
import { ReactComponent as WarningIcon } from 'src/assets/images/warningRing.svg'
import Tooltip from 'src/components/UtilityComponents/Tooltip'
import s from './cell.module.scss'
import { formattedNumber } from 'src/utils'
import { Field } from 'formik'

type ProviderViewsTableCellProps = {
	value: number | null
	isEditable: boolean
	cell: Cell<CampaignViewsReportTableData, any>
	onCellChange?: (
		cell: Cell<CampaignViewsReportTableData, any>,
		value: number
	) => void
	onAllColumnValuesChange?: (
		columnId: 'forecast' | 'actual',
		value: number
	) => void
}
export const ProviderViewsEditableTableCell = ({
	value,
	cell,
	onCellChange,
	onAllColumnValuesChange,
	isEditable,
}: ProviderViewsTableCellProps) => {
	const [isEditMode, setIsEditMode] = useState(false)
	const [localValue, setLocalValue] = useState<string>(
		value ? value.toString() : '0'
	)
	const inputRef = useRef<HTMLInputElement | null>(null)

	const [lastTap, setLastTap] = useState(0)
	const tapThreshold = 200 // Time threshold in milliseconds

	const handleTouch = (event) => {
		const currentTime = new Date().getTime()
		const timeDifference = currentTime - lastTap

		if (timeDifference < tapThreshold && timeDifference > 0) {
			setIsEditMode(true)
			if (inputRef.current) {
				inputRef.current.focus()
			}
			// Perform the double-tap action here
		} else {
			// Handle single tap (if needed)
		}

		setLastTap(currentTime)
	}
	const handleBlur = () => {
		setIsEditMode(false)

		if (isEditMode) {
			handleSetValue()
		}
	}

	const handleSetValue = () => {
		if (onCellChange) {
			const clearStringValue = localValue.trim().replaceAll(',', '')
			const numberedValue = parseInt(clearStringValue)

			onCellChange(cell, numberedValue || 0)
		}
	}
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLocalValue(event.target.value)
	}

	const handleKeyDown = (event: React.KeyboardEvent) => {
		if (event.key === 'Enter') {
			setIsEditMode(!isEditMode)

			if (isEditMode) {
				handleSetValue()
			}
		}
	}

	const handleAllColumnValuesChange = (
		cell: Cell<CampaignViewsReportTableData, any>
	) => {
		if (onAllColumnValuesChange && value !== null) {
			onAllColumnValuesChange(
				cell.column.id as 'forecast' | 'actual',
				value
			)
		}
	}
	const handleDoubleClick = () => {
		setIsEditMode(true)
		if (inputRef.current) {
			inputRef.current.focus()
		}
	}
	useEffect(() => {
		if (isEditMode && inputRef.current) {
			inputRef.current.focus()
		}
	}, [isEditMode])
	return (
		<td
			className={s.editableCell}
			{...cell.getCellProps()}
			key={`${cell.row.id}_${cell.column.id}`}
			style={{
				width: cell.column?.width,
			}}
			tabIndex={0}
			onKeyDown={handleKeyDown}
		>
			<div className={s.editableCellContent}>
				<Field name={`statistics[${cell.row.index}].${cell.column.id}`}>
					{({ field, form }) => {
						const actualError =
							form.errors.statistics?.[cell.row.index]?.actual
						const isActualCell = cell.column.id === 'actual'

						return isEditable && isEditMode ? (
							<NumericFormat
								customInput={CustomFormControl} // Use the wrapper component
								onBlur={handleBlur}
								getInputRef={inputRef}
								onKeyDown={handleKeyDown}
								style={{
									height: '100%',
									width: '100%',
								}}
								value={value || 0}
								thousandSeparator=","
								decimalScale={2}
								allowNegative={false}
								onChange={handleChange}
							/>
						) : (
							<div
								onKeyDown={handleKeyDown}
								onTouchStart={handleTouch}
								onDoubleClick={handleDoubleClick}
								className={s.editableCellView}
							>
								<div className="d-flex align-items-center">
									<p>
										{value !== null
											? formattedNumber(value)
											: 'Не заполнено'}
									</p>
								</div>

								{isActualCell &&
									!actualError &&
									isEditable &&
									value !== null && (
										<Tooltip text="Применить ко всем">
											<Button
												variant="light"
												className={s.applyAllButton}
												onClick={() =>
													handleAllColumnValuesChange(
														cell
													)
												}
											>
												<SchemeIcon />
											</Button>
										</Tooltip>
									)}
								{isActualCell && actualError && (
									<Tooltip text={actualError}>
										<div className="d-flex jusity-content-center align-items-center h-100">
											<Button
												variant="text"
												className={s.applyAllButton}
												style={{
													visibility: 'visible',
												}}
											>
												<WarningIcon
													style={{ fill: '#DC3545' }}
												/>
											</Button>
										</div>
									</Tooltip>
								)}
							</div>
						)
					}}
				</Field>
			</div>
		</td>
	)
}
const CustomFormControl = React.forwardRef((props, ref) => {
	return (
		<Form.Control
			{...props}
			ref={ref as React.MutableRefObject<HTMLInputElement | null>}
			isInvalid={props['aria-invalid']}
		/>
	)
})
CustomFormControl.displayName = 'CustomFormControl'
