import React, { useEffect, useState } from 'react'
import ls from '../../style.module.scss'
import { Accordion } from 'react-bootstrap'
import { AdvItemInfo } from '../../common/AdvItemInfo'
import { RenderPreview } from 'src/components/UtilityComponents/ProgressFileInput'
import { AdvItemActions } from '../../common/AdvItemActions'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { ReactComponent as Yandex } from '../../../../../../../../../assets/images/yandex.svg'
import { InternetInfo } from '../../common/InternetInfo'
import { AdvStateType } from '../../common/ModerationModalButton'
import { CommonAdvAccordionsProps } from '../../CampaignDetailedInfo'
import { AdjusmentMessage } from '../../common/AdjusmentMessage'
import { DocsView } from '../../common/DocsView'
import useNotificationData from 'src/stores/notificationsData'
import { EditVinstantButton } from '../../common/EditVinstantButton'

type Props = CommonAdvAccordionsProps & {
	adjusmentMessage: string
}
export const YandexAccordionBody = ({
	setDetailedItem,
	setAdvItemActon,
	adjusmentMessage,
	onEditVinstant,
}: Props) => {
	const [moder, moderInterface] = useModerationData()

	const yandex = moder.detailedCampaign.internet_yandex

	const [, notificationsInterface] = useNotificationData()

	const vinstantEditStatus =
		notificationsInterface.getNewContentCreated('yandex')

	const [resolution, setResolution] = useState('')
	const getResolutionAndExtension = async () => {
		const extension = yandex?.image?.split('.').pop()

		const image = new Image()
		image.src = yandex.image || ''
		image.onload = function () {
			setResolution(`${image.width}x${image.height}.${extension}`)
		}
	}

	const handleSetDetailedItem = () => {
		setDetailedItem({ id: yandex.id, type: 'yandex' })
	}

	useEffect(() => {
		getResolutionAndExtension()
	}, [])

	const handleSetAdvItemActionType = (
		action: 'moderation' | 'adjustment' | 'reject'
	) => {
		setAdvItemActon({
			action: action,
			type: 'yandex',
			rejectMessage: yandex.reject_msg,
			adjustmentMessage: yandex.adjustment_msg,
			isDocRequired: yandex.is_docs_required,
			item: {
				id: yandex.id,
				name: 'Yandex',
				placeName: yandex.link || '',
				src: 'https://storage.yandexcloud.net/admarket-images/static-img/internet.png',
			},
		})
	}
	const handleEditVinstant = (link: string) => {
		onEditVinstant({
			instanceId: yandex.id,
			newContentKey: 'yandex',
			vinstantLink: link,
		})
	}
	return (
		<Accordion.Body className={ls.accordionBody}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
					padding: '16px',
				}}
			>
				<div
					style={{
						display: 'flex',
						gap: '16px',
						alignItems: 'center',
					}}
				>
					<Yandex /> <h6 style={{ margin: 0 }}>Яндекс</h6>
				</div>

				<div
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '16px',
					}}
				>
					<AdvItemInfo
						budget={yandex.amount || 0}
						clicks={yandex.click_count}
						expenses={yandex.expense}
						format={'Изображение'}
						shows={yandex.views_count}
						status={yandex.state}
					/>
				</div>
				{!!adjusmentMessage && (
					<AdjusmentMessage
						isDocRequired={yandex.is_docs_required}
						adjusmentMessage={adjusmentMessage}
					/>
				)}

				{!!yandex.docs.length && (
					<DocsView docs={yandex.docs} id={yandex.id} />
				)}
				<InternetInfo item={yandex} type="yandex" />

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '16px',
					}}
				>
					{yandex.link ? (
						<div
							style={{
								display: 'flex',
								gap: '0.5rem',
								alignItems: 'flex-start',
							}}
						>
							<div className={ls.mediaCreativePreview}>
								<RenderPreview
									style={{
										width: '60px',
										height: '60px',
										borderRadius: '4px',
										minHeight: '60px',
										minWidth: '60px',
									}}
									value={{
										name: '',
										src: yandex.image,
									}}
								/>
								<div>
									<div>{yandex.heading}</div>
									<span>{resolution}</span>
								</div>
							</div>
							<EditVinstantButton
								editVinstanLink={yandex.edit_vinstant}
								onEditVinstant={handleEditVinstant}
								vinstantEditStatus={vinstantEditStatus}
							/>
						</div>
					) : (
						<h5 style={{ margin: 0 }}>Нет креатива</h5>
					)}

					<AdvItemActions
						setAdvItemActionType={handleSetAdvItemActionType}
						state={yandex.state as AdvStateType}
						setDetailedItem={handleSetDetailedItem}
					/>
				</div>

				{/* documents */}
			</div>
		</Accordion.Body>
	)
}
