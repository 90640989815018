import React from 'react'
import { PlacementShort } from 'src/stores/ADMarketTypes.types'
import { LeftPanelHeader } from './LeftPanelHeader'
import { LeftPanelPlacementList } from './LeftPanelPlacementList'
import { LeftPanelFooter } from './LeftPanelFooter'
import { Address } from '../../modals/MassCoverageModalMap'

type Props = {
	onRadiusChange: (radius: number) => void
	radius: number
	onPlacementClick: (placement: PlacementShort) => void
	placements: PlacementShort[]
	onAddPlacements: () => void
	isPlacementsTheSame: boolean
	onSearchStringChange: (searchString: string) => void
	isFooterDisabled: boolean
	itemsCount: number
	addedPlacement: PlacementShort[]
	searchAddresses: Address[] | null
	onAddressClick: (address: Address) => void
}
export const MassCoverageItemsControl = ({
	onRadiusChange,
	radius,
	onPlacementClick,
	placements,
	onAddPlacements,
	isPlacementsTheSame,
	onSearchStringChange,
	isFooterDisabled,
	itemsCount,
	addedPlacement,
	searchAddresses,
	onAddressClick,
}: Props) => {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
				height: '100%',
				maxHeight: '100%',
			}}
		>
			<div
				style={{
					display: 'flex',
					gap: '16px',
					alignItems: 'center',
					width: '100%',
				}}
			>
				<LeftPanelHeader
					isRadiusBlock={!searchAddresses}
					onSearchStringChange={onSearchStringChange}
					onRadiusChange={onRadiusChange}
					radius={radius}
				/>
			</div>
			<LeftPanelPlacementList
				onAddressClick={onAddressClick}
				searchAddresses={searchAddresses}
				addedPlacements={addedPlacement}
				onPlacementClick={onPlacementClick}
				placements={placements}
			/>
			<LeftPanelFooter
				isFooterDisabled={isFooterDisabled}
				isPlacementsTheSame={isPlacementsTheSame}
				onAddPlacements={onAddPlacements}
				itemsCount={itemsCount}
			/>
		</div>
	)
}
