import React from 'react'
import { Card, Dropdown, Form } from 'react-bootstrap'
import AdMarketDropdown from './Dropdown'
import bem from '../../utils/bem'
import '../../styles/autocomplete-input.scss'

const cn = bem('autocomplete-input')

const CustomSelect = ({ data, current, onSelect }) => {
	return (
		<AdMarketDropdown
			menu={({ close }) => (
				<Card className={cn('menu')}>
					{data.length ? (
						data.map((option, i) => (
							<Dropdown.Item
								onClick={() => {
									onSelect(option)
									close()
								}}
								key={i}
								className={cn('option')}
								style={
									current.id === option.id
										? {
												backgroundColor: '#e9ecef',
										  }
										: {}
								}
							>
								{option.legal_short_name || option.legal_name}
								<span
									style={{
										display: 'block',
										fontSize: '14px',
									}}
									className={'text-secondary'}
								>
									{`${option.name}, ИНН ${option.inn}, ${option.actual_location}`}
								</span>
							</Dropdown.Item>
						))
					) : (
						<Dropdown.Item>
							Данных по запросу не найдено
						</Dropdown.Item>
					)}
				</Card>
			)}
			trigger={
				<div>
					<Form.Control
						value={current?.legal_short_name || current?.legal_name}
						onChange={(ev) => {
							return
						}}
						size={'lg'}
						onClick={(e) => null}
						type={'text'}
						style={{
							cursor: 'pointer',
							position: 'relative',
							width: '100%',
						}}
					/>
					<div style={{ position: 'absolute', top: 9, right: 15 }}>
						<svg
							width="10"
							height="5"
							viewBox="0 0 10 5"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path d="M10 0H0L5 5L10 0Z" fill="#495057" />
						</svg>
					</div>
				</div>
			}
			zIndex={5}
			offset={0}
			menuWidth={'100%'}
		/>
	)
}
export default CustomSelect
