import React, { FC, useEffect, useMemo, useState } from 'react'
import { Dropdown, Form, InputGroup } from 'react-bootstrap'
import { PlacementShortWithStateAndFocus } from '../BookingAD'
import useDebounce from 'src/utils/useDebounce'
import { useIsMount } from 'src/utils'
import useCampaignData from 'src/stores/campaignData'
import { Region } from 'src/stores/ADMarketTypes.types'

const placementsTypeDefault = 'Все'

interface IPlacementsSearchAndList {
	placementTypes: string[]
	placements: PlacementShortWithStateAndFocus[]
	handleFocusPlacement: (id: number) => void
	mobileIsExpanded?: boolean
	onSearchStringChange: (value: string) => void
	onTypeChange: (placementType: string) => void
	placementType: string
	initialSearchString: string
	onCityClick: (city: Region) => void
	focusedCityId: number
}

const PlacementsSearchAndList: FC<IPlacementsSearchAndList> = ({
	placements,
	handleFocusPlacement,
	mobileIsExpanded = true,
	placementTypes,
	onSearchStringChange,
	onTypeChange,
	placementType,
	initialSearchString,
	onCityClick,
	focusedCityId,
}) => {
	return (
		<div className={'d-flex flex-column h-100'}>
			<SearchByName
				initialSearchString={initialSearchString}
				onSearchChange={onSearchStringChange}
				mobileIsExpanded={mobileIsExpanded}
			/>
			{mobileIsExpanded && (
				<>
					<QuickCitiesFocus
						currentCity={focusedCityId}
						onCityClick={onCityClick}
					/>

					<TypeFilter
						placementType={placementType}
						onTypeChange={onTypeChange}
						placementTypes={placementTypes}
					/>

					<PlacementsList
						list={placements}
						onClick={handleFocusPlacement}
					/>
				</>
			)}
		</div>
	)
}

export default PlacementsSearchAndList

const SearchByName = ({
	onSearchChange,
	mobileIsExpanded,
	initialSearchString,
}: {
	initialSearchString: string
	onSearchChange: (text: string) => void
	mobileIsExpanded: boolean
}) => {
	const [text, setText] = useState(initialSearchString)
	const isFirstRender = useIsMount()
	const debouncedSearch = useDebounce(text, 1000)
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		e.preventDefault()
		setText(e.currentTarget.value)
	}
	useEffect(() => {
		if (!isFirstRender) {
			onSearchChange(debouncedSearch)
		}
	}, [debouncedSearch])
	return (
		<>
			<div className={'w-100'} style={{ padding: '24px 24px 0 24px' }}>
				<InputGroup className={'d-flex mb-0 flex-nowrap'}>
					<InputGroup.Text
						style={{
							backgroundColor: 'transparent',
							borderRight: 'none',
						}}
					>
						<i className="bi bi-search" />
					</InputGroup.Text>
					<Form.Control
						type={'text'}
						value={text}
						onChange={handleChange}
						placeholder={'Поиск по адресу'}
						style={{ borderLeft: 'none' }}
						tabIndex={1}
					/>
				</InputGroup>
			</div>
		</>
	)
}

type QuickCitiesFocusProps = {
	onCityClick: (city: Region) => void
	currentCity: number
}
const QuickCitiesFocus = ({
	currentCity,
	onCityClick,
}: QuickCitiesFocusProps) => {
	const [campaign, _] = useCampaignData()

	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const nextFocusCity = campaign.regions.find(
			(city) => city.id === Number(e.currentTarget.value)
		)
		if (nextFocusCity) {
			onCityClick(nextFocusCity)
		}
	}

	const sortedRegions = useMemo(() => {
		if (!campaign) return []

		return [...campaign.regions].sort((a, b) =>
			a.title.localeCompare(b.title, 'ru')
		)
	}, [campaign])
	return (
		<div
			className={'w-100'}
			style={{
				padding: '16px 24px 0 24px',
			}}
		>
			<Form.Label>Город</Form.Label>
			<Form.Select
				onChange={handleChange}
				value={currentCity}
				placeholder={'Все города'}
			>
				{sortedRegions.map((city) => (
					<option value={city.id} key={city.id}>
						{city.title}
					</option>
				))}
			</Form.Select>
		</div>
	)
}

const TypeFilter = ({
	onTypeChange,
	placementTypes,
	placementType,
}: {
	onTypeChange: (type: string) => void
	placementTypes: string[]
	placementType: string
}) => {
	const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		e.preventDefault()
		onTypeChange(e.currentTarget.value)
	}

	return (
		<>
			<div
				className={'w-100'}
				style={{
					padding: '16px 24px 16px 24px',
					boxShadow: '0px 5px 10px rgba(33, 37, 41, 0.1)',
				}}
			>
				<Form.Label>Тип места</Form.Label>
				<Form.Select
					onChange={handleChange}
					value={placementType}
					placeholder={'Поиск по адресу'}
				>
					<option>{placementsTypeDefault}</option>
					{placementTypes.map((type, key) => (
						<option key={key + type}>{type}</option>
					))}
				</Form.Select>
			</div>
			<Dropdown.Divider className={'p-0 m-0'} />
		</>
	)
}

const PlacementsList = ({
	list,
	onClick,
}: {
	list: PlacementShortWithStateAndFocus[]
	onClick: (id: number) => void
}) => {
	return (
		<ul
			style={{
				flex: '1 1 auto',
				overflowY: 'auto',
				listStyle: 'none',
				padding: '0',
			}}
		>
			{list.map((placement, index) => (
				<React.Fragment key={placement.pk}>
					<li
						style={{ padding: '8px 24px', cursor: 'pointer' }}
						onClick={(e) => {
							e.preventDefault()
							onClick(placement.pk)
						}}
					>
						<p className={'mb-0'}>{placement.name}</p>
						<span className={'text-secondary mt-1'}>
							{placement.placement_type_name}
						</span>
					</li>
					{index !== list.length - 1 && (
						<Dropdown.Divider className={'p-0 m-0'} />
					)}
				</React.Fragment>
			))}
		</ul>
	)
}
