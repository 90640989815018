import React, { useEffect, useState } from 'react'
import ls from '../../style.module.scss'
import { Accordion } from 'react-bootstrap'
import { AdvItemInfo } from '../../common/AdvItemInfo'
import { RenderPreview } from 'src/components/UtilityComponents/ProgressFileInput'
import { AdvItemActions } from '../../common/AdvItemActions'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { ReactComponent as MCR } from 'src/assets/images/railway-2.svg'
import { NewContentCreatedKeysType } from 'src/stores/types/newContendCreatedType'
import { AdvStateType } from '../../common/ModerationModalButton'
import { AdvItemAction, EditVinstantModel } from '../../CampaignDetailedInfo'
import { AdjusmentMessage } from '../../common/AdjusmentMessage'
import { DocsView } from '../../common/DocsView'
import useNotificationData from 'src/stores/notificationsData'
import { EditVinstantButton } from '../../common/EditVinstantButton'

type Props = {
	onEditVinstant: (editModel: EditVinstantModel) => void
	setDetailedItem: React.Dispatch<
		React.SetStateAction<
			| {
					type: NewContentCreatedKeysType
					id: string | number
			  }
			| undefined
		>
	>

	setAdvItemActon: React.Dispatch<
		React.SetStateAction<AdvItemAction | undefined>
	>
	adjusmentMessage: string
}
export const MoscowCentRingAccordionBody = ({
	setDetailedItem,
	setAdvItemActon,
	adjusmentMessage,
	onEditVinstant,
}: Props) => {
	const [moder, moderInterface] = useModerationData()

	const mcr = moder.detailedCampaign.public_transport

	const [, notificationsInterface] = useNotificationData()

	const vinstantEditStatus =
		notificationsInterface.getNewContentCreated('publicTransport')

	const [resolution, setResolution] = useState('')
	const getResolutionAndExtension = async () => {
		const content = mcr.image || mcr.video
		const extension = content?.split('.').pop()

		setResolution(`640x896.${extension}`)
	}
	const handleSetDetailedItem = () => {
		setDetailedItem({ id: mcr.id || 0, type: 'publicTransport' })
	}
	useEffect(() => {
		getResolutionAndExtension()
	}, [])

	const handleSetAdvItemActionType = (
		action: 'moderation' | 'adjustment' | 'reject'
	) => {
		setAdvItemActon({
			action: action,
			type: 'publicTransport',
			rejectMessage: mcr.reject_msg,
			adjustmentMessage: mcr.adjustment_msg,
			isDocRequired: mcr.is_docs_required,
			item: {
				id: mcr.id || 0,
				name: 'Цифровые экраны МЦК',
				placeName: '',
				src: 'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg',
			},
		})
	}

	const handleEditVinstant = (link: string) => {
		onEditVinstant({
			instanceId: mcr.id || 0,
			newContentKey: 'publicTransport',
			vinstantLink: link,
		})
	}
	return (
		<Accordion.Body className={ls.accordionBody}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
					padding: '16px',
				}}
			>
				<div
					style={{
						display: 'flex',
						gap: '16px',
						alignItems: 'center',
					}}
				>
					<MCR /> <h6 style={{ margin: 0 }}>Цифровые экраны МЦК</h6>
				</div>

				<div
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '16px',
					}}
				>
					<AdvItemInfo
						budget={parseFloat(mcr.amount || '0')}
						clicks={mcr.views_count}
						expenses={mcr.expense}
						format={'Изображение'}
						shows={mcr.views_count}
						status={mcr.state}
					/>
				</div>

				{!!adjusmentMessage && (
					<AdjusmentMessage
						isDocRequired={mcr.is_docs_required}
						adjusmentMessage={adjusmentMessage}
					/>
				)}
				{!!mcr.docs.length && (
					<DocsView docs={mcr.docs} id={mcr.id || 0} />
				)}

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '16px',
					}}
				>
					{mcr.image || mcr.video ? (
						<>
							<div
								style={{
									display: 'flex',
									gap: '0.5rem',
									alignItems: 'flex-start',
								}}
							>
								<div className={ls.mediaCreativePreview}>
									<RenderPreview
										style={{
											width: '60px',
											height: '60px',
											borderRadius: '4px',
											minHeight: '60px',
											minWidth: '60px',
										}}
										value={{
											name: '',
											src: mcr.image || mcr.video,
										}}
									/>
									<div>
										<div>
											{mcr.image
												? 'Изображение'
												: 'Видео'}
										</div>
										<span>{resolution}</span>
									</div>
								</div>
								<EditVinstantButton
									editVinstanLink={mcr.edit_vinstant}
									onEditVinstant={handleEditVinstant}
									vinstantEditStatus={vinstantEditStatus}
								/>
							</div>
						</>
					) : (
						<h5 style={{ margin: 0 }}>Нет креатива</h5>
					)}

					<AdvItemActions
						setAdvItemActionType={handleSetAdvItemActionType}
						state={mcr.state as AdvStateType}
						setDetailedItem={handleSetDetailedItem}
					/>
				</div>
			</div>
		</Accordion.Body>
	)
}
