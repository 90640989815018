import React, { useMemo } from 'react'
import { Form } from 'react-bootstrap'
import s from './BookingsPagination.module.scss'
import useProviderData from '../../../../../stores/providerData'
import { useBookingsContext } from '../../context/BookingsProvider'

export const PaginationLimits = [5, 10, 20, 30, 40]

const BookingsPagination: React.FC<{ layoutWidth: number | undefined }> = ({
	layoutWidth,
}) => {
	const { limit, offset, setOffset, setLimit } = useBookingsContext()
	const [provider] = useProviderData()

	const total = useMemo<number>(() => {
		if (!provider.bookings) return 0
		return provider?.bookings?.count
	}, [provider.bookings])

	const pagesTotal = useMemo<number>(
		() => Math.ceil(total / limit),
		[provider.bookings, offset, limit] // eslint-disable-line react-hooks/exhaustive-deps
	)

	const pages = useMemo<number[]>(
		() => new Array(pagesTotal || 1).fill(0),
		[pagesTotal]
	)

	const pageValue = useMemo<number>(() => {
		return offset / limit + 1
	}, [offset, limit])

	const currentItems = useMemo<string>(() => {
		if (offset === 0 && limit !== 1)
			return `1 - ${limit > total ? total : limit} из ${total} позиций`
		if (offset + 1 === total) return `${offset + 1} из ${total} позиций`
		if (limit === 1) return `${offset + 1} из ${total} позиций`
		return `${offset + 1} - ${
			offset + limit < total ? offset + limit : total
		} из ${total} позиций`
	}, [offset, limit, total])
	const prevAvailable = useMemo<boolean>(() => {
		return pageValue <= pagesTotal && pageValue - 1 > 0
	}, [pageValue, pagesTotal])
	const nextAvailable = useMemo<boolean>(() => {
		return pageValue < pagesTotal
	}, [pageValue, pagesTotal])

	const showPageText = useMemo<boolean>(
		() => (layoutWidth === undefined ? false : layoutWidth >= 640),
		[layoutWidth]
	)
	const mobile_version = useMemo<boolean>(
		() => (layoutWidth === undefined ? false : layoutWidth < 518),
		[layoutWidth]
	)

	function onValuesChange(limit: number, offset: number) {
		setOffset(offset)
		setLimit(limit)
	}

	return (
		<div className={s.paginationContainer}>
			{!mobile_version && (
				<>
					<div className={s.group} style={{ paddingLeft: '12px' }}>
						Показывать:
						<Form.Select
							className={s.select}
							value={limit}
							onChange={(ev) =>
								onValuesChange
									? onValuesChange(
											parseInt(ev.target.value),
											0
									  )
									: null
							}
						>
							{PaginationLimits.map((el, index) => (
								<option key={index}>{el}</option>
							))}
						</Form.Select>
					</div>
					<div className={s.separator} />
				</>
			)}

			<div
				className={s.group}
				style={{
					paddingLeft: '12px',
					flex: '1 1 auto',
					justifyContent: 'center',
				}}
			>
				{currentItems}
			</div>
			<div className={s.separator} />
			{!mobile_version && (
				<>
					<div
						className={s.group}
						style={{ paddingRight: showPageText ? '12px' : '0' }}
					>
						<Form.Select
							className={s.select}
							onChange={(ev) =>
								onValuesChange
									? onValuesChange(
											limit,
											limit *
												(parseInt(ev.target.value) - 1)
									  )
									: null
							}
							value={pageValue}
						>
							{pages.map((_, i) => (
								<option key={i}>{i + 1}</option>
							))}
						</Form.Select>
						{showPageText && `из ${pagesTotal} страниц`}
					</div>
					<div className={s.separator} />
				</>
			)}

			<div
				className={
					prevAvailable ? `${s.group} ${s.withhover}` : `${s.group}`
				}
				style={{
					width: '42px',
					cursor: prevAvailable ? 'pointer' : 'default',
					maxWidth: '42px',
				}}
				onClick={() => {
					if (prevAvailable) {
						if (onValuesChange) {
							onValuesChange(limit, offset - limit)
						}
					}
				}}
			>
				<i className={'bi bi-arrow-left'} />
			</div>
			<div className={s.separator} />
			<div
				className={
					nextAvailable ? `${s.group} ${s.withhover}` : `${s.group}`
				}
				style={{
					width: '42px',
					cursor: nextAvailable ? 'pointer' : 'default',
					maxWidth: '42px',
				}}
				onClick={() => {
					if (nextAvailable) {
						if (onValuesChange) {
							onValuesChange(limit, offset + limit)
						}
					}
				}}
			>
				<i className={'bi bi-arrow-right'} />
			</div>
		</div>
	)
}

export default BookingsPagination
