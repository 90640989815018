import React, { useCallback, useEffect, useState } from 'react'
import {
	DistrictBookingMediaFormat,
	useTechnicalRequirementsStore,
} from 'src/stores/technical-requirements-store'
import s from '../components.module.scss'
import useNotificationData from 'src/stores/notificationsData'
import { NewContentCreatedStatusType } from 'src/stores/types/newContendCreatedType'
import { motion } from 'framer-motion'
import { DistrictBookingFormatControls } from './DistrictBookingFormatControls'
import { DistrictBookingFormatView } from './DistrictBookingFormatView'
import { CommonMediaModal } from 'src/components/common/garantid-card/garantid-media-format-card/components/common-media/modals/CommonMediaModal'
import { MediaFormatRequirements } from 'src/components/common/garantid-card/garantid-media-format-card/components/common/MediaFormatRequirements'
import { PlacementsOverview } from '../PlacementsOverview'
import { downloadByRequest } from 'src/utils'
import { useCampaignInfoStore } from 'src/components/_client/campaign-info/store/campaignInfo.store'
import useCampaignData from 'src/stores/campaignData'

type DistrictBookingFormatProps = {
	mediaFormat: DistrictBookingMediaFormat
	districtBookingId: number
}

export type DistrictBookingFormatModal = 'requirements' | 'placements'

const modalTitles: Record<DistrictBookingFormatModal, string> = {
	requirements: 'Требования к загрузке',
	placements: 'Размещения',
}

export const DistrictBookingFormat = ({
	mediaFormat,
	districtBookingId,
}: DistrictBookingFormatProps) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [_, notificationsInterface] = useNotificationData()
	const fetchVinstantCreative = useCampaignInfoStore(
		(state) => state.fetchVinstantCreative
	)
	const fetchTechnicalRequirements = useTechnicalRequirementsStore(
		(state) => state.fetchTechnicalRequirements
	)

	const [modalType, setModalType] =
		useState<DistrictBookingFormatModal | null>(null)

	const screenResolution = `${mediaFormat.media_requirements.max_width} x ${mediaFormat.media_requirements.max_height} px`
	const placementsCount = mediaFormat.placements.length

	const editVinstantContentKey =
		districtBookingId + `${mediaFormat.media?.external_type}`

	const editCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated(editVinstantContentKey) as
			| NewContentCreatedStatusType
			| undefined

	const handleCloseModal = useCallback(() => {
		setModalType(null)
	}, [])

	const handleUploadDistrictBookingMedia = useCallback(() => {
		const url = `district_booking_creative/${mediaFormat.media?.id}/download/`
		downloadByRequest(url, mediaFormat.media?.file_name || '')
	}, [mediaFormat.media])

	const refetchAfterVinstantEnd = async () => {
		await fetchVinstantCreative(campaign.id)
		await fetchTechnicalRequirements(campaign)
		notificationsInterface.setNewContendCreated(
			editVinstantContentKey,
			'pending'
		)
	}
	useEffect(() => {
		if (editCreativeVinstantStatus === 'end') {
			refetchAfterVinstantEnd()
		}
	}, [editCreativeVinstantStatus])

	return (
		<motion.div
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			initial={{ opacity: 0 }}
			className={s.districtBookingFormat}
		>
			<DistrictBookingFormatView
				setModalType={setModalType}
				placementsCount={placementsCount}
				mediaFormatName={mediaFormat.media_type_name}
				media={mediaFormat.media}
				isVinstanLoading={editCreativeVinstantStatus === 'start'}
				screenResolution={screenResolution}
			/>
			<DistrictBookingFormatControls
				onUpload={handleUploadDistrictBookingMedia}
				setModalType={setModalType}
			/>

			<CommonMediaModal
				onClose={handleCloseModal}
				open={!!modalType}
				title={modalTitles[modalType || 'requirements']}
			>
				{modalType === 'requirements' && (
					<MediaFormatRequirements
						mediaRequierments={mediaFormat.media_requirements}
					/>
				)}

				{modalType === 'placements' && (
					<PlacementsOverview placements={mediaFormat.placements} />
				)}
			</CommonMediaModal>
		</motion.div>
	)
}
