import React from 'react'
import { Card } from 'react-bootstrap'
import s from './outdoorPresentationCard.module.scss'
import { OutdoorAdvHeader } from './outdoor-adv-header/OutdoorAdvHeader'
import { OutdoorVariantsSelectors } from './outdoor-variants-selectors/OutdoorVariantsSelectors'
import { OutdoorVariants } from '../../../../../types'
import { CardWrapper } from '../../../../common/card-wrapper/CardWrapper'

type Props = {
	selectedVariants: OutdoorVariants[]
	onSelectorClick: (variant: OutdoorVariants) => void
}
export const OutdoorPresentationCard = ({
	onSelectorClick,
	selectedVariants,
}: Props) => {
	return (
		<CardWrapper>
			<div className={s.outdoorPresentationCardContent}>
				<OutdoorAdvHeader />
				<OutdoorVariantsSelectors
					isLoading={false}
					onSelectorClick={onSelectorClick}
					selectedVariants={selectedVariants}
				/>
			</div>
		</CardWrapper>
	)
}
