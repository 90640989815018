export type PlacementFilterKeyType =
	| 'advertising_company'
	| 'company'
	| 'placement_region'

export type PlacementFilterType = {
	title: string
	key: PlacementFilterKeyType
	filterItems: Array<string>
	searchKey?: string
}
export const placementFilters: Array<PlacementFilterType> = [
	{
		key: 'advertising_company',
		title: 'Кампании',
		filterItems: [],
		searchKey: 'adv_campaigns',
	},
	{
		key: 'company',
		title: 'Заказчики',
		filterItems: [],
		searchKey: 'companies',
	},
	{
		key: 'placement_region',
		title: 'Регионы',
		filterItems: [],
		searchKey: 'regions',
	},
]
