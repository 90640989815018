import moment from 'moment'
import { ModerAdvCompanyItemType } from '../types/ModerationRequestTypes'
import IntlFormatter from 'src/utils/intl-money-formatter'

export const getCampaignInfoMainFields = (
	campaign: ModerAdvCompanyItemType
) => {
	return {
		Заказчик: campaign.company_name,
		Период: `с ${moment(campaign.start_date).format(
			'DD MMMM YYYY'
		)} по ${moment(campaign.end_date).format('DD MMMM YYYY')}`,
		['Региона показа']:
			campaign?.extra_data?.region_titles?.length > 0
				? campaign.extra_data.region_titles?.join(',')
				: 'Не назначено',
		Пол:
			campaign?.extra_data?.gender_titles?.length > 0
				? campaign.extra_data.gender_titles?.join(',')
				: 'Все',
		Возраст: campaign.extra_data?.age_titles?.join(', '),
		Бюджет: IntlFormatter.format(
			campaign?.extra_data?.total_budget,
			undefined,
			2
		),
	}
}
