import React from 'react'
import { Button } from 'react-bootstrap'
import s from '../components.module.scss'
import Tooltip from 'src/components/UtilityComponents/Tooltip'
import buttonStyle from '../../../../garantid-card/garantid-media-format-card/components/common-media/commonMedia.module.scss'
import { ReactComponent as DownloadIcon } from 'src/assets/images/download.svg'
import { DistrictBookingFormatModal } from './DistrictBookingFormat'

type Props = {
	setModalType: (type: DistrictBookingFormatModal) => void
	onUpload: () => void
}
export const DistrictBookingFormatControls = ({
	setModalType,
	onUpload,
}: Props) => {
	return (
		<div className={s.districtBookingFormatControls}>
			<Button
				onClick={() => setModalType('requirements')}
				variant="light"
			>
				Требования к загрузке
			</Button>

			<Tooltip text="Выгрузить материал" placement="left">
				<Button
					className={buttonStyle.commonMediaControls_primary}
					variant={'light'}
					onClick={onUpload}
				>
					<DownloadIcon />
				</Button>
			</Tooltip>
		</div>
	)
}
