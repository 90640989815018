import React from 'react'
import { GarantidCard } from 'src/components/common'
import useCampaignData from 'src/stores/campaignData'
import { AdvTypeSummaryInfo } from '../common/adv-type-summary-info/AdvTypeSummaryInfo'

export const IndoorInfo = (props: any) => {
	const [campaign, campaignInterface] = useCampaignData()

	return (
		<div className="d-flex flex-column gap-4">
			{campaign.indoor_adv_is_selected && <GarantidCard type="indoor" />}
			<AdvTypeSummaryInfo advType="indoor" />
		</div>
	)
}
