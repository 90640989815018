import React from 'react'
import { CardWrapper } from '../../../../common/card-wrapper/CardWrapper'
import { ReactComponent as RecommendIcon } from 'src/assets/images/recommendIcon.svg'
import s from './recPresentation.module.scss'

export const RecommendationPresentation = () => {
	return (
		<CardWrapper>
			<div className={s.recPresentation}>
				<div className={s.recHeader}>
					<h4>Рекомендации к размещению</h4>
					<RecommendIcon />
				</div>

				<div className={s.recDescription}>
					<p>Подобрали на основании заполненных данных</p>
				</div>
			</div>
		</CardWrapper>
	)
}
