import React from 'react'
import { Button } from 'react-bootstrap'
import cn from 'classnames'
import s from './common.module.scss'
import { IconButtonDelete } from 'src/components/UtilityComponents'
import { AnimatePresence, motion } from 'framer-motion'
import useResponsive from 'src/utils/useResponsive'

type Props = {
	isDeleteActive: boolean
	onCreate: () => void
	onDelete: () => void
}
export const NotificationsActionRow = ({
	isDeleteActive,
	onCreate,
	onDelete,
}: Props) => {
	const { isMobile } = useResponsive()
	return (
		<div className={cn(s.notificationActionRow)}>
			<div className={cn(s.notificationActionHeader)}>
				{isMobile && <h5>Уведомления</h5>}

				<Button
					variant="primary"
					onClick={onCreate}
					style={{ width: isMobile ? '100%' : 'auto' }}
				>
					Создать уведомление
				</Button>
			</div>

			<div className={cn(s.notificationDelete)}>
				<AnimatePresence>
					{isDeleteActive && (
						<motion.div
							onClick={onDelete}
							initial={{ opacity: 0, x: '100%' }}
							animate={{ opacity: 1, x: 0 }}
							exit={{ opacity: 0, x: '100%' }}
							transition={{ duration: 0.5 }}
							style={{
								position: 'absolute',
								right: 0,
								pointerEvents: isDeleteActive ? 'auto' : 'none',
								opacity: isDeleteActive ? 1 : 0,
							}}
						>
							<IconButtonDelete onClick={() => {}} />
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	)
}
