import { CampaignInfoTypeBookingsSeparation } from 'src/stores/ADMarketTypes.types'
import {
	AdvSelectorData,
	AdvSelectorState,
	SpecialAdvSelectorData,
} from '../types'
import { defaultAdvSelectors } from '../constants/defaultAdvSelectors'
import { getAdvFormatTotalAmount } from './getAdvFormatTotalAmount'
import { checkIsAdvFormatSelected } from './checkIsAdvFormatSelected'

export const getAdvFormatsSelectorsData = (
	campaign: CampaignInfoTypeBookingsSeparation,
	special?: SpecialAdvSelectorData
): AdvSelectorData[] => {
	const selectors = [...defaultAdvSelectors]

	const selectorsWithData = selectors.map((selector) => ({
		...selector,
		totalAmount: getAdvFormatTotalAmount(selector.key, campaign),
		state: special?.[selector.key]?.defaultDisabled
			? AdvSelectorState.Disabled
			: checkIsAdvFormatSelected(selector.key, campaign)
			? AdvSelectorState.Added
			: AdvSelectorState.Pending,
	}))
	return selectorsWithData
}
