import React from 'react'
import { ReactComponent as OutdoorIcon } from 'src/assets/images/billboard_2.svg'
import s from './oudoorHeader.module.scss'

export const OutdoorAdvHeader = (props: any) => {
	return (
		<div className={s.outdoorHeader}>
			<div className={s.primaryText}>
				<h4>Наружная реклама</h4>
				<div>
					<OutdoorIcon />
				</div>
			</div>
			<p>Выберите способ размещения</p>
		</div>
	)
}
