import React, { useEffect, useMemo } from 'react'
import { useCampaignReportStore } from '../../store'
import {
	getStatAndlogDetailTableData,
	getStatAnLogDetailTableColumns,
} from '../../utils'
import { ReportDetailWithStatLog } from '../../types'
import { StatLogTable } from './stat-log-table/StatLogTable'

type Props = {
	fetchDetails: () => Promise<void>
}

export const InternetDetailsWrapper = ({ fetchDetails }: Props) => {
	const vk = useCampaignReportStore((store) => store.detailedReport.vk)
	const yandex = useCampaignReportStore(
		(store) => store.detailedReport.yandex
	)

	useEffect(() => {
		fetchDetails()
	}, [])
	return <div>{(vk || yandex) && <InternetDetails />}</div>
}

export const InternetDetails = () => {
	const vk = useCampaignReportStore((store) => store.detailedReport.vk)
	const yandex = useCampaignReportStore(
		(store) => store.detailedReport.yandex
	)

	const data = useMemo(() => {
		const usedData = [] as {
			adv: 'yandex' | 'vk'
			statLog: ReportDetailWithStatLog | null
		}[]

		if (yandex) {
			usedData.push({
				adv: 'yandex',
				statLog: yandex,
			})
		}
		if (vk) {
			usedData.push({
				adv: 'vk',
				statLog: vk,
			})
		}
		return getStatAndlogDetailTableData(usedData)
	}, [yandex, vk])

	const columns = useMemo(() => {
		const internetDataForColumns = vk || yandex
		const result = getStatAnLogDetailTableColumns(internetDataForColumns!)

		return result
	}, [yandex, vk])

	return <StatLogTable columns={columns} data={data} />
}
