import React from 'react'
import { ReactComponent as OutdoorIcon } from 'src/assets/images/billboard_2.svg'
import s from './publicTransportHeader.module.scss'

export const PublicTransportAdvHeader = (props: any) => {
	return (
		<div className={s.outdoorHeader}>
			<div className={s.primaryText}>
				<h4>Общественный транспорт</h4>
				<div>
					<OutdoorIcon />
				</div>
			</div>
			<p>
				Реклама на станциях и в поездах Московского центрального кольца
				(МЦК) и Московских Центральных Диаметров (МЦД)
			</p>
		</div>
	)
}
