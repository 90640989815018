import React, { useEffect, useState } from 'react'
import ls from '../../style.module.scss'
import { Accordion } from 'react-bootstrap'
import { AdvItemInfo } from '../../common/AdvItemInfo'
import { RenderPreview } from 'src/components/UtilityComponents/ProgressFileInput'
import { AdvItemActions } from '../../common/AdvItemActions'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { ReactComponent as VK } from '../../../../../../../../../assets/images/vk.svg'
import { InternetInfo } from '../../common/InternetInfo'
import { AdvStateType } from '../../common/ModerationModalButton'
import { CommonAdvAccordionsProps } from '../../CampaignDetailedInfo'
import { AdjusmentMessage } from '../../common/AdjusmentMessage'
import { DocsView } from '../../common/DocsView'
import useNotificationData from 'src/stores/notificationsData'
import { EditVinstantButton } from '../../common/EditVinstantButton'

type Props = CommonAdvAccordionsProps & {
	adjusmentMessage: string
}
export const VkAccordionBody = ({
	setDetailedItem,
	setAdvItemActon,
	adjusmentMessage,
	onEditVinstant,
}: Props) => {
	const [moder, moderInterface] = useModerationData()

	const vk = moder.detailedCampaign.internet_vk

	const [, notificationsInterface] = useNotificationData()

	const vinstantEditStatus = notificationsInterface.getNewContentCreated('vk')

	const [resolution, setResolution] = useState('')
	const getResolutionAndExtension = async () => {
		const extension = vk?.image?.split('.').pop()

		const image = new Image()
		image.src = vk.image || ''
		image.onload = function () {
			setResolution(`${image.width}x${image.height}.${extension}`)
		}
	}
	const handleSetDetailedItem = () => {
		setDetailedItem({ id: vk.id, type: 'vk' })
	}
	useEffect(() => {
		getResolutionAndExtension()
	}, [])

	const handleSetAdvItemActionType = (
		action: 'moderation' | 'adjustment' | 'reject'
	) => {
		setAdvItemActon({
			action: action,
			type: 'vk',
			rejectMessage: vk.reject_msg,
			adjustmentMessage: vk.adjustment_msg,
			isDocRequired: vk.is_docs_required,
			item: {
				id: vk.id,
				name: 'VK-реклама',
				placeName: vk.link || '',
				src: 'https://storage.yandexcloud.net/admarket-images/static-img/internet.png',
			},
		})
	}
	const handleEditVinstant = (link: string) => {
		onEditVinstant({
			instanceId: vk.id,
			newContentKey: 'vk',
			vinstantLink: link,
		})
	}
	return (
		<Accordion.Body className={ls.accordionBody}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
					padding: '16px',
				}}
			>
				<div
					style={{
						display: 'flex',
						gap: '16px',
						alignItems: 'center',
					}}
				>
					<VK /> <h6 style={{ margin: 0 }}>VK</h6>
				</div>

				<div
					style={{
						borderBottom: '1px solid #CED4DA',
						paddingBottom: '16px',
					}}
				>
					<AdvItemInfo
						budget={vk.amount || 0}
						clicks={vk.views_count}
						expenses={vk.expense}
						format={'Изображение'}
						shows={vk.views_count}
						status={vk.state}
					/>
				</div>

				{!!adjusmentMessage && (
					<AdjusmentMessage
						isDocRequired={vk.is_docs_required}
						adjusmentMessage={adjusmentMessage}
					/>
				)}
				{!!vk.docs.length && <DocsView docs={vk.docs} id={vk.id} />}

				<InternetInfo item={vk} type="vk" />

				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '16px',
					}}
				>
					{vk.link ? (
						<div
							style={{
								display: 'flex',
								gap: '0.5rem',
								alignItems: 'flex-start',
							}}
						>
							<div className={ls.mediaCreativePreview}>
								<RenderPreview
									style={{
										width: '60px',
										height: '60px',
										borderRadius: '4px',
										minHeight: '60px',
										minWidth: '60px',
									}}
									value={{
										name: '',
										src: vk.image,
									}}
								/>
								<div>
									<div>{vk.heading}</div>
									<span>{resolution}</span>
								</div>
							</div>
							<EditVinstantButton
								editVinstanLink={vk.edit_vinstant}
								onEditVinstant={handleEditVinstant}
								vinstantEditStatus={vinstantEditStatus}
							/>
						</div>
					) : (
						<h5 style={{ margin: 0 }}>Нет креатива</h5>
					)}

					<AdvItemActions
						setAdvItemActionType={handleSetAdvItemActionType}
						state={vk.state as AdvStateType}
						setDetailedItem={handleSetDetailedItem}
					/>
				</div>
			</div>
		</Accordion.Body>
	)
}
