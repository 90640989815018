import React from 'react'
import {
	CodeConfirmationFormData,
	CodeResendData,
	RegistrationCredentialsFormData,
	RegistrationStagesType,
} from '../../model/types'
import { RegistrationHeader } from './RegistrationHeader'
import { RegistrationStages } from './RegistrationStages'
import { RegistrationFooter } from './RegistrationFooter'
import s from './registration.module.scss'
import { FormikHelpers } from 'formik'
import { PhoneConfirmationVariant } from 'src/stores/userData'

type Props = {
	stage: RegistrationStagesType
	phoneNumber: string
	expirationDateKey: string
	lastConfirmationVariantKey: string

	onEnterCredentials: (
		values: RegistrationCredentialsFormData,
		formikHelpers: FormikHelpers<RegistrationCredentialsFormData>
	) => Promise<void>

	onResendConfirmationCode: (values: CodeResendData) => Promise<void>
	setStage: (stage: RegistrationStagesType) => void
	onConfirmCode: (
		values: CodeConfirmationFormData,
		formikHelpers: FormikHelpers<CodeConfirmationFormData>,
		confirmationVariant: PhoneConfirmationVariant
	) => Promise<any>
	toLogin: () => void
}
export const Registration = ({
	stage,
	lastConfirmationVariantKey,
	toLogin,
	onEnterCredentials,
	phoneNumber,
	expirationDateKey,
	setStage,
	onConfirmCode,
	onResendConfirmationCode,
}: Props) => {
	return (
		<div className={s.registration}>
			<RegistrationHeader />
			<RegistrationStages
				lastConfirmationVariantKey={lastConfirmationVariantKey}
				expirationDateKey={expirationDateKey}
				onConfirmCode={onConfirmCode}
				onResendConfirmationCode={onResendConfirmationCode}
				phoneNumber={phoneNumber}
				stage={stage}
				setStage={setStage}
				onEnterCredentials={onEnterCredentials}
			/>
			<RegistrationFooter toLogin={toLogin} />
		</div>
	)
}
