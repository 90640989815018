import React, { useCallback, useEffect, useMemo, useState } from 'react'
import s from './createReportPage.module.scss'
import { useHistory } from 'react-router-dom'
import useCampaignData from 'src/stores/campaignData'
import bem from 'src/utils/bem'
import { Spinner } from 'react-bootstrap'
import '../../../styles/create-campaign-page.scss'
import moment from 'moment'
import { authFetch } from 'src/utils/authFetch'
import downloadBlob from 'src/utils/downloadBlob'
import useResponsive from 'src/utils/useResponsive'
import cn from 'classnames'
import {
	BackButton,
	BottomButtons,
	ContentHeader,
	CreateReportStepOne,
	CreateReportStepThree,
	CreateReportStepTwo,
} from './components'
import { withPage } from 'src/components/page/Page'
import {
	CampaignInfoType,
	CampaignInfoTypeBookingsSeparation,
} from 'src/stores/ADMarketTypes.types'

export type RelatedKeyType =
	| 'vkinternetadvertising'
	| 'yandexinternetadvertising'
	| 'teletargetadversting'
	| 'adstreamadversting'
	| 'publictransportadvertising'
	| 'indoor'
	| 'outdoor'

export type PlacementsType<T> = {
	id: T
	name?: string
	placement_type: { name: string }
}
export type CampaignListItem = {
	id: string
	name: string
	district_adv_is_selected: boolean
	outdoor_adv_is_selected: boolean
	indoor_adv_is_selected: boolean
	extra_data: {
		tv_is_selected: boolean
		inet_is_selected: boolean
		transport_is_selected: boolean
	}
}
export type PlacementsWithSelectType<T> = PlacementsType<T> & {
	isSelected: boolean
}
const CreateReportPage = () => {
	const [campaign, campaignInterface] = useCampaignData()

	//request data
	const [indoorPlacements, setIndoorPlacements] = useState<
		PlacementsWithSelectType<number>[]
	>([])
	const [outdoorPlacements, setOutdoorPlacements] = useState<
		PlacementsWithSelectType<number>[]
	>([])

	const [campaignList, setCampaignList] = useState<CampaignListItem[]>([])

	const [campaignIds, setCampaingIds] = useState<string[]>([])
	const [startDate, setStartDate] = useState('')
	const [endDate, setEndDate] = useState('')

	const [contentFormats, setContentFormats] = useState<
		Record<'video' | 'banner', boolean>
	>({ video: true, banner: true })
	const [relatedKeys, setRelatedKeys] = useState<
		Record<RelatedKeyType, boolean>
	>({
		adstreamadversting: false,
		teletargetadversting: false,
		vkinternetadvertising: false,
		yandexinternetadvertising: false,
		publictransportadvertising: false,
		indoor: false,
		outdoor: false,
	})

	const [currentStep, setCurrentStep] = useState(0)

	const [isLoading, setIsLoading] = useState(false)

	const { isMobile } = useResponsive()

	const history = useHistory()

	const STEPS = useMemo(
		() => [
			{
				label: 'Рекламные компании',
			},
			{
				label: 'Период размещения',
			},
			{
				label: 'Тип размещения',
			},
		],
		[campaignIds]
	)

	const handleStartDateChange = useCallback((startDate) => {
		setStartDate(startDate)
	}, [])

	const handleEndDateChange = useCallback((endDate) => {
		setEndDate(endDate)
	}, [])

	const handleBackClick = () => {
		history.push('/campaigns/')
	}

	const goToNextStep = () => {
		if (currentStep < STEPS.length - 1) {
			setCurrentStep((prevStep) => prevStep + 1)
		}
	}

	const goToPreviousStep = () => {
		if (currentStep > 0) {
			setCurrentStep((prevStep) => prevStep - 1)
		}
	}

	const availableStep = campaignIds.length !== 0 ? 3 : 0

	const isOneStepSelect = campaignIds.length === 0

	const download = async () => {
		setIsLoading(true)

		const requestReleatedKeys = [] as string[]
		for (const key in relatedKeys) {
			if (relatedKeys[key]) {
				if (key === 'indoor') {
					if (!requestReleatedKeys.includes('booking')) {
						requestReleatedKeys.push('booking')
					}
				} else if (key === 'outdoor') {
					if (!requestReleatedKeys.includes('booking')) {
						requestReleatedKeys.push('booking')
					}
					requestReleatedKeys.push('districtbooking')
				} else {
					requestReleatedKeys.push(key)
				}
			}
		}

		const placementsIds = [] as number[]

		if (relatedKeys['indoor']) {
			placementsIds.push(
				...indoorPlacements.filter((p) => p.isSelected).map((p) => p.id)
			)
		}
		if (relatedKeys['outdoor']) {
			placementsIds.push(
				...outdoorPlacements
					.filter((p) => p.isSelected)
					.map((p) => p.id)
			)
		}
		const body = {
			id: campaignIds,
			start_date: startDate,
			end_date: endDate,
			media_formats: Object.keys(contentFormats).filter(
				(k) => contentFormats[k]
			),
			releated_key: requestReleatedKeys,
			placement_id: placementsIds,
		}
		const answ: Response = await authFetch({
			url: '/core/api/v1/adv_companies/stats/export/',
			method: 'POST',
			raw: true,
			body,
		})

		const currentDate = new Date()
		const currentDay = new Date(
			currentDate.getFullYear(),
			currentDate.getMonth(),
			currentDate.getDate()
		)

		if (answ.ok) {
			const blob = await answ.blob()

			setTimeout(() => {
				setIsLoading(false)
				handleBackClick()
			}, 3000)
			downloadBlob(blob, `Статистика-${currentDay}`, 'xlsx')
		} else {
			throw new Error('Ошибка при загрузке')
		}
	}

	const getContent = () => {
		switch (currentStep) {
			case 0:
				return (
					<CreateReportStepOne
						campaignIds={campaignIds}
						setCampaignIds={setCampaingIds}
						campaignList={campaignList}
					/>
				)
			case 1:
				return (
					<CreateReportStepTwo
						startDate={startDate}
						handleStartDateChange={handleStartDateChange}
						endDate={endDate}
						handleEndDateChange={handleEndDateChange}
					/>
				)
			case 2:
				return (
					<CreateReportStepThree
						contentFormats={contentFormats}
						setContentFormats={setContentFormats}
						setRelatedKeysChange={setRelatedKeys}
						outdoorPlacements={outdoorPlacements}
						relatedKeys={relatedKeys}
						campaignIds={campaignIds}
						indoorPlacements={indoorPlacements}
						setIndoorPlacements={setIndoorPlacements}
						setOutdoorPlacements={setOutdoorPlacements}
						campaignsList={campaignList}
					/>
				)
			default:
				return <></>
		}
	}

	const downloadDisabled: boolean = useMemo(() => {
		const isFormatSelected = contentFormats.banner || contentFormats.video
		if (!isFormatSelected) return true

		const selectedRelatedKeysCount = Object.keys(relatedKeys).some(
			(el) => relatedKeys[el]
		)
		const isIndoorPlacementsSelected = indoorPlacements.some(
			(p) => p.isSelected
		)
		const isOutdoorPlacementsSelected = outdoorPlacements.some(
			(p) => p.isSelected
		)
		if (relatedKeys.indoor && !isIndoorPlacementsSelected) {
			return true
		}
		if (relatedKeys.outdoor && !isOutdoorPlacementsSelected) {
			return true
		}

		const disabled = !selectedRelatedKeysCount

		return disabled
	}, [contentFormats, relatedKeys, indoorPlacements, outdoorPlacements])

	const fetchFormingDocsCampaigns = async () => {
		const url = `/core/api/v1/adv_companies/?state=active&state=completed`

		const campaignList = await authFetch<CampaignListItem[]>({
			url: url,
			method: 'GET',
		})
		setCampaignList(campaignList)
	}

	useEffect(() => {
		fetchFormingDocsCampaigns()
	}, [])
	return (
		<div className={cn(s.backButton, { [s.backButton_mobile]: isMobile })}>
			{!isMobile && (
				<div
					id={'back-button-wrapper'}
					style={{
						minWidth: '165px',
					}}
				>
					<BackButton onClick={handleBackClick} />
				</div>
			)}

			<div
				id={'main-content-wrapper'}
				className={cn(s.mainContentWrapper)}
			>
				<div id={'main-content'}>
					<ContentHeader onClick={handleBackClick} />

					<div
						id={'report-content-with-navigation'}
						className={cn(s.reportContent, {
							[s.reportContent_mobile]: isMobile,
						})}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
							}}
						>
							<div
								id={'step-content-wrapper'}
								style={{ padding: isMobile ? '0 8px' : '0' }}
							>
								{getContent()}
							</div>

							<BottomButtons
								downloadDisabled={downloadDisabled}
								currentStep={currentStep}
								download={download}
								goToNextStep={goToNextStep}
								goToPreviousStep={goToPreviousStep}
								isOneStepSelect={isOneStepSelect}
							/>
						</div>

						<div
							style={{
								width: isMobile ? '100%' : 'auto',
								margin: isMobile ? '16px 8px 0 0' : '0',
							}}
						>
							<Stepper
								step={currentStep}
								avaliableStep={availableStep}
								onStepClick={setCurrentStep}
								STEPS={STEPS}
							/>
						</div>
					</div>

					{isLoading && (
						<div className={s.loader}>
							<Spinner animation="border" variant="primary" />
						</div>
					)}
				</div>
			</div>
		</div>
	)
}

function Stepper({ STEPS, step, avaliableStep, onStepClick }) {
	const cn = bem('create-campaign-page')
	const { isMobile } = useResponsive()
	return (
		<ol className={cn('stepper')} id={'create-campaign-stepper'}>
			<div className={cn('stepper-block')}>
				{STEPS.filter((s, i) => i <= step).map(({ label }, i) => {
					return (
						<li
							key={label}
							className={cn('step', {
								active: step === i,
								completed: step > i,
								avaliable: i <= avaliableStep,
							})}
							style={{ margin: isMobile ? '0px' : '16px' }}
							onClick={
								i <= avaliableStep
									? () => onStepClick(i)
									: () => {}
							}
						>
							<div className={cn('step-num')}>
								{step > i ? (
									<i className="bi bi-check2" />
								) : (
									i + 1
								)}
							</div>
							<div className={cn('step-label')}>
								{!isMobile ? label : ''}
							</div>
						</li>
					)
				})}
			</div>
			<div className={cn('stepper-block')}>
				{STEPS.filter((s, i) => i > step).map(({ label }, i) => (
					<li
						key={label}
						className={cn('step', {
							avaliable: i + step + 1 <= avaliableStep,
						})}
						onClick={
							i + step + 1 <= avaliableStep
								? () => onStepClick(i + step + 1)
								: () => {}
						}
						style={{ margin: isMobile ? '0px' : '16px' }}
					>
						<div className={cn('step-num')}>{i + 2 + step}</div>
						<div className={cn('step-label')}>{label}</div>
					</li>
				))}
			</div>
		</ol>
	)
}

const getStartString = (start) => {
	const s = moment(start, 'YYYY-MM-DD')
	return s.format('DD.MM.YY')
}
const getAndString = (end) => {
	const e = moment(end, 'YYYY-MM-DD')
	return e.format('DD.MM.YY')
}
export default withPage({ noSidebar: false, isProvider: false })(
	CreateReportPage
)
