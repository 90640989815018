import React, { FC } from 'react'
import useCampaignData from '../../../../../stores/campaignData'
import s from './internet-previews.module.scss'
import VkButtonTextMap from '../../../create-campaign/Step3-Upload/Internet/VkButtonText.data'

interface IVkPreview {
	children?: React.ReactNode | React.ReactNode[]
}

const VkPreview: FC<IVkPreview> = ({}) => {
	const [{ internet_vk: values }] = useCampaignData()
	return (
		<VkAdPreview
			link={values.link || ''}
			text={values.text || ''}
			heading={values.heading || ''}
			image={values.image || ''}
			button_text={values.button_text || ''}
		/>
	)
}

type ValuesForPreview = {
	link: string
	image: string
	heading: string
	text: string
	button_text: string
}

const VkAdPreview: FC<ValuesForPreview> = (props) => {
	return (
		<div className={s.vk_container}>
			<h5>{props.heading}</h5>
			<span className={'text-secondary'}>Реклама • 0+</span>
			<p>{props.text}</p>
			<img src={props.image} alt={props.heading} />
			<div className={'w-100 d-flex justify-content-end'}>
				<button className={s.vkBtn}>
					{VkButtonTextMap.get(props.button_text)}
				</button>
			</div>
		</div>
	)
}

export default VkPreview
