import React from 'react'
import { useState } from 'react'
import MobileModal from 'src/components/UtilityComponents/MobileModal'
import { BookingFiltersList } from 'src/components/_provider/Bookings/filters/booking-filters-mobile/BookingFiltersList'
import {
	BookingsSettings,
	MobileFilters,
	MobileFiltersKeyType,
} from 'src/components/_provider/Bookings/filters/booking-filters-mobile/BookingsSettings'
import { ShowHideArrowButton } from 'src/components/_provider/Bookings/filters/booking-filters-mobile/ShowHideArrowButton'
import { ProviderMobileFiltersList } from './ProviderMobileFiltersList'
import { BookingFiltersContent } from 'src/components/_provider/Bookings/filters/components/filter-content/BookingFiltersContent'
import { ProviderMobileFilterContent } from './ProviderMobileFilterContent'
import { PlacementFilterType } from '../../constants'
import { PlacementFilterKeyType } from '../../constants/placementFilters'

type ProviderMobileFiltersProps = {
	filters: PlacementFilterType[]
	setFilter: (key: PlacementFilterKeyType, value: Array<string>) => void
}

export const ProviderMobileFilters = ({
	filters,
	setFilter,
}: ProviderMobileFiltersProps) => {
	const [open, setOpen] = useState(false)

	const [modalOpen, setModalOpen] = useState(false)
	const [filterKey, setFilterKey] = useState<
		PlacementFilterKeyType | undefined
	>(undefined)

	const handleFilterKeyChange = (key: PlacementFilterKeyType) => {
		setFilterKey(key)
		setModalOpen(true)
	}
	const handleModalClose = () => {
		setFilterKey(undefined)
		setModalOpen(false)
	}
	return (
		<div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					borderBottom: '1px solid #CED4DA',
				}}
			>
				{open && (
					<ProviderMobileFiltersList
						filters={filters}
						setCurrentFilter={handleFilterKeyChange}
					/>
				)}
				<ShowHideArrowButton
					closeTitle="Свернуть"
					open={open}
					setOpen={setOpen}
					openTitle="Настройка фильтров"
				/>
			</div>

			<MobileModal
				enableTouching={false}
				defaultTouchingSetup={false}
				show={modalOpen}
				onHide={handleModalClose}
				title={
					filterKey === 'advertising_company'
						? 'Кампании'
						: 'Заказчики'
				}
				styles={{
					padding: 0,
					maxHeight: '100vh',
					height: '100vh',
					display: 'flex',
					flexDirection: 'column',
				}}
				paddingContent="0"
				contentStyles={{
					overflow: 'hidden',
					height: '100%',
					flex: '1 1 auto',
				}}
			>
				<ProviderMobileFilterContent
					setFilter={setFilter}
					filterKey={filterKey}
					onClose={handleModalClose}
				/>
			</MobileModal>
		</div>
	)
}
