import React, { useMemo } from 'react'
import { Card, Spinner } from 'react-bootstrap'
import s from './garantidMediaCard.module.scss'
import { useTechnicalRequirementsStore } from 'src/stores/technical-requirements-store'
import { ReactComponent as TargetIcon } from 'src/assets/images/location-3.svg'
import { ReactComponent as IndoorIcon } from 'src/assets/images/indoor.svg'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { GarantidMediaFormatCard } from './garantid-media-format-card/GarantidMediaFormatCard'

type Props = {
	type: 'outdoor' | 'indoor'
}

export const GarantidCard = ({ type }: Props) => {
	const mediaFormats = useTechnicalRequirementsStore((state) => state[type])

	const totalAdvBudget = useMemo(() => {
		return mediaFormats.reduce(
			(acc, format) => acc + parseFloat(format.total_format_budget),
			0
		)
	}, [mediaFormats])

	return (
		<Card className={s.garantidCard}>
			<GarantidMediaCardHeader type={type} budget={totalAdvBudget} />
			<Card.Body className={s.cardsContainer}>
				{mediaFormats.map((mediaFormat) => (
					<GarantidMediaFormatCard
						key={mediaFormat.id}
						mediaFormat={mediaFormat}
					/>
				))}
			</Card.Body>
		</Card>
	)
}

type GarantidMediaCardHeaderProps = {
	type: 'outdoor' | 'indoor'
	budget: number
}
export const GarantidMediaCardHeader = ({
	type,
	budget,
}: GarantidMediaCardHeaderProps) => {
	const Icon = type === 'outdoor' ? TargetIcon : IndoorIcon
	const headerText =
		type === 'outdoor' ? 'Гарантированные показы' : 'Внутренняя реклама'
	const budgetValue = IntlFormatter.format(budget)

	const isLoading = useTechnicalRequirementsStore((state) => state.isLoading)

	return (
		<Card.Header className={s.garantidMediaCardHeader}>
			<div className={s.garantidMediaCardHeader_title}>
				{isLoading ? (
					<Spinner
						animation="border"
						size="sm"
						style={{ color: '#007BFF' }}
					/>
				) : (
					<Icon />
				)}

				<h5>{headerText}</h5>
			</div>

			<h5>{budgetValue}</h5>
		</Card.Header>
	)
}
