import React, { FC, useState } from 'react'
import { ReusableModalProps } from '../../../../UtilityComponents/ReusableModals/ReusableModalsProvider'
import CreateCreativeForm, { CreateCreativeFields } from './CreateCreativeForm'
import { Modal } from 'react-bootstrap'
import { useConfirm } from '../../../../UtilityComponents/ConfirmModalProvider'

const CreateCreativeDesktopModal: FC<ReusableModalProps<CreateCreativeFields>> =
	({ show, onHide, onDone }) => {
		const { confirm } = useConfirm()
		const [isBG, setIsBG] = useState(false)
		const onSubmit = (values: CreateCreativeFields) => {
			onDone(values)
		}
		const onCancel = async () => {
			setIsBG(true)
			confirm({
				title: 'Вы уверены, что хотите отменить создание материала?',
				text: 'После этого действия все заполненные поля сбросятся',
				closeButton: false,
				declineText: 'Нет, отменить',
				acceptText: 'Да, уверен',
				acceptVariant: 'danger',
				isStatic: true,
			})
				.then((answer) => {
					answer && onHide()
				})
				.finally(() => {
					setIsBG((p) => !p)
				})
		}
		return (
			<Modal
				show={show}
				onHide={onCancel}
				centered
				backdrop="static"
				keyboard={false}
			>
				{isBG && (
					<div
						className={
							'position-absolute w-100 h-100 top-0 start-0'
						}
						style={{
							backgroundColor: 'rgba(0,0,0,.5)',
							zIndex: 10000,
						}}
					></div>
				)}
				<Modal.Header closeButton>
					<Modal.Title as={'h5'}>Создание материала</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: '24px' }}>
					<CreateCreativeForm onSubmit={onSubmit} onHide={onCancel} />
				</Modal.Body>
			</Modal>
		)
	}

export default CreateCreativeDesktopModal
