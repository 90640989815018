type advItemStatus =
	| 'draft'
	| 'inner_moderation'
	| 'moderation'
	| 'adjustment'
	| 'confirm'
	| 'completed'
	| 'reject'
	| 'canceled'

export type AdvItemStatusColorsType = {
	[key in advItemStatus]: {
		color: string
		title: string
	}
}
export const advItemStatus: AdvItemStatusColorsType = {
	moderation: { color: '#007BFF', title: 'На модерации' },
	confirm: { color: '#28A745', title: 'Подтверждена' },
	completed: { color: '#6C757D', title: 'Завершена' },
	adjustment: { color: '#007BFF', title: 'На исправлении' },
	canceled: { color: '#007BFF', title: 'Отменена' },
	draft: { color: '#007BFF', title: 'Черновик' },
	inner_moderation: { color: '#007BFF', title: 'Внутренняя модерация' },
	inner_confirm: {
		color: '#007BFF',
		title: 'Внутренняя модерация завершена',
	},
	reject: { color: '#007BFF', title: 'Отклонена' },
} as AdvItemStatusColorsType
