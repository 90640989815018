import React from 'react'
import s from './tvPresentationCard.module.scss'
import { TVVariants } from '../../../../../types'
import { TvAdvHeader } from './tv-adv-header/TvAdvHeader'
import { TvVariantsSelectors } from './tv-variants-selectors/TvVariantsSelectors'
import { CardWrapper } from '../../../../common/card-wrapper/CardWrapper'

type Props = {
	selectedVariants: TVVariants[]
	onSelectorClick: (variant: TVVariants) => void
}
export const TvPresentationCard = ({
	onSelectorClick,
	selectedVariants,
}: Props) => {
	return (
		<CardWrapper>
			<div className={s.outdoorPresentationCardContent}>
				<TvAdvHeader />
				<TvVariantsSelectors
					isLoading={false}
					onSelectorClick={onSelectorClick}
					selectedVariants={selectedVariants}
				/>
			</div>
		</CardWrapper>
	)
}
