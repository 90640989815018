import React from 'react'
import { Badge } from 'react-bootstrap'
import IntlFormatter from 'src/utils/intl-money-formatter'
import s from './PlacementCard.module.scss'
import ImageFullScreenWrapper from '../../../../UtilityComponents/ImageFullScreenWrapper'
import '../../../../../styles/placement-card.scss'
import {
	DisplayPlacementPriceString,
	DisplayInnerPlacementPriceString,
} from './PlacementPicker/placement-details/PlacementDetails'
import cn from 'classnames'
import declOfNum from 'src/utils/declOfNum'
import { formattedNumber } from 'src/utils'

export default function PlacementCard({
	data,
	onDelete = null,
	isMobile = false,
}: any) {
	if (isMobile)
		return (
			<div
				className={s.placementCard}
				style={{ opacity: data?.is_active ? 1 : 0.5 }}
			>
				<div className={s.container}>
					<ImageFullScreenWrapper>
						<img
							src={data?.placement?.image}
							className="rounded"
							style={{
								minWidth: 72,
								minHeight: 72,
								maxWidth: 72,
								maxHeight: 72,
								content: '',
							}}
							alt={''}
						/>
					</ImageFullScreenWrapper>
					<div className={s.title}>
						<div>{data.placement.name}</div>
						<span className={'text-secondary'}>
							{data.placement.description}
						</span>
					</div>
					{!data?.is_active && (
						<Badge
							bg={'secondary'}
							style={{
								height: 'fit-content',
								fontWeight: 400,
								marginRight: '8px',
							}}
						>
							Неактивен
						</Badge>
					)}
					<div
						className={s.trash}
						style={{ cursor: 'pointer' }}
						onClick={() => onDelete(data.id)}
					>
						<i className="bi bi-trash" />
					</div>
				</div>
				<div>
					<div className="text-secondary mb-2 mt-1">
						{data.placement.only_work_days
							? 'Только рабочие дни'
							: 'Ежедневно'}
						: {data.placement_showing_count.ac_days_count}
					</div>
					<div className={'text-secondary'}>
						{data.placement_showing_count.ac_days_count} x{' '}
						{data.placement.door_type === 'outdoor' &&
							DisplayPlacementPriceString(
								data?.placement_showing_count
									?.showing_count_per_day,
								data?.placement_showing_count?.price
							)}
						{data.placement.door_type === 'indoor' &&
							DisplayInnerPlacementPriceString(
								data?.placement.screen_count,
								data?.placement_showing_count?.price
							)}
					</div>
				</div>
			</div>
		)
	return (
		<div style={{ opacity: data?.is_active ? 1 : 0.5 }}>
			<div className={cn(s.item, 'd-flex flex-column position-relative')}>
				<div className={cn(s.top, 'd-flex')}>
					<ImageFullScreenWrapper>
						<img
							src={
								data.placement.image ||
								'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
							}
							width={80}
							height={80}
							className="me-3 rounded-2"
							alt={'card'}
						/>
					</ImageFullScreenWrapper>
					<div
						className={cn(
							s.title,
							'd-flex flex-column flex-grow-1'
						)}
					>
						<div style={{ paddingRight: '15px' }}>
							{data.placement.name}
						</div>
						<div className={s.desc}>
							{data.placement.placement_type?.name}
						</div>
					</div>
					<div className={s.rightContent}>
						{!data?.is_active && (
							<Badge
								bg={'secondary'}
								style={{
									height: 'fit-content',
									position: 'absolute',
									top: 0,
									right: 0,
									fontWeight: 400,
								}}
							>
								Неактивен
							</Badge>
						)}
						{!!onDelete && (
							<div
								className={s.delete}
								onClick={() => onDelete(data.id)}
							>
								<i className="bi bi-trash" />
							</div>
						)}
					</div>
				</div>
				<div className="d-flex flex-column justify-content-between w-100">
					<div className={cn(s.cells, 'd-flex flex-grow-1')}>
						<div
							className={cn(
								s.cell,
								'd-flex flex-column flex-grow-1'
							)}
						>
							<div className={s.cellTitle}>Рекламу увидят:</div>
							<div className="">
								~{' '}
								{formattedNumber(
									data.placement_showing_count
										.showing_count_per_day
								)}{' '}
								чел/день
							</div>
							<div className={s.cellBorder}></div>
						</div>
						<div
							className={cn(
								s.cell,
								s.cellMargin,
								'd-flex flex-column flex-grow-1'
							)}
						>
							<div className={s.cellTitle}>
								Количество дней показа:
							</div>
							<div>
								{data.placement_showing_count.ac_days_count}{' '}
								{declOfNum(
									data.placement_showing_count.ac_days_count,
									['день', 'дня', 'дней']
								)}
							</div>
						</div>
					</div>
					<div
						className={cn(
							'd-flex justify-content-between',
							s.bottom
						)}
					>
						<div>Прогнозируемая цена:</div>
						<div>
							{IntlFormatter.format(
								data.placement_showing_count.ac_price
							)}
						</div>
					</div>
					{/* <div>
						{data.placement_showing_count.ac_days_count} x{' '}
						{data.placement.door_type === 'outdoor' &&
							DisplayPlacementPriceString(
								data?.placement_showing_count
									?.showing_count_per_day,
								data?.placement_showing_count?.price
							)}
						{data.placement.door_type === 'indoor' &&
							DisplayInnerPlacementPriceString(
								data?.placement.screen_count,
								data?.placement_showing_count?.price
							)}
					</div>
					<div>{IntlFormatter.format(data?.booking_budget)}</div> */}
				</div>
			</div>
		</div>
	)
}
