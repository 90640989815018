import React, { useEffect, useState } from 'react'
import useResponsive from 'src/utils/useResponsive'
import { MobileIssues } from '../../mobile-issues/MobileIssues'
import { IssuesTable } from '../../issues-table/IssuesTable'
import { moderationApi } from 'src/components/_moderator/api/moderatorApi'
import { DisplayIssuesCampaign } from 'src/components/_moderator/types/ModerationRequestTypes'
import { NotificationModal } from '../../notification-modal/NotificationModal'

export type AdvType =
	| 'outdoor-garant'
	| 'outdoor-mass'
	| 'indoor'
	| 'internet'
	| 'tv'
	| 'public_transport'

export type NotifyItemType =
	| 'indoor'
	| 'vk'
	| 'yandex'
	| 'adstream'
	| 'outdoor'
	| 'districtbooking'
	| 'public_transport'

export type NotifyItem = {
	type: NotifyItemType
	id: number
	name: string
	placeName?: string
}

export type NotificationModel = {
	companyName: string
	campaignId: string
} & NotifyItem

type IssuesContentProps = {
	displayIssues: DisplayIssuesCampaign[]
	isLoading: boolean
}
export const IssuesContent = ({
	displayIssues,
	isLoading,
}: IssuesContentProps) => {
	const { isMobile, isTablet } = useResponsive()

	const [notifyItem, setNotifyItem] = useState<
		NotificationModel | undefined
	>()

	const getChannels = async () => {
		const result = await moderationApi.getAdstreamChannels()
		setChannels(result)
	}

	const [channels, setChannels] = useState<
		Array<{ id: number; name: string }>
	>([])
	useEffect(() => {
		getChannels()
	}, [])
	return (
		<>
			{isMobile ? (
				<MobileIssues
					displayIssues={displayIssues}
					channels={channels}
					setNotifyItem={setNotifyItem}
				/>
			) : (
				<IssuesTable
					setNotifyItem={setNotifyItem}
					channels={channels}
					isLoading={isLoading}
					displayIssues={displayIssues}
				/>
			)}
			{notifyItem && (
				<NotificationModal
					onClose={() => setNotifyItem(undefined)}
					open={!!notifyItem}
					notificationModel={notifyItem}
				/>
			)}
		</>
	)
}
