import { useLayoutEffect, useState } from 'react'

const useIsSideBarOpen = () => {
	const [open, setOpen] = useState<boolean>(() => {
		const p: HTMLDivElement = document.querySelector(
			'.page'
		) as HTMLDivElement
		return p.classList.contains('page_sideBarOpen')
	})

	useLayoutEffect(() => {
		const p: HTMLDivElement = document.querySelector(
			'.page'
		) as HTMLDivElement
		const attrObserver = new MutationObserver((mutations) => {
			mutations.forEach((mu) => {
				if (mu.type !== 'attributes' && mu.attributeName !== 'class')
					return
				setOpen(p.classList.contains('page_sideBarOpen'))
			})
		})
		attrObserver.observe(p, { attributes: true })

		return () => {
			attrObserver.disconnect()
		}
	})
	return open
}

export default useIsSideBarOpen
