import React from 'react'
export const PaymentStepsData = {
	METHODS: [
		{
			title: 'Для физ.лиц',
			description: 'VISA, МИР или MasterCard. Комиссия не взимается.',
			icon: (
				<svg
					width="20"
					height="16"
					viewBox="0 0 20 16"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M4 10H8.5V8H4V10Z" fill="#007BFF" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M3 0C1.34315 0 0 1.34315 0 3V13C0 14.6569 1.34315 16 3 16H17C18.6569 16 20 14.6569 20 13V3C20 1.34315 18.6569 0 17 0H3ZM2 3C2 2.44772 2.44772 2 3 2H17C17.5523 2 18 2.44772 18 3V4L2 4L2 3ZM2 6L18 6V13C18 13.5523 17.5523 14 17 14H3C2.44771 14 2 13.5523 2 13V6Z"
						fill="currentColor"
					/>
				</svg>
			),
			key: 'PAYMENT',
		},
		{
			title: 'Для юр.лиц',
			description:
				'Для оплаты необходимо скачать и оплатить квитанцию. Комиссию и срок зачисления платежа можно узнать в вашем банке.',
			icon: (
				<svg
					width="18"
					height="20"
					viewBox="0 0 18 20"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M6 0H17C17.5523 0 18 0.447715 18 1V16H16V2H6V0Z"
						fill="currentColor"
					/>
					<path
						d="M2 4H13C13.5523 4 14 4.44772 14 5V19C14 19.5523 13.5523 20 13 20H10V18H12V6H2V4Z"
						fill="currentColor"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M4 20V18H8V16H4V14H7C7.39397 14 7.78407 13.9224 8.14805 13.7716C8.51203 13.6209 8.84274 13.3999 9.12132 13.1213C9.3999 12.8427 9.62087 12.512 9.77164 12.1481C9.9224 11.7841 10 11.394 10 11C10 10.606 9.9224 10.2159 9.77164 9.85195C9.62087 9.48797 9.3999 9.15726 9.12132 8.87868C8.84274 8.6001 8.51203 8.37913 8.14805 8.22836C7.78407 8.0776 7.39397 8 7 8H2V12H0V14H2V16H0V18H2V20H4ZM7 12C7.13132 12 7.26136 11.9741 7.38268 11.9239C7.50401 11.8736 7.61425 11.8 7.70711 11.7071C7.79997 11.6142 7.87362 11.504 7.92388 11.3827C7.97413 11.2614 8 11.1313 8 11C8 10.8687 7.97413 10.7386 7.92388 10.6173C7.87362 10.496 7.79997 10.3858 7.70711 10.2929C7.61425 10.2 7.50401 10.1264 7.38268 10.0761C7.26136 10.0259 7.13132 10 7 10H4V12H7Z"
						fill="currentColor"
					/>
				</svg>
			),
			key: 'INVOICE',
		},
	],
	CLOSED_DOCUMENTS: [
		{
			title: 'Через ЭДО',
			description: 'Диадок, Тензор и другие сервисы',
			icon: (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M4 3C3.44772 3 3 3.44772 3 4V5.52786C3 5.9936 3.10844 6.45294 3.31672 6.8695L3.65836 7.55279C3.79912 7.83431 3.79912 8.16569 3.65836 8.44721L3.55279 8.65836C3.1305 9.50294 3.1305 10.4971 3.55279 11.3416L3.65836 11.5528C3.79912 11.8343 3.79912 12.1657 3.65836 12.4472L3.55279 12.6584C3.1305 13.5029 3.1305 14.4971 3.55279 15.3416L3.65836 15.5528C3.79912 15.8343 3.79912 16.1657 3.65836 16.4472L3.31672 17.1305C3.10844 17.5471 3 18.0064 3 18.4721V20C3 20.5523 3.44772 21 4 21H12V19H5V18.4721C5 18.3169 5.03614 18.1638 5.10557 18.0249L5.44721 17.3416C5.8695 16.4971 5.8695 15.5029 5.44721 14.6584L5.34164 14.4472C5.20088 14.1657 5.20088 13.8343 5.34164 13.5528L5.44721 13.3416C5.8695 12.4971 5.8695 11.5029 5.44721 10.6584L5.34164 10.4472C5.20088 10.1657 5.20088 9.83431 5.34164 9.55279L5.44721 9.34164C5.8695 8.49706 5.8695 7.50294 5.44721 6.65836L5.10557 5.97508C5.03614 5.83622 5 5.68311 5 5.52786V5H19V11.5H21V4C21 3.44772 20.5523 3 20 3H4Z"
						fill="currentColor"
					/>
					<path d="M8 9H17V7H8V9Z" fill="currentColor" />
					<path d="M14 13H8V11H14V13Z" fill="currentColor" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M18.5039 13.1362C18.1925 12.9546 17.8075 12.9546 17.4961 13.1362L14.4961 14.8862C14.1889 15.0654 14 15.3943 14 15.75V19.25C14 19.6057 14.1889 19.9346 14.4961 20.1138L17.4961 21.8638C17.8075 22.0454 18.1925 22.0454 18.5039 21.8638L21.5039 20.1138C21.8111 19.9346 22 19.6057 22 19.25V15.75C22 15.3943 21.8111 15.0654 21.5039 14.8862L18.5039 13.1362ZM16 18.6756V16.3244L18 15.1577L20 16.3244V18.6756L18 19.8423L16 18.6756Z"
						fill="currentColor"
					/>
				</svg>
			),
			key: 'EDO',
		},
		{
			title: 'В личном кабинете',
			description: 'Документы будут хранится в истории операции',
			icon: (
				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M8 9L16 9V7L8 7L8 9Z" fill="currentColor" />
					<path
						d="M14 13L8 13L8 11L14 11L14 13Z"
						fill="currentColor"
					/>
					<path
						d="M8 17L16 17L16 15L8 15L8 17Z"
						fill="currentColor"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M4 21V3C4 2.44772 4.44772 2 5 2H5.52786C5.9936 2 6.45294 2.10844 6.8695 2.31672L7.55279 2.65836C7.83431 2.79912 8.16569 2.79912 8.44721 2.65836L8.65836 2.55279C9.50294 2.1305 10.4971 2.1305 11.3416 2.55279L11.5528 2.65836C11.8343 2.79912 12.1657 2.79912 12.4472 2.65836L12.6584 2.55279C13.5029 2.1305 14.4971 2.1305 15.3416 2.55279L15.5528 2.65836C15.8343 2.79912 16.1657 2.79912 16.4472 2.65836L17.1305 2.31672C17.5471 2.10844 18.0064 2 18.4721 2H19C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3V21C20 21.5523 19.5523 22 19 22H18.4721C18.0064 22 17.5471 21.8916 17.1305 21.6833L16.4472 21.3416C16.1657 21.2009 15.8343 21.2009 15.5528 21.3416L15.3416 21.4472C14.4971 21.8695 13.5029 21.8695 12.6584 21.4472L12.4472 21.3416C12.1657 21.2009 11.8343 21.2009 11.5528 21.3416L11.3416 21.4472C10.4971 21.8695 9.50294 21.8695 8.65836 21.4472L8.44721 21.3416C8.16569 21.2009 7.83431 21.2009 7.55279 21.3416L6.8695 21.6833C6.45294 21.8916 5.9936 22 5.52786 22H5C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21ZM6.65836 4.44721L6 4.11803L6 19.882L6.65836 19.5528C7.50294 19.1305 8.49706 19.1305 9.34164 19.5528L9.55279 19.6584C9.83431 19.7991 10.1657 19.7991 10.4472 19.6584L10.6584 19.5528C11.5029 19.1305 12.4971 19.1305 13.3416 19.5528L13.5528 19.6584C13.8343 19.7991 14.1657 19.7991 14.4472 19.6584L14.6584 19.5528C15.5029 19.1305 16.4971 19.1305 17.3416 19.5528L18 19.882V4.11803L17.3416 4.44721C16.4971 4.8695 15.5029 4.8695 14.6584 4.44721L14.4472 4.34164C14.1657 4.20088 13.8343 4.20088 13.5528 4.34164L13.3416 4.44721C12.4971 4.86951 11.5029 4.86951 10.6584 4.44721L10.4472 4.34164C10.1657 4.20088 9.83431 4.20088 9.55279 4.34164L9.34164 4.44721C8.49706 4.86951 7.50294 4.86951 6.65836 4.44721Z"
						fill="currentColor"
					/>
				</svg>
			),
			key: 'LK',
		},
	],
}
