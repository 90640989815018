import React from 'react'
import { ReactComponent as OutdoorIcon } from 'src/assets/images/billboard_2.svg'
import s from './tvHeader.module.scss'

export const TvAdvHeader = (props: any) => {
	return (
		<div className={s.outdoorHeader}>
			<div className={s.primaryText}>
				<h4>ТВ-реклама</h4>
				<div>
					<OutdoorIcon />
				</div>
			</div>
			<div>
				Реклама будет транслироваться на площадках{' '}
				<a
					href="https://adstream.ru/"
					target={'_blank'}
					rel={'noreferrer'}
				>
					AdStream
				</a>{' '}
				и{' '}
				<a
					href="https://teletarget.ru/"
					target={'_blank'}
					rel={'noreferrer'}
				>
					Teletarget
				</a>
				.
			</div>
		</div>
	)
}
