export type PlacementTabKeyType = 'all' | 'unused' | 'used' | 'archive'
export type PlacementTabCountKeyType =
	| 'count_total'
	| 'count_used'
	| 'count_unused'
	| 'count_in_archive'
export type PlacementTabType = {
	count: number
	title: string
	key: PlacementTabKeyType
	countKey: PlacementTabCountKeyType
}
export const placementTabs: Array<PlacementTabType> = [
	{
		count: 0,
		title: 'Все',
		key: 'all',
		countKey: 'count_total',
	},
	{
		count: 0,
		title: 'Свободные',
		key: 'unused',
		countKey: 'count_unused',
	},
	{
		count: 0,
		title: 'Забронированные',
		key: 'used',
		countKey: 'count_used',
	},
	{
		count: 0,
		title: 'Архив',
		key: 'archive',
		countKey: 'count_in_archive',
	},
]
