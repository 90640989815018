import React, { FC } from 'react'
import useResponsive from '../../../../utils/useResponsive'
import { Operations } from '../../../../stores/ADMarketTypes.types'
import MobileOperationsList from './MobileOperationsList'
import DesktopOperationsList from './DesktopOperationsList'

interface IOperationsContainer {
	operations: Operations[]
}

const OperationsContainer: FC<IOperationsContainer> = ({ operations }) => {
	const { isMobile } = useResponsive()

	return (
		<>
			{isMobile && <MobileOperationsList operations={operations} />}
			{!isMobile && <DesktopOperationsList operations={operations} />}
		</>
	)
}

export default OperationsContainer
