import React from 'react'
import s from '../style.module.scss'
import cn from 'classnames'
import {
	ItemCheckbox,
	ProviderPagination,
} from 'src/components/_provider/Placements/components'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { ModerAdvCompanyItemType } from 'src/components/_moderator/types/ModerationRequestTypes'
import { CampaignsItem } from './CampaignsItem'

type CampaignsListProps = {
	setSelectedItem: (adv: ModerAdvCompanyItemType | undefined) => void
	selectedItemId: string | undefined
	isLoading: boolean
	total: number
	limit: number
	offset: number
	setOffset: (offset: number) => void
	setLimit: (limit: number) => void
}

export const CampaignsList = ({
	setSelectedItem,
	selectedItemId,
	limit,
	offset,
	total,
	setLimit,
	setOffset,
}: CampaignsListProps) => {
	return (
		<div className={cn(s.campaingList)}>
			<CampaignsListHeader />
			<CampaignsItemsList
				selectedItemId={selectedItemId}
				setSelectedItem={setSelectedItem}
			/>
			<div style={{ borderTop: '1px solid #CED4DA' }}>
				<ProviderPagination
					limit={limit}
					offset={offset}
					setLimit={setLimit}
					setOffset={setOffset}
					total={total}
				/>
			</div>
		</div>
	)
}
const CampaignsListHeader = () => {
	return (
		<div className={cn(s.campaingListHeader)}>
			<ItemCheckbox
				style={{
					height: '100%',
					padding: '14px 15px',
					minWidth: 'auto',
					borderLeft: 'none',
				}}
				checked={false}
				onCheckBoxClick={() => {}}
			/>
			<div className={cn(s.campaingListHeaderName)}>Название</div>
			<div className={cn(s.campaingListHeaderStatus)}>Статус</div>
		</div>
	)
}
type CampaignsItemsListPropsType = {
	setSelectedItem: (adv: ModerAdvCompanyItemType | undefined) => void
	selectedItemId: string | undefined
}
export const CampaignsItemsList = ({
	setSelectedItem,
	selectedItemId,
}: CampaignsItemsListPropsType) => {
	const [moderData, moderInterface] = useModerationData()

	const list = moderData.advList

	const handleSetSelectedItem = (selectedItem: ModerAdvCompanyItemType) => {
		setSelectedItem(selectedItem)
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}

	return (
		<div className={cn(s.campaignItemsList)}>
			{list &&
				list.map((campaign) => {
					return (
						<CampaignsItem
							isActive={campaign.id === selectedItemId}
							key={campaign.id}
							campaign={campaign}
							onClick={() => handleSetSelectedItem(campaign)}
						/>
					)
				})}
		</div>
	)
}
