import React from 'react'
import ls from '../style.module.scss'
import { Accordion } from 'react-bootstrap'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { TvAccordionBody } from './accorion-bodies/TvAccordionBody'
import { ReactComponent as TV } from '../../../../../../../../assets/images/tv.svg'
import { CommonAdvAccordionsProps } from '../CampaignDetailedInfo'
import { BookingState } from 'src/stores/ADMarketTypes.types'
import { getAdjustmentMessage } from 'src/components/_moderator/utils'

export const TvDetailedAccordion = ({
	setDetailedItem,
	setAdvItemActon,
	onEditVinstant,
}: CommonAdvAccordionsProps) => {
	const [moder, moderInterface] = useModerationData()

	const handleSetAdvItemActionType = (
		action: 'moderation' | 'adjustment' | 'reject'
	) => {
		setAdvItemActon({
			action: action,
			type: 'adstream',
			rejectMessage: moder.detailedCampaign.adstream.reject_msg || '',
			adjustmentMessage:
				moder.detailedCampaign.adstream.adjustment_msg || '',
			isDocRequired: moder.detailedCampaign.adstream.is_docs_required,
			item: {
				id: moder?.detailedCampaign?.adstream.id,
				name: 'Adstream',
				placeName: '',
				src: 'https://storage.yandexcloud.net/admarket-images/static-img/tv_4_11zon.jpg',
			},
		})
	}

	return (
		<Accordion className={ls.accordion} defaultActiveKey="0">
			<Accordion.Item eventKey="0">
				<Accordion.Header as={'div'} className={ls.accordionHeader}>
					<TV style={{ fill: '#212529' }} />
					<h6>TV-реклама</h6>
				</Accordion.Header>

				{moder?.detailedCampaign?.extra_data.tv_is_selected && (
					<TvAccordionBody
						onEditVinstant={onEditVinstant}
						adjusmentMessage={getAdjustmentMessage({
							state: moder.detailedCampaign.internet_vk
								.state as unknown as BookingState,
							adjusmentMessage:
								moder.detailedCampaign.internet_vk
									.adjustment_msg,
							rejectMessage:
								moder.detailedCampaign.internet_vk.reject_msg,
						})}
						setAdvItemActionType={handleSetAdvItemActionType}
						setDetailedItem={setDetailedItem}
					/>
				)}
			</Accordion.Item>
		</Accordion>
	)
}
