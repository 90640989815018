import React, { useMemo } from 'react'
import { Redirect, Route } from 'react-router-dom'
import useUserData from '../../stores/userData'
import ROUTES from '../../constants/Routes'
import { withPage } from '../page/Page'
import NotificationsPage from '../notifications/NotificationsPage'
import useResponsive from '../../utils/useResponsive'

const UserNotificationsPage = withPage({ noSidebar: false, isProvider: false })(
	NotificationsPage
)
const ProviderNotificationsPage = withPage({
	noSidebar: false,
	isProvider: true,
})(NotificationsPage)

export default function NotificationCheckRoute({ ...passThrough }) {
	const [user] = useUserData()
	const { isMobile, isTablet } = useResponsive()
	const isDesktop = useMemo(
		() => !isMobile && !isTablet,
		[isMobile, isTablet]
	)
	if (!user || !user.phone_number || !user.is_phone_confirmed) {
		return <Redirect to={ROUTES.ROUTE_AUTH} />
	}
	if (user.provider)
		return !isDesktop ? (
			<Route {...passThrough}>
				<ProviderNotificationsPage />
			</Route>
		) : (
			<Redirect to={ROUTES.ROUTE_PROVIDER} />
		)

	if (!user.provider)
		return !isDesktop ? (
			<Route {...passThrough}>
				<UserNotificationsPage />
			</Route>
		) : (
			<Redirect to={ROUTES.ROUTE_CAMPAIGNS} />
		)
	return <Redirect to={ROUTES.ROUTE_AUTH} />
}
