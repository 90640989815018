import React from 'react'
import { Spinner } from 'react-bootstrap'

export const CreateReportPlacementHeader = ({
	isLoading,
	title,
}: {
	isLoading: boolean
	title: string
}) => {
	return (
		<div
			style={{
				display: 'flex',
				gap: '10px',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<h4 style={{ padding: '24px 0 0 0' }}>{title}</h4>

			{isLoading && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<Spinner
						style={{ color: '#007BFF' }}
						animation="border"
						size="sm"
						role="status"
						aria-hidden="true"
						className="me-2"
					/>
				</div>
			)}
		</div>
	)
}
