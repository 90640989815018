import React from 'react'
import { StepElement } from './StepElement'
import { HelpContentKey, getHelpImg, helpImgList } from '../../../constants'

type PersonCreatingContentProps = {
	changeActive: (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		itemId: HelpContentKey,
		nestedId?: HelpContentKey
	) => void
	contentKey: HelpContentKey
}

export const CreatingContent = ({
	changeActive,
	contentKey,
}: PersonCreatingContentProps) => {
	const isLegal = contentKey === 'legal'
	const headerText = isLegal ? 'юридических' : 'физических'
	const staticUrl =
		'https://storage.yandexcloud.net/admarket-images/static-img/'
	return (
		<>
			<h2>Создание рекламной кампании для {headerText} лиц</h2>

			<section>
				<h4 style={{ marginBottom: '24px' }}>
					Шаг 1. Данные о бизнесе
				</h4>
				<ol>
					<StepElement
						number={1}
						alt="Выберите вкладку «Создать новую организацию»"
						title={'Выберите вкладку «Создать новую организацию».'}
						url={
							isLegal
								? getHelpImg(helpImgList.legal.create[1])
								: getHelpImg(helpImgList.person.create[1])
						}
					/>

					<StepElement
						number={2}
						alt="Введите название вашего бизнеса"
						title="Введите название вашего бизнеса. Его можно поменять
							в любой момент. Это удобно, если у вас несколько
							организаций."
						url={
							isLegal
								? getHelpImg(helpImgList.legal.create[2])
								: getHelpImg(helpImgList.person.create[2])
						}
					/>
					<StepElement
						number={3}
						alt="Выберите вид деятельности"
						title="Выберите вид деятельности из выпадающего списка."
						url={
							isLegal
								? getHelpImg(helpImgList.legal.create[3])
								: getHelpImg(helpImgList.person.create[3])
						}
					/>
					<StepElement
						number={4}
						alt="Выберите тип бизнеса"
						title="Выберите тип бизнеса. Для физических лиц нажмите на
							кнопку «Физлицо», и заполните все обязательные поля
							ниже. Заполните поле «Фактический адрес вашего
							бизнеса», если вы, например, хотите привлечь
							максимальное внимание к рекламной кампании в вашем
							районе."
						url={
							isLegal
								? getHelpImg(helpImgList.legal.create[4])
								: getHelpImg(helpImgList.person.create[4])
						}
					/>
				</ol>
			</section>

			<section>
				<h4 style={{ marginBottom: '24px' }}>
					Шаг 2. Данные о рекламной кампании
				</h4>
				<ol>
					<StepElement
						number={1}
						alt="укажите название вашей рекламной
						кампании"
						title="В поле «Название» укажите название вашей рекламной
							кампании, например, «Моя реклама». Так вам будет
							удобнее ее искать в общем списке всех кампаний."
						url={getHelpImg(helpImgList.campaignData[1])}
					/>

					<StepElement
						number={2}
						alt="Выберите период проведения"
						title="Выберите период проведения рекламной кампании.
							Обратите внимание, рекламная кампания может
							начинаться только с 1 или 15 числа каждого месяца.
							Минимальный срок размещения — 2 недели."
						url={getHelpImg(helpImgList.campaignData[2])}
					/>

					<StepElement
						number={3}
						alt="Выберите регион"
						title="Выберите регион показа рекламы. Можно выбрать один,
							несколько или все регионы, в зависимости от
							интересов и клиентов вашего бизнеса."
						url={getHelpImg(helpImgList.campaignData[3])}
					/>
					<StepElement
						number={4}
						alt="Укажите время"
						title="Укажите время показа рекламы по московскому времени
							(GMT+3)."
						url={getHelpImg(helpImgList.campaignData[4])}
					/>

					<StepElement
						alt="Укажите пол и возраст"
						title="5. Укажите пол и возраст
						аудитории и нажмите
						кнопку «Продолжить»."
						url={getHelpImg(helpImgList.campaignData[5])}
					/>
				</ol>
			</section>

			<section>
				<h4 style={{ marginBottom: '24px' }}>Шаг 3. Форматы рекламы</h4>

				<ol>
					<StepElement
						number={1}
						alt="Выбор формата запуска рекламы"
						title="Выберите, в каких форматах хотите запустить рекламу."
						url={getHelpImg(helpImgList.adFormats[1])}
					>
						<ul>
							<li>
								<p>
									О добавлении рекламных мест и настройке
									наружной рекламы читайте{' '}
									<a
										href=""
										onClick={(e) =>
											changeActive(e, 'outdoor')
										}
									>
										в разделе
									</a>
									.
								</p>
							</li>
							<li>
								<p>
									О добавлении рекламных мест и настройке
									внутренней рекламы читайте{' '}
									<a
										href=""
										onClick={(e) =>
											changeActive(e, 'indoor')
										}
									>
										в разделе
									</a>
									.
								</p>
							</li>
							<li>
								<p>
									О выборе способа показа интернет-рекламы
									читайте{' '}
									<a
										href=""
										onClick={(e) =>
											changeActive(e, 'internet')
										}
									>
										в разделе
									</a>
									.
								</p>
							</li>
							<li>
								О добавлении и настройке ТВ-рекламы читайте{' '}
								<a
									href=""
									onClick={(e) => changeActive(e, 'tv')}
								>
									в разделе
								</a>
								.
							</li>
						</ul>
					</StepElement>

					<StepElement
						number={2}
						alt="финальная стоимость"
						title="После выбора форматов отобразится финальная стоимость рекламной кампании. Еще раз всё проверьте и нажмите на кнопку «Продолжить»."
						url={getHelpImg(helpImgList.adFormats[2])}
					>
						<div>
							Обратите внимание, что на данном этапе оформления
							заказа все цены указаны до НДС. Учитывайте этот
							момент при планировании своего бюджета.
						</div>
					</StepElement>
				</ol>
			</section>

			<section>
				<h4 style={{ marginBottom: '24px' }}>
					Шаг 4. Загрузка материалов
				</h4>
				<ol>
					<StepElement
						number={1}
						alt="рекламные материалы"
						title="Загрузите рекламные материалы к каждому формату рекламы."
						url={getHelpImg(
							helpImgList.dataUpload.legalAndPerson[1]
						)}
					>
						<ul>
							<li>
								<p>
									Чтобы посмотреть технические требования к
									рекламному материалу, нажмите на кнопку
									«Требования».
								</p>
							</li>
							<li>
								<p>
									Если у вас есть готовый рекламный материал,
									то загрузите его через кнопку «Выберите
									файл».
								</p>
							</li>
							<li>
								Чтобы посмотреть всю информацию о
								запланированной рекламной кампании, нажмите на
								кнопку «Смотреть медиаплан».{' '}
								<a
									href=""
									onClick={(e) =>
										changeActive(e, 'media-plan')
									}
								>
									Подробнее
								</a>
							</li>
						</ul>
					</StepElement>

					<StepElement
						number={2}
						alt="создать его
						с помощью конструктора"
						title="Если у вас нет готового рекламного материала, вы можете создать его
							с помощью конструктора. Нажмите на кнопку «Создать материал», выберите
							один из шаблонов или создайте свой."
						url={getHelpImg(
							helpImgList.dataUpload.legalAndPerson[2]
						)}
					/>

					<StepElement
						number={3}
						alt="Отправить на модерацию"
						title="После загрузки всех рекламных материалов кнопка «Отправить на модерацию»
							станет активна."
						url={getHelpImg(
							helpImgList.dataUpload.common.sendToModerate
						)}
					/>

					<StepElement
						number={4}
						alt="Подтвердите действие"
						title="Подтвердите действие или перепроверьте всё еще раз, поскольку во время
							модерации вы не сможете изменить рекламную кампанию."
						url={getHelpImg(helpImgList.dataUpload.common.confirm)}
					/>

					<StepElement
						number={5}
						alt="уведомление на электронную почту"
						title="После модерации вам придет уведомление на электронную почту. При
							успешном прохождении появится счет на оплату."
						url={
							getHelpImg(helpImgList.dataUpload.common.result) +
							'?v1'
						}
					>
						<div>
							Счет необходимо оплатить в течение{' '}
							{isLegal ? 'суток' : '30 минут'}.{' '}
							{`В
							случае, если вы не успеете произвести оплату, ваша
							рекламная кампания перейдет в статус "черновик", потребуется повторное подтверждение дат проведения рекламной кампании.`}
						</div>
					</StepElement>
				</ol>
			</section>
		</>
	)
}
