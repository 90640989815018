import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { withPage } from '../../page/Page'
import bem from '../../../utils/bem'
import MediaformatsPage from '../Mediaformats/MediaformatsPage'
import '../../../styles/provider-page.scss'
import useProviderData from '../../../stores/providerData'
import BookingsPage from '../Bookings/BookingsPage'
import PlacementsPage from '../Placements/PlacementsPage'

const cn = bem('provider-page')
export const provider_routes = [
	{
		path: '/bookings',
		component: BookingsPage,
		title: 'Брони',
		count: 0,
		renderToMenu: true,
	},
	{
		path: '/media-formats',
		component: MediaformatsPage,
		title: 'Медиа-форматы',
		count: 1,
		renderToMenu: true,
	},
	{
		path: '/placements',
		component: PlacementsPage,
		title: 'Рекламные носители',
		count: 1,
		renderToMenu: true,
	},
	{
		path: '/prices',
		component: () => <h6>В разработке</h6>,
		title: 'Прайс лист',
		count: null,
		renderToMenu: false,
	},
	{
		path: '/help',
		component: () => <h6>В разработке</h6>,
		title: 'Справка',
		count: null,
		renderToMenu: false,
	},
]

const ProviderPage: React.FC = () => {
	//for prefetching ProviderData
	useProviderData()
	return (
		<section className={cn()}>
			<Switch>
				{provider_routes.map((i, k) => (
					<Route
						key={k + i.path}
						path={'/provider' + i.path}
						component={i.component}
						exact
					/>
				))}
				<Route path={'/'}>
					<Redirect to={`/provider/bookings`} />
				</Route>
			</Switch>
		</section>
	)
}
export default withPage({ noSidebar: false, isProvider: true })(ProviderPage)
