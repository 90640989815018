import React, { useCallback, useState } from 'react'
import { AdvFormats } from '../types'
import s from './advFormatStep.module.scss'
import { AdvFormatSelectionStage } from './select-stage/AdvFormatSelectionStage'
import { AdvFormatStage } from './adv-format-stage/AdvFormatStage'

type Props = {
	setStep: (step: number) => void
	cn: (className: string) => void
	onBack: () => void
	onNextStep: () => void
}
export const AdvFormatsStep = ({ cn, onBack, onNextStep, setStep }: Props) => {
	const [selectedAdvFormat, setSelectedAdvFormat] =
		useState<AdvFormats | null>(null)

	const handleSelectFormat = useCallback((format: AdvFormats) => {
		setSelectedAdvFormat(format)
	}, [])

	const goToSelectors = useCallback(() => {
		setSelectedAdvFormat(null)
	}, [])

	return (
		<div className={s.advFormatStep}>
			{selectedAdvFormat ? (
				<AdvFormatStage
					goToSelectors={goToSelectors}
					selectedAdvFormat={selectedAdvFormat}
					onBack={onBack}
					cn={cn}
					onNextStep={onNextStep}
				/>
			) : (
				<AdvFormatSelectionStage
					onSelectFormat={handleSelectFormat}
					onBack={onBack}
					onNextStep={onNextStep}
				/>
			)}
		</div>
	)
}
