import React from 'react'
import { StepElement } from './StepElement'
import { getHelpImg, helpImgList } from '../../../constants'

export const MediaPlanContent = (props: any) => {
	const mediaPlanText = (
		<>
			В медиаплане отображается вся информация о запланированной рекламной
			кампании:
			<ul style={{ marginTop: '10px', marginBottom: 0 }}>
				<li>
					<p>
						общая информация о рекламной кампании — название,
						период, регионы, время, пол, возраст;
					</p>
				</li>
				<li>
					<p>
						таблица со всеми выбранными форматами рекламы, местами
						размещений и прогнозными показателями (показы, стоимость
						без НДС);
					</p>
				</li>
				<li>возможность скачать медиаплан в формате Excel.</li>
			</ul>
		</>
	)

	return (
		<>
			<h2>Медиаплан</h2>

			{mediaPlanText}
			<section style={{ listStyle: 'none', marginTop: 0 }}>
				<StepElement
					title={''}
					alt=""
					url={getHelpImg(helpImgList.mediaPlan[1])}
				>
					<div>
						Обратите внимание, все цены указаны без учета НДС. При
						планировании рекламной кампании учитывайте этот момент.
					</div>
				</StepElement>
			</section>
		</>
	)
}
