import React from 'react'
import s from '../helpContent.module.scss'
import cn from 'classnames'
import ImageFullScreenWrapper from 'src/components/UtilityComponents/ImageFullScreenWrapper'
import { HelpContentKey, getHelpImg, helpImgList } from '../../../constants'
import { StepElement, StepElementImage } from './StepElement'

type InternetHelpContentProps = {
	isMobile: boolean
	changeActive: (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		itemId: HelpContentKey,
		nestedId?: HelpContentKey
	) => void
}

export const InternetHelpContent = ({
	isMobile,
	changeActive,
}: InternetHelpContentProps) => {
	return (
		<>
			<h2>Интернет-реклама</h2>

			<section>
				<p>
					Интернет-реклама — реклама в сети Интернет, которая
					представлена в виде баннеров, видеороликов и текстовых
					блоков. Такая реклама обычно имеет ссылку на сайт
					рекламодателя.
				</p>

				<p>
					Интернет-реклама в Admarket — это размещение таргетированных
					баннеров или видеороликов в рекламных сетях Яндекс и VK.
				</p>

				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					className={cn(s.img, { [s.img_mobile]: isMobile })}
				>
					<ImageFullScreenWrapper>
						<img
							className={cn(s.img, { [s.img_mobile]: isMobile })}
							src={getHelpImg(helpImgList.internet.main)}
							alt={''}
							style={{
								borderRight: 'none',
								borderLeft: 'none',
								borderRadius: 0,
							}}
						/>
					</ImageFullScreenWrapper>
				</div>
			</section>

			<section>
				<h4 className={cn(s.title)}>Создание интернет-рекламы</h4>
				<ol>
					<li className={cn(s.li_text)}>
						<span>1. </span>
						<p>Перейдите на платформу Admarket.</p>
					</li>
					<li className={cn(s.li_text)}>
						<span>2. </span>
						<p>
							Начните создавать рекламную кампанию.{' '}
							<a
								href=""
								onClick={(e) =>
									changeActive(
										e,
										'campaign-creation',
										'person'
									)
								}
							>
								Подробнее
							</a>
						</p>
					</li>
					<li className={cn(s.li_text)}>
						<span>3. </span>
						<p>Дойдите до шага 3 с выбором форматов рекламы.</p>
					</li>

					<StepElement
						number={4}
						alt="Добавить место"
						title="В блоке «Интернет-реклама» выберите способ рекламы Яндекс или VK."
						url={getHelpImg(helpImgList.internet.create[4])}
					/>

					<StepElement
						number={5}
						title="Если выбрали Яндекс, заполните обязательные поля — «Рекламируемый сайт» и «Бюджет кампании». Минимальный бюджет — 8 500 ₽."
						url={getHelpImg(helpImgList.internet.create[5])}
					>
						<div>
							Обратите внимание, что на данном этапе оформления
							заказа все цены указаны до НДС. Учитывайте этот
							момент при планировании своего бюджета.
						</div>
					</StepElement>
					<StepElement
						number={6}
						title="Если выбрали VK, заполните обязательные поля — «Рекламируемый сайт» и «Бюджет кампании». Минимальный бюджет — 8 500 ₽. "
						url={getHelpImg(helpImgList.internet.create[6])}
					/>
					<li>
						<div className={cn(s.li_text)}>
							<span>7. </span>
							<p>
								Можете переходить к следующим форматам рекламы.
								Если решили остановиться только на
								интернет-рекламе, в самом низу или закрепленном
								меню нажмите на кнопку «Подтвердить бюджет».
							</p>
						</div>

						<StepElementImage
							alt=""
							isMobile={isMobile}
							url={getHelpImg(helpImgList.internet.create[7])}
						/>
						<div
							className={cn(s.child)}
							style={{ marginTop: '24px' }}
						>
							<ul>
								<li>
									<p>
										О добавлении рекламных мест и настройке
										наружной рекламы читайте{' '}
										<a
											href=""
											onClick={(e) =>
												changeActive(e, 'outdoor')
											}
										>
											в разделе
										</a>
										.
									</p>
								</li>
								<li>
									<p>
										О добавлении рекламных мест и внутренней
										наружной рекламы читайте{' '}
										<a
											href=""
											onClick={(e) =>
												changeActive(e, 'internet')
											}
										>
											в разделе
										</a>
										.
									</p>
								</li>
								<li>
									О добавлении и настройке ТВ-рекламы читайте{' '}
									<a
										href=""
										onClick={(e) => changeActive(e, 'tv')}
									>
										в разделе
									</a>
									.
								</li>
							</ul>
						</div>
					</li>
				</ol>
			</section>

			<section>
				<h4 className={cn(s.title)}>
					Шаг 4. Загрузка материалов для интернет-рекламы
				</h4>

				<ol>
					<StepElement
						number={1}
						title="Для Яндекса заполните обязательные поля — «Что будете рекламировать?» и «Описание вашего товара или услуги»."
						url={getHelpImg(helpImgList.dataUpload.internet[1])}
					>
						<ul>
							<li>
								<p>
									Если у вас есть готовый рекламный материал,
									загрузите его через кнопку «Выберите файл».
								</p>
							</li>

							<li>
								Чтобы посмотреть всю информацию о
								запланированной рекламной кампании, нажмите на
								кнопку «Смотреть медиаплан».{' '}
								<a
									href=""
									onClick={(e) =>
										changeActive(e, 'media-plan')
									}
								>
									Подробнее
								</a>
								.
							</li>
						</ul>
					</StepElement>

					<StepElement
						number={2}
						title="Для VK заполните обязательные поля — «Что будете рекламировать?», «Описание вашего товара или услуги», «Надпись на кнопке». Загрузите готовый рекламный материал."
						url={getHelpImg(helpImgList.dataUpload.internet[2])}
					/>
					<StepElement
						number={3}
						title="Если у вас нет готового рекламного материала, вы можете создать его с помощью конструктора. Нажмите на кнопку «Создать материал», выберите один из шаблонов или создайте свой."
						url={getHelpImg(helpImgList.dataUpload.internet[3])}
					/>
					<StepElement
						number={4}
						title="После загрузки всех рекламных материалов кнопка «Отправить на модерацию» станет активна."
						url={getHelpImg(
							helpImgList.dataUpload.common.sendToModerate
						)}
					/>
					<StepElement
						number={5}
						title="Подтвердите действие или перепроверьте всё еще раз, поскольку во время модерации вы не сможете изменить рекламную кампанию."
						url={getHelpImg(helpImgList.dataUpload.common.confirm)}
					/>
					<StepElement
						number={6}
						title="После модерации вам придет уведомление на электронную почту. При успешном прохождении появится счет на оплату."
						url={
							getHelpImg(helpImgList.dataUpload.common.result) +
							'?v1'
						}
					>
						<div>
							{`Рекламную кампанию необходимо оплатить в течение суток. В
							случае, если вы не успеете произвести оплату, ваша
							рекламная кампания перейдет в статус "черновик", 
							потребуется повторное подтверждение дат проведения рекламной кампании.`}
						</div>
					</StepElement>
				</ol>
			</section>
		</>
	)
}
