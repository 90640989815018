import React from 'react'
import { Form } from 'react-bootstrap'
import AdMarketSelect from 'src/components/UtilityComponents/ADMarketSelect/AdMarketSelect'
import s from './massCoverageList.module.scss'
import { DropdownIndicator } from 'src/components/_client/create-campaign/Step1-Campaign/WatchTimeSelector'
import { NumericFormat } from 'react-number-format'
import InputWithEndText from 'src/components/UtilityComponents/InputWithEndText'
import { ReactComponent as PinOnMap } from 'src/assets/images/pin-2.svg'
import { DistrictBookingRec } from 'src/stores/types/recommendations'
import useResponsive from 'src/utils/useResponsive'

type Props = {
	districtBooking: DistrictBookingRec
}
export const MassCoverageItem = ({ districtBooking }: Props) => {
	const { isMobile } = useResponsive()
	return (
		<div className={s.item}>
			<div className={s.inputs}>
				<Form.Group className={s.controlCity}>
					<Form.Label>{'Город'}</Form.Label>
					<AdMarketSelect
						id={`${districtBooking.city_id}_city`}
						value={{
							label: districtBooking.city_name,
							value: districtBooking.city_id,
						}}
						placeholder={'Не выбран'}
						components={{ DropdownIndicator }}
						options={[
							{
								value: districtBooking.city_id,
								label: districtBooking.city_name,
							},
						]}
						onChange={() => {}}
						isDisabled={true}
						styles={{ borderRadius: isMobile ? '12px' : '16px' }}
					/>
				</Form.Group>

				<Form.Group className={s.controlBudget}>
					<Form.Label>{'Бюджет на город'}</Form.Label>
					<div style={{ display: 'flex', gap: '0.5rem' }}>
						<NumericFormat
							id={`${districtBooking.city_id}_budget`}
							decimalScale={2}
							decimalSeparator=","
							value={districtBooking.budget}
							suffix=" ₽"
							thousandSeparator={' '}
							customInput={InputWithEndText}
							controlStyle={{
								minHeight: '40px',
								height: isMobile ? '40px' : '45px',
								fontSize: isMobile ? '16px' : '1.25rem',
								borderRadius: isMobile ? '12px' : '16px',
							}}
							disabled={true}
							onChange={() => {}}
							onKeyDown={() => {}}
							containerStyle={{
								overflow: 'hidden',
								width: '100%',
							}}
						/>
					</div>
				</Form.Group>
			</div>

			<div className={s.counts}>
				<PinOnMap />
				<div className={s.countsText}>
					<h6>Добавлено рекламных мест:</h6>
					<h6>{districtBooking.placements.length}</h6>
				</div>
			</div>
		</div>
	)
}
