import React, { useMemo } from 'react'
import { AdvFormats, AdvSelectorState } from '../../../../types'
import { Card } from 'react-bootstrap'
import useCampaignData from 'src/stores/campaignData'
import {
	getAdvFormatsSelectorsData,
	getSpecialSelectorProps,
} from '../../../../utils'
import { FormatsSelectorItem } from './FormatsSelectorItem'
import s from './formatsSelector.module.scss'
import useUserData from 'src/stores/userData'
import { RecommendationsLink } from '../recommendations/rec-link/RecommendationsLink'

type Props = {
	onSelectFormat: (format: AdvFormats) => void
	onRecommendationsClick: () => void
}
export const FormatsSelector = ({
	onSelectFormat,
	onRecommendationsClick,
}: Props) => {
	const [campaign, campaignInterface] = useCampaignData()

	const [userData, _] = useUserData()

	const specialProps = useMemo(
		() => getSpecialSelectorProps(userData.config),
		[userData]
	)

	const selectors = useMemo(() => {
		const selectorsData = getAdvFormatsSelectorsData(campaign, specialProps)
		return selectorsData.filter(
			(s) => s.state !== AdvSelectorState.Disabled
		)
	}, [campaign, specialProps])

	return (
		<Card className={s.selectorsCard}>
			<div className={s.selectorsHead}>
				<h2>Форматы рекламы</h2>
				<p>Выберите подходящий для вас формат рекламы</p>
			</div>

			<RecommendationsLink onClick={onRecommendationsClick} />

			<div className={s.selectorsList}>
				{selectors.map((selector) => (
					<FormatsSelectorItem
						key={selector.key}
						selector={selector}
						onSelectFormat={onSelectFormat}
					/>
				))}
			</div>
		</Card>
	)
}
