import React from 'react'
import { PhotoReportItem } from './PhotoReportItem'
import moment from 'moment'
import { ProviderBookingPhotoReport } from '../CompletedCampaignReport'

type Props = {
	photosList: ProviderBookingPhotoReport[]
	endDate: string
	startDate: string
	refetchBookingDetail: () => Promise<void>
}
export const PhotoReportItemsList = ({
	endDate,
	photosList,
	startDate,
	refetchBookingDetail,
}: Props) => {
	return (
		<div className="d-flex flex-column gap-2">
			{photosList.map((photoReport) => (
				<PhotoReportItem
					refetchBookingDetail={refetchBookingDetail}
					key={photoReport.date}
					date={photoReport.date}
					photos={photoReport.photo_reports}
					endDate={moment(endDate, 'YYYY-MM-DD')}
					startDate={moment(startDate, 'YYYY-MM-DD')}
				/>
			))}
		</div>
	)
}
