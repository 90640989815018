import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { AdvFormats, IndoorVariants } from '../../../../types'
import { AdvFormatVariantsContainer } from '../../../common/adv-format-variants-container/AdvFormatVariantsContainer'
import useCampaignData from 'src/stores/campaignData'
import { useAdformatsStore } from 'src/components/_client/create-campaign/Step2-ADformats/adformats-store/adformats.store'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { IndoorGarantViews } from './indoor-variants/garant-views/IndoorGarantViews'
import { AdvFormatNav } from '../../../common/adv-format-nav/AdvFormatNav'
import { Button } from 'react-bootstrap'
import { AdvFormatContent } from '../../../common/adv-format-content/AdvFormatContent'
import { AdvFormatContainer } from '../../../common/adv-format-container/AdvFormatContainer'
import s from './indoorAdv.module.scss'
import useResponsive from 'src/utils/useResponsive'

const IndoorVariantsForRender: Record<
	IndoorVariants,
	(props: any) => JSX.Element
> = {
	garantViews: IndoorGarantViews,
}

type Props = {
	goToSelectors: () => void
}

export const IndoorAdv = ({ goToSelectors }: Props) => {
	const [campaign, campaignInterface] = useCampaignData()

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const setAdFormatErrors = useAdformatsStore(
		(state) => state.setAdFormatErrors
	)
	const indoorBookingsCount = useMemo(() => {
		return campaign.booking.filter(
			(booking) => booking.placement.door_type === 'indoor'
		).length
	}, [campaign])

	const { addToast } = useContext(ToastsContext)
	const removeAdFormatErrorByErrorId = useAdformatsStore(
		(state) => state.removeAdFormatErrorByErrorId
	)
	const [indoorVariants, setIndoorVariants] = useState<IndoorVariants[]>(
		() => [IndoorVariants.GarantViews]
	)

	const handleAddFormat = useCallback(async () => {
		const body = {
			indoor_adv_is_selected: indoorBookingsCount > 0,
		}
		try {
			setIsLoading(true)
			await campaignInterface.patchCampaign(campaign.id, body)
			await campaignInterface.refetchSelected()
			addToast({
				text: 'Внутренняя реклама изменена',
				type: 'success',
			})
			goToSelectors()
		} catch (e) {
			addToast({
				text: 'Ошибка изменения внутренней рекламы',
				type: 'danger',
			})
		} finally {
			setIsLoading(false)
		}
	}, [indoorBookingsCount])

	const handleRemoveFormat = async () => {
		const body = {
			indoor_adv_is_selected: false,
		}
		try {
			setIsLoading(true)
			await campaignInterface.patchCampaign(campaign.id, body)
			await campaignInterface.refetchSelected()
			addToast({
				text: 'Внутренняя реклама изменена',
				type: 'success',
			})
			goToSelectors()
		} catch (e) {
			addToast({
				text: 'Ошибка изменения внутренней рекламы',
				type: 'danger',
			})
		} finally {
			setIsLoading(false)
		}
	}
	const extraNavElements = useMemo(() => {
		if (campaign.indoor_adv_is_selected) {
			return (
				<DisableIndoorAdvButton onDisableIndoor={handleRemoveFormat} />
			)
		} else {
			return null
		}
	}, [campaign])
	const RenderedOutdoorVariants = useMemo(
		() =>
			indoorVariants.map((variant) => {
				return React.createElement(IndoorVariantsForRender[variant], {
					key: variant,
				})
			}),
		[indoorVariants]
	)

	const { isMobile } = useResponsive()
	//remove all adv format errors
	useEffect(() => {
		return () => {
			setAdFormatErrors('indoorAd', null)
		}
	}, [])
	return (
		<AdvFormatContainer>
			<AdvFormatContent>
				<AdvFormatVariantsContainer>
					{RenderedOutdoorVariants}
				</AdvFormatVariantsContainer>
			</AdvFormatContent>

			<AdvFormatNav
				className={isMobile ? s.advNav : undefined}
				totalAdvVariants={indoorBookingsCount}
				isFormatChanged={true}
				advFormat={AdvFormats.Indoor}
				advAddDisabled={isLoading || indoorBookingsCount === 0}
				onBack={goToSelectors}
				onAddFormat={handleAddFormat}
				extraButton={extraNavElements}
			/>
		</AdvFormatContainer>
	)
}

type DisableIndoorAdvButtonProps = {
	onDisableIndoor: () => void
}
export const DisableIndoorAdvButton = ({
	onDisableIndoor,
}: DisableIndoorAdvButtonProps) => {
	return (
		<Button variant="danger" onClick={onDisableIndoor}>
			Убрать из кампании
		</Button>
	)
}
