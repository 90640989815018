import React, { FC, ReactElement } from 'react'
import { OverlayTrigger, Tooltip as BootstrapTooltip } from 'react-bootstrap'
import { Placement } from 'react-bootstrap/types'

export interface ITooltip {
	children: ReactElement
	text: string
	placement?: Placement
	style?: React.CSSProperties
}

const Tooltip: FC<ITooltip> = ({ children, placement = 'right', text }) => {
	// @ts-ignore
	return (
		<OverlayTrigger
			placement={placement}
			overlay={(props) => (
				<BootstrapTooltip id={`tooltip${text}`} {...props}>
					{text}
				</BootstrapTooltip>
			)}
		>
			{children}
		</OverlayTrigger>
	)
}

export default Tooltip
