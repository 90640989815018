import React, { useEffect, useState } from 'react'
import { CommonSenderProps } from './SenderTypeButtonsAdditional'
import { AdvChoisesItem } from './AdvChoisesItem'
import {
	AdvChoisesByAdvType,
	getNotficationAdvTypeCounts,
} from 'src/components/_moderator/constants'
import { NotificationAdvTypeCountKeys } from 'src/components/_moderator/types/ModerationRequestTypes'

type Props = {} & CommonSenderProps

export const AdvTypeSender = ({ detailedNotify, onChange }: Props) => {
	const [advChoisesByState, setAdvChoisesByState] =
		useState<AdvChoisesByAdvType>(() => {
			return getNotficationAdvTypeCounts(
				detailedNotify.count_adv_state_and_type,
				detailedNotify.extra_data.type_adv_company
			)
		})

	const handleIsSelectedChange = (key: NotificationAdvTypeCountKeys) => {
		setAdvChoisesByState({
			...advChoisesByState,
			[key]: {
				...advChoisesByState[key],
				isSelected: !advChoisesByState[key].isSelected,
			},
		})
	}
	useEffect(() => {
		onChange(
			Object.values(advChoisesByState)
				.filter((el) => el.isSelected)
				.map((el) => el.advType)
		)
	}, [advChoisesByState])
	return (
		<div>
			{Object.keys(advChoisesByState).map((key, index: number) => {
				const item = advChoisesByState[key]
				return (
					<AdvChoisesItem
						key={key}
						count={item.count}
						isSelected={item.isSelected}
						title={item.title}
						onChange={() =>
							handleIsSelectedChange(
								key as NotificationAdvTypeCountKeys
							)
						}
					/>
				)
			})}
		</div>
	)
}
