import React from 'react'
import {
	DisplayIssueType,
	DisplayIssuesCampaign,
} from 'src/components/_moderator/types/ModerationRequestTypes'
import { AdvProblem } from './AdvProblem'
import { CampaignInfo } from '../common/components/CampaignInfo'
import { AdvType, NotifyItem } from '../common/components/IssuesContent'
import { ProblemAdvType } from '../common/constants/problemAdvTitles'

type CampaignIssueRowProps = {
	issueObject: {
		[key in AdvType]: Array<IssueObjectType>
	}
	totalIssuesCount: number
	setNotifyItem: (notifyItem: NotifyItem) => void
	issue: DisplayIssuesCampaign
}

export type IssueObjectType = DisplayIssueType & NotifyItem

export const CampaignIssueRow = ({
	issueObject,
	setNotifyItem,
	totalIssuesCount,
	issue,
}: CampaignIssueRowProps) => {
	return (
		<>
			{Object.keys(issueObject).map((key, index) => {
				if (index === 0) {
					return (
						<AdvProblem
							onNotification={setNotifyItem}
							key={key}
							issues={issueObject[key]}
							type={key as ProblemAdvType}
						>
							<td
								rowSpan={
									totalIssuesCount +
									Object.keys(issueObject).length
								}
								scope="rowgroup"
								style={{
									verticalAlign: 'top',
								}}
							>
								<CampaignInfo issue={issue} />
							</td>
						</AdvProblem>
					)
				}

				return (
					<AdvProblem
						onNotification={setNotifyItem}
						key={key}
						issues={issueObject[key]}
						type={key as ProblemAdvType}
					/>
				)
			})}
		</>
	)
}
