import { advTitles } from '../constants'
import { CampaignReportType, ReportDetailType, ReportTableData } from '../types'

export const getTableData = (report: Partial<CampaignReportType>) => {
	const data: ReportTableData[] = Object.entries(report).map(
		([key, value]) => ({
			format: advTitles[key as ReportDetailType],
			views: {
				forecast: value.forecast_views_count,
				actual: value.views_count,
			},
			costWithoutVAT: {
				forecast: value.amount,
				actual: value.fact_cost,
			},
			id: key as ReportDetailType,
		})
	)

	const totalViewAndCounts: ReportTableData = {
		format: 'Итого',
		views: {
			forecast: data.reduce((acc, curr) => acc + curr.views.forecast, 0),
			actual: data.reduce((acc, curr) => acc + curr.views.actual, 0),
		},
		costWithoutVAT: {
			forecast: data.reduce(
				(acc, curr) => acc + curr.costWithoutVAT.forecast,
				0
			),
			actual: data.reduce(
				(acc, curr) => acc + curr.costWithoutVAT.actual,
				0
			),
		},
	}
	data.push(totalViewAndCounts)
	return data
}
