import React, { FC, useState } from 'react'
import { Operations } from '../../../../stores/ADMarketTypes.types'
import { Dropdown } from 'react-bootstrap'
import BottomSheet from '../../../UtilityComponents/Bottomsheet'
import getOperationInfo from '../getOperationInfo'
import DetailItem from '../../../../utils/DetailItem'

interface IMobileOperationsList {
	operations: Operations[]
}

const MobileOperationsList: FC<IMobileOperationsList> = ({ operations }) => {
	const [operationModalId, setOperationModalId] = useState<false | string>(
		false
	)

	const onOperationClick = (id: string) => {
		setOperationModalId(id)
	}
	return (
		<div style={{ overflowY: 'auto', maxHeight: '100%' }}>
			<Dropdown.Divider className={'m-0 p-0'} />
			{operations.map((operation, index) => (
				<React.Fragment key={operation.id}>
					<MobileOperation
						{...operation}
						onOperationClick={onOperationClick}
					/>

					<Dropdown.Divider className={'m-0 p-0 mx-3'} />
				</React.Fragment>
			))}
			<BottomSheet
				show={Boolean(operationModalId)}
				onHide={() => setOperationModalId(false)}
				// touchEvents={true}
			>
				{operationModalId && (
					<MobileOperationDetail
						{...(operations.find(
							(el) => el.operation_id === operationModalId
						) || operations[0])}
					/>
				)}
			</BottomSheet>
		</div>
	)
}
const MobileOperation: FC<
	Operations & { onOperationClick: (id: string) => void }
> = (props) => {
	const { operation_id, onOperationClick } = props
	const { payment_method, title, status, amount, date } = getOperationInfo(
		props,
		'12px'
	)
	return (
		<div
			onClick={() => onOperationClick(operation_id)}
			className={'d-flex flex-column gap-2 p-3'}
		>
			<div className={'d-flex justify-content-between'}>
				<span style={{ fontSize: '12px' }} className={'text-secondary'}>
					{date}
				</span>
				{status}
			</div>
			<p style={{ fontSize: '14px' }} className={'mb-0'}>
				{title}
			</p>
			<div className={'d-flex justify-content-between'}>
				<span style={{ fontSize: '12px' }} className={'text-secondary'}>
					{payment_method}
				</span>
				<span style={{ fontSize: '12px' }}>{amount}</span>
			</div>
		</div>
	)
}

const MobileOperationDetail: FC<Operations> = (props) => {
	const { payment_method, title, status, amount, date } = getOperationInfo(
		props,
		'12px'
	)
	return (
		<div className={'position-relative'}>
			<div
				style={{
					position: 'sticky',
					top: 0,
					left: 0,
					padding: '16px',
					borderBottom: '1px solid #CED4DA',
					backgroundColor: 'transparent',
				}}
			>
				<h6 className={'mb-0'}>Операция от {date}</h6>
			</div>
			<div className={'p-3'}>
				<DetailItem
					left={'Назначение платежа'}
					right={title}
					bottomDivider={false}
					leftFixWidth={'170px'}
				/>
				<DetailItem
					left={'Способ оплаты'}
					right={payment_method}
					bottomDivider={false}
					leftFixWidth={'170px'}
				/>
				<DetailItem
					left={'Сумма'}
					right={amount}
					bottomDivider={false}
					leftFixWidth={'170px'}
				/>
				<DetailItem
					left={'Статус'}
					right={status}
					bottomDivider={false}
					leftFixWidth={'170px'}
				/>
			</div>
		</div>
	)
}

export default MobileOperationsList
