function format(number: number | string, fixed = 0): string {
	let possibleNumber: string | number = parseFloat(number?.toString())
	if (
		isNaN(possibleNumber) ||
		possibleNumber === null ||
		possibleNumber === undefined
	) {
		possibleNumber = 0
	}

	possibleNumber = possibleNumber
		.toFixed(fixed)
		.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
		.trim()
		.replace('.', ',')

	return possibleNumber
}

export const moneyToString = format
