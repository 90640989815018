import React, { useContext, useEffect, useState } from 'react'
import s from './ClientProfilePage.module.scss'
import { withPage } from '../../page/Page'
import {
	Alert,
	Button,
	Col,
	Dropdown,
	Form,
	InputGroup,
	Modal,
	OverlayTrigger,
	Row,
	Spinner,
	Tooltip,
} from 'react-bootstrap'
import {
	ChanePhoneBlock,
	ChangePasswordBlock,
} from '../../_provider/Profile/ProviderProfile'
import useUserData from '../../../stores/userData'
import { ToastsContext } from '../../UtilityComponents/ToastsContextProvider'
import { Formik } from 'formik'
import useResponsive from '../../../utils/useResponsive'
import { useHistory } from 'react-router-dom'
import { authFetch } from '../../../utils/authFetch'
import { companies, company, getIndustryTypes } from '../../../constants/api'
import { useConfirm } from '../../UtilityComponents/ConfirmModalProvider'
import { ScrollToError } from '../../UtilityComponents/ScrollToFormikError'
import { AutocompleteInput } from '../../UtilityComponents/AutocompleteInput'
import yandexTracking from '../../../utils/yandexTracking'
import MyWallet from './MyWallet'

const ClientProfilePage = (props) => {
	const { isMobile } = useResponsive()
	const history = useHistory()

	return (
		<>
			{isMobile && (
				<div
					style={{
						height: '40px',
						position: 'fixed',
						background: '#fff',
						width: '100vw',
						borderBottom: '1px solid #CED4DA',
						zIndex: 2,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						cursor: 'pointer',
						fontSize: '12px',
					}}
					onClick={() => history.goBack()}
				>
					<div
						style={{
							position: 'absolute',
							height: '100%',
							padding: '0 13px',
							top: 0,
							left: 0,
							fontSize: '20px',
							color: '#6C757D',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<i className="bi bi-arrow-left" />
					</div>
					Личный кабинет
				</div>
			)}
			<div
				className={s.profileContainer}
				style={isMobile ? { marginTop: '40px' } : {}}
			>
				{!isMobile && <h1>Личный кабинет</h1>}

				<section className={s.profileSection}>
					<h3>Информация о клиенте</h3>
					<Dropdown.Divider className={'m-0 p-0'} />
					<div className={s.personal}>
						<ChangePersonalInfoBlock isMobile={isMobile} />
						<ChanePhoneBlock />
					</div>
				</section>
				<section className={s.profileSection}>
					<h3>Безопасность</h3>
					<Dropdown.Divider className={'m-0 p-0'} />
					<ChangePasswordBlock />
				</section>
				<OrganizationBlock isMobile={isMobile} />
			</div>
		</>
	)
}

const ChangePersonalInfoBlock = ({ isMobile }) => {
	const [user, userInterface] = useUserData()

	const { addToast } = useContext(ToastsContext)

	const currentFIO = `${user.last_name || ''} ${user.first_name || ''} ${
		user.middle_name || ''
	}`.trim()
	async function handleValidate(values) {
		const errors: any = {}
		const { fio } = values
		const splitted = fio.trim().split(' ')
		if (splitted.length < 2) {
			errors.fio = 'Укажите корретные ФИО'
		}
		return errors
	}
	async function handleSubmit(
		values,
		{ setSubmitting, setFieldValue, setFieldError }
	) {
		const { fio, email } = values
		const splitted = fio.trim().split(' ')
		const body: any = {}
		body.first_name = splitted[1]
		body.last_name = splitted[0]
		body.middle_name = ''
		if (splitted.length > 2) {
			splitted.forEach((el, index) => {
				if (index > 1) {
					body.middle_name += el + ' '
				}
			})
		}
		body.middle_name = body.middle_name.trim()
		await userInterface.changeUserFIO(body)
		if (email !== user?.email) {
			try {
				await userInterface.setEmail(email)
			} catch (e: any) {
				setFieldError('email', e.data?.email?.[0])
				return
			}
		}
		addToast({
			text: 'Ваша персональная информация обновлена',
			type: 'success',
			withAction: false,
		})
		setFieldValue('fio', values.fio.trim())
	}
	return (
		<div className={s.personalInfo}>
			<div
				style={
					isMobile
						? {
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								marginBottom: '16px',
						  }
						: { marginRight: '40px' }
				}
			>
				<svg
					width="80"
					height="80"
					viewBox="0 0 80 80"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<rect width="80" height="80" rx="40" fill="#6C757D" />
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M40 20C34.4772 20 30 24.4772 30 30C30 35.5228 34.4772 40 40 40C45.5228 40 50 35.5228 50 30C50 24.4772 45.5228 20 40 20ZM34 30C34 26.6863 36.6863 24 40 24C43.3137 24 46 26.6863 46 30C46 33.3137 43.3137 36 40 36C36.6863 36 34 33.3137 34 30Z"
						fill="white"
					/>
					<path
						d="M40.0002 42C29.1793 42 23 51.1966 23 60H27C27 52.8034 31.9462 46 40.0002 46C48.0542 46 53 52.8033 53 60H57C57 51.1967 50.8211 42 40.0002 42Z"
						fill="white"
					/>
				</svg>
			</div>
			<Formik
				initialValues={{
					fio: currentFIO,
					email: user?.email,
				}}
				validate={handleValidate}
				onSubmit={handleSubmit}
				validateOnChange={false}
			>
				{({
					values,
					errors,
					handleSubmit,
					setErrors,
					setFieldValue,
				}) => (
					<Form onSubmit={handleSubmit} style={{ width: '100%' }}>
						<Form.Group style={{ marginBottom: '24px' }}>
							<Form.Label>ФИО</Form.Label>
							<Form.Control
								size="lg"
								type={'text'}
								value={values.fio}
								onChange={(e) => {
									setFieldValue('fio', e.target.value)
									setErrors({})
								}}
								isInvalid={!!errors.fio}
								placeholder={'Введите ФИО'}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.fio}
							</Form.Control.Feedback>
						</Form.Group>
						<Form.Group style={{ marginBottom: '24px' }}>
							<Form.Label>Email</Form.Label>
							<Form.Control
								size="lg"
								type={'email'}
								value={values.email}
								onChange={(e) => {
									setFieldValue('email', e.target.value)
									setErrors({})
								}}
								isInvalid={!!errors.email}
							/>
							<Form.Control.Feedback type="invalid">
								{errors.email}
							</Form.Control.Feedback>
						</Form.Group>
						<Button
							type="submit"
							disabled={
								values.fio === currentFIO &&
								values.email === user?.email
							}
						>
							Сохранить изменения
						</Button>
					</Form>
				)}
			</Formik>
		</div>
	)
}

const OrganizationBlock = ({ isMobile }) => {
	const [userCompanies, setUserCompanies] = useState<null | any[]>(null)
	const [industryTypes, setIndustryTypes] = useState<null | any[]>(null)
	const [showModalCreateOrganization, setShowModalCreateOrganization] =
		useState(false)

	useEffect(() => {
		;(async () => {
			await fetchUserCompanies()
		})()
	}, [])
	useEffect(() => {
		;(async () => {
			const types: any[] = await authFetch({
				url: getIndustryTypes,
				method: 'GET',
			})
			setIndustryTypes(types)
		})()
	}, [])

	const fetchUserCompanies = async () => {
		const list: any[] = await authFetch({
			url: companies,
			method: 'GET',
		})
		if (list.length !== 0)
			return setUserCompanies(list.sort((a, b) => (a.id > b.id ? -1 : 1)))
		return setUserCompanies([])
	}

	if (userCompanies === null || industryTypes === null)
		return (
			<div className={'w-100 d-flex justify-content-center'}>
				<Spinner animation={'border'} variant={'primary'} />
			</div>
		)
	if (userCompanies.length === 0) return <></>
	return (
		<>
			<section className={s.profileSection}>
				<div
					className={`d-flex justify-content-between ${
						isMobile && 'flex-column'
					}`}
					style={isMobile ? { marginBottom: '16px' } : {}}
				>
					<h3>Организации</h3>
					<Button
						variant={'outline-primary'}
						style={{ height: 'fit-content', fontWeight: 500 }}
						onClick={() => setShowModalCreateOrganization(true)}
					>
						<i
							className={'bi bi-plus'}
							style={{ fontSize: '1rem', marginRight: '6px' }}
						/>
						Добавить организацию
					</Button>
				</div>
				<Dropdown.Divider className={'m-0 p-0'} />
				{userCompanies &&
					userCompanies.map((company, index) => (
						<React.Fragment key={company.id}>
							<Organization
								data={company}
								industryTypes={industryTypes}
								isMobile={isMobile}
								fetchUserCompanies={fetchUserCompanies}
							/>
							{index !== userCompanies.length - 1 && (
								<Dropdown.Divider className={'m-0 p-0'} />
							)}
						</React.Fragment>
					))}
			</section>
			{showModalCreateOrganization && (
				<CreateOrganizationModal
					isMobile={isMobile}
					show={showModalCreateOrganization}
					onHide={() => setShowModalCreateOrganization(false)}
					fetchUserCompanies={fetchUserCompanies}
					industryTypes={industryTypes}
				/>
			)}
		</>
	)
}

const Organization = ({
	data,
	industryTypes,
	isMobile,
	fetchUserCompanies,
}) => {
	const { confirm } = useConfirm()
	const { addToast } = useContext(ToastsContext)

	async function handleValidate(values) {
		const errors: any = {}
		;['name', 'legal_short_name'].forEach((name) => {
			if (!values[name]) {
				errors[name] = 'Обязательное поле'
			}
		})
		return errors
	}

	async function handleSubmit(values) {
		let formData = new FormData()
		formData.append('legal_short_name', values.legal_short_name)
		formData.append('name', values.name)
		formData.append('industry_type_id', values.industry_type_id)
		try {
			await authFetch({
				url: company.params(data?.id),
				method: 'PATCH',
				body: formData,
			})
		} finally {
			addToast({
				text: 'Информация об организации обновлена',
				type: 'success',
				withAction: false,
			})
			fetchUserCompanies()
		}
	}
	async function handleDeleteOrganization() {
		const answer = await confirm({
			title: 'Вы уверены, что хотите удалить организацию?',
			text: 'После этого действия вы не сможете ее вернуть обратно',
			closeButton: true,
			acceptText: 'Да, уверен',
			declineText: 'Нет, отменить',
		})
		if (answer) {
			try {
				await authFetch({
					url: company.params(data?.id),
					method: 'DELETE',
				})
			} finally {
				addToast({
					text: 'Организация удалена',
					type: 'secondary',
					withAction: false,
				})
				fetchUserCompanies()
			}
		}
	}

	return (
		<Formik
			initialValues={{
				name: data?.name,
				legal_short_name: data?.legal_short_name,
				industry_type_id: industryTypes.find(
					(type) => type.id === data?.industry_type?.id
				)?.id,
			}}
			validate={handleValidate}
			onSubmit={handleSubmit}
			validateOnChange={false}
		>
			{({ values, errors, handleSubmit, setErrors, setFieldValue }) => (
				<Form
					onSubmit={handleSubmit}
					style={{ width: '100%', padding: '24px 0 32px 0' }}
				>
					<Alert
						style={{
							paddingRight: '12px',
							marginBottom: '24px',
							background: '#F5F5F5',
							borderColor: '#F5F5F5',
						}}
						className={'d-flex'}
						variant={'secondary'}
					>
						<i className="bi bi-info-circle-fill me-3" />
						<span>
							Создано рекламных кампаний:{' '}
							{data?.counts?.active +
								data?.counts?.moderation +
								data?.counts?.payment_waiting}
						</span>
					</Alert>
					<Row
						className="g-2"
						style={isMobile ? {} : { gap: '24px' }}
					>
						<Col sm>
							<Form.Group
								style={
									isMobile
										? { marginBottom: '16px' }
										: { marginBottom: '24px' }
								}
							>
								<Form.Label>Название бизнеса</Form.Label>
								<Form.Control
									size="lg"
									type={'text'}
									value={values.name}
									onChange={(e) => {
										setFieldValue('name', e.target.value)
										setErrors({})
									}}
									isInvalid={!!errors.name}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.name}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm>
							<Form.Group
								style={
									isMobile
										? { marginBottom: '16px' }
										: { marginBottom: '24px' }
								}
							>
								<Form.Label>Юридический адрес</Form.Label>
								<Form.Control
									size="lg"
									type={'text'}
									value={data?.actual_location}
									disabled
									style={{ color: '#6C757D' }}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row
						className="g-2"
						style={isMobile ? {} : { gap: '24px' }}
					>
						<Col sm>
							<Form.Group
								style={
									isMobile
										? { marginBottom: '16px' }
										: { marginBottom: '24px' }
								}
							>
								<Form.Label>Организация или ИП</Form.Label>
								<Form.Control
									size="lg"
									type={'text'}
									value={values.legal_short_name}
									onChange={(e) => {
										setFieldValue(
											'legal_short_name',
											e.target.value
										)
										setErrors({})
									}}
									isInvalid={!!errors.legal_short_name}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.legal_short_name}
								</Form.Control.Feedback>
							</Form.Group>
						</Col>
						<Col sm>
							<Form.Group
								style={
									isMobile
										? { marginBottom: '16px' }
										: { marginBottom: '24px' }
								}
							>
								<Form.Label>ИНН</Form.Label>
								<Form.Control
									size="lg"
									type={'text'}
									value={data?.inn}
									disabled
									style={{ color: '#6C757D' }}
								/>
							</Form.Group>
						</Col>
					</Row>
					<Row
						className="g-2"
						style={isMobile ? {} : { gap: '24px' }}
					>
						<Col sm>
							<Form.Group
								style={
									isMobile
										? { marginBottom: '16px' }
										: { marginBottom: '24px' }
								}
							>
								<Form.Label>Вид деятельности</Form.Label>
								<Form.Select
									className={s.select}
									value={values.industry_type_id}
									onChange={(ev) => {
										setFieldValue(
											'industry_type_id',
											parseInt(ev.target.value)
										)
										setErrors({})
									}}
									size={'lg'}
								>
									{industryTypes.map((el, index) => (
										<option key={el.id} value={el.id}>
											{el.name}
										</option>
									))}
								</Form.Select>
							</Form.Group>
						</Col>
						<Col sm>
							<Form.Group
								style={
									isMobile
										? { marginBottom: '16px' }
										: { marginBottom: '24px' }
								}
							>
								<Form.Label>КПП</Form.Label>
								<Form.Control
									size="lg"
									type={'text'}
									value={data?.kpp}
									disabled
									style={{ color: '#6C757D' }}
								/>
							</Form.Group>
						</Col>
					</Row>
					<div className={'d-flex justify-content-between w-100'}>
						<Button
							type="submit"
							disabled={
								values.name === data?.name &&
								values.legal_short_name ===
									data?.legal_short_name &&
								values.industry_type_id ===
									data?.industry_type?.id
							}
						>
							Сохранить изменения
						</Button>
						<div>
							<OverlayTrigger
								placement="left"
								overlay={(props) => (
									<Tooltip id="delete_placement" {...props}>
										Удалить организацию
									</Tooltip>
								)}
							>
								<div
									className={s.trash}
									onClick={handleDeleteOrganization}
								>
									<i className={'bi bi-trash'} />
								</div>
							</OverlayTrigger>
						</div>
					</div>
				</Form>
			)}
		</Formik>
	)
}

const CreateOrganizationModal = ({
	isMobile,
	show,
	onHide,
	industryTypes,
	fetchUserCompanies,
}) => {
	const { addToast } = useContext(ToastsContext)

	async function handleSubmit(values) {
		const {
			legal_name,
			inn,
			kpp,
			name,
			industry_type_id,
			actual_location,
			actual_location_lat,
			actual_location_lon,
			legal_short_name,
		} = values
		let formData = new FormData()
		formData.append('legal_name', legal_name)
		formData.append('legal_short_name', legal_short_name)
		formData.append('inn', inn)
		if (kpp) {
			formData.append('kpp', kpp)
		}
		formData.append('name', name)
		formData.append('industry_type_id', industry_type_id)
		formData.append('actual_location', actual_location)
		formData.append('actual_location_lat', actual_location_lat)
		formData.append('actual_location_lon', actual_location_lon)
		try {
			await authFetch({
				url: companies,
				method: 'POST',
				body: formData,
			})
			await yandexTracking.reachGoal('create-new-company')
		} finally {
			fetchUserCompanies()
			onHide()
			addToast({
				text: 'Организация добавлена',
				type: 'success',
				withAction: false,
			})
		}
	}

	function handleValidate(values) {
		const errors: any = {}
		;['industry_type_id', 'name'].forEach((name) => {
			if (!values[name]) {
				errors[name] = 'Обязательное поле'
			}
		})
		;['legal_name', 'inn'].forEach((name) => {
			if (!values[name]) {
				errors.legal_name = 'Выберите организацию из выпадающего списка'
			}
		})
		;[
			'actual_location',
			'actual_location_lat',
			'actual_location_lon',
		].forEach((name) => {
			if (!values[name]) {
				errors.actual_location = 'Выберите адрес из выпадающего списка'
			}
		})
		return errors
	}

	return (
		<Modal
			show={show}
			onHide={onHide}
			animation={false}
			centered
			backdrop="static"
			keyboard={false}
		>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'}>Добавление организации</Modal.Title>
			</Modal.Header>

			<Formik
				initialValues={{
					name: '',
					industry_type_id: industryTypes?.[0].id,
					legal_name: '',
					legal_short_name: '',
					actual_location: '',
					actual_location_lat: '',
					actual_location_lon: '',
					inn: '',
					kpp: '',
				}}
				onSubmit={handleSubmit}
				enableReinitialize={true}
				validate={handleValidate}
				validateOnChange={false}
			>
				{({
					setFieldValue,
					values,
					handleSubmit,
					errors,
					setFieldError,
				}: any) => (
					<Form onSubmit={handleSubmit}>
						<ScrollToError />
						<Modal.Body style={{ padding: '24px' }}>
							<InputGroup hasValidation>
								<Form.Label>Название бизнеса</Form.Label>
								<Form.Control
									size="lg"
									type={'text'}
									value={values.name}
									name={'name'}
									onChange={(e) => {
										setFieldError('name', null)
										setFieldValue('name', e.target.value)
									}}
									isInvalid={!!errors.name}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.name}
								</Form.Control.Feedback>
							</InputGroup>
							<InputGroup hasValidation>
								<Form.Label>Вид деятельности</Form.Label>
								<Form.Select
									name={'industry_type_id'}
									size="lg"
									onChange={(e: any) => {
										setFieldError('industry_type_id', null)
										setFieldValue(
											'industry_type_id',
											e.target.value
										)
									}}
									isInvalid={!!errors.industry_type_id}
								>
									{industryTypes &&
										industryTypes.map(({ id, name }) => (
											<option key={id} value={id}>
												{name}
											</option>
										))}
								</Form.Select>
								<Form.Control.Feedback type="invalid">
									{errors.industry_type_id}
								</Form.Control.Feedback>
							</InputGroup>
							<InputGroup hasValidation>
								<Form.Label>Организация или ИП</Form.Label>
								<AutocompleteInput
									value={values.legal_short_name}
									onChange={(e) => {
										setFieldError('legal_short_name', null)
										setFieldValue(
											'legal_short_name',
											e.target.value
										)
									}}
									onSelect={(data) => {
										setFieldError('inn', null)
										setFieldError('kpp', null)
										setFieldError('legal_short_name', null)
										setFieldError('legal_name', null)
										setFieldValue('inn', data.inn)
										setFieldValue('kpp', data.kpp)
										setFieldValue(
											'legal_short_name',
											data.legal_short_name
										)
										setFieldValue(
											'legal_name',
											data.legal_name
										)
									}}
									error={errors.inn || errors.legal_name}
									type={'company'}
									name={'legal_name'}
								/>
							</InputGroup>
							{!!values.inn && (
								<>
									<InputGroup hasValidation>
										<Form.Label>
											Полное название организации
										</Form.Label>
										<Form.Control
											value={values.legal_name}
											disabled={true}
											size="lg"
											type={'text'}
										/>
									</InputGroup>
									<Row>
										<Col>
											<InputGroup hasValidation>
												<Form.Label>ИНН</Form.Label>
												<Form.Control
													value={values.inn}
													disabled={true}
													size="lg"
													type={'text'}
												/>
											</InputGroup>
										</Col>
										<Col>
											<InputGroup hasValidation>
												<Form.Label>КПП</Form.Label>
												<Form.Control
													value={values.kpp}
													disabled={true}
													size="lg"
													type={'text'}
												/>
											</InputGroup>
										</Col>
									</Row>
								</>
							)}
							<InputGroup hasValidation>
								<Form.Label>
									Фактический адрес вашего бизнеса
								</Form.Label>
								<AutocompleteInput
									value={values.actual_location}
									onChange={(e) => {
										setFieldError('actual_location', null)
										setFieldValue(
											'actual_location',
											e.target.value
										)
									}}
									onSelect={(data) => {
										setFieldValue(
											'actual_location',
											data.address
										)
										setFieldValue(
											'actual_location_lat',
											data.lat
										)
										setFieldValue(
											'actual_location_lon',
											data.lon
										)
									}}
									error={errors.actual_location}
									type={'address'}
									name={'actual_location'}
								/>
							</InputGroup>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={onHide}>
								Отменить
							</Button>
							<Button variant="primary" type={'submit'}>
								Добавить
							</Button>
						</Modal.Footer>
					</Form>
				)}
			</Formik>
		</Modal>
	)
}

export default withPage({ noSidebar: false, isProvider: false })(
	ClientProfilePage
)
