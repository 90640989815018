import React from 'react'

import { Accordion } from 'react-bootstrap'
import s from './accordions.module.scss'

type Props = {
	header: string
	children?: React.ReactNode
}
export const BaseAccordion = ({ header, children }: Props) => {
	return (
		<Accordion className={s.accordion}>
			<Accordion.Item eventKey="0" className={s.accordionItem}>
				<Accordion.Header as={'div'} className={s.accordionHeader}>
					<h6>{header}</h6>
				</Accordion.Header>

				<Accordion.Body style={{ padding: '0' }}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						{children}{' '}
					</div>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	)
}
