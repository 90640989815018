import { AdFormatError, AdFormatKeys, AdformatsStore } from '../adformats.store'

export const getAdformatsErrorsState = (
	state: AdformatsStore
): Record<AdFormatKeys, AdFormatError[] | null> => {
	return {
		massCoverage: state.massCoverage.errors,
		garant: state.garant.errors,
		mcr: state.mcr.errors,
		mcd: state.mcd.errors,
		yandex: state.yandex.errors,
		adstream: state.adstream.errors,
		indoorAd: state.indoorAd.errors,
		vk: state.vk.errors,
	}
}
