export const getError = (e: any) => {
	const str = JSON.stringify(e)
	try {
		const error = JSON.parse(str)
		return error
	} catch {
		return {
			data: {
				message: 'undefined error',
			},
		}
	}
}
