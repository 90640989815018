import React, { FC, useMemo } from 'react'
import { Form } from 'react-bootstrap'
import TwoValuesRange from '../../../UtilityComponents/TwoValuesRange/TwoValuesRange'
import AdMarketSelect from 'src/components/UtilityComponents/ADMarketSelect/AdMarketSelect'
import { ActionMeta, GroupBase, SingleValue } from 'react-select'
import useCampaignData from 'src/stores/campaignData'

interface IWatchTimeSelector {
	start_time: string
	end_time: string
	setFieldValue: (name: string, value: string) => null
	setFieldError: (name: string, error: any) => null
	errors: {}
}

type AdDisplayTime =
	| '06-12'
	| '09-15'
	| '09-15'
	| '12-18'
	| '15-21'
	| '18-00'
	| '06-21'
	| '00-23'

type AdDisplayTimeOptionType = {
	label: string
	value: AdDisplayTime
}

const adDisplayTimeOptions: Array<AdDisplayTimeOptionType> = [
	{ label: '6.00 - 12.00', value: '06-12' },
	{ label: '9.00 - 15.00', value: '09-15' },
	{ label: '12.00 - 18.00', value: '12-18' },
	{ label: '15.00 - 21.00', value: '15-21' },
	{ label: '18.00 - 00.00', value: '18-00' },
	{ label: 'День 6:00 - 21:00', value: '06-21' },
	{ label: 'Круглосуточно', value: '00-23' },
]

export const DropdownIndicator = () => {
	return (
		<div
			style={{
				position: 'absolute',
				top: 'calc(50% + 2)',
				right: 16,
			}}
		>
			<svg
				width="10"
				height="5"
				viewBox="0 0 10 5"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M10 0H0L5 5L10 0Z" fill="#495057" />
			</svg>
		</div>
	)
}

export const WatchTimeSelect: FC<IWatchTimeSelector> = ({
	end_time,
	start_time,
	setFieldValue,
}) => {
	const optionKey = `${start_time}-${end_time}`

	const defaultOption = useMemo((): AdDisplayTimeOptionType => {
		const option = adDisplayTimeOptions.find(
			(option) => option.value === optionKey
		)

		return option || adDisplayTimeOptions[adDisplayTimeOptions.length - 1]
	}, [end_time, start_time])

	const handleSelectChange = (newValue: SingleValue<any>) => {
		const currentValue = newValue.value as AdDisplayTime

		const splittedValue = currentValue.split('-')

		setFieldValue('start_time', splittedValue[0])
		setFieldValue('end_time', splittedValue[1])
	}

	return (
		<Form.Group className={'mt-4'}>
			<Form.Label>Время показа рекламы</Form.Label>
			<AdMarketSelect
				defaultValue={defaultOption.value}
				placeholder={defaultOption.label}
				components={{ DropdownIndicator }}
				options={adDisplayTimeOptions as any}
				onChange={handleSelectChange}
			/>
		</Form.Group>
	)
}
export default WatchTimeSelect
