import { Formik } from 'formik'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Form, InputGroup, Modal, Spinner } from 'react-bootstrap'
import AdMarketMultiplyDropdown from 'src/components/UtilityComponents/ADMarketMultiplyDropdown/ADMarketMultiplyDropdown'
import FileInput from 'src/components/UtilityComponents/FileInput'
import { ScrollToError } from 'src/components/UtilityComponents/ScrollToFormikError'
import useCampaignData from 'src/stores/campaignData'
import {
	ChannelSectionType,
	fetchAdstreamInfo,
} from '../../../create-campaign/Step2-ADformats/TVAd/TVvideo'
import { spreadArray } from 'src/components/_provider/Mediaformats/MediaInfo'
import useDebounce from 'src/utils/useDebounce'
import { DocumentsUploadForm } from '../../internet/DocumentsUploadForm'
import { BookingState } from 'src/stores/ADMarketTypes.types'
import { EditVinstantInputButton } from '../../common/edit-vinstant-input-button/EditVinstantInputButton'
import { EditVinstantProcessingWrapped } from '../../common/edit-vinstant-processing/EditVinstantProcessing'

export interface IEditTvModal {
	show: boolean
	handleClose: () => void
	onEditVinstantClick: () => void
	adstreamVinstantLink: string | null
	adstreamVinstantEditStatus: string | undefined
}

const EditTvModall: FC<IEditTvModal> = ({
	show,
	handleClose,
	onEditVinstantClick,
	adstreamVinstantLink,
	adstreamVinstantEditStatus,
}) => {
	const [loading, setLoading] = useState(false)
	const [campaign, campaignInterface] = useCampaignData()
	const [variants, setVariants] = useState<null | {
		sections: ChannelSectionType[]
	}>(null)

	const [formValues, setFormValues] = useState<{} | null>(null)
	const debouncedFormValues = useDebounce(formValues, 1000)
	const setFieldErrorRef = useRef()

	//Загрузка всех списков
	useEffect(() => {
		;(async () => {
			const res = await fetchAdstreamInfo()
			setVariants(res)
		})()
	}, [])

	useEffect(() => {
		if (!debouncedFormValues) return
		saveFilters(debouncedFormValues, setFieldErrorRef.current).then()
	}, [debouncedFormValues])

	async function saveFilters(values, setFieldError) {
		try {
			await campaignInterface.saveVideoFilters(campaign?.id, {
				selection_ids: formatChannels(values),
				...values,
			})
		} catch (error: any) {
			if (error && setFieldError) {
				for (const [key, value] of Object.entries(error)) {
					setFieldError(key as any, value as any)
				}
			}
		}
	}

	function formatChannels(values) {
		return getIDFromOtherArray(
			values.channels,
			variants?.sections || [],
			'name'
		)
	}

	const initialValues = useMemo<{
		channels: string[]
		image: any
	}>(() => {
		const c: string[] = []
		campaign.adstream.selection_ids.forEach((id) => {
			const el = variants?.sections.find((s) => s.id === id)
			if (el) c.push(el.name)
		})
		return {
			channels: c,
			image: {
				src: campaign.adstream.image,
				name: 'Текущее видео',
			},
		}
	}, [campaign.adstream, variants])

	async function handleSubmit(initialValues, { setErrors }) {
		const fd = new FormData()
		const copy: { [key: string]: any; image?: File } = { ...initialValues }
		delete copy.image
		delete copy.channels

		for (let key in copy) {
			fd.append(key, copy[key])
		}

		if (
			initialValues.image !== undefined &&
			(initialValues.image as File) instanceof File
		) {
			fd.append('image', initialValues.image as File)
		}
		if (initialValues.channels && initialValues.channels.length > 0) {
			fd.append('channels', initialValues.channels.join(','))
		}
		const nextAdsreamState =
			campaign.state === 'moderation'
				? BookingState.Inner_Moderation
				: BookingState.Moderation
		try {
			setLoading(true)
			await campaignInterface.saveVideoFilters(campaign.id, fd)
			await campaignInterface.updateAdstream(
				campaign.id,
				nextAdsreamState
			)
			handleClose()
		} catch (e: any) {
			const errors = {}
			for (const k in e) {
				errors[k] = e?.[k][0]
			}
			setErrors(errors)
		} finally {
			setLoading(false)
		}
	}
	function handleValidation() {}

	return (
		<Modal show={show} onHide={handleClose} backdrop={'static'}>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} style={{ marginLeft: '8px' }}>
					Исправление AdStream
				</Modal.Title>
			</Modal.Header>
			<Formik
				initialValues={initialValues}
				onSubmit={handleSubmit}
				validate={handleValidation}
				validateOnChange={false}
			>
				{({
					values,
					setFieldValue,
					handleSubmit,
					errors,
					setFieldError,
				}: any) => (
					<>
						<Modal.Body style={{ padding: '24px' }}>
							<Form className={'d-flex flex-column gap-2'}>
								<ScrollToError />
								<InputGroup
									hasValidation
									style={{ marginBottom: '24px' }}
								>
									<Form.Label>Каналы для показа</Form.Label>
									<AdMarketMultiplyDropdown
										list={
											variants?.sections.map(
												(el) => el?.name
											) || []
										}
										selected={values.channels}
										error={errors.channels}
										onSelectedChange={(channels) => {
											setFieldError('channels', null)
											setFieldValue('channels', channels)
										}}
										additionalInfo={variants?.sections.map(
											(el) => {
												return {
													title: el.name,
													info: GenerateChannelSectionDescription(
														el
													),
												}
											}
										)}
										withSeparator
									/>
								</InputGroup>

								{adstreamVinstantEditStatus === 'start' ? (
									<EditVinstantProcessingWrapped />
								) : (
									<Form.Group>
										<Form.Label>
											Загрузка изображения
										</Form.Label>
										<FileInput
											ExtraButton={
												adstreamVinstantLink ? (
													<EditVinstantInputButton
														onClick={
															onEditVinstantClick
														}
													/>
												) : null
											}
											error={errors.image}
											onUpload={(file) => {
												setFieldValue('image', file)
												setFieldError('image', null)
											}}
											value={values.image}
											onRemove={() => {
												setFieldValue('image', null)
												setFieldError('image', null)
											}}
											size={15}
											allowedExtensions={['MP4']}
											descr={
												'1920 × 1080 px, MP4, наличие звуковой дорожки, длительность от 5 до 30 секунд, не более 15 МБ'
											}
										/>
									</Form.Group>
								)}

								{campaign.adstream?.is_docs_required && (
									<DocumentsUploadForm
										adFormatId={campaign.adstream.id}
										type={'adstream'}
										campaignId={campaign.id}
										title="Загрузка документов"
									/>
								)}
							</Form>
						</Modal.Body>
						<Modal.Footer>
							<Button variant="secondary" onClick={handleClose}>
								Отменить
							</Button>
							<Button
								variant="primary"
								onClick={handleSubmit}
								type={'submit'}
								disabled={
									withErrors(errors) ||
									loading ||
									!values.image ||
									!values.channels
								}
								className="d-flex gap-2 align-items-center"
							>
								Отправить на модерацию
								{loading && (
									<Spinner
										variant="text"
										animation="border"
										size="sm"
									/>
								)}
							</Button>
						</Modal.Footer>
					</>
				)}
			</Formik>
		</Modal>
	)
}

const withErrors = (errors: Object): boolean => {
	if (errors === null) return false
	if (Object.keys(errors).length === 0) return false
	let not_null = false
	for (const [, value] of Object.entries(errors)) {
		if (value !== null) not_null = true
	}
	return not_null ? true : false
}

const channelsToDisplay = 5

function GenerateChannelSectionDescription(
	section: ChannelSectionType
): string {
	const { channels, channels_count } = section

	if (channels_count <= channelsToDisplay)
		return `${spreadArray(channels.map((ch) => ch.name))}.`
	return `${spreadArray(
		channels.slice(0, channelsToDisplay).map((ch) => ch.name)
	)} + еще ${channels_count - channelsToDisplay} других.`
}

function getIDFromOtherArray(
	arr1: string[],
	arr2: any[],
	field_name_in_arr2: string
): number[] {
	const ids: number[] = []
	arr1.forEach((value) => {
		const id = arr2.find((el) => el[field_name_in_arr2] === value)?.id
		ids.push(id)
	})
	return ids
}

export default EditTvModall
