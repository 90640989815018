import { TechnicalRequirementsStore } from '../technicalRequirements.store'
import {
	DistrictBookingItem,
	DistrictBookingMediaFormat,
	UniqDistrictBookingFormat,
} from '../types'

export const getUniqDistrictsFormats = (
	store: TechnicalRequirementsStore
): UniqDistrictBookingFormat[] => {
	const allDistrictsFormats = store.district.flatMap((d) => d.advFormats)
	const uniqFormatsIds = new Set(allDistrictsFormats.map((el) => el.id))

	const uniqFormats = [] as UniqDistrictBookingFormat[]

	for (const formatId of uniqFormatsIds) {
		const allFormatPlacements = allDistrictsFormats.flatMap((f) =>
			f.id === formatId ? f.placements : []
		)

		const format = allDistrictsFormats.find(
			(f) => f.id === formatId
		) as DistrictBookingMediaFormat

		const delegates = store.district.filter((d) =>
			d.advFormats.find((f) => f.id === formatId)
		)

		const allDistrictsBookingsIdsInThisFormat = delegates.map((el) => el.id)

		const minId = Math.min(...allDistrictsBookingsIdsInThisFormat)

		const delegate = delegates.find(
			(delegate) => delegate.id === minId
		) as DistrictBookingItem
		uniqFormats.push({
			placements: allFormatPlacements,
			external_id: format.external_id,
			id: format.id,
			media: format.media,
			media_requirements: format.media_requirements,
			media_type_name: format.media_type_name,
			delegate: {
				id: delegate.id,
				city_id: delegate.city_id,
				city_name: delegate.city_name,
			},
			districtBookingsIds: allDistrictsBookingsIdsInThisFormat,
		})
	}
	return uniqFormats
}
