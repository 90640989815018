export const ageGroups = {
	zero: '0+',
	six: '6+',
	twelve: '12+',
	sixteen: '16+',
	eighteen: '18+',
} as const

export type AgeGroupsKeys = keyof typeof ageGroups
export type AgeGroupsValues = typeof ageGroups[AgeGroupsKeys]
