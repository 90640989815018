import React, { FC, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

type contextFunction = (
	setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
) => React.ReactNode | React.ReactNode[]

interface IPickerDesktopRightPannel {
	children?: React.ReactNode | React.ReactNode[] | contextFunction
	count: number
	isMobile: boolean
}

const PickerDesktopRightPannel: FC<IPickerDesktopRightPannel> = ({
	children,
	count,
	isMobile,
}) => {
	const [isExpanded, setIsExpanded] = useState(false)
	return (
		<div
			style={{
				width: isMobile ? '100%' : '360px',
				maxWidth: isMobile ? '100%' : '360px',
				transition: 'transform 0.3s ease-in-out',
				position: 'absolute',
				zIndex: isMobile ? 10000 : 2,
				backgroundColor: '#fff',
				height: 'calc(100vh - 64px)',
				transform: `translateX(${
					isExpanded ? 0 : isMobile ? '100%' : 360 + 'px'
				})`,
				right: 0,
				top: 0,
			}}
		>
			<CartIcon
				isMobile={isMobile}
				isExpanded={isExpanded}
				setIsExpanded={setIsExpanded}
				count={count}
			/>

			{typeof children === 'function'
				? children(setIsExpanded)
				: children}
		</div>
	)
}
export default PickerDesktopRightPannel

export const CartIcon = ({ isMobile, isExpanded, setIsExpanded, count }) => (
	<OverlayTrigger
		placement="left"
		overlay={(props) =>
			isMobile ? (
				<></>
			) : (
				<Tooltip id="expand" {...props}>
					{isExpanded ? 'Свернуть' : 'Выбранные поверхности'}
				</Tooltip>
			)
		}
	>
		<div
			style={{
				position: 'absolute',
				top: '24px',
				left: isMobile ? '' : '-68px',
				right: isMobile ? '24px' : '',
				zIndex: 2,
				borderRadius: '50%',
				backgroundColor: '#fff',
				width: '48px',
				height: '48px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				cursor: 'pointer',
			}}
			onClick={() => setIsExpanded((p) => !p)}
		>
			<i
				style={{
					transition: 'transform .5s ease-in-out',
					// width: "24px",
				}}
			>
				{!isExpanded ? (
					<svg
						width="18"
						height="16"
						viewBox="0 0 18 16"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M2 8V2H16V8H10H8H2ZM8 10H1C0.447716 10 0 9.55229 0 9V1C0 0.447716 0.447715 0 1 0H17C17.5523 0 18 0.447715 18 1V9C18 9.55228 17.5523 10 17 10H10V16H8V10Z"
							fill="#212529"
						/>
					</svg>
				) : (
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						style={{ transform: 'rotate(-180deg)' }}
					>
						<path
							d="M3.4938 8.00012L9.34743 2.42896L7.96861 0.980225L0.310587 8.2687C-0.103212 8.66253 -0.103578 9.32238 0.309783 9.71667L7.96781 17.0214L9.34824 15.5742L3.50459 10.0001L19.9999 10.0012L20.0001 8.00118L11.7469 8.00065L3.4938 8.00012Z"
							fill="#212529"
						/>
					</svg>
				)}
			</i>
			{!isExpanded && (
				<div
					style={{
						position: 'absolute',
						width: '22px',
						height: '22px',
						backgroundColor: '#007BFF',
						borderRadius: '50%',
						color: '#fff',
						top: -3,
						textAlign: 'center',
						fontSize: '12px',
						paddingTop: '2px',
						right: 0,
					}}
				>
					{count}
				</div>
			)}
		</div>
	</OverlayTrigger>
)
