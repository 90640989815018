import React from 'react'
import { ReactComponent as PDF } from '../../../../assets/images/pdf.svg'
import { ReactComponent as Download } from '../../../../assets/images/download.svg'
import { ReportDocType, getFileTypeTitle } from './DocumentsModal'
import s from './documentsModal.module.scss'

type Props = {
	item: ReportDocType
	downloadDoc: (doc: ReportDocType) => void
}
export const ReportDocument = ({ item, downloadDoc }: Props) => {
	return (
		<div
			onClick={() => downloadDoc(item)}
			key={item?.id}
			className={s.reportDocument}
		>
			<div>
				<PDF />
				<span>{getFileTypeTitle(item?.file_type) || 'Документ'}</span>
			</div>
			<div>
				<Download />
				<p style={{ margin: 0 }}>Выгрузить</p>
			</div>
		</div>
	)
}
