import React from 'react'
import { ReactComponent as AdstreamIcon } from 'src/assets/images/adstream.svg'
import { ReactComponent as TeletargetIcon } from 'src/assets/images/teletarget.svg'
import { ReactComponent as Ok } from 'src/assets/images/ok.svg'
import { TVVariants } from '../../../../../../../types'
import cn from 'classnames'
import s from './tvSelector.module.scss'

type Props = {
	title: string
	selectorKey: TVVariants
	isSelected: boolean
	description: string
	extraInfo: string
	onClick: (variant: TVVariants) => void
	disabled
	isLoading: boolean
}

export const TvVariantSelector = ({
	description,
	isSelected,
	onClick,
	selectorKey,
	title,
	disabled,
	extraInfo,
	isLoading,
}: Props) => {
	const handleSelectorClick = () => {
		if (disabled) return
		onClick(selectorKey)
	}
	const VariantIcon =
		selectorKey === TVVariants.Adstream ? AdstreamIcon : TeletargetIcon
	return (
		<div
			className={cn({
				[s.format_selected]: isSelected,
				[s.format]: !isSelected,
				[s.format_disabled]: disabled || isLoading,
			})}
			onClick={handleSelectorClick}
		>
			<div className={cn(s.icon)}>
				{isSelected ? <Ok /> : <VariantIcon />}
			</div>
			<div className={s.title}>
				<p>{description}</p>
				<p>{extraInfo}</p>
			</div>
		</div>
	)
}
