import React from 'react'
import {
	AdvItemState,
	PlacementViewModel,
} from 'src/stores/technical-requirements-store'
import s from './garantidItem.module.scss'
import { RenderPreview } from 'src/components/UtilityComponents/ProgressFileInput'
import { Button, Dropdown, Form } from 'react-bootstrap'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { GarantidItemMenu } from './GarantidItemMenu'
import { ReactComponent as EditIcon } from 'src/assets/images/edit.svg'
import { GarantidItemModal } from './GarantidCampaignItem'
import cn from 'classnames'
import { ConditionArrowLeftRight } from 'src/components/UtilityComponents'
import useResponsive from 'src/utils/useResponsive'

const CustomToggle = React.forwardRef(
	(
		{
			children,
			onClick,
		}: {
			children: React.ReactChildren
			onClick: (
				e: React.MouseEvent<HTMLButtonElement, MouseEvent>
			) => void
		},
		ref: React.Ref<HTMLButtonElement>
	) => (
		<Button
			ref={ref}
			onClick={(e) => {
				e.preventDefault
				onClick(e)
			}}
			variant="light"
		>
			{children}
		</Button>
	)
)
CustomToggle.displayName = 'CustomToggle'

const CustomMenu = React.forwardRef(
	(
		{
			children,
			style,
			className,
			'aria-labelledby': labeledBy,
		}: {
			children: React.ReactChildren
			style: React.CSSProperties
			className: string
			'aria-labelledby': string
		},
		ref: React.Ref<HTMLDivElement>
	) => {
		return (
			<div
				ref={ref}
				style={style}
				className={className}
				aria-labelledby={labeledBy}
			>
				{children}
			</div>
		)
	}
)

CustomMenu.displayName = 'CustomMenu'

const editableStates = [AdvItemState.Adjustment]
const removableStates = [AdvItemState.Adjustment, AdvItemState.Inner_Moderation]

type GarandidCampaignItemHeaderProps = {
	placement: PlacementViewModel
	budget: string
	state: AdvItemState
	setModal: (modalType: GarantidItemModal) => void
	onRemove: () => void
}
export const GarandidCampaignItemHeader = ({
	placement,
	budget,
	state,
	setModal,
	onRemove,
}: GarandidCampaignItemHeaderProps) => {
	const { isMobile } = useResponsive()
	const editable = editableStates.includes(state)
	const removable = removableStates.includes(state)
	return (
		<div className={s.garandidCampaignItemHeader}>
			<div className={s.garandidCampaignItemPlacement}>
				<PlacementPreview
					address={placement.address}
					image={placement.image}
					name={placement.name}
				/>
				<h5 className="m-0">{IntlFormatter.format(budget)}</h5>
			</div>

			<GarantidItemMenu mobileViewClassName={s.garandidItemMenuMobile}>
				<Dropdown.Item
					eventKey="1"
					className={cn(
						s.garantidmMenuItem,
						s.garandidCampaignMenuItem_bordered
					)}
					onClick={() => setModal('placementInfo')}
					mobileAutoClose={true}
				>
					<i className="bi bi-info-circle"></i>{' '}
					<p>Подробнее о месте</p>
					{isMobile && (
						<ConditionArrowLeftRight
							open={false}
							styles={{ display: 'block' }}
						/>
					)}
				</Dropdown.Item>
				<Dropdown.Item
					eventKey="2"
					className={cn(s.garantidmMenuItem, {
						[s.garandidCampaignMenuItem_bordered]: editable,
					})}
					onClick={() => setModal('requirements')}
					mobileAutoClose={true}
				>
					<i className="bi bi-question-circle"></i>
					<p>Требования к загрузке</p>
					{isMobile && (
						<ConditionArrowLeftRight
							open={false}
							styles={{ display: 'block' }}
						/>
					)}
				</Dropdown.Item>
				{editable && (
					<Dropdown.Item
						eventKey="3"
						className={s.garantidmMenuItem}
						onClick={() => setModal('edit')}
						mobileAutoClose={true}
					>
						<EditIcon />
						<p>Исправить</p>
						{isMobile && (
							<ConditionArrowLeftRight
								open={false}
								styles={{ display: 'block' }}
							/>
						)}
					</Dropdown.Item>
				)}
				{removable && (
					<Dropdown.Item
						eventKey="4"
						className={cn(s.garantidmMenuItem, [
							s.garandidCampaignMenuItem_delete,
						])}
						onClick={onRemove}
						mobileAutoClose={true}
					>
						<i className="bi bi-trash"></i>
						<p>Удалить размещение</p>
					</Dropdown.Item>
				)}
			</GarantidItemMenu>
		</div>
	)
}

type PlacementPreviewProps = {
	name: string
	image: string
	address: string
	styles?: React.CSSProperties
}
export const PlacementPreview = ({
	image,
	name,
	address,
	styles,
}: PlacementPreviewProps) => {
	return (
		<div className={s.garandidCampaignPlacementInfo} style={styles}>
			<RenderPreview
				style={{
					height: '80px',
					width: '80px',
					borderRadius: '12px',
				}}
				value={{
					name: name,
					src: image,
				}}
				type="image"
			/>
			<div className={s.placementAddress}>
				<h5>{name}</h5>
				<h5>{address}</h5>
			</div>
		</div>
	)
}
