import { NewContentCreatedKeysType } from '../types/newContendCreatedType'
import {
	ContentCreateEndType,
	ContentCreatedStartType,
} from '../types/webSoketMessageType'

export const newContentStartAlerts: Record<
	ContentCreatedStartType,
	NewContentCreatedKeysType
> = {
	import_creative_start_adstream: 'adstream',
	import_creative_start_booking: 'booking',
	import_creative_start_vk: 'vk',
	import_creative_start_yandex: 'yandex',
	import_creative_start_districtbooking: 'districtbooking',
	import_creative_start_public_transport: 'publicTransport',
}

export const newContentEndAlerts: Record<
	ContentCreateEndType,
	NewContentCreatedKeysType
> = {
	import_creative_end_adstream: 'adstream',
	import_creative_end_booking: 'booking',
	import_creative_end_vk: 'vk',
	import_creative_end_yandex: 'yandex',
	import_creative_end_districtbooking: 'districtbooking',
	import_creative_end_public_transport: 'publicTransport',
}
