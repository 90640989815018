import React, { FC, useMemo, useState } from 'react'
import s from '../Bookings.module.scss'
import BookingsSearch from './BookingsSearch'
import FilterButtons from './FilterButtons'
import { useBookingsContext } from '../context/BookingsProvider'
import ModalSetFilters from './ModalSetFilters'
import CurrentFilters from './CurrentFilters'

const BookingsFilters: FC = () => {
	const [showModal, setShowModal] = useState<boolean | string>(false)
	const [showFilters, setShowFilters] = useState(true)
	const [hideMapButton, setHideMapButton] = useState(true)

	const { filterByCompany, filterByAd } = useBookingsContext()
	const filterCountLabel = useMemo(
		() => filterByAd.length + filterByCompany.length,
		[filterByAd, filterByCompany]
	)
	return (
		<>
			<div className={s.filterContainer}>
				<div className={'d-flex justify-content-between w-100'}>
					<BookingsSearch />
					<FilterButtons
						openFilterModal={() => {
							if (filterCountLabel === 0 || showFilters)
								return setShowModal('Кампании')
							setShowFilters(true)
						}}
						hideMapButton={hideMapButton}
					/>
				</div>

				<CurrentFilters
					show={showFilters}
					openFilterModal={setShowModal}
					onHide={() => setShowFilters(false)}
				/>
				{showModal && (
					<ModalSetFilters
						show={showModal}
						onHide={() => setShowModal(false)}
					/>
				)}
			</div>
		</>
	)
}

export default BookingsFilters
