export const determineFileType = (
	fileName: string
): 'image' | 'video' | 'unknown' => {
	const allowedImageExtensions = ['jpg', 'png']
	const allowedVideoExtensions = ['mp4']

	const extension = fileName.split('.').pop()?.toLowerCase() || ''

	if (allowedImageExtensions.includes(extension)) {
		return 'image'
	}
	if (allowedVideoExtensions.includes(extension)) {
		return 'video'
	}

	return 'unknown'
}
