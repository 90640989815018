import React from 'react'
import { ReactComponent as BackIcon } from '../../../../assets/images/back.svg'

type BackButtonProps = {
	onClick: () => void
}

export const BackButton = ({ onClick }: BackButtonProps) => {
	return (
		<button
			style={{
				display: 'flex',
				alignItems: 'center',
				border: 'none',
				justifyContent: 'space-between',
				padding: 0,
				background: 'none',
			}}
			onClick={onClick}
		>
			<BackIcon style={{ margin: 0 }} />
			<div
				style={{
					marginLeft: '9px',
					fontFamily: 'Inter',
					fontSize: '16px',
					fontWeight: 400,
					lineHeight: '24px',
					letterSpacing: '-0.03em',
					textAlign: 'left',
					color: '#6C757D',
				}}
			>
				Назад
			</div>
		</button>
	)
}
