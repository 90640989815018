import React, { FC } from 'react'
import { BookingState } from '../../../../../stores/ADMarketTypes.types'
import s from '../../Bookings.module.scss'

interface IBookingIcon {
	state: BookingState
	image: string
	size?: number
}

const BookingIcon: FC<IBookingIcon> = ({ state, size = 40, image }) => {
	const sizePx = size + 'px'
	return (
		<div
			className={s.bookingPlacementTypeImage}
			dangerouslySetInnerHTML={{ __html: image }}
			style={{
				backgroundColor: GetBackgroundColorByState(state),
				width: sizePx,
				height: sizePx,
			}}
		/>
	)
}

export const GetBackgroundColorByState = (state: string) => {
	switch (state) {
		case 'moderation':
			return '#007bff'
		case 'inner_moderation':
			return '#007bff'
		case 'confirm':
			return '#28A745'
		case 'adjustment':
			return '#007bff'
		case 'completed':
			return '#6C757D'
		case 'reject':
			return '#DC3545'
		case 'canceled':
			return '#DC3545'
		default:
			return '007bff'
	}
}

export default BookingIcon
