import React from 'react'
import { EditVinstantInputButton } from 'src/components/_client/campaign-info/common/edit-vinstant-input-button/EditVinstantInputButton'
import { UploadButton } from 'src/components/common/garantid-card/garantid-media-format-card/components/garantid-item/GarantidCampaignItemStateWithCreative'
import s from './inputExtraButton.module.scss'
type Props = {
	onEditVinstant?: () => void
	onUpload?: () => void
}
export const InputExtraButtons = ({ onEditVinstant, onUpload }: Props) => {
	return (
		<div className={s.inputExtraButtons}>
			{onUpload && <UploadButton handleDownloadMedia={onUpload} />}

			{onEditVinstant && (
				<EditVinstantInputButton
					onClick={onEditVinstant}
					styles={{ fill: '#6C757D' }}
				/>
			)}
		</div>
	)
}
