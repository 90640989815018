import React from 'react'
import s from './advFormatNav.module.scss'
import { Button } from 'react-bootstrap'
import { AddAdvButton } from './add-adv-button/AddAdvButton'
import { AdvFormats } from '../../../types'
import cn from 'classnames'

type Props = {
	advFormat: AdvFormats
	onBack: () => void
	advAddDisabled: boolean
	onAddFormat: () => Promise<void>
	isFormatChanged: boolean
	totalAdvVariants: number
	extraButton?: React.ReactNode
	className?: string
}

export const AdvFormatNav = ({
	advFormat,
	advAddDisabled,
	onBack,
	onAddFormat,
	isFormatChanged,
	totalAdvVariants,
	extraButton,
	className,
}: Props) => {
	return (
		<div className={cn(s.advFormatNav, className)}>
			<Button variant="secondary" onClick={onBack}>
				Назад
			</Button>

			<div className={s.advFormatActionContainer}>
				{extraButton && extraButton}

				<AddAdvButton
					totalAdvVariants={totalAdvVariants}
					advFormat={advFormat}
					onAddFormat={onAddFormat}
					disabled={advAddDisabled}
					isFormatChanged={isFormatChanged}
				/>
			</div>
		</div>
	)
}
