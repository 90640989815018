import React from 'react'
import { Column } from 'react-table'
import { ReportDetailType, ReportTableData } from '../types'
import { formattedNumber } from 'src/utils'
import IntlFormatter from 'src/utils/intl-money-formatter'

export const advTitles: Record<ReportDetailType, string> = {
	indoor: 'Внутренняя реклама',
	outdoor: 'Наружная реклама',
	publicTransport: 'Общественный транспорт',
	internet: 'Интернет-реклама',
	tv: 'ТВ-реклама',
}

// Определение колонок
export const reportTableColumns: Column<ReportTableData>[] = [
	{
		Header: 'Формат рекламы',
		accessor: 'format',
		Cell: ({ value }) => <h6 className="m-0">{value}</h6>,
	},
	{
		Header: 'Показы',
		columns: [
			{
				Header: 'Прогнозные',
				accessor: (row) => row.views.forecast,
				Cell: ({ value }) => <p>~ {formattedNumber(value)}</p>,
			},
			{
				Header: 'Фактические',
				accessor: (row) => row.views.actual,
				Cell: ({ value }) => <p>~ {formattedNumber(value)}</p>,
			},
		],
	},
	{
		Header: 'Стоимость без НДС',
		columns: [
			{
				Header: 'Прогнозная',
				accessor: (row) => row.costWithoutVAT.forecast,
				Cell: ({ value }) => <p>{IntlFormatter.format(value)}</p>,
			},
			{
				Header: 'Фактическая',
				accessor: (row) => row.costWithoutVAT.actual,
				Cell: ({ value }) => <p>{IntlFormatter.format(value)}</p>,
			},
		],
	},
]
