import React from 'react'
import cn from 'classnames'
import s from './style.module.scss'
import moment from 'moment'
import { DisplayIssuesCampaign } from 'src/components/_moderator/types/ModerationRequestTypes'
import { CampaignBadge } from 'src/components/_client/campaigns-list/ClientStatistics'

type CampaignInfoProps = {
	issue: DisplayIssuesCampaign
}

export const CampaignInfo = ({ issue }: CampaignInfoProps) => {
	return (
		<div className={cn(s.campaignCell)}>
			<div>{`${issue.company_name} от ${moment(issue.created_at).format(
				'DD.MM.YY'
			)}`}</div>
			<span>{`${issue.name}`}</span>
			<span>{`${moment(issue.start_date).format('DD.MM.YY')} - ${moment(
				issue.end_date
			).format('DD.MM.YY')}`}</span>
			<div>
				<CampaignBadge state={issue.state} />
			</div>
		</div>
	)
}
