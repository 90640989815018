import React from 'react'
import { Modal } from 'react-bootstrap'
import { PlacementCreateAndEdit } from './PlacementCreateAndEdit'
import useResponsive from 'src/utils/useResponsive'
import MobileModal from 'src/components/UtilityComponents/MobileModal'
import s from './style.module.scss'

type CreateAndEditModalProps = {
	open: boolean
	setOpen: (open: boolean) => void
	placementId?: string
	onClose: () => void
	updatePlacementsInfo: () => Promise<void>
}

export const CreateAndEditModal = ({
	placementId,
	open,
	setOpen,
	onClose,
	updatePlacementsInfo,
}: CreateAndEditModalProps) => {
	const { isMobile } = useResponsive()

	const title = placementId
		? 'Редактировать рекламный носитель'
		: 'Создать рекламный носитель'
	if (isMobile) {
		return (
			<MobileModal
				enableTouching={false}
				defaultTouchingSetup={false}
				show={open}
				onHide={onClose}
				title={title}
				styles={{
					padding: 0,
					maxHeight: '100vh',
					height: '100vh',
					display: 'flex',
					flexDirection: 'column',
				}}
				paddingContent="0"
			>
				<PlacementCreateAndEdit
					updatePlacementsInfo={updatePlacementsInfo}
					onClose={onClose}
					placementId={placementId}
				/>
			</MobileModal>
		)
	}
	return (
		<Modal
			show={open}
			onHide={onClose}
			centered
			size={'lg'}
			animation={true}
		>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} className={'ms-2'}>
					{title}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body className="p-0">
				<PlacementCreateAndEdit
					updatePlacementsInfo={updatePlacementsInfo}
					onClose={onClose}
					placementId={placementId}
				/>
			</Modal.Body>
		</Modal>
	)
}
