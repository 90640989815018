import React, { useState } from 'react'
import s from './common.module.scss'
import cn from 'classnames'
import { ReactComponent as DocIcon } from '../../../../../../../../assets/images/document.svg'

import { DocsType } from 'src/components/_moderator/types/ModerationRequestTypes'
import { Form } from 'react-bootstrap'

type DocsViewProps = {
	docs: Array<DocsType>
	isDocRequired?: boolean
	id: string | number
}

const maxItemsDisplayDocsCount = 4
export const DocsView = ({ docs }: DocsViewProps) => {
	const [collapsed, setCollpased] = useState(true)

	const filteredDocs = docs.filter(
		(el, index) => index < maxItemsDisplayDocsCount
	)

	return (
		<div className={cn(s.docsView)}>
			<ul>
				{collapsed
					? filteredDocs.map((el) => (
							<li key={el.id}>
								<DocIcon />{' '}
								<a
									target="_blank"
									rel="noreferrer"
									href={el.file}
								>
									{el.file_name}
								</a>
							</li>
					  ))
					: docs.map((el) => (
							<li key={el.id}>
								<DocIcon />
								<a
									target="_blank"
									rel="noreferrer"
									href={el.file}
								>
									{el.file_name}
								</a>
							</li>
					  ))}

				{filteredDocs.length >= maxItemsDisplayDocsCount && (
					<div
						style={{
							color: '#007BFF',
							padding: '6px 12px',
							fontWeight: 500,
						}}
						onClick={() => setCollpased(!collapsed)}
					>
						{collapsed
							? `Еще +${docs.length - filteredDocs.length}`
							: 'Свернуть'}
					</div>
				)}
			</ul>
		</div>
	)
}
