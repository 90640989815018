import React, { useEffect, useState } from 'react'
import { CommonSenderProps } from './SenderTypeButtonsAdditional'
import {
	AdvChoisesByState,
	getNotficationAdvStateCounts,
} from 'src/components/_moderator/constants'
import {
	NotificationAdvStateCountKeys,
	NotificationStateAdvChoises,
} from 'src/components/_moderator/types/ModerationRequestTypes'
import { AdvChoisesItem } from './AdvChoisesItem'

type Props = {} & CommonSenderProps

export const CampaignSender = ({ detailedNotify, onChange }: Props) => {
	const [advChoisesByState, setAdvChoisesByState] =
		useState<AdvChoisesByState>(() => {
			return getNotficationAdvStateCounts(
				detailedNotify.count_adv_state_and_type,
				detailedNotify.extra_data.state_adv_company
			)
		})

	const handleIsSelectedChange = (key: NotificationAdvStateCountKeys) => {
		setAdvChoisesByState({
			...advChoisesByState,
			[key]: {
				...advChoisesByState[key],
				isSelected: !advChoisesByState[key].isSelected,
			},
		})
	}
	useEffect(() => {
		onChange(
			Object.values(advChoisesByState)
				.filter((el) => el.isSelected)
				.map((el) => el.state)
		)
	}, [advChoisesByState])
	return (
		<div>
			{Object.keys(advChoisesByState).map((key, index: number) => {
				const item = advChoisesByState[key]
				return (
					<AdvChoisesItem
						key={key}
						count={item.count}
						isSelected={item.isSelected}
						title={item.title}
						onChange={() =>
							handleIsSelectedChange(
								key as NotificationAdvStateCountKeys
							)
						}
					/>
				)
			})}
		</div>
	)
}
