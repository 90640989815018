import React from 'react'
import cn from 'classnames'
import baseStyle from '../../createReportSteps.module.scss'
import useResponsive from 'src/utils/useResponsive'
import { CreateReportBody, ReportCheckBox } from '../../../common'
import { CreateReportPlacementHeader } from './CreateReportPlacementHeader'

type ContentFormatsProps = {
	contentFormats: Record<'video' | 'banner', boolean>
	handlerContentFormatChange: (format: 'video' | 'banner') => void
}

export const ContentFormats = ({
	contentFormats,
	handlerContentFormatChange,
}: ContentFormatsProps) => {
	const { isMobile } = useResponsive()
	return (
		<div
			className={cn(baseStyle.createReportStep, {
				[baseStyle.createReportStep_mobile]: isMobile,
			})}
			style={{ marginTop: '20px' }}
		>
			<CreateReportBody>
				<CreateReportPlacementHeader
					isLoading={false}
					title="Форматы"
				/>

				{Object.keys(contentFormats).map((format) => (
					<ReportCheckBox
						checked={contentFormats[format]}
						key={format}
						text={format === 'video' ? 'Видео' : 'Баннер'}
						onChange={() =>
							handlerContentFormatChange(
								format as 'video' | 'banner'
							)
						}
					/>
				))}
			</CreateReportBody>
		</div>
	)
}
