import React, { FC, useEffect, useMemo, useState } from 'react'
import { Badge, Button } from 'react-bootstrap'
import ModalBookingLocationNew from '../content/details/ModalBookingLocationNew'
import useQuery from 'src/utils/useQuery'
import { useBookingsContext } from '../context/BookingsProvider'
import useProviderData from 'src/stores/providerData'
import s from '../../Placements/components/placements-filter/providerFilter.module.scss'
interface IFilterButtons {
	openFilterModal: Function
	hideMapButton: boolean
}
export interface MapPoint {
	id: number
	image: string
	lat: string
	lon: string
	name: string
	only_work_days: boolean
	ots: string
	size: string
	banner_im: string
	placement_type: string
	advertising_company: number
	state: string
}

const FilterButtons: FC<IFilterButtons> = ({
	openFilterModal,
	hideMapButton,
}) => {
	const [showLocationQuickView, setShowLocationQuickView] = useState(false)
	const [provider] = useProviderData()

	const { filterByCompany, filterByAd, searchString } = useBookingsContext()
	const filterCountLabel = useMemo(
		() => filterByAd.length + filterByCompany.length,
		[filterByAd, filterByCompany]
	)

	const params = {
		offset: 0,
		limit: 20,
		q: searchString,
		company: filterByCompany.map((x) => `company=${x}`),
		advertising_company: filterByAd.map((x) => `advertising_company=${x}`),
	}

	const [placements, isPlacementsLoading] = useQuery({
		url: getPlacementsUrl(params, provider),
		cacheByUrl: true,
	})

	const filteredPlacements = useMemo(() => {
		return (((placements as any)?.results as any[]) || []).slice(0, 18)
	}, [placements])

	return (
		<div className={'d-flex gap-2'} style={{ justifyContent: 'flex-end' }}>
			{hideMapButton && (
				<Button
					variant={'light'}
					onClick={() => setShowLocationQuickView(true)}
					className={s.iconButton}
				>
					<svg
						width="14"
						height="13"
						viewBox="0 0 14 13"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						className={'me-1'}
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M13.3895 0.125843C13.5633 0.251144 13.6663 0.452376 13.6663 0.666672V10C13.6663 10.2525 13.5237 10.4834 13.2978 10.5963L9.29782 12.5963C9.11013 12.6901 8.88922 12.6901 8.70153 12.5963L4.94852 10.7198L1.21049 11.9658C1.00719 12.0336 0.783713 11.9995 0.609868 11.8742C0.436022 11.7489 0.333008 11.5476 0.333008 11.3333V2.00001C0.333008 1.71305 0.516628 1.45829 0.788856 1.36755L4.78886 0.034216C4.9257 -0.0113988 5.07365 -0.0113988 5.21049 0.034216L8.99967 1.29728L12.7889 0.034216C12.9922 -0.0335502 13.2156 0.000542571 13.3895 0.125843ZM8.33301 2.48051L5.66634 1.59162V9.58798L8.33301 10.9213V2.48051ZM9.66634 10.9213L12.333 9.58798V1.59162L9.66634 2.48051V10.9213ZM4.33301 9.5195V1.59162L1.66634 2.48051V10.4084L4.33301 9.5195Z"
							fill="#212529"
						/>
					</svg>
					Смотреть на карте
				</Button>
			)}
			{showLocationQuickView && (
				<ModalBookingLocationNew
					show={showLocationQuickView}
					onHide={() => setShowLocationQuickView(false)}
					isPlacementsLoading={isPlacementsLoading}
					placements={filteredPlacements}
					openFilterModal={openFilterModal}
				/>
			)}

			<Button
				variant={'light'}
				onClick={() => {
					openFilterModal()
				}}
				className={s.iconButton}
			>
				<svg
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={'me-1'}
				>
					<path
						d="M9.33333 6.66667C10.8061 6.66667 12 7.86057 12 9.33333C12 10.8061 10.8061 12 9.33333 12C8.09096 12 7.04701 11.1504 6.75081 10.0005L0 10V8.66667L6.75064 8.66683C7.04661 7.5166 8.09072 6.66667 9.33333 6.66667ZM9.33333 8C8.59695 8 8 8.59695 8 9.33333C8 10.0697 8.59695 10.6667 9.33333 10.6667C10.0697 10.6667 10.6667 10.0697 10.6667 9.33333C10.6667 8.59695 10.0697 8 9.33333 8ZM2.66667 0C3.90928 0 4.95339 0.849929 5.24936 2.00016L12 2V3.33333L5.24919 3.33384C4.95299 4.48373 3.90904 5.33333 2.66667 5.33333C1.19391 5.33333 0 4.13943 0 2.66667C0 1.19391 1.19391 0 2.66667 0ZM2.66667 1.33333C1.93029 1.33333 1.33333 1.93029 1.33333 2.66667C1.33333 3.40305 1.93029 4 2.66667 4C3.40305 4 4 3.40305 4 2.66667C4 1.93029 3.40305 1.33333 2.66667 1.33333Z"
						fill="#212529"
					/>
				</svg>
				Фильтр
				{filterCountLabel !== 0 && (
					<Badge className={'ms-1'}>{filterCountLabel}</Badge>
				)}
			</Button>
		</div>
	)
}

function getPlacementsUrl(params, provider) {
	let url = `/core/api/v1/provider/placements/?limit=${params.limit}&offset=${params.offset}`
	if (params.q) url += `&q=${params.q}`
	if (
		params.company.length &&
		params.company.length !== provider.bookings_filters?.companies.length
	)
		url += `&${params.company.join('&')}`
	if (
		params.advertising_company.length &&
		params.advertising_company.length !==
			provider.bookings_filters?.adv_campaigns.length
	)
		url += `&${params.advertising_company.join('&')}`
	return url
}

export default FilterButtons
