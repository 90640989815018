import React, { FC } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import FormLabelTooltip from '../../../UtilityComponents/FormLabelTooltip'
import { TOOLTIPS } from '../tooltips.data'
import CustomSelect from '../../../UtilityComponents/CustomSelect'
import { CompanyType } from '../../../../stores/ADMarketTypes.types'
import { BusinessFormType, IndustryTypes } from './BusinessDataForm'
import AdMarketSelect from '../../../UtilityComponents/ADMarketSelect/AdMarketSelect'

interface ICommonFields {
	isPreset: boolean
	companiesList: CompanyType[]
	presetCompany: CompanyType | null
	setPresetCompany: React.Dispatch<React.SetStateAction<CompanyType | null>>
	values: BusinessFormType
	setFieldError: Function
	setFieldValue: Function
	errors: { [key in keyof BusinessFormType]?: string }
	industryTypes: IndustryTypes
}

const CommonFields: FC<ICommonFields> = ({
	setFieldValue,
	setFieldError,
	values,
	companiesList,
	presetCompany,
	setPresetCompany,
	isPreset,
	errors,
	industryTypes,
}) => {
	return (
		<>
			<InputGroup hasValidation>
				<Form.Label>
					Название бизнеса{' '}
					<FormLabelTooltip text={TOOLTIPS.STEP_0.BUISNESS_NAME} />
				</Form.Label>
				{isPreset ? (
					<>
						<CustomSelect
							data={companiesList}
							current={presetCompany}
							onSelect={(item) => setPresetCompany(item)}
						/>
					</>
				) : (
					<Form.Control
						size="lg"
						type={'text'}
						value={values.name}
						name={'name'}
						onChange={(e) => {
							setFieldError('name', null)
							setFieldValue('name', e.target.value)
						}}
						isInvalid={!!errors.name}
						className={'rounded'}
					/>
				)}
				<Form.Control.Feedback type="invalid">
					{errors.name}
				</Form.Control.Feedback>
			</InputGroup>
			<InputGroup hasValidation>
				<Form.Label>
					Вид деятельности{' '}
					<FormLabelTooltip text={TOOLTIPS.STEP_0.INDUSTRY_TYPE} />
				</Form.Label>
				<AdMarketSelect
					value={((): {
						value: string | number
						label: string
					} => {
						return {
							value: values.industry_type_id,
							label:
								industryTypes.find(
									(el) => el.id === values.industry_type_id
								)?.name || '',
						}
					}).call({})}
					options={industryTypes.map((el) => ({
						value: el.id,
						label: el.name,
					}))}
					onChange={(newValue) => {
						setFieldError('industry_type_id', null)
						setFieldValue('industry_type_id', newValue?.value)
					}}
					isDisabled={isPreset}
				/>
				<Form.Control.Feedback type="invalid">
					{errors.industry_type_id}
				</Form.Control.Feedback>
			</InputGroup>
		</>
	)
}

export default CommonFields
