import React, { useEffect, useState } from 'react'
import { PlacementSearchProps, Search } from '../Search'
import s from './styles.module.scss'
import cn from 'classnames'

type SearchWithVariantsProps = {
	variants?: Array<string>
} & PlacementSearchProps

export const SearchWithVariants = ({
	variants,
	...restProps
}: SearchWithVariantsProps) => {
	const [openVariants, setOpenVariants] = useState(false)

	//TODO подумать как обойти этот костыль
	const handleOnBlur = (e: any) => {
		setTimeout(() => {
			setOpenVariants(false)
		}, 300)
	}

	return (
		<div style={{ position: 'relative' }} onBlur={handleOnBlur}>
			<div onFocus={() => setOpenVariants(true)}>
				<Search {...restProps} />
				{openVariants && (
					<div className={cn(s.card)}>
						<div className={cn(s.list)}>
							{variants?.length ? (
								<>
									{variants?.map((variant, index) => (
										<div
											onClick={(e) => {
												restProps.setSearchString(
													variant
												)
											}}
											className={cn(s.listItem)}
											key={variant + index}
										>
											{variant}
										</div>
									))}
								</>
							) : (
								<div
									className={cn(s.listItem)}
									style={{ color: '#6C757D' }}
								>
									Нет совпадений
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
