import React, { useState } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { ImageTitle } from '../common/ImageTitle'
import s from '../style.module.scss'

type AdvItemActionModalBodyProps = {
	id: string | number
	src: string
	name: string
	placeName: string
	setAdjustmentMessage: (value: string) => void
	adjustmentMessage: string
	messageText: string
	isDocRequired: boolean
	setIsDocRequired: React.Dispatch<React.SetStateAction<boolean>>
}
export const AdvItemActionModalBody = ({
	id,
	name,
	placeName,
	src,
	setAdjustmentMessage,
	setIsDocRequired,
	isDocRequired,
	adjustmentMessage,
	messageText,
}: AdvItemActionModalBodyProps) => {
	const [imageSrc, setImageSrc] = useState(src)
	const minLength = 5
	const [error, setError] = useState<string | undefined>(() => {
		if (adjustmentMessage?.length < 5) {
			return `Минимальная длина сообщения: ${minLength} символов`
		}
		return undefined
	})

	const handleAdjustmentMessageChange = (value: string) => {
		if (value.length < 5) {
			setError(`Минимальная длина сообщения: ${minLength} символов`)
			setAdjustmentMessage(value)
		} else {
			setError(undefined)
			setAdjustmentMessage(value)
		}
	}
	return (
		<div style={{ width: '100%', padding: '24px' }}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					gap: '16px',
				}}
			>
				<ImageTitle
					onError={() => setImageSrc('')}
					src={
						imageSrc ||
						'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
					}
					alt={placeName}
					primeTitle={name}
					secondaryTitle={placeName}
				/>

				<p
					className={s.campaignItemInfoFieldValue}
					style={{ margin: 0, maxWidth: '100%', textAlign: 'left' }}
				>
					{messageText}
				</p>

				<InputGroup hasValidation className="mb-0">
					<Form.Control
						placeholder="Ваш комментарий"
						size={'lg'}
						value={adjustmentMessage}
						onChange={(e) => {
							handleAdjustmentMessageChange(e.target.value)
						}}
						name={`text`}
						as={'textarea'}
						isInvalid={!!error}
					/>
					<Form.Control.Feedback type="invalid">
						{error}
					</Form.Control.Feedback>
				</InputGroup>

				<Form.Check
					className="mb-0"
					checked={isDocRequired}
					onChange={(e) => setIsDocRequired(!isDocRequired)}
					type={'checkbox'}
					label={'Требуются дополнительные документы'}
					id={`is-doc-required`}
				/>
			</div>
		</div>
	)
}
