import React from 'react'
import { ReactComponent as InternetIcon } from 'src/assets/images/internet.svg'
import s from './internetHeader.module.scss'

export const InternetAdvHeader = (props: any) => {
	return (
		<div className={s.outdoorHeader}>
			<div className={s.primaryText}>
				<h4>Интернет-реклама</h4>
				<div>
					<InternetIcon />
				</div>
			</div>
			<p>
				Выберите способы показа рекламы. Реклама будет транслироваться в
				Яндексе и VK.
			</p>
		</div>
	)
}
