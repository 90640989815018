import React from 'react'
import cn from 'classnames'
import s from './../../../../../../../_client/create-campaign/Step2-ADformats/BookingsAD/BookingAD.module.scss'
import IntlFormatter from 'src/utils/intl-money-formatter'

type AdvItemStatisticProps = {
	total: number
	shows: number
	price: number
	style?: React.CSSProperties
}

export const AdvItemStatistic = ({
	price,
	shows,
	total,
	style,
}: AdvItemStatisticProps) => {
	return (
		<div className={s.totals} style={style}>
			<div className={cn(s.totalsItem, s.bb)}>
				<div>Всего рекламных мест:</div>
				<div>{total}</div>
			</div>
			<div className={cn(s.totalsItem, s.bb)}>
				<div>Всего рекламу увидят:</div>
				<div>~ {shows} чел./день</div>
			</div>
			<div className={s.totalsItem}>
				<div>Стоимость всего:</div>
				<div>{IntlFormatter.format(price, undefined, 2)}</div>
			</div>
		</div>
	)
}
