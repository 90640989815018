import React from 'react'
import cn from 'classnames'
import s from '../../createReportSteps.module.scss'
import useResponsive from 'src/utils/useResponsive'
import { CreateReportBody, CreateReportHeader } from '../../../common'
import { CreateReportAdFormatsList } from '.'
import { AdFormatNameType } from '../CreateReportStepThree'

type MainContentProps = {
	disabledAdFormats: Record<AdFormatNameType, boolean>
	handleAdFormatChange: (name: AdFormatNameType, isSelected: boolean) => void
}
export const MainContent = ({
	disabledAdFormats,
	handleAdFormatChange,
}: MainContentProps) => {
	const { isMobile } = useResponsive()

	return (
		<div
			id={'create-report-step-three-content'}
			className={cn(s.createReportStep, {
				[s.createReportStep_mobile]: isMobile,
			})}
		>
			<CreateReportHeader
				title="Тип размещения"
				description="Вы увидите с каких рекламных мест 
            пользователи видят вашу рекламу, на какие форматы реагируют 
            лучше или хуже и на основании этих данных, сможете сделать
             выводы относительно эффективности размещения."
			/>

			<CreateReportBody>
				<CreateReportAdFormatsList
					disabledAdFormats={disabledAdFormats}
					handleAdFormatChange={handleAdFormatChange}
				/>
			</CreateReportBody>
		</div>
	)
}
