import React, { Dispatch, SetStateAction, useState } from 'react'
import s from './content.module.scss'
import cn from 'classnames'
import { NotificationActionType } from './NotificationsContent'
import {
	NotificationModel,
	NotificationState,
} from 'src/components/_moderator/types/ModerationRequestTypes'
import Tooltip from 'src/components/UtilityComponents/Tooltip'
import moment from 'moment'
import { getSenderByMessage } from 'src/components/_moderator/utils'

type Props = {
	selectedTab: NotificationState
	setNotificationInfo: (
		notificationId: number,
		mode: NotificationActionType
	) => Promise<void>
	notificationsData: NotificationModel[]
	setSelectedNotificationsIds: Dispatch<SetStateAction<number[]>>
	setRemovingId: Dispatch<SetStateAction<number | undefined>>
	selectedNotificationsIds: number[]
}
export const NotificationsTable = ({
	notificationsData,
	setSelectedNotificationsIds,
	selectedNotificationsIds,
	setNotificationInfo,
	setRemovingId,
	selectedTab,
}: Props) => {
	const allNotificationsSelected =
		!!notificationsData?.length &&
		notificationsData?.length === selectedNotificationsIds.length

	const handleSelectAllNotifacations = () => {
		if (allNotificationsSelected) setSelectedNotificationsIds([])
		else {
			setSelectedNotificationsIds(
				notificationsData.map((item) => item.id)
			)
		}
	}

	const handleSelectNotificationChange = (notificationId: number) => {
		if (selectedNotificationsIds.includes(notificationId)) {
			setSelectedNotificationsIds(
				selectedNotificationsIds.filter((id) => id !== notificationId)
			)
		} else {
			setSelectedNotificationsIds([
				...selectedNotificationsIds,
				notificationId,
			])
		}
	}

	const getSenderCellText = (notifyModel: NotificationModel) => {
		const result = getSenderByMessage(notifyModel)

		return `${result.title} : ${result.items.join(', ')}`
	}

	const handleCellClick = (item: NotificationModel) => {
		if (selectedTab === 'draft') {
			setNotificationInfo(item.id, 'create')
		} else {
			setNotificationInfo(item.id, 'view')
		}
	}
	return (
		<>
			<table className={s.notificationsTable}>
				<thead>
					<tr style={{ height: '48px', background: 'none' }}>
						<th
							style={{
								width: '48px',
								maxWidth: '48px',
								textAlign: 'center',
								padding: '0',
							}}
						>
							<input
								onClick={handleSelectAllNotifacations}
								checked={allNotificationsSelected}
								type="checkbox"
							/>
						</th>
						<th
							style={{
								width: '120px',
							}}
						>
							Дата
						</th>
						<th
							style={{
								width: '240px',
								maxWidth: '412px',
								whiteSpace: 'nowrap',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
							}}
						>
							Заголовок
						</th>
						<th style={{ width: '412px', minWidth: '412px' }}>
							Основной текст
						</th>
						<th style={{ maxWidth: '300px' }}>Отправлено по</th>
						<th
							style={{
								width: '40px',
								borderRight: 'none',
							}}
						></th>
						<th style={{ borderLeft: 'none', width: '40px' }}></th>
					</tr>
				</thead>
				<tbody>
					{notificationsData?.map((item) => (
						<tr
							key={item.id}
							className={cn({
								[s.selectedRow]:
									selectedNotificationsIds.includes(item.id),
							})}
						>
							<td>
								<input
									onChange={() =>
										handleSelectNotificationChange(item.id)
									}
									checked={selectedNotificationsIds.includes(
										item.id
									)}
									type="checkbox"
								/>
							</td>
							<td
								style={{
									cursor: 'pointer',
								}}
								onClick={() => handleCellClick(item)}
							>
								{moment(item.created_at).format('DD.MM.YYYY')}
							</td>
							<td
								style={{
									width: '240px',
									maxWidth: '240px',
								}}
								onClick={() => handleCellClick(item)}
							>
								{item.title}
							</td>
							<td
								style={{
									width: '412px',
									maxWidth: '412px',
								}}
								onClick={() => handleCellClick(item)}
							>
								{item.description}
							</td>
							<td
								style={{ maxWidth: '300px' }}
								onClick={() => handleCellClick(item)}
							>
								{selectedTab === 'created'
									? getSenderCellText(item)
									: ''}
							</td>
							<Tooltip placement="left" text="Редактировать">
								<td
									className={s.edit}
									onClick={() =>
										setNotificationInfo(item.id, 'edit')
									}
								>
									<div>
										<i className="bi bi-pencil"></i>
									</div>
								</td>
							</Tooltip>
							<Tooltip placement="left" text="Удалить">
								<td
									onClick={() =>
										setNotificationInfo(item.id, 'delete')
									}
									className={s.error}
								>
									<div>
										<i className="bi bi-trash"></i>
									</div>
								</td>
							</Tooltip>
						</tr>
					))}
				</tbody>
			</table>
		</>
	)
}
