import React, { useMemo, useState } from 'react'
import { OutdoorDetailedAccordion } from './accordions/OutdoorDetailedAccordion'
import { IndoorDetailedAccordion } from './accordions/IndoorDetailedAccordion'
import { InternetDetailedAccordion } from './accordions/InternetDetailedAccordion'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { NewContentCreatedKeysType } from 'src/stores/types/newContendCreatedType'
import { TvDetailedAccordion } from './accordions/TvDetailedAccordion'
import { DetailedItemModal } from './modals/DetailedItemModal'
import { AdvItemActionModal } from './modals/AdvItemActionModal'
import { PublicTransportDetailedAccordion } from './accordions/PublicTransportDetailedAccordion'
import { ModerVinstantEditModal } from './modals/ModerVinstantEditModal'
import { DistrictBookingExternalType } from 'src/stores/ADMarketTypes.types'
import { BookingId } from 'src/stores/notificationsData'

export type AdvItemAction = {
	action: 'reject' | 'adjustment' | 'moderation'
	item: {
		id: string | number
		name: string
		placeName: string
		src: string
	}
	rejectMessage: string
	adjustmentMessage: string
	isDocRequired: boolean
	type:
		| 'yandex'
		| 'vk'
		| 'adstream'
		| 'indoor'
		| 'outdoor'
		| 'districtbooking'
		| 'publicTransport'
}
export type EditVinstantModel = {
	vinstantLink: string
	instanceId: number
	newContentKey: NewContentCreatedKeysType | BookingId
	externalType?: DistrictBookingExternalType
}
export type CommonAdvAccordionsProps = {
	onEditVinstant: (editModel: EditVinstantModel) => void
	setDetailedItem: React.Dispatch<
		React.SetStateAction<
			| {
					type: NewContentCreatedKeysType
					id: string | number
			  }
			| undefined
		>
	>
	setAdvItemActon: React.Dispatch<
		React.SetStateAction<AdvItemAction | undefined>
	>
}
export const CampaignDetailedInfo = () => {
	const [{ detailedCampaign }, moderInterface] = useModerationData()

	const selectedState = useMemo(() => {
		return {
			districtAdv:
				detailedCampaign.district_adv_is_selected &&
				detailedCampaign?.district_booking.length > 0,
			outdoorAdv:
				detailedCampaign.outdoor_adv_is_selected &&
				detailedCampaign?.booking?.filter(
					(el) => el.placement.door_type === 'outdoor'
				)?.length > 0,
			indoorAdv:
				detailedCampaign.indoor_adv_is_selected &&
				detailedCampaign?.booking?.filter(
					(el) => el.placement.door_type === 'indoor'
				)?.length > 0,
			internetAdv: detailedCampaign?.extra_data?.inet_is_selected,
			tvAdv: detailedCampaign.extra_data.tv_is_selected,
			publicTransportAdv:
				detailedCampaign.extra_data.transport_is_selected,
		}
	}, [detailedCampaign])

	const [editVinstantModel, setEditVinstantModel] = useState<
		EditVinstantModel | undefined
	>(undefined)

	const [detailedItem, setDetailedItem] = useState<
		{ type: NewContentCreatedKeysType; id: string | number } | undefined
	>(undefined)

	const [advItemAction, setAdvItemActon] = useState<
		AdvItemAction | undefined
	>(undefined)

	const handleOpenEditVinstan = (editModel: EditVinstantModel) => {
		setEditVinstantModel(editModel)
	}
	const handleCloseEditVinstant = () => {
		setEditVinstantModel(undefined)
	}
	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
				}}
			>
				{(selectedState.districtAdv || selectedState.outdoorAdv) && (
					<OutdoorDetailedAccordion
						onEditVinstant={handleOpenEditVinstan}
						setAdvItemActon={setAdvItemActon}
						setDetailedItem={setDetailedItem}
					/>
				)}

				{selectedState.indoorAdv && (
					<IndoorDetailedAccordion
						onEditVinstant={handleOpenEditVinstan}
						setAdvItemActon={setAdvItemActon}
						setDetailedItem={setDetailedItem}
					/>
				)}
				{selectedState.publicTransportAdv && (
					<PublicTransportDetailedAccordion
						onEditVinstant={handleOpenEditVinstan}
						setAdvItemActon={setAdvItemActon}
						setDetailedItem={setDetailedItem}
					/>
				)}
				{selectedState.internetAdv && (
					<InternetDetailedAccordion
						onEditVinstant={handleOpenEditVinstan}
						setAdvItemActon={setAdvItemActon}
						setDetailedItem={setDetailedItem}
					/>
				)}
				{selectedState.tvAdv && (
					<TvDetailedAccordion
						onEditVinstant={handleOpenEditVinstan}
						setAdvItemActon={setAdvItemActon}
						setDetailedItem={setDetailedItem}
					/>
				)}
			</div>

			{editVinstantModel && (
				<ModerVinstantEditModal
					onClose={handleCloseEditVinstant}
					editVinstantModel={editVinstantModel}
					open={!!editVinstantModel}
				/>
			)}

			{!!detailedItem && (
				<DetailedItemModal
					setAdvItemActon={setAdvItemActon}
					setDetailedItem={setDetailedItem}
					detailedItem={detailedItem}
					open={!!detailedItem}
					onClose={() => setDetailedItem(undefined)}
				/>
			)}

			{!!advItemAction && (
				<AdvItemActionModal
					setAdvItemActon={setAdvItemActon}
					advItem={advItemAction}
					open={!!advItemAction}
					onClose={() => setAdvItemActon(undefined)}
				/>
			)}
		</>
	)
}
