import React from 'react'
import { Card } from 'react-bootstrap'
import s from './totalBudget.module.scss'
import useCampaignData from 'src/stores/campaignData'
import { AdvFormats } from '../../../types'
import { getAdvFormatTotalAmount } from '../../../utils'
import IntlFormatter from 'src/utils/intl-money-formatter'

/**
 * Компонент для отображения итогового бюджета кампании
 */
export const TotalCampaignBudget = (props: any) => {
	const [campaign, campaignInterface] = useCampaignData()

	const advFormatValues = Object.values(AdvFormats)

	const totalBudget = advFormatValues.reduce((acc, format) => {
		const formatBudget = getAdvFormatTotalAmount(format, campaign)
		return acc + formatBudget
	}, 0)

	return (
		<Card className={s.totalBudget}>
			<div className={s.totalBudgetContent}>
				<h5>Итоговый бюджет:</h5>
				<h5>{IntlFormatter.format(totalBudget)}</h5>
			</div>
		</Card>
	)
}
