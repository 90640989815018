import moment from 'moment'
import { Moment } from 'moment'
import React, { useState } from 'react'
import { Button, Card, Spinner } from 'react-bootstrap'
import { ConditionArrowUpDown } from 'src/components/UtilityComponents'
import ImageFullScreenWrapper from 'src/components/UtilityComponents/ImageFullScreenWrapper'
import { BASE_URL } from 'src/constants/api'
import { ProviderPhotoReport } from 'src/stores/ProviderTypes.types'
import { authFetch } from 'src/utils/authFetch'

const deletePhoto = async (photoId: number) => {
	return await authFetch({
		url: `${BASE_URL}/provider/photo_report/${photoId}`,
		method: 'DELETE',
		raw: true,
	})
}

type PhotoReportItemProps = {
	photos: ProviderPhotoReport[]
	startDate: Moment
	endDate: Moment
	date: string
	refetchBookingDetail: () => void
}
export const PhotoReportItem = ({
	photos,
	date,
	endDate,
	startDate,
	refetchBookingDetail,
}: PhotoReportItemProps) => {
	const [isCollpased, setIsCollapsed] = useState(true)

	const handleDelete = async (photoId: number) => {
		await deletePhoto(photoId)

		await refetchBookingDetail()
	}
	return (
		<Card
			className="d-flex"
			style={{ borderRadius: '12px', overflow: 'hidden' }}
		>
			<Card.Header
				style={{
					border: 'none',
					background: 'inherit',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				<h6 className="m-0">
					{moment(date, 'YYYY-MM-DD').format('D MMMM')}
				</h6>
				<Button
					onClick={() => setIsCollapsed(!isCollpased)}
					variant="text"
				>
					<ConditionArrowUpDown
						open={!isCollpased}
						styles={{
							padding: 0,
							width: '16px',
							height: '16px',
							background: 'none',
						}}
					/>
				</Button>
			</Card.Header>

			{!isCollpased && (
				<Card.Body
					style={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}
				>
					{photos.map((photo) => (
						<PhotoContent
							photo={photo}
							key={photo.id}
							onDelete={handleDelete}
						/>
					))}
				</Card.Body>
			)}
		</Card>
	)
}

type PhotoContentProps = {
	photo: ProviderPhotoReport
	onDelete: (photoId: number) => void
}
const PhotoContent = ({ photo, onDelete }: PhotoContentProps) => {
	const [photoState, setPhotoState] = useState<'loading' | 'pending'>(
		'pending'
	)
	const handeDeletePhoto = async () => {
		setPhotoState('loading')
		await onDelete(photo.id)
		setPhotoState('pending')
	}
	return (
		<div
			style={{
				overflow: 'hidden',
				position: 'relative',
				display: 'flex',
				flexDirection: 'column',
				marginTop: '4px',
				gap: '4px',
			}}
		>
			<div className="d-flex align-items-center justify-content-end w-100">
				{photoState === 'loading' ? (
					<Spinner animation="border" size="sm" variant="primary" />
				) : (
					<i
						onClick={handeDeletePhoto}
						style={{
							cursor: 'pointer',
						}}
						className="bi bi-x-circle-fill"
					></i>
				)}
			</div>
			<ImageFullScreenWrapper>
				<img
					style={{
						borderRadius: '16px',
						maxWidth: '150px',
						maxHeight: ' 150px',
						width: '150px',
						height: '150px',
						minWidth: '150px',
						minHeight: ' 150px',
					}}
					src={photo.photo}
					alt={'img_'}
				/>
			</ImageFullScreenWrapper>{' '}
		</div>
	)
}
