import React from 'react'
import s from '../style.module.scss'
import cn from 'classnames'
import { ItemCheckbox } from 'src/components/_provider/Placements/components'
import { ModerAdvCompanyItemType } from 'src/components/_moderator/types/ModerationRequestTypes'
import { ReactComponent as Rocket } from '../../../../../../../assets/images/rocket.svg'
import { campaignStatus } from 'src/components/_moderator/constants'
import moment from 'moment'

type CampaignsItemPropsType = {
	campaign: ModerAdvCompanyItemType
	onClick: () => void
	isActive: boolean
}

export const CampaignsItem = ({
	campaign,
	onClick,
	isActive,
}: CampaignsItemPropsType) => {
	return (
		<div
			className={cn(s.campaignItem, {
				[s.campaignItem_active]: isActive,
			})}
		>
			<ItemCheckbox
				style={{
					height: '100%',
					padding: '16px 15px',
					minWidth: 'auto',
					border: 'none',
				}}
				checked={false}
				onCheckBoxClick={() => {}}
			/>
			<div
				onClick={onClick}
				style={{
					maxWidth: '100%',
					width: '100%',
					borderLeft: '1px solid #ced4da',
					cursor: 'pointer',
				}}
			>
				<div
					style={{
						padding: '16px',
						display: 'flex',
						flexDirection: 'column',
						gap: '8px',
					}}
				>
					<div
						id={'header with status'}
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							gap: '16px',
						}}
					>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
								gap: '16px',
							}}
						>
							<div
								style={{
									width: '40px',
									height: '40px',
									backgroundColor:
										campaignStatus?.[campaign.state]
											?.color || 'gray',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'center',
									padding: '5px',
									borderRadius: '50%',
								}}
							>
								<Rocket
									style={{
										fill: 'white',
										width: '20px',
										height: '20px',
									}}
								/>
							</div>

							<h6 className={s.campaignItemName}>
								{`${campaign.name} от ${moment(
									campaign.created_at
								).format('DD.MM.YYYY')}`}
							</h6>
						</div>

						<div
							style={{
								height: 'min-content',
								borderRadius: '4px',
								padding: '3px 4px',
								color: 'white',
								backgroundColor:
									campaignStatus?.[campaign.state]?.color ||
									'gray',
							}}
						>
							<p className={cn(s.campaignItemStatus)}>
								{campaignStatus?.[campaign.state]?.title ||
									campaign.state}
							</p>
						</div>
					</div>
					<div>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								gap: '4px',
							}}
						>
							<div className={cn(s.campaignItemFieldInfo)}>
								<div
									className={cn(s.campaignItemFieldInfoName)}
								>
									Заказчик:
								</div>
								<div
									className={cn(s.campaignItemFieldInfoValue)}
									style={{ maxWidth: '200px' }}
								>
									{campaign.company_name}
								</div>
							</div>
							<div className={cn(s.campaignItemFieldInfo)}>
								<div
									className={cn(s.campaignItemFieldInfoName)}
								>
									Период:
								</div>
								<div
									className={cn(s.campaignItemFieldInfoValue)}
								>
									{`с ${moment(campaign.start_date).format(
										'DD MMMM YYYY'
									)} по ${moment(campaign.end_date).format(
										'DD MMMM YYYY'
									)}`}
								</div>
							</div>
							<div className={cn(s.campaignItemFieldInfo)}>
								<div
									className={cn(s.campaignItemFieldInfoName)}
								>
									Региона показа:
								</div>
								<div
									className={cn(s.campaignItemFieldInfoValue)}
									style={{ maxWidth: '200px' }}
								>
									{campaign?.extra_data?.region_titles
										?.length > 0
										? campaign.extra_data.region_titles?.join(
												','
										  )
										: 'Не назначено'}
								</div>
							</div>
							<div className={cn(s.campaignItemFieldInfo)}>
								<div
									className={cn(s.campaignItemFieldInfoName)}
								>
									Бюджет:
								</div>
								<div
									className={cn(s.campaignItemFieldInfoValue)}
								>
									{`${campaign?.extra_data?.total_budget} ₽`}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
