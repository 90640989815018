import React from 'react'
import ImageFullScreenWrapper from 'src/components/UtilityComponents/ImageFullScreenWrapper'
import s from '../style.module.scss'
type ImageTitleProps = {
	src: string
	alt: string
	primeTitle: string
	secondaryTitle: string
	style?: React.CSSProperties
	onError?: () => void
}

export const ImageTitle = ({
	alt,
	primeTitle,
	secondaryTitle,
	src,
	style,
	onError,
}: ImageTitleProps) => {
	const handleError = () => {
		onError && onError()
	}
	return (
		<div className={s.imageTitle} style={style}>
			<ImageFullScreenWrapper>
				<img onError={handleError} src={src} alt={alt} />
			</ImageFullScreenWrapper>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<h6>{primeTitle}</h6>
				<h6>{secondaryTitle}</h6>
			</div>
		</div>
	)
}
