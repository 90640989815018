import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import { PlacementShowingCountType } from 'src/stores/ADMarketTypes.types'

type Props = {
	showingCounts: PlacementShowingCountType[]
	setSelectedPrice: (shouwingCountTypeId: number) => void
	selectedPrice: number | undefined
	isActive: boolean
}
export const VideoDurationSelector = ({
	showingCounts,
	setSelectedPrice,
	selectedPrice,
	isActive,
}: Props) => {
	return (
		<ButtonGroup aria-label="VideoDurationSelector">
			{showingCounts.map((count) => (
				<Button
					key={count.id}
					variant={
						selectedPrice === count.id ? 'primary' : 'secondary'
					}
					onClick={() => setSelectedPrice(count.id)}
				>
					{count.video_duration}
				</Button>
			))}
		</ButtonGroup>
	)
}
