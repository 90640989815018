import React from 'react'
import { Button } from 'react-bootstrap'
import { ConditionArrowLeftRight } from 'src/components/UtilityComponents'
import useResponsive from 'src/utils/useResponsive'
import s from './adstreamActionButtons.module.scss'
import { EditVinstantInputButton } from '../../../common/edit-vinstant-input-button/EditVinstantInputButton'
import { UploadButton } from 'src/components/common/garantid-card/garantid-media-format-card/components/garantid-item/GarantidCampaignItemStateWithCreative'
import { downloadByRequest } from 'src/utils'

type Props = {
	onRequirementsClick: () => void
	onDeleteClick?: () => void
	isDeleteButton: boolean
	requirementButtonText: string
	isEditVinstantButton: boolean
	onEditVinstantClick: () => void
	url?: string
	fileName?: string
}
export const AdstreamActionButtons = ({
	onDeleteClick,
	onRequirementsClick,
	isDeleteButton,
	requirementButtonText,
	isEditVinstantButton,
	onEditVinstantClick,
	url,
	fileName,
}: Props) => {
	const { isMobile } = useResponsive()

	const handleDeleteClick = () => {
		isDeleteButton && onDeleteClick && onDeleteClick()
	}
	const isUploadButton = url && fileName
	const handleUploadClick = () => {
		if (isUploadButton) {
			downloadByRequest(url, fileName)
		}
	}
	return (
		<div className={s.actionButtons}>
			<Button
				onClick={onRequirementsClick}
				variant={isMobile ? 'text' : 'light'}
				className={s.actionButtons_requirements}
			>
				<span>{requirementButtonText}</span>
				<ConditionArrowLeftRight open={false} />
			</Button>
			{isUploadButton && (
				<UploadButton
					handleDownloadMedia={handleUploadClick}
					className={s.actionButtons_upload}
				/>
			)}

			{isEditVinstantButton && (
				<EditVinstantInputButton
					onClick={onEditVinstantClick}
					styles={{ width: '40px', height: '40px' }}
					variant={isMobile ? 'text' : 'light'}
				/>
			)}
			{isDeleteButton && (
				<Button
					onClick={handleDeleteClick}
					variant={isMobile ? 'danger' : 'light'}
					className={s.actionButtons_remove}
				>
					<i className="bi bi-trash" />

					<span>Удалить</span>
				</Button>
			)}
		</div>
	)
}
