import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { NotificationsTableRowDataType } from 'src/components/_moderator/constants'
import { InfoField } from '../common'
import s from './modals.module.scss'
import cn from 'classnames'
import {
	Manager,
	NotificationCategory,
	NotificationDetails,
	NotificationModel,
} from 'src/components/_moderator/types/ModerationRequestTypes'
import { NotificationActionType } from '../content/NotificationsContent'
import moment from 'moment'
import {
	getSenderByMessage,
	getUpdatedString,
} from 'src/components/_moderator/utils'
import { moderationApi } from 'src/components/_moderator/api/moderatorApi'
import useResponsive from 'src/utils/useResponsive'
import MobileModal from 'src/components/UtilityComponents/MobileModal'
import { NotificationInfoContent } from './NotificationInfoContent'

type Props = {
	notification: (NotificationModel & NotificationDetails) | undefined
	onClose: () => void
	setActionMode: React.Dispatch<React.SetStateAction<NotificationActionType>>
	open: boolean
	onDelete: () => void
	managers: Manager[]
}
export const NotificationInfo = ({
	notification,
	onClose,
	open,
	setActionMode,
	onDelete,
	managers: managersList,
}: Props) => {
	const [title, setTitle] = useState('')
	const [items, setItems] = useState<string[]>([])
	const [manager, setManager] = useState<string | undefined>('')

	const { isMobile } = useResponsive()

	const handleGetCellsInfo = async () => {
		if (notification) {
			const { items, title } = getSenderByMessage(notification)
			setTitle(title)
			setItems(items)

			const manager = managersList.find(
				(el) => el.id === notification.owner
			)
			if (manager)
				setManager(`${manager?.first_name} ${manager.last_name}`)
		}
	}
	useEffect(() => {
		handleGetCellsInfo()
	}, [notification])

	return isMobile ? (
		<MobileModal
			show={open}
			onHide={onClose}
			title={'Просмотр уведомления'}
			paddingContent={'0'}
			contentStyles={{ display: 'flex', paddingBottom: '1rem' }}
		>
			{!notification ? (
				<div
					style={{
						height: '100%',
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<Spinner animation="border" style={{ color: '#007BFF' }} />
				</div>
			) : (
				<div
					style={{
						flexGrow: 1,
						overflow: 'hidden',
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
					}}
				>
					<div
						style={{
							flexGrow: 1,
							overflowY: 'auto',
							padding: '1rem',
						}}
					>
						<NotificationInfoContent
							items={items}
							manager={manager}
							notification={notification}
							title={title}
						/>
					</div>

					<div
						style={{
							display: 'flex',
							gap: '0.5rem',
							justifyContent: 'flex-end',
							borderTop: '1px solid #CED4DA',
							padding: '1rem 1rem 0.5rem 1rem',
							width: '100%',
						}}
					>
						<Button variant="danger" onClick={onDelete}>
							Удалить
						</Button>
						<Button
							variant="primary"
							onClick={() => setActionMode('edit')}
						>
							Редактировать
						</Button>
					</div>
				</div>
			)}
		</MobileModal>
	) : (
		<Modal
			show={open}
			onHide={onClose}
			centered
			size={'sm'}
			animation={true}
			contentClassName={cn({ [s.invisibleModal]: !notification })}
		>
			{!notification ? (
				<Spinner animation="border" style={{ color: '#007BFF' }} />
			) : (
				<>
					<Modal.Header closeButton>
						<Modal.Title as={'h5'} className={'ms-2'}>
							Просмотр уведомления
						</Modal.Title>
					</Modal.Header>
					<Modal.Body style={{ padding: '24px' }}>
						<NotificationInfoContent
							items={items}
							manager={manager}
							notification={notification}
							title={title}
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="danger" onClick={onDelete}>
							Удалить
						</Button>
						<Button
							variant="primary"
							onClick={() => setActionMode('edit')}
						>
							Редактировать
						</Button>
					</Modal.Footer>
				</>
			)}
		</Modal>
	)
}
