import React from 'react'
import { MediaPreview } from 'src/components/common/garantid-card/garantid-media-format-card/components/common-media/CommonMedia'
import { DistrictBookingsCreativeType } from 'src/stores/ADMarketTypes.types'
import s from '../components.module.scss'
import { DistrictBookingFormatModal } from './DistrictBookingFormat'
import { BidRequestPreview } from 'src/components/common/garantid-card/garantid-media-format-card/components/common/BidRequestPreview'
type DistrictBookingFormatViewProps = {
	media: Omit<DistrictBookingsCreativeType, 'edit_vinstant'> | undefined
	screenResolution: string
	isVinstanLoading: boolean
	mediaFormatName: string
	placementsCount: number
	setModalType: (type: DistrictBookingFormatModal) => void
}
export const DistrictBookingFormatView = ({
	media,
	screenResolution,
	isVinstanLoading,
	mediaFormatName,
	placementsCount,
	setModalType,
}: DistrictBookingFormatViewProps) => {
	return (
		<div className="d-flex flex-column gap-2 w-100">
			<div className="d-flex justify-content-between align-items-center">
				<h6 className="m-0">{mediaFormatName}</h6>
				<p
					onClick={() => setModalType('placements')}
					className={s.formatPlacementsCount}
				>
					Рекламные места: {placementsCount}
				</p>
			</div>
			{media ? (
				<MediaPreview
					file={media.file}
					fileName={media.file_name}
					screenResolution={screenResolution}
					isLoading={isVinstanLoading}
				/>
			) : (
				<BidRequestPreview />
			)}
		</div>
	)
}
