import React, { useState } from 'react'
import { AdvFormats } from '../../types'
import { Recommendations } from './formats-selector/recommendations/Recommendations'
import { Formats } from './formats-selector/formats/Formats'

type Props = {
	onSelectFormat: (format: AdvFormats) => void
	onBack: () => void
	onNextStep: () => void
}

export const AdvFormatSelectionStage = ({
	onSelectFormat,
	onBack,
	onNextStep,
}: Props) => {
	const [remommendationsOpen, setRecommendationsOpen] = useState(false)

	return (
		<>
			{remommendationsOpen ? (
				<Recommendations onBack={() => setRecommendationsOpen(false)} />
			) : (
				<Formats
					onRecommendationsClick={() => setRecommendationsOpen(true)}
					onBack={onBack}
					onNextStep={onNextStep}
					onSelectFormat={onSelectFormat}
				/>
			)}
		</>
	)
}
