import React, { useState } from 'react'
import { Button, Form, InputGroup, Modal, Spinner } from 'react-bootstrap'
import { ReusableModalProps } from '../../../../../UtilityComponents/ReusableModals/ReusableModalsProvider'

type onDone = false | string
//TODO если нигде не используется, то можно удалить
const minLength = 5
const ModalBookingAdjustment: React.FC<ReusableModalProps<false | string>> = ({
	show,
	onHide,
	onDone,
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const [adjustmentMessage, setAdjustmentMessage] = useState('')
	const [isDocRequired, setIsDocRequired] = useState(false)

	const [error, setError] = useState<string | undefined>(() => {
		if (adjustmentMessage?.length < minLength) {
			return `Минимальная длина сообщения: ${minLength} символов`
		}
		return undefined
	})

	const handleAdjustmentMessageChange = (value: string) => {
		if (value.length < minLength) {
			setError(`Минимальная длина сообщения: ${minLength} символов`)
			setAdjustmentMessage(value)
		} else {
			setError(undefined)
			setAdjustmentMessage(value)
		}
	}
	return (
		<Modal show={show} onHide={onHide} centered>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'}>Исправление брони</Modal.Title>
			</Modal.Header>
			<Modal.Body
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					gap: '16px',
				}}
			>
				<InputGroup hasValidation className="mb-0">
					<Form.Label>
						Напишите, что необходимо исправить заказчику, чтобы
						пройти модерацию
					</Form.Label>
					<Form.Control
						placeholder="Ваш комментарий"
						size={'lg'}
						value={adjustmentMessage}
						onChange={(e) => {
							handleAdjustmentMessageChange(e.target.value)
						}}
						name={`text`}
						as={'textarea'}
						isInvalid={!!error}
					/>
					<Form.Control.Feedback type="invalid">
						{error}
					</Form.Control.Feedback>
				</InputGroup>
				<Form.Check
					className="mb-0"
					checked={isDocRequired}
					onChange={(e) => setIsDocRequired(!isDocRequired)}
					type={'checkbox'}
					label={'Требуются дополнительные документы'}
					id={`is-doc-required`}
				/>
				{/* Напишите, что необходимо исправить заказчику, чтобы пройти
				модерацию
				<Form.Control
					as="textarea"
					rows={3}
					className={'mt-3'}
					placeholder={'Ваш комментарий'}
					value={comment}
					onChange={(e) => setComment(e.target.value)}
				/> */}
			</Modal.Body>
			<Modal.Footer>
				<div
					style={{
						display: 'flex',
						gap: '8px',
						width: '100%',
						justifyContent: 'flex-end',
					}}
				>
					<Button variant="secondary" onClick={() => {}}>
						Отменить
					</Button>
					<Button
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '8px',
						}}
						variant="primary"
						onClick={() => {}}
					>
						Отправить{' '}
						{isLoading && <Spinner animation="border" size="sm" />}
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
}

export default ModalBookingAdjustment
