import React from 'react'
import { Button } from 'react-bootstrap'

type Props = {
	onRemoveAll: () => void
}
export const RightPanelHeader = ({ onRemoveAll }: Props) => {
	return (
		<div style={{ padding: '1.5rem', borderBottom: '1px solid #CED4DA' }}>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					gap: '1rem',
				}}
			>
				<h5 style={{ margin: 0 }}>Добавленные места</h5>
				<Button variant="danger" onClick={onRemoveAll}>
					Удалить все
				</Button>
			</div>
		</div>
	)
}
