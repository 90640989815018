import { AnimatePresence } from 'framer-motion'
import React, { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { TemplateIframe } from 'src/components/_client/create-campaign/Step3-Upload/components/templates-modal/temlate-iframe'
import useResponsive from 'src/utils/useResponsive'
import cn from 'classnames'
import s from 'src/components/_client/create-campaign/Step3-Upload/components/templates-modal/templates-modal.module.scss'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import useNotificationData, { BookingId } from 'src/stores/notificationsData'
import { NewContentCreatedKeysType } from 'src/stores/types/newContendCreatedType'
type Props = {
	editVinstantLink
	open: boolean
	newContentKey: NewContentCreatedKeysType | BookingId
	onClose: () => void
}
export const EditVinstantModal = ({
	editVinstantLink,
	onClose,
	open,
	newContentKey,
}: Props) => {
	const { isMobile } = useResponsive()
	const [, notificationsInterface] = useNotificationData()

	const { addToast } = useContext(ToastsContext)

	const newContentStatus =
		notificationsInterface.getNewContentCreated(newContentKey)

	useEffect(() => {
		if (newContentStatus === 'start') {
			addToast({
				text: `Начата загрузка контента`,
				type: 'info',
			})
			onClose()
		}
	}, [newContentStatus])

	return (
		<Modal
			onHide={onClose}
			centered
			size={'sm'}
			animation={true}
			show={open}
			dialogClassName="modal-fullscreen"
		>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} className={'ms-2'}>
					Редактирование материала
				</Modal.Title>
			</Modal.Header>
			<Modal.Body style={{ padding: '0' }} className={cn(s.modalBody)}>
				<div
					className={cn(s.templatesModalBodyItem, {
						[s.templatesModalBodyItem_mobile]: isMobile,
					})}
				>
					<AnimatePresence>
						<TemplateIframe url={editVinstantLink} />
					</AnimatePresence>
				</div>
			</Modal.Body>

			{isMobile && (
				<Modal.Footer>
					<button
						onClick={onClose}
						type="button"
						className="btn btn-secondary"
					>
						Отменить
					</button>
				</Modal.Footer>
			)}
		</Modal>
	)
}
