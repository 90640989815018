import React, { useMemo, useState } from 'react'
import NotificationsIcon from './NotificationsIcon'
import useResponsive from '../../utils/useResponsive'
import NotificationsDesktop from './NotificationsDesktop'
import { useHistory, useLocation } from 'react-router-dom'
import useNotificationData from '../../stores/notificationsData'
import NotificationsAlertProvider from './NotificationsAlert/NotificationsAlertProvider'

const HeaderNotifications = () => {
	const [desktopOpen, setDesktopOpen] = useState(false)
	const [notifications, notificationsInterface] = useNotificationData()

	const history = useHistory()
	const location = useLocation()
	const { isDesktop } = useResponsive()

	const notifications_count_not_read = useMemo(() => {
		return notificationsInterface.getNotificationsCountNotRead()
	}, [notifications]) // eslint-disable-line react-hooks/exhaustive-deps

	const isNotificationPage = useMemo(() => {
		return location.pathname.includes('notifications') || desktopOpen
	}, [location, desktopOpen])

	function handleNotificationClick() {
		if (isDesktop) return setDesktopOpen((p) => !p)
		return isNotificationPage
			? history.goBack()
			: history.push('/notifications/')
	}
	return (
		<>
			<NotificationsIcon
				onClick={handleNotificationClick}
				isNotificationPage={isNotificationPage}
				notifications_count_not_read={notifications_count_not_read}
			/>
			{isDesktop && (
				<NotificationsDesktop
					desktopOpen={desktopOpen}
					closeDesktop={() => setDesktopOpen(false)}
				/>
			)}
			<NotificationsAlertProvider />
		</>
	)
}

export default HeaderNotifications
