import React, { useContext, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Modal } from 'react-bootstrap'
import useNotificationData from 'src/stores/notificationsData'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { EditVinstantModel } from '../CampaignDetailedInfo'

type Props = {
	onClose: () => void
	open: boolean
	editVinstantModel: EditVinstantModel
}
export const ModerVinstantEditModal = ({
	onClose,
	open,
	editVinstantModel,
}: Props) => {
	const { newContentKey, vinstantLink } = editVinstantModel
	const [, notificationsInterface] = useNotificationData()

	const newContentStatus =
		notificationsInterface.getNewContentCreated(newContentKey)

	const { addToast } = useContext(ToastsContext)

	useEffect(() => {
		if (newContentStatus === 'start') {
			addToast({
				text: `Начато обновление контента`,
				type: 'info',
			})

			onClose()
		}
	}, [newContentStatus])

	return (
		<Modal show={open} onHide={onClose} fullscreen animation={true}>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} className={'ms-2'}>
					Редактирование шаблона винстант
				</Modal.Title>
			</Modal.Header>

			<Modal.Body
				style={{
					padding: 0,
					margin: 0,
				}}
			>
				<motion.div
					style={{ width: '100%', height: '100%' }}
					initial={{ opacity: 0, y: 8 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 20 }}
				>
					<iframe
						style={{ width: '100%', height: '100%' }}
						title="Шаблон"
						aria-label="Шаблон"
						src={vinstantLink}
					>
						Ваш браузер не поддерживает плавающие фреймы
					</iframe>
					{/* <button onClick={onClose}>Close</button> */}
				</motion.div>
			</Modal.Body>
		</Modal>
	)
}
