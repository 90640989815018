import React from 'react'
import { ReactComponent as WarningRing } from '../../../../../../../assets/images/warningRing.svg'
import s from './style.module.scss'
import cn from 'classnames'

export const NoIssues = () => {
	return (
		<div className={cn(s.noIssues)}>
			<WarningRing />
			<p>Нет ни одной проблемы с показами</p>
		</div>
	)
}
