import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import useUserData from '../../stores/userData'
import ROUTES from '../../constants/Routes'

export default function PrivateProviderRoute({ children, ...passThrough }) {
	const [user] = useUserData()

	if (
		!user ||
		!user.phone_number ||
		!user.is_phone_confirmed ||
		user.provider === null ||
		user.is_moder
	) {
		return <Redirect to={ROUTES.ROUTE_AUTH} />
	}
	return <Route {...passThrough}>{children}</Route>
}
