import React from 'react'
import {
	CodeConfirmationFormData,
	CodeResendData,
	NewPasswordFormData,
	PassResetStagesType,
	PhoneInputFormData,
} from '../../model/types'
import { PhoneInput } from './reset-stages/PhoneInput'
import { FormikHelpers } from 'formik'
import { CodeConfirmation } from './reset-stages/CodeConfirmation'
import { NewPassword } from './reset-stages/NewPassword'
import { PhoneConfirmationVariant } from 'src/stores/userData'

type Props = {
	stage: PassResetStagesType
	phoneNumber: string
	expirationDateKey: string
	lastConfirmationVariantKey: string

	setStage: (stage: PassResetStagesType) => void
	onResendConfirmationCode: (values: CodeResendData) => Promise<void>
	onEnterPhoneNumber: (
		values: PhoneInputFormData,
		formikHelpers: FormikHelpers<PhoneInputFormData>
	) => Promise<void>
	onConfirmCode: (
		values: CodeConfirmationFormData,
		formikHelpers: FormikHelpers<CodeConfirmationFormData>,
		confirmationVariant: PhoneConfirmationVariant
	) => Promise<any>
	onEnterNewPassword: (
		values: NewPasswordFormData,
		formikHelpers: FormikHelpers<NewPasswordFormData>
	) => Promise<any>
}
export const PassResetStages = ({
	stage,
	phoneNumber,
	expirationDateKey,
	lastConfirmationVariantKey,
	setStage,
	onResendConfirmationCode,
	onEnterPhoneNumber,
	onConfirmCode,
	onEnterNewPassword,
}: Props) => {
	return (
		<div>
			{stage === PassResetStagesType.PhoneInput && (
				<PhoneInput onSubmit={onEnterPhoneNumber} />
			)}

			{stage === PassResetStagesType.CodeConfirmation && (
				<CodeConfirmation
					phoneNumber={phoneNumber}
					expirationDateKey={expirationDateKey}
					lastConfirmationVariantKey={lastConfirmationVariantKey}
					onBack={() => setStage(PassResetStagesType.PhoneInput)}
					onSubmit={onConfirmCode}
					onResendConfirmationCode={onResendConfirmationCode}
				/>
			)}

			{stage === PassResetStagesType.NewPassword && (
				<NewPassword onSubmit={onEnterNewPassword} />
			)}
		</div>
	)
}
