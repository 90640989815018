import React, { FC, useState } from 'react'
import Tooltip, { ITooltip } from './Tooltip'
import useResponsive from '../../utils/useResponsive'

//TODO переделеать под переиспользуемость
const FormLabelTooltip: FC<Omit<ITooltip, 'children'>> = ({ ...props }) => {
	const { isMobile } = useResponsive()
	const [hover, setHover] = useState(false)

	const handleClick = () => {
		setHover(!hover)
	}
	return (
		<Tooltip {...props} placement={isMobile ? 'top' : undefined}>
			<span
				className={`ms-1 ${hover ? 'text-black' : 'text-secondary'}`}
				style={{ cursor: 'pointer' }}
				onMouseEnter={() => setHover(true)}
				onMouseLeave={() => setHover(false)}
				onClick={handleClick}
			>
				{Icon}
			</span>
		</Tooltip>
	)
}

const Icon = (
	<svg
		width="14"
		height="14"
		viewBox="0 0 14 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.83337 9.5C7.83337 9.96023 7.46028 10.3333 7.00004 10.3333C6.5398 10.3333 6.16671 9.96023 6.16671 9.5C6.16671 9.03976 6.5398 8.66666 7.00004 8.66666C7.46028 8.66666 7.83337 9.03976 7.83337 9.5Z"
			fill="currentColor"
		/>
		<path
			d="M5.57133 4.18082C5.20219 4.50895 5.01176 4.94986 5.00004 5.50358H6.19096C6.20561 5.25455 6.29057 5.0568 6.44584 4.91031C6.60112 4.7609 6.80473 4.68619 7.05668 4.68619C7.30571 4.68619 7.50785 4.75358 7.66313 4.88834C7.81547 5.02311 7.89164 5.19596 7.89164 5.4069C7.89164 5.6149 7.83891 5.78629 7.73344 5.92106C7.6309 6.05582 7.43754 6.20817 7.15336 6.37809C6.84574 6.55973 6.63041 6.75748 6.50737 6.97135C6.38139 7.19694 6.33305 7.47965 6.36235 7.81949L6.37113 8.07438H7.53569V7.84147C7.53569 7.62467 7.58696 7.44889 7.68949 7.31412C7.79203 7.17936 7.98979 7.02701 8.28276 6.85709C8.90971 6.48795 9.22319 5.99137 9.22319 5.36735C9.22319 4.86344 9.03276 4.45475 8.6519 4.14127C8.26518 3.82486 7.75981 3.66666 7.13578 3.66666C6.47367 3.66666 5.95219 3.83805 5.57133 4.18082Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0.333374 7C0.333374 3.3181 3.31814 0.333328 7.00004 0.333328C10.6819 0.333328 13.6667 3.3181 13.6667 7C13.6667 10.6819 10.6819 13.6667 7.00004 13.6667C3.31814 13.6667 0.333374 10.6819 0.333374 7ZM7.00004 1.66666C4.05452 1.66666 1.66671 4.05448 1.66671 7C1.66671 9.94551 4.05452 12.3333 7.00004 12.3333C9.94556 12.3333 12.3334 9.94551 12.3334 7C12.3334 4.05448 9.94556 1.66666 7.00004 1.66666Z"
			fill="currentColor"
		/>
	</svg>
)

export default FormLabelTooltip
