import React from 'react'
import { Button } from 'react-bootstrap'
import s from './itemsControl.module.scss'

type Props = {
	itemsCount: number
	onAddPlacements: () => void
	isPlacementsTheSame: boolean
	isFooterDisabled: boolean
}

export const LeftPanelFooter = ({
	itemsCount,
	onAddPlacements,
	isPlacementsTheSame,
	isFooterDisabled,
}: Props) => {
	return (
		<div
			className={s.leftPanelFooter}
			style={{
				color: isFooterDisabled ? '#6C757D' : 'inherit',
			}}
		>
			<div className={s.leftPanelFooterPrimary}>
				<p
					style={{
						margin: 0,
						color: 'inherit',
					}}
				>
					Всего мест выделено:
				</p>
				<span>{itemsCount}</span>
			</div>

			{itemsCount !== 0 && (
				<Button
					disabled={isFooterDisabled}
					variant={isPlacementsTheSame ? 'danger' : 'primary'}
					onClick={onAddPlacements}
				>
					{isPlacementsTheSame
						? 'Удалить из рекламной кампании'
						: 'Добавить в рекламную кампанию'}
				</Button>
			)}
		</div>
	)
}
