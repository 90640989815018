import React, { useEffect, useRef } from 'react'
import { Column, useTable } from 'react-table'
import { StatLogReportTableData } from '../../../types'
import s from './statlogTable.module.scss'

type StatLogTableProps = {
	columns: Column<StatLogReportTableData>[]
	data: StatLogReportTableData[]
}
export const StatLogTable = ({ columns, data }: StatLogTableProps) => {
	const scrollRef = useRef<HTMLDivElement | null>(null)

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable<StatLogReportTableData>({
			columns,
			data,
		})
	useEffect(() => {
		const scrollContainer = scrollRef.current

		if (!scrollContainer) return
		const onWheel = (e) => {
			e.preventDefault()
			scrollContainer.scrollLeft += e.deltaY // Adjust horizontal scroll based on vertical mouse wheel movement
		}

		if (scrollContainer) {
			scrollContainer.addEventListener('wheel', onWheel)
		}

		// Cleanup event listener on component unmount
		return () => {
			if (scrollContainer) {
				scrollContainer.removeEventListener('wheel', onWheel)
			}
		}
	}, [])
	return (
		<div style={{ overflowX: 'auto' }} ref={scrollRef}>
			<table {...getTableProps()} className={s.statLogTable}>
				<thead>
					{headerGroups.map((headerGroup, index) => (
						<tr
							{...headerGroup.getHeaderGroupProps()}
							key={`header${index}`}
						>
							{headerGroup.headers.map((column) => (
								<th
									{...column.getHeaderProps()}
									key={`headCell${column.id}`}
									style={{
										width: column?.width,
										minWidth: column?.minWidth,
									}}
								>
									{
										// @ts-ignore
										column.originalId !==
										'placement_placeholder' ? (
											<div className="d-flex">
												{column.render('Header')}
											</div>
										) : (
											<div>
												<h6
													className={'m-0'}
													style={{
														fontWeight: 500,
														fontSize: '16px',
													}}
												>
													Способ показа
												</h6>
											</div>
										)
									}
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{rows.map((row) => {
						prepareRow(row)
						return (
							<tr {...row.getRowProps()} key={`dataRow${row.id}`}>
								{row.cells.map((cell, index) => (
									<td
										{...cell.getCellProps()}
										key={`dataRowCell${cell.column.id}${index}`}
									>
										<div>{cell.render('Cell')}</div>
									</td>
								))}
							</tr>
						)
					})}
				</tbody>
			</table>
		</div>
	)
}
