import React, { FC, useEffect, useState } from 'react'
import { PaymentChooseFormType } from './PaymentChooseModal'
import { Button, Modal, Spinner } from 'react-bootstrap'
import s from './PaymentSteps.module.scss'
import { PaymentStepsData } from './PaymentSteps.data'
import { SelectedIcon } from '../../../create-campaign/Step2-ADformats/AdFormatSelector'
import useCampaignData from '../../../../../stores/campaignData'
import IntlFormatter from '../../../../../utils/intl-money-formatter'
import openLinkInNewTab from '../../../../../utils/openLinkInNewTab'
import Tooltip from 'src/components/UtilityComponents/Tooltip'
import { authFetch } from 'src/utils/authFetch'
import { companies } from 'src/constants/api'

export interface IPaymentSteps {
	values: PaymentChooseFormType
	setFieldValue: (field: keyof PaymentChooseFormType, value: any) => void
	handleSubmit: (values) => void
	onNextStep: () => void
	isSubmitting: boolean
	closeModal: () => void
}

const PaymentStep0: FC<IPaymentSteps> = ({
	values,
	setFieldValue,
	handleSubmit,
	isSubmitting,
}) => {
	const [campaign] = useCampaignData()
	const isPaymentSelected = values.method === 'PAYMENT'
	const isInvoiceSelected = values.method === 'INVOICE'
	const isLKSelected = values.closed_documents === 'LK'
	const isEDOSelected = values.closed_documents === 'EDO'
	const handleMethodClick = (method: typeof values.method) => {
		if (method === 'PAYMENT') {
			return setFieldValue('method', isPaymentSelected ? null : method)
		}
		if (method === 'INVOICE') {
			return setFieldValue('method', isInvoiceSelected ? null : method)
		}
	}
	const handleClosedDocumentClick = (
		closed_document: typeof values.closed_documents
	) => {
		if (closed_document === 'LK') {
			return setFieldValue(
				'closed_documents',
				isLKSelected ? null : closed_document
			)
		}
		if (closed_document === 'EDO') {
			return setFieldValue(
				'closed_documents',
				isEDOSelected ? null : closed_document
			)
		}
	}
	return (
		<>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'}>Оплата рекламной кампании</Modal.Title>
			</Modal.Header>
			<Modal.Body
				style={{
					padding: '40px',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<div className={'mb-3'}>Выберите способ оплаты</div>
				<div className={'d-flex flex-column gap-2'}>
					{PaymentStepsData.METHODS.map((method) => (
						<div
							key={method.key}
							className={`${
								values.method === method.key
									? s.stepCardSelected
									: s.stepCard
							}`}
							onClick={() => {
								handleMethodClick(
									method.key as typeof values.method
								)
							}}
						>
							<div>
								{values.method === method.key ? (
									<SelectedIcon />
								) : (
									method.icon
								)}
							</div>
							<div className={'d-flex flex-column gap-1'}>
								<h6 className={'mb-0'}>{method.title}</h6>
								<span
									className={'text-secondary'}
									style={{ fontSize: '12px' }}
								>
									{method.description}
								</span>
							</div>
						</div>
					))}
				</div>

				{values.method === 'INVOICE' && (
					<div className={'mt-3 d-flex flex-column'}>
						<div className={'mb-3'}>
							Как вы хотите получать закрывающие документы для
							бухгалтерии?
						</div>
						<div className={'d-flex flex-column gap-2'}>
							{PaymentStepsData.CLOSED_DOCUMENTS.map(
								(document) => (
									<div
										key={document.key}
										className={`${
											values.closed_documents ===
											document.key
												? s.stepCardSelected
												: s.stepCard
										}`}
										onClick={() => {
											handleClosedDocumentClick(
												document.key as typeof values.closed_documents
											)
										}}
									>
										<div>
											{values.closed_documents ===
											document.key ? (
												<SelectedIcon />
											) : (
												document.icon
											)}
										</div>
										<div
											className={
												'd-flex flex-column gap-1'
											}
										>
											<h6 className={'mb-0'}>
												{document.title}
											</h6>
											<span
												className={'text-secondary'}
												style={{ fontSize: '12px' }}
											>
												{document.description}
											</span>
										</div>
									</div>
								)
							)}
						</div>
					</div>
				)}

				{/*Control Buttons*/}
				<div
					className={
						'mt-3 d-flex flex-column justify-content-between align-items-center gap-4'
					}
				>
					<div className={s.amounts}>
						<div className={s.amount}>
							<h5 className={'mb-0'}>Бюджет кампании: </h5>
							<h5>
								{IntlFormatter.format(campaign?.budget.total)}
							</h5>
						</div>
						<div className={s.amount}>
							<div style={{ position: 'relative' }}>
								<h5>Итого с НДС: </h5>
								<Tooltip
									text="Ставка НДС составляет 20 %"
									placement="top"
								>
									<p
										style={{
											position: 'absolute',
											color: '#007BFF',
											top: -8,
											right: -10,
										}}
									>
										*
									</p>
								</Tooltip>
							</div>

							<h5>
								{IntlFormatter.format(
									parseFloat(campaign?.budget_vat.total)
								)}
							</h5>
						</div>
					</div>

					<Button
						variant={'primary'}
						style={{ width: '100%' }}
						disabled={(() => {
							if (isSubmitting) return true
							if (!values.method) return true
							if (values.method === 'PAYMENT') return false
							return (
								values.method === 'INVOICE' &&
								!values.closed_documents
							)
						})()}
						onClick={handleSubmit}
					>
						{values.method === 'INVOICE'
							? 'Скачать счет на оплату'
							: 'Оплатить'}
					</Button>
				</div>
			</Modal.Body>
		</>
	)
}

const PaymentStep1: FC<IPaymentSteps> = ({ onNextStep, values }) => {
	const [campaign, campaignInterface] = useCampaignData()

	const [isLoading, setIsLoading] = useState(true)

	const [externalUrl, setExternalUrl] = useState<string | undefined>(
		undefined
	)

	const handleClick = () => {
		if (externalUrl) {
			openLinkInNewTab(externalUrl)
		}
		onNextStep()
	}

	const createInvoise = async () => {
		try {
			const result = await campaignInterface.createInvoice(false)

			//добавление отметки о получении документов через ЭДО
			const formData = new FormData()
			const isEDOSelected = values.closed_documents === 'EDO'
			formData.append(
				'is_need_send_edo_document',
				isEDOSelected.toString()
			)

			await authFetch<any>({
				url: `${companies}${campaign?.company?.id}/`,
				method: 'PATCH',
				body: formData,
			})

			await campaignInterface.refetchSelected()
			if (result?.external_url) {
				setExternalUrl(result.external_url)
			}
		} catch (e) {
			console.log(e)
		}
		setIsLoading(false)
	}

	const isExternalUrlText = externalUrl
		? 'Ваш счёт готов'
		: 'Запрос на формирования счета создан'
	const title = isLoading
		? 'Создаем запрос на формирование счета'
		: isExternalUrlText

	useEffect(() => {
		createInvoise()
	}, [])
	return (
		<Modal.Body
			style={{ padding: '40px' }}
			className={'d-flex flex-column gap-3 align-items-center'}
		>
			{isLoading ? (
				<Spinner animation={'border'} variant={'primary'} />
			) : (
				<svg
					width="48"
					height="48"
					viewBox="0 0 48 48"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M14 32V26H18V31.1716L21.4142 34.5858L18.5858 37.4142L14.5858 33.4142C14.2107 33.0391 14 32.5304 14 32Z"
						fill="#212529"
					/>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M16 23C11.0294 23 7 27.0294 7 32C7 36.9706 11.0294 41 16 41C20.9706 41 25 36.9706 25 32C25 27.0294 20.9706 23 16 23ZM3 32C3 24.8203 8.8203 19 16 19C23.1797 19 29 24.8203 29 32C29 39.1797 23.1797 45 16 45C8.8203 45 3 39.1797 3 32Z"
						fill="#212529"
					/>
					<path
						d="M42 4C43.1046 4 44 4.89543 44 6V30C44 30.5304 43.7893 31.0391 43.4142 31.4142C43.0391 31.7893 42.5304 32 42 32L32 32V28L36.1707 28C36.0602 27.6872 36 27.3506 36 27C36 25.3431 37.3431 24 39 24C39.3506 24 39.6872 24.0602 40 24.1707V11.8293C39.6872 11.9398 39.3506 12 39 12C37.3431 12 36 10.6569 36 9C36 8.64937 36.0602 8.31278 36.1707 8L11.8293 8C11.9398 8.31278 12 8.64937 12 9C12 10.6569 10.6569 12 9 12C8.64936 12 8.31278 11.9398 8 11.8293V18H4L4 6C4 4.89543 4.89543 4 6 4H42Z"
						fill="#212529"
					/>
				</svg>
			)}

			<h5 className={'mb-0'}>{title}</h5>

			{!externalUrl && !isLoading && (
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'flex-start',
						justifyContent: 'center',
						maxWidth: '90%',
					}}
				>
					<span>В течении 5 минут пришлем счёт на почту</span>
				</div>
			)}
			<Button onClick={handleClick} disabled={isLoading}>
				{externalUrl ? 'Скачать счет' : 'Продолжить'}
			</Button>
		</Modal.Body>
	)
}
const PaymentStep2: FC<IPaymentSteps> = ({ closeModal }) => {
	return (
		<Modal.Body
			style={{ padding: '40px' }}
			className={'d-flex flex-column align-items-center'}
		>
			<svg
				width="48"
				height="48"
				viewBox="0 0 48 48"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M14 32V26H18V31.1716L21.4142 34.5858L18.5858 37.4142L14.5858 33.4142C14.2107 33.0391 14 32.5304 14 32Z"
					fill="#212529"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16 23C11.0294 23 7 27.0294 7 32C7 36.9706 11.0294 41 16 41C20.9706 41 25 36.9706 25 32C25 27.0294 20.9706 23 16 23ZM3 32C3 24.8203 8.8203 19 16 19C23.1797 19 29 24.8203 29 32C29 39.1797 23.1797 45 16 45C8.8203 45 3 39.1797 3 32Z"
					fill="#212529"
				/>
				<path
					d="M42 4C43.1046 4 44 4.89543 44 6V30C44 30.5304 43.7893 31.0391 43.4142 31.4142C43.0391 31.7893 42.5304 32 42 32L32 32V28L36.1707 28C36.0602 27.6872 36 27.3506 36 27C36 25.3431 37.3431 24 39 24C39.3506 24 39.6872 24.0602 40 24.1707V11.8293C39.6872 11.9398 39.3506 12 39 12C37.3431 12 36 10.6569 36 9C36 8.64937 36.0602 8.31278 36.1707 8L11.8293 8C11.9398 8.31278 12 8.64937 12 9C12 10.6569 10.6569 12 9 12C8.64936 12 8.31278 11.9398 8 11.8293V18H4L4 6C4 4.89543 4.89543 4 6 4H42Z"
					fill="#212529"
				/>
			</svg>
			<h5 className={'mt-3 mb-1'}>Оплатите счёт в течение 24 часов</h5>

			<span className={'mb-4 text-center'}>
				После оплаты рекламная кампания сразу будет запущена
			</span>
			<Button variant={'primary'} onClick={closeModal}>
				Хорошо, спасибо
			</Button>
		</Modal.Body>
	)
}

const PaymentSteps: FC<IPaymentSteps>[] = [
	PaymentStep0,
	PaymentStep1,
	PaymentStep2,
]

export default PaymentSteps
