import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import {
	BookingsContextType,
	BookingsTabsNames,
	OrderingVariant,
} from './Context.types'
import useProviderData from '../../../../stores/providerData'
import useBookingsQueryParams from './useBookingsQueryParams'
import useActionsController from './useActionsController'

const InitBookingsTabs = [
	{
		count: 0,
		display: 'Все',
		state: BookingsTabsNames.ALL,
	},
	{
		count: 0,
		display: 'На модерации',
		state: BookingsTabsNames.MODERATION,
	},
	{
		count: 0,
		display: 'Подтверждённые',
		state: BookingsTabsNames.CONFIRM,
	},
	{
		count: 0,
		display: 'На исправлении',
		state: BookingsTabsNames.ADJUSTMENT,
	},

	{
		count: 0,
		display: 'Завершенные',
		state: BookingsTabsNames.COMPLETED,
	},
	{
		count: 0,
		display: 'Отклоненные',
		state: BookingsTabsNames.REJECT,
	},
	{
		count: 0,
		display: 'Отмененные',
		state: BookingsTabsNames.CANCELED,
	},
]

const BookingContext = React.createContext<BookingsContextType>({
	tabs: InitBookingsTabs,
	selectedTab: BookingsTabsNames.ALL,
	setSelectedTab: () => null,
	searchString: '',
	setSearchString: () => null,
	filterByAd: [],
	filterByCompany: [],
	setFilterByAd: () => null,
	setFilterByCompany: () => null,
	limit: 0,
	offset: 0,
	setLimit: () => null,
	setOffset: () => null,
	isListFetching: false,
	selectedBooking: 0,
	setSelectedBooking: () => null,
	ordering: OrderingVariant.NULL,
	onOrderingTabClick: () => null,
	onActionButtonClick: () => null,
	filterByRegions: [],
	setFilterByRegions: () => null,
} as BookingsContextType)

interface IBookingsProvider {
	children: React.ReactNode | React.ReactNode[]
}
const BookingsProvider: FC<IBookingsProvider> = ({ children }) => {
	const [provider, providerInterface] = useProviderData()
	const [init, update] = useBookingsQueryParams()

	const [selectedTab, setSelectedTab] = useState<BookingsTabsNames>(
		init.selectedTab
	)
	const [searchString, setSearchString] = useState(init.searchString)
	const [filterByAd, setFilterByAd] = useState<string[]>(init.filterByAd)
	const [filterByCompany, setFilterByCompany] = useState<string[]>(
		init.filterByCompany
	)

	const [filterByRegions, setFilterByRegions] = useState<string[]>(
		init.filterByRegions
	)

	const [limit, setLimit] = useState<number>(init.limit)
	const [offset, setOffset] = useState<number>(init.offset)
	const [ordering, setOrdering] = useState<OrderingVariant>(init.ordering)

	const [selectedBooking, setSelectedBooking] = useState<number>(
		init.selectedBooking
	)

	const [isListFetching, setIsListFetching] = useState(false)

	const updateQuery = () => {
		update({
			selectedBooking,
			selectedTab,
			limit,
			offset,
			filterByAd,
			filterByCompany,
			searchString,
			ordering,
			filterByRegions,
		})
	}

	const tabs = useMemo(() => {
		if (!provider?.bookings) return InitBookingsTabs
		return InitBookingsTabs.map((tab) => {
			if (tab.state === BookingsTabsNames.ALL)
				return {
					...tab,
					count: provider.bookings['count_total'] as number,
				}

			return {
				...tab,
				count: provider.bookings[`count_${tab.state}`] as number,
			}
		})
	}, [provider.bookings])

	const fetchBookings = async () => {
		const noDataWithThisOffset = await providerInterface.fetchBookings(
			selectedTab,
			limit,
			offset,
			filterByAd,
			filterByCompany,
			searchString,
			ordering,
			filterByRegions
		)
		if (noDataWithThisOffset) {
			setOffset(0)
		}
	}
	const onActionButtonClick = useActionsController(fetchBookings)

	/* eslint-disable */
	useEffect(() => {
		//refetchBookingsList
		setIsListFetching(true)
		fetchBookings()
			.then(() => {
				setIsListFetching(false)
				updateQuery()
			})
			.catch(() => setIsListFetching(false))
	}, [
		selectedTab,
		limit,
		offset,
		filterByAd,
		filterByCompany,
		filterByRegions,
		searchString,
		ordering,
	])
	/* eslint-enable */

	useEffect(() => {
		//check if booking id in current list, else reset booking id to first
		if (!provider?.bookings?.results) return
		if (provider?.bookings?.results?.length !== 0) {
			if (
				selectedBooking &&
				provider?.bookings?.results?.find(
					(el) => el.id === selectedBooking
				)
			) {
				setSelectedBooking(selectedBooking)
			} else {
				setSelectedBooking(provider?.bookings?.results[0]?.id)
			}
		} else {
			setSelectedBooking(0)
		}
	}, [provider?.bookings?.results]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		//update query without refetch booking list
		updateQuery()
	}, [selectedBooking]) // eslint-disable-line react-hooks/exhaustive-deps

	const onOrderingTabClick = (tabName: 'placement_name' | 'state') => {
		switch (ordering) {
			case OrderingVariant.NULL:
				if (tabName === 'placement_name')
					setOrdering(OrderingVariant.NAME_ASC)
				if (tabName === 'state') setOrdering(OrderingVariant.STATE_ASC)
				break
			case OrderingVariant.NAME_ASC:
				if (tabName === 'placement_name')
					setOrdering(OrderingVariant.NAME_DESC)
				if (tabName === 'state') setOrdering(OrderingVariant.STATE_ASC)
				break
			case OrderingVariant.NAME_DESC:
				if (tabName === 'placement_name')
					setOrdering(OrderingVariant.NULL)
				if (tabName === 'state') setOrdering(OrderingVariant.STATE_ASC)
				break
			case OrderingVariant.STATE_ASC:
				if (tabName === 'placement_name')
					setOrdering(OrderingVariant.NAME_ASC)
				if (tabName === 'state') setOrdering(OrderingVariant.STATE_DESC)
				break
			case OrderingVariant.STATE_DESC:
				if (tabName === 'placement_name')
					setOrdering(OrderingVariant.NAME_ASC)
				if (tabName === 'state') setOrdering(OrderingVariant.NULL)
				break
		}
	}

	return (
		<BookingContext.Provider
			value={{
				tabs,
				selectedTab,
				setSelectedTab,
				setSearchString,
				searchString,
				filterByCompany,
				filterByAd,
				filterByRegions,
				setFilterByRegions,
				setFilterByCompany,
				setFilterByAd,
				limit,
				setLimit,
				offset,
				setOffset,
				isListFetching,
				selectedBooking,
				setSelectedBooking,
				ordering,
				onOrderingTabClick,
				onActionButtonClick,
			}}
		>
			{children}
		</BookingContext.Provider>
	)
}

export const useBookingsContext = () => useContext(BookingContext)

export default BookingsProvider
