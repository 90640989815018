import React from 'react'
import s from './components.module.scss'
import { PlacementViewModel } from 'src/stores/technical-requirements-store'
import { RenderPreview } from 'src/components/UtilityComponents/ProgressFileInput'

type Props = {
	placements: PlacementViewModel[]
}

export const PlacementsOverview = ({ placements }: Props) => {
	return (
		<div className={s.placementsOverViewContainer}>
			<div className={s.placementsOverView}>
				{placements.map((placement) => (
					<div key={placement.id} className={s.placement}>
						<RenderPreview
							style={{
								height: '80px',
								width: '80px',
								borderRadius: '12px',
							}}
							value={{
								name: placement.name,
								src: placement.image,
							}}
						/>
						<div className={s.placementAddress}>
							<h5>{placement.name}</h5>
							<h5>{placement.address}</h5>
						</div>
					</div>
				))}
			</div>
		</div>
	)
}
