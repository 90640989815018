import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { NotificationState } from 'src/components/_moderator/constants'
import useResponsive from 'src/utils/useResponsive'
import { NotificationsTable } from './NotificationsTable'
import { DeleteConfirm, NotificationEdit, NotificationInfo } from '../modals'
import { NoNotifications, NotificationsActionRow } from '../common'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import {
	CreateNotificationBody,
	NotificationAdvTypeChoises,
	NotificationModel,
	NotificationStateAdvChoises,
} from 'src/components/_moderator/types/ModerationRequestTypes'
import { SubmitValue } from 'src/components/_moderator/types/SubmitValue'
import { NotificationsListMobile } from './mobile/NotificationsListMobile'
import { TabType, Tabs } from 'src/components/UtilityComponents'

type Props = {
	notificationsData: NotificationModel[]
	setSelectedNotificationsIds: Dispatch<SetStateAction<number[]>>
	selectedNotificationsIds: number[]
	isDeleteActive: boolean
	selectedTab: NotificationState
	tabs: TabType<NotificationState>[]
	setTab: (tab: NotificationState) => void
}

export type NotificationActionType =
	| 'view'
	| 'create'
	| 'edit'
	| 'delete'
	| undefined

export const NotificationsContent = ({
	notificationsData,
	selectedNotificationsIds,
	setSelectedNotificationsIds,
	isDeleteActive,
	selectedTab,
	setTab,
	tabs,
}: Props) => {
	const [actionMode, setActionMode] = useState<
		NotificationActionType | undefined
	>(undefined)

	const [moder, moderInterface] = useModerationData()
	const isLoading = moder.isModerLoading
	const managers = moder.managers

	const detailedNotification = moder.detailedNotification

	const [groupDeleteNeeded, setGroupDeleteNeeded] = useState(false)

	const [removingId, setRemovingId] = useState<number | undefined>(undefined)

	const { isMobile } = useResponsive()

	const handleCreateNotification = async () => {
		setActionMode('create')
		moderInterface.clearDetailedNotification()

		await moderInterface.createDraftModerNotification(selectedTab)
	}

	const handleSetActionMode = async (
		notificationId: number,
		mode: NotificationActionType
	) => {
		if (mode === 'delete') {
			setRemovingId(notificationId)
			setActionMode(mode)
			return
		}
		if (detailedNotification?.id === notificationId) {
			setActionMode(mode)
			return
		}
		moderInterface.clearDetailedNotification()
		setActionMode(mode)
		await moderInterface.getDetailedNotificationInformation(notificationId)
	}

	const handleClose = () => {
		setActionMode(undefined)
		moderInterface.clearDetailedNotification()
	}

	const handleDeleteModalClose = () => {
		setActionMode(undefined)
		setGroupDeleteNeeded(false)
		setRemovingId(undefined)
	}

	const handleUpdateDraftNotifaction = async (
		values: Partial<SubmitValue>
	) => {
		const body: Partial<CreateNotificationBody> = {
			category: values?.senderType,
			description: values?.text,
			owner: values?.manager?.value || '',
			state: 'draft',
			title: values?.header,
		}

		if (values?.senderType) {
			switch (values.senderType) {
				case 'adv_company':
					body.state_adv_choices =
						values.senderItems as NotificationStateAdvChoises[]
					break
				case 'adv_type':
					body.type_adv_choices =
						values.senderItems as NotificationAdvTypeChoises[]
					break
				case 'client':
					body.company = values.senderItems as string[]
					break
				default:
					break
			}
		}

		for (const key in body) {
			if (body[key] === undefined) {
				delete body[key]
			}
		}

		moderInterface.updateDraftModerNotification({
			notificationId: detailedNotification?.id || 0,
			body: body,
		})
	}
	const handleSubmitNotification = async (values: SubmitValue) => {
		const body: CreateNotificationBody = {
			category: values.senderType,
			description: values.text,
			owner: values.manager?.value || '',
			state: 'created',
			title: values.header,
		}

		switch (values.senderType) {
			case 'adv_company':
				body.state_adv_choices =
					values?.senderItems as NotificationStateAdvChoises[]
				break
			case 'adv_type':
				body.type_adv_choices =
					values?.senderItems as NotificationAdvTypeChoises[]
				break
			case 'client':
				body.company = values.senderItems as string[]
				break
			default:
				break
		}

		if (actionMode === 'create') {
			await moderInterface.createModerNotification(
				detailedNotification?.id || 0,
				body,
				selectedTab
			)
		}
		if (actionMode === 'edit') {
			await moderInterface.updateModerNotification({
				notificationId: detailedNotification?.id || 0,
				body: body,
				prevState: selectedTab,
			})
		}
		handleClose()
	}

	const handleDeleteNotifications = async () => {
		if (actionMode === 'delete') {
			if (removingId) {
				await moderInterface.deleteModerNotification(removingId)
				setSelectedNotificationsIds(
					selectedNotificationsIds.filter((el) => el !== removingId)
				)
			}
		} else {
			await moderInterface.deleteModerBulkNotifications(
				selectedNotificationsIds,
				selectedTab
			)
			setSelectedNotificationsIds([])
		}
		handleDeleteModalClose()
	}

	useEffect(() => {
		moderInterface.getManagers()
	}, [])

	const atLeastOneNotificationExist =
		!!moder.notificationCount.count_created ||
		!!moder.notificationCount.count_draft

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					maxHeight: '100%',
					overflowY: 'auto',
					overflowX: 'hidden',
				}}
			>
				<div
					style={{
						display: 'flex',
						flexDirection: isMobile ? 'column-reverse' : 'column',
					}}
				>
					{atLeastOneNotificationExist && (
						<div>
							<Tabs<NotificationState>
								selectedTab={selectedTab}
								setTab={setTab}
								tabs={tabs}
							/>
						</div>
					)}
					{notificationsData.length !== 0 && (
						<NotificationsActionRow
							onDelete={() => setGroupDeleteNeeded(true)}
							onCreate={() => handleCreateNotification()}
							isDeleteActive={!!selectedNotificationsIds.length}
						/>
					)}
				</div>

				{!notificationsData.length ? (
					<NoNotifications
						onCreate={() => handleCreateNotification()}
					/>
				) : isMobile ? (
					<NotificationsListMobile
						selectedTab={selectedTab}
						setRemovingId={setRemovingId}
						setNotificationInfo={handleSetActionMode}
						notificationsData={notificationsData}
						selectedNotificationsIds={selectedNotificationsIds}
						setSelectedNotificationsIds={
							setSelectedNotificationsIds
						}
					/>
				) : (
					<NotificationsTable
						selectedTab={selectedTab}
						setRemovingId={setRemovingId}
						setNotificationInfo={handleSetActionMode}
						notificationsData={notificationsData}
						selectedNotificationsIds={selectedNotificationsIds}
						setSelectedNotificationsIds={
							setSelectedNotificationsIds
						}
					/>
				)}

				{actionMode === 'view' && (
					<NotificationInfo
						managers={managers}
						onDelete={() =>
							handleSetActionMode(
								detailedNotification?.id || 0,
								'delete'
							)
						}
						setActionMode={setActionMode}
						notification={detailedNotification}
						onClose={handleClose}
						open={!!actionMode}
					/>
				)}

				{(actionMode === 'edit' || actionMode === 'create') && (
					<NotificationEdit
						managers={managers}
						updateDraftNotification={handleUpdateDraftNotifaction}
						isLoading={isLoading}
						onDelete={() =>
							handleSetActionMode(
								detailedNotification?.id || 0,
								'delete'
							)
						}
						onSubmit={handleSubmitNotification}
						mode={actionMode}
						notification={detailedNotification}
						onClose={handleClose}
						open={!!actionMode}
					/>
				)}

				{(groupDeleteNeeded || !!removingId) && (
					<DeleteConfirm
						onDelete={handleDeleteNotifications}
						onClose={handleDeleteModalClose}
						open={groupDeleteNeeded || !!removingId}
						notificationIds={
							actionMode === 'delete'
								? [removingId!]
								: selectedNotificationsIds
						}
					/>
				)}
			</div>
		</>
	)
}
