import { PlacementFilterType } from '../constants'
import { placementFilters } from '../constants/placementFilters'
import { PlacementPageQueryParamsType } from '../PlacementsPage'

export const intializePlacementFilters = (
	params: PlacementPageQueryParamsType
): Array<PlacementFilterType> => {
	const filters = placementFilters.map((filter) => {
		if (params[filter.key]) {
			if (Array.isArray(params[filter.key])) {
				return {
					...filter,
					filterItems: params[filter.key] as Array<string>,
				}
			} else {
				return {
					...filter,
					filterItems: [params[filter.key]] as Array<string>,
				}
			}
		} else {
			return filter
		}
	})
	return filters
}
