import React from 'react'
import { LoginHeader } from './LoginHeader'
import { LoginFormView } from './LoginFormView'
import { LoginFooter } from './LoginFooter'
import s from './login.module.scss'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { FormikHelpers } from 'formik'

type LoginFormData = {
	phone_number: string
	password: string
	rememberMe: boolean
}

type Props = {
	headerTitle: string
	validationSchema: Yup.ObjectSchema<
		LoginFormData,
		Yup.AnyObject,
		{
			phone_number: undefined
			password: undefined
			rememberMe: undefined
		},
		''
	>
	onSubmit: (
		values: LoginFormData,
		formikHelpers: FormikHelpers<LoginFormData>
	) => void
	toResetPassword: () => void
	toRegister: () => void
}

export const Login = ({
	headerTitle,
	toResetPassword,
	onSubmit,
	validationSchema,
	toRegister,
}: Props) => {
	const { agencyAlias } = useParams<{ agencyAlias: string }>()

	return (
		<div className={s.login}>
			<LoginHeader agency={agencyAlias} headerTitle={headerTitle} />

			<LoginFormView
				onResetPassword={toResetPassword}
				onSubmit={onSubmit}
				validationSchema={validationSchema}
			/>

			<LoginFooter toRegister={toRegister} />
		</div>
	)
}
