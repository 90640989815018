import React from 'react'
import { AdFormatError } from '../../../adformats-store/adformats.store'

type RedirectErrorItemProps = {
	adName: string
	adErrors: AdFormatError[]
}
export const RedirectErrorItem = ({
	adErrors,
	adName,
}: RedirectErrorItemProps) => {
	const handleClick = (
		event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
	) => {
		event.preventDefault()

		const targetId = event.currentTarget.getAttribute('href')
		if (targetId) {
			const targetElement = document.querySelector(targetId)

			if (targetElement) {
				targetElement.scrollIntoView({
					block: 'center',
					behavior: 'smooth',
				})
			}
		}
	}
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '4px',
				alignItems: 'flex-start',
				minWidth: '200px',
				fontSize: '16px',
				fontWeight: 400,
				lineHeight: ' 24px',
				padding: '4px',
			}}
		>
			<div style={{ color: '#212529', fontWeight: 500 }}>{adName}</div>
			<ul style={{ color: '#212529', margin: 0 }}>
				{adErrors.map((error) => {
					const { field, id, message } = error
					return (
						<li key={field + id}>
							<a href={'#' + id} onClick={handleClick}>
								{`${field}`}
							</a>
						</li>
					)
				})}
			</ul>
		</div>
	)
}
