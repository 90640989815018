import React from 'react'
import { Search } from 'src/components/UtilityComponents'
import { CreateReportSelectedCount } from 'src/components/_client/create-report/components/common'
import useResponsive from 'src/utils/useResponsive'

type ProviderFilterContentHeaderProps = {
	setSearchString: React.Dispatch<React.SetStateAction<string>>
	count: number
	isAllSelected: boolean
	onClick: () => void
}
export const ProviderFilterContentHeader = ({
	setSearchString,
	count,
	isAllSelected,
	onClick,
}: ProviderFilterContentHeaderProps) => {
	const { isMobile } = useResponsive()
	return (
		<div
			id={'provider-filter-content-header'}
			style={{
				borderBottom: '1px solid #CED4DA',
				padding: '16px 16px 8px 16px',
			}}
		>
			<Search setSearchString={setSearchString} minWidth="100%" />
			<CreateReportSelectedCount
				style={{ paddingTop: '4px' }}
				buttonStyle={!isMobile ? { paddingLeft: 0 } : undefined}
				isCounter={isMobile}
				count={count}
				onClick={onClick}
				isAllSelected={isAllSelected}
			/>
		</div>
	)
}
