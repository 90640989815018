import React from 'react'
import { ReportBreadCrumbsInfo } from '../../types'
import { Breadcrumb } from 'react-bootstrap'
import s from './breadcrumbs.module.scss'
import { useHistory } from 'react-router-dom'

type CampaignReportBreadcrumbsProps = {
	breadcrumbsInfo: ReportBreadCrumbsInfo
}
export const CampaignReportBreadcrumbs = ({
	breadcrumbsInfo,
}: CampaignReportBreadcrumbsProps) => {
	const history = useHistory()

	const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
		e.preventDefault()

		if (breadcrumbsInfo?.id) {
			history.push(`/campaigns/${breadcrumbsInfo.id}`)
		}
	}
	return (
		<Breadcrumb listProps={{ className: 'm-0 d-flex align-items-center' }}>
			<Breadcrumb.Item
				onClick={handleClick}
				className={s.navigationBreadcrumb}
			>
				<span>
					<i className="bi bi-arrow-left"></i>
				</span>

				<p>{breadcrumbsInfo?.name}</p>
			</Breadcrumb.Item>
			<Breadcrumb.Item>Отчет</Breadcrumb.Item>
		</Breadcrumb>
	)
}
