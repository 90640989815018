import { MediaRequirements } from 'src/stores/technical-requirements-store'
import { getMaxFileSize } from './getMaxFileSize'
import { PlacementShowingCountType } from 'src/stores/ADMarketTypes.types'

export const getFileDescription = (
	mediaRequierments: MediaRequirements,
	placementShowingCount?: PlacementShowingCountType
) => {
	const allowedFormatsDescr = `Допустимые форматы — ${[
		...mediaRequierments.image_formats,
		...mediaRequierments.video_formats,
	].join(', ')}.`

	const resolution = `Ширина: ${mediaRequierments.max_width} px, высота: ${
		mediaRequierments.max_height
	} px, не более ${getMaxFileSize(mediaRequierments)} МБ.`

	const duration =
		mediaRequierments.is_video && mediaRequierments?.duration
			? `Длительность: ${
					placementShowingCount?.video_duration ||
					mediaRequierments?.duration
			  } секунд.`
			: ''

	return `${allowedFormatsDescr} ${resolution} ${duration}`
}
