import React from 'react'
import { StepElement } from './StepElement'
import { getHelpImg, helpImgList } from '../../../constants'

export const RegistrationContent = (props: any) => {
	return (
		<>
			<h2>Регистрация</h2>

			<section>
				<ol>
					<StepElement
						number={1}
						title={
							<>
								Чтобы начать регистрацию, перейдите на страницу{' '}
								<a href="admarket.ru/">admarket.ru</a> и нажмите
								кнопку «Создать аккаунт».
							</>
						}
						url={getHelpImg(helpImgList.signUp[1])}
						alt=""
					/>
					<StepElement
						number={2}
						title="
                        	Для создания нового аккаунта укажите свой номер
							телефона и придумайте пароль. Прочитайте договор
							оферты, политику конфиденциальности и нажмите
							«Продолжить»."
						alt=""
						url={getHelpImg(helpImgList.signUp[2])}
					/>

					<StepElement
						number={3}
						title="В течение пары минут вам поступит звонок, введите
							последние 4 цифры из входящего номера. После этого
							ваша регистрация будет завершена."
						alt=""
						url={getHelpImg(helpImgList.signUp[3])}
					/>

					<StepElement
						number={4}
						title="Поздравляем! Вы в своем личном кабинете и можете
							приступать к созданию рекламной кампании."
						alt=""
						url={getHelpImg(helpImgList.signUp[4])}
					/>
				</ol>
			</section>
		</>
	)
}
