import React, { useState } from 'react'
import { Alert, Button, Form, InputGroup, Spinner } from 'react-bootstrap'
import { Formik } from 'formik'
import useCampaignData from '../../../../stores/campaignData'
import moment from 'moment'
import yandexTracking from '../../../../utils/yandexTracking'
import useResponsive from '../../../../utils/useResponsive'
import { ConnectedFocusError } from 'focus-formik-error'
import { useHistory } from 'react-router-dom'
import FormLabelTooltip from '../../../UtilityComponents/FormLabelTooltip'
import { TOOLTIPS } from '../tooltips.data'
import {
	DatePickerPresetsType,
	getDatePickerPresets,
} from './CampaignUtilsFunctions'
import StartDatePicker from './StartDatePicker'
import RegionSelector from './RegionSelector'
import WatchTimeSelector from './WatchTimeSelector'
import AgeSelector from './AgeSelector'
import GenderSelector from './GenderSelector'
import { ReactComponent as WarningRing } from '../../../../assets/images/warningRing.svg'
import { TopicSelector } from './TopicSelector'
import { AgeMarkingSelector } from './AgeMarkingSelector'

const minDaysAdvCampaignLength = 7
const daysBeforeCampaignStart = 7

const firstAvailableDates = {
	startDate: moment().add(daysBeforeCampaignStart, 'days'),
	endDate: (startDate: moment.Moment) => {
		return startDate.add(minDaysAdvCampaignLength - 1, 'days')
	},
}
const optionalFields = ['topic']

export default function CampaignDataForm({
	cn,
	onBack,
	onNextStep,
	companyId,
	initialValues,
	...props
}) {
	const [campaign, campaignInterface] = useCampaignData()

	const history = useHistory()
	const { isMobile } = useResponsive()
	const presets = getDatePickerPresets({
		month: 1,
		week: 1,
		customDate: {
			endDate: moment(campaign.end_date, 'YYYY-MM-DD', true),
			startDate: moment(campaign.start_date, 'YYYY-MM-DD', true),
		},
	})

	const [presetVariant, setPresetVariant] = useState<DatePickerPresetsType>(
		() => {
			if (campaign.start_date || campaign.end_date) {
				const customPreset = presets.find(
					(el) => el.variant === 'custom'
				)
				return customPreset as DatePickerPresetsType
			}
			return presets[0]
		}
	)

	const [loading, setLoading] = useState(false)

	async function handleSubmit(values) {
		setLoading(true)
		const { start_date, end_date, name } = values

		const start = moment(start_date).format('YYYY-MM-DD')
		const end = moment(end_date).format('YYYY-MM-DD')
		const start_time = values.start_time + ':00'
		const end_time = values.end_time + ':00'
		try {
			if (campaign?.id) {
				await campaignInterface.patchCampaign(campaign.id, {
					name,
					start_date: start,
					end_date: end,
					gender_ids: values.genders,
					age_ids: values.ages,
					start_time: start_time,
					end_time: end_time,
					region_ids: values.regions,
					topic: values.topic,
					age_marking: values.age_marking,
				})
			} else {
				const { id: created_id } =
					await campaignInterface.createCampaign({
						name,
						start_date: start,
						end_date: end,
						gender_ids: values.genders,
						age_ids: values.ages,
						start_time: start_time,
						end_time: end_time,
						region_ids: values.regions,
						topic: values.topic,
						age_marking: values.age_marking,
					})
				await yandexTracking.reachGoal('set_ac_info')
				history.push('/campaigns/' + created_id)
			}
		} catch (e) {
			console.log('-> e', e)
		} finally {
			setLoading(false)
			await campaignInterface.refetchSelected()
			onNextStep()
		}
	}

	function handleValidate(values) {
		const errors: any = {}
		Object.entries(values).forEach(([key, value]) => {
			const requiredField = !optionalFields.includes(key)
			if (requiredField && !value) {
				errors[key] = 'Обязательное поле'
			}
		})

		const durationInDays = values.end_date.diff(values.start_date, 'days')
		if (durationInDays < minDaysAdvCampaignLength - 1) {
			errors['end_date'] = `Не менее ${minDaysAdvCampaignLength} дней`
		}

		const daysToStart = values.start_date.diff(moment(), 'days')

		if (daysToStart < daysBeforeCampaignStart) {
			errors['start_date'] = `Не ранее ${daysBeforeCampaignStart} дней`
		}
		if (!values.start_date.isValid()) {
			errors['start_date'] = 'Обязательное поле'
		}
		if (!values.end_date.isValid()) {
			errors['end_date'] = 'Обязательное поле'
		}

		//Check Time
		if (!values.end_time) {
			errors['end_time'] = 'Введите время'
		}
		if (!values.start_time) {
			errors['start_time'] = 'Введите время'
		}
		;['start_time', 'end_time'].forEach((key) => {
			const val = values[key]
			if (!val) {
				errors[key] = 'Введите время'
				return
			}
			if (Number(val) < 0 || Number(val) > 24) {
				errors[key] = 'Введите корректное время'
				return
			}
		})
		if (values.ages.length === 0) {
			errors['ages'] = 'Выберите хотя бы один диапозон возраста'
		}
		if (values.regions.length === 0) {
			errors['regions'] = 'Выберите хотя бы один регион'
		}

		return errors
	}

	const handlePresetChange = (
		preset: DatePickerPresetsType,
		setFieldValue: (
			field: string,
			value: any,
			shouldValidate?: boolean | undefined
		) => void
	) => {
		setPresetVariant(preset)

		setFieldValue('start_date', preset.startDate)
		setFieldValue('end_date', preset.endDate)
	}
	const handleCustomValuesChange = (data: {
		newValues: {
			field: 'start_date' | 'end_date'
			value: moment.Moment
		}
		setFieldValue: (
			field: string,
			value: any,
			shouldValidate?: boolean | undefined
		) => void
	}) => {
		data.setFieldValue(data.newValues.field, data.newValues.value, true)
	}

	return (
		<Formik
			initialValues={{
				name:
					campaign?.name ||
					`Рекламная кампания ${initialValues?.name || ''}`,

				start_date: moment(presetVariant.startDate, 'YYYY-MM-DD', true),
				end_date: moment(presetVariant.endDate, 'YYYY-MM-DD', true),
				ages: campaign?.ages?.map((g) => g.id) || [],
				genders: campaign?.genders?.map((g) => g.id) || [3],
				regions: campaign?.regions?.map((r) => r.id) || [],
				start_time: campaign?.start_time?.split(':')[0] || '00',
				end_time: campaign?.end_time?.split(':')[0] || '23',
				topic: campaign?.topic,
				age_marking: campaign?.age_marking || 'zero',
			}}
			onSubmit={handleSubmit}
			validate={handleValidate}
			validateOnChange={false}
		>
			{({
				setFieldValue,
				values,
				handleSubmit,
				errors,
				setFieldError,
			}: any) => (
				<Form onSubmit={handleSubmit}>
					<ConnectedFocusError />
					<InputGroup hasValidation>
						<Form.Label>
							Название{' '}
							<FormLabelTooltip text={TOOLTIPS.STEP_1.RK_NAME} />
						</Form.Label>
						<Form.Control
							size="lg"
							type={'text'}
							value={values.name}
							onChange={(e) => {
								setFieldValue('name', e.target.value)
								setFieldError('name', '')
							}}
							isInvalid={!!errors.name}
							name={'name'}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.name}
						</Form.Control.Feedback>
					</InputGroup>
					<Alert
						variant={'secondary'}
						style={{
							display: 'flex',
							alignItems: isMobile ? 'flex-start' : 'center',
							gap: '16px',
							borderRadius: '8px',
						}}
					>
						<WarningRing
							style={{
								minWidth: '20px',
								minHeight: '20px',
								width: '20px',
								height: '20px',
								fill: '#212529',
							}}
						/>

						<p
							style={{
								letterSpacing: '-0.03em',
								margin: 0,
							}}
						>
							Дата запуска кампании зависит от соответствия
							рекламных материалов техническим требованиям и
							времени на модерацию
						</p>
					</Alert>

					<StartDatePicker
						onPresetChange={(preset) =>
							handlePresetChange(preset, setFieldValue)
						}
						firstAvaliableDate={firstAvailableDates.startDate}
						presetVariant={presetVariant}
						onValuesChange={(newValues) =>
							handleCustomValuesChange({
								newValues: newValues,
								setFieldValue: setFieldValue,
							})
						}
						presets={presets}
						cn={cn}
						values={values}
						setFieldValue={setFieldValue}
						errors={errors}
						isMobile={isMobile}
						campaign={campaign}
					/>
					<RegionSelector
						value={values.regions}
						setFieldValue={setFieldValue}
						setFieldError={setFieldError}
						errors={errors.regions}
					/>

					<WatchTimeSelector
						end_time={values.end_time}
						start_time={values.start_time}
						errors={errors}
						setFieldError={setFieldError}
						setFieldValue={setFieldValue}
					/>
					<GenderSelector
						value={values.genders}
						setFieldValue={setFieldValue}
						setFieldError={setFieldError}
						error={errors.genders}
					/>
					<AgeSelector
						value={values.ages}
						setFieldValue={setFieldValue}
						setFieldError={setFieldError}
						error={errors.ages}
					/>
					<TopicSelector
						advCategoryKey={values.topic}
						setFieldValue={setFieldValue}
					/>
					<AgeMarkingSelector
						ageMarking={values.age_marking}
						setFieldValue={setFieldValue}
					/>
					{/*Navigation Buttons*/}
					<div className={cn('buttons')}>
						<Button
							variant={'primary'}
							onClick={handleSubmit}
							disabled={loading}
							style={
								isMobile
									? { width: '100%', marginLeft: '10px' }
									: {}
							}
						>
							{loading ? (
								<Spinner
									animation="border"
									variant={'light'}
									size="sm"
								/>
							) : (
								'Продолжить'
							)}
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	)
}
