import React, { FC, useMemo, useState } from 'react'
import s from './BookingsFilter.module.scss'
import { useBookingsContext } from '../context/BookingsProvider'
import useProviderData from '../../../../stores/providerData'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

interface ICurrentFilters {
	show: boolean
	openFilterModal: Function
	children?: React.ReactNode | React.ReactNode[]
	onHide: Function
}

const CurrentFilters: FC<ICurrentFilters> = ({
	openFilterModal,
	show,
	onHide,
}) => {
	const {
		filterByCompany,
		filterByAd,
		setFilterByAd,
		setFilterByCompany,
		filterByRegions,
		setFilterByRegions,
	} = useBookingsContext()
	const [provider] = useProviderData()

	const noFilters =
		filterByCompany.length === 0 &&
		filterByAd.length === 0 &&
		filterByRegions.length === 0

	if (noFilters || !show) return null
	return (
		<div className={s.container}>
			<div className={s.tabContainer}>
				<FilterTab
					content={filterByAd}
					onRemove={() => setFilterByAd([])}
					text={'Кампании'}
					setOpenModal={openFilterModal}
					bookings_filters={provider?.bookings_filters}
				/>
				<FilterTab
					content={filterByCompany}
					onRemove={() => setFilterByCompany([])}
					text={'Заказчики'}
					setOpenModal={openFilterModal}
					bookings_filters={provider?.bookings_filters}
				/>
				<FilterTab
					content={filterByRegions}
					onRemove={() => setFilterByRegions([])}
					text={'Регионы'}
					setOpenModal={openFilterModal}
					bookings_filters={provider?.bookings_filters}
				/>
				<Button
					variant={'clear'}
					className={s.clearBtn}
					onClick={() => openFilterModal(true)}
				>
					<AddButton />
					Добавить фильтр
				</Button>
			</div>
			<RightButtons onHide={onHide} />
		</div>
	)
}

const FilterTab = ({
	content,
	onRemove,
	text,
	setOpenModal,
	bookings_filters,
}) => {
	const titles = useMemo<string>(() => {
		if (content.length === 1) {
			if (text === 'Заказчики') {
				return bookings_filters?.companies.find(
					(campaign) => campaign.id === content[0]
				)?.name
			}
			if (text === 'Кампании') {
				return bookings_filters?.adv_campaigns.find(
					(campaign) => campaign.id === content[0]
				)?.name
			}
			if (text === 'Регионы') {
				return bookings_filters?.regions.find(
					(campaign) => campaign.id === content[0]
				)?.name
			}
		}
		if (content.length === 2) {
			if (text === 'Заказчики') {
				return (
					bookings_filters?.companies.find(
						(campaign) => campaign.id === content[0]
					)?.name +
					', ' +
					bookings_filters?.companies.find(
						(campaign) => campaign.id === content[1]
					)?.name
				)
			}
			if (text === 'Кампании') {
				return (
					bookings_filters?.adv_campaigns.find(
						(campaign) => campaign.id === content[0]
					)?.name +
					', ' +
					bookings_filters?.adv_campaigns.find(
						(campaign) => campaign.id === content[1]
					)?.name
				)
			}
			if (text === 'Регионы') {
				return (
					bookings_filters?.regions.find(
						(campaign) => campaign.id === content[0]
					)?.name +
					', ' +
					bookings_filters?.regions.find(
						(campaign) => campaign.id === content[1]
					)?.name
				)
			}
		}
		return ''
	}, [text, bookings_filters, content])

	if (content.length === 0) return <></>
	if (content.length > 2)
		return (
			<div className={s.tab} onClick={() => setOpenModal(text)}>
				{text}: <span>{content.length}</span>
				<div
					className={s.close}
					onClick={(e) => {
						e.stopPropagation()
						onRemove()
					}}
				>
					<svg
						width="12"
						height="12"
						viewBox="0 0 12 12"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M5.99992 6.94273L10.1952 11.138L11.138 10.1952L6.94273 5.99992L11.138 1.80469L10.1951 0.861878L5.99992 5.05711L1.80463 0.861816L0.861816 1.80463L5.05711 5.99992L0.861816 10.1952L1.80463 11.138L5.99992 6.94273Z"
							fill="#212529"
						/>
					</svg>
				</div>
			</div>
		)
	return (
		<div className={s.tab} onClick={() => setOpenModal(text)}>
			{text}: <span>{titles}</span>
			<div
				className={s.close}
				onClick={(e) => {
					e.stopPropagation()
					onRemove()
				}}
			>
				<svg
					width="12"
					height="12"
					viewBox="0 0 12 12"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M5.99992 6.94273L10.1952 11.138L11.138 10.1952L6.94273 5.99992L11.138 1.80469L10.1951 0.861878L5.99992 5.05711L1.80463 0.861816L0.861816 1.80463L5.05711 5.99992L0.861816 10.1952L1.80463 11.138L5.99992 6.94273Z"
						fill="#212529"
					/>
				</svg>
			</div>
		</div>
	)
}

function AddButton() {
	return (
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{ marginRight: '4px' }}
		>
			<path
				d="M6.66667 0H5.33333V5.33333H0V6.66667H5.33333V12H6.66667V6.66667H12V5.33333H6.66667V0Z"
				fill="#212529"
			/>
		</svg>
	)
}

const RightButtons = ({ onHide }) => {
	const { setFilterByCompany, setFilterByAd } = useBookingsContext()
	const resetFilter = () => {
		setFilterByCompany([])
		setFilterByAd([])
	}
	return (
		<div className={'d-flex gap-2'}>
			<Button
				variant={'light'}
				style={{ whiteSpace: 'nowrap' }}
				onClick={onHide}
			>
				Свернуть
				<svg
					width="12"
					height="7"
					viewBox="0 0 12 7"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
					className={'ms-1'}
				>
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M5.52873 0.861766L0.862061 5.52843L1.80487 6.47124L6.00013 2.27598L10.1954 6.47124L11.1382 5.52843L6.47154 0.861766C6.21119 0.601416 5.78908 0.601416 5.52873 0.861766Z"
						fill="#212529"
					/>
				</svg>
			</Button>
			<OverlayTrigger
				placement="left"
				overlay={(props) => (
					<Tooltip id="button-tooltip" {...props}>
						Сбросить фильтр{' '}
					</Tooltip>
				)}
			>
				<Button variant={'light'} onClick={resetFilter}>
					<svg
						width="18"
						height="14"
						viewBox="0 0 18 14"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M9.00005 1.99984C6.23863 1.99984 4.00005 4.23841 4.00005 6.99984C4.00005 7.13628 4.0055 7.27135 4.01618 7.40486L4.83794 6.35306L5.88862 7.17394L4.14731 9.40272C3.92063 9.69285 3.50168 9.7443 3.21154 9.51763L0.982666 7.77633L1.80352 6.72563L2.67982 7.41023C2.67113 7.2745 2.66672 7.13765 2.66672 6.99984C2.66672 3.50203 5.50225 0.666504 9.00005 0.666504C10.9352 0.666504 12.6681 1.53521 13.8288 2.90156L12.8126 3.76478C11.8944 2.68392 10.5275 1.99984 9.00005 1.99984Z"
							fill="#212529"
						/>
						<path
							d="M15.3203 6.58936L16.1966 7.27396L17.0174 6.22327L14.7886 4.48197C14.4984 4.2553 14.0795 4.30675 13.8528 4.59687L12.1115 6.82565L13.1621 7.64654L13.9839 6.59474C13.9946 6.72827 14 6.86337 14 6.99984C14 9.76126 11.7615 11.9998 9.00005 11.9998C7.47263 11.9998 6.10566 11.3158 5.18749 10.2349L4.17131 11.0981C5.33199 12.4645 7.06491 13.3332 9.00005 13.3332C12.4979 13.3332 15.3334 10.4976 15.3334 6.99984C15.3334 6.862 15.329 6.72512 15.3203 6.58936Z"
							fill="#212529"
						/>
					</svg>
				</Button>
			</OverlayTrigger>
		</div>
	)
}
export default CurrentFilters
