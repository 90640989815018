import React, { useEffect, useRef, useState } from 'react'
import { Center, Placemark } from './MassCoverageModalMap'
import { initMap, paintCircle, paintItems } from '../../../utils'
import { useEventListeners } from 'src/utils/useEventListeners'

// @ts-ignore:next-line
export const ymaps = window?.ymaps

type Props = {
	center: Center
	setCenter?: React.Dispatch<React.SetStateAction<Center>>
	focusCenter: Center | null
	getCirlce?: (cirlce: any) => void
	radius?: number
	items?: Array<[number, number]>
	objectManagerJSON: {
		type: string
		features: Placemark[]
	}
	onMapClick?: (e: any) => void
	onItemClick?: (placementId: number) => Promise<void>
}
const standardZoom = 13
const maxZoom = 16

export const MassCoverageYandexMap = ({
	center,
	setCenter,
	radius,
	getCirlce,
	items,
	objectManagerJSON,
	onMapClick,
	onItemClick,
	focusCenter,
}: Props) => {
	const mapRef = useRef<HTMLDivElement | null>(null)
	const { addEventListener, cleanupListeners } = useEventListeners()

	const [centerPlacemark, setCenterPlacemark] = useState<any | null>(null)

	const handleSetCenter = (center: Center) => {
		setCenter && setCenter(center)
	}
	const handleGetCircle = (cirlce: any) => {
		if (getCirlce) {
			cirlce && getCirlce(cirlce)
		}
	}
	const handleMapClick = (e: any) => {
		onMapClick && onMapClick(e)
	}
	const handleItemClick = (placementId: number) => {
		onItemClick && onItemClick(placementId)
	}
	const handleSetMapFocusCenter = () => {
		const map = mapRef.current as any

		if (map && map?.setCenter && typeof map?.setCenter === 'function') {
			if (focusCenter) {
				map.setCenter([focusCenter.lat, focusCenter.lon], maxZoom)
			} else {
				map.setCenter([center.lat, center.lon], standardZoom)
			}
		}
	}

	useEffect(() => {
		//слушатель клика по месту внутри кластера. используется датасет id
		addEventListener(document, 'click', (e) => {
			const selectBaloon = e
				.composedPath()
				.find(
					(data) =>
						data.className === 'ymaps_cluster_baloon_content_place'
				)
			if (selectBaloon && selectBaloon?.dataset?.id) {
				handleItemClick(parseInt(selectBaloon.dataset.id))
			}
		})

		return cleanupListeners
	}, [])
	useEffect(() => {
		if (centerPlacemark) {
			centerPlacemark.geometry.setCoordinates([center.lat, center.lon])
		}
	}, [center])
	useEffect(() => {
		handleSetMapFocusCenter()
	}, [focusCenter, mapRef])

	useEffect(() => {
		ymaps.ready(() => {
			mapRef.current = initMap({
				ref: mapRef.current,
				center,
				onMapClick: handleMapClick,
			})

			const paintResult = paintCircle({
				map: mapRef.current,
				center,
				setCenter: handleSetCenter,
				radius: radius,
			})
			paintItems({
				map: mapRef.current,
				objectManagerJSON,
				onItemClick: handleItemClick,
			})

			if (paintResult?.circle) {
				handleGetCircle(paintResult?.circle)
			}
			if (paintResult?.centerPlacemark) {
				setCenterPlacemark(paintResult?.centerPlacemark)
			}
		})
		return () => {
			const ymapsMap = mapRef?.current as any

			if (ymapsMap && ymapsMap?.destroy) {
				if (typeof ymapsMap.destroy === 'function') {
					console.log('remove Map')

					ymapsMap.destroy()
				}
			}
		}
	}, [])

	return <div style={{ width: '100%', height: '100%' }} ref={mapRef} />
}
