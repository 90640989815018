import React, { FC } from 'react'
import useUserData from '../../stores/userData'
import { withPage } from '../page/Page'
import ROUTES from '../../constants/Routes'
import { Redirect } from 'react-router-dom'

const DeveloperRouteCheck: FC = () => {
	const [user] = useUserData()
	if (!user || user.phone_number !== '+79959163902')
		return <Redirect to={ROUTES.ROUTE_AUTH} />

	return <div>Developer route for testing</div>
}

export default withPage()(DeveloperRouteCheck)
