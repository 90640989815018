import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { AdvFormats, PublicTransportVariants } from '../../../../types'
import { AdvFormatVariantsContainer } from '../../../common/adv-format-variants-container/AdvFormatVariantsContainer'
import useCampaignData from 'src/stores/campaignData'
import { useAdformatsStore } from 'src/components/_client/create-campaign/Step2-ADformats/adformats-store/adformats.store'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { CampaignInfoTypeBookingsSeparation } from 'src/stores/ADMarketTypes.types'
import { AdvFormatNav } from '../../../common/adv-format-nav/AdvFormatNav'
import { MCR } from './p-t-variants/mcr/MCR'
import { MCD } from './p-t-variants/mcd/MCD'
import { PublicTransportPresentationCard } from './p-t-presentation-card/PublicTransportPresentationCard'
import { authFetch } from 'src/utils/authFetch'
import { BASE_URL } from 'src/constants/api'
import { AdvFormatContent } from '../../../common/adv-format-content/AdvFormatContent'
import { AdvFormatContainer } from '../../../common/adv-format-container/AdvFormatContainer'

const initializePublicTransportVariants = (
	campaign: CampaignInfoTypeBookingsSeparation
): PublicTransportVariants[] => {
	const variants = [] as PublicTransportVariants[]

	if (campaign.public_transport?.is_selected) {
		variants.push(PublicTransportVariants.MCR)
	}

	return variants
}

const PublicTransportVariantsForRender: Record<
	PublicTransportVariants,
	(props: any) => JSX.Element
> = {
	moskowCentRing: MCR,
	moskowCentDiameter: MCD,
}

const togglePublicTransportSelectedState = async (
	campaignId: string,
	isSelected: boolean
) => {
	await authFetch<{ is_selected: boolean }>({
		method: 'PATCH',
		url: `${BASE_URL}/adv_companies/${campaignId}/transport-adv/`,
		body: { is_selected: isSelected },
	})
}
type Props = {
	goToSelectors: () => void
}
export const PublicTransportAdv = ({ goToSelectors }: Props) => {
	const [campaign, campaignInterface] = useCampaignData()

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const setAdFormatErrors = useAdformatsStore(
		(state) => state.setAdFormatErrors
	)

	const { addToast } = useContext(ToastsContext)

	const [publicTransportVariants, setPublicTransportVariants] = useState<
		PublicTransportVariants[]
	>(() => initializePublicTransportVariants(campaign))

	const handleVariantClick = useCallback(
		(variant: PublicTransportVariants) => {
			if (publicTransportVariants.includes(variant)) {
				//действия при удалении варианта наружки

				//убрать ошибки из MCR
				if (variant === PublicTransportVariants.MCR) {
					setAdFormatErrors('mcr', null)
				}

				if (variant === PublicTransportVariants.MCD) {
					console.log('remove')
				}

				//убрать компонент из стэка форм
				setPublicTransportVariants(
					publicTransportVariants.filter((v) => v !== variant)
				)
			} else {
				setPublicTransportVariants([
					variant,
					...publicTransportVariants,
				])
			}
		},
		[publicTransportVariants]
	)

	const handleAddFormat = async () => {
		try {
			setIsLoading(true)
			await togglePublicTransportSelectedState(
				campaign.id,
				publicTransportVariants.includes(PublicTransportVariants.MCR)
			)
			await campaignInterface.refetchSelected()
			addToast({
				text: 'Общественный транспорт изменен',
				type: 'success',
			})
			goToSelectors()
		} catch (e) {
			addToast({
				text: 'Ошибка изменения общественного транспорта',
				type: 'danger',
			})
		} finally {
			setIsLoading(false)
		}
	}
	const RenderedPublicTransportVariants = useMemo(
		() =>
			publicTransportVariants.map((variant) => {
				return React.createElement(
					PublicTransportVariantsForRender[variant],
					{
						key: variant,
					}
				)
			}),
		[publicTransportVariants]
	)

	//remove all adv format errors
	useEffect(() => {
		return () => {
			setAdFormatErrors('mcr', null)
			setAdFormatErrors('mcd', null)
		}
	}, [])
	return (
		<AdvFormatContainer>
			<AdvFormatContent>
				<PublicTransportPresentationCard
					onSelectorClick={handleVariantClick}
					selectedVariants={publicTransportVariants}
				/>
				<AdvFormatVariantsContainer>
					{RenderedPublicTransportVariants}
				</AdvFormatVariantsContainer>
			</AdvFormatContent>

			<AdvFormatNav
				advFormat={AdvFormats.PublicTransport}
				advAddDisabled={isLoading}
				onBack={goToSelectors}
				onAddFormat={handleAddFormat}
				isFormatChanged={true}
				totalAdvVariants={publicTransportVariants.length}
			/>
		</AdvFormatContainer>
	)
}
