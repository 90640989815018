import React, { useEffect, useMemo, useRef, useState } from 'react'
import { CodeConfirmationFormData, CodeResendData } from '../../../model/types'
import { InputGroup } from 'react-bootstrap'
import { Form, Button } from 'react-bootstrap'
import { Formik, Form as FormikForm, FormikHelpers } from 'formik'
import s from './resetStages.module.scss'
import { PhoneConfirmationVariant } from 'src/stores/userData'
import PhoneFormatter from 'src/utils/phone-formatter'
import TimeFormatter from 'src/utils/time-formatter'
import { BackButton } from '../../common/BackButton'

const getConfirmationHelperText = (
	variant: PhoneConfirmationVariant,
	phone: string
) => {
	const texts: Record<PhoneConfirmationVariant, string> = {
		phone: `В течение минуты на номер ${PhoneFormatter(
			phone
		)} поступит звонок. Введите последние 4 цифры номера, с которого звонят.`,
		sms: `В течение минуты на номер ${PhoneFormatter(
			phone
		)} придет СМС с кодом`,
	}

	return texts[variant]
}

const timerText: Record<PhoneConfirmationVariant, string> = {
	phone: 'Звонок не поступил? ',
	sms: 'Смс не пришло? ',
}

type Props = {
	phoneNumber: string
	expirationDateKey: string
	lastConfirmationVariantKey: string

	onSubmit: (
		values: CodeConfirmationFormData,
		formikHelpers: FormikHelpers<CodeConfirmationFormData>,
		confirmationVariant: PhoneConfirmationVariant
	) => Promise<void>
	onResendConfirmationCode: (values: CodeResendData) => Promise<void>
	onBack: () => void
}

export const CodeConfirmation = ({
	phoneNumber,
	expirationDateKey,
	lastConfirmationVariantKey,

	onSubmit,
	onResendConfirmationCode,
	onBack,
}: Props) => {
	// Ссылка для хранения идентификатора таймера
	const timerRef = useRef<NodeJS.Timer | null>(null)

	//информация о последнем использованном варианте отправки сообщения, контролируется родителем
	const lastConfirmationVariant = sessionStorage.getItem(
		lastConfirmationVariantKey
	) as PhoneConfirmationVariant | null

	const [timeLeft, setTimeLeft] = useState<number | null>(null)

	const [confirmationVariant, setConfirmationVariant] =
		useState<PhoneConfirmationVariant>(lastConfirmationVariant || 'sms')

	// Функция для запуска таймера
	const startTimer = (expirationDate: Date) => {
		const now = new Date()
		const remainingTime = Math.floor(
			(expirationDate.getTime() - now.getTime()) / 1000
		)

		if (remainingTime > 0) {
			setTimeLeft(remainingTime)

			// Очищаем предыдущий таймер, если он существует
			if (timerRef.current) {
				clearInterval(timerRef.current)
			}

			// Устанавливаем новый таймер
			timerRef.current = setInterval(() => {
				setTimeLeft((prevTimeLeft) => {
					if (prevTimeLeft !== null && prevTimeLeft > 0) {
						return prevTimeLeft - 1
					} else {
						if (timerRef.current) {
							clearInterval(timerRef.current)
						}
						return null
					}
				})
			}, 1000)
		} else {
			setTimeLeft(0)
		}
	}

	const handleCodeChange = async (
		e,
		setFieldValue: (
			field: string,
			value: any,
			shouldValidate?: boolean
		) => void,
		submitForm: (() => Promise<void>) & (() => Promise<any>)
	) => {
		const value = e.target.value

		setFieldValue('code', value)

		// Автоматически вызываем submitForm, если длина значения равна 4
		if (value.length === 4) {
			await submitForm()
		}
	}

	const confirmationHelperText = useMemo(() => {
		return getConfirmationHelperText(confirmationVariant, phoneNumber)
	}, [confirmationVariant])

	const timerHelperText = useMemo(() => {
		if (timeLeft && timeLeft > 1) {
			return `${
				timerText[confirmationVariant]
			} Можно повторить через ${TimeFormatter(timeLeft)}`
		}
	}, [timeLeft, confirmationVariant])

	const handleCodeSend = async (
		values: CodeConfirmationFormData,
		formikHelpers: FormikHelpers<CodeConfirmationFormData>
	) => {
		await onSubmit(values, formikHelpers, confirmationVariant)
	}

	const handleResendCode = async (variant: PhoneConfirmationVariant) => {
		await onResendConfirmationCode({
			phone_number: phoneNumber,
			confirmType: variant,
		})

		// Устанавливаем новый тип подтверждения и запускаем таймер
		setConfirmationVariant(variant)
		const newCodeSendingDate = sessionStorage.getItem(expirationDateKey)

		if (newCodeSendingDate) {
			const expirationDate = new Date(newCodeSendingDate)
			startTimer(expirationDate)
		}
	}

	useEffect(() => {
		const newCodeSendingDate = sessionStorage.getItem(expirationDateKey)

		if (newCodeSendingDate) {
			const expiredDate = new Date(newCodeSendingDate)
			startTimer(expiredDate)
		}

		// Очистка таймера при размонтировании компонента
		return () => {
			if (timerRef.current) {
				clearInterval(timerRef.current)
			}
		}
	}, [])

	return (
		<div>
			<p className={s.conformationHelper}>{confirmationHelperText}</p>
			<Formik<CodeConfirmationFormData>
				initialValues={{
					code: '',
				}}
				onSubmit={handleCodeSend}
			>
				{({ errors, setFieldValue, isSubmitting, submitForm }) => (
					<FormikForm>
						<div className={s.formContent}>
							<div className={s.inputs}>
								<Form.Group controlId="confirmationCode">
									<Form.Label>Код подтверждения</Form.Label>
									<InputGroup
										className="d-flex flex-nowrap"
										style={{ height: '48px', margin: 0 }}
									>
										<Form.Control
											disabled={isSubmitting}
											type={'text'}
											placeholder="••••"
											aria-label="Код подтверждения"
											aria-describedby="confirmationCode"
											isInvalid={!!errors.code}
											style={{
												borderRadius: '16px',
												borderColor: '#CED4DA',
											}}
											onChange={(e) =>
												handleCodeChange(
													e,
													setFieldValue,
													submitForm
												)
											}
										/>
									</InputGroup>

									<span className={s.timerHelper}>
										{timerHelperText}
									</span>

									<Form.Control.Feedback
										type="invalid"
										style={{
											display: errors?.code
												? 'block'
												: 'none',
										}}
									>
										{errors.code}
									</Form.Control.Feedback>
								</Form.Group>

								<div className={s.resendCodeVariants}>
									<Button
										disabled={!!timeLeft || isSubmitting}
										variant="text"
										onClick={() =>
											handleResendCode('phone')
										}
									>
										Получить код по звонку
									</Button>
									<Button
										disabled={!!timeLeft || isSubmitting}
										variant="text"
										onClick={() => handleResendCode('sms')}
									>
										Прислать код из смс
									</Button>
								</div>
							</div>
							<BackButton
								disabled={isSubmitting}
								onBack={onBack}
							/>
						</div>
					</FormikForm>
				)}
			</Formik>
		</div>
	)
}
