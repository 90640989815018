
import React,{ FC } from 'react'

const CampaignDurationIcon: FC = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M11 9.58579V5H9V9.58579C9 10.1162 9.21071 10.6249 9.58579 11L12.7929 14.2071L14.2071 12.7929L11 9.58579Z"
			fill="#212529"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18Z"
			fill="#212529"
		/>
	</svg>
)

const CampaignStartEndIcon: FC = () => (
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7 0V2H11V0H13V2H17C17.5523 2 18 2.44772 18 3V17C18 17.5523 17.5523 18 17 18H1C0.447715 18 0 17.5523 0 17V3C0 2.44772 0.447715 2 1 2H5V0H7ZM15.999 10H1.999L2 16H16L15.999 10ZM13 12C13.5523 12 14 12.4477 14 13C14 13.5523 13.5523 14 13 14C12.4477 14 12 13.5523 12 13C12 12.4477 12.4477 12 13 12ZM5 4H2L1.999 8H15.999L16 4H13V6H11V4H7V6H5V4Z"
			fill="#212529"
		/>
	</svg>
)

const CampaignBudgetIcon: FC = () => (
	<svg
		width="18"
		height="20"
		viewBox="0 0 18 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M0 1C0 0.447715 0.447715 0 1 0H17C17.5523 0 18 0.447715 18 1V17C18 17.5523 17.5523 18 17 18H16V20H14V18H4V20H2V18H1C0.447715 18 0 17.5523 0 17V1ZM2 2V16H16V12H14V13C14 13.5523 13.5523 14 13 14H5C4.44772 14 4 13.5523 4 13V5C4 4.44772 4.44772 4 5 4H13C13.5523 4 14 4.44772 14 5V6H16V2H2ZM14 10H16V8H14V10ZM6 6V8H8V10H6V12H12V6H6Z"
			fill="#212529"
		/>
	</svg>
)

export { CampaignBudgetIcon, CampaignDurationIcon, CampaignStartEndIcon }
