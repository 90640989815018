import React from 'react'
import { PlacementFilterType } from '../../../constants'
import { FilterItem } from './FilterItem'
import { PlacementFilterKeyType } from '../../../constants/placementFilters'

type FiltersItemListProps<T> = {
	filters: Array<PlacementFilterType>
	onDeleteFilter?: (filterKey: PlacementFilterKeyType) => void
	searchObject?: T
}

export const FiltersItemList = <T extends {}>({
	filters,
	onDeleteFilter,
	searchObject,
}: FiltersItemListProps<T>) => {
	const activeFiltres = filters.filter(
		(filter) => filter.filterItems.length > 0
	)

	return (
		<div
			style={{
				display: 'flex',
				flexWrap: 'wrap',
				gap: '4px',
			}}
		>
			{activeFiltres.map((filter) => (
				<FilterItem
					searchObject={searchObject}
					onDeleteFilter={onDeleteFilter}
					key={filter.key}
					filter={filter}
				/>
			))}
		</div>
	)
}
