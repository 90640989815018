import React, { useState } from 'react'
import { CampaignReportContent } from '../../campaign-info/common/campaign-report/CampaignReportCard'
import { ExpandedStatistics } from './ExpandedStatistics'
import s from './ClientStatistics.module.scss'
import { ReactComponent as StatsIcon } from 'src/assets/images/invoice-1.svg'
import { Button } from 'react-bootstrap'

import cn from 'classnames'
import { ConditionArrowUpDown } from 'src/components/UtilityComponents'

type Props = {
	row: any
	expandedData: any
}
export const ExpandedRow = ({ row, expandedData }: Props) => {
	const [isStatsOpen, setIsStatsOpen] = useState(false)
	return (
		<div
			className={cn(s.expandedRow, {
				[s.expandedRowShadowed]: !isStatsOpen,
			})}
		>
			<div
				style={{
					padding: '12px',
				}}
			>
				<CampaignReportContent
					campaignId={row.id}
					classname={s.reportRow}
				/>
			</div>

			<ExpandedStatisticsPreview
				expandedData={expandedData}
				row={row}
				open={isStatsOpen}
				setIsOpen={setIsStatsOpen}
			/>
		</div>
	)
}

type PreviewProps = {
	row: any
	expandedData: any
	open: boolean
	setIsOpen: (open: boolean) => void
}
export const ExpandedStatisticsPreview = ({
	row,
	expandedData,
	open,
	setIsOpen,
}: PreviewProps) => {
	return (
		<div
			style={{
				borderTop: '1px solid #CED4DA',
			}}
		>
			<div
				className="d-flex justify-content-between"
				style={{ padding: '12px', borderBottom: '1px solid #CED4DA' }}
			>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						gap: '1rem',
					}}
				>
					<div
						style={{
							width: '24px',
							height: '24px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<StatsIcon style={{ width: '16px', height: '16px' }} />
					</div>

					<h5
						style={{
							margin: 0,
							fontSize: '14px',
							lineHeight: '28px',
							letterSpacing: '-0.02em',
							fontWeight: 500,
						}}
					>
						Расширенная cтатистика
					</h5>
				</div>
				<Button
					variant={'light'}
					style={{
						borderRadius: '16px',
						fontSize: '14px',
						display: 'flex',
						alignItems: 'center',
						gap: '0.25rem',
						height: '40px',
						padding: '8px 16px',
					}}
					onClick={() => setIsOpen(!open)}
				>
					<ConditionArrowUpDown
						open={open}
						styles={{
							width: '20px',
							height: '20px',
							marginBottom: !open ? '2px' : 'none',
						}}
					/>
					<h6
						style={{
							margin: 0,
							fontSize: '14px',
							letterSpacing: '-0.04em',
						}}
					>
						{open ? 'Свернуть' : 'Показать'}
					</h6>
				</Button>
			</div>
			{open && (
				<ExpandedStatistics
					campaignState={row.state}
					expandedData={expandedData[row.id]}
					key={row.id + '-stats'}
				/>
			)}
		</div>
	)
}
