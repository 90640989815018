import React, { useMemo, useState } from 'react'
import { PlacementShort, PlacementType } from 'src/stores/ADMarketTypes.types'
import { MassCoverageItemsControl } from './selected-items-control'
import { MassCoverageDetailedPlacement } from './detailed-placement'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { motion } from 'framer-motion'
import { getDetailedItemActionType } from '../../../utils'
import { Address } from '../modals/MassCoverageModalMap'

type Props = {
	searchAddresses: Address[] | null
	onAddItem: (placementId: number) => void
	onRemoveItem: (itemId: number) => void
	addedPlacement: PlacementShort[]
	onRadiusChange: (radius: number) => void
	radius: number
	placements: PlacementShort[]
	detailedPlacement: PlacementType | undefined
	onPlacementClick: (placement: PlacementShort) => void
	setDetailedPlacement: (placement: PlacementType | undefined) => void
	onAddPlacements: () => void
	isPlacementsTheSame: boolean
	onSearchStringChange: (searchString: string) => void
	isFooterDisabled: boolean
	itemsCount: number
	onAddressClick: (address: Address) => void
}
export const MassCoverLeftPannel = ({
	onRadiusChange,
	radius,
	placements,
	onPlacementClick,
	detailedPlacement,
	setDetailedPlacement,
	onAddPlacements,
	isPlacementsTheSame,
	onSearchStringChange,
	isFooterDisabled,
	itemsCount,
	addedPlacement,
	onRemoveItem,
	onAddItem,
	searchAddresses,
	onAddressClick,
}: Props) => {
	const [isExpanded, setIsExpanded] = useState(true)

	const isAlreadyAdded = useMemo(
		() => !!addedPlacement.find((el) => el.pk === detailedPlacement?.id),
		[addedPlacement, detailedPlacement]
	)
	const handleDetailedAction = () => {
		if (isAlreadyAdded) {
			onRemoveItem(detailedPlacement?.id || 0)
			return
		}

		if (detailedPlacement) {
			onAddItem(detailedPlacement.id)
		}
	}

	return (
		<motion.div
			initial={{ x: '-100%' }}
			animate={{ x: isExpanded ? '0%' : '-100%' }}
			style={{
				width: '28%',
				maxWidth: '400px',
				position: 'absolute',
				height: '100%',
				maxHeight: '100%',
				top: 0,
				left: 0,
				zIndex: 9999,
				background: 'white',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'space-between',
			}}
		>
			<TogglePannel
				isExpanded={isExpanded}
				setIsExpanded={setIsExpanded}
			/>

			{detailedPlacement ? (
				<MassCoverageDetailedPlacement
					actionType={getDetailedItemActionType(isAlreadyAdded, true)}
					onClick={handleDetailedAction}
					setDetailedPlacement={setDetailedPlacement}
					placement={detailedPlacement}
				/>
			) : (
				<MassCoverageItemsControl
					onAddressClick={onAddressClick}
					searchAddresses={searchAddresses}
					addedPlacement={addedPlacement}
					isFooterDisabled={isFooterDisabled}
					onSearchStringChange={onSearchStringChange}
					isPlacementsTheSame={isPlacementsTheSame}
					onPlacementClick={onPlacementClick}
					onRadiusChange={onRadiusChange}
					placements={placements}
					radius={radius}
					onAddPlacements={onAddPlacements}
					itemsCount={itemsCount}
				/>
			)}
		</motion.div>
	)
}
type TogglePannelProps = {
	isExpanded: boolean
	setIsExpanded: (isExpanded: boolean) => void
}
const TogglePannel = ({ isExpanded, setIsExpanded }: TogglePannelProps) => {
	return (
		<OverlayTrigger
			placement="right"
			overlay={(props) => (
				<Tooltip id="expand" {...props}>
					{isExpanded ? 'Свернуть' : 'Развернуть'}
				</Tooltip>
			)}
		>
			<div
				style={{
					position: 'absolute',
					top: '24px',
					right: '-68px',
					zIndex: 2,
					borderRadius: '50%',
					backgroundColor: '#fff',
					width: '48px',
					height: '48px',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					cursor: 'pointer',
				}}
				onClick={() => setIsExpanded(!isExpanded)}
			>
				<i
					style={{
						transform: isExpanded
							? 'rotate(0deg)'
							: 'rotate(-180deg)',
						transition: 'transform .5s ease-in-out',
						width: '24px',
					}}
				>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M3.4938 8.00012L9.34743 2.42896L7.96861 0.980225L0.310587 8.2687C-0.103212 8.66253 -0.103578 9.32238 0.309783 9.71667L7.96781 17.0214L9.34824 15.5742L3.50459 10.0001L19.9999 10.0012L20.0001 8.00118L11.7469 8.00065L3.4938 8.00012Z"
							fill="#212529"
						/>
					</svg>
				</i>
			</div>
		</OverlayTrigger>
	)
}
