import { CampaignInfoTypeBookingsSeparation } from 'src/stores/ADMarketTypes.types'

export const checkIsAtLeastOneFormatSelected = (
	campaign: CampaignInfoTypeBookingsSeparation
): boolean => {
	return (
		campaign.outdoor_adv_is_selected ||
		campaign.district_adv_is_selected ||
		campaign.indoor_adv_is_selected ||
		campaign.internet_vk.is_selected ||
		campaign.internet_yandex.is_selected ||
		campaign.adstream.is_selected ||
		!!campaign.public_transport?.is_selected
	)
}
