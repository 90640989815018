import React from 'react'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { Cell, Table } from './Table/Table'
import { CampaignStates } from 'src/stores/ADMarketTypes.types'

const TITLES = {
	outdoor: 'Наружная реклама - Гарантированные просмотры',
	districtbooking: 'Наружная реклама - Максимальный охват',
	indoor: 'Внутренняя реклама',
	internetAD: 'Интернет реклама',
	tvAD: 'TV-реклама',
}

const PLACES = {
	vk: 'VK Реклама',
	adstream: 'Adstream',
	teletarget: 'Teletarget',
	yandex: 'Яндекс',
}

const FORMATS = {
	vk: 'Баннер',
	adstream: 'Видео',
	teletarget: 'Баннер',
	districtbooking: 'Видео',
	yandex: 'Баннер',
}

type Props = {
	expandedData: any
	campaignState: CampaignStates
}
export function ExpandedStatistics({ expandedData, campaignState }: Props) {
	const data = Object.entries(expandedData)
		.map(([key, val]: [any, any]) => {
			if (key === 'totals') return null

			if (key === 'bookingAD') {
				const getPlace = (bookingItem) => (
					<>
						{bookingItem.placement.placement_type.name}
						<br />
						{bookingItem.placement.name}
					</>
				)
				const getBookingRow = (type) =>
					val[type]?.map((x, i) => ({
						type,
						title: TITLES[type],
						place: getPlace(x),
						format: 'Видео',
						factCost: x.fact_cost,
						amount: IntlFormatter.format(x.amount, false),
						views_count: x.views_count || 0,
						click_count: x.click_count || 0,
						booking_budget: IntlFormatter.format(
							x?.booking_budget || 0,
							false
						),
						isFirstInGroup: i === 0,
						isLastInGroup: i === val[type].length - 1,
					}))

				return [
					val.indoor && getBookingRow('indoor'),
					val.outdoor && getBookingRow('outdoor'),
				].flat()
			}

			if (key === 'districtBooking') {
				if (val?.['districtbooking'].length > 0) {
					return val['districtbooking'].map((el, i) => {
						return {
							type: key,
							title: TITLES['districtbooking'],
							place: el?.city_name,
							format: 'Видео',
							factCost: el.fact_cost,
							amount: IntlFormatter.format(el.amount, false),
							views_count: el.views_count || 0,
							click_count: el.click_count || 0,
							booking_budget: IntlFormatter.format(
								el.amount,
								false
							),
							isFirstInGroup: i === 0,
							isLastInGroup:
								i === val['districtbooking'].length - 1,
						}
					})
				}
			} else if (key === 'public_transport') {
				if (val?.is_selected) {
					return {
						type: key,
						title: 'Общественный транспорт',
						place: 'Цифровые экраны на МЦК',
						format: val?.image ? 'Баннер' : 'Видео',
						factCost: val.fact_cost,
						amount: IntlFormatter.format(val.amount, false),
						views_count: val.views_count || 0,
						click_count: val.click_count || 0,
						booking_budget: IntlFormatter.format(val.amount, false),
						isFirstInGroup: true,
						isLastInGroup: false,
					}
				}
			} else {
				const places: [string, any][] = Object.entries(val)
				if (places.length === 0) return null

				return places.map(([placeKey, place], i) => ({
					type: key,
					title: TITLES[key],
					place: PLACES[placeKey],
					format: FORMATS[placeKey],
					factCost: place.fact_cost,
					amount: IntlFormatter.format(place.amount, false),
					views_count: place.views_count,
					click_count: place.click_count,
					booking_budget: IntlFormatter.format(place.amount, false),
					isFirstInGroup: i === 0,
					isLastInGroup: i === places.length - 1,
				}))
			}
		})
		.flat()
		.filter(Boolean)

	const cols = [
		{
			key: 'empty',
			width: 48,
			noPadding: true,
			isHeader: true,
			noBorderBottom: true,
		},
		{
			key: 'type',
			title: 'Тип рекламы',
			cell: (row) => (row.isFirstInGroup ? row.title : void 7),
			noBorderBottom: (row) => !row.isLastInGroup,
			width: 200,
		},
		{
			key: 'place',
			title: 'Место размещения',
			cell: (row) => row.place,
			width: 396,
		},
		{
			key: 'format',
			title: 'Формат',
			cell: (row) => row.format,
			width: 100,
		},
		{
			key: 'views_count',
			title: 'Показы',
			cell: (row) => row.views_count,
			faded: true,
			justify: 'right',
			width: 140,
		},
		{
			key: 'click_count',
			title: 'Клики',
			cell: (row) => row.click_count,
			faded: true,
			justify: 'right',
			width: 140,
		},
		{
			key: 'booking_budget',
			cell: (row) => row.booking_budget,
			title: 'Расход (план)',
			faded: true,
			justify: 'right',
			width: 140,
		},
	]

	if (campaignState === CampaignStates.Completed) {
		cols.push({
			key: 'fact_cost',
			cell: (row) =>
				row.factCost !== null
					? IntlFormatter.format(row.factCost, false)
					: 0,
			title: 'Расход (Факт)',
			faded: true,
			justify: 'right',
			width: 140,
		})
	}

	const sumData = {
		format: 'Итого:',
		views_count: expandedData.totals.shows,
		click_count: expandedData.totals.clicks,
		booking_budget: IntlFormatter.format(expandedData.totals.amount, false),
		fact_cost: IntlFormatter.format(expandedData.totals.fact_cost, false),
	}

	return (
		<Table
			data={data}
			cols={cols}
			headerCell={(col, i) => {
				const rightDirectedCols = [
					'views_count',
					'click_count',
					'booking_budget',
				]

				return (
					<Cell
						content={
							col.title ? (
								<>
									<Cell
										content={col.title}
										noBorderRight
										noBorderBottom={[
											'type',
											'place',
										].includes(col.key)}
										justify={
											rightDirectedCols.includes(col.key)
												? 'right'
												: void 7
										}
										isHeader
									/>
									<Cell
										content={
											col.key in sumData ? (
												sumData[col.key]
											) : (
												<>&nbsp;</>
											)
										}
										justify={
											[
												...rightDirectedCols,
												'format',
												'booking_budget',
											].includes(col.key)
												? 'right'
												: void 7
										}
										noBorder
									/>
								</>
							) : (
								void 7
							)
						}
						noBorderRight={i === cols.length - 1}
						noBorderBottom={i === 0 || !data.length}
						direction={'column'}
						isHeader
						noPadding
					/>
				)
			}}
			wrapRow={(renderedRow, row, i) => {
				return (
					<>
						{renderedRow}
						{row.isLastInGroup && i !== data.length - 1 && (
							<div
								style={{
									background: '#f5f5f5',
									height: 4,
									borderLeft: '1px solid #dee2e6',
									borderRight: '1px solid #dee2e6',
								}}
							/>
						)}
					</>
				)
			}}
			noHover
			noBorderLeft
			noBorderRight
			noBorderTop
		/>
	)
}
