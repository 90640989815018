import React, { FC, useMemo } from 'react'
import useCampaignData from '../../../stores/campaignData'
import {
	CampaignBudgetIcon,
	CampaignDurationIcon,
	CampaignStartEndIcon,
} from './icons/CampaignDurationsIcons'
import useResponsive from '../../../utils/useResponsive'
import declOfNum from '../../../utils/declOfNum'
import intlMoneyFormatter from '../../../utils/intl-money-formatter'
import s from './CampaignInfo.module.scss'
import { Dropdown } from 'react-bootstrap'
import { CampaignStates } from 'src/stores/ADMarketTypes.types'
import { ReactComponent as PaymentIcon } from 'src/assets/images/rouble.svg'
import cn from 'classnames'
import IntlFormatter from '../../../utils/intl-money-formatter'

type DataType = {
	icon: FC
	title: string
	text: string
	showTitle: boolean
	tooltip?: string
}

const CampaignDuration: FC = () => {
	const [campaign, campaignInterface] = useCampaignData()
	const { isMobile } = useResponsive()

	//Для заверешенной кампании в бюджете показываем сумму всех всех выбранных форматов рекламы НДС
	const budget =
		campaign.state === CampaignStates.Completed
			? campaign.budget_vat.total
			: campaign.budget.total
	//Для заверешенной кампании в Фактической стоимости показываем итоговую подтвержденную сумму с НДС, иначе показываем сколько стоит запуск РК с НДС
	const factCost =
		campaign.state === CampaignStates.Completed
			? campaign.confirmed_budget_vat
			: campaign.budget_vat.total

	const data = useMemo<DataType[]>(() => {
		return [
			{
				icon: CampaignStartEndIcon,
				title: 'Дата начала и окончания:',
				text: campaignInterface.getCampaignPeriod(),
				showTitle: !isMobile,
			},
			{
				icon: CampaignDurationIcon,
				title: 'Срок размещения:',
				text: `${campaign.days_duration} ${declOfNum(
					campaign.days_duration,
					['день', 'дня', 'дней']
				)}`,
				showTitle: true,
			},
			{
				icon: CampaignBudgetIcon,
				title: 'Бюджет кампании:',
				text: intlMoneyFormatter.format(budget),
				showTitle: true,
			},
		]
	}, [campaign, isMobile]) // eslint-disable-line react-hooks/exhaustive-deps

	const extendedData = {
		[CampaignStates.Paid]: {
			title: 'Оплачено',
			icon: PaymentIcon,
			text: IntlFormatter.format(campaign.sum_payed_payments || '0'),
		},
		[CampaignStates.Payment_waiting]: {
			title: 'К оплате',
			icon: PaymentIcon,
			text: IntlFormatter.format(parseFloat(campaign.budget_vat.total)),
		},
		[CampaignStates.Completed]: {
			title: 'Фактическая стоимость',
			icon: PaymentIcon,
			text: IntlFormatter.format(factCost),
		},
	}

	const isExtended = !!extendedData[campaign.state]

	const lastDataIndex = data.length - (isExtended ? 0 : 1)

	if (isMobile)
		return (
			<div className={s.campaignDurationContainerMobile}>
				{data.map((d, index) => (
					<React.Fragment key={d.title}>
						<div>
							<div
								style={{
									display: 'grid',
									placeItems: 'center',
								}}
							>
								<d.icon />
							</div>
							<div className={'ms-2 w-100 text-dark'}>
								{!d.showTitle ? (
									d.text
								) : (
									<div
										className={
											'w-100 d-flex justify-content-between'
										}
									>
										<span>{d.title}</span>
										<span>{d.text}</span>
									</div>
								)}
							</div>
						</div>
						{index !== lastDataIndex && (
							<Dropdown.Divider className={'m-0 p-0'} />
						)}
					</React.Fragment>
				))}
				{isExtended && (
					<div>
						<div
							style={{
								display: 'grid',
								placeItems: 'center',
							}}
						>
							<PaymentIcon />
						</div>
						<div className={'ms-2 w-100 text-dark'}>
							<div
								className={
									'w-100 d-flex justify-content-between'
								}
							>
								<span>
									{' '}
									{extendedData[campaign.state]?.title}
								</span>
								<span>
									{' '}
									{extendedData[campaign.state]?.text}
								</span>
							</div>
						</div>
					</div>
				)}
			</div>
		)

	return (
		<div
			className={cn(s.campaignDurationContainer, {
				[s.campaignDurationContainer_extended]: isExtended,
			})}
		>
			{data.map((d, index) => (
				<div key={d.title}>
					<div className={'d-flex align-items-center'}>
						<span
							style={{
								marginRight: '20px',
								display: 'grid',
								placeItems: 'center',
							}}
						>
							{<d.icon />}
						</span>
						{d.showTitle && (
							<span style={{ whiteSpace: 'nowrap' }}>
								{d.title}
							</span>
						)}
					</div>

					<span
						style={{
							fontSize: '20px',
							marginLeft: '38px',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
						}}
					>
						{d.text}
					</span>
				</div>
			))}
			{isExtended && (
				<div>
					<div className={'d-flex align-items-center'}>
						<span
							style={{
								marginRight: '20px',
								display: 'grid',
								placeItems: 'center',
							}}
						>
							{<PaymentIcon />}
						</span>

						<span style={{ whiteSpace: 'nowrap' }}>
							{extendedData[campaign.state]?.title}
						</span>
					</div>

					<span
						style={{
							fontSize: '20px',
							marginLeft: '38px',
							whiteSpace: 'nowrap',
							textOverflow: 'ellipsis',
						}}
					>
						{extendedData[campaign.state]?.text}
					</span>
				</div>
			)}
		</div>
	)
}

export default CampaignDuration
