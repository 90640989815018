/* eslint-disable */
import React, { useCallback, useEffect, useRef } from 'react'
import locationPlacemark from '../../assets/images/location-placemark.svg'
import icon_billboard from '../../assets/images/icon_billboard.svg'
import { useEventListeners } from '../../utils/useEventListeners'
import '../../styles/map.scss'
import getPlacementIcon from '../_client/create-campaign/Step2-ADformats/BookingsAD/PlacementPicker/getPlacementIcon'
import { PlacementShortWithStateAndFocus } from '../_client/create-campaign/Step2-ADformats/BookingsAD/BookingAD'
// @ts-ignore

const ymaps = window.ymaps

const noEkran = (str) => {
	const reg = /\\/gm
	return str.replace(reg, '')
}
export type YmapProps = {
	data?: PlacementShortWithStateAndFocus[]
	width?: number | string
	height?: number | string
	onPlacemarkClick?: (id: number) => any
	address?: string
	area?: boolean
	fixed?: boolean
	zoom?: number
	onAddressFound?: (coords: number[]) => void
	buisnessCoords?: any[] | null
	focusCenter?: [number, number]
	withZoomControls?: boolean
	isMobile?: boolean
	changeCenter?: string[] | null
	onClick?: () => void
	smallData?: any
	bigBuisnessLabel?: boolean | string
	placementsType?: 'all' | 'outdoor' | 'indoor'
}

const LOCATION_PLACEMARK_OPTIONS = {
	iconLayout: 'default#image',
	iconImageHref: locationPlacemark,
	iconImageSize: [40, 40],
	iconImageOffset: [-20, -20],
	hintCloseTimeout: 100,
	hintOpenTimeout: 100,
}
const LOCATION_PLACEMARK_OPTIONS_DARK = {
	iconLayout: 'default#image',
	iconImageHref: icon_billboard,
	iconImageSize: [40, 40],
	iconImageOffset: [-20, -20],
	hintCloseTimeout: 100,
	hintOpenTimeout: 100,
}

const HARD_CODE_POLYGON = [
	[55.717704, 37.570306],
	[55.693084, 37.535973],
	[55.671165, 37.496491],
	[55.658163, 37.516404],
	[55.655299, 37.53975],
	[55.651805, 37.548848],
	[55.649476, 37.563439],
	[55.700476, 37.614937],
	[55.706377, 37.588158],
]
const POLYGON_CENTER = [55.682246, 37.555671]

export default function YandexMap({
	data,
	area,
	width = 400,
	height = 400,
	address = 'Moscow',
	onAddressFound,
	onPlacemarkClick,
	fixed,
	zoom = 10,
	focusCenter,
	buisnessCoords = null,
	withZoomControls = false,
	isMobile = false,
	changeCenter = null,
	onClick,
	smallData,
	bigBuisnessLabel = false,
	placementsType,
}: YmapProps) {
	const mapRef = useRef(null)
	const mapObjectRef = useRef<any>(null)
	const { addEventListener, cleanupListeners } = useEventListeners()
	let HintLayout = null
	let MyIconContentLayout = null
	let MyIconContentLayoutSelected = null
	let customBalloonContentLayout = null
	let customBaloonLayout = null
	let bigBuisnessBaloonLayout = null

	const focusCenterStart = focusCenter || buisnessCoords || POLYGON_CENTER
	useEffect(() => {
		ymaps.ready(() => {
			console.log('ymaps ready')
			mapObjectRef.current = new ymaps.Map(
				mapRef.current,
				{
					center: focusCenterStart,
					zoom,
					controls: [],
					margin: 50,
				},
				{
					searchControlProvider: 'yandex#search',
					yandexMapDisablePoiInteractivity: true,
					suppressMapOpenBlock: true,
					autoFitToViewport: 'always',
				}
			)
			//Тултип
			HintLayout = ymaps.templateLayoutFactory.createClass(
				"<div class='ymaps_my-hint'>" +
					'{{properties.hintContent}}' +
					'</div>',
				{
					// Определяем метод getShape, который
					// будет возвращать размеры макета хинта.
					// Это необходимо для того, чтобы хинт автоматически
					// сдвигал позицию при выходе за пределы карты.
					getShape: function () {
						let el = this.getElement(),
							result = null
						if (el) {
							let firstChild = el.firstChild
							result = new ymaps.shape.Rectangle(
								new ymaps.geometry.pixel.Rectangle([
									[0, 0],
									[
										firstChild.offsetWidth,
										firstChild.offsetHeight,
									],
								])
							)
						}
						return result
					},
				}
			)
			//Иконка объединения
			MyIconContentLayout = ymaps.templateLayoutFactory.createClass(
				'<div style="color: #000000; font-size: 16px; padding: 12px 4px;line-height: 20px; border: 2px solid #CED4DA; background: #FFFFFF; border-radius: 8px; transform: translateY(-40%); position:relative; font-weight: 500;">{{ properties.geoObjects.length }}' +
					'<div style="color: white; position:absolute; left: 100%; top: 100%; transform: translateX(-60%) translateY(-60%);border: 2px solid #FFFFFF; width: 24px; height: 24px; display: flex; align-items: center; justify-content: center; background-color:#007BFF; border-radius: 50%">' +
					'<i class="bi bi-plus d-flex align-items-center" />' +
					'</div>' +
					'</div>'
			)
			MyIconContentLayoutSelected =
				ymaps.templateLayoutFactory.createClass(
					'<div style="color: #FFFFFF; font-size: 16px; padding: 12px 4px;line-height: 20px; border: 2px solid #FFFFFF; background: #6C747F; border-radius: 8px; transform: translateY(-40%); position:relative; font-weight: 500;">{{ properties.geoObjects.length }}' +
						'</div>'
				)
			//Балун контент
			customBalloonContentLayout =
				ymaps.templateLayoutFactory.createClass(
					[
						"<div id='ymaps_cluster_baloon_content'>",
						"<div class='ymaps_cluster_baloon_content_container'>",
						"<div class='ymaps_cluster_baloon_content_header'> " +
							"<div class='ymaps_cluster_baloon_content_title'></div></div>",
						"<div class='ymaps_cluster_baloon_content_places'>",
						'{% for geoObject in properties.geoObjects %}',
						'<div class="ymaps_cluster_baloon_content_place" data-id="{{geoObject.properties.id}}" data-state="{{geoObject.properties.state}}">',
						'<div class="ymaps_cluster_baloon_content_place_icon">            {{ geoObject.properties.icon|default: geoObject.properties.icon|raw }}</div>',
						'<div class="ymaps_cluster_baloon_content_place_data">',
						"<div class='ymaps_cluster_baloon_content_place_data_title'>{{geoObject.properties.placement_type_name}}</div>",
						"<div class='ymaps_cluster_baloon_content_place_data_size'>{{geoObject.properties.size}}</div>",
						'</div>',
						'</div>',
						'{% endfor %}',
						'</div>',
						'</div>',
						'</div>',
					].join(''),
					{
						build: async function () {
							if (customBalloonContentLayout) {
								// @ts-ignore
								customBalloonContentLayout.superclass.build.call(
									this
								)
							}
							const coords =
								this._data.object.geometry.coordinates
							ymaps
								.geocode(coords, { results: 1 })
								.then((res: any) => {
									const firstGeoObject = res.geoObjects.get(0)
									if (firstGeoObject) {
										let fullAdress =
											firstGeoObject.getAddressLine()
										let country =
											firstGeoObject.getCountry() + ','
										//remove AdministrativeAreas
										firstGeoObject
											.getAdministrativeAreas()
											.forEach((str, index) => {
												fullAdress = fullAdress.replace(
													str + ',',
													' '
												)
												country += ` ${str}${
													index ===
													firstGeoObject.getAdministrativeAreas()
														.length -
														1
														? ''
														: ','
												}`
											})
										fullAdress = fullAdress
											.replace(
												firstGeoObject.getCountry() +
													',',
												''
											)
											.trim()
										// @ts-ignore
										document.querySelector(
											'#ymaps_cluster_baloon_content > div > div.ymaps_cluster_baloon_content_header > div'
										).innerHTML = fullAdress
									}
								})
						},
					}
				)
			//Балун
			customBaloonLayout = ymaps.templateLayoutFactory
				.createClass(`<div id="ymaps_baloon-container_{{properties.id}}" class="ymaps_baloon-container {{properties.state}} {{properties.focus}}"><div class="{{properties.state}} ymaps_booking-baloon {{properties.focus}}" data-focus="{{properties.focus}}"">
        <div class="icon">
            {{ properties.icon|default: properties.icon|raw }}
        </div>
        <div class="data">
            <div class="title">{{properties.placement_type_name}}</div>
            <div class="size">{{properties.size}}</div>
        </div>
    </div>
    <div class="dot"></div>
</div>`)
			//Балун координаты бизнеса большой
			bigBuisnessBaloonLayout = ymaps.templateLayoutFactory.createClass(
				`<div id="ymaps_big_adress_label" data-hover="{{properties.hover}}">
        <div class="icon">
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="20" fill="#fff"/>
<g clip-path="url(#clip0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 28C20 28 26 22.314 26 18C26 16.4087 25.3679 14.8826 24.2426 13.7574C23.1174 12.6321 21.5913 12 20 12C18.4087 12 16.8826 12.6321 15.7574 13.7574C14.6321 14.8826 14 16.4087 14 18C14 22.314 20 28 20 28ZM20 21C20.7956 21 21.5587 20.6839 22.1213 20.1213C22.6839 19.5587 23 18.7956 23 18C23 17.2044 22.6839 16.4413 22.1213 15.8787C21.5587 15.3161 20.7956 15 20 15C19.2044 15 18.4413 15.3161 17.8787 15.8787C17.3161 16.4413 17 17.2044 17 18C17 18.7956 17.3161 19.5587 17.8787 20.1213C18.4413 20.6839 19.2044 21 20 21Z" fill="#6C757D"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="16" height="16" fill="white" transform="translate(12 12)"/>
</clipPath>
</defs>
</svg>
        </div>
        <div class="data">
           <div class="title">Адрес вашего бизнеса</div>
            <div class="size">{{properties.adress}}</div>
        </div>
    </div>`
			)

			if (withZoomControls) drawZoomControls()
			if (fixed) fixedMap()
			if (area) {
				drawArea()
			}
			if (buisnessCoords) drawBuisnessCoords()

			if (isMobile) {
				if (onClick) {
					mapObjectRef.current.events.add('click', (e) => {
						onClick()
					})
				}
			}
		})

		return () => {
			ymaps.ready(() => {
				mapObjectRef.current.destroy()
			})
		}
	}, [])

	useEffect(() => {
		ymaps.ready(() => {
			if (data) {
				mapObjectRef.current.geoObjects.removeAll()
				drawData(data, placementsType)
				//Добавление метки адреса бизнеса
				if (buisnessCoords) drawBuisnessCoords()
			}
		})
	}, [data, placementsType])
	useEffect(() => {
		ymaps.ready(() => {
			if (smallData) {
				mapObjectRef.current.geoObjects.removeAll()
				if (area) drawArea()
				if (buisnessCoords) drawBuisnessCoords()
				drawSmallData(smallData, buisnessCoords)
			}
		})
	}, [smallData])

	const drawZoomControls = useCallback(() => {
		const ZoomLayout = ymaps.templateLayoutFactory.createClass(
				'<div>' +
					"<div id='ymaps_zoom-in' class='btn'><i class=\"bi bi-plus\"></i></div>" +
					"<div id='ymaps_zoom-out' class='btn'><i class=\"bi bi-dash\"></i></div>" +
					'</div>',
				{
					// Переопределяем методы макета, чтобы выполнять дополнительные действия
					// при построении и очистке макета.
					build: function () {
						// Вызываем родительский метод build.
						ZoomLayout.superclass.build.call(this)

						// Привязываем функции-обработчики к контексту и сохраняем ссылки
						// на них, чтобы потом отписаться от событий.
						this.zoomInCallback = ymaps.util.bind(this.zoomIn, this)
						this.zoomOutCallback = ymaps.util.bind(
							this.zoomOut,
							this
						)

						// Начинаем слушать клики на кнопках макета.
						// @ts-ignore
						document
							.getElementById('ymaps_zoom-in')
							.addEventListener('click', this.zoomInCallback)
						// @ts-ignore
						document
							.getElementById('ymaps_zoom-out')
							.addEventListener('click', this.zoomOutCallback)
					},

					clear: function () {
						ZoomLayout.superclass.clear.call(this)
					},

					zoomIn: function () {
						const map = this.getData().control.getMap()
						map.setZoom(map.getZoom() + 1, { checkZoomRange: true })
					},

					zoomOut: function () {
						const map = this.getData().control.getMap()
						map.setZoom(map.getZoom() - 1, { checkZoomRange: true })
					},
				}
			),
			zoomControl = new ymaps.control.ZoomControl({
				options: {
					layout: ZoomLayout,
					position: isMobile
						? {
								right: 8,
								top: 250,
						  }
						: {
								bottom: 24,
								left: 24,
						  },
				},
			})
		mapObjectRef.current.controls.add(zoomControl)
	}, [])

	const fixedMap = useCallback(() => {
		mapObjectRef.current.behaviors.disable([
			'drag',
			'dblClickZoom',
			'rightMouseButtonMagnifier',
			'multiTouch',
			'scrollZoom',
		])
	}, [])

	const drawBuisnessCoords = useCallback(() => {
		if (bigBuisnessLabel) {
			if (typeof bigBuisnessLabel !== 'boolean') {
				const placemark = new ymaps.Placemark(
					buisnessCoords,
					{
						adress: bigBuisnessLabel.replace('г Москва,', ''),
					},
					{
						iconLayout: bigBuisnessBaloonLayout,
						iconShape: {
							type: 'Rectangle',
							coordinates: [
								[0, 0],
								[240, 56],
							],
						},
						iconOffset: [-120, -80],
					}
				)
				placemark.events.add('mouseenter', (e) => {
					return e.get('target').properties.set('hover', 'hover')
				})
				placemark.events.add('mouseleave', (e) => {
					return e.get('target').properties.set('hover', '')
				})
				mapObjectRef.current.geoObjects.add(placemark)
			}
		} else {
			const placemark = new ymaps.Placemark(
				buisnessCoords,
				{
					openHintOnHover: true,
					hintContent: `Адрес вашего бизнеса`,
				},
				{
					...LOCATION_PLACEMARK_OPTIONS,
					hintLayout: HintLayout,
				}
			)
			mapObjectRef.current.geoObjects.add(placemark)
		}
	}, [])

	const drawArea = useCallback(() => {
		const polygon = new ymaps.Polygon(
			[HARD_CODE_POLYGON],
			{},
			{
				fillColor: '007BFF',
				interactivityModel: 'default#transparent',
				strokeWidth: 2,
				strokeColor: '007BFF',
				fillOpacity: 0.1,
			}
		)
		mapObjectRef.current.geoObjects.add(polygon)
	}, [])

	const drawData = useCallback((data, placementsType) => {
		const objectManager = new ymaps.ObjectManager({
			clusterize: true,
			gridSize: 128,
			hasHint: false,
			clusterIconContentLayout: MyIconContentLayout,
			clusterBalloonContentLayout: customBalloonContentLayout,
			// clusterBalloonCloseButton: false,
			clusterBalloonShadow: true,
			clusterIcons: [
				{
					href: '',
					size: [40, 40],
					offset: [-20, -20],
				},
				{
					href: '',
					size: [60, 60],
					offset: [-30, -30],
				},
			],
		})
		const json: any = {
			type: 'FeatureCollection',
			features: [],
		}
		for (let i = 0; i < data.length; i++) {
			const properties: any = {
				...data[i],
				icon: getPlacementIcon(data[i]?.image_type, data[i]?.is_active),
				id: data[i]?.pk,
			}
			const options: any = {
				iconLayout: customBaloonLayout,
				iconShape: {
					type: 'Rectangle',
					coordinates: [
						[0, 0],
						[186, 56],
					],
				},
				iconOffset:
					data[i]?.state === 'selected' ? [-90, 12] : [-90, -80],
			}

			const placemark = {
				type: 'Feature',
				id: data[i].pk,
				geometry: {
					type: 'Point',
					coordinates: [data[i].lat, data[i].lon],
				},
				properties,
				options,
			}
			if (placementsType === 'all') {
				json.features.push(placemark)
			} else {
				if (
					data[i]?.door_type === placementsType ||
					data[i]?.state === 'selected'
				)
					json.features.push(placemark)
			}
		}
		objectManager.add(json)
		objectManager.objects.events
			.add('click', function (e) {
				const objectId = e.get('objectId')
				if (onPlacemarkClick) {
					onPlacemarkClick(objectId)
				}
			})
			.add('mouseenter', function (e) {
				// @ts-ignore
				const p = document.getElementById(
					`ymaps_baloon-container_${e.get('objectId')}`
				)
				if (p) {
					p.classList.add('placemark-hover')
				}
			})
			.add('mouseleave', function (e) {
				const p = document.getElementById(
					`ymaps_baloon-container_${e.get('objectId')}`
				)
				if (p) p.classList.remove('placemark-hover')
			})

		mapObjectRef.current.geoObjects.add(objectManager)
	}, [])

	const drawSmallData = useCallback((smallData, buisnessCoords) => {
		const tmp = smallData.map((el) => {
			return [parseFloat(el.lat), parseFloat(el.lon)]
		})
		if (buisnessCoords) {
			tmp.push([
				parseFloat(buisnessCoords[0]),
				parseFloat(buisnessCoords[1]),
			])
		}

		const bounds = ymaps.util.bounds.fromPoints(tmp)
		mapObjectRef.current.setBounds(bounds, {
			checkZoomRange: true,
			zoomMargin: 20,
		})
		// console.log(bounds)
		smallData?.forEach((el) => {
			const placemark = new ymaps.Placemark(
				[el.lat, el.lon],
				{},
				{
					...LOCATION_PLACEMARK_OPTIONS_DARK,
				}
			)
			mapObjectRef.current.geoObjects.add(placemark)
		})
	}, [])

	//обработка клика по балуну
	useEffect(() => {
		addEventListener(document, 'click', (e) => {
			const selectBaloon = e
				.composedPath()
				.find(
					(data) =>
						data.className === 'ymaps_cluster_baloon_content_place'
				)
			if (selectBaloon) {
				if (onPlacemarkClick) {
					onPlacemarkClick(parseInt(selectBaloon.dataset.id))
				}
			}
		})

		return cleanupListeners
	}, [])

	//при получении нового центра карты
	useEffect(() => {
		if (changeCenter === null) return
		mapObjectRef.current.setCenter(changeCenter)
		// mapObjectRef.current.setZoom(15);
	}, [changeCenter])

	//при получении адреса
	useEffect(() => {
		if (address) {
			ymaps.ready(() => {
				ymaps.geocode(address, { results: 1 }).then((res: any) => {
					if (onAddressFound && res.geoObjects.get(0)) {
						const coordinates =
							res.geoObjects.get(0).geometry._coordinates
						const placemark = new ymaps.Placemark(
							coordinates,
							{},
							LOCATION_PLACEMARK_OPTIONS
						)
						mapObjectRef.current.geoObjects.removeAll()
						mapObjectRef.current.geoObjects.add(placemark)
						drawArea()
						mapObjectRef.current.setCenter(coordinates)
						void onAddressFound?.(coordinates)
					}
				})
			})
		}
	}, [address])

	return <div style={{ width, height }} ref={mapRef} onClick={onClick} />
}
