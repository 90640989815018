import React from 'react'
import { Button } from 'react-bootstrap'
import s from './common.module.scss'

type Props = {
	disabled: boolean
	text: string
}
export const SubmitButton = ({ disabled, text }: Props) => {
	return (
		<Button
			type={'submit'}
			variant="primary"
			className={s.submitButton}
			disabled={disabled}
		>
			{text}
		</Button>
	)
}
