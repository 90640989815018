import React from 'react'
import { StepElement } from './StepElement'
import { getHelpImg, helpImgList } from '../../../constants'

export const DocumentsContent = (props: any) => {
	return (
		<>
			<h2>Документы</h2>

			<ul style={{ listStyle: 'none', padding: '0' }}>
				<StepElement number={undefined} title={''} url={''} alt="">
					Внимание! Закрывающие документы появляются в личном кабинете
					после 16 числа следующего месяца за отчетным периодом.
				</StepElement>
			</ul>

			<section>
				<ol>
					<StepElement
						number={1}
						title={
							'Заходим в рекламные кампании в статусе «Завершенные»'
						}
						url={getHelpImg(helpImgList.documents[1])}
						alt=""
					/>
					<StepElement
						number={2}
						title={
							'У необходимой рекламной кампании нажимаем на дополнительное меню в виде трех вертикальных точек с правой стороны карточки.'
						}
						alt=""
						url={getHelpImg(helpImgList.documents[2])}
					/>

					<StepElement
						number={3}
						title="Появится контекстное окно с выбором: выгрузить отчетность или документы.
                         Для получения закрывающих необходимо нажать на «Выгрузить документы», 
                         после чего вы можете сохранить себе документ на компьютер или сразу же его распечатать."
						alt=""
						url={getHelpImg(helpImgList.documents[3])}
					/>

					<StepElement
						number={4}
						title="В случае, если вы видите 2 файла УПД, вам необходимо скачать оба файла."
						alt=""
						url={getHelpImg(helpImgList.documents[4])}
					/>
				</ol>
			</section>
		</>
	)
}
