import { PlacementShort } from 'src/stores/ADMarketTypes.types'
import { authFetch } from 'src/utils/authFetch'
import { getMassCoveragePlacements } from 'src/constants/api'

export const fetchMassCoveragePlacements = async (cityId: number) => {
	let res = await authFetch<PlacementShort[]>({
		url: getMassCoveragePlacements.params(cityId),
		method: 'GET',
	})
	return res
}
