import React, { useState } from 'react'
import s from './Tabs.module.scss'
import cn from 'classnames'

export function Tabs({
	tabs,
}: {
	tabs: {
		label: string
		render: () => {}
		key: string
	}[]
}) {
	const [tabKey, setTabKey] = useState(tabs[0].key)
	const activeTab = tabs.find((x) => x.key === tabKey)

	return (
		<div className={s.container}>
			<div className={s.tabs}>
				{tabs.map((tab) => (
					<div
						className={cn(s.tab, { [s.active]: activeTab === tab })}
						onClick={() => setTabKey(tab.key)}
						key={tab.key}
					>
						{tab.label}
					</div>
				))}
			</div>
			<div className={s.content}>{activeTab?.render()}</div>
		</div>
	)
}
