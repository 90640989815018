import React, { useEffect, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import { NotificationModel } from '../common/components/IssuesContent'
import {
	CreateNotificationBody,
	CreateRelatedNotificationBody,
	NotificationModel as DraftNotificationModel,
	NotificationRelatedItem,
} from '../../../../types/ModerationRequestTypes'
import { Formik } from 'formik'
import s from './style.module.scss'
import cn from 'classnames'
import MobileModal from 'src/components/UtilityComponents/MobileModal'
import useResponsive from 'src/utils/useResponsive'
import { moderationApi } from 'src/components/_moderator/api/moderatorApi'
import extraStyle from '../../notifications-moderation/components/modals/modals.module.scss'
import useModerationData from 'src/components/_moderator/store/moderationStore'

type NotificationModalProps = {
	open: boolean
	onClose: () => void
	notificationModel: NotificationModel
}
export const NotificationModal = ({
	open,
	onClose,
	notificationModel,
}: NotificationModalProps) => {
	const [moder, moderInterface] = useModerationData()

	const [draftNotification, setDraftNotification] = useState<
		DraftNotificationModel | undefined
	>(undefined)

	const handleSubmit = async (values: { header: string; text: string }) => {
		if (draftNotification) {
			const relatedKey: NotificationRelatedItem =
				notificationModel.type === 'indoor' ||
				notificationModel.type === 'outdoor'
					? 'booking'
					: notificationModel.type

			const body: CreateNotificationBody &
				Partial<CreateRelatedNotificationBody> = {
				title: values.header,
				description: values.text,
				category: 'related_item',
				state: 'created',
				owner: draftNotification.owner,
				[relatedKey]: notificationModel.id,
			}

			await moderInterface.createModerNotification(
				draftNotification.id,
				body
			)
			onClose()
		}
	}

	const { isMobile } = useResponsive()

	const initializeDraftNotification = async () => {
		const result = await moderationApi.createDraftNotification()
		if (result) {
			setDraftNotification(result)
		}
	}

	const handleClose = async () => {
		if (draftNotification) {
			await moderationApi.deleteNotification(draftNotification.id)
			onClose()
		} else {
			onClose()
		}
	}

	useEffect(() => {
		initializeDraftNotification()
	}, [])
	return (
		<>
			{isMobile ? (
				<MobileModal
					show={open}
					onHide={handleClose}
					title={
						<div
							className={cn(s.modalHeader, {
								[s.modalHeader_mobile]: isMobile,
							})}
						>
							<h5>Отправить уведомление</h5>
							<h5>{notificationModel.companyName}</h5>
						</div>
					}
					contentStyles={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						padding: 0,
					}}
				>
					{!draftNotification ? (
						<Spinner
							animation="border"
							style={{ color: '#007BFF' }}
						/>
					) : (
						<Formik
							initialValues={{ header: '', text: '' }}
							onSubmit={handleSubmit}
							validateOnChange={false}
						>
							{({
								values,
								setFieldValue,
								handleSubmit,
								errors,
								setFieldError,
							}) => (
								<>
									<Form
										className={
											'd-flex flex-column gap-3 p-3'
										}
									>
										<div className={s.modalAdvInfo}>
											<h6>{notificationModel.name}</h6>
											<h6>
												{notificationModel.placeName}
											</h6>
										</div>
										<Form.Group>
											<Form.Label>Заголовок</Form.Label>
											<Form.Control
												size={'lg'}
												value={values.header}
												onChange={(e) => {
													setFieldValue(
														'header',
														e.target.value
													)
													setFieldError(
														'header',
														undefined
													)
												}}
												isInvalid={!!errors.header}
												name={`header`}
											/>
											<Form.Control.Feedback type="invalid">
												{errors.header}
											</Form.Control.Feedback>
										</Form.Group>

										<Form.Group>
											<Form.Label>
												Основной текст
											</Form.Label>
											<Form.Control
												size={'lg'}
												value={values.text}
												onChange={(e) => {
													setFieldValue(
														`text`,
														e.target.value
													)
													setFieldError(
														`text`,
														undefined
													)
												}}
												isInvalid={!!errors[`text`]}
												name={`text`}
												as={'textarea'}
											/>
											<Form.Control.Feedback type="invalid">
												{errors[`text`]}
											</Form.Control.Feedback>
										</Form.Group>
									</Form>

									<Modal.Footer>
										<Button
											variant="secondary"
											onClick={onClose}
										>
											Отменить
										</Button>
										<Button
											variant="primary"
											onClick={handleSubmit as any}
											type={'submit'}
											disabled={
												!values.header || !values.text
											}
										>
											Отправить
										</Button>
									</Modal.Footer>
								</>
							)}
						</Formik>
					)}
				</MobileModal>
			) : (
				<Modal
					show={open}
					onHide={handleClose}
					centered
					size={'sm'}
					animation={true}
					contentClassName={cn({
						[extraStyle.invisibleModal]: !draftNotification,
					})}
				>
					{!draftNotification ? (
						<Spinner
							animation="border"
							style={{ color: '#007BFF' }}
						/>
					) : (
						<>
							<Modal.Header closeButton>
								<Modal.Title as={'h5'} className={'ms-2'}>
									<div className={s.modalHeader}>
										<h5>Отправить уведомление</h5>
										<h5>{notificationModel.companyName}</h5>
									</div>
								</Modal.Title>
							</Modal.Header>

							<Formik
								initialValues={{ header: '', text: '' }}
								onSubmit={handleSubmit}
								validateOnChange={false}
							>
								{({
									values,
									setFieldValue,
									handleSubmit,
									errors,
									setFieldError,
								}) => (
									<>
										<Modal.Body style={{ padding: '24px' }}>
											<Form
												className={
													'd-flex flex-column gap-3'
												}
											>
												<div className={s.modalAdvInfo}>
													<h6>
														{notificationModel.name}
													</h6>
													<h6>
														{
															notificationModel.placeName
														}
													</h6>
												</div>
												<Form.Group>
													<Form.Label>
														Заголовок
													</Form.Label>
													<Form.Control
														size={'lg'}
														value={values.header}
														onChange={(e) => {
															setFieldValue(
																'header',
																e.target.value
															)
															setFieldError(
																'header',
																undefined
															)
														}}
														isInvalid={
															!!errors.header
														}
														name={`header`}
													/>
													<Form.Control.Feedback type="invalid">
														{errors.header}
													</Form.Control.Feedback>
												</Form.Group>

												<Form.Group>
													<Form.Label>
														Основной текст
													</Form.Label>
													<Form.Control
														size={'lg'}
														value={values.text}
														onChange={(e) => {
															setFieldValue(
																`text`,
																e.target.value
															)
															setFieldError(
																`text`,
																undefined
															)
														}}
														isInvalid={
															!!errors[`text`]
														}
														name={`text`}
														as={'textarea'}
													/>
													<Form.Control.Feedback type="invalid">
														{errors[`text`]}
													</Form.Control.Feedback>
												</Form.Group>
											</Form>
										</Modal.Body>
										<Modal.Footer>
											<Button
												variant="secondary"
												onClick={handleClose}
											>
												Отменить
											</Button>
											<Button
												variant="primary"
												onClick={handleSubmit as any}
												type={'submit'}
												disabled={
													!values.header ||
													!values.text
												}
											>
												Отправить
											</Button>
										</Modal.Footer>
									</>
								)}
							</Formik>
						</>
					)}
				</Modal>
			)}
		</>
	)
}
