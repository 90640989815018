import React from 'react'
import s from './commonStyles.module.scss'
import cn from 'classnames'
import useResponsive from 'src/utils/useResponsive'

type CreateReportBodyProps = {
	children: React.ReactNode
}

export const CreateReportBody = ({ children }: CreateReportBodyProps) => {
	const { isMobile } = useResponsive()
	return (
		<div className={cn(s.reportBody, { [s.reportBody_mobile]: isMobile })}>
			{children}
		</div>
	)
}
