import React, { FC, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import useDebounce from '../../../../utils/useDebounce'
import { useBookingsContext } from '../context/BookingsProvider'
import { SearchWithVariants } from 'src/components/UtilityComponents'
import useProviderData from 'src/stores/providerData'
import { booking } from 'src/constants/api'

interface IBookingsSearch {
	children?: React.ReactNode | React.ReactNode[]
}

const BookingsSearch: FC<IBookingsSearch> = ({}) => {
	const { setSearchString, searchString } = useBookingsContext()

	const [provider] = useProviderData()

	const bookingVariants = provider?.bookings?.results?.map(
		(booking) => booking.placement.name
	)
	return (
		<SearchWithVariants
			setSearchString={setSearchString}
			searchString={searchString}
			variants={bookingVariants}
		/>
	)
}

export default BookingsSearch
