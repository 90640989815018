import React, { ReactElement } from 'react'
import { Button } from 'react-bootstrap'
import { FiltersItemList } from './FiltersItemList'
import useResponsive from 'src/utils/useResponsive'
import { ReactComponent as AddIcon } from '../../../../../../assets/images/add.svg'

import cn from 'classnames'
import s from '../providerFilter.module.scss'
import { PlacementFilterType } from '../../../constants'
import { PlacementFilterKeyType } from '../../../constants/placementFilters'

export type FiltersItemListProps<T> = {
	filters: Array<PlacementFilterType>
	onDeleteFilter?: (filterKey: PlacementFilterKeyType) => void
	searchObject: T
}

type FiltersStateProps = {
	filters: Array<PlacementFilterType>
	allFiltersSelected?: boolean
	onDeleteFilter?: (filterKey: PlacementFilterKeyType) => void
	onFilterAdd?: () => void
	children?: ReactElement<FiltersItemListProps<{}>>
}
export const FiltersState = ({
	filters,
	allFiltersSelected,
	onDeleteFilter,
	onFilterAdd,
	children,
}: FiltersStateProps) => {
	const { isDesktop } = useResponsive()

	const handleFilterAdd = () => {
		onFilterAdd && onFilterAdd()
	}
	return (
		<>
			{children}

			{!allFiltersSelected && (
				<Button
					variant={'light'}
					className={cn(s.iconButton)}
					onClick={handleFilterAdd}
					style={{
						alignSelf: 'flex-start',
						minWidth: '42px',
						minHeight: '38px',
						marginRight: '8px',
					}}
				>
					<AddIcon />
					{isDesktop && 'Добавить фильтр'}
				</Button>
			)}
		</>
	)
}
