import React from 'react'
import s from './commonMedia.module.scss'
import { Button } from 'react-bootstrap'
import Tooltip from 'src/components/UtilityComponents/Tooltip'
import { ReactComponent as DownloadIcon } from 'src/assets/images/download.svg'
import { ReactComponent as EditIcon } from 'src/assets/images/edit.svg'
import useResponsive from 'src/utils/useResponsive'

type Props = {
	onDeleteMedia?: () => void
	onEditMedia?: () => void
	onRequirementsClick?: () => void
	onUpload?: () => void
}
export const CommonMediaControls = ({
	onDeleteMedia,
	onEditMedia,
	onRequirementsClick,
	onUpload,
}: Props) => {
	const { isMobile } = useResponsive()
	return (
		<div className={s.commonMediaControls}>
			{onRequirementsClick && (
				<Button
					className={s.commonMediaControls_primary}
					variant="light"
					onClick={onRequirementsClick}
					style={{ width: isMobile ? '100%' : 'auto' }}
				>
					{isMobile ? 'Требования' : 'Требования к загрузке'}
				</Button>
			)}
			{onUpload && (
				<Tooltip text="Выгрузить материал" placement="left">
					<Button
						className={s.commonMediaControls_primary}
						variant={'light'}
						onClick={onUpload}
					>
						<DownloadIcon />
					</Button>
				</Tooltip>
			)}
			{onEditMedia && (
				<Tooltip text="Редактировать материал" placement="left">
					<Button
						className={s.commonMediaControls_primary}
						variant={'light'}
						onClick={onEditMedia}
					>
						<EditIcon />
					</Button>
				</Tooltip>
			)}
			{/* {onDeleteMedia && (
				<Tooltip text="Удалить материал" placement="left">
					<Button
						className={s.commonMediaControls_danger}
						variant={'light'}
						onClick={() => {}}
					>
						<i className="bi bi-trash"></i>
					</Button>
				</Tooltip>
			)} */}
		</div>
	)
}
