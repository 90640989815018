import React, { FC, useMemo, useState } from 'react'
import { Operations } from '../../../../stores/ADMarketTypes.types'
import getOperationInfo from '../getOperationInfo'
import s from '../user-operations.module.scss'
import { Modal } from 'react-bootstrap'
import DetailItem from '../../../../utils/DetailItem'

interface IDesktopOperationsList {
	operations: Operations[]
}

const DesktopOperationsList: FC<IDesktopOperationsList> = ({ operations }) => {
	const [operationModalId, setOperationModalId] = useState<false | string>(
		false
	)

	const onOperationClick = (id: string) => {
		setOperationModalId(id)
	}

	const { payment_method, date, title, status, amount } = useMemo(
		() =>
			getOperationInfo(
				operations.find((el) => el.operation_id === operationModalId) ||
					operations[0],
				'16px'
			),
		[operations, operationModalId]
	)
	return (
		<div
			style={{
				flex: '1 1 auto',
				width: '100%',
				overflowY: 'auto',
				overflowX: 'hidden',
			}}
		>
			<table
				style={{
					padding: '0 24px 24px 24px',
					maxHeight: '100%',
					width: '100%',
				}}
			>
				<thead>
					<tr className={s.desktopTableHeader}>
						<td>Дата</td>
						<td>Назначение платежа</td>
						<td>Способ оплаты</td>
						<td>Сумма</td>
						<td>Статус</td>
					</tr>
				</thead>
				<tbody
					style={{
						overflowY: 'scroll',
						maxHeight: '100%',
					}}
				>
					{operations.map((operation) => (
						<DesktopOperation
							onClick={onOperationClick}
							{...operation}
							key={operation.id}
						/>
					))}
				</tbody>
			</table>
			<Modal
				show={Boolean(operationModalId)}
				onHide={() => setOperationModalId(false)}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title as={'h5'}>{`Операция от ${date}`}</Modal.Title>
				</Modal.Header>
				<Modal.Body style={{ padding: '40px' }}>
					<DetailItem
						left={'Назначение платежа'}
						right={title}
						bottomDivider={false}
						leftFixWidth={'180px'}
						fontSize={'16px'}
					/>
					<DetailItem
						left={'Способ оплаты'}
						right={payment_method}
						bottomDivider={false}
						leftFixWidth={'180px'}
						fontSize={'16px'}
					/>
					<DetailItem
						left={'Сумма'}
						right={amount}
						bottomDivider={false}
						leftFixWidth={'180px'}
						fontSize={'16px'}
					/>
					<DetailItem
						left={'Статус'}
						right={status}
						bottomDivider={false}
						leftFixWidth={'180px'}
						fontSize={'16px'}
					/>
				</Modal.Body>
			</Modal>
		</div>
	)
}

const DesktopOperation: FC<Operations & { onClick: (id: string) => void }> = (
	props
) => {
	const { payment_method, title, status, amount, date } = getOperationInfo(
		props,
		'16px'
	)
	return (
		<tr
			onClick={() => props.onClick(props.operation_id)}
			className={s.desktopTableRow}
		>
			<td>{date}</td>
			<td>{title}</td>
			<td>{payment_method}</td>
			<td>{amount}</td>
			<td>{status}</td>
		</tr>
	)
}

export default DesktopOperationsList
