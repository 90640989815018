import React, { useContext, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'
import {
	CampaignModeration,
	DisplayIssuesModeration,
	NotificationsModeration,
} from './components'
import ROUTES from 'src/constants/Routes'
import { ToastsContext } from '../UtilityComponents/ToastsContextProvider'
import useModerationData from './store/moderationStore'

export const ModeratorPage = (props: any) => {
	const [moder, moderInterface] = useModerationData()

	const { addToast } = useContext(ToastsContext)
	useEffect(() => {
		if (moder.moderToastMessage) {
			addToast({
				text: moder.moderToastMessage.text,
				type: moder.moderToastMessage.type,
			})
			moderInterface.setModerToastMessage(undefined)
		}
	}, [moder.moderToastMessage])
	return (
		<div style={{ background: '#FFFFFF', height: '100%' }}>
			<Switch>
				<Route
					path={
						ROUTES.ROUTE_MODERATOR +
						ROUTES.ROUTE_CAMPAIGN_MODERATION
					}
					component={CampaignModeration}
					exact
				/>
				<Route
					path={
						ROUTES.ROUTE_MODERATOR +
						ROUTES.ROUTE_DISPLAY_ISSUES_MODERATION
					}
					component={DisplayIssuesModeration}
					exact
				/>
				<Route
					path={
						ROUTES.ROUTE_MODERATOR +
						ROUTES.ROUTE_NOTIFICATIONS_MODERATION
					}
					component={NotificationsModeration}
					exact
				/>

				{/* <Route path={'/'}>
				<Redirect
					to={
						ROUTES.ROUTE_MODERATOR +
						ROUTES.ROUTE_CAMPAIGN_MODERATION
					}
				/>
			</Route> */}
			</Switch>
		</div>
	)
}
