import React, { FC } from 'react'
import s from './Header.module.scss'
import { Link } from 'react-router-dom'
import useResponsive from '../../../utils/useResponsive'
import HeaderNotifications from '../../notifications/HeaderNotifications'
import Profile from '../Profile/Profile'
import { ReactComponent as Logo } from 'src/assets/images/logo.svg'
import { HeaderChat } from 'src/components/chat/HeaderChat'

interface IHeader {
	onSidebarOpen: any
	noSidebar: any
	sidebarForceOpen: any
}

const Header: FC<IHeader> = ({
	onSidebarOpen,
	noSidebar,
	sidebarForceOpen,
}) => {
	const { isDesktop } = useResponsive()
	return (
		<header className={s.header}>
			<div className={s.burger} onClick={onSidebarOpen}>
				{sidebarForceOpen ? (
					<i className="bi bi-x" />
				) : (
					<i className="bi bi-list" />
				)}
			</div>
			<div className={s.logo}>
				<Link to={'/'}>
					<Logo />
				</Link>
			</div>
			<div id={'header-page-title'} className={s.pageTitle} />
			<HeaderChat />
			<HeaderNotifications />
			<Profile />
		</header>
	)
}

export default Header
