import ReconnectingWebSocket from 'reconnecting-websocket'
import { userDataStore, userDataStoreInterface } from './userData'

const isProduction = () => {
	const host = window.location.hostname
	return !(host.includes('localhost') || host.includes('dev'))
}
const SOCKET_URL = isProduction()
	? 'wss://app.admarket.ru/socker/ws'
	: 'wss://admarket-dev.adtech-it.ru/socker/ws'

class WsController {
	private readonly ws: ReconnectingWebSocket

	private retryCount: number = 0

	constructor() {
		this.ws = new ReconnectingWebSocket(SOCKET_URL, [], {
			connectionTimeout: 3000,
			maxRetries: 5,
		})
		this.ws.onopen = () => {
			const jwt = userDataStoreInterface.jwt_token()
			if (jwt) {
				this.ws.send(jwt)
			} else {
				this.ws.close()
				this.ws.reconnect()
			}
		}
		this.ws.onclose = (e) => {
			console.error('ws closed...')
		}
		this.ws.onerror = (e) => {
			this.ws.close()
			this.retryCount++
			if (this.retryCount < 5) {
				this.ws.reconnect()
			} else {
				console.error('max number of reconnections has been reached ')
			}
		}
		userDataStore.on('logout', () => this.ws.close())
		userDataStore.on('jwt_token', () => {
			this.ws.close()
			this.ws.reconnect()
		})
	}
	public onMessage(handler) {
		if (this.ws) this.ws.onmessage = handler
	}
}

export default WsController
