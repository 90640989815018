import React from 'react'
import { Button } from 'react-bootstrap'
import { ConditionArrowUpDown } from 'src/components/UtilityComponents'
import Tooltip from 'src/components/UtilityComponents/Tooltip'
import s from './common.module.scss'
type CollapseCardButtonProps = {
	overlayText: string
	open: boolean
	onClick: () => void
}
export const CollapseCardButton = ({
	onClick,
	open,
	overlayText,
}: CollapseCardButtonProps) => {
	return (
		<Tooltip text={overlayText} placement="top">
			<Button
				className={s.collapseButton}
				variant="text"
				onClick={onClick}
			>
				<ConditionArrowUpDown
					open={open}
					styles={{ width: '1rem', height: '1rem' }}
				/>
			</Button>
		</Tooltip>
	)
}
