import React, { useEffect, useMemo } from 'react'
import { useCampaignReportStore } from '../../store'
import {
	getStatAndlogDetailTableData,
	getStatAnLogDetailTableColumns,
} from '../../utils'
import { ReportDetailWithStatLog } from '../../types'
import { StatLogTable } from './stat-log-table/StatLogTable'

type Props = {
	fetchDetails: () => Promise<void>
}

export const TvDetailsWrapper = ({ fetchDetails }: Props) => {
	const adstream = useCampaignReportStore(
		(store) => store.detailedReport.adstream
	)

	useEffect(() => {
		fetchDetails()
	}, [])
	return <div>{adstream && <TvDetailsDetails />}</div>
}

export const TvDetailsDetails = () => {
	const adstream = useCampaignReportStore(
		(store) => store.detailedReport.adstream
	)

	const data = useMemo(() => {
		const usedData = [] as {
			adv: 'adstream'
			statLog: ReportDetailWithStatLog | null
		}[]

		usedData.push({
			adv: 'adstream',
			statLog: adstream,
		})

		return getStatAndlogDetailTableData(usedData)
	}, [adstream])

	const columns = useMemo(() => {
		const result = getStatAnLogDetailTableColumns(adstream!)

		return result
	}, [adstream])

	return <StatLogTable columns={columns} data={data} />
}
