import React from 'react'
import {
	CreativeTemplateKeysType,
	CreativeTemplateType,
	creativeTemplates,
} from '../../../constants'
import { TemplateImage, getSize } from './template-image/TemplateImage'
import { motion } from 'framer-motion'
import useResponsive from 'src/utils/useResponsive'
import cn from 'classnames'
import s from './images-list.module.scss'
import { YourTemlateForm } from './template-image/YourTemlateForm'
import { TempalteImagesListHeader } from './TempalteImagesListHeader'
import { DesignerHelp } from './template-image/DesignerHelp'

type TemplateImageListPropsType = {
	screenResolution: CreativeTemplateKeysType
	onChooseTemplate: (template: CreativeTemplateType) => void
	isLoading: boolean
	templateId: number | undefined
	getOrderLayoutModal?: () => void
	isAvailableVinstant?: boolean
}

/**
 * Рендерит список шаблонов.
 * @component
 * @param {CreativeTemplateKeysType} screenResolution - ключ для доступа к свойству статического объекта
 * @param {function} onChooseTemplate - callback вызывающийся при клике на выбранном шаблоне
 * @param {boolean} isLoading - состояние получения redirectURL
 * @param templateId - id выбранного шаблона
 */
export const TemplateImagesList = ({
	screenResolution,
	onChooseTemplate,
	isLoading,
	templateId,
	getOrderLayoutModal,
	isAvailableVinstant = false,
}: TemplateImageListPropsType) => {
	const { isMobile } = useResponsive()

	const templatesKey = creativeTemplates?.[screenResolution]
		? screenResolution
		: '0'

	const handleGetOrderLayoutModal = () => {
		getOrderLayoutModal && getOrderLayoutModal()
	}
	return (
		<motion.div
			initial={{ opacity: 0, y: 8 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 20 }}
			className={cn(s.template_images_list_container, {
				[s.template_images_list_container_mobile]: isMobile,
			})}
		>
			<TempalteImagesListHeader
				isMobile={isMobile}
				isNoTemplates={templatesKey === '0'}
			/>

			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					alignItems: 'flex-start',
					maxHeight: 'auto',
					marginTop: isMobile ? '6px' : '1.5rem',
					justifyContent: isMobile ? 'center' : 'flex-start',
					marginBottom: isMobile ? '1rem' : '0',
				}}
			>
				{creativeTemplates[templatesKey]?.map((el) =>
					el.templateId !== 0 ? (
						<TemplateImage
							isAvailableVinstant={isAvailableVinstant}
							isSelected={el.templateId === templateId}
							isLoading={isLoading}
							screenResolution={templatesKey}
							onChooseTemplate={() => onChooseTemplate(el)}
							key={el.templateId}
							template={el}
						/>
					) : isMobile ? (
						<React.Fragment key={el.templateId}></React.Fragment>
					) : isAvailableVinstant ? (
						<YourTemlateForm
							onClick={() =>
								onChooseTemplate({ templateId: 0 } as any)
							}
							key={el.templateId}
							screenResolution={templatesKey}
						/>
					) : (
						<></>
					)
				)}

				<DesignerHelp
					screenResolution={templatesKey}
					key={'designer-help'}
					onClick={handleGetOrderLayoutModal}
				/>
			</div>
		</motion.div>
	)
}
