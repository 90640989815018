import React, { useContext, useState } from 'react'
import { Registration } from '../common/ui/registration/Registration'
import {
	ClientRegistrationInput,
	CodeConfirmationFormData,
	CodeResendData,
	RegisterQueryParams,
	RegistrationCredentialsFormData,
	RegistrationStagesType,
} from '../common/model/types'
import { FormikHelpers } from 'formik'
import useUserData, {
	AcceptPhoneActivationCodeData,
	PhoneConfirmationVariant,
} from 'src/stores/userData'
import TimeFormatter from 'src/utils/time-formatter'
import { useQueryParams } from 'src/utils'
import yandexTracking from 'src/utils/yandexTracking'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { useHistory } from 'react-router-dom'

export const ClientRegistration = () => {
	const codeResendCooldown = 60
	const lastConfirmationVariantKey = 'clientRegisterConfVariant'
	const expirationDateKey = 'newCodeSendingDate'
	const defaultPhoneConfirmationVariant: PhoneConfirmationVariant = 'sms'

	const { params } = useQueryParams<Partial<RegisterQueryParams>>()

	const history = useHistory()

	const { addToast } = useContext(ToastsContext)

	const [_, userInterface] = useUserData()

	const [stage, setStage] = useState<RegistrationStagesType>(
		RegistrationStagesType.CredentialsInput
	)

	const [phoneNumber, setPhoneNumber] = useState('')
	const [password, setPassword] = useState('')

	const saveResendCooldown = () => {
		const newExpiredDate = new Date()
		newExpiredDate.setSeconds(
			newExpiredDate.getSeconds() + codeResendCooldown
		) // Устанавливаем время истечения через 1 минуту

		sessionStorage.setItem(expirationDateKey, newExpiredDate.toISOString()) // Сохраняем дату истечения
	}

	const handleEnterCredentials = async (
		values: RegistrationCredentialsFormData,
		formikHelpers: FormikHelpers<RegistrationCredentialsFormData>
	) => {
		try {
			// Получаем текущую дату и время для проверки состояния таймера
			const now = new Date()
			const newCodeSendingDate = sessionStorage.getItem(expirationDateKey)

			// Форматируем номер телефона, удаляя все нецифровые символы
			const formattedPhone = `+${values.phone_number.replace(/\D/g, '')}`
			let data: ClientRegistrationInput = {
				phone_number: formattedPhone,
				password: values.password,
			}
			if (params?.utm_source) {
				data['utm_source'] = params.utm_source
			}

			// Вспомогательная функция для проверки, истек ли таймер
			const isTimerExpired = (date: string | null) => {
				return date ? now > new Date(date) : true
			}

			// Вспомогательная функция для обработки отправки кода подтверждения
			const handleCodeSending = async () => {
				//на бэке по дефолту код подтверждения отправляется по смс
				await userInterface.register(data)
				yandexTracking.setUser(data)
				await yandexTracking.reachGoal('set_phone_and_pass')
				saveResendCooldown()
				sessionStorage.setItem(
					lastConfirmationVariantKey,
					defaultPhoneConfirmationVariant
				)
				setPhoneNumber(values.phone_number)
				setPassword(values.password)
				setStage(RegistrationStagesType.VerificationCode)
			}

			// Проверяем, существует ли сохраненная дата отправки нового кода
			if (newCodeSendingDate) {
				const isDateExpired = isTimerExpired(newCodeSendingDate)

				// Проверяем, совпадает ли введенный номер телефона и пароль с ранее сохраненным
				if (
					values.phone_number === phoneNumber &&
					password === values.password
				) {
					if (isDateExpired) {
						await handleCodeSending()
					} else {
						setStage(RegistrationStagesType.VerificationCode)
						return
					}
				} else {
					if (isDateExpired) {
						await handleCodeSending()
					} else {
						// Определяем оставшееся время до истечения таймера
						const expirationDate = new Date(newCodeSendingDate)
						const remainingTime = Math.floor(
							(expirationDate.getTime() - now.getTime()) / 1000
						)
						// Устанавливаем ошибку для отображения пользователю
						formikHelpers.setFieldError(
							'phone_number',
							`Можно повторить регистрацию через ${TimeFormatter(
								remainingTime
							)}`
						)
						return
					}
				}
			} else {
				await handleCodeSending()
			}
		} catch (e: any) {
			if (typeof e?.data === 'object') {
				Object.entries(e.data).forEach(([key, value]) => {
					if (key === 'detail') {
						formikHelpers.setFieldError(
							'phone_number',
							'Ошибка отправки'
						)
					}
					formikHelpers.setFieldError(
						key,
						Array.isArray(value) ? value[0] : value
					)
				})
			} else {
				formikHelpers.setFieldError('phone_number', 'Ошибка отправки')
			}
		} finally {
			formikHelpers.setSubmitting(false)
		}
	}
	const handleConfirmCode = async (
		values: CodeConfirmationFormData,
		formikHelpers: FormikHelpers<CodeConfirmationFormData>,
		confirmationVariant: PhoneConfirmationVariant
	) => {
		try {
			const data: AcceptPhoneActivationCodeData = {
				code: values.code,
				variant: confirmationVariant,
			}
			await userInterface.acceptPhoneActivationCode(data)
			await yandexTracking.reachGoal('accept_phone')
			history.push('/campaigns/')
		} catch (e: any) {
			if (typeof e?.data === 'object') {
				Object.keys(e.data).forEach((key) => {
					if (key === 'detail') {
						formikHelpers.setFieldError('code', 'Ошибка отправки')
					}
					formikHelpers.setFieldError(key, e.data[key][0])
				})
			} else {
				formikHelpers.setFieldError('code', 'Ошибка отправки')
			}
		} finally {
			formikHelpers.setSubmitting(false)
		}
	}

	const handleResendActivationCode = async (values: CodeResendData) => {
		try {
			await userInterface.sendPhoneActivationCode(values.confirmType)

			sessionStorage.setItem(
				lastConfirmationVariantKey,
				values.confirmType
			)
			saveResendCooldown()
		} catch (e: any) {
			addToast({
				text: 'Ошибка отправки кода',
				type: 'danger',
			})
		}
	}
	const handleToLogin = () => {
		window.location.href = '/auth/#/login'
	}
	return (
		<div>
			<Registration
				stage={stage}
				phoneNumber={phoneNumber}
				expirationDateKey={expirationDateKey}
				lastConfirmationVariantKey={lastConfirmationVariantKey}
				onConfirmCode={handleConfirmCode}
				onResendConfirmationCode={handleResendActivationCode}
				setStage={setStage}
				toLogin={handleToLogin}
				onEnterCredentials={handleEnterCredentials}
			/>
		</div>
	)
}
