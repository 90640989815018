import React from 'react'
import s from './login.module.scss'

type Props = {
	agency?: string
	headerTitle: string
}

export const LoginHeader = ({ agency, headerTitle }: Props) => {
	return (
		<div className={s.loginHeader}>
			<h3>{headerTitle}</h3>

			{agency && <h6>{agency}</h6>}
		</div>
	)
}
