import React, { useEffect, useMemo } from 'react'
import { useCampaignReportStore } from '../../store'
import {
	getStatAndlogDetailTableData,
	getStatAnLogDetailTableColumns,
} from '../../utils'
import { ReportDetailWithStatLog } from '../../types'
import { StatLogTable } from './stat-log-table/StatLogTable'

type Props = {
	fetchDetails: () => Promise<void>
}

export const PublicTransportDetailsWrapper = ({ fetchDetails }: Props) => {
	const mcr = useCampaignReportStore(
		(store) => store.detailedReport.public_transport
	)

	useEffect(() => {
		fetchDetails()
	}, [])
	return <div>{mcr && <PublicTransportDetails />}</div>
}

export const PublicTransportDetails = () => {
	const mcr = useCampaignReportStore(
		(store) => store.detailedReport.public_transport
	)

	const data = useMemo(() => {
		const usedData = [] as {
			adv: 'public_transport'
			statLog: ReportDetailWithStatLog | null
		}[]

		usedData.push({
			adv: 'public_transport',
			statLog: mcr,
		})

		return getStatAndlogDetailTableData(usedData)
	}, [mcr])

	const columns = useMemo(() => {
		const result = getStatAnLogDetailTableColumns(mcr!)

		return result
	}, [mcr])

	return <StatLogTable columns={columns} data={data} />
}
