import React from 'react'
import s from '../helpContent.module.scss'
import cn from 'classnames'
import { StepElement, StepElementImage } from './StepElement'
import {
	HandleActiveItemChangeType,
	HelpContentKey,
	getHelpImg,
	helpImgList,
} from '../../../constants'

type TvHelpContentProps = {
	isMobile: boolean
	changeActive: (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		itemId: HelpContentKey,
		nestedId?: HelpContentKey
	) => void
}

export const TvHelpContent = ({
	isMobile,
	changeActive,
}: TvHelpContentProps) => {
	return (
		<>
			<h2>ТВ-реклама</h2>

			<section style={{ listStyle: 'none' }}>
				<p>
					Телевизионная реклама — это рекламные сообщения, которые
					транслируются в эфире телеканалов. Это позволяет получить
					широкие охваты и достаточно большую аудиторию.
				</p>
				<p>
					Размещение телевизионной рекламы на платформе Admarket
					возможно через технологии Teletarget и Adstream.
				</p>

				<StepElementImage
					alt=""
					isMobile={isMobile}
					url={getHelpImg(helpImgList.tv.main)}
				/>
			</section>

			<section>
				<h4 className={cn(s.title)}>Технология Teletarget</h4>
				<p>
					Работает в современных Smart TV, которые подключены к
					интернету. Ваша реклама выйдет в эфире девяти федеральных
					телеканалов: Первый, Матч ТВ, НТВ, РенТВ, СТС, ТВ3,
					Домашний, ТНТ, Ю.
				</p>
				<p>
					Баннер находится на экране в течение 20 секунд, после чего
					автоматически скрывается.
				</p>
				<p>
					Мы заботимся о защите вашего бренда от появления в
					негативном информационном окружении, поэтому не показываем
					баннеры во время выпусков новостей, телерекламы,
					политических ток-шоу и похожего контента.
				</p>

				<p>
					Реклама через технологию Teletarget возможна в двух
					форматах:
				</p>

				<ol>
					<li className={cn(s.li_text)}>
						<span>1. </span>
						<p>
							<span style={{ fontWeight: 500 }}>
								Баннер 750x100.{' '}
							</span>
							Появляется поверх эфира телеканала. Рекомендуем
							такой формат для кратких и емких рекламных
							сообщений.
						</p>
					</li>
					<li className={cn(s.li_text)}>
						<span>2. </span>
						<p style={{ marginBottom: 0 }}>
							<span style={{ fontWeight: 500 }}>L-Баннер. </span>
							Приводит к уменьшению окна с эфиром телеканала.
							Остальную площадь экрана (30%) занимает ваша
							реклама. Такой формат позволяет разместить больше
							информации, чем в баннере 750x100. Расположите
							QR-код, чтобы получить переходы, например, на ваш
							сайт, лендинг или чат в мессенджере.
						</p>
					</li>
				</ol>
				<p>
					Узнать о технологии Teletarget вы можете на{' '}
					<a href="https://teletarget.ru/admarket">сайте</a>.
				</p>
			</section>

			<section>
				<h4 className={cn(s.title)}>Технология Adstream</h4>
				<p>Работает следующим образом:</p>

				<ol>
					<li className={cn(s.li_text)}>
						<span>1. </span>
						<p>
							Пользователь смотрит контент через IPTV-приставку
							или Smart TV.
						</p>
					</li>
					<li className={cn(s.li_text)}>
						<span>2. </span>
						<p>
							Пользователь совершает действие в меню источника
							контента, обращается к архиву или переключает
							ТВ-каналы.
						</p>
					</li>
					<li>
						<div className={cn(s.li_text)}>
							<span>3. </span>
							<p style={{ marginBottom: 0 }}>
								После выбора контента пользователю показывают
								один рекламный креатив. После окончания рекламы
								начинается трансляция выбранного контента.
							</p>
						</div>
					</li>
				</ol>
				<p>
					Узнать о технологии Adstream вы можете на{' '}
					<a href="http://adstream.ru/">сайте</a>.
				</p>
			</section>

			<section>
				<h4 className={cn(s.title)}>Создание ТВ-рекламы</h4>

				<ol>
					<li className={cn(s.li_text)}>
						<span>1. </span>
						<p>Перейдите на платформу Admarket.</p>
					</li>
					<li className={cn(s.li_text)}>
						<span>2. </span>
						<p>
							Начните создавать рекламную кампанию.{' '}
							<a
								href=""
								onClick={(e) =>
									changeActive(
										e,
										'campaign-creation',
										'person'
									)
								}
							>
								Подробнее
							</a>
						</p>
					</li>
					<li className={cn(s.li_text)}>
						<span>3. </span>
						<p>Дойдите до шага 3 с выбором форматов рекламы.</p>
					</li>

					<StepElement
						number={4}
						title="В блоке «ТВ-реклама» нажмите на кнопку «Добавить размещение»."
						url={getHelpImg(helpImgList.tv.create[4])}
					/>

					<StepElement
						number={5}
						title="Выберите нужные каналы для показа рекламы из выпадающего списка и укажите бюджет кампании. Минимальный бюджет — 10 000 ₽. "
						url={getHelpImg(helpImgList.tv.create[5])}
					>
						<div>
							Обратите внимание, что на данном этапе оформления
							заказа все цены указаны до НДС. Учитывайте этот
							момент при планировании своего бюджета.{' '}
						</div>
					</StepElement>

					<li>
						<div className={cn(s.li_text)}>
							<span>6. </span>
							<p>
								Можете переходить к следующим форматам рекламы.
								Если решили остановиться только на ТВ-рекламе, в
								самом низу или закрепленном меню нажмите на
								кнопку «Подтвердить бюджет».
							</p>
						</div>

						<StepElementImage
							alt=""
							isMobile={isMobile}
							url={getHelpImg(helpImgList.tv.create[6])}
						/>
						<div
							className={cn(s.child)}
							style={{ marginTop: '24px' }}
						>
							<ul>
								<li>
									<p>
										О добавлении рекламных мест и настройке
										наружной рекламы читайте{' '}
										<a
											href=""
											onClick={(e) =>
												changeActive(e, 'outdoor')
											}
										>
											в разделе
										</a>
										.
									</p>
								</li>
								<li>
									<p>
										О добавлении рекламных мест и внутренней
										наружной рекламы читайте{' '}
										<a
											href=""
											onClick={(e) =>
												changeActive(e, 'internet')
											}
										>
											в разделе
										</a>
										.
									</p>
								</li>
								<li>
									О добавлении и настройке интернет-рекламы
									читайте{' '}
									<a
										href=""
										onClick={(e) =>
											changeActive(e, 'internet')
										}
									>
										в разделе
									</a>
									.
								</li>
							</ul>
						</div>
					</li>
				</ol>
			</section>

			<section>
				<h4 style={{ marginBottom: '24px' }}>
					Шаг 4. Загрузка материалов для ТВ-рекламы
				</h4>
				<ol>
					<StepElement
						number={1}
						title="Загрузите готовые материалы."
						url={getHelpImg(helpImgList.dataUpload.tv[1])}
					>
						<ul>
							<li>
								<p>
									Чтобы посмотреть технические требования к
									рекламному материалу, нажмите на кнопку
									«Требования».
								</p>
							</li>
							<li>
								<p>
									Если у вас есть готовый рекламный материал,
									загрузите его через кнопку «Выберите файл».
								</p>
							</li>
							<li>
								Чтобы посмотреть всю информацию о
								запланированной рекламной кампании, нажмите на
								кнопку «Смотреть медиаплан».{' '}
								<a
									href=""
									onClick={(e) =>
										changeActive(e, 'media-plan')
									}
								>
									Подробнее
								</a>
							</li>
						</ul>
					</StepElement>
					<StepElement
						number={2}
						title="Если у вас нет готового рекламного материала, 
                        вы можете создать его с помощью конструктора. Нажмите на кнопку «Создать материал»,
                        выберите один из шаблонов или создайте свой."
						url={getHelpImg(helpImgList.dataUpload.tv[2])}
					/>
					<StepElement
						number={3}
						title={
							'После загрузки всех рекламных материалов кнопка «Отправить на модерацию» станет активна.'
						}
						url={getHelpImg(
							helpImgList.dataUpload.common.sendToModerate
						)}
					/>

					<StepElement
						number={4}
						title="Подтвердите действие или перепроверьте всё еще раз, поскольку во время модерации вы не сможете изменить рекламную кампанию."
						url={getHelpImg(helpImgList.dataUpload.common.confirm)}
					/>

					<StepElement
						number={5}
						title="После модерации вам придет уведомление на электронную почту. При успешном прохождении появится счет на оплату."
						url={
							getHelpImg(helpImgList.dataUpload.common.result) +
							'?v1'
						}
					>
						<div>
							{`Рекламную кампанию необходимо оплатить в течение
							суток. В случае, если вы не успеете произвести
							оплату, ваша рекламная кампания перейдет в статус
							"черновик", потребуется повторное подтверждение дат
							проведения рекламной кампании.`}
						</div>
					</StepElement>
				</ol>
			</section>
		</>
	)
}
