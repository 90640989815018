import React, { useState } from 'react'
import s from '../../districts/districtsUpload.module.scss'
import { Card } from 'react-bootstrap'
import { GarantidMediaFormatCardHeader } from 'src/components/common/garantid-card/garantid-media-format-card/components/header/GarantidMediaFormatCardHeader'
import {
	AdvFormatBookingItem,
	GarantidItem,
	MediaFormatViewModel,
	PlacementViewModel,
} from 'src/stores/technical-requirements-store'
import { MassUploadForm } from './MassUploadForm'
import { BookingUpload } from './BookingUpload'
import ls from '../bookingsUpload.module.scss'

const checkIsAllVideoDurationTheSame = (
	bookings: AdvFormatBookingItem<GarantidItem, PlacementViewModel>[]
) => {
	return bookings.every(
		(booking) =>
			booking.item.placement_showing_count.video_duration ===
			bookings[0].item.placement_showing_count.video_duration
	)
}

type Props = {
	format: MediaFormatViewModel<GarantidItem, PlacementViewModel>
}
export const BookingFormatUpload = ({ format }: Props) => {
	//найти букинг где нет креатива или букинг для которого исользовалась групповая загрузка. будет передан как инстанст в массовую загрузку
	const massUploadDelegate = format.booking_item.find(
		(booking) =>
			!booking.item.media_creative ||
			booking.item.media_creative?.is_self_download === false
	)

	const isAllVideoDurationTheSame = checkIsAllVideoDurationTheSame(
		format.booking_item
	)

	const isAlwaysOpen =
		format.booking_item.length <= 1 ||
		!isAllVideoDurationTheSame ||
		!massUploadDelegate

	const [open, setOpen] = useState(isAlwaysOpen)

	return (
		<Card className={s.distictsUploadItem} id={`${format.media_type_name}`}>
			<GarantidMediaFormatCardHeader
				open={open}
				setOpen={setOpen}
				mediaTypeName={format.media_type_name}
				isOpenButton={!isAlwaysOpen}
				counts={{
					approved: 0,
					rejected: 0,
					total: format.counts.total,
				}}
			/>

			{open ? (
				<div className={ls.bookingsUploadContainer}>
					{format.booking_item.map((booking) => (
						<BookingUpload
							requirements={format.media_requirements}
							key={booking.item.id}
							booking={booking}
						/>
					))}
				</div>
			) : (
				<MassUploadForm
					format={format}
					delegate={massUploadDelegate!.item}
				/>
			)}
		</Card>
	)
}
