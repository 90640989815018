import React from 'react'
import s from './advHeader.module.scss'

type Props = {
	Icon: React.FunctionComponent<
		React.SVGProps<SVGSVGElement> & {
			title?: string
		}
	>
	title: string
	description?: string
}
export const AdvHeader = ({ Icon, description, title }: Props) => {
	return (
		<div className={s.advPresentation}>
			<div className={s.advHeader}>
				<h5>{title}</h5>
				<Icon />
			</div>

			{description && (
				<div className={s.advDescription}>
					<p>{description}</p>
				</div>
			)}
		</div>
	)
}
