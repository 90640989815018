import React from 'react'
import { StepElement, StepElementImage } from './StepElement'
import { HelpContentKey, getHelpImg, helpImgList } from '../../../constants'
import s from '../helpContent.module.scss'
import cn from 'classnames'

type IndoorOutdoorHelpContentProps = {
	isMobile: boolean
	changeActive: (
		e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
		itemId: HelpContentKey,
		nestedId?: HelpContentKey
	) => void
	type: 'outdoor' | 'indoor'
}
export const IndoorOutdoorHelpContent = ({
	changeActive,
	isMobile,
	type,
}: IndoorOutdoorHelpContentProps) => {
	return (
		<>
			<h2>
				{type === 'outdoor' ? 'Наружная реклама' : 'Внутренняя реклама'}
			</h2>

			<section>
				<p>
					{type === 'outdoor'
						? 'Наружная реклама — это рекламные сообщения, которые размещены на улице, внешних поверхностях зданий и транспорта. Представлены в виде графических изображений, видео или текста.'
						: 'Внутренняя реклама — рекламные сообщения, которые стационарно размещены внутри помещений общественного назначения.'}
				</p>

				<p>
					{type === 'outdoor'
						? 'Цифровая наружная реклама в Admarket — это анимированное изображение или видеоролик хронометражем 5 секунд. Располагается на экранах на улицах и магистралях города.'
						: 'Цифровая внутренняя реклама в Admarket — это изображение или видеоролик хронометражом 5 секунд. Размещается на экранах в магазинах, торговых и бизнес-центрах, фитнес-клубах и на заправках.'}
				</p>
				<StepElementImage
					isMobile={isMobile}
					alt="Наружная реклама"
					url={
						type === 'outdoor'
							? getHelpImg(helpImgList.outdoor.main)
							: getHelpImg(helpImgList.indoor.main)
					}
				/>
			</section>

			<section>
				<h4 className={cn(s.title)}>
					Создание {type === 'outdoor' ? 'наружной' : 'внутренней'}{' '}
					рекламы
				</h4>
				<ol>
					<li className={cn(s.li_text)}>
						<span>1. </span>
						<p>Перейдите на платформу Admarket.</p>
					</li>
					<li className={cn(s.li_text)}>
						<span>2. </span>
						<p>
							Начните создавать рекламную кампанию.{' '}
							<a
								href=""
								onClick={(e) =>
									changeActive(
										e,
										'campaign-creation',
										'person'
									)
								}
							>
								Подробнее
							</a>
						</p>
					</li>
					<li className={cn(s.li_text)}>
						<span>3. </span>
						<p>Дойдите до шага 3 с выбором форматов рекламы.</p>
					</li>

					<StepElement
						number={4}
						alt="Добавить место"
						title={`В блоке «${
							type === 'outdoor' ? 'Наружная' : 'Внутренняя'
						} реклама» нажмите на кнопку «Добавить место».`}
						url={getHelpImg(helpImgList.outdoor.create[4])}
					/>

					<li className={cn(s.marker)}>
						<p>5. Добавление рекламной конструкции доступно:</p>

						<ul style={{ listStyle: 'none', paddingLeft: '20px' }}>
							<li>
								<p>{'1) через поиск;'}</p>
							</li>
							<li>
								<p>{'2) из выпадающего списка;'}</p>
							</li>
							<li>
								<p>{'3) на карте.'}</p>
							</li>
						</ul>
						<StepElementImage
							isMobile={isMobile}
							alt="Добавление через карту"
							url={
								type === 'outdoor'
									? getHelpImg(helpImgList.outdoor.create[5])
									: getHelpImg(helpImgList.indoor.create[5])
							}
						/>
					</li>

					<StepElement
						number={6}
						title="После того как вы нашли нужный для вас адрес конструкции,
                             нажмите на него, чтобы появилась карточка данной конструкции.
                            Тут вы можете посмотреть все данные, в том числе и стоимость на заданный вами период."
						url={
							type === 'outdoor'
								? getHelpImg(helpImgList.outdoor.create[6])
								: getHelpImg(helpImgList.indoor.create[6])
						}
					/>

					<StepElement
						number={7}
						title="Если вас не устраивает рекламное место, вы можете вернуться к списку адресов кнопкой «Назад»."
						url={
							type === 'outdoor'
								? getHelpImg(helpImgList.outdoor.create[7])
								: getHelpImg(helpImgList.indoor.create[7])
						}
					/>

					<StepElement
						number={8}
						title="Если всё устроило, нажмите на кнопку «Добавить в рекламную кампанию»."
						url={
							type === 'outdoor'
								? getHelpImg(helpImgList.outdoor.create[8])
								: getHelpImg(helpImgList.indoor.create[8])
						}
					/>

					<StepElement
						number={9}
						title="После этого справа появится дополнительное меню с выбранными местами и общей стоимостью. Если уже добавили все необходимые конструкции, то жмите на кнопку «Подтвердить выбор»."
						url={
							type === 'outdoor'
								? getHelpImg(helpImgList.outdoor.create[9])
								: getHelpImg(helpImgList.indoor.create[9])
						}
					/>

					<StepElement
						number={10}
						title="После этого вы вернетесь на шаг 3, но с уже добавленными местами в наружной рекламе. При необходимости вы можете добавить еще места или убрать."
						url={
							type === 'outdoor'
								? getHelpImg(helpImgList.outdoor.create[10])
								: getHelpImg(helpImgList.indoor.create[10])
						}
					/>

					<li>
						<div className={cn(s.li_text)}>
							<span>11. </span>
							<p>
								{`Можете переходить к следующим форматам рекламы. Если решили остановиться только на ${
									type === 'outdoor'
										? 'наружной'
										: 'внутренней'
								} рекламе, в самом низу или закрепленном меню нажмите на кнопку «Подтвердить бюджет».`}
							</p>
						</div>

						<StepElementImage
							alt=""
							isMobile={isMobile}
							url={
								type === 'outdoor'
									? getHelpImg(helpImgList.outdoor.create[11])
									: getHelpImg(helpImgList.indoor.create[11])
							}
						/>
						<div
							className={cn(s.child)}
							style={{ marginTop: '24px' }}
						>
							<ul>
								<li>
									<p>
										{`О добавлении рекламных мест и настройке
										${type === 'outdoor' ? 'внутренней' : 'наружной'} рекламы читайте `}
										<a
											href=""
											onClick={(e) =>
												changeActive(
													e,
													type === 'outdoor'
														? 'indoor'
														: 'outdoor'
												)
											}
										>
											в разделе
										</a>{' '}
										.
									</p>
								</li>
								<li>
									<p>
										О выборе способа показа интернет-рекламы
										читайте{' '}
										<a
											href=""
											onClick={(e) =>
												changeActive(e, 'internet')
											}
										>
											в разделе
										</a>
										.
									</p>
								</li>
								<li>
									О добавлении и настройке ТВ-рекламы читайте{' '}
									<a
										href=""
										onClick={(e) => changeActive(e, 'tv')}
									>
										в разделе
									</a>
									.
								</li>
							</ul>
						</div>
					</li>
				</ol>
			</section>

			<section>
				<h4 style={{ marginBottom: '24px' }}>
					{`Шаг 4. Загрузка материалов для ${
						type === 'outdoor' ? 'наружной' : 'внутренней'
					} рекламы`}
				</h4>
				<ol>
					<StepElement
						number={1}
						title="Загрузите готовые материалы."
						url={getHelpImg(helpImgList.dataUpload.inAndOutDoor[1])}
					>
						<ul>
							<li>
								<p>
									Чтобы посмотреть технические требования к
									рекламному материалу, нажмите на кнопку
									«Требования».
								</p>
							</li>
							<li>
								<p>
									Если у вас есть готовый рекламный материал,
									загрузите его через кнопку «Выберите файл».
								</p>
							</li>
							<li>
								Чтобы посмотреть всю информацию о
								запланированной рекламной кампании, нажмите на
								кнопку «Смотреть медиаплан».{' '}
								<a
									href=""
									onClick={(e) =>
										changeActive(e, 'media-plan')
									}
								>
									Подробнее
								</a>
							</li>
						</ul>
					</StepElement>
					<StepElement
						number={2}
						title="Если у вас нет готового рекламного материала, 
                        вы можете создать его с помощью конструктора. Нажмите на кнопку «Создать материал»,
                        выберите один из шаблонов или создайте свой."
						url={getHelpImg(helpImgList.dataUpload.inAndOutDoor[2])}
					/>
					<StepElement
						number={3}
						title="После загрузки всех рекламных материалов кнопка «Отправить на модерацию» станет активна."
						url={getHelpImg(
							helpImgList.dataUpload.common.sendToModerate
						)}
					/>

					<StepElement
						number={4}
						title="Подтвердите действие или перепроверьте всё еще раз, поскольку во время модерации вы не сможете изменить рекламную кампанию."
						url={getHelpImg(helpImgList.dataUpload.common.confirm)}
					/>

					<StepElement
						number={5}
						title="После модерации вам придет уведомление на электронную почту. При успешном прохождении появится счет на оплату."
						url={
							getHelpImg(helpImgList.dataUpload.common.result) +
							'?v1'
						}
					>
						<div>
							{`Рекламную кампанию необходимо оплатить в течение
							суток. В случае, если вы не успеете произвести
							оплату, ваша рекламная кампания перейдет в статус
							"черновик", потребуется повторное подтверждение дат
							проведения рекламной кампании.`}
						</div>
					</StepElement>
				</ol>
			</section>
		</>
	)
}
