import moment from 'moment'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { ProviderBookingPhotoReport } from './CompletedCampaignReport'
import { AddPhotoReportItem } from './add-photo-report-item-form/AddPhotoReportItemForm'
import { PhotoReportItemsList } from './photo-report-item/PhotoReportItemsList'
import { BookingDetails } from 'src/stores/ProviderTypes.types'

type Props = {
	photos: ProviderBookingPhotoReport[]
	startDate: string
	endDate: string
	bookingId: number
	refetchBookingDetail: () => Promise<void>
}
export const CampaignPhotoReport = ({
	photos,
	endDate,
	startDate,
	bookingId,
	refetchBookingDetail,
}: Props) => {
	const [isTemplate, setIsTemplate] = useState(false)

	const photosList = photos.filter(
		(photos) => photos.photo_reports.length > 0
	)
	const onClose = () => {
		setIsTemplate(false)
	}
	return (
		<div className="d-flex flex-column gap-4">
			<h5 className="m-0">Фотоотчет</h5>

			{photosList.length ? (
				<PhotoReportItemsList
					refetchBookingDetail={refetchBookingDetail}
					photosList={photosList}
					endDate={endDate}
					startDate={startDate}
				/>
			) : (
				<div className="d-flex justify-content-center">
					<h6>Еще нет загруженных фото</h6>
				</div>
			)}

			{!isTemplate ? (
				<Button onClick={() => setIsTemplate(!isTemplate)}>
					Добавить фото
				</Button>
			) : (
				<AddPhotoReportItem
					refetchBookingDetail={refetchBookingDetail}
					bookingId={bookingId}
					onClose={onClose}
					endDate={moment(endDate)}
					startDate={moment(startDate)}
				/>
			)}
		</div>
	)
}
