import { useState } from 'react'

export default function useAuth(callback, finallyCallback?: () => void) {
	const [errors, setErrors] = useState<{} | null>(null)

	async function handleSubmit(data) {
		try {
			await callback(data)
			setErrors(null)
		} catch (e: any) {
			if (e?.code === 404) {
				setErrors({ phone_number: 'Пользователь не найден' })
			} else {
				if (e?.data) {
					if (e.data?.['detail']) {
						setErrors({ detail: e.data['detail'] })
						return
					}
					setErrors(
						Object.entries(e.data).reduce(
							(curr: any, next: any) => {
								curr[next[0]] = next[1][0]
								return curr
							},
							{}
						)
					)
				} else {
					setErrors({ detail: 'Ошибка отправки данных регистрации' })
				}
			}
		} finally {
			if (typeof finallyCallback === 'function') {
				finallyCallback()
			}
		}
	}

	return { errors, handleSubmit, setErrors }
}
