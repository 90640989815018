import React from 'react'
import { BookingDetails } from 'src/stores/ProviderTypes.types'

export enum BookingsTabsNames {
	INNER_MODERATION = 'inner_moderation',
	ADJUSTMENT = 'adjustment',
	MODERATION = 'moderation',
	DRAFT = 'draft',
	CONFIRM = 'confirm',
	COMPLETED = 'completed',
	REJECT = 'reject',
	CANCELED = 'canceled',
	ALL = 'all',
}

export type BookingsTabs = {
	count: number
	display: string
	state: BookingsTabsNames
}[]

export enum OrderingVariant {
	STATE_ASC = 'state',
	STATE_DESC = '-state',
	NAME_ASC = 'placement_name',
	NAME_DESC = '-placement_name',
	NULL = '',
}

export type ActionButtonClickProps = {
	action: 'accept' | 'edit' | 'reject' | 'download'
	id: number | number[]
	booking: BookingDetails
}

export type BookingsContextType = {
	tabs: BookingsTabs
	selectedTab: BookingsTabsNames
	setSelectedTab: React.Dispatch<React.SetStateAction<BookingsTabsNames>>
	searchString: string
	setSearchString: React.Dispatch<React.SetStateAction<string>>
	filterByAd: string[]
	filterByRegions: string[]
	setFilterByRegions: React.Dispatch<React.SetStateAction<string[]>>
	setFilterByAd: React.Dispatch<React.SetStateAction<string[]>>
	filterByCompany: string[]
	setFilterByCompany: React.Dispatch<React.SetStateAction<string[]>>
	limit: number
	offset: number
	setLimit: React.Dispatch<React.SetStateAction<number>>
	setOffset: React.Dispatch<React.SetStateAction<number>>
	isListFetching: boolean
	selectedBooking: number
	setSelectedBooking: React.Dispatch<React.SetStateAction<number>>
	ordering: OrderingVariant
	onOrderingTabClick: (tabName: 'placement_name' | 'state') => void
	onActionButtonClick: ({}: ActionButtonClickProps) => void
}
