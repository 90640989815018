import React, { useEffect } from 'react'
import s from './ADMarketTreeDropDown.module.scss'
import { Button, Form } from 'react-bootstrap'

type TreeValue = {} & {
	id: number
	title: string
	parent_id: number | null
}

type Props = {
	tree: TreeValue[]
	onChange: (ids: number[]) => void
	children: ({
		onChange,
		isItemInSelected,
	}: {
		onChange: (ids: number[]) => void
		isItemInSelected: (itemID: number) => boolean
		searchString: string
	}) => JSX.Element
	withSearch?: boolean
	search: string
	setSearch: (searchString: string) => void
	isItemInSelected: (itemID: number) => boolean
	close: () => void
	onSelectAll: () => void
	onClearAll: () => void
}

export const AdmarketTreeDropDownMenu = ({
	tree,
	onChange,
	children,
	withSearch = true,
	setSearch,
	isItemInSelected,
	search,
	close,
	onClearAll,
	onSelectAll,
}: Props) => {
	useEffect(() => {
		return () => {
			setSearch('')
		}
	}, [])
	return (
		<div className={s.menu}>
			{withSearch && (
				<div className={s.search}>
					<Form.Control
						type={'text'}
						placeholder={'Поиск'}
						onChange={(e) => setSearch(e.target.value.trim())}
					/>
					<i className="bi bi-search"></i>
				</div>
			)}
			<div className={s.menuData}>
				{children &&
					children({
						onChange,
						isItemInSelected,
						searchString: search,
					})}
			</div>
			<div className={s.menuControls}>
				<div>
					<a
						onClick={(e) => {
							e.preventDefault()
							onSelectAll()
						}}
						href={'/'}
					>
						Выбрать все
					</a>
				</div>
				<div>
					<a
						onClick={(e) => {
							e.preventDefault()
							onClearAll()
						}}
						href={'/'}
					>
						Снять все
					</a>
				</div>
				<div>
					<Button onClick={close} style={{ marginLeft: '-12px' }}>
						Готово
					</Button>
				</div>
			</div>
		</div>
	)
}
