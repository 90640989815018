import { BASE_URL } from 'src/constants/api'
import { authFetch } from './authFetch'

export const downloadByRequest = async (
	url: string,
	filename: string,
	method = 'GET' as any
) => {
	try {
		const res: any = await authFetch({
			method: method,
			url: `${BASE_URL}/${url}`,
			raw: true,
		})

		const blob: Blob | undefined = await res.blob()

		if (blob) {
			const newBlob = new Blob([blob])
			const blobUrl = window.URL.createObjectURL(newBlob)
			const link = document.createElement('a')
			link.href = blobUrl
			link.setAttribute('download', filename)
			document.body.appendChild(link)
			link.click()
			link?.parentNode?.removeChild(link)
			window.URL.revokeObjectURL(blobUrl)
		}
		return {
			success: true,
		}
	} catch (e) {
		console.log('something went wrong while downloadByRequest', e)
		return {
			success: false,
		}
	}
}
