import React from 'react'
import { ReactComponent as Open } from '../../assets/images/select-open.svg'
import { ReactComponent as Close } from '../../assets/images/select-hide.svg'

type ConditionArrowUpDownProps = {
	open: boolean
	variant?: 'short' | 'long'
	styles?: React.CSSProperties
}

export const ConditionArrowUpDown = ({
	open,
	variant = 'short',
	styles,
}: ConditionArrowUpDownProps) => {
	if (variant === 'long') {
		return open ? (
			<i style={styles} className="bi bi-arrow-up"></i>
		) : (
			<i style={styles} className="bi bi-arrow-down"></i>
		)
	}
	return open ? <Close style={styles} /> : <Open style={styles} />
}
