import React from 'react'
import s from './ADFormatSellector.module.scss'
import { Card } from 'react-bootstrap'
import useCampaignData from '../../../../stores/campaignData'
import { ReactComponent as PublicTransportIco } from 'src/assets/images/metro.svg'
import { ReactComponent as Outdoor } from 'src/assets/images/billboard_2.svg'
import { ReactComponent as Indoor } from 'src/assets/images/indoor.svg'
import { ReactComponent as Internet } from 'src/assets/images/internet.svg'
import { ReactComponent as TV } from 'src/assets/images/tv.svg'

export const SelectedIcon = () => (
	<svg
		width="18"
		height="14"
		viewBox="0 0 18 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.4571 2.3107L6.95706 12.8107C6.56654 13.2012 5.93337 13.2012 5.54285 12.8107L0.542847 7.8107L1.95706 6.39648L6.24995 10.6894L16.0428 0.896484L17.4571 2.3107Z"
		/>
	</svg>
)

export const AdIcon = ({
	name,
	withCurrentColor = false,
}: {
	name: 'indoor' | 'outdoor' | 'internet' | 'tv' | 'publicTransport'
	withCurrentColor?: boolean
}): JSX.Element => {
	switch (name) {
		case 'internet':
			return <Internet />
		case 'outdoor':
			return <Outdoor />
		case 'tv':
			return <TV />
		case 'indoor':
			return <Indoor />
		case 'publicTransport':
			return <PublicTransportIco />
		default:
			return <></>
	}
}

const AdFormatSelector = ({
	formats,
	setFormats,
	cn,
	addToStack,
	removeFromStack,
}) => {
	const [campaign, campaignInterface] = useCampaignData()

	function clickHandle(key) {
		const tmp = formats?.find((el) => el.key === key)
		tmp.isSelected = !tmp.isSelected
		tmp?.data?.forEach((val) => {
			val.isSelected = false
		})
		tmp.isSelected
			? addToStack({ Component: tmp.Component, key: key })
			: removeFromStack(key)
		if (!tmp.isSelected && key === 'internet') {
			removeFromStack(['vk', 'yandex'])
		}
		// if (!tmp.isSelected && key === 'tv') {
		// 	removeFromStack(['video', 'banner'])
		// }
		setFormats((p) => p.map((el) => (el.key === key ? tmp : el)))

		ActionByADType(key, tmp.isSelected).then()

		if (key === 'tv') {
			// раньше было 2 формата tv: video и banner (adstream и teletarget)
			// сейчас оставили один, и при выборе/отмене tv
			// приравниваем это к выбору/отмене video
			updVideoFormat(tmp)
		}
	}

	async function updVideoFormat(tvTmp) {
		const videoTmp = tvTmp.data.find((item) => item.key === 'video')
		videoTmp.isSelected = tvTmp.isSelected

		setFormats((p) => p.map((el) => (el.key === 'video' ? videoTmp : el)))
		videoTmp.isSelected
			? addToStack({ Component: videoTmp.Component, key: 'video' }, 'tv')
			: removeFromStack('video')
		await campaignInterface.saveVideoFilters(campaign?.id, {
			is_selected: !!videoTmp.isSelected,
		})
	}

	async function ActionByADType(key, isSelected) {
		if (key === 'outdoor') {
			await campaignInterface.patchCampaign(campaign?.id, {
				outdoor_adv_is_selected: isSelected,
			})
		}
		if (key === 'indoor') {
			await campaignInterface.patchCampaign(campaign?.id, {
				indoor_adv_is_selected: isSelected,
			})
		}
		if (key === 'internet') {
			if (isSelected) return
			await campaignInterface.changeInternetStatus(
				campaign?.id,
				isSelected
			)
		}
		if (key === 'tv') {
			if (isSelected) return
			await campaignInterface.saveTeletargetFilters(campaign?.id, {
				is_selected: isSelected,
			})
			await campaignInterface.saveVideoFilters(campaign?.id, {
				is_selected: isSelected,
			})
		}
	}
	return (
		<>
			<Card className={cn('form')} style={{ zIndex: 12 }}>
				<h2 className={cn('subtitle')} style={{ marginBottom: '8px' }}>
					Форматы рекламы
				</h2>
				<div
					className={cn('separator')}
					style={{ margin: 0, padding: 0, marginBottom: '24px' }}
				/>
				<div style={{ position: 'relative', width: '100%' }}>
					<div style={{ marginBottom: '16px' }}>
						Выберите один или несколько форматов рекламы
					</div>
					<div className={s.container}>
						{formats.map((format) => (
							<div
								className={
									format?.isSelected
										? s.formatSelected
										: s.format
								}
								key={format.key}
								onClick={(e) => {
									e.preventDefault()
									clickHandle(format.key)
								}}
							>
								<div className={s.icon}>
									{format.isSelected && <SelectedIcon />}
									{!format.isSelected && (
										<AdIcon name={format.key} />
									)}
								</div>
								<span className={s.title + ' noselect'}>
									{format.title}
								</span>
							</div>
						))}
					</div>
				</div>
			</Card>
		</>
	)
}

export default AdFormatSelector
