import React from 'react'
import { ReactComponent as LocationPlacemarker } from 'src/assets/images/location-3.svg'
import { ReactComponent as City } from 'src/assets/images/radius.svg'
import { ReactComponent as Ok } from 'src/assets/images/ok.svg'
import { OutdoorVariants } from '../../../../../../../types'
import cn from 'classnames'
import s from './outdoorSelector.module.scss'

type Props = {
	title: string
	selectorKey: OutdoorVariants
	isSelected: boolean
	description: string
	onClick: (variant: OutdoorVariants) => void
	isLoading: boolean
}
export const OutdoorVariantSelector = ({
	title,
	selectorKey,
	isSelected,
	description,
	onClick,
	isLoading,
}: Props) => {
	const VariantIcon =
		selectorKey === OutdoorVariants.GarantViews ? LocationPlacemarker : City
	return (
		<div
			className={cn({
				[s.format_selected]: isSelected,
				[s.format]: !isSelected,
				[s.format_disabled]: isLoading,
			})}
			onClick={() => onClick(selectorKey)}
		>
			<div className={cn(s.icon)}>
				{isSelected ? <Ok /> : <VariantIcon />}
			</div>
			<div className={s.title}>
				<div>{title}</div>
				<span>{description}</span>
			</div>
		</div>
	)
}
