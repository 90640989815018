import React from 'react'
import { Modal } from 'react-bootstrap'
import BottomSheet from 'src/components/UtilityComponents/Bottomsheet'
import MobileModal from 'src/components/UtilityComponents/MobileModal'
import { MoscowCentRingRequirements } from 'src/components/_client/create-campaign/Step3-Upload/components/public-transport/moscow-cent-ring-upload/requirements/MoscowCentRingRequirements'
import useResponsive from 'src/utils/useResponsive'
type Props = {
	open: boolean
	onClose: () => void
}

export const MoscowCentRingRequirementsModal = ({ onClose, open }: Props) => {
	const { isMobile } = useResponsive()

	return (
		<>
			{!isMobile && (
				<Modal
					show={open}
					onHide={onClose}
					centered
					// size={'lg'}
					animation={true}
				>
					<Modal.Header closeButton>
						<Modal.Title as={'h5'} style={{ paddingLeft: '8px' }}>
							Требования к цифровому экрану МЦК
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className={'p-0'}>
						<MoscowCentRingRequirements isHeader={false} />
					</Modal.Body>
				</Modal>
			)}
			{isMobile && (
				<MobileModal
					show={open}
					onHide={onClose}
					title={'Исправление рекламного места'}
					contentStyles={{ padding: 0 }}
				>
					<div className={'d-flex flex-column h-100'}>
						<MoscowCentRingRequirements isHeader={false} />
					</div>
				</MobileModal>
			)}
		</>
	)
}
