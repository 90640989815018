import React, { useCallback, useMemo } from 'react'
import { HandleActiveItemChangeType, HelpContentKey } from '../../constants'
import s from './helpContent.module.scss'
import cn from 'classnames'

import useResponsive from 'src/utils/useResponsive'
import {
	CreatingContent,
	DocumentsContent,
	InternetHelpContent,
	MediaPlanContent,
	RegistrationContent,
	TvHelpContent,
} from './content'
import { IndoorOutdoorHelpContent } from './content/IndoorOutdoorHelpContent'
import { StatisticContent } from './content/StatisticContent'

type HelpContentProps = {
	contentKey: HelpContentKey
	changeActive: HandleActiveItemChangeType
}

export const HelpContent = ({ contentKey, changeActive }: HelpContentProps) => {
	const { isMobile, isTablet } = useResponsive()

	const handleLinkClick = useCallback(
		(
			e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
			itemId: HelpContentKey,
			nestedId?: HelpContentKey
		) => {
			e.preventDefault()
			changeActive(itemId, nestedId)
			window.scrollTo({ top: 0, behavior: 'smooth' })
		},
		[changeActive]
	)

	const content = useMemo((): JSX.Element => {
		switch (contentKey) {
			case 'registration':
				return <RegistrationContent />
			case 'person':
			case 'legal':
				return (
					<CreatingContent
						contentKey={contentKey}
						changeActive={handleLinkClick}
					/>
				)
			case 'media-plan':
				return <MediaPlanContent />
			case 'indoor':
				return (
					<IndoorOutdoorHelpContent
						type="indoor"
						changeActive={handleLinkClick}
						isMobile={isMobile}
					/>
				)
			case 'outdoor':
				return (
					<IndoorOutdoorHelpContent
						type="outdoor"
						changeActive={handleLinkClick}
						isMobile={isMobile}
					/>
				)
			case 'internet':
				return (
					<InternetHelpContent
						changeActive={handleLinkClick}
						isMobile={isMobile}
					/>
				)
			case 'tv':
				return (
					<TvHelpContent
						changeActive={handleLinkClick}
						isMobile={isMobile}
					/>
				)
			case 'documents':
				return <DocumentsContent />
			case 'statistics':
				return <StatisticContent />
			default:
				return <div>Справка в разработке</div>
		}
	}, [contentKey])

	return (
		<div>
			<article
				className={cn(s.acticle, {
					[s.acticle_tablet]: isTablet,
					[s.acticle_mobile]: isMobile,
				})}
			>
				{content}
			</article>
		</div>
	)
}
