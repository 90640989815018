import React, { useRef } from 'react'
import bem from '../../utils/bem'
import '../../styles/footer.scss'
import moment from 'moment'
import { offerta, personalDataAgreement } from 'src/constants/links'

const cn = bem('footer')

export const FOOTER_LINKS = [
	{ label: 'Договор оферты', href: offerta },
	{
		label: 'Политика обработки персональных данных',
		href: personalDataAgreement,
	},
	{
		label: 'support@admarket.ru',
		href: 'mailto:support@admarket.ru',
		blue: true,
	},
]

export default function Footer({ forceOpened, wide }) {
	const currentYear = useRef(moment().year())
	return (
		<div className={cn(null, { sidebarOpen: forceOpened, wide: wide })}>
			<div className={cn('links')}>
				{FOOTER_LINKS.map(({ label, href, blue }) =>
					label === 'support@admarket.ru' ? (
						<a
							key={label}
							className={cn('link', { blue })}
							href={href}
						>
							{label}
						</a>
					) : (
						<a
							key={label}
							className={cn('link', { blue })}
							href={href}
							target={'_blank'}
							rel="noreferrer"
						>
							{label}
						</a>
					)
				)}
			</div>
			<div className={cn('copyright')}>
				© {currentYear.current} ООО «ЭдТех Инвест»
			</div>
		</div>
	)
}
