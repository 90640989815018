import React, { useMemo } from 'react'
import { MassCoverageList } from './variants/mass-coverage/MassCoverageList'
import s from './recFormats.module.scss'
import { OutdoorsList } from './variants/outdoor/OutdoorsList'
import { IndoorsList } from './variants/indoor/IndoorsList'
import { MCRList } from './variants/mcr/MCRList'
import { YandexList } from './variants/yandex/YandexList'
import { VkList } from './variants/vk/VkList'
import { AdstreamList } from './variants/adstream/AdstreamList'
import { TotalRecBudget } from './total-rec-budget/TotalRecBudget'
import {
	RecommendationsApplyInput,
	RecommendationsType,
} from 'src/stores/types/recommendations'
import useResponsive from 'src/utils/useResponsive'

type Props = {
	errors: RecommendationsApplyInput
	recommendations: RecommendationsType | null
	onChangeApplyInput: (
		field: keyof RecommendationsApplyInput,
		value: string
	) => void
	applyInput: RecommendationsApplyInput
}
export const RecommendedFormats = ({
	recommendations,
	onChangeApplyInput,
	applyInput,
	errors,
}: Props) => {
	const budget = recommendations?.budget

	const outdoor = useMemo(() => {
		if (!recommendations?.bookings) {
			return null
		}

		const bookings = recommendations.bookings.filter(
			(el) => el.placement.door_type === 'outdoor'
		)
		if (!bookings.length) {
			return null
		}

		return bookings
	}, [recommendations])

	const indoor = useMemo(() => {
		if (!recommendations?.bookings) {
			return null
		}

		const bookings = recommendations.bookings.filter(
			(el) => el.placement.door_type === 'indoor'
		)
		if (!bookings.length) {
			return null
		}

		return bookings
	}, [recommendations])

	const summaryBudget = [
		{
			title: 'Наружная реклама',
			id: 'outdoor',
			items: [
				{
					title: 'Гарантированные показы',
					value: budget?.outdoor || '0',
					isSelected: Boolean(outdoor?.length),
				},
				{
					title: 'Максимальный охват',
					value: budget?.district || '0',
					isSelected: Boolean(
						recommendations?.districtbookings?.length
					),
				},
			],
		},
		{
			title: 'Внутренняя реклама',
			id: 'indoor',
			items: [
				{
					title: '',
					value: budget?.indoor || '0',
					isSelected: Boolean(indoor?.length),
				},
			],
		},
		{
			title: 'Интернет-реклама',
			id: 'internet',
			items: [
				{
					title: 'Яндекс.Директ',
					value: budget?.yandex || '0',
					isSelected: Boolean(recommendations?.yandex),
				},
				{
					title: 'VK Реклама',
					value: budget?.vk || '0',
					isSelected: Boolean(recommendations?.vk),
				},
			],
		},
		{
			title: 'Общественный транспорт',
			id: 'transport',
			items: [
				{
					title: 'Цифровые экраны на МЦК',
					value: budget?.transport || '0',
					isSelected: Boolean(recommendations?.public_transport),
				},
			],
		},
		{
			title: 'ТВ-реклама',
			id: 'tv',
			items: [
				{
					title: 'AdStream',
					value: budget?.tv || '0',
					isSelected: Boolean(recommendations?.adstream),
				},
			],
		},
	]

	const { isMobile } = useResponsive()
	return (
		<div className={s.formatsList}>
			{recommendations?.districtbookings && (
				<MassCoverageList
					items={recommendations.districtbookings}
					budget={budget?.district || '0'}
				/>
			)}

			{outdoor && (
				<OutdoorsList
					bookings={outdoor}
					budget={budget?.outdoor || '0'}
				/>
			)}

			{indoor && (
				<IndoorsList bookings={indoor} budget={budget?.indoor || '0'} />
			)}

			{recommendations?.public_transport && (
				<MCRList mcr={recommendations.public_transport} />
			)}

			{recommendations?.yandex && (
				<YandexList
					yandex={recommendations.yandex}
					onChangeApplyInput={onChangeApplyInput}
					link={applyInput.yandex_link}
					errors={errors}
				/>
			)}

			{recommendations?.vk && (
				<VkList
					vk={recommendations.vk}
					onChangeApplyInput={onChangeApplyInput}
					link={applyInput.vk_link}
					errors={errors}
				/>
			)}

			{recommendations?.adstream && (
				<AdstreamList adstream={recommendations.adstream} />
			)}

			{recommendations?.budget &&
				recommendations?.budget_vat &&
				!isMobile && (
					<TotalRecBudget
						budget={summaryBudget}
						total={{
							budget: recommendations.budget.total,
							budget_vat: recommendations.budget_vat?.total,
						}}
					/>
				)}
		</div>
	)
}
