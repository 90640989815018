import { SpecialAdvSelectorData, UserConfig } from '../types'

const defaultSpecialProps: SpecialAdvSelectorData = {
	indoor: { defaultDisabled: false },
	internet: { defaultDisabled: false },
	outdoor: { defaultDisabled: false },
	publicTransport: { defaultDisabled: false },
	tv: { defaultDisabled: true },
}

export const getSpecialSelectorProps = (
	userConfig: UserConfig | undefined
): SpecialAdvSelectorData => {
	if (!userConfig) {
		return defaultSpecialProps
	}

	return {
		indoor: { defaultDisabled: !userConfig.is_enable_indoor },
		internet: {
			defaultDisabled:
				!userConfig.is_enable_yandex && !userConfig.is_enable_vk,
		},
		outdoor: { defaultDisabled: !userConfig.is_enable_outdoor },
		publicTransport: { defaultDisabled: !userConfig.is_enable_outdoor },
		tv: { defaultDisabled: !userConfig.is_enable_tv },
	}
}
