import React, { ReactNode } from 'react'
import s from './articles.module.scss'

type ArticleProps = {
	title: string | ReactNode
	children: ReactNode
	isBorder?: boolean
}
export const MoscowCentRingRequirementsArticle = ({
	title,
	isBorder = true,
	children,
}: ArticleProps) => {
	return (
		<div className={s.article}>
			<h6 style={{ borderBottom: isBorder ? 'auto' : 'none' }}>
				{title}
			</h6>
			{children}
		</div>
	)
}
type ArticleItemProps = {
	title: string
	style?: React.CSSProperties
	children: React.ReactNode
}
export const ArticleItem = ({ title, children, style }: ArticleItemProps) => {
	return (
		<div className={s.articleItem} style={style}>
			<span>{title}</span>
			<div>{children}</div>
		</div>
	)
}
