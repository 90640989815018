import React from 'react'
import ls from '../style.module.scss'
import { Accordion } from 'react-bootstrap'
import { ReactComponent as PublicTransportIcon } from 'src/assets/images/metro.svg'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { CommonAdvAccordionsProps } from '../CampaignDetailedInfo'
import { BookingState } from 'src/stores/ADMarketTypes.types'
import { getAdjustmentMessage } from 'src/components/_moderator/utils'
import { MoscowCentRingAccordionBody } from './accorion-bodies/MoscowCentRingAccordionBody'

export const PublicTransportDetailedAccordion = ({
	setDetailedItem,
	setAdvItemActon,
	onEditVinstant,
}: CommonAdvAccordionsProps) => {
	const [moder, moderInterface] = useModerationData()

	return (
		<Accordion className={ls.accordion} defaultActiveKey="0">
			<Accordion.Item eventKey="0">
				<Accordion.Header as={'div'} className={ls.accordionHeader}>
					<PublicTransportIcon style={{ fill: '#212529' }} />
					<h6>Общественный транспорт</h6>
				</Accordion.Header>

				{moder?.detailedCampaign?.public_transport?.is_selected && (
					<MoscowCentRingAccordionBody
						onEditVinstant={onEditVinstant}
						adjusmentMessage={getAdjustmentMessage({
							state: moder.detailedCampaign.public_transport
								.state as unknown as BookingState,
							adjusmentMessage:
								moder.detailedCampaign.public_transport
									.adjustment_msg,
							rejectMessage:
								moder.detailedCampaign.public_transport
									.reject_msg,
						})}
						setAdvItemActon={setAdvItemActon}
						setDetailedItem={setDetailedItem}
					/>
				)}
			</Accordion.Item>
		</Accordion>
	)
}
