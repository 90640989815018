import React from 'react'
import { RegistrationInfo } from './registration-info/RegistrationInfo'
import s from './registration.module.scss'

type Props = {
	toLogin: () => void
}
export const RegistrationFooter = ({ toLogin }: Props) => {
	return (
		<div className={s.registrationFooter}>
			<div className={s.footerLink}>
				<p>Уже есть аккаунт?</p> <span onClick={toLogin}>Войти</span>
			</div>
		</div>
	)
}
