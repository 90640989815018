//this function is usefull for displaying numbers with thousands separations
export const formattedNumber = (value: number | string | null | undefined) => {
	if (value === null || value === undefined) {
		return 'Неизвестно'
	}

	return Number(value).toLocaleString('ru-RU', {
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	})
}
