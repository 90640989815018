import React from 'react'
import { Accordion } from 'react-bootstrap'
import {
	BookingState,
	DistrictBookingExternalType,
	DistrictBookingsType,
} from 'src/stores/ADMarketTypes.types'
import ls from '../../style.module.scss'
import { districtBookingExternalTitles } from 'src/components/_client/create-campaign/Step3-Upload/constants/districtBookingExternalTitles'
import { RenderPreview } from 'src/components/UtilityComponents/ProgressFileInput'
import { AdvItemActions } from '../../common/AdvItemActions'
import { AdvItemInfo } from '../../common/AdvItemInfo'
import { AdvStateType } from '../../common/ModerationModalButton'
import { CommonAdvAccordionsProps } from '../../CampaignDetailedInfo'
import { AdjusmentMessage } from '../../common/AdjusmentMessage'
import { DocsView } from '../../common/DocsView'
import {
	DocsListType,
	ExtraFields,
} from 'src/components/_moderator/types/ModerationRequestTypes'
import { EditVinstantButton } from '../../common/EditVinstantButton'
import useNotificationData from 'src/stores/notificationsData'

type Props = CommonAdvAccordionsProps & {
	districtbooking: DistrictBookingsType &
		DocsListType &
		Omit<ExtraFields, 'edit_vinstant'>
	cityName: string | undefined
	adjusmentMessage: string
}
export const DistrictBookinAccordionBody = ({
	districtbooking,
	cityName,
	setDetailedItem,
	setAdvItemActon,
	adjusmentMessage,
	onEditVinstant,
}: Props) => {
	const [, notificationsInterface] = useNotificationData()

	const handleSetDetailedItem = () => {
		setDetailedItem({ id: districtbooking.id, type: 'districtbooking' })
	}
	const handleSetAdvItemActionType = (
		action: 'moderation' | 'adjustment' | 'reject'
	) => {
		setAdvItemActon({
			action: action,
			type: 'districtbooking',
			rejectMessage: districtbooking.reject_msg,
			adjustmentMessage: districtbooking.adjustment_msg,
			isDocRequired: districtbooking.is_docs_required,
			item: {
				id: districtbooking.id,
				name: '',
				placeName: '',
				src: 'https://storage.yandexcloud.net/admarket-images/static-img/outdoor_26_11zon.jpg',
			},
		})
	}
	const handleEditVinstant = (
		link: string,
		externalType: DistrictBookingExternalType
	) => {
		onEditVinstant({
			instanceId: districtbooking.id,
			newContentKey: districtbooking.id + externalType,
			vinstantLink: link,
			externalType: externalType,
		})
	}
	return (
		<Accordion.Body className={ls.accordionBody}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '16px',
					padding: '16px',
				}}
			>
				<h6 style={{ margin: 0 }}>{cityName}</h6>
				<AdvItemInfo
					budget={parseFloat(districtbooking.amount)}
					clicks={districtbooking.click_count}
					expenses={districtbooking.expense}
					format={'Видео'}
					shows={districtbooking.views_count}
					status={districtbooking.state}
				/>

				{districtbooking?.creatives.map((creative) => (
					<div
						key={creative.id}
						style={{
							display: 'flex',
							gap: '0.5rem',
							alignItems: 'flex-start',
						}}
					>
						<div className={ls.mediaCreativePreview}>
							<RenderPreview
								style={{
									width: '60px',
									height: '60px',
									borderRadius: '4px',
									minHeight: '60px',
									minWidth: '60px',
								}}
								value={{
									name: creative.file_name,
									src: creative.file,
								}}
							/>
							<div>
								<div>
									{
										districtBookingExternalTitles[
											creative.external_type
										]
									}
								</div>
								<span>{creative.file_name}</span>
							</div>
						</div>
						<EditVinstantButton
							editVinstanLink={creative.edit_vinstant}
							onEditVinstant={(link) =>
								handleEditVinstant(link, creative.external_type)
							}
							vinstantEditStatus={notificationsInterface.getNewContentCreated(
								districtbooking.id + creative.external_type
							)}
						/>
					</div>
				))}

				{!!adjusmentMessage && (
					<AdjusmentMessage
						isDocRequired={districtbooking.is_docs_required}
						adjusmentMessage={adjusmentMessage}
					/>
				)}
				{!!districtbooking.docs.length && (
					<DocsView
						docs={districtbooking.docs}
						id={districtbooking.id}
						isDocRequired={districtbooking.is_docs_required}
					/>
				)}

				<AdvItemActions
					setAdvItemActionType={handleSetAdvItemActionType}
					state={districtbooking.state as AdvStateType}
					setDetailedItem={handleSetDetailedItem}
				/>

				{/* documents */}
			</div>
		</Accordion.Body>
	)
}
