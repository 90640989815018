import React from 'react'
import { ReactComponent as IndoorIcon } from 'src/assets/images/indoor.svg'
import s from './indoorAdvHeader.module.scss'

export const IndoorAdvHeader = (props: any) => {
	return (
		<div className={s.outdoorHeader}>
			<div className={s.primaryText}>
				<h4>Внутренняя реклама</h4>
				<div>
					<IndoorIcon />
				</div>
			</div>
			<p>
				Реклама внутри помещений общественного назначения — в торговых
				центрах, фитнес-центрах, лифтах, кинотеатрах, ресторанах.
			</p>
		</div>
	)
}
