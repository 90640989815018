import React from 'react'
import { CardWrapper } from '../../../../../common/card-wrapper/CardWrapper'
import VkAd from 'src/components/_client/create-campaign/Step2-ADformats/InternetAD/VkAd'

export const Vk = () => {
	return (
		<CardWrapper>
			<VkAd />
		</CardWrapper>
	)
}
