import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useProviderData from 'src/stores/providerData'
import { ProviderFilterCheckbox } from 'src/components/_provider/Bookings/filters/components/common/ProviderFilterCheckbox'
import { useQueryParams } from 'src/utils'
import {
	ProviderFilterContentBody,
	ProviderFilterContentFooter,
	ProviderFilterContentHeader,
} from 'src/components/_provider/Bookings'
import { PlacementFilterKeyType } from '../../constants/placementFilters'
import useResponsive from 'src/utils/useResponsive'

type ProviderCompaniesFilterContentProps = {
	onFilterSubmit: (key: PlacementFilterKeyType, value: Array<string>) => void
	onClose: () => void
	onChange?: (key: PlacementFilterKeyType, value: Array<string>) => void
	selectedItems?: Array<string>
}
type BaseProviderRegionsQueryType = {
	placement_region: string | string[] | undefined
}
export const ProviderRegionsFilterContent = <
	T extends BaseProviderRegionsQueryType
>({
	onFilterSubmit,
	onClose,
	selectedItems,
	onChange,
}: ProviderCompaniesFilterContentProps) => {
	const [provider] = useProviderData()

	const { params, pathName } = useQueryParams<BaseProviderRegionsQueryType>()

	const [selectedRegions, setSelectedRegions] = useState(() => {
		if (selectedItems) return selectedItems

		if (params?.placement_region) {
			if (Array.isArray(params?.placement_region))
				return params?.placement_region
			return [params?.placement_region]
		} else {
			return []
		}
	})

	const regions = provider?.bookings_filters?.regions

	const [searchString, setSearchString] = useState('')

	const filtredRegions = useMemo(
		() =>
			regions?.filter((el) =>
				typeof el.name === 'string'
					? el.name.toLowerCase().includes(searchString.toLowerCase())
					: false
			),
		[searchString, regions]
	)

	const handleSelectedChange = (regionId: string) => {
		if (selectedRegions.includes(regionId)) {
			setSelectedRegions(selectedRegions.filter((el) => el !== regionId))
		} else {
			setSelectedRegions([...selectedRegions, regionId])
		}
	}

	const handleFilterSubmit = () => {
		onFilterSubmit('placement_region', selectedRegions)
	}
	const selectList = useMemo(
		() =>
			filtredRegions?.map((el) => (
				<ProviderFilterCheckbox
					checked={selectedRegions.includes(el.id)}
					onChange={() => handleSelectedChange(el.id)}
					text={el.name}
					key={el.id}
				/>
			)),
		[searchString, regions, selectedRegions]
	)
	const handleChangeAll = useCallback(() => {
		if (selectedRegions?.length >= filtredRegions?.length) {
			return setSelectedRegions([])
		} else {
			setSelectedRegions(filtredRegions?.map((campaign) => campaign.id))
		}
	}, [selectedRegions, regions, filtredRegions])

	useEffect(() => {
		onChange && onChange('placement_region', selectedRegions)
	}, [selectedRegions])

	const { isMobile } = useResponsive()
	return (
		<div
			id={'provider-filter-content-wrapper'}
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				width: '100%',
			}}
		>
			<ProviderFilterContentHeader
				onClick={handleChangeAll}
				isAllSelected={selectedRegions.length >= filtredRegions?.length}
				count={selectedRegions.length}
				setSearchString={setSearchString}
			/>

			<ProviderFilterContentBody>
				<div>{selectList}</div>
			</ProviderFilterContentBody>

			{isMobile && (
				<ProviderFilterContentFooter
					onApplyFilters={handleFilterSubmit}
					onClose={onClose}
				/>
			)}
		</div>
	)
}
