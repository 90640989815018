import React, { useState } from 'react'
import s from './passReset.module.scss'
import { PassResetFooter } from './PassResetFooter'
import { PassResetStages } from './PassResetStages'
import { PassResetHeader } from './PassResetHeader'
import {
	CodeConfirmationFormData,
	CodeResendData,
	NewPasswordFormData,
	PassResetStagesType,
	PhoneInputFormData,
} from '../../model/types'
import { FormikHelpers } from 'formik'
import { PhoneConfirmationVariant } from 'src/stores/userData'

type Props = {
	stage: PassResetStagesType
	phoneNumber: string
	expirationDateKey: string
	lastConfirmationVariantKey: string

	setStage: (stage: PassResetStagesType) => void
	onResendConfirmationCode: (values: CodeResendData) => Promise<void>
	onEnterPhoneNumber: (
		values: PhoneInputFormData,
		formikHelpers: FormikHelpers<PhoneInputFormData>
	) => Promise<void>
	onConfirmCode: (
		values: CodeConfirmationFormData,
		formikHelpers: FormikHelpers<CodeConfirmationFormData>,
		confirmationVariant: PhoneConfirmationVariant
	) => Promise<any>
	onEnterNewPassword: (
		values: NewPasswordFormData,
		formikHelpers: FormikHelpers<NewPasswordFormData>
	) => Promise<any>
	toRegister: () => void
	toLogin: () => void
}
export const PassReset = ({
	stage,
	phoneNumber,
	expirationDateKey,
	lastConfirmationVariantKey,

	setStage,
	onResendConfirmationCode,
	onConfirmCode,
	onEnterNewPassword,
	onEnterPhoneNumber,
	toLogin,
	toRegister,
}: Props) => {
	return (
		<div className={s.passReset}>
			<PassResetHeader />
			<PassResetStages
				lastConfirmationVariantKey={lastConfirmationVariantKey}
				expirationDateKey={expirationDateKey}
				phoneNumber={phoneNumber}
				setStage={setStage}
				onResendConfirmationCode={onResendConfirmationCode}
				onConfirmCode={onConfirmCode}
				onEnterNewPassword={onEnterNewPassword}
				stage={stage}
				onEnterPhoneNumber={onEnterPhoneNumber}
			/>
			<PassResetFooter toLogin={toLogin} toRegister={toRegister} />
		</div>
	)
}
