import React from 'react'
import { OutdoorVariantSelector } from './outdoor-variant-selector/OutdoorVariantSelector'
import { OutdoorVariants } from '../../../../../../types'
import s from './outdoorVariantsSelectors.module.scss'

type Props = {
	isLoading: boolean
	onSelectorClick: (variant: OutdoorVariants) => void
	selectedVariants: OutdoorVariants[]
}
export const OutdoorVariantsSelectors = ({
	isLoading,
	onSelectorClick,
	selectedVariants,
}: Props) => {
	return (
		<div className={s.selectors}>
			<OutdoorVariantSelector
				onClick={onSelectorClick}
				isSelected={selectedVariants.includes(
					OutdoorVariants.MassCoverage
				)}
				title="Максимальный охват"
				description={
					'Размещение минимум на 3 экранах. Нельзя указать время показа.'
				}
				selectorKey={OutdoorVariants.MassCoverage}
				isLoading={isLoading}
			/>

			<OutdoorVariantSelector
				onClick={onSelectorClick}
				description={
					'Размещение на 1 и более экранах. Можно указать время показа.'
				}
				isSelected={selectedVariants.includes(
					OutdoorVariants.GarantViews
				)}
				title="Гарантированные показы"
				selectorKey={OutdoorVariants.GarantViews}
				isLoading={isLoading}
			/>
		</div>
	)
}
