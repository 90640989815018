import React from 'react'
import { AdIcon, SelectedIcon } from '../AdFormatSelector'
import { Alert } from 'react-bootstrap'
import s from '../public-transport/components/public-transport-selector/selector.module.scss'
import useCampaignData from '../../../../../stores/campaignData'
import { InsideErrors } from '../ADFormatSummary'
import selectorContainer from '../outdoor-ad/outdoorAd.module.scss'
import classnames from 'classnames'
import { ReactComponent as Ok } from 'src/assets/images/ok.svg'
import { ReactComponent as Yandex } from 'src/assets/images/yandex.svg'
import { ReactComponent as Vk } from 'src/assets/images/vk.svg'
import { useAdformatsStore } from '../adformats-store/adformats.store'

export const YandexIcon = () => (
	<svg
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<circle cx="12.5" cy="12" r="10.5" fill="#FC3F1D" />
		<path
			d="M16.25 18.75H13.8928V7.07599H12.8409C10.9125 7.07599 9.89954 8.04685 9.89954 9.48449C9.89954 11.1158 10.6008 11.8736 12.0421 12.845L13.2304 13.6415L9.80193 18.75H7.25L10.3279 14.1853C8.55534 12.9224 7.56177 11.6988 7.56177 9.62055C7.56177 7.0174 9.37361 5.25 12.8215 5.25H16.25V18.75Z"
			fill="white"
		/>
	</svg>
)
export const VkIcon = () => (
	<svg
		width="25"
		height="24"
		viewBox="0 0 25 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#clip0_8174_30294)">
			<path
				d="M0.5 11.52C0.5 6.0894 0.5 3.37413 2.18707 1.68707C3.87413 0 6.5894 0 12.02 0H12.98C18.4106 0 21.1259 0 22.813 1.68707C24.5 3.37413 24.5 6.0894 24.5 11.52V12.48C24.5 17.9106 24.5 20.6259 22.813 22.313C21.1259 24 18.4106 24 12.98 24H12.02C6.5894 24 3.87413 24 2.18707 22.313C0.5 20.6259 0.5 17.9106 0.5 12.48V11.52Z"
				fill="#007BFF"
			/>
			<path
				d="M13.2698 17.29C7.79976 17.29 4.6798 13.54 4.5498 7.30005H7.2898C7.3798 11.88 9.39976 13.82 10.9998 14.22V7.30005H13.5799V11.25C15.1599 11.08 16.8197 9.28005 17.3797 7.30005H19.9597C19.5297 9.74005 17.7297 11.54 16.4497 12.28C17.7297 12.88 19.7798 14.45 20.5598 17.29H17.7197C17.1097 15.39 15.5899 13.92 13.5799 13.72V17.29H13.2698Z"
				fill="white"
			/>
		</g>
		<defs>
			<clipPath id="clip0_8174_30294">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0.5)"
				/>
			</clipPath>
		</defs>
	</svg>
)

type InternetSelectorProps = {
	cn: any
	formats: any
	setFormats: any
	onError: (key: keyof InsideErrors, value: boolean) => void
	addToStack: any
	removeFromStack: any
	setIsLoading: any
}
const InternetSelector = ({
	cn,
	formats,
	setFormats,
	onError,
	addToStack,
	removeFromStack,
	setIsLoading,
}: InternetSelectorProps) => {
	const [campaign, campaignInterface] = useCampaignData()

	const toggleAdFormatSelectedState = useAdformatsStore(
		(state) => state.toggleAdFormatSelectedState
	)

	const setAdFormatErrors = useAdformatsStore(
		(state) => state.setAdFormatErrors
	)

	async function clickHandle(key) {
		const tmp = formats?.find((el) => el.key === key)
		tmp.isSelected = !tmp.isSelected
		setFormats((p) => p.map((el) => (el.key === key ? tmp : el)))
		tmp.isSelected
			? addToStack({ Component: tmp.Component, key: key }, 'internet')
			: removeFromStack(key)
		patchCampaignToNewValue(key, tmp.isSelected)
	}

	async function patchCampaignToNewValue(campaignName, value) {
		setIsLoading(true)
		const formData = new FormData()
		if (campaignName === 'yandex') {
			if (value) {
				formData.append('is_selected', 'true')
			} else {
				formData.append('is_selected', 'false')
			}
			const result: { is_selected: boolean } =
				await campaignInterface.patchInternetYandex(
					campaign?.id,
					formData
				)
			toggleAdFormatSelectedState('yandex', result?.is_selected)
			if (!result.is_selected) {
				setAdFormatErrors('yandex', null)
			}
			setIsLoading(false)
		}
		if (campaignName === 'vk') {
			if (value) {
				formData.append('is_selected', 'true')
			} else {
				formData.append('is_selected', 'false')
				onError('vk', false)
			}
			const result: { is_selected: boolean } =
				await campaignInterface.patchInternetVk(campaign?.id, formData)
			toggleAdFormatSelectedState('vk', result?.is_selected)
			if (!result.is_selected) {
				setAdFormatErrors('vk', null)
			}
			setIsLoading(false)
		}
	}

	return (
		<>
			<div
				className={'d-flex justify-content-between'}
				style={{ marginBottom: '8px' }}
			>
				<h4 className={'mb-0'}>Интернет-реклама</h4>
				<div>
					<AdIcon name={'internet'} />
				</div>
			</div>
			<div className={'mb-3'}>
				Выберите способы показа рекламы. Реклама будет транслироваться в
				Яндексе и VK.{' '}
			</div>

			<div className={selectorContainer.selectorsContainer}>
				{formats.map((format, index) => (
					<div
						id={'sdfsdf' + index}
						key={format.key}
						className={classnames({
							[s.format_selected]: format.isSelected,
							[s.format]: !format.isSelected,
							[s.format_disabled]: format?.disabled,
						})}
						onClick={() => {
							if (format.disabled) return
							clickHandle(format.key)
						}}
					>
						<div className={classnames(s.icon)}>
							{format?.isSelected ? (
								<Ok />
							) : format.key === 'yandex' ? (
								<Yandex />
							) : (
								<Vk />
							)}
						</div>

						<div className={s.title}>
							<div>{format.title}</div>

							<p>{format.description}</p>
						</div>
					</div>
				))}
			</div>
		</>
	)
}

export default InternetSelector
