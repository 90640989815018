import React from 'react'
import s from './cardWrapper.module.scss'
import { Card } from 'react-bootstrap'

type Props = {
	children: React.ReactNode
}
export const CardWrapper = ({ children }: Props) => {
	return <Card className={s.cardWrapper}>{children}</Card>
}
