import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { ReactComponent as Arrow } from '../../../../../assets/images/arrow.svg'
import cn from 'classnames'
import s from './toggleSidebarButton.module.scss'
import { RenderDesktop } from 'src/components/UtilityComponents'

type OpenSidebarButtonProps = {
	forceOpened: boolean
	toggleOpenSidebar: (open: boolean) => void
}

export const ToggleSidebarButton = ({
	forceOpened,
	toggleOpenSidebar,
}: OpenSidebarButtonProps) => {
	return (
		<>
			<RenderDesktop>
				<OverlayTrigger
					placement="right"
					overlay={(props) => (
						<Tooltip id="sidebar_show_btn-tooltip" {...props}>
							Раскрыть меню
						</Tooltip>
					)}
				>
					<div
						className={cn(s.clientSidebar_hidden, {
							[s.hidden]: forceOpened,
						})}
						onClick={() => toggleOpenSidebar(true)}
					>
						<Arrow />
					</div>
				</OverlayTrigger>
			</RenderDesktop>
			<RenderDesktop>
				<OverlayTrigger
					placement="right"
					overlay={(props) => (
						<Tooltip id="sidebar_hide_btn-tooltip" {...props}>
							Скрыть меню
						</Tooltip>
					)}
				>
					<div
						className={cn(s.clientSidebar_hide_btn)}
						onClick={() => toggleOpenSidebar(false)}
						id="sidebar_hide_btn"
					>
						<Arrow />
					</div>
				</OverlayTrigger>
			</RenderDesktop>
		</>
	)
}
