import produce from 'immer'
import makeStoreResettable from 'src/utils/makeStoreResettable'
import { createStoreon } from 'storeon'
import { storeonDevtools } from 'storeon/devtools'
import {
	CreateNotificationBody,
	CreateRelatedNotificationBody,
	DetailedCampaignInfo,
	Manager,
	ModerAdvCompanyItemType,
	ModerAdvCompanyListType,
	ModerationCountsType,
	ModerationNotificationsCountsType,
	NotificationDetails,
	NotificationModel,
	NotificationState,
	PatchAdvItemBodyType,
} from '../types/ModerationRequestTypes'
import createStoreHook from 'src/utils/createStoreHook'
import { moderationApi } from '../api/moderatorApi'
import { ModerToastType } from '../types/ModerToastType'
import { getError } from 'src/utils/getError'

type ModerationDataStateType = {
	counts: ModerationCountsType
	advList: Array<ModerAdvCompanyItemType>
	notificationCount: ModerationNotificationsCountsType
	notificationsList: Array<NotificationModel>
	detailedCampaign: DetailedCampaignInfo
	detailedNotification: (NotificationModel & NotificationDetails) | undefined
	managers: Manager[]
	isModerLoading: boolean
	moderToastMessage:
		| {
				text: string
				type: ModerToastType
		  }
		| undefined
}

export const moderationStore = createStoreon<
	ModerationDataStateType,
	{
		setAdvList: {
			advList: Array<ModerAdvCompanyItemType>
			counts: ModerationCountsType
		}
		setDetailedCampaign: {
			detailedCampaign: DetailedCampaignInfo
		}
		setIsModerLoading: {
			isLoading: boolean
		}
		setModerToastMessage: {
			text: string
			type: ModerToastType
		}
		setNotificationsList: {
			notificationsList: Array<NotificationModel>
			counts: ModerationNotificationsCountsType
		}
		setDetailedNotification: {
			detailedNotification:
				| (NotificationModel & NotificationDetails)
				| undefined
		}
		setManagers: {
			managers: Manager[]
		}
	}
>([
	(store) => {
		makeStoreResettable(store, {
			counts: {} as ModerationCountsType,
			advList: [] as Array<ModerAdvCompanyItemType>,
			detailedCampaign: {} as DetailedCampaignInfo,
			detailedNotification: undefined,
			notificationCount: {} as ModerationNotificationsCountsType,
			notificationsList: [] as Array<NotificationModel>,
			managers: [] as Manager[],
		})
		store.on(
			'setAdvList',
			produce(
				(
					state: ModerationDataStateType,
					payload: {
						advList: Array<ModerAdvCompanyItemType>
						counts: ModerationCountsType
					}
				) => {
					state.advList = payload.advList
					state.counts = payload.counts
				}
			)
		),
			store.on(
				'setDetailedCampaign',
				produce(
					(
						state: ModerationDataStateType,
						payload: {
							detailedCampaign: DetailedCampaignInfo
						}
					) => {
						state.detailedCampaign = payload.detailedCampaign
					}
				)
			),
			store.on(
				'setIsModerLoading',
				produce(
					(
						state: ModerationDataStateType,
						payload: {
							isLoading: boolean
						}
					) => {
						state.isModerLoading = payload.isLoading
					}
				)
			)
		store.on(
			'setModerToastMessage',
			produce(
				(
					state: ModerationDataStateType,
					payload:
						| {
								text: string
								type: ModerToastType
						  }
						| undefined
				) => {
					state.moderToastMessage = payload
				}
			)
		),
			store.on(
				'setNotificationsList',
				produce(
					(
						state: ModerationDataStateType,
						payload: {
							notificationsList: Array<NotificationModel>
							counts: ModerationNotificationsCountsType
						}
					) => {
						state.notificationsList = payload.notificationsList
						state.notificationCount = payload.counts
					}
				)
			),
			store.on(
				'setDetailedNotification',
				produce(
					(
						state: ModerationDataStateType,
						payload: {
							detailedNotification:
								| (NotificationModel & NotificationDetails)
								| undefined
						}
					) => {
						state.detailedNotification =
							payload.detailedNotification
					}
				)
			),
			store.on(
				'setManagers',
				produce(
					(
						state: ModerationDataStateType,
						payload: { managers: Manager[] }
					) => {
						state.managers = payload.managers
					}
				)
			)
	},
	process.env.NODE_ENV !== 'production' &&
		storeonDevtools({ name: 'moderationStore' }),
])
export const moderationStoreInterface = {
	name: 'ModerationData',

	setIsModerLoading(isLoading: boolean) {
		moderationStore.dispatch('setIsModerLoading', {
			isLoading: isLoading,
		})
	},
	setModerToastMessage(
		toast: { text: string; type: ModerToastType } | undefined
	) {
		moderationStore.dispatch('setModerToastMessage', toast)
	},
	clearDetailedNotification() {
		moderationStore.dispatch('setDetailedNotification', {
			detailedNotification: undefined,
		})
	},
	setNotification() {
		moderationStore.get().notificationsList
	},
	async updateAdvCampaign(
		campaignId: string,
		body: {
			state_advertising: 'moderation' | 'adjustment' | 'reject'
			comment?: string
		}
	) {
		try {
			this.setIsModerLoading(true)
			await moderationApi.patchAdvCampaing(campaignId, body)
			const result = await moderationApi.getDetailedCampaingInformation(
				campaignId.toString()
			)
			moderationStore.dispatch('setDetailedCampaign', {
				detailedCampaign: result,
			})
			moderationStore.dispatch('setModerToastMessage', {
				text: 'Кампания успешно обновлена',
				type: 'success',
			})
		} catch (e) {
			moderationStore.dispatch('setModerToastMessage', {
				text: 'При обновлении кампании произошла ошибка',
				type: 'danger',
			})
		} finally {
			this.setIsModerLoading(false)
		}
	},
	async updateAdvItem(
		campaignId: string | number,
		type:
			| 'yandex'
			| 'vk'
			| 'adstream'
			| 'indoor'
			| 'outdoor'
			| 'districtbooking'
			| 'publicTransport',
		body: Partial<PatchAdvItemBodyType>,
		bookingId?: string
	) {
		try {
			this.setIsModerLoading(true)
			await moderationApi.patchAdvItem(campaignId, type, body, bookingId)
			const result = await moderationApi.getDetailedCampaingInformation(
				campaignId.toString()
			)
			moderationStore.dispatch('setDetailedCampaign', {
				detailedCampaign: result,
			})
			moderationStore.dispatch('setModerToastMessage', {
				text: 'Рекламное место успешно обновлено',
				type: 'success',
			})
		} catch (e: any) {
			const result = getError(e)

			if (result?.data) {
				const messages = Object.keys(result?.data).map((key) => {
					return result?.data[key] as string
				})

				if (messages?.length > 0) {
					moderationStore.dispatch('setModerToastMessage', {
						text: messages.join(', '),
						type: 'danger',
					})
				}
			}
		} finally {
			this.setIsModerLoading(false)
		}
	},

	async fetchAdvList(
		queryString: string
	): Promise<ModerAdvCompanyItemType[] | void> {
		try {
			this.setIsModerLoading(true)
			const result: ModerAdvCompanyListType =
				await moderationApi.getAdvCompanyList(queryString)

			const counts: ModerationCountsType = {
				count: result.count,
				count_close_waiting: result.count_close_waiting,
				count_active: result.count_active,
				count_adjustment: result.count_adjustment,
				count_canceled: result.count_canceled,
				count_completed: result.count_completed,
				count_confirmed: result.count_confirmed,
				count_draft: result.count_draft,
				count_moderation: result.count_moderation,
				count_payment_waiting: result.count_payment_waiting,
				count_total: result.count_total,
				count_start_waiting: result.count_start_waiting,
				count_paid: result?.count_paid,
			}

			moderationStore.dispatch('setAdvList', {
				advList: result.results,
				counts: counts,
			})
			return result.results
		} catch (e) {
		} finally {
			this.setIsModerLoading(false)
		}
	},
	async getDetailedCampaignInfo(id: string) {
		try {
			this.setIsModerLoading(true)

			const result = await moderationApi.getDetailedCampaingInformation(
				id
			)

			moderationStore.dispatch('setDetailedCampaign', {
				detailedCampaign: result,
			})
			return result
		} catch (e) {
		} finally {
			this.setIsModerLoading(false)
		}
	},

	async sendManagerConfirm(campaignId: string) {
		try {
			this.setIsModerLoading(true)
			await moderationApi.managerConfirm(campaignId)
			const result = await moderationApi.getDetailedCampaingInformation(
				campaignId
			)
			moderationStore.dispatch('setDetailedCampaign', {
				detailedCampaign: result,
			})
			moderationStore.dispatch('setModerToastMessage', {
				text: 'Закрывающие документы рекламной кампании сформированы',
				type: 'success',
			})
		} catch (e: any) {
			const result = getError(e)
			if (result?.data?.[0])
				moderationStore.dispatch('setModerToastMessage', {
					text: result?.data?.[0] as string,
					type: 'danger',
				})
		} finally {
			this.setIsModerLoading(false)
		}
	},
	async getDisplayIssues(queryString: string) {
		try {
			this.setIsModerLoading(true)
			return await moderationApi.fetchDisplayIssues(queryString)
		} catch (e: any) {
			const result = getError(e)
			if (result?.data?.[0])
				moderationStore.dispatch('setModerToastMessage', {
					text: result?.data?.[0] as string,
					type: 'danger',
				})
		} finally {
			this.setIsModerLoading(false)
		}
	},
	async getModerNotifications(queryString: string) {
		try {
			this.setIsModerLoading(true)
			const result = await moderationApi.fetchManagerNotifications(
				queryString
			)

			const counts: ModerationNotificationsCountsType = {
				count: result.count,
				count_created: result.count_created,
				count_draft: result.count_draft,
				count_total: result.count_total,
			}

			moderationStore.dispatch('setNotificationsList', {
				counts: counts,
				notificationsList: result.results,
			})
		} catch (e: any) {
			const result = getError(e)
			if (result?.data?.[0])
				moderationStore.dispatch('setModerToastMessage', {
					text: result?.data?.[0] as string,
					type: 'danger',
				})
		} finally {
			this.setIsModerLoading(false)
		}
	},
	async getDetailedNotificationInformation(notificationId: number) {
		try {
			this.setIsModerLoading(true)
			const result =
				await moderationApi.fetchDetailedNotificationInformation(
					notificationId
				)

			moderationStore.dispatch('setDetailedNotification', {
				detailedNotification: result,
			})
		} catch (e: any) {
			const result = getError(e)
			if (result?.data?.[0])
				moderationStore.dispatch('setModerToastMessage', {
					text: result?.data?.[0] as string,
					type: 'danger',
				})
		} finally {
			this.setIsModerLoading(false)
		}
	},
	async createDraftModerNotification(selectedTab: NotificationState) {
		try {
			this.setIsModerLoading(true)
			const result = await moderationApi.createDraftNotification()

			const notification =
				await moderationApi.fetchDetailedNotificationInformation(
					result.id
				)

			moderationStore.dispatch('setDetailedNotification', {
				detailedNotification: notification,
			})
			let notifications = moderationStore.get().notificationsList
			if (selectedTab === 'draft') {
				notifications = [result, ...notifications]
			}

			const notifyCounts = moderationStore.get().notificationCount

			moderationStore.dispatch('setNotificationsList', {
				notificationsList: notifications,
				counts: {
					...notifyCounts,
					count_draft: notifyCounts.count_draft + 1,
					count_total: notifyCounts.count_total + 1,
				},
			})
			return result
		} catch (e: any) {
			const result = getError(e)
			if (result?.data?.[0])
				moderationStore.dispatch('setModerToastMessage', {
					text: result?.data?.[0] as string,
					type: 'danger',
				})
		} finally {
			this.setIsModerLoading(false)
		}
	},
	async createModerNotification(
		notificationId: number,
		body: CreateNotificationBody & Partial<CreateRelatedNotificationBody>,
		selectedTab?: NotificationState
	) {
		try {
			this.setIsModerLoading(true)
			const result = await moderationApi.createNotification(
				notificationId,
				body
			)
			const counts = moderationStore.get().notificationCount
			let notifications = moderationStore.get().notificationsList

			if (selectedTab) {
				if (selectedTab === 'draft') {
					notifications = notifications.filter(
						(el) => el.id !== result.id
					)
				} else {
					notifications = [result, ...notifications]
				}
			}
			moderationStore.dispatch('setNotificationsList', {
				notificationsList: notifications,
				counts: {
					...counts,
					count_created: counts.count_created + 1,
					count_draft: counts.count_draft - 1,
				},
			})
			moderationStore.dispatch('setModerToastMessage', {
				text: 'Уведомления были отправлены',
				type: 'success',
			})
		} catch (e: any) {
			const result = getError(e)
			if (result?.data?.[0])
				moderationStore.dispatch('setModerToastMessage', {
					text: result?.data?.[0] as string,
					type: 'danger',
				})
		} finally {
			this.setIsModerLoading(false)
		}
	},
	async updateDraftModerNotification(data: {
		notificationId: number
		body: Partial<CreateNotificationBody>
	}) {
		try {
			this.setIsModerLoading(true)
			const result = await moderationApi.updateDraftNotification(
				data.notificationId,
				data.body
			)

			const notifications = moderationStore.get().notificationsList
			const counts = moderationStore.get().notificationCount

			moderationStore.dispatch('setNotificationsList', {
				counts: counts,
				notificationsList: notifications.map((el) =>
					el.id === result.id ? result : el
				),
			})
			this.clearDetailedNotification()

			moderationStore.dispatch('setModerToastMessage', {
				text: 'Черновик обновлен',
				type: 'success',
			})
		} catch (e: any) {
			const result = getError(e)
			if (result?.data?.[0])
				moderationStore.dispatch('setModerToastMessage', {
					text: result?.data?.[0] as string,
					type: 'danger',
				})
		} finally {
			this.setIsModerLoading(false)
		}
	},
	async updateModerNotification(data: {
		notificationId: number
		body: CreateNotificationBody
		prevState: NotificationState
	}) {
		try {
			this.setIsModerLoading(true)
			const result = await moderationApi.updateNotification(
				data.notificationId,
				data.body
			)

			const notifications = moderationStore.get().notificationsList
			const counts = moderationStore.get().notificationCount

			let updatedNotifications = [] as NotificationModel[]
			let updatedCounts = {} as ModerationNotificationsCountsType

			//update from draft to created
			if (data.prevState === 'draft') {
				updatedNotifications = notifications.filter(
					(el) => el.id !== result.id
				)

				updatedCounts = {
					...counts,
					count_created: counts.count_created + 1,
					count_draft: counts.count_draft - 1,
				}
			} else {
				//update fields in created item
				updatedNotifications = notifications.map((el) =>
					el.id === result.id ? result : el
				)
				updatedCounts = counts
			}

			moderationStore.dispatch('setNotificationsList', {
				notificationsList: updatedNotifications,
				counts: updatedCounts,
			})
			this.clearDetailedNotification()

			moderationStore.dispatch('setModerToastMessage', {
				text: 'Уведомления были обновлены',
				type: 'success',
			})
		} catch (e: any) {
			const result = getError(e)
			if (result?.data?.[0])
				moderationStore.dispatch('setModerToastMessage', {
					text: result?.data?.[0] as string,
					type: 'danger',
				})
		} finally {
			this.setIsModerLoading(false)
		}
	},

	async deleteModerNotification(notificationId: number) {
		try {
			this.setIsModerLoading(true)
			const result = await moderationApi.deleteNotification(
				notificationId
			)

			const counts = moderationStore.get().notificationCount

			const notifications = moderationStore
				.get()
				.notificationsList.filter((el) => el.id !== notificationId)

			moderationStore.dispatch('setNotificationsList', {
				notificationsList: notifications,
				counts: {
					count: counts.count - 1,
					count_total: counts.count_total - 1,
					count_created:
						result.state === 'created'
							? counts.count_created - 1
							: counts.count_created,
					count_draft:
						result.state === 'draft'
							? counts.count_draft - 1
							: counts.count_draft,
				},
			})
			moderationStore.dispatch('setModerToastMessage', {
				text: 'Уведомление удалено',
				type: 'success',
			})
		} catch (e: any) {
			const result = getError(e)
			if (result?.data?.[0])
				moderationStore.dispatch('setModerToastMessage', {
					text: 'Ошибка при удалении уведомления' as string,
					type: 'danger',
				})
		} finally {
			this.setIsModerLoading(false)
		}
	},
	async deleteModerBulkNotifications(
		notify: number[],
		selectedTab: NotificationState
	) {
		try {
			this.setIsModerLoading(true)
			const result = await moderationApi.deleteBulkNotifications({
				notify,
			})

			const counts = moderationStore.get().notificationCount

			const notifications = moderationStore
				.get()
				.notificationsList.filter((el) => !result.includes(el.id))

			moderationStore.dispatch('setNotificationsList', {
				notificationsList: notifications,
				counts: {
					count: counts.count - result.length,
					count_total: counts.count_total - result.length,
					count_created:
						selectedTab === 'created'
							? counts.count_created - result.length
							: counts.count_created,
					count_draft:
						selectedTab === 'draft'
							? counts.count_draft - result.length
							: counts.count_draft,
				},
			})
			moderationStore.dispatch('setModerToastMessage', {
				text: 'Уведомления удалены',
				type: 'success',
			})
		} catch (e: any) {
			const result = getError(e)
			if (result?.data?.[0])
				moderationStore.dispatch('setModerToastMessage', {
					text: 'Ошибка при удалении уведомления' as string,
					type: 'danger',
				})
		} finally {
			this.setIsModerLoading(false)
		}
	},
	async getManagers() {
		this.setIsModerLoading(true)

		try {
			const result = await moderationApi.fetchManagers()
			moderationStore.dispatch('setManagers', { managers: result })
		} catch (e: any) {
		} finally {
			this.setIsModerLoading(false)
		}
	},

	get() {
		return moderationStore.get()
	},
	subscribe(callback) {
		return moderationStore.on('@changed', callback)
	},
	getAdvList() {
		return moderationStore.get().advList
	},
}

const useModerationData: () => [
	ModerationDataStateType,
	typeof moderationStoreInterface
] = createStoreHook(moderationStoreInterface)

export default useModerationData
