import { CampaignInfoTypeBookingsSeparation } from 'src/stores/ADMarketTypes.types'

export const checkIsOutdoorMediaUploaded = (
	campaign: CampaignInfoTypeBookingsSeparation
): boolean => {
	if (
		campaign.district_adv_is_selected &&
		!!campaign.districtbookings.length
	) {
		const allDistrictBookingsWithCreativeIds = campaign.districtbookings
			.filter((el) => el.creatives.length !== 0)
			.map((el) => el.id)

		const allDistrictBookingsMediaIds = [
			...allDistrictBookingsWithCreativeIds,
			...(campaign.bid_requests?.districtbooking?.map((el) => el.id) ||
				[]),
		]

		const isEveryDistricBookingHasCreative =
			campaign.districtbookings.every((el) =>
				allDistrictBookingsMediaIds.includes(el.id)
			)

		//break function if receive falsy
		if (!isEveryDistricBookingHasCreative) {
			return false
		}
	}

	if (
		campaign.outdoor_adv_is_selected &&
		!!campaign.outdoor_bookings.length
	) {
		const allOutdoorsMediaIds = [
			...campaign.media.map((el) => el.booking_id),
			...(campaign.bid_requests?.booking?.map((el) => el.id) || []),
		]

		const isEveryBookingHasCreative = campaign.outdoor_bookings.every(
			(el) => allOutdoorsMediaIds.includes(el.id)
		)

		//break function if receive falsy
		if (!isEveryBookingHasCreative) return false
	}

	return true
}
export const checkIsIndoorMediaUploaded = (
	campaign: CampaignInfoTypeBookingsSeparation
): boolean => {
	if (!campaign.indoor_adv_is_selected) {
		return true
	}

	const allIndoorsMediaIds = [
		...campaign.media.map((el) => el.booking_id),
		...(campaign.bid_requests?.booking?.map((el) => el.id) || []),
	]

	const isEveryIndoorBookingHasCreative = campaign.indoor_bookings.every(
		(el) => allIndoorsMediaIds.includes(el.id)
	)

	return !isEveryIndoorBookingHasCreative
}

export const checkIsInternetMediaUploaded = (
	campaign: CampaignInfoTypeBookingsSeparation
): boolean => {
	if (
		!campaign.internet_vk.is_selected &&
		!campaign.internet_yandex.is_selected
	) {
		return true
	}

	let isVkValid = true
	let isYandexValid = true

	//vk check
	if (campaign.internet_vk.is_selected) {
		const vkBids =
			campaign.bid_requests?.internet_vk?.map((el) => el.id) || []

		const isBid = vkBids.includes(campaign.internet_vk.id)
		if (isBid) isVkValid = true
		else {
			const vkHead = campaign.internet_vk.heading
			const vkText = campaign.internet_vk.text
			const vkButtonText = campaign.internet_vk.button_text
			const vkImage = campaign.internet_vk.image
			if (!vkHead || !vkText || !vkButtonText || !vkImage) {
				isVkValid = false
			} else {
				const validHead = vkHead.length > 0 && vkHead.length < 81
				const validText = vkText.length > 0 && vkText.length < 81

				isVkValid = validHead && validText
			}
		}
	}

	//yandex check
	if (campaign.internet_yandex.is_selected) {
		const yandexBids =
			campaign.bid_requests?.internet_yandex?.map((el) => el.id) || []
		const isBid = yandexBids.includes(campaign.internet_yandex.id)
		if (isBid) isYandexValid = true
		else {
			const yandexHead = campaign.internet_yandex.heading
			const yandexText = campaign.internet_yandex.text
			const yandexImage = !!campaign.internet_yandex.image
			if (!yandexHead || !yandexText || !yandexImage) {
				isYandexValid = false
			} else {
				const validHead =
					yandexHead.length > 0 && yandexHead.length < 81
				const validText =
					yandexText.length > 0 && yandexText.length < 81

				isYandexValid = validHead && validText
			}
		}
	}

	return isVkValid && isYandexValid
}

export const checkIsTvMediaUploaded = (
	campaign: CampaignInfoTypeBookingsSeparation
): boolean => {
	if (!campaign.teletarget.is_selected && !campaign.adstream.is_selected) {
		return true
	}

	let isAdstreamValid = true
	let isTeletargetValid = true

	//adstream check
	if (campaign.adstream.is_selected) {
		const adstreamBids =
			campaign.bid_requests?.adstream?.map((el) => el.id) || []

		const isBid = adstreamBids.includes(campaign.adstream.id)
		if (isBid) isAdstreamValid = true
		else {
			isAdstreamValid = !!campaign.adstream.image
		}
	}

	//teletarget check
	//teletarget isn't enabled yet (31/01/2024)

	return isAdstreamValid && isTeletargetValid
}
export const checkIsPublicTransportMediaUploaded = (
	campaign: CampaignInfoTypeBookingsSeparation
) => {
	const media =
		campaign.public_transport?.image || campaign.public_transport?.video

	return !!media
}

export const checkIsAllAdvFormatsHasValidMedia = (
	campaign: CampaignInfoTypeBookingsSeparation
): boolean => {
	return (
		checkIsIndoorMediaUploaded(campaign) &&
		checkIsOutdoorMediaUploaded(campaign) &&
		checkIsInternetMediaUploaded(campaign) &&
		checkIsTvMediaUploaded(campaign) &&
		checkIsPublicTransportMediaUploaded(campaign)
	)
}
