import React from 'react'
import { Button } from 'react-bootstrap'
import { PlacementType } from 'src/stores/ADMarketTypes.types'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { PlacementWithOnlyOneShowingCount } from '../../BookingAD'
import moment from 'moment'
import s from './placementDetail.module.scss'

type Props = {
	details: PlacementType
	selectedPrice: number | undefined
	booked: boolean
	onRemovePlacement: (id: number) => void
	onSelectPlacement: (
		id: number,
		placement_details: PlacementWithOnlyOneShowingCount
	) => void
}

export const PlacementDetatilsFooter = ({
	details,
	selectedPrice,
	booked,
	onRemovePlacement,
	onSelectPlacement,
}: Props) => {
	return (
		<div className={s.placementDetatilsFooter}>
			<PlacementCosts details={details} selectedPrice={selectedPrice} />
			<Button
				variant={booked ? 'danger' : 'primary '}
				disabled={!selectedPrice || !details.is_active}
				onClick={() => {
					if (booked) onRemovePlacement(details.id)
					else {
						const copy = { ...details }
						// @ts-ignore
						delete copy.placement_showing_counts
						onSelectPlacement(details.id, {
							...copy,
							placement_showing_count:
								details.placement_showing_counts.find(
									(el) => el.id === selectedPrice
								) || details.placement_showing_counts[0],
						})
					}
				}}
			>
				{details.is_active &&
					(booked
						? 'Удалить из рекламной кампании'
						: 'Добавить в рекламную кампанию')}
				{!details.is_active &&
					(details?.activation_date
						? `Неактивно до ${moment(
								details.activation_date,
								'YYYY-MM-DD'
						  ).format('DD.MM.YYYY')}`
						: 'Неактивно')}
			</Button>
		</div>
	)
}

type PlacementCostsProps = {
	details: PlacementType
	selectedPrice: number | undefined
}
export const PlacementCosts = ({
	details,
	selectedPrice,
}: PlacementCostsProps) => {
	const selectedShowingCountType = details.placement_showing_counts.find(
		({ id }) => id === selectedPrice
	)

	return (
		<div className={s.placementCosts}>
			{selectedShowingCountType && (
				<div className={s.selectedShowingCountType}>
					<p>Стоимость за 1 день:</p>{' '}
					<p>
						{IntlFormatter.format(selectedShowingCountType.price)}
					</p>
				</div>
			)}

			{details.is_active && (
				<div className={s.totalCount}>
					<h5>
						{details.door_type === 'outdoor'
							? 'Ориентировочная стоимость:'
							: 'Cтоимость:'}
					</h5>
					<h5>
						{IntlFormatter.format(
							details.placement_showing_counts.find(
								({ id }) => id === selectedPrice
							)?.ac_price || 0,
							true
						)}
					</h5>
				</div>
			)}
		</div>
	)
}
