import React from 'react'
import s from './mcrRequirements.module.scss'
import {
	MCRAnimationArticle,
	MCRCommonArticle,
	MCRStaticContentArticle,
	MCRRussOutdoorArticle,
	MCRExampleArticle,
} from './requirement-articles'
type Props = {
	isHeader: boolean
}

export const MoscowCentRingRequirements = ({ isHeader }: Props) => {
	return (
		<div>
			{isHeader && (
				<div className={s.header}>
					Требования к цифровому экрану МЦК
				</div>
			)}

			<div className={s.contentWrapper}>
				<div className={s.content}>
					<MCRCommonArticle />
					<MCRStaticContentArticle />
					<MCRAnimationArticle />
					<MCRRussOutdoorArticle />
					<MCRExampleArticle />
				</div>
			</div>
		</div>
	)
}
