import React, { useEffect, useMemo, useState } from 'react'
import { MediaType } from './MediaInfo'
import '../../../styles/modalMediaformat.scss'
import bem from '../../../utils/bem'
import { Formik, useField } from 'formik'
import {
	Button,
	ButtonGroup,
	Col,
	Form,
	InputGroup,
	Modal,
	Row,
} from 'react-bootstrap'
import ButtonGroupBooleanSwitch from '../../UtilityComponents/ButtonGroupBooleanSwitch'

import { ConnectedFocusError } from 'focus-formik-error'
import useProviderData from '../../../stores/providerData'
import useResponsive from 'src/utils/useResponsive'
import MobileModal from 'src/components/UtilityComponents/MobileModal'
import { ButtonGroupSwitch } from './ModalMediaFormat'

const cn = bem('modal-media')
export const MEDIA_FORMATS = {
	image: ['PNG', 'JPEG', 'PDF'],
	video: ['MPEG4', 'MP4', 'MOV', 'AVI'],
	colorModel: ['RGB', 'HSL'],
	codec: ['Xvid', 'H264'],
}

type MediaFormatsFormProps = {
	Init: any
	handleValidate: any
	handleSubmit: any
	handleClose: any
	media: any
	submitDisabled: any
}
export const MediaFormatsForm = ({
	Init,
	handleValidate,
	handleSubmit,
	handleClose,
	media,
	submitDisabled,
}: MediaFormatsFormProps) => {
	const { isMobile } = useResponsive()
	return (
		<Formik
			initialValues={Init}
			validate={handleValidate}
			onSubmit={handleSubmit}
			enableReinitialize={true}
			validateOnBlur={true}
			validateOnMount={false}
		>
			{({ setFieldValue, values, handleSubmit, errors }: any) => (
				<Form>
					<ConnectedFocusError />
					<Modal.Body>
						<Col>
							<Form.Label>Название</Form.Label>
							<InputGroup hasValidation>
								<Form.Control
									type={'text'}
									value={values.name}
									onChange={(e) =>
										setFieldValue('name', e.target.value)
									}
									isInvalid={!!errors.name}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.name}
								</Form.Control.Feedback>
							</InputGroup>
							<Form.Label>Тип</Form.Label>
							<InputGroup
								hasValidation
								style={{ flexDirection: 'column', gap: '8px' }}
							>
								<CustomCheckBox
									label={'Изображение'}
									name={'is_image'}
									checked={values.is_image}
								/>
								<CustomCheckBox
									label={'Видео'}
									name={'is_video'}
									checked={values.is_video}
								/>
								{errors.notype && (
									<div
										className={'invalid-feedback'}
										style={{ display: 'block' }}
									>
										{errors.notype}
									</div>
								)}
							</InputGroup>
							<Col>
								<Row className={'w-100 mb-2'}>
									<Form.Label
										style={{
											marginLeft: '12px',
										}}
									>
										Формат
									</Form.Label>

									<div
										style={{
											display: 'flex',
											alignItems: 'flex-start',
											gap: '24px',
										}}
									>
										<Col
											style={{
												display: 'flex',
												justifyContent: 'flex-start',
												alignItems: 'center',
												flexWrap: 'wrap',
												gap: '8px',
											}}
										>
											{MEDIA_FORMATS.image.map((e, i) => (
												<CustomCheckBox
													label={e}
													name={e}
													checked={values[e]}
													disabled={!values.is_image}
													key={e}
												/>
											))}
										</Col>
										<Col
											style={{
												display: 'flex',
												justifyContent: 'flex-start',
												alignItems: 'center',
												flexWrap: 'wrap',
												gap: '8px',
											}}
										>
											{MEDIA_FORMATS.video.map((e, i) => (
												<CustomCheckBox
													key={i}
													label={e}
													name={e}
													checked={values[e]}
													disabled={!values.is_video}
												/>
											))}
										</Col>
									</div>
								</Row>
							</Col>
							{(errors.video_formats || errors.image_formats) && (
								<Row
									className={'w-100 mb-2'}
									style={{ marginTop: 0 }}
								>
									<Col>
										{values.is_image &&
											errors.image_formats && (
												<div
													className={
														'invalid-feedback'
													}
													style={{
														display: 'block',
													}}
												>
													{errors.image_formats}
												</div>
											)}
									</Col>
									<Col>
										{values.is_video &&
											errors.video_formats && (
												<div
													className={
														'invalid-feedback'
													}
													style={{
														display: 'block',
													}}
												>
													{errors.video_formats}
												</div>
											)}
									</Col>
								</Row>
							)}
							<Row className={'w-100 mb-2'}>
								<Col>
									<Form.Label>Мин. ширина (px)</Form.Label>
									<InputGroup
										hasValidation
										style={{ marginBottom: 0 }}
									>
										<Form.Control
											type={'number'}
											value={values.min_width}
											onChange={(e) =>
												setFieldValue(
													'min_width',
													e.target.value
												)
											}
											isInvalid={!!errors.min_width}
											placeholder={'0 px'}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.min_width}
										</Form.Control.Feedback>
									</InputGroup>
								</Col>
								<Col>
									<Form.Label>Макс. ширина (px)</Form.Label>
									<InputGroup
										hasValidation
										style={{ marginBottom: 0 }}
									>
										<Form.Control
											type={'number'}
											value={values.max_width}
											onChange={(e) =>
												setFieldValue(
													'max_width',
													e.target.value
												)
											}
											isInvalid={!!errors.max_width}
											placeholder={'0 px'}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.max_width}
										</Form.Control.Feedback>
									</InputGroup>
								</Col>
							</Row>
							<Row className={'w-100 mb-2'}>
								<Col>
									<Form.Label>Мин. высота (px)</Form.Label>
									<InputGroup
										hasValidation
										style={{ marginBottom: 0 }}
									>
										<Form.Control
											type={'number'}
											value={values.min_height}
											onChange={(e) =>
												setFieldValue(
													'min_height',
													e.target.value
												)
											}
											isInvalid={!!errors.min_height}
											placeholder={'0 px'}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.min_height}
										</Form.Control.Feedback>
									</InputGroup>
								</Col>
								<Col>
									<Form.Label>Макс. высота (px)</Form.Label>
									<InputGroup
										hasValidation
										style={{ marginBottom: 0 }}
									>
										<Form.Control
											type={'number'}
											value={values.max_height}
											onChange={(e) =>
												setFieldValue(
													'max_height',
													e.target.value
												)
											}
											isInvalid={!!errors.max_height}
											placeholder={'0 px'}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.max_height}
										</Form.Control.Feedback>
									</InputGroup>
								</Col>
							</Row>

							<Row className={'w-100 mb-2'}>
								<Col>
									<Form.Label>Мин. DPI</Form.Label>
									<InputGroup
										hasValidation
										style={{ marginBottom: 0 }}
									>
										<Form.Control
											type={'number'}
											value={values.min_dpi}
											onChange={(e) =>
												setFieldValue(
													'min_dpi',
													e.target.value
												)
											}
											isInvalid={!!errors.min_dpi}
											disabled={!values.is_image}
											placeholder={'0 dpi'}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.min_dpi}
										</Form.Control.Feedback>
									</InputGroup>
								</Col>
								<Col>
									<Form.Label>Макс. DPI</Form.Label>
									<InputGroup
										hasValidation
										style={{ marginBottom: 0 }}
									>
										<Form.Control
											type={'number'}
											value={values.max_dpi}
											onChange={(e) =>
												setFieldValue(
													'max_dpi',
													e.target.value
												)
											}
											isInvalid={!!errors.max_dpi}
											disabled={!values.is_image}
											placeholder={'0 dpi'}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.max_dpi}
										</Form.Control.Feedback>
									</InputGroup>
								</Col>
							</Row>

							<Row className={'w-100 mb-2'}>
								<Col>
									<Form.Label>
										Макс. размер изображения
									</Form.Label>
									<InputGroup
										hasValidation
										style={{ marginBottom: 0 }}
									>
										<Form.Control
											type={'number'}
											value={values.image_max_size}
											onChange={(e) =>
												setFieldValue(
													'image_max_size',
													e.target.value
												)
											}
											isInvalid={!!errors.image_max_size}
											placeholder="0 Мб"
											disabled={!values.is_image}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.image_max_size}
										</Form.Control.Feedback>
									</InputGroup>
								</Col>
								<Col>
									<Form.Label>Соотношение сторон</Form.Label>
									<InputGroup
										hasValidation
										style={{ marginBottom: 0 }}
									>
										<Form.Control
											type={'text'}
											value={values.aspect_ratio}
											onChange={(e) =>
												setFieldValue(
													'aspect_ratio',
													e.target.value
												)
											}
											isInvalid={!!errors.aspect_ratio}
											placeholder={'16:9'}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.aspect_ratio}
										</Form.Control.Feedback>
									</InputGroup>
								</Col>
							</Row>
							<Row className={'w-100 mb-2'}>
								<Col>
									<Form.Label>
										Длительность показа (c)
									</Form.Label>
									<InputGroup
										hasValidation
										style={{ marginBottom: 0 }}
									>
										<Form.Control
											type={'number'}
											value={values.duration}
											onChange={(e) =>
												setFieldValue(
													'duration',
													e.target.value
												)
											}
											isInvalid={!!errors.duration}
											placeholder={'0 сек'}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.duration}
										</Form.Control.Feedback>
									</InputGroup>
								</Col>
								<Col></Col>
							</Row>

							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'flex-start',
									flexDirection: isMobile ? 'row' : 'column',
									padding: '10px 0',
								}}
							>
								<Row className={'w-100'}>
									<Col
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'flex-start',
											flexDirection: 'column',
										}}
									>
										<Form.Label>Цветовая модель</Form.Label>
										<ButtonGroupSwitch
											elements={MEDIA_FORMATS.colorModel}
											initialValue={values.color_model}
											setFieldValue={setFieldValue}
											name={'color_model'}
											disabled={!values.is_image}
										/>
									</Col>
								</Row>

								<Row className={'w-100 mb-2'}>
									<Col
										style={{
											display: 'flex',
											justifyContent: 'space-between',
											alignItems: 'flex-start',
											flexDirection: 'column',
										}}
									>
										<Form.Label>Кодек</Form.Label>
										<ButtonGroupSwitch
											elements={MEDIA_FORMATS.codec}
											initialValue={values.codec}
											setFieldValue={setFieldValue}
											name={'codec'}
											disabled={!values.is_video}
										/>
									</Col>
								</Row>
							</div>

							<Row className={'w-100 mb-2'}>
								<Col>
									<Form.Label>
										Мин. кадровая частота (кадр/c)
									</Form.Label>
									<InputGroup
										hasValidation
										style={{ marginBottom: 0 }}
									>
										<Form.Control
											type={'number'}
											value={values.min_fps}
											onChange={(e) =>
												setFieldValue(
													'min_fps',
													e.target.value
												)
											}
											isInvalid={!!errors.min_fps}
											disabled={!values.is_video}
											placeholder={'0 fps'}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.min_fps}
										</Form.Control.Feedback>
									</InputGroup>
								</Col>
								<Col>
									<Form.Label>
										Макс. кадровая частота (кадр/c)
									</Form.Label>
									<InputGroup
										hasValidation
										style={{ marginBottom: 0 }}
									>
										<Form.Control
											type={'number'}
											value={values.max_fps}
											onChange={(e) =>
												setFieldValue(
													'max_fps',
													e.target.value
												)
											}
											isInvalid={!!errors.max_fps}
											disabled={!values.is_video}
											placeholder={'0 fps'}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.max_fps}
										</Form.Control.Feedback>
									</InputGroup>
								</Col>
							</Row>
							<Row className={'w-100 mb-2'}>
								<Col>
									<Form.Label>
										Мин. битрейт (бит/c)
									</Form.Label>
									<InputGroup
										hasValidation
										style={{ marginBottom: 0 }}
									>
										<Form.Control
											type={'number'}
											value={values.min_bitrate}
											onChange={(e) =>
												setFieldValue(
													'min_bitrate',
													e.target.value
												)
											}
											isInvalid={!!errors.min_bitrate}
											disabled={!values.is_video}
											placeholder={'0 Мбит/с'}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.min_bitrate}
										</Form.Control.Feedback>
									</InputGroup>
								</Col>
								<Col>
									<Form.Label>
										Макс. битрейт (бит/c)
									</Form.Label>
									<InputGroup
										hasValidation
										style={{ marginBottom: 0 }}
									>
										<Form.Control
											type={'number'}
											value={values.max_bitrate}
											onChange={(e) =>
												setFieldValue(
													'max_bitrate',
													e.target.value
												)
											}
											isInvalid={!!errors.max_bitrate}
											disabled={!values.is_video}
											placeholder={'0 Мбит/с'}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.max_bitrate}
										</Form.Control.Feedback>
									</InputGroup>
								</Col>
							</Row>
							<Row className={'w-100 mb-2'}>
								<Col>
									<Form.Label>Макс. размер видео</Form.Label>
									<InputGroup
										hasValidation
										style={{ marginBottom: 0 }}
									>
										<Form.Control
											type={'number'}
											value={values.video_max_size}
											onChange={(e) =>
												setFieldValue(
													'video_max_size',
													e.target.value
												)
											}
											isInvalid={!!errors.video_max_size}
											placeholder="0 Мб"
											disabled={!values.is_video}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.video_max_size}
										</Form.Control.Feedback>
									</InputGroup>
								</Col>
								<Col></Col>
							</Row>
							<Row className={'w-100 mb-2'}>
								<Col
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'flex-start',
										flexDirection: 'column',
									}}
								>
									<Form.Label>Звуковая дорожка</Form.Label>
									<ButtonGroupBooleanSwitch
										elements={['Да', 'Нет']}
										initialValue={values.with_audio_track}
										setFieldValue={setFieldValue}
										name={'with_audio_track'}
										disabled={!values.is_video}
									/>
								</Col>
							</Row>

							<Row className={'w-100 mb-2'}>
								<Col>
									<Form.Label>
										Дополнительная информация
									</Form.Label>
									<Form.Control
										type={'text'}
										value={values.additional_info}
										onChange={(e) =>
											setFieldValue(
												'additional_info',
												e.target.value
											)
										}
										isInvalid={!!errors.additional_info}
										as={'textarea'}
										rows={4}
										spellCheck={false}
									/>
								</Col>
							</Row>
							<Row className={'w-100'}>
								<Col>
									<Form.Label>
										Ссылка на требования
									</Form.Label>
									<InputGroup
										hasValidation
										style={{ marginBottom: 0 }}
									>
										<Form.Control
											type={'text'}
											value={values.external_link}
											onChange={(e) =>
												setFieldValue(
													'external_link',
													e.target.value
												)
											}
											isInvalid={!!errors.external_link}
										/>
										<Form.Control.Feedback type="invalid">
											{errors.external_link}
										</Form.Control.Feedback>
									</InputGroup>
								</Col>
							</Row>
						</Col>
					</Modal.Body>
					<Modal.Footer
						style={{
							display: 'flex',
							flexDirection: isMobile ? 'column' : 'row',
						}}
					>
						<Button
							style={{
								width: isMobile ? '100%' : 'auto',
							}}
							variant="secondary"
							onClick={handleClose}
						>
							Отменить
						</Button>
						<Button
							style={{
								width: isMobile ? '100%' : 'auto',
							}}
							variant="primary"
							onClick={handleSubmit}
							disabled={
								Object.keys(errors).length !== 0 ||
								submitDisabled
							}
						>
							{media ? 'Сохранить' : 'Создать'}
						</Button>
					</Modal.Footer>
				</Form>
			)}
		</Formik>
	)
}

//TODO зарефактор форму выше - добавь типы и файл со стилями
const CustomCheckBox = ({ label, disabled = false, ...props }) => {
	// @ts-ignore
	const [field] = useField(props)
	return (
		<Form.Label
			style={{ color: disabled ? '#6C757D' : '#212529', margin: 0 }}
		>
			<Form.Check
				type="checkbox"
				{...field}
				{...props}
				disabled={disabled}
			/>
			{label}
		</Form.Label>
	)
}
