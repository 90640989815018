import React, { useState } from 'react'
import { Button, Modal, Spinner } from 'react-bootstrap'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import { AdvItemAction } from '../CampaignDetailedInfo'
import { AdvItemActionModalBody } from './AdvItemActionModalBody'
import { moderationApi } from 'src/components/_moderator/api/moderatorApi'
import { getError } from 'src/utils/getError'
import { PatchAdvItemBodyType } from 'src/components/_moderator/types/ModerationRequestTypes'
type AdvItemActionModalProps = {
	open: boolean
	onClose: () => void
	advItem: AdvItemAction
	setAdvItemActon: React.Dispatch<
		React.SetStateAction<AdvItemAction | undefined>
	>
}
const minLength = 5
export const AdvItemActionModal = ({
	onClose,
	open,
	advItem,
	setAdvItemActon,
}: AdvItemActionModalProps) => {
	const [{ detailedCampaign, isModerLoading }, moderInterface] =
		useModerationData()

	const [adjustmentMessage, setAdjustmentMessage] = useState(() => {
		if (advItem.action === 'reject') return advItem.rejectMessage
		else return advItem.adjustmentMessage
	})
	const [isDocRequired, setIsDocRequired] = useState(advItem.isDocRequired)
	const getModalTitle = () => {
		if (advItem.action === 'adjustment') {
			return 'Исправление рекламного места'
		}
		if (advItem.action === 'reject') {
			return 'Отклонение рекламного места'
		}
		if (advItem.action === 'moderation') {
			return 'Вернуть на модерацию'
		}
	}
	const getMessageText = () => {
		if (advItem.action === 'adjustment') {
			return 'Напишите, что необходимо исправить заказчику, чтобы пройти модерацию'
		}
		if (advItem.action === 'reject') {
			return 'Напишите заказчику причину, почему отклоняете это рекламное место'
		}

		return ''
	}

	const action = async () => {
		const body: Partial<PatchAdvItemBodyType> = {
			state:
				advItem.action === 'moderation'
					? 'inner_moderation'
					: advItem.action,
		}

		if (advItem.action === 'reject') {
			Object.assign(body, {
				reject_msg: adjustmentMessage,
			})
		} else if (advItem.action === 'adjustment') {
			Object.assign(body, {
				adjustment_msg: adjustmentMessage,
			})
		}

		if (advItem.isDocRequired !== isDocRequired) {
			Object.assign(body, { is_docs_required: isDocRequired })
		}
		try {
			await moderInterface.updateAdvItem(
				detailedCampaign.id,
				advItem.type,
				body,
				advItem.item.id.toString()
			)

			setAdvItemActon(undefined)
		} catch (e: any) {}
	}

	return (
		<Modal
			show={open}
			onHide={onClose}
			centered
			size={'sm'}
			animation={true}
		>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} className={'ms-2'}>
					{getModalTitle()}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body
				style={{
					width: '540px',
					padding: 0,
					margin: 0,
					position: 'relative',
				}}
			>
				{advItem.action !== 'moderation' ? (
					<AdvItemActionModalBody
						setIsDocRequired={setIsDocRequired}
						isDocRequired={isDocRequired}
						messageText={getMessageText()}
						adjustmentMessage={adjustmentMessage}
						setAdjustmentMessage={setAdjustmentMessage}
						{...advItem.item}
					/>
				) : (
					<div
						style={{
							padding: '40px 30px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<h5 style={{ margin: 0 }}>
							Отправить рекламное место на модерацию?
						</h5>
					</div>
				)}
			</Modal.Body>

			<Modal.Footer>
				<div
					style={{
						display: 'flex',
						gap: '8px',
						width: '100%',
						justifyContent: 'flex-end',
					}}
				>
					<Button variant="secondary" onClick={onClose}>
						Отменить
					</Button>
					<Button
						style={{
							display: 'flex',
							alignItems: 'center',
							gap: '8px',
						}}
						disabled={
							(advItem.action !== 'moderation' &&
								adjustmentMessage?.length < minLength) ||
							isModerLoading
						}
						variant="primary"
						onClick={action}
					>
						Отправить{' '}
						{isModerLoading && (
							<Spinner animation="border" size="sm" />
						)}
					</Button>
				</div>
			</Modal.Footer>
		</Modal>
	)
}
