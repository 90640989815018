import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import s from './BookingsFilter.module.scss'
import useProviderData from '../../../../stores/providerData'
import { useBookingsContext } from '../context/BookingsProvider'

interface IModalSetFilters {
	show: string | boolean
	onHide: () => void
	children?: React.ReactNode | React.ReactNode[]
}

const ModalSetFilters: FC<IModalSetFilters> = ({ show, onHide }) => {
	const [tab, setTab] = useState<'Кампании' | 'Заказчики' | 'Регионы'>(() => {
		if (show === 'Кампании') return 'Кампании'
		if (show === 'Заказчики') return 'Заказчики'
		if (show === 'Регионы') return 'Регионы'
		return 'Кампании'
	})
	const [provider] = useProviderData()
	const {
		filterByCompany,
		filterByAd,
		filterByRegions,
		setFilterByAd,
		setFilterByCompany,
		setFilterByRegions,
	} = useBookingsContext()
	const [editedCompany, setEditedCompany] = useState(filterByCompany)
	const [editedAd, setEditedAd] = useState(filterByAd)
	const [editedRegions, setEditedRegions] = useState(filterByRegions)

	const [search, setSearch] = useState<string>('')
	const [isAll, setIsAll] = useState<boolean>(false)
	const currentData = useMemo(() => {
		if (tab === 'Кампании') return editedAd
		if (tab === 'Заказчики') return editedCompany
		if (tab === 'Регионы') return editedRegions

		return []
	}, [tab, editedAd, editedCompany, editedRegions])

	const setCurrentData = useCallback(
		(newData) => {
			if (tab === 'Кампании') setEditedAd(newData)
			if (tab === 'Заказчики') setEditedCompany(newData)
			if (tab === 'Регионы') setEditedRegions(newData)
		},
		[tab]
	)
	const currentAllValues = useMemo(() => {
		if (tab === 'Кампании') {
			if (search === '') return provider?.bookings_filters?.adv_campaigns
			return provider?.bookings_filters?.adv_campaigns.filter(
				(campaign) =>
					campaign?.name.toUpperCase().includes(search.toUpperCase())
			)
		}
		if (tab === 'Заказчики') {
			if (search === '') return provider?.bookings_filters?.companies
			return provider?.bookings_filters?.companies.filter((company) =>
				company?.name.toUpperCase().includes(search.toUpperCase())
			)
		}
		if (tab === 'Регионы') {
			if (search === '') return provider?.bookings_filters?.regions
			return provider?.bookings_filters?.regions.filter((region) =>
				region?.name?.toUpperCase().includes(search.toUpperCase())
			)
		}
		return []
	}, [tab, provider, search])

	const isItemSelected = useCallback(
		(id) => {
			return currentData.includes(id)
		},
		[currentData, currentAllValues]
	)

	useEffect(() => {
		//проверка на галочку выбрать все
		if (
			currentData?.length === currentAllValues?.length &&
			currentAllValues?.length !== 0
		) {
			setIsAll(true)
		} else {
			setIsAll(false)
		}
	}, [tab, search, currentData, currentAllValues])

	useEffect(() => {
		setSearch('')
	}, [tab])

	return (
		<Modal
			show={show}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={onHide}
			animation={false}
			style={{ background: 'rgba(0, 0, 0, 0.5)' }}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Настройка фильтров
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className={'p-0 '}>
				<div className={'d-flex flex-nowrap'}>
					<div
						style={{
							minWidth: '200px',
							borderRight: '1px solid #CED4DA',
							paddingTop: '8px',
						}}
						className={'d-flex flex-column'}
					>
						<div
							className={
								tab === 'Кампании'
									? s.filterTypeActive
									: s.filterType
							}
							onClick={() => {
								setTab('Кампании')
							}}
						>
							<span>Кампании</span>
							{editedAd.length !== 0 && (
								<div>{editedAd.length}</div>
							)}
						</div>
						<div
							className={
								tab === 'Заказчики'
									? s.filterTypeActive
									: s.filterType
							}
							onClick={() => setTab('Заказчики')}
						>
							<span>Заказчики</span>
							{editedCompany.length !== 0 && (
								<div>{editedCompany.length}</div>
							)}
						</div>
						<div
							className={
								tab === 'Регионы'
									? s.filterTypeActive
									: s.filterType
							}
							onClick={() => setTab('Регионы')}
						>
							<span>Регионы</span>
							{editedRegions.length !== 0 && (
								<div>{editedRegions.length}</div>
							)}
						</div>
					</div>
					<div className={'w-100'}>
						<div
							style={{
								borderBottom: '1px solid #CED4DA',
								minHeight: '120px',
								padding: '16px 24px',
							}}
							className={'d-flex flex-column'}
						>
							<InputGroup className="mb-3 d-flex flex-nowrap">
								<InputGroup.Text className={'bg-transparent'}>
									<svg
										width="19"
										height="19"
										viewBox="0 0 19 19"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M8 0C12.4183 0 16 3.58172 16 8C16 9.8482 15.3733 11.55 14.3207 12.9045L18.2071 16.7929L16.7929 18.2071L12.9045 14.3207C11.55 15.3733 9.8482 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2Z"
											fill="#6C757D"
										/>
									</svg>
								</InputGroup.Text>
								<Form.Control
									placeholder="Найти"
									aria-label="Поиск"
									type={'text'}
									value={search}
									onChange={(e) => setSearch(e.target.value)}
									style={{ borderLeft: 'none' }}
								/>
							</InputGroup>
							<div>
								<Form.Check
									type={'checkbox'}
									checked={isAll}
									onChange={() => {
										if (isAll) {
											setIsAll(false)
											setCurrentData([])
										} else {
											setIsAll(true)
											setCurrentData(
												currentAllValues.map(
													(el) => el.id
												)
											)
										}
									}}
									label={'Выбрать всё'}
									id={'isAll'}
								/>
							</div>
						</div>
						<div
							style={{
								minHeight: '200px',
								maxHeight:
									'calc(100vh - 120px - 72px - 80px - 100px)',
								overflowY: 'auto',
							}}
						>
							{currentAllValues?.map((item) => (
								<Form.Group
									style={{ padding: '8px 24px' }}
									key={item.id}
									className={'hover-shadow'}
								>
									<Form.Check
										label={item?.name}
										type={'checkbox'}
										id={item.id}
										checked={
											isItemSelected(item.id) || false
										}
										onChange={() => {
											if (isItemSelected(item.id)) {
												setCurrentData(
													currentData.filter(
														(data) =>
															data !== item.id
													)
												)
											} else {
												setCurrentData([
													...currentData,
													item.id,
												])
											}
										}}
										className={'w-100'}
									/>
								</Form.Group>
							))}
						</div>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onHide}>
					Отменить
				</Button>
				<Button
					variant="primary"
					onClick={() => {
						setFilterByAd(editedAd)
						setFilterByCompany(editedCompany)
						setFilterByRegions(editedRegions)
						onHide()
					}}
				>
					Применить
				</Button>
			</Modal.Footer>
		</Modal>
	)
}

export default ModalSetFilters
