import React from 'react'
import s from './item.module.scss'
import IntlFormatter from 'src/utils/intl-money-formatter'
type Props = {
	icon?: React.ReactNode
	stats: {
		mainTitle: string
		secondaryTitle?: string
		format: string
		viesCount: number
		clickCount: number
		expense: number
	}
	isBorder?: boolean
}

export const Item = ({ stats, icon, isBorder = false }: Props) => {
	return (
		<div style={{ borderBottom: isBorder ? '1px solid #CED4DA' : 'none' }}>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '0.5rem',
					padding: '12px ',
				}}
			>
				<div className={s.header}>
					<div>
						{icon} <span>{stats.mainTitle}</span>
					</div>
					<span>{stats.secondaryTitle}</span>
				</div>

				<div className={s.linesWrapper}>
					<div className={s.line}>
						<div className={s.info}>
							<div>Формат:</div>
							<span>{stats.format}</span>
						</div>
						<div className={s.info}>
							<div>Показы:</div>
							<span
								style={{
									color:
										stats.viesCount <= 0 ? '#CED4DA' : '',
								}}
							>
								{stats.viesCount}
							</span>
						</div>
					</div>

					<div className={s.line}>
						<div className={s.info}>
							<div>Клики:</div>
							<span
								style={{
									color:
										stats.clickCount <= 0 ? '#CED4DA' : '',
								}}
							>
								{stats.clickCount}
							</span>
						</div>
						<div className={s.info}>
							<div>Расход:</div>
							<span
								style={{
									color: stats.expense <= 0 ? '#CED4DA' : '',
								}}
							>
								{IntlFormatter.format(
									stats.expense,
									undefined,
									2
								)}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
