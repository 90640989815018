import React, { FC } from 'react'
import { Badge } from 'react-bootstrap'
import { AnimateSharedLayout, motion } from 'framer-motion'
import s from '../Bookings.module.scss'
import { useBookingsContext } from '../context/BookingsProvider'
import useResponsive from 'src/utils/useResponsive'
import MobileTabsDropDown from 'src/components/_client/campaigns-list/MobileTabsDropDown'
import { BookingsTabsNames } from '../context/Context.types'

type BookingTypeProps = {
	setSelectedTab: React.Dispatch<React.SetStateAction<BookingsTabsNames>>
	selectedTab: BookingsTabsNames
	bookingsCount: number
}
const BookingsTabs: FC<BookingTypeProps> = ({
	setSelectedTab: setSelectedTabsMobile,
	selectedTab: selectedTabMobile,
	bookingsCount,
}) => {
	const { tabs, selectedTab, setSelectedTab } = useBookingsContext()
	const { isMobile } = useResponsive()

	const mobileTabs = tabs.map((el) => {
		return { ...el, title: el.state }
	})
	const handleSetSelectedTab = (tab: BookingsTabsNames) => {
		setSelectedTabsMobile(tab)
		setSelectedTab(tab)
	}

	return isMobile ? (
		<div style={{ borderBottom: '1px solid #CED4DA' }}>
			<MobileTabsDropDown
				tabs={mobileTabs}
				setSelectedTab={handleSetSelectedTab}
				selectedTab={selectedTab}
			/>
		</div>
	) : (
		<div
			className={s.tabsWrapper}
			style={{
				borderBottom:
					bookingsCount === 0 ? '1px solid #CED4DA' : 'none',
			}}
		>
			<div className={s.tabs}>
				<AnimateSharedLayout>
					{tabs.map((tab) => (
						<motion.div
							key={tab.state}
							onClick={(e) => {
								setSelectedTab(tab.state)
								// setOffset(0)
							}}
							style={{
								position: 'relative',
								color:
									selectedTab === tab.state
										? '#007BFF'
										: '#6C757D',
							}}
						>
							{tab.display}
							<Badge
								bg={
									selectedTab === tab.state
										? 'primary'
										: 'secondary'
								}
							>
								{tab.count}
							</Badge>
							{selectedTab === tab.state && <ActiveLine />}
						</motion.div>
					))}
				</AnimateSharedLayout>
			</div>
		</div>
	)
}
const ActiveLine = () => {
	return (
		<motion.div
			layoutId={'active-line'}
			style={{
				width: '100%',
				height: '3px',
				position: 'absolute',
				bottom: '-2px',
				left: '0',
				backgroundColor: '#0D6EFD',
			}}
		/>
	)
}

export default BookingsTabs
