import React, { FC, useContext, useMemo } from 'react'
import useCampaignData from '../../../stores/campaignData'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { CampaignStates } from '../../../stores/ADMarketTypes.types'
import s from './CampaignInfo.module.scss'
import useResponsive from '../../../utils/useResponsive'
import {
	CancelCampaignIcon,
	DeleteCampaignIcon,
	EditCampaignIcon,
	RepeatCampaignIcon,
	ReturnToDraftIcon,
} from './icons/CampaignButtonsActionsIcons'
import { useConfirm } from '../../UtilityComponents/ConfirmModalProvider'
import { useHistory } from 'react-router-dom'
import { ToastsContext } from '../../UtilityComponents/ToastsContextProvider'

type ActionButton = {
	action: 'edit' | 'delete' | 'cancel' | 'return_draft' | 'repeat'
	tooltip_text: string
	onClick?: () => any
	icon: React.FC
	hover_color: string
	position: number
	is_visible?: boolean
}

const ActionButtons: ActionButton[] = [
	{
		action: 'edit',
		tooltip_text: 'Продолжить создание',
		icon: EditCampaignIcon,
		hover_color: '#007BFF',
		position: 1,
		//99% удалить потом
	},
	{
		action: 'delete',
		tooltip_text: 'Удалить кампанию',
		icon: DeleteCampaignIcon,
		hover_color: '#DC3545',
		position: 5,
		//state==="draft"
	},
	{
		action: 'cancel',
		tooltip_text: 'Отменить кампанию',
		icon: CancelCampaignIcon,
		hover_color: '#DC3545',
		position: 4,
		//state==="moderation"
	},
	{
		action: 'return_draft',
		tooltip_text: 'Вернуть в черновик',
		icon: ReturnToDraftIcon,
		hover_color: '#007BFF',
		position: 3,
		//state==="все брони внутри рк подходят по условию"
	},
	{
		action: 'repeat',
		tooltip_text: 'Повторить кампанию',
		icon: RepeatCampaignIcon,
		hover_color: '#007BFF',
		position: 2,
	},
]

const CampaignStatusAndButtons: FC = () => {
	const [campaign] = useCampaignData()
	return (
		<div className={'d-flex justify-content-between align-items-center'}>
			<CampaignBadge status={campaign.state} />
			<CampaignButtons />
		</div>
	)
}

const CampaignButtons: FC = () => {
	const [campaign, campaignInterface] = useCampaignData()
	const { isMobile } = useResponsive()
	const { confirm } = useConfirm()
	const history = useHistory()
	const { addToast } = useContext(ToastsContext)

	const handleCampaignDelete = async (id) => {
		const answ = await confirm({
			text: 'После этого действия вы не сможете ее вернуть обратно',
			title: 'Удалить рекламную кампанию?',
			closeButton: true,
			acceptText: 'Да, уверен',
			declineText: 'Нет, отменить',
		})
		if (answ) {
			history.push('/campaigns/')
			const errors: any = await campaignInterface.deleteCampaign(id)
			if (errors) {
				addToast({ text: errors?.data[0], type: 'danger' })
			} else {
				addToast({
					text: 'Рекламная кампания удалена',
					type: 'success',
				})
			}
		}
	}
	const handleCampaignCancel = async (id) => {
		const answ = await confirm({
			text: 'После этого действия вы не сможете ее вернуть обратно',
			title: 'Отменить рекламную кампанию?',
			closeButton: true,
			acceptText: 'Да, уверен',
			declineText: 'Нет, отменить',
			acceptVariant: 'danger',
		})
		if (answ) {
			const errors: any = await campaignInterface.cancelCampaign(id)
			if (errors) {
				addToast({ text: errors?.data[0], type: 'danger' })
			} else {
				addToast({
					text: 'Рекламная кампания отменена',
					type: 'success',
				})
			}
		}
	}
	const handleCampaignDraft = async (id) => {
		if (!campaignInterface.isCanReturnedToDraft()) return
		const answ = await confirm({
			text: 'Все загруженные рекламные материалы и подтвержденные рекламные места сохранятся, отклоненные станут неактивными с возможностью добавить их снова, а отмененные удалятся.',
			title: 'Возврат рекламной кампании в черновик',
			closeButton: true,
			acceptText: 'Подтвердить',
			declineText: 'Отменить',
		})
		if (answ) {
			const new_id: any = await campaignInterface.moveCampaignToDraft(id)
			if (new_id) {
				history.push('/campaigns/' + new_id)
			}
		}
	}

	const buttons = useMemo<ActionButton[]>(() => {
		return ActionButtons.map((el) => {
			switch (el.action) {
				case 'edit':
					return {
						...el,
						is_visible: false,
					}
				case 'cancel':
					return {
						...el,
						is_visible:
							campaign.state === CampaignStates.Moderation,
						onClick: () => handleCampaignCancel(campaign.id),
					}
				case 'delete':
					return {
						...el,
						is_visible: campaign.state === CampaignStates.Draft,
						onClick: () => handleCampaignDelete(campaign.id),
					}
				case 'repeat':
					return { ...el, is_visible: false }
				case 'return_draft':
					return {
						...el,
						is_visible:
							campaignInterface.isCanReturnedToDraft() ||
							campaign.state === CampaignStates.Confirmed,
						onClick: () => handleCampaignDraft(campaign.id),
					}
				default:
					return el
			}
		})
	}, [campaign]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className={'d-flex align-items-end gap-2'}>
			{buttons.map((button) =>
				button.is_visible ? (
					<OverlayTrigger
						placement={'left'}
						overlay={
							<Tooltip id={`tooltip-top`}>
								{button.tooltip_text}
							</Tooltip>
						}
						key={button.action}
					>
						<button
							className={s.campaignActionButton}
							style={{
								background: isMobile ? '#fff' : 'transparent',
								color: button.hover_color,
							}}
							onClick={button.onClick}
						>
							<button.icon />
						</button>
					</OverlayTrigger>
				) : null
			)}
		</div>
	)
}

const CampaignBadge = ({ status }: { status: CampaignStates }) => {
	const bg = useMemo(() => {
		switch (status) {
			case 'moderation':
				return 'primary'
			case 'paid':
			case 'completed':
				return 'success'
			case 'canceled':
				return 'danger'
			case 'draft':
				return 'secondary'
			case 'payment_waiting':
				return 'primary'
			case 'active':
				return 'primary'
			case 'start_waiting':
				return 'primary'
			case 'confirmed':
				return 'success'
			default:
				return 'primary'
		}
	}, [status])
	const text = useMemo(() => {
		switch (status) {
			case 'moderation':
				return 'На модерации'
			case 'completed':
				return 'Завершена'
			case 'canceled':
				return 'Отменена'
			case 'payment_waiting':
				return 'Ожидает оплаты'
			case 'start_waiting':
				return 'Ожидает старта'
			case 'active':
				return 'Активная'
			case 'confirmed':
				return 'Модерация завершена'
			case 'paid':
				return 'Оплачена'
			case 'draft':
				return 'Черновик'
		}
	}, [status])
	return (
		<Badge
			bg={bg}
			style={{
				fontSize: '14px',
				fontWeight: 'normal',
				height: 'min-content',
			}}
		>
			{text}
		</Badge>
	)
}

export default CampaignStatusAndButtons
