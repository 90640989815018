import React, { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import s from './common.module.scss'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import ImageFullScreenWrapper from 'src/components/UtilityComponents/ImageFullScreenWrapper'

type FormingDocsItemProps = {
	advType:
		| 'districtbooking'
		| 'outdoor'
		| 'indoor'
		| 'adstream'
		| 'vk'
		| 'yandex'
	id: string | number
	factCost: string
	budget: string | number
	onFactCostChange: (value: string) => void
}
export const FormingDocsItem = ({
	advType,
	id,
	budget,
	factCost,
	onFactCostChange,
}: FormingDocsItemProps) => {
	const [factCostLocal, setFactCostLocal] = useState(factCost)

	const debouncedFactCost = useDebounce(factCostLocal, 400)

	useEffect(() => {
		if (factCostLocal === factCost) return

		onFactCostChange(factCostLocal)
	}, [debouncedFactCost])
	return (
		<div className={cn(s.formingDocsItem)}>
			<FormingDocsItemHeader advType={advType} id={id} />
			<FactCostCompare
				budget={budget}
				factCost={factCostLocal}
				onFactCostChange={setFactCostLocal}
			/>
		</div>
	)
}

type FactCostCompareProps = {
	factCost: string
	budget: string | number
	onFactCostChange: (value: string) => void
}

export const FactCostCompare = ({
	budget,
	factCost,
	onFactCostChange,
}: FactCostCompareProps) => {
	const handleOnChange = (value: string) => {
		if (factCost === '0') {
			const parsedValue = parseFloat(value)
			onFactCostChange(parsedValue.toString())
		} else if (factCost === '') {
			onFactCostChange('0')
		} else {
			onFactCostChange(value)
		}
	}
	return (
		<Row style={{ display: 'flex', width: '100%' }}>
			<Form.Group as={Col} className="w-100">
				<Form.Label>Запланированный бюджет</Form.Label>
				<InputWithEndText
					disabled
					endText={'₽'}
					type={'number'}
					size={'lg'}
					value={budget}
					onChange={(e) => {}}
				/>
			</Form.Group>
			<Form.Group as={Col} className="w-100">
				<Form.Label>Фактический бюджет</Form.Label>
				<InputWithEndText
					endText={'₽'}
					type={'number'}
					size={'lg'}
					value={factCost}
					onChange={(e) => {
						if (e.target.value === '') {
							handleOnChange('0')
						} else {
							handleOnChange(e.target.value)
						}
					}}
				/>
			</Form.Group>
		</Row>
	)
}

import { ReactComponent as TV } from '../../../../../../../../assets/images/tv.svg'
import { ReactComponent as Yandex } from '../../../../../../../../assets/images/yandex.svg'
import { ReactComponent as VK } from '../../../../../../../../assets/images/vk.svg'
import { Col, Form, Row } from 'react-bootstrap'
import InputWithEndText from 'src/components/UtilityComponents/InputWithEndText'
import useDebounce from 'src/utils/useDebounce'

type FormingDocsItemHeaderProps = {
	advType:
		| 'districtbooking'
		| 'outdoor'
		| 'indoor'
		| 'adstream'
		| 'vk'
		| 'yandex'
	id: string | number
}
type HeaderInfoObjectType = {
	image: string | React.FunctionComponent<React.SVGProps<SVGSVGElement>>
	mainText: string
	secondaryText?: string
}
const FormingDocsItemHeader = ({ advType, id }: FormingDocsItemHeaderProps) => {
	const [{ detailedCampaign }] = useModerationData()

	const headerInfoObject = useMemo((): HeaderInfoObjectType => {
		switch (advType) {
			case 'districtbooking':
				const districtbooking = detailedCampaign.district_booking.find(
					(el) => el.id === id
				)
				return {
					image: 'https://storage.yandexcloud.net/admarket-images/static-img/outdoor_26_11zon.jpg',
					mainText: districtbooking?.city_id.toString() || '',
					secondaryText: districtbooking?.creatives
						.map((creative) => creative.id)
						.join(', '),
				}
			case 'outdoor':
			case 'indoor':
				const booking = detailedCampaign.booking.find(
					(el) => el.id === id
				)
				return {
					image: booking?.placement?.image || '',
					mainText: booking?.placement.placement_type.name || '',
					secondaryText: booking?.placement?.name || '',
				}
			case 'adstream':
				return {
					image: TV,
					mainText: 'Показ видеоролика при переключении канала',
				}
			case 'vk':
				return {
					image: VK,
					mainText: 'VK',
				}
			case 'yandex':
				return {
					image: Yandex,
					mainText: 'Яндекс',
				}
		}
	}, [detailedCampaign, advType, id])

	return (
		<div style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
			<div>
				{typeof headerInfoObject.image === 'string' ? (
					<ImageFullScreenWrapper>
						<img
							style={{
								width: '64px',
								height: '64px',
								borderRadius: '4px',
							}}
							src={
								headerInfoObject.image ||
								'https://storage.yandexcloud.net/admarket-dev-data/import/vdooh/default.jpg'
							}
							alt={''}
						/>
					</ImageFullScreenWrapper>
				) : (
					<headerInfoObject.image />
				)}
			</div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<h6 className="mb-0">{headerInfoObject.mainText}</h6>
				{headerInfoObject.secondaryText && (
					<h6 className="mb-0">{headerInfoObject.secondaryText}</h6>
				)}
			</div>
		</div>
	)
}
