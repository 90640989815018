import { ModerationAdvStatus } from '../types/ModerationRequestTypes'

export type CampaignStatusColorsType = {
	[key in ModerationAdvStatus]: {
		color: string
		title: string
	}
}
export const campaignStatus: CampaignStatusColorsType = {
	moderation: { color: '#007BFF', title: 'На модерации' },
	confirmed: { color: '#28A745', title: 'Подтверждена' },
	paid: { color: '#da9100', title: 'Оплачена' },
	completed: { color: '#6C757D', title: 'Завершена' },
	start_waiting: { color: '#28A745', title: 'ожидает запуска' },
	adjustment: { color: '#007BFF', title: 'На исправлении' },
	active: { color: '#007BFF', title: 'Активна' },
	canceled: { color: '#6C757D', title: 'Отменена' },
	close_waiting: { color: '#6C757D', title: 'Ожидает закрытия' },
	payment_waiting: { color: '#6C757D', title: 'Ожидает оплаты' },
	draft: { color: '#6C757D', title: 'Черновик' },
} as CampaignStatusColorsType
