import React from 'react'
import { CardWrapper } from '../../../../../../common/card-wrapper/CardWrapper'
import s from './mcr.module.scss'
import { AdvHeader } from '../../../../../../common/adv-header/AdvHeader'
import { ReactComponent as MCR } from 'src/assets/images/railway-2.svg'
import { Form } from 'react-bootstrap'
import { NumericFormat } from 'react-number-format'
import InputWithEndText from 'src/components/UtilityComponents/InputWithEndText'
import { ReactComponent as PinOnMap } from 'src/assets/images/pin-2.svg'
import { CommonRec } from 'src/stores/types/recommendations'
import useResponsive from 'src/utils/useResponsive'

type Props = {
	mcr: CommonRec
}
export const MCRList = ({ mcr }: Props) => {
	const { isMobile } = useResponsive()
	return (
		<CardWrapper>
			<div className={s.content}>
				<AdvHeader
					Icon={MCR}
					title="Цифровые экраны на МЦК"
					description="Динамическое распределение показов в рамках бюджета рекламной кампании по всем свободным рекламным местам в Московском центральном кольце"
				/>

				<div className={s.item}>
					<Form.Group className={s.controlBudget}>
						<Form.Label>{'Бюджет на город'}</Form.Label>
						<div style={{ display: 'flex', gap: '0.5rem' }}>
							<NumericFormat
								id={`mcr_budget`}
								decimalScale={2}
								decimalSeparator=","
								value={mcr.budget}
								suffix=" ₽"
								thousandSeparator={' '}
								customInput={InputWithEndText}
								controlStyle={{
									minHeight: '40px',
									height: isMobile ? '40px' : '45px',
									fontSize: isMobile ? '16px' : '1.25rem',
									borderRadius: isMobile ? '12px' : '16px',
								}}
								disabled={true}
								onChange={() => {}}
								onKeyDown={() => {}}
								containerStyle={{
									overflow: 'hidden',
									width: '100%',
								}}
							/>
						</div>
					</Form.Group>

					<div className={s.counts}>
						<PinOnMap />
						<div className={s.countsText}>
							<h6>Добавлено рекламных мест:</h6>
							<h6>{}</h6>
						</div>
					</div>
				</div>
			</div>
		</CardWrapper>
	)
}
