import React, { FC, useMemo, useState, useEffect } from 'react'
import useResponsive from '../../../utils/useResponsive'
import useCampaignData from '../../../stores/campaignData'
import { Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap'
import s from './CampaignInfo.module.scss'

import moment from 'moment'
import { useHistory } from 'react-router-dom'
import {
	REUSABLE_MODALS,
	useReusableModals,
} from '../../UtilityComponents/ReusableModals/ReusableModalsProvider'
import { CampaignStates } from 'src/stores/ADMarketTypes.types'

const CampaignTitle: FC = () => {
	const { isMobile } = useResponsive()
	const showModal = useReusableModals()
	const [campaign] = useCampaignData()
	const margin = useMemo(() => (isMobile ? '16px' : '24px'), [isMobile])
	const history = useHistory()
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		let timeoutId: NodeJS.Timeout

		if (isLoading) {
			timeoutId = setTimeout(() => {
				setIsLoading(false)
			}, 3000)
		}

		return () => clearTimeout(timeoutId)
	}, [isLoading])

	const handleMediaPlanClick = async () => {
		try {
			setIsLoading(true)
			await showModal(REUSABLE_MODALS.showMediaPlanModal)
			setIsLoading(false)
		} catch (error) {
			setIsLoading(false)
		}
	}

	return (
		<>
			<div
				className={s.campaignTitle}
				style={{ marginTop: margin, marginBottom: margin }}
			>
				<div className={'d-flex align-items-baseline'}>
					{!isMobile && (
						<OverlayTrigger
							placement={'right'}
							overlay={
								<Tooltip id={`tooltip-top`}>
									Вернуться на главную
								</Tooltip>
							}
						>
							<button
								className={s.campaignActionButton}
								onClick={() => {
									history.push('/campaigns')
								}}
								style={{
									color: '#212529',
									marginRight: '16px',
								}}
							>
								<i
									className={
										'bi bi-arrow-left text-secondary'
									}
								/>
							</button>
						</OverlayTrigger>
					)}
					<h1 className={'mb-0'}>{`РК «${campaign.name}» от ${moment(
						campaign.created_at
					).format('DD.MM.YY')}`}</h1>
				</div>

				{campaign.state !== CampaignStates.Completed && (
					<>
						{isLoading ? (
							isMobile ? (
								<Spinner
									animation="border"
									role="status"
									size="sm"
									style={{ marginRight: '5px' }}
								>
									<span className="visually-hidden">
										Загрузка медиаплана...
									</span>
								</Spinner>
							) : (
								<Button
									variant={'secondary'}
									style={{
										height: 'fit-content',
										minWidth: 'fit-content',
									}}
									disabled
								>
									<Spinner
										animation="border"
										role="status"
										size="sm"
										style={{ marginRight: '5px' }}
									>
										<span className="visually-hidden">
											Загрузка медиаплана...
										</span>
									</Spinner>
									Загрузка медиаплана...
								</Button>
							)
						) : (
							!isMobile && (
								<Button
									variant={'secondary'}
									onClick={handleMediaPlanClick}
									style={{
										height: 'fit-content',
										minWidth: 'fit-content',
									}}
								>
									Смотреть медиаплан
								</Button>
							)
						)}
						{isMobile && !isLoading && (
							<Button
								variant={'secondary'}
								onClick={handleMediaPlanClick}
								style={{
									height: 'fit-content',
									minWidth: 'fit-content',
									width: '100%',
									marginBottom: '8px',
								}}
							>
								Смотреть медиаплан
							</Button>
						)}
					</>
				)}
			</div>
		</>
	)
}

export default CampaignTitle
