import React from 'react'
import s from './commonStyles.module.scss'
import cn from 'classnames'
import useResponsive from 'src/utils/useResponsive'

type CreateReportHeaderProps = {
	title: string
	description: string
	children?: React.ReactNode
}

export const CreateReportHeader = ({
	description,
	title,
	children,
}: CreateReportHeaderProps) => {
	const { isMobile } = useResponsive()
	return (
		<div
			className={cn(s.reportHeader, {
				[s.reportHeader_mobile]: isMobile,
			})}
		>
			<h1>{title}</h1>
			<p>{description}</p>
			{children}
		</div>
	)
}
