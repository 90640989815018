import React, { useCallback, useMemo, useState } from 'react'
import { ProviderBookingQueryParams } from '../../../booking-filters-mobile/BookingsSettings'
import useProviderData from 'src/stores/providerData'
import { ProviderFilterCheckbox } from 'src/components/_provider/Bookings/filters/components/common/ProviderFilterCheckbox'
import { useQueryParams } from 'src/utils'
import { ProviderFilterContentHeader } from '../../common/ProviderFilterContentHeader'
import { ProviderFilterContentBody } from '../../common/ProviderFilterContentBody'
import { ProviderFilterContentFooter } from '../../common/ProviderFilterContentFooter'

type BookingCampaingFilterContentProps = {
	onFilterSubmit: (queryParams: ProviderBookingQueryParams) => void
	onClose: () => void
}
export const BookingCampaingFilterContent = ({
	onFilterSubmit,
	onClose,
}: BookingCampaingFilterContentProps) => {
	const [provider] = useProviderData()

	const { params, pathName } =
		useQueryParams<Partial<ProviderBookingQueryParams>>()

	const [selectedCampaigns, setSelectedCampaigns] = useState(() => {
		if (params?.adv_companies) {
			if (Array.isArray(params?.adv_companies))
				return params?.adv_companies
			return [params?.adv_companies]
		} else {
			return []
		}
	})

	const availableCampaigns = provider?.bookings_filters?.adv_campaigns

	const [searchString, setSearchString] = useState('')

	const filtredCampaigns = useMemo(
		() =>
			availableCampaigns?.filter((el) =>
				el.name.toLowerCase().includes(searchString.toLowerCase())
			),
		[searchString, location, availableCampaigns]
	)

	const handleSelectedChange = (capmaignId: string) => {
		if (selectedCampaigns.includes(capmaignId)) {
			setSelectedCampaigns(
				selectedCampaigns.filter((el) => el !== capmaignId)
			)
		} else {
			setSelectedCampaigns([...selectedCampaigns, capmaignId])
		}
	}

	const handleFilterSubmit = () => {
		onFilterSubmit({
			...params,
			adv_companies: selectedCampaigns,
		} as any)
	}
	const selectList = useMemo(
		() =>
			filtredCampaigns?.map((el) => (
				<ProviderFilterCheckbox
					checked={selectedCampaigns.includes(el.id)}
					onChange={() => handleSelectedChange(el.id)}
					text={el.name}
					key={el.id}
				/>
			)),
		[searchString, availableCampaigns, selectedCampaigns]
	)
	const handleChangeAll = useCallback(() => {
		if (selectedCampaigns?.length >= filtredCampaigns?.length) {
			return setSelectedCampaigns([])
		} else {
			setSelectedCampaigns(
				filtredCampaigns?.map((campaign) => campaign.id)
			)
		}
	}, [selectedCampaigns, availableCampaigns, filtredCampaigns])
	return (
		<div
			id={'provider-filter-content-wrapper'}
			style={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%',
				width: '100%',
			}}
		>
			<ProviderFilterContentHeader
				onClick={handleChangeAll}
				isAllSelected={
					selectedCampaigns.length >= filtredCampaigns?.length
				}
				count={selectedCampaigns.length}
				setSearchString={setSearchString}
			/>

			<ProviderFilterContentBody>
				<div>{selectList}</div>
			</ProviderFilterContentBody>

			<ProviderFilterContentFooter
				onApplyFilters={handleFilterSubmit}
				onClose={onClose}
			/>
		</div>
	)
}
