import React, { FC, useState } from 'react'
import { TvType } from './TvStateView.types'
import { TvState } from '../../../../stores/ADMarketTypes.types'
import { Button } from 'react-bootstrap'
import EditTvModall from './adstream/EditAdstreamModall'
import EditTvModalTeletarget from './teletarget/EditTeletargeModal'
import useCampaignData from 'src/stores/campaignData'
import useNotificationData from 'src/stores/notificationsData'
import { useCampaignInfoStore } from '../store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from '../store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { EditVinstantModal } from '../common/edit-vinstant-modal/EditVinstantModal'
import { defaultMessageData, MessagesData } from '../constants/messages-data'

interface ITvStateView {
	state: TvState
	tvType: TvType
	adjustment_msg?: string
	reject_msg?: string
}

const TvStateView: FC<ITvStateView> = ({
	state,
	tvType,
	adjustment_msg,
	reject_msg,
}) => {
	const [campaign, campaignInterface] = useCampaignData()
	const [showEditModal, setShowEditModal] = useState(false)

	const data = MessagesData[state] || defaultMessageData
	if (state === TvState.Adjustment) {
		data.message = adjustment_msg || ''
	}
	if (state === TvState.Reject) {
		data.message = reject_msg || ''
	}
	const [tvVinstantEditKey, setTvVinstantEditKey] = useState<
		'adstream' | 'teletarget' | ''
	>('')
	const [_, notificationsInterface] = useNotificationData()

	const editAdstreamCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated('adstream') as
			| string
			| undefined

	const adstreamVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'adstream_id',
			itemId: campaign.adstream?.id.toString(),
		})
	)
	const teletargetVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'teletarget_id',
			itemId: campaign.teletarget?.id.toString(),
		})
	)
	const handleEditVinstantModalClose = () => {
		setTvVinstantEditKey('')
		setShowEditModal(true)
	}

	const handleEditVinstantModalOpen = (key: 'adstream' | 'teletarget') => {
		if (key === 'adstream') {
			if (adstreamVinstantLink === null) {
				return
			}
		}
		if (key === 'teletarget') {
			if (teletargetVinstantLink === null) {
				return
			}
		}
		setTvVinstantEditKey(key)
	}

	if (!data) return <></>
	return (
		<div
			className={'d-flex flex-column mb-4'}
			style={{ color: data.color }}
		>
			<>
				<div className={'mb-3'}>
					<i className={data.icon} style={{ marginRight: '12px' }} />
					{data.title}
				</div>
				<div
					className={'w-100 mb-3'}
					style={{
						height: '4px',
						borderRadius: '8px',
						backgroundColor: '#E9ECEF',
						position: 'relative',
					}}
				>
					<div
						style={{
							width: `${data.progress}%`,
							backgroundColor: data.color,
							borderRadius: '8px',
							height: '4px',
							position: 'absolute',
						}}
					></div>
				</div>
				<span>{data.message}</span>
			</>

			<EditVinstantModal
				newContentKey={tvVinstantEditKey}
				editVinstantLink={adstreamVinstantLink}
				onClose={handleEditVinstantModalClose}
				open={!!tvVinstantEditKey}
			/>

			{state === TvState.Adjustment && (
				<>
					<Button
						variant={'primary'}
						className={'mt-3'}
						style={{ width: 'fit-content' }}
						onClick={() => {
							setShowEditModal(true)
						}}
					>
						Исправить
					</Button>
					{tvType === 'adstream' ? (
						<EditTvModall
							adstreamVinstantEditStatus={
								editAdstreamCreativeVinstantStatus
							}
							adstreamVinstantLink={adstreamVinstantLink}
							onEditVinstantClick={() =>
								handleEditVinstantModalOpen('adstream')
							}
							show={showEditModal}
							handleClose={() => {
								setShowEditModal(false)
							}}
						/>
					) : (
						<EditTvModalTeletarget
							show={showEditModal}
							handleClose={() => {
								setShowEditModal(false)
							}}
							tvType={tvType}
						/>
					)}
				</>
			)}
		</div>
	)
}

export default TvStateView
