import { PhoneConfirmationVariant } from 'src/stores/userData'

export type LoginFormData = {
	phone_number: string
	password: string
	rememberMe: boolean
}
export type LoginInput = {
	phone_number: string
	password: string
	agency_alias?: string
}

export enum PassResetStagesType {
	PhoneInput = 'phoneInput',
	CodeConfirmation = 'codeConfirmation',
	NewPassword = 'newPassword',
}

export type PhoneInputFormData = {
	phone_number: string
}

export type CodeConfirmationFormData = {
	code: string
}

export type CodeResendData = {
	phone_number: string
	confirmType: PhoneConfirmationVariant
	agency_alias?: string
}
export type NewPasswordFormData = {
	password: string
	password_confirmation: string
}

export type PassResetPhoneInput = {
	phone_number: string
	agency_alias?: string
}

export enum RegistrationStagesType {
	CredentialsInput = 'credentialsInput',
	VerificationCode = 'verificationCode',
}

export type RegistrationCredentialsFormData = {
	phone_number: string
	password: string
}
export type ClientRegistrationInput = {
	phone_number: string
	password: string
	utm_sources?: string
}
export type RegisterQueryParams = {
	utm_source: string
}
