import React from 'react'
import s from '../../style.module.scss'
import cn from 'classnames'
import { ReactComponent as Edit } from 'src/assets/images/edit.svg'
import { ReactComponent as Clear } from 'src/assets/images/clear-ring.svg'
import { ReactComponent as OK } from 'src/assets/images/checkmark-ring.svg'
import { ReactComponent as Docs } from 'src/assets/images/docs.svg'

type Props = {
	isConfirmAvailable?: boolean
	setAction: React.Dispatch<
		React.SetStateAction<
			'moderation' | 'adjustment' | 'reject' | 'completed' | undefined
		>
	>
	action: 'moderation' | 'adjustment' | 'reject' | 'completed'
	isLoading: boolean
}
export const BottomButtons = ({
	setAction,
	action,
	isLoading,
	isConfirmAvailable = true,
}: Props) => {
	return (
		<div
			style={{
				width: '100%',
				minHeight: '80px',
				height: '80px',
				borderTop: '1px solid #ced4da',
				display: 'flex',
				justifyContent: 'center',
				alignSelf: 'flex-end',
			}}
		>
			{action === 'completed' ? (
				<button
					disabled={isLoading}
					className={cn(s.bottomButton)}
					style={{ color: '#007BFF' }}
					onClick={() => setAction('completed')}
				>
					<Docs style={{ fill: '#007BFF' }} />
					<p>Сформировать закрывающие документы</p>
				</button>
			) : (
				<>
					<button
						disabled={isLoading || !isConfirmAvailable}
						className={s.bottomButton}
						style={{ color: '#28A745' }}
						onClick={() => setAction('moderation')}
					>
						<OK style={{ fill: '#28A745' }} />
						<p>Подвердить</p>
					</button>
					<button
						disabled={isLoading}
						className={s.bottomButton}
						style={{ color: '#007BFF', fill: '#007BFF' }}
						onClick={() => setAction('adjustment')}
					>
						<Edit />
						<p>На исправление</p>
					</button>
					<button
						disabled={isLoading}
						className={s.bottomButton}
						style={{ color: '#DC3545' }}
						onClick={() => setAction('reject')}
					>
						<Clear />
						<p>Отклонить</p>
					</button>
				</>
			)}
		</div>
	)
}
