import * as Yup from 'yup'

export const loginValidationSchema = Yup.object().shape({
	phone_number: Yup.string()
		.matches(
			/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
			'Введите корректный номер телефона'
		)
		.required('Телефон обязателен'),
	password: Yup.string().required('Введите пароль'),
	rememberMe: Yup.boolean().required('Введите пароль'),
})

export const phoneValidationSchema = Yup.object().shape({
	phone_number: Yup.string()
		.matches(
			/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
			'Введите корректный номер телефона'
		)
		.required('Телефон обязателен'),
})

export const newPasswordValidationSchema = Yup.object().shape({
	password: Yup.string()
		.matches(
			/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
			'Пароль должен содержать минимум 8 символов, включая заглавную букву, цифру и специальный символ (!@#$%^&*(),.?":{}|<>)'
		)
		.required('Введите пароль'),
	password_confirmation: Yup.string()
		.oneOf([Yup.ref('password'), undefined], 'Пароли не совпадают')
		.required('Подтвердите пароль'),
})

export const registrationValidationSchema = Yup.object().shape({
	phone_number: Yup.string()
		.matches(
			/^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
			'Введите корректный номер телефона'
		)
		.required('Телефон обязателен'),
	password: Yup.string()
		.matches(
			/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/,
			'Пароль должен содержать минимум 8 символов, включая заглавную букву, цифру и специальный символ (!@#$%^&*(),.?":{}|<>)'
		)
		.required('Введите пароль'),
})
