import React, { Children } from 'react'
import ImageFullScreenWrapper from 'src/components/UtilityComponents/ImageFullScreenWrapper'
import s from '../helpContent.module.scss'
import cn from 'classnames'
import useResponsive from 'src/utils/useResponsive'

type HelpContentElementProps = {
	title: string | JSX.Element
	url?: string
	alt?: string
	children?: React.ReactNode
	number?: number | string
	style?: React.CSSProperties
}
export const StepElement = ({
	alt,
	title,
	url,
	children,
	number,
	style,
}: HelpContentElementProps) => {
	const { isMobile } = useResponsive()
	return (
		<li
			className={cn(s.marker, { [s.marker_mobile]: isMobile })}
			style={style}
		>
			<div style={{ display: 'flex' }}>
				{number && (
					<span style={{ whiteSpace: 'pre' }}>{`${number}. `} </span>
				)}

				<p className={cn(s.title)}>{title}</p>
			</div>

			{children && <div className={cn(s.child)}>{children}</div>}

			{url && (
				<StepElementImage
					alt={alt || ''}
					isMobile={isMobile}
					url={url}
				/>
			)}
		</li>
	)
}

type StepElementImageProps = {
	url: string
	alt: string
	isMobile: boolean
}

export const StepElementImage = ({
	alt,
	isMobile,
	url,
}: StepElementImageProps) => {
	return (
		<ImageFullScreenWrapper fullWidth={true}>
			<img
				className={cn(s.img, { [s.img_mobile]: isMobile })}
				src={url}
				alt={alt}
			/>
		</ImageFullScreenWrapper>
	)
}
