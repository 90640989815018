import React from 'react'
const AcceptBookingIcon = ({ width = 20, height = 20 }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.70706 13.2071L14.7071 8.20706L13.2928 6.79285L8.99995 11.0857L6.20706 8.29285L4.79285	 9.70706L8.29285 13.2071C8.68337 13.5976 9.31654 13.5976 9.70706 13.2071Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
			fill="currentColor"
		/>
	</svg>
)

const EditBookingIcon = ({ width = 18, height = 18 }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.2072 0.292893L18.2072 4.29289C18.5977 4.68342 18.5977 5.31658 18.2072 5.70711L7.20717 16.7071C7.06757 16.8467 6.88978 16.9419 6.69618 16.9806L1.69618 17.9806C0.996462 18.1205 0.379543 17.5036 0.519487 16.8039L1.51949 11.8039C1.55821 11.6103 1.65336 11.4325 1.79296 11.2929L12.793 0.292893C13.1835 -0.0976311 13.8167 -0.0976311 14.2072 0.292893ZM10.9995 4.914L3.42127 12.493L2.77482 15.7252L6.00706 15.0788L13.5855 7.5L10.9995 4.914ZM13.5001 2.41421L12.4145 3.5L14.9995 6.085L16.0859 5L13.5001 2.41421Z"
			fill="currentColor"
		/>
	</svg>
)

const DownloadBookingIcon = ({ width = 22, height = 20 }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 22 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14 18L2 18L2 2L14 2V6H16V1C16 0.447716 15.5523 0 15 0H1C0.734784 0 0.48043 0.105356 0.292893 0.292892C0.105357 0.480429 0 0.734783 0 1V19C0 19.5523 0.447717 20 1 20L14 20L14 18Z"
			fill="currentColor"
		/>
		<path
			d="M19.6439 13.2285C19.8086 13.3897 19.8909 13.6296 19.8909 13.9482C19.8909 14.5319 19.6152 14.8237 19.0638 14.8237C18.6663 14.8237 18.3799 14.7217 18.2044 14.5176C18.0289 14.3135 17.9412 13.9858 17.9412 13.5347H16.3568C16.3568 14.061 16.4731 14.5176 16.7059 14.9043C16.9422 15.291 17.268 15.5882 17.6834 15.7959C18.0988 16.0036 18.5589 16.1074 19.0638 16.1074C19.8086 16.1074 20.3976 15.9159 20.8309 15.5327C21.2641 15.1496 21.4808 14.6178 21.4808 13.9375C21.4808 13.318 21.3071 12.8078 20.9598 12.4067C20.616 12.0057 20.0628 11.6673 19.3001 11.3916C18.8633 11.2054 18.5589 11.0264 18.387 10.8545C18.2151 10.679 18.1292 10.4839 18.1292 10.269C18.1292 9.99333 18.2062 9.77491 18.3602 9.61378C18.5141 9.44906 18.729 9.3667 19.0047 9.3667C19.2983 9.3667 19.5221 9.46338 19.6761 9.65674C19.8336 9.84652 19.9124 10.124 19.9124 10.4893H21.4861C21.4861 10.0202 21.3841 9.60124 21.18 9.23243C20.9795 8.86361 20.6912 8.57894 20.3152 8.37842C19.9393 8.17432 19.5114 8.07227 19.0316 8.07227C18.3118 8.07227 17.7174 8.27279 17.2484 8.67383C16.7829 9.07129 16.5501 9.59945 16.5501 10.2583C16.5501 10.8026 16.722 11.2752 17.0657 11.6763C17.4095 12.0737 17.9734 12.4211 18.7576 12.7183C19.1873 12.8937 19.4827 13.0638 19.6439 13.2285Z"
			fill="currentColor"
		/>
		<path
			d="M7.17798 10.8652L8.23071 8.17969H10.0408L8.20386 12.0576L10.0837 16H8.2522L7.17798 13.2661L6.10913 16H4.27759L6.15747 12.0576L4.31519 8.17969H6.12525L7.17798 10.8652Z"
			fill="currentColor"
		/>
		<path
			d="M12.6831 14.6895H15.4546V16H11.104V8.17969H12.6831V14.6895Z"
			fill="currentColor"
		/>
	</svg>
)

const RejectBookingIcon = ({ width = 20, height = 20 }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.7928 14.2071L10 11.4142L7.20715 14.2071L5.79294 12.7928L8.58579 10L5.79285 7.20706L7.20706 5.79285L10 8.58579L12.7929 5.79285L14.2072 7.20706L11.4142 10L14.2071 12.7928L12.7928 14.2071Z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10Z"
			fill="currentColor"
		/>
	</svg>
)

export {
	AcceptBookingIcon,
	RejectBookingIcon,
	EditBookingIcon,
	DownloadBookingIcon,
}
