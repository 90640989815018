import React, { useMemo } from 'react'
import {
	DisplayIssueType,
	DisplayIssuesCampaign,
} from 'src/components/_moderator/types/ModerationRequestTypes'

import { AdvType, NotificationModel, NotifyItem } from './IssuesContent'

export type IssueObjectType = DisplayIssueType & NotifyItem

type CampaignIssueWrapperProps = {
	issue: DisplayIssuesCampaign
	channels: {
		id: number
		name: string
	}[]
	setNotifyItem: React.Dispatch<
		React.SetStateAction<NotificationModel | undefined>
	>
	WrappedComponent: React.ElementType<{
		issueObject: {
			[key in AdvType]: Array<IssueObjectType>
		}
		totalIssuesCount: number
		setNotifyItem: (notifyItem: NotifyItem) => void
		issue: DisplayIssuesCampaign
	}>
}

export const CampaignIssueWrapper = ({
	issue,
	channels,
	setNotifyItem,
	WrappedComponent,
}: CampaignIssueWrapperProps) => {
	const {
		adstream,
		booking,
		district_booking,
		internet_vk,
		internet_yandex,
		public_transport,
	} = { ...issue }

	const handleSetNotifyItem = (notifyItem: NotifyItem) => {
		setNotifyItem({
			...notifyItem,
			companyName: issue.name,
			campaignId: issue.id,
		})
	}

	const getIssueObject = (): {
		[key in AdvType]: Array<IssueObjectType>
	} => {
		const internetProblems: Array<IssueObjectType> = []
		if (internet_vk.is_selected && internet_vk.problem) {
			internetProblems.push({
				name: 'VK Реклама',
				problem: internet_vk.problem,
				type: 'vk',
				id: internet_vk.id,
			})
		}

		if (internet_yandex.is_selected && internet_yandex.problem) {
			internetProblems.push({
				name: 'Яндекс.Директ',
				problem: internet_yandex.problem,
				type: 'yandex',
				id: internet_yandex.id,
			})
		}

		const tvProblems: Array<IssueObjectType> = []
		if (adstream.is_selected && adstream.problem) {
			tvProblems.push({
				name: adstream.selection_ids
					.map(
						(id) => channels.find((el) => el.id === id)?.name || id
					)
					.join(', '),
				problem: adstream.problem,
				type: 'adstream',
				id: adstream.id,
			})
		}
		const publicTransportProblems: Array<IssueObjectType> = []
		if (
			public_transport &&
			public_transport?.is_selected &&
			public_transport.problem
		) {
			publicTransportProblems.push({
				id: public_transport.id,
				name: 'Цифровые экраны на МЦК',
				problem: public_transport.problem,
				type: 'public_transport',
			})
		}
		const issueObject: { [key in AdvType]: Array<IssueObjectType> } = {
			'outdoor-garant': booking
				// .filter((el) => el.door_type === 'outdoor' && el.problem)
				.filter((el) => el.door_type === 'outdoor')
				.map((el) => {
					return {
						name: el.name,
						problem: el.problem,
						placeName: el.place_name || '',
						id: el.id,
						type: 'outdoor',
					}
				}),
			'outdoor-mass': district_booking
				.filter((el) => el.problem)
				.map((el) => {
					return {
						name: el?.city_name || '',
						problem: el.problem,
						id: el.id,
						type: 'districtbooking',
					}
				}),
			indoor: booking
				// .filter((el) => el.door_type === 'indoor' && el.problem)
				.filter((el) => el.door_type === 'indoor')
				.map((el) => {
					return {
						name: el.name,
						problem: el.problem,
						placeName: el.place_name || '',
						id: el.id,
						type: 'indoor',
					}
				}),
			internet: internetProblems,
			tv: tvProblems,
			public_transport: publicTransportProblems,
		}

		for (const key in issueObject) {
			if (issueObject[key].length === 0) {
				delete issueObject[key]
			}
		}
		return issueObject
	}

	const issueObject = useMemo(getIssueObject, [issue, channels])

	const totalIssuesCount = Object.values(issueObject).reduce(
		(acc, arr) => acc + arr.length,
		0
	)

	return (
		<WrappedComponent
			issue={issue}
			setNotifyItem={handleSetNotifyItem}
			issueObject={issueObject}
			totalIssuesCount={totalIssuesCount}
		/>
	)
}
