import React, { FC, useEffect, useRef, useState } from 'react'
import s from './ProgressFileInput.module.scss'
import authProgressFetch from '../../utils/authProgressFetch'
import { motion } from 'framer-motion'
import { Button } from 'react-bootstrap'
import ImageFullScreenWrapper from './ImageFullScreenWrapper'
import { spreadArray } from '../_provider/Mediaformats/MediaInfo'
import { PlaceholderPDF, PlaceholderVIDEO } from '../../utils/MediaPlaceHolders'
import cn from 'classnames'
import { useTypewriter } from 'src/utils/useTypewriter'
import { useConfirm } from './ConfirmModalProvider'

export type ObjectValue = {
	src: string | null
	name: string
}

export interface IProgressFileInput {
	onLoadedMessage?: string
	initialValue: ObjectValue
	description?: string
	allowedExtension?: string[]
	maxSizeMb?: number
	updateUrl: string
	removeUrl?: string
	updateMethod?: 'PATCH' | 'POST'
	removeMethod?: 'PATCH' | 'DELETE'
	nameInBody: string
	parentError?: string
	disabled?: boolean
	noPadding?: boolean
	onFileLoad?: (any) => void
	onFileDelete?: () => void
	additionalBody?: Object
	onlyView?: boolean
	setIsLoading?: () => void
	loadingInProgress?: boolean
	style?: React.CSSProperties
	isDescription?: boolean
	getConditionBodyField?: (fileName: string) => 'image' | 'video' | 'unknown'
	ExtraButton?: React.ReactNode
	isDeleteConfirm?: boolean
}

const ProgressFileInput: FC<IProgressFileInput> = ({
	allowedExtension = [],
	updateMethod = 'PATCH',
	removeMethod = 'DELETE',
	additionalBody = {},
	onlyView = false,
	noPadding,
	loadingInProgress = false,
	isDescription = true,
	isDeleteConfirm = true,
	...props
}) => {
	const displayText = useTypewriter('...', 1000)

	const [hovered, setHovered] = useState(false)
	const { confirm } = useConfirm()
	const [highlight, setHighLight] = useState(false)
	const [progress, setProgress] = useState<number>(0)
	const [fileSize, setFileSize] = useState<number>(0)
	const [fileName, setFileName] = useState('')
	const [fileDimensions, setFileDimensions] = useState<null | string>(null)
	const [loadingState, setLoadingState] = useState<
		null | 'loading' | 'loaded'
	>(null)
	const [fileError, setFileError] = useState('')
	const [canInteract, setCanInteract] = useState(true)
	const AbortControllerRef = useRef<null | AbortController>(null)

	useEffect(() => {
		if (!props.initialValue?.src) return setFileDimensions('')
		const ext = GetFileType(props.initialValue.src)
		if (ext === 'IMAGE') {
			const img = new Image()
			img.src = props.initialValue.src
			setFileDimensions('Рассчитываем размеры...')
			img.onload = () => {
				setFileDimensions(`${img.width} x ${img.height} px`)
			}
		} else {
			setFileDimensions('')
		}
	}, [props.initialValue.src])

	function onDragEnter(e) {
		preventEvent(e)
		setHovered(true)
		setHighLight(true)
	}

	function onDragLeave(e) {
		preventEvent(e)
		setHovered(false)
		setHighLight(false)
	}

	function onDragOver(e) {
		e.preventDefault()
	}

	async function onDrop(e) {
		setHovered(false)
		preventEvent(e)
		setHighLight(false)
		if (!showEmptyScreen) return
		const file = e.dataTransfer.files[0]
		if (file) {
			if (Validate(file)) {
				await LoadFile(file)
			}
		}
	}

	function preventEvent(e) {
		e.stopPropagation()
		e.preventDefault()
	}

	async function handleInputChange(e) {
		const file = e.target.files?.[0]
		if (file && Validate(file)) {
			await LoadFile(file)
		}
	}

	async function LoadFile(file) {
		props.setIsLoading && props.setIsLoading()

		const body: any = { ...additionalBody }
		if (props.getConditionBodyField) {
			const fileType = props.getConditionBodyField(file?.name || '')
			if (fileType !== 'unknown') {
				body[fileType] = file
			}
		} else {
			body[props.nameInBody] = file
		}

		setFileName(file.name)
		AbortControllerRef.current = new AbortController()
		const signal = AbortControllerRef.current?.signal
		const error: {} = await authProgressFetch({
			url: props.updateUrl,
			method: updateMethod,
			body,
			onProgress: ({ percent, total }) => {
				setLoadingState('loading')
				setFileSize(total)
				setProgress(percent)
				if (percent === 100) {
					setLoadingState('loaded')
				}
			},
			onLoad: (res) => {
				if (props?.onFileLoad) props.onFileLoad(res)
			},
			signal,
		})
		if (error) {
			setLoadingState(null)
			setFileError(Object.values(error)?.[0] as string)
		}
	}

	const handleDelete = async () => {
		setCanInteract(false)
		const body: any = {}
		body[props.nameInBody] = ''
		await authProgressFetch({
			url: props.removeUrl || props.updateUrl,
			method: removeMethod,
			body,
			onLoad: async () => {
				setLoadingState(null)
				if (props.onFileDelete) await props.onFileDelete()
				setCanInteract(true)
			},
		})
	}
	async function DeleteFile() {
		if (!isDeleteConfirm) {
			handleDelete()
		} else {
			const answ = await confirm({
				text: 'Файл будет безвозвратно удален',
				title: 'Удалить материал?',
				closeButton: true,
				acceptText: 'Да, удалить',
				declineText: 'Нет, отменить',
				acceptVariant: 'danger',
			})

			if (answ) {
				handleDelete()
			}
		}
	}

	function Validate(file) {
		if (!ValidateExtension(file, allowedExtension)) {
			setFileError('Некорректный формат файла')
			return false
		}
		if (props.maxSizeMb && file.size > props.maxSizeMb * 2 ** 20) {
			setFileError(
				`Размер файла больше допустимых (${props.maxSizeMb} MБ)`
			)
			return false
		}
		return true
	}

	const showLoadingScreen = loadingState === 'loading'
	const isSrc =
		typeof props.initialValue.src === 'string' &&
		props.initialValue.src.length > 0
	const showEmptyScreen =
		!isSrc && loadingState === null && !fileError && !loadingInProgress
	const showFileError = fileError
	const showCurrentValue =
		!fileError &&
		(loadingState === 'loaded' || loadingState === null) &&
		isSrc

	return (
		<div className={s.container}>
			<div
				className={cn(s.dropZone, { [s.noPadding]: noPadding })}
				data-highlight={showEmptyScreen && highlight}
				data-error={fileError !== '' || props.parentError}
				data-loaded={props.initialValue?.src && !props.parentError}
				onDragOver={onDragOver}
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onDrop={onDrop}
				style={onlyView ? { border: 'none', ...props.style } : {}}
			>
				{showEmptyScreen && (
					<div className={s.noValue} onDrop={preventEvent}>
						{!hovered && (
							<>
								<label>
									Выберите файл
									<input
										type={'file'}
										onChange={handleInputChange}
										disabled={props.disabled}
										accept={
											allowedExtension
												? spreadArray(
														allowedExtension.map(
															(ext) =>
																'.' +
																ext.toLowerCase()
														),
														','
												  )
												: '*'
										}
									/>
								</label>
								<span>или перетащите его сюда</span>
							</>
						)}
					</div>
				)}
				{showLoadingScreen && (
					<div className={s.loadingContainer}>
						<div className={s.imageContainer}>
							<RenderLoadingPlaceholder filename={fileName} />
						</div>
						<div className={s.data}>
							<div className={s.filename}>{fileName}</div>
							<div className={s.bar}>
								<motion.div
									className={s.blue}
									animate={{ width: `${progress}%` }}
								></motion.div>
							</div>
							<div className={s.info}>
								<div>{Math.floor(progress)}%</div>
								<div>{formatSize(fileSize)}</div>
							</div>
						</div>
						<div
							className={s.cancel}
							onClick={() => {
								AbortControllerRef.current?.abort()
								setLoadingState(null)
							}}
						>
							<i className={'bi bi-x'}></i>
						</div>
					</div>
				)}
				{showCurrentValue && (
					<div className={s.value}>
						<RenderPreview value={props.initialValue} />

						{isDescription && (
							<div className={s.data}>
								<div className={s.name}>
									{props.initialValue.name || 'Текущий файл'}
								</div>
								<div className={s.sizes}>
									{fileDimensions || ''}
								</div>
							</div>
						)}

						<div className={s.icoButtons}>
							{props?.ExtraButton && props?.ExtraButton}
							{!onlyView && (
								<div
									className={s.delete}
									onClick={() => {
										if (!canInteract) return
										DeleteFile()
									}}
								>
									<svg
										width="12"
										height="14"
										viewBox="0 0 12 14"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M2.66602 5V12.3333H9.33268V5H10.666V13C10.666 13.3682 10.3675 13.6667 9.99935 13.6667H1.99935C1.63116 13.6667 1.33268 13.3682 1.33268 13V5H2.66602ZM5.33268 5V11H3.99935V5H5.33268ZM7.99935 5V11H6.66602V5H7.99935ZM7.99935 0.333328C8.2863 0.333328 8.54106 0.516948 8.6318 0.789176L9.14601 2.33266L11.3327 2.33333V3.66666H0.666016V2.33333L2.85202 2.33266L3.36689 0.789176C3.45764 0.516948 3.7124 0.333328 3.99935 0.333328H7.99935ZM7.51884 1.66666H4.47986L4.25735 2.33266H7.74068L7.51884 1.66666Z"
											fill="#6C757D"
										/>
									</svg>
								</div>
							)}
						</div>
					</div>
				)}
				{showFileError && (
					<div
						className={
							'd-flex justify-content-between align-items-center'
						}
					>
						<div style={{ color: '#DC3545' }}>
							{fileError || props.parentError}
						</div>
						<Button
							className={s.cancelButton}
							variant={'light'}
							onClick={() => setFileError('')}
						>
							Отмена
						</Button>
					</div>
				)}
				{!showEmptyScreen &&
					!showLoadingScreen &&
					!showCurrentValue &&
					!showFileError && (
						<div
							className={
								'text-secondary d-flex justify-content-center align-items-center w-100'
							}
						>
							{`Загрузка${displayText}`}
						</div>
					)}
			</div>
			{/* {props.description && !props.parentError && (
				<div className={s.description}>{props.description}</div>
			)} */}
			{props.initialValue?.src && !props.parentError && !onlyView ? (
				<div className={`${s.description} ${s.loaded}`}>
					{props.onLoadedMessage || 'Материал загружен'}
				</div>
			) : (
				props.description && (
					<div className={s.description}>{props.description}</div>
				)
			)}
			{props.parentError && (
				<div
					className={s.description}
					style={{ color: '#dc3545', fontSize: '14px' }}
				>
					{props.parentError}
				</div>
			)}
		</div>
	)
}

export default ProgressFileInput

const ValidateExtension = (
	file_extension: File,
	allowedExtension: string[]
): boolean => {
	if (allowedExtension.length === 0) return true

	if (
		allowedExtension?.includes('JPEG') ||
		allowedExtension?.includes('jpeg')
	)
		allowedExtension?.push('jpg')
	allowedExtension = allowedExtension.map((ext) => ext.toUpperCase())
	const fileExtension = file_extension.name.split('.').pop()
	if (!fileExtension) return false
	return allowedExtension.includes(fileExtension.toUpperCase())
}

function formatSize(length) {
	let i = 0,
		type = ['б', 'Кб', 'Мб', 'Гб', 'Тб', 'Пб']
	while ((length / 1000) | 0 && i < type.length - 1) {
		length /= 1024
		i++
	}
	return length.toFixed(2) + ' ' + type[i]
}
//TODO расширить функицию для .doc типа
const GetFileType = (url): 'PDF' | 'IMAGE' | 'VIDEO' | 'UNKNOWN' => {
	if (!url) return 'UNKNOWN'
	const PDF = 'pdf',
		VIDEO = ['mp4', 'mov', 'avi', 'mpeg4'],
		IMAGE = ['png', 'jpeg', 'jpg', 'svg']
	const extension = url?.split('.')?.pop().toLowerCase()
	const isPDF = extension === PDF
	const isImage = IMAGE.find((e) => e === extension)
	const isVideo = VIDEO.find((e) => e === extension)
	if (isPDF) return 'PDF'
	if (isImage) return 'IMAGE'
	if (isVideo) return 'VIDEO'
	return 'UNKNOWN'
}

export const RenderPreview = ({
	value,
	style,
	type,
}: {
	value: ObjectValue
	style?: React.CSSProperties
	type?: 'image'
}) => {
	const ext = GetFileType(value.src)
	if (ext === 'IMAGE' || type === 'image')
		return (
			<div className={s.imageContainer}>
				<ImageFullScreenWrapper>
					<img style={style} src={value.src || ''} alt={value.name} />
				</ImageFullScreenWrapper>
			</div>
		)
	if (ext === 'VIDEO')
		return <PlaceholderVIDEO style={style} link={value.src} />
	if (ext === 'PDF') return <PlaceholderPDF link={value.src} />
	return <div></div>
}

const RenderLoadingPlaceholder = ({ filename }: { filename: string }) => {
	const ext = GetFileType(filename)
	if (ext === 'IMAGE')
		return (
			<svg
				width="20"
				height="16"
				viewBox="0 0 20 16"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8Z"
					fill="#6C757D"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0 1C0 0.447715 0.447715 0 1 0H19C19.5523 0 20 0.447715 20 1V15C20 15.5523 19.5523 16 19 16H1C0.447715 16 0 15.5523 0 15V1ZM2 2V14H4.5858L10.2929 8.29289C10.6834 7.90237 11.3166 7.90237 11.7071 8.29289L13 9.58579L18 4.5858V2H2ZM18 14H7.41423L11 10.4142L12.2929 11.7071C12.6834 12.0976 13.3166 12.0976 13.7071 11.7071L18 7.41423V14Z"
					fill="#6C757D"
				/>
			</svg>
		)
	if (ext === 'VIDEO')
		return (
			<svg
				width="20"
				height="19"
				viewBox="0 0 20 19"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16 9C16 8.44783 15.5525 8.0002 15.0004 8C15.6281 7.16434 16 6.12561 16 5C16 2.23858 13.7614 0 11 0C9.00395 0 7.28109 1.16963 6.47938 2.8609C5.79779 2.32182 4.93648 2 4 2C1.79086 2 0 3.79086 0 6C0 6.76544 0.215003 7.48067 0.587911 8.08859C0.241198 8.2456 0 8.59463 0 9V18C0 18.5523 0.447715 19 1 19H15C15.5523 19 16 18.5523 16 18V17.1815L18.4405 18.8288C18.7471 19.0358 19.1428 19.0566 19.4693 18.883C19.7959 18.7095 20 18.3698 20 18V9C20 8.63018 19.7959 8.29054 19.4693 8.11697C19.1428 7.94341 18.7471 7.96425 18.4405 8.17115L16 9.81851V9ZM11 2C9.34315 2 8 3.34315 8 5C8 6.65685 9.34315 8 11 8C12.6569 8 14 6.65685 14 5C14 3.34315 12.6569 2 11 2ZM16 12.2315V14.7685L18 16.1185V10.8815L16 12.2315ZM14 10H2V17H14V10ZM2 6C2 4.89543 2.89543 4 4 4C5.10457 4 6 4.89543 6 6C6 7.10457 5.10457 8 4 8C2.89543 8 2 7.10457 2 6Z"
					fill="#6C757D"
				/>
			</svg>
		)

	if (ext === 'PDF')
		return (
			<svg
				width="16"
				height="20"
				viewBox="0 0 16 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M16 6V19C16 19.5523 15.5523 20 15 20H1C0.447715 20 0 19.5523 0 19V1C0 0.447715 0.447715 0 1 0H10C10.2652 0 10.5196 0.105357 10.7071 0.292893L15.7071 5.29289C15.8946 5.48043 16 5.73478 16 6ZM8 2H2V18H14V8H9C8.44771 8 8 7.55228 8 7V2ZM10 2.41421V6H13.5858L10 2.41421Z"
					fill="#6C757D"
				/>
			</svg>
		)

	return (
		<svg
			width="20"
			height="16"
			viewBox="0 0 20 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8Z"
				fill="#6C757D"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0 1C0 0.447715 0.447715 0 1 0H19C19.5523 0 20 0.447715 20 1V15C20 15.5523 19.5523 16 19 16H1C0.447715 16 0 15.5523 0 15V1ZM2 2V14H4.5858L10.2929 8.29289C10.6834 7.90237 11.3166 7.90237 11.7071 8.29289L13 9.58579L18 4.5858V2H2ZM18 14H7.41423L11 10.4142L12.2929 11.7071C12.6834 12.0976 13.3166 12.0976 13.7071 11.7071L18 7.41423V14Z"
				fill="#6C757D"
			/>
		</svg>
	)
}
