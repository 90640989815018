import { motion } from 'framer-motion'
import React from 'react'
import useResponsive from 'src/utils/useResponsive'
import s from './template-iframe.module.scss'
import cn from 'classnames'

type TemplateIframePropsType = {
	url: string
}

export const TemplateIframe = ({ url }: TemplateIframePropsType) => {
	const { isMobile } = useResponsive()

	return (
		<motion.div
			className={cn(s.templateIframeContainer)}
			initial={{ opacity: 0, y: 8 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 20 }}
		>
			<iframe
				className={cn(s.templateIframe, {
					[s.templateIframe_mobile]: isMobile,
				})}
				title="Шаблон"
				aria-label="Шаблон"
				src={url}
			>
				Ваш браузер не поддерживает плавающие фреймы
			</iframe>
			{/* <button onClick={onClose}>Close</button> */}
		</motion.div>
	)
}
