import React, { FC, ChangeEvent, useState } from 'react'
import InputMask from 'react-input-mask'
import { Form } from 'react-bootstrap'
import { ReactComponent as CalendarIcon } from 'src/assets/images/calendar.svg'

interface DateInputProps {
	type: string
	pendingValue: string
	handleChange: (value: string) => void
	disabled?: boolean
	error?: string
	onIconClick: () => void
}

export const DateInput: FC<DateInputProps> = ({
	type,
	pendingValue,
	handleChange,
	disabled = false,
	error,
	onIconClick,
}) => {
	const [mask, setMask] = useState(pendingValue ? '99.99.9999' : '')
	const id =
		type === 'start'
			? 'start-picker'
			: type === 'end'
			? 'end-picker'
			: 'dropdown-picker'
	const placeholder =
		type === 'start' ? 'Начало' : type === 'end' ? 'Окончание' : ''

	const handleFocus = () => {
		setMask('99.99.9999')
	}
	const handleBlur = () => {
		if (!pendingValue) {
			setMask('')
		}
	}
	return (
		<div>
			<InputMask
				id={id}
				placeholder={placeholder}
				mask={mask}
				value={pendingValue}
				onChange={(e: ChangeEvent<HTMLInputElement>) => {
					handleChange(e.target.value)
				}}
				disabled={disabled}
			>
				{(inputProps: any) => (
					<div style={{ position: 'relative' }}>
						<Form.Control
							onFocus={handleFocus}
							onBlur={handleBlur}
							size="lg"
							type="text"
							isInvalid={!!error}
							disabled={disabled}
							{...inputProps}
							style={{
								fontSize: '16px',
								fontWeight: 400,
								lineHeight: '24px',
								textAlign: 'left',
								backgroundImage: 'none',
							}}
						/>
						<CalendarIcon
							onClick={onIconClick}
							style={{
								position: 'absolute',
								cursor: 'pointer',
								top: '50%',
								right: '12px',
								transform: 'translateY(-50%)',
								fill: '#6C757D',
							}}
						/>
					</div>
				)}
			</InputMask>
		</div>
	)
}
