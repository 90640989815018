import React, { useEffect, useState } from 'react'
import { Placeholder } from 'react-bootstrap'
import FileInput from 'src/components/UtilityComponents/FileInput'
import { ObjectValue } from 'src/components/UtilityComponents/ProgressFileInput'
import useCampaignData from 'src/stores/campaignData'

type DocType = ObjectValue & { id: string }
type Props = {
	bookingItemsIds: number[]
	onDocsChanged: (isChanged: boolean) => void
	setIsLoading: (value: boolean) => void
}

export const MassDocsUpload = ({
	bookingItemsIds,
	onDocsChanged,
	setIsLoading,
}: Props) => {
	const [campaign, campaignInterface] = useCampaignData()
	const campaignId = campaign.id

	const [docsIsLoading, setDocsIsLoading] = useState(false)
	const [docs, setDocs] = useState<DocType[]>([])

	const fetchList = async () => {
		setDocsIsLoading(true)
		const result = await campaignInterface.fetchCampaignDocsList(campaignId)

		const filredDocs = result.filter(
			(el) => el.booking === bookingItemsIds[0]
		)
		const docs = filredDocs.map((el) => ({
			src: el.file,
			name: el.file_name,
			id: el.id.toString(),
		}))
		setDocs(docs)

		setDocsIsLoading(false)
	}
	useEffect(() => {
		fetchList()
	}, [])

	// const handleDeleteDoc = async (docId: string) => {
	// 	if (!docId.startsWith(uniqLocalDocIdStarter)) {
	// 		const id = Number(docId)

	// 		if (!isNaN(id)) {
	// 			setIsLoading(true)
	// 			await campaignInterface.deleteDocById(id)
	// 			setIsLoading(false)
	// 		}
	// 	}

	// 	const newDocs = docs.filter((el) => el.id !== docId)
	// 	if (newDocs.length === 0) {
	// 		onDocsChanged(false)
	// 	}
	// 	setDocs(newDocs)
	// }
	const handleAddDoc = async (doc: File) => {
		setIsLoading(true)
		setDocsIsLoading(true)

		for await (const booking of bookingItemsIds) {
			await campaignInterface.addDocInAdvFormat({
				advFormat: 'booking',
				advItemId: booking,
				campaignId: campaignId,
				file: doc,
			})
		}
		await fetchList()

		setDocsIsLoading(false)
		setIsLoading(false)
		onDocsChanged(true)
	}

	return (
		<div>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '10px',
				}}
			>
				{!!docs.length &&
					docs.map((el) => (
						<FileInput
							key={el.id}
							onUpload={handleAddDoc}
							value={{ src: el.src, name: el.name }}
							onRemove={null}
							size={20}
							allowedExtensions={['pdf']}
							descr="Документ загружен"
						/>
					))}
				{docsIsLoading ? (
					<DocsPreview />
				) : (
					<FileInput
						key={`doc_input_${docs.length}`}
						onUpload={handleAddDoc}
						value={{ src: '', name: '' }}
						onRemove={null}
						size={20}
						allowedExtensions={['pdf']}
						descr="Документ PDF формата. Размер - не более 20 МБ"
					/>
				)}
			</div>
		</div>
	)
}

export const DocsPreview = (props: any) => {
	return (
		<Placeholder
			animation="glow"
			className="d-flex gap-2"
			style={{
				border: '1px dashed #CED4DA',
				padding: '10px 16px',
				borderRadius: '5px',
			}}
		>
			<Placeholder
				style={{ borderRadius: '12px', width: '80px', height: '80px' }}
			/>
			<div className="d-flex flex-column gap-2 align-self-center">
				<Placeholder style={{ width: '120px', height: '12px' }} />
			</div>
		</Placeholder>
	)
}
