import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { authFetch } from 'src/utils/authFetch'
import { Loader } from 'src/components/_provider/Bookings/content/list/Loader'
import { downloadFileByLink } from 'src/utils'
import { ReportDocument } from './ReportDocument'
import downloadBlob from 'src/utils/downloadBlob'
type DocumentsModalProps = {
	open: boolean
	campaignId: string | undefined
	onClose: () => void
}
type FileType = 'upd_online' | 'upd_offline' | 'spec'
export type ReportDocType = {
	id: string
	file: string
	campaign_id: string
	file_type: FileType
}

export const getFileTypeTitle = (fileType: FileType) => {
	if (fileType === 'upd_online') return 'Онлайн размещение'
	if (fileType === 'upd_offline') return 'Оффлайн размещение'
	if (fileType === 'spec') return 'Спецификация'
}
async function fetchFile(url) {
	const response = await fetch(url)
	if (!response.ok) {
		throw new Error(`Failed to fetch ${url}`)
	}
	return await response.blob()
}
export const DocumentsModal = ({
	campaignId,
	open,
	onClose,
}: DocumentsModalProps) => {
	const [isLoading, setIsLoading] = useState(true)

	const [documents, setDocuments] = useState<
		Array<ReportDocType> | undefined
	>(undefined)

	const downloadDoc = async (doc: ReportDocType) => {
		try {
			const fileExtension = doc?.file?.split('.')?.pop() as string
			const blobData = await fetchFile(doc.file)

			downloadBlob(blobData, `1C_${doc?.id}`, fileExtension)
		} catch {}
	}

	const fetchCampaignDocuments = async () => {
		try {
			const result: Array<ReportDocType> = await authFetch({
				url: `/core/api/v1/adv_companies/${campaignId}/report_docs/`,
				method: 'GET',
			})
			setDocuments(result)
		} catch (e) {
		} finally {
			setIsLoading(false)
		}
	}

	const handleDownloadAll = async () => {
		const result: any = await authFetch({
			url: `/core/api/v1/ones/${campaignId}/zip/`,
			method: 'GET',
			raw: true,
		})

		const blobData = await result.blob()

		downloadBlob(blobData, `docs`, 'zip')
	}

	console.log(documents)

	useEffect(() => {
		fetchCampaignDocuments()
	}, [campaignId])
	return (
		<Modal
			show={open}
			onHide={onClose}
			centered
			size={'sm'}
			animation={true}
		>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} className={'ms-2'}>
					Выгрузка документов
				</Modal.Title>
			</Modal.Header>

			<Modal.Body className={'p-4'}>
				<div
					style={{
						display: 'flex',
						flexWrap: 'wrap',
						justifyContent: 'center',
						gap: '24px',
						alignItems: 'center',
					}}
				>
					{isLoading && <Loader />}
					{documents && (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								width: '100%',
							}}
						>
							{documents.map((item) => (
								<ReportDocument
									key={item.id}
									downloadDoc={downloadDoc}
									item={item}
								/>
							))}
						</div>
					)}
				</div>
			</Modal.Body>

			<Modal.Footer>
				<Button
					onClick={handleDownloadAll}
					style={{ borderRadius: '4px' }}
					variant="primary"
				>
					Выгрузить все
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
