import React from 'react'
import { ReactComponent as MCR } from 'src/assets/images/railway-2.svg'
import s from './mcr.module.scss'
import { MoscowCentRingControls } from './MoscowCentRingControls'
type Props = {}
export const MoscowCentRing = ({}: Props) => {
	return (
		<div className={s.ourdoorMassCoverage}>
			<div className={s.ourdoorMassCoverageHeader}>
				<div className={s.ourdoorMassCoverageHeader_primary}>
					<h5>Цифровые экраны на МЦК</h5>
					<MCR />
				</div>
			</div>
			<p className="m-0">
				Равномерное распределение показов по всем 64 поверхностям на 30
				станциях. Выбор станций прорабатывается оператором рекламных
				экранов и будет доступен позже.
			</p>

			<MoscowCentRingControls />
		</div>
	)
}
