import React from 'react'
import { useHistory } from 'react-router-dom'
import s from './headerChat.module.scss'

export const ChatPage = (props: any) => {
	const history = useHistory()

	return (
		<div className={s.pageContainer}>
			<header>
				<div className={s.goBack} onClick={() => history.goBack()}>
					<i className="bi bi-arrow-left" />
				</div>
				<span>Чат поддержки</span>
			</header>

			<section>
				<iframe
					width="100%"
					height="100%"
					src="https://jivo.chat/O816map4hs"
				></iframe>
			</section>
		</div>
	)
}
