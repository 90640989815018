import React, { FC, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useBookingsContext } from '../../context/BookingsProvider'
import useProviderData from '../../../../../stores/providerData'
import { authFetch } from '../../../../../utils/authFetch'
import { providerBookingInfo } from '../../../../../constants/api'
import { BookingDetails } from '../../../../../stores/ProviderTypes.types'
import BookingsDetailsLayout from './BookingsDetailsLayout'

interface IBookingDetailsController {
	children?: React.ReactNode | React.ReactNode[]
	onModalClose: () => void
}

const BookingDetailsController: FC<IBookingDetailsController> = ({
	onModalClose,
}) => {
	const { selectedBooking } = useBookingsContext()
	const [provider, providerInterface] = useProviderData()
	const [bookingInfo, setBookingInfo] = useState<BookingDetails | null>(null)
	const [isLoading, setIsLoading] = useState(false)

	//on change selectedBooking
	useEffect(() => {
		;(async () => {
			if (provider?.bookings?.results && selectedBooking) {
				if (
					provider?.bookings?.results.find(
						(el) => el.id === selectedBooking
					)
				) {
					setIsLoading(true)
					try {
						const res = await authFetch<BookingDetails>({
							url: providerBookingInfo.params(selectedBooking),
							method: 'GET',
						})
						setBookingInfo(res)
						setIsLoading(false)
					} catch (e) {
						console.log(e)
						setIsLoading(false)
					}
				}
			}
		})()
	}, [selectedBooking, provider?.bookings?.results])

	if (isLoading) return <Loader />
	if (!selectedBooking || !bookingInfo) return null
	return (
		<BookingsDetailsLayout
			onModalClose={onModalClose}
			booking={bookingInfo}
		/>
	)
}

const Loader = () => (
	<div
		className={
			'd-grid h-100 w-100 align-items-center justify-content-center'
		}
	>
		<Spinner animation={'border'} variant={'primary'} />
	</div>
)

export default BookingDetailsController
