import React, { useContext, useState } from 'react'
import useResponsive from 'src/utils/useResponsive'
import PlacementInfo, { ControlsButtons } from '../../PlacementInfo'
import useProviderData from 'src/stores/providerData'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { useConfirm } from 'src/components/UtilityComponents/ConfirmModalProvider'
import { PlacementsListContent } from './PlacementsListContent'
import BottomSheet from 'src/components/UtilityComponents/Bottomsheet'
import ModalPlacementDisable from '../../ModalPlacementDisable'

type PlacementsContentProps = {
	limit: number
	offset: number
	setPaginationModel: React.Dispatch<
		React.SetStateAction<{
			limit: number
			offset: number
		}>
	>
	total: number
	onEdit: (itemId?: string) => void
	updatePlacementsInfo: () => Promise<void>
}

export const PlacementsContent = (props: PlacementsContentProps) => {
	const handleLimitChange = (limit: number) => {
		props.setPaginationModel((prev) => ({ ...prev, limit: limit }))
	}
	const handleOffsetChange = (offset: number) => {
		props.setPaginationModel((prev) => ({ ...prev, offset: offset }))
	}

	const [provider, providerInterface] = useProviderData()
	const { addToast } = useContext(ToastsContext)
	const { confirm } = useConfirm()

	const [disableModal, setDisableModal] = useState<boolean | string>(false)

	const [selectedItem, setSelectedItem] = useState<number | undefined>(
		undefined
	)
	const [bottomModal, setBottomModal] = useState<boolean>(false)

	async function handleToArchive(id) {
		setBottomModal(false)
		setSelectedItem(undefined)
		addToast({
			text: 'Рекламный носитель перенесен в архив.',
			type: 'secondary',
		})
		await providerInterface.ArchivePlacement(id)
		await props.updatePlacementsInfo()
	}
	async function handleFromArchive(id) {
		setBottomModal(false)
		setSelectedItem(undefined)
		addToast({
			text: 'Рекламный носитель восстановлен',
			type: 'success',
		})
		await providerInterface.RestorePlacement(id)
		await props.updatePlacementsInfo()
	}

	async function handleDelete(id) {
		const answer = await confirm({
			text: 'Рекламный носитель будет удален без возможности восстановления.',
			title: 'Удалить навсегда?',
			closeButton: true,
			acceptText: 'Удалить',
			declineText: 'Отменить',
		})
		if (answer) {
			setBottomModal(false)
			setSelectedItem(undefined)
			await providerInterface.DeletePlacement(id)
			await props.updatePlacementsInfo()
			addToast({
				text: 'Рекламный носитель удален',
				type: 'danger',
			})
		}
	}

	async function handleDisable(id) {
		if (bottomModal) setBottomModal(false)
		setDisableModal(id)
	}
	async function handleEnable(id) {
		const newID = await providerInterface.EnablePlacement(id)
		await props.updatePlacementsInfo()
		if (newID) setSelectedItem(newID)
	}
	const handleEdit = (itemId?: string) => {
		setBottomModal(false)
		props.onEdit(itemId)
	}

	const { isDesktop } = useResponsive()
	return (
		<div
			style={{
				maxHeight: '100%',
				height: '100%',
				maxWidth: '100%',
				width: '100%',
				display: 'flex',
				flexDirection: 'row',
				overflow: 'hidden',
			}}
		>
			<PlacementsListContent
				{...props}
				onCreate={handleEdit}
				setLimit={handleLimitChange}
				setOffset={handleOffsetChange}
				onDisable={handleDisable}
				onEnable={handleEnable}
				onDelete={handleDelete}
				onArchive={handleToArchive}
				onFromArchive={handleFromArchive}
				selectedItem={selectedItem}
				setBottomModal={setBottomModal}
				setSelectedItem={setSelectedItem}
			/>

			{isDesktop && (
				<div
					id={'info'}
					style={{
						maxWidth: '50%',
						height: '100%',
						width: '50%',
						display: 'flex',
						flexDirection: 'column',
						borderTop: '1px solid #CED4DA',
					}}
				>
					<PlacementInfo
						isTablet={!isDesktop}
						item={provider?.placements?.results?.find(
							(e) =>
								e?.id?.toString() === selectedItem?.toString()
						)}
						onEdit={props.onEdit}
						onArchive={handleToArchive}
						onRestore={handleFromArchive}
						onDelete={handleDelete}
						onDisable={handleDisable}
						onEnable={handleEnable}
					/>
				</div>
			)}

			{!isDesktop && bottomModal && (
				<BottomSheet
					show={bottomModal}
					onHide={() => setBottomModal(false)}
					fullSize
					Footer={
						selectedItem !== undefined &&
						provider?.placements?.results?.filter(
							(e) => e.id === selectedItem
						)[0]?.state !== 'used' ? (
							<ControlsButtons
								item={provider?.placements?.results?.find(
									(e) =>
										e.id.toString() ===
										selectedItem.toString()
								)}
								onEdit={handleEdit}
								onArchive={handleToArchive}
								onRestore={handleFromArchive}
								onDelete={handleDelete}
								onEnable={handleEnable}
								onDisable={handleDisable}
							/>
						) : null
					}
				>
					<div>
						<PlacementInfo
							item={provider?.placements?.results?.find(
								(e) =>
									e.id.toString() === selectedItem!.toString()
							)}
							onEdit={handleEdit}
							onArchive={handleToArchive}
							onRestore={handleFromArchive}
							onDelete={handleDelete}
							isTablet={!isDesktop}
							withControlsButtons={false}
							onDisable={handleDisable}
							onEnable={handleEnable}
						/>
					</div>
				</BottomSheet>
			)}

			{disableModal && (
				<ModalPlacementDisable
					show={Boolean(disableModal)}
					placementId={disableModal.toString()}
					handleClose={() => {
						setDisableModal(false)
					}}
					isTablet={!isDesktop}
					setSelectedItem={setSelectedItem}
					updatePlacementsInfo={props.updatePlacementsInfo}
				/>
			)}
		</div>
	)
}
