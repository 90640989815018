import React, { FC, useEffect } from 'react'
import s from './InternetAd.module.scss'
import {
	VkIcon,
	YandexIcon,
} from '../../create-campaign/Step2-ADformats/InternetAD/InternetSelector'
import IntlFormatter from '../../../../utils/intl-money-formatter'
import useCampaignData from '../../../../stores/campaignData'
import InternetStateView from './InternetStateView'
import ProgressFileInput from '../../../UtilityComponents/ProgressFileInput'
import InternetControls from './InternetControls'
import YandexDirectPreview from './internet-previews/YandexDirectPreview'
import VkPreview from './internet-previews/VkPreview'
import useNotificationData from 'src/stores/notificationsData'
import { EditVinstantProcessing } from '../common/edit-vinstant-processing/EditVinstantProcessing'
import { InputExtraButtons } from '../../create-campaign/Step3-Upload/components/outdoor/common/input-extra-buttons/InputExtraButtons'
import { downloadByRequest } from 'src/utils'
import useResponsive from 'src/utils/useResponsive'

interface IInternetCard {
	name: 'yandex' | 'vk'
}

const InternetCard: FC<IInternetCard> = ({ name }) => {
	const [campaign, campaignInterface] = useCampaignData()
	const internet =
		name === 'yandex' ? campaign.internet_yandex : campaign.internet_vk

	const [_, notificationsInterface] = useNotificationData()
	const editCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated(name)

	const handleVinstantEditEnd = async () => {
		notificationsInterface.setNewContendCreated(name, 'pending')
		await campaignInterface.refetchSelected()
	}

	const handleUpload = () => {
		const urlPrefix = name == 'vk' ? 'vk_creative' : 'yandex_creative'
		if (internet?.image) {
			downloadByRequest(
				`adv_companies/${campaign.id}/${urlPrefix}/download/`,
				internet.image
			)
		}
	}
	useEffect(() => {
		if (editCreativeVinstantStatus === 'end') {
			handleVinstantEditEnd()
		}
	}, [editCreativeVinstantStatus])

	const { isDesktop } = useResponsive()
	return (
		<div className={s.internetCard}>
			<div className={s.internetHeader}>
				<div
					className={'me-4'}
					style={{
						transform: 'scale(1.5)',
					}}
				>
					{name === 'yandex' && <YandexIcon />}
					{name === 'vk' && <VkIcon />}
				</div>
				<div style={{ flex: '1 1 auto' }}>
					<h5 className={'mb-0'}>
						{name === 'yandex' && 'Яндекс'}
						{name === 'vk' && 'VK Реклама'}
					</h5>
				</div>
				<div>
					<h5 className={'mb-0'}>
						{IntlFormatter.format(internet.amount || 0)}
					</h5>
				</div>
			</div>
			<div className={s.internetColumns}>
				<div style={{ width: '360px', minWidth: '360px' }}>
					{name === 'yandex' && <YandexDirectPreview />}
					{name === 'vk' && <VkPreview />}
					<div
						className={'text-secondary w-100 text-center'}
						style={{ fontSize: '12px', marginTop: '8px' }}
					>
						Внешний вид объявления может незначительно отличаться от
						примера в предпросмотре
					</div>
				</div>
				<div
					className={'d-flex flex-column'}
					style={{ flex: '1 1 auto' }}
				>
					<InternetStateView internet={internet} name={name} />

					<div
						style={{
							marginLeft: '-16px',
							marginTop: '-16px',
							flex: '1 1 auto',
						}}
					>
						{editCreativeVinstantStatus === 'start' ? (
							<div
								style={{
									maxWidth: 'max-content',
									padding: '0.5rem',
								}}
							>
								<EditVinstantProcessing />
							</div>
						) : (
							<ProgressFileInput
								ExtraButton={
									<InputExtraButtons
										onEditVinstant={undefined}
										onUpload={handleUpload}
									/>
								}
								initialValue={{
									src:
										internet?.image ||
										'https://storage.yandexcloud.net/admarket-media-dev/empty_VmO0bhA.jpg',
									name: 'Изображение',
								}}
								style={{ maxWidth: isDesktop ? '50%' : '100%' }}
								updateUrl={''}
								nameInBody={''}
								onlyView
							/>
						)}
					</div>
					<div>
						<InternetControls internet={internet} name={name} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default InternetCard
