import React from 'react'
import { Spinner } from 'react-bootstrap'
import s from './editVinstantProcessing.module.scss'

type Props = {
	styles?: React.CSSProperties
}
export const EditVinstantProcessing = ({ styles }: Props) => {
	return (
		<div className={s['edit-vinstant-processing']} style={{ ...styles }}>
			<p>Обработка материала</p>
			<Spinner
				animation="border"
				size="sm"
				className={s['edit-vinstant-processing-spinner']}
			></Spinner>
		</div>
	)
}

export const EditVinstantProcessingWrapped = ({ styles }: Props) => {
	return (
		<div
			className={s['edit-vinstant-processing-wrapped']}
			style={{ ...styles }}
		>
			<EditVinstantProcessing />
		</div>
	)
}
