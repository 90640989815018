import React from 'react'
import ls from '../style.module.scss'
import {
	advItemStatus,
	campaignStatus,
} from 'src/components/_moderator/constants'
import cn from 'classnames'
import s from '../../style.module.scss'
import IntlFormatter from 'src/utils/intl-money-formatter'

type AdvItemInfoProps = {
	budget: number
	format: string
	clicks: number
	status: string
	shows: number
	expenses: number
}

export const AdvItemInfo = (props: AdvItemInfoProps) => {
	const isValid = (value: number | string) => {
		if (typeof value === 'number') {
			return value !== 0
		} else {
			const result = parseFloat(value)
			if (isNaN(result)) {
				return false
			} else {
				return result !== 0
			}
		}
	}

	const getValidStyle = (value: string | number) => {
		return isValid(value) ? {} : { color: '#CED4DA' }
	}
	return (
		<div className={ls.advItemInfo}>
			<div className={ls.advItemInfoColumn}>
				<div className={ls.advItemInfoField}>
					<span>Бюджет:</span>
					<div style={getValidStyle(props.budget)}>
						{IntlFormatter.format(props.budget)}
					</div>
				</div>
				<div className={ls.advItemInfoField}>
					<span>Формат:</span>
					<div>{props.format}</div>
				</div>
				<div className={ls.advItemInfoField}>
					<span>Клики:</span>
					<div style={getValidStyle(props.clicks)}>
						{props.clicks}
					</div>
				</div>
			</div>
			<div className={ls.advItemInfoColumn}>
				<div className={ls.advItemInfoField}>
					<span>Статус:</span>
					<div
						style={{
							height: 'min-content',
							borderRadius: '4px',
							padding: '3px 4px',
							color: 'white',
							overflow: 'hidden',
							backgroundColor:
								campaignStatus?.[props.status]?.color || 'gray',
						}}
					>
						<p className={cn(s.campaignItemStatus)}>
							{advItemStatus?.[props.status]?.title ||
								props.status}
						</p>
					</div>
				</div>
				<div className={ls.advItemInfoField}>
					<span>Показы:</span>
					<div style={getValidStyle(props.shows)}>{props.shows}</div>
				</div>
				<div className={ls.advItemInfoField}>
					<span>Расходы:</span>
					<div style={getValidStyle(props.expenses)}>
						{IntlFormatter.format(props.expenses, undefined, 2)}
					</div>
				</div>
			</div>
		</div>
	)
}
