import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { BASE_URL } from 'src/constants/api'
import { authFetch } from 'src/utils/authFetch'
import downloadBlob from 'src/utils/downloadBlob'

type Props = {
	onClose: () => void
}

const getExportPlacements = async () => {
	return await authFetch({
		url: `${BASE_URL}/provider/export_placements/`,
		method: 'POST',
		raw: true,
	})
}

export const ExportModalBody = ({ onClose }: Props) => {
	const handleExportPlacements = async () => {
		const result: any = await getExportPlacements()
		if (result) {
			const blobData = await result.blob()

			downloadBlob(blobData, `export_placements`, 'xlsx')
		}
		onClose()
	}

	useEffect(() => {
		handleExportPlacements()
	}, [])
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				padding: '24px',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '1rem',
					alignItems: 'center',
				}}
			>
				<Spinner animation="border" style={{ color: '#007BFF' }} />
				<h5 style={{ margin: 0 }}>
					Формируем рекламные носители для экспорта
				</h5>
			</div>
		</div>
	)
}
