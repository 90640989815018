import React from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import {
	NotificationCategory,
	NotificationDetails,
	NotificationModel,
} from 'src/components/_moderator/types/ModerationRequestTypes'
import { SenderTypeButtonsAdditional } from './SenderTypeButtonsAdditional'
import s from '../common.module.scss'
import cn from 'classnames'
import useResponsive from 'src/utils/useResponsive'

export type Props = {
	setFieldValue: (
		field: string,
		value: NotificationCategory,
		shouldValidate?: boolean | undefined
	) => void
	value: NotificationCategory
	detailedNotify: NotificationModel & NotificationDetails
	onChange: (items: string[]) => void
}

export const SenderTypeButtons = ({
	setFieldValue,
	value,
	detailedNotify,
	onChange,
}: Props) => {
	const { isMobile } = useResponsive()
	return (
		<div className={cn(s.sender)}>
			<ButtonGroup
				vertical={isMobile}
				className={s.senderButtons}
				size="lg"
				aria-label="sender-types"
			>
				<Button
					className={s.senderButton}
					onClick={() => setFieldValue('senderType', 'client')}
					variant={
						value === 'client' || value === 'related_item'
							? 'primary'
							: 'secondary'
					}
				>
					Клиентам
				</Button>
				<Button
					className={s.senderButton}
					onClick={() => setFieldValue('senderType', 'adv_company')}
					variant={value === 'adv_company' ? 'primary' : 'secondary'}
				>
					Кампаниям
				</Button>
				<Button
					className={s.senderButton}
					onClick={() => setFieldValue('senderType', 'adv_type')}
					variant={value === 'adv_type' ? 'primary' : 'secondary'}
				>
					Типы рекламы
				</Button>
			</ButtonGroup>

			<SenderTypeButtonsAdditional
				onChange={onChange}
				detailedNotify={detailedNotify}
				senderType={value}
			/>
		</div>
	)
}
