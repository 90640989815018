import React, { useEffect, useMemo, useState } from 'react'
import { TvItemHeader } from '../common/TvItemHeader'
import useCampaignData from 'src/stores/campaignData'
import TvStateView from '../TvStateView'
import { TvType } from '../TvStateView.types'
import ProgressFileInput from 'src/components/UtilityComponents/ProgressFileInput'
import { AdstreamActionButtons } from './adstream-action-buttons/AdstreamActionButtons'
import s from './tvAdsteam.module.scss'
import SmallBottomSheet from 'src/components/UtilityComponents/SmallBottomSheet'
import { TvState } from 'src/stores/ADMarketTypes.types'
import { AdstreamRequirements } from './modals/AdstreamRequirements'
import { useConfirm } from 'src/components/UtilityComponents/ConfirmModalProvider'
import useNotificationData from 'src/stores/notificationsData'
import { getVinstantLinkByAdAdFormat } from '../../store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { useCampaignInfoStore } from '../../store/campaignInfo.store'
import { EditVinstantModal } from '../../common/edit-vinstant-modal/EditVinstantModal'
import { EditVinstantProcessing } from '../../common/edit-vinstant-processing/EditVinstantProcessing'
import { InputExtraButtons } from 'src/components/_client/create-campaign/Step3-Upload/components/outdoor/common/input-extra-buttons/InputExtraButtons'
import { downloadByRequest } from 'src/utils'

export const AdstreamItemWrapper = (props: any) => {
	const { confirm } = useConfirm()
	const [campaign, campaignInterface] = useCampaignData()
	const [isOpenBottomSheet, setIsOpenBottomSheet] = useState(false)
	const [isRequirementsOpen, setIsRequirementsOpen] = useState(false)

	const isDeleteButton = useMemo(() => {
		return (
			campaign.adstream.state !== TvState.Reject &&
			campaign.adstream.state !== TvState.Canceled &&
			campaign.adstream.state !== TvState.Completed
		)
	}, [campaign])

	const handleOpenRequirementsOnMobile = () => {
		setIsOpenBottomSheet(false)
		setIsRequirementsOpen(true)
	}

	const handleDelete = async () => {
		setIsOpenBottomSheet(false)
		setIsRequirementsOpen(false)

		const answ = await confirm({
			text: 'После этого действия вы не сможете ее вернуть обратно',
			title: 'Вы уверены, что хотите удалить показ видеоролика при переключении канала из рекламной кампании',
			closeButton: 'true',
			acceptText: 'Да, уверен',
			declineText: 'Нет, отменить',
			acceptVariant: 'danger',
		})
		if (!answ) return

		await campaignInterface.saveVideoFilters(campaign.id, {
			to_canceled: true,
		})
	}

	const [isEditVinstantOpen, setIsEditVinstantOpen] = useState(false)

	const [_, notificationsInterface] = useNotificationData()

	const editCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated('adstream')

	const adstreamVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'adstream_id',
			itemId: campaign.adstream.id.toString(),
		})
	)
	const showEditVinstantButton =
		campaign.adstream.state === TvState.Adjustment && !!adstreamVinstantLink

	const handleIsEditVinstantOpen = () => {
		if (editCreativeVinstantStatus === 'start') {
			return
		}

		if (isEditVinstantOpen) {
			setIsEditVinstantOpen(false)
		} else {
			if (showEditVinstantButton) {
				setIsEditVinstantOpen(true)
			}
		}
	}

	const handleVinstantEditEnd = async () => {
		notificationsInterface.setNewContendCreated('adstream', 'pending')
		await campaignInterface.refetchSelected()
	}
	const handleUpload = () => {
		if (campaign.adstream.image) {
			downloadByRequest(
				`adv_companies/${campaign.id}/channel_creative/download/`,
				campaign.adstream.image
			)
		}
	}
	useEffect(() => {
		if (editCreativeVinstantStatus === 'end') {
			handleVinstantEditEnd()
		}
	}, [editCreativeVinstantStatus])
	return (
		<div className={s.adstreamCard}>
			<TvItemHeader
				iconType="tv"
				onMenuClick={() => setIsOpenBottomSheet(!isOpenBottomSheet)}
				headerText={'Показ видеоролика при переключении канала'}
				price={campaign.adstream.amount}
				isAdstream={true}
			/>

			<div className={s.adstreamCardBody}>
				<div className={s.adstreamStateWrapper}>
					<TvStateView
						state={campaign.adstream.state}
						tvType={TvType.ADSTREAM}
						adjustment_msg={campaign.adstream.adjustment_msg}
						reject_msg={campaign.adstream.reject_msg}
					/>
				</div>

				<div className={s.adstreamInputWrapper}>
					{editCreativeVinstantStatus === 'start' ? (
						<EditVinstantProcessing />
					) : (
						<ProgressFileInput
							ExtraButton={
								<InputExtraButtons
									onEditVinstant={undefined}
									onUpload={handleUpload}
								/>
							}
							isDeleteConfirm={false}
							noPadding
							style={{
								maxWidth: '55%',
							}}
							initialValue={{
								src:
									campaign.adstream.image ||
									'https://storage.yandexcloud.net/admarket-media-dev/empty_VmO0bhA.jpg',
								name: 'Медиаматериал',
							}}
							updateUrl=""
							nameInBody=""
							onlyView
						/>
					)}

					<AdstreamActionButtons
						isEditVinstantButton={false}
						onEditVinstantClick={handleIsEditVinstantOpen}
						requirementButtonText={'Требования к видеофайлу'}
						isDeleteButton={isDeleteButton}
						onDeleteClick={handleDelete}
						onRequirementsClick={() => setIsRequirementsOpen(true)}
					/>
				</div>
			</div>

			{isOpenBottomSheet && (
				<SmallBottomSheet
					touchHeight="1rem"
					show={isOpenBottomSheet}
					height={isDeleteButton ? '116px' : '64px'}
					onHide={() => setIsOpenBottomSheet(false)}
				>
					<div style={{ padding: '12px' }}>
						<AdstreamActionButtons
							isEditVinstantButton={false}
							onEditVinstantClick={handleIsEditVinstantOpen}
							requirementButtonText={'Требования к видеофайлу'}
							isDeleteButton={isDeleteButton}
							onDeleteClick={handleDelete}
							onRequirementsClick={handleOpenRequirementsOnMobile}
						/>
					</div>
				</SmallBottomSheet>
			)}

			<EditVinstantModal
				newContentKey={'adstream'}
				editVinstantLink={adstreamVinstantLink}
				onClose={() => setIsEditVinstantOpen(false)}
				open={isEditVinstantOpen}
			/>
			{isRequirementsOpen && (
				<AdstreamRequirements
					onClose={() => setIsRequirementsOpen(false)}
					open={isRequirementsOpen}
				/>
			)}
		</div>
	)
}
