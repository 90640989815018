import React, { useState } from 'react'
import { Formik } from 'formik'
import {
	Button,
	Form,
	InputGroup,
	OverlayTrigger,
	Spinner,
	Tooltip,
} from 'react-bootstrap'
import bem from '../../utils/bem'
import { callable } from '../../utils/types'
import InputMask from 'react-input-mask'
import {
	ClosedEyeIcon,
	OpenEyeIcon,
} from '../_provider/Profile/ProviderProfile'
import s from './authstyles/signUpForm.module.scss'
import classNames from 'classnames'

type Props = {
	isLoading?: boolean
	onSubmit: (a: any) => any
	submitLabel: string
	cn: (a: any) => any
	onValidate?: callable
	errors?: { [key: string]: string } | null
	fields: {
		name: string
		autoComplete: string
		label: string
		text?: string
		type?: string
		toggleVisibility?: boolean
		placeholder?: string
	}[]
	extraActions?: React.ReactNode
	extraActionsClassName?: string
	clName?: string
}

export default function FieldsForm({
	onSubmit,
	submitLabel,
	cn,
	fields,
	onValidate = () => {},
	errors: outerErrors,
	isLoading = false,
	extraActions,
	extraActionsClassName,
	clName,
}: Props) {
	const [showPassword, setShowPassword] = useState(false)

	async function handleSubmit(values) {
		if (values.phone_number) {
			values.phone_number = values.phone_number.replace(/[^\d+]/g, '')
		}

		await onSubmit(values)
	}

	return (
		<Formik
			initialValues={fields.reduce((curr, { name }) => {
				curr[name] = ''
				return curr
			}, {})}
			onSubmit={handleSubmit}
			validate={onValidate}
			validateOnChange={false}
		>
			{({
				handleSubmit,
				errors,
				setFieldValue,
				setErrors,
				values,
			}: any) => (
				<>
					<div></div>
					<div className={classNames(s.signUpForm, clName)}>
						<div className="d-flex flex-column gap-2">
							<div className={s.signUpInputs}>
								{fields.map((fieldData, i) => {
									const Component =
										fieldData.name === 'remember'
											? CheckboxInput
											: TextField
									const error =
										errors[fieldData.name] ||
										outerErrors?.[fieldData.name]
									const value = values[fieldData.name]
									return (
										<Component
											key={i}
											{...{
												cn,
												showPassword,
												error,
												setShowPassword,
												setFieldValue,
												setErrors,
												value,
												onKeyDown: (e) => {
													if (e.key === 'Enter') {
														handleSubmit()
													}
												},
												...fieldData,
											}}
										/>
									)
								})}
							</div>

							{outerErrors && outerErrors['detail'] && (
								<Form.Label
									className="text-danger"
									style={{ fontSize: '12px' }}
								>
									{outerErrors['detail']}
								</Form.Label>
							)}
						</div>

						<div className={extraActionsClassName || ''}>
							<Button
								onClick={handleSubmit}
								className={s.signUpSubmit}
								variant={'primary'}
								size="lg"
							>
								{isLoading ? (
									<Spinner animation="border" size="sm" />
								) : (
									submitLabel
								)}
							</Button>
							{extraActions}
						</div>
					</div>
				</>
			)}
		</Formik>
	)
}

function TextField({
	cn,
	label,
	showPassword,
	autoComplete,
	placeholder,
	toggleVisibility,
	error,
	name,
	setShowPassword,
	text,
	setFieldValue,
	setErrors,
	type,
	value,
	...pass
}: any) {
	return (
		<div className={cn('control')}>
			<div className={cn('input')}>
				<Form.Label>{label}</Form.Label>
				<InputGroup hasValidation className="m-0">
					{name === 'phone_number' ? (
						<InputMask
							mask={'+7 (999) 999-99-99'}
							value={value}
							onChange={(e) => {
								setFieldValue(name, e.target.value)
								setErrors({})
							}}
							{...pass}
						>
							{(inputProps) => (
								<Form.Control
									size="lg"
									type={
										toggleVisibility && showPassword
											? 'text'
											: type || name
									}
									autoComplete={autoComplete}
									placeholder={placeholder}
									isInvalid={!!error}
									{...inputProps}
								/>
							)}
						</InputMask>
					) : (
						<Form.Control
							size="lg"
							type={
								toggleVisibility && showPassword
									? 'text'
									: type || name
							}
							autoComplete={autoComplete}
							placeholder={placeholder}
							isInvalid={!!error}
							value={value}
							onChange={(e) => {
								const newValue =
									name == 'password' || name == 'newPassword'
										? e.target.value.trim()
										: e.target.value

								setFieldValue(name, newValue)
								setErrors({})
							}}
							{...pass}
						/>
					)}
					<Form.Control.Feedback type="invalid">
						{error}
					</Form.Control.Feedback>
				</InputGroup>
				{toggleVisibility && !error && (
					<div className={cn('popover')}>
						<OverlayTrigger
							placement={'top'}
							overlay={
								<Tooltip id={`tooltip-top`}>
									{showPassword
										? 'Скрыть пароль'
										: 'Показать пароль'}
								</Tooltip>
							}
						>
							<div
								onClick={() =>
									setShowPassword((state) => !state)
								}
							>
								{showPassword ? (
									<ClosedEyeIcon />
								) : (
									<OpenEyeIcon />
								)}
							</div>
						</OverlayTrigger>
					</div>
				)}
			</div>
			{!!text && !error && (
				<Form.Text className={s.signUpInputsHelpText}>
					Пароль должен содержать не менее восьми знаков, включать
					буквы, цифры и специальные символы
				</Form.Text>
			)}
		</div>
	)
}

function CheckboxInput({ cn, label, name, value, setFieldValue }) {
	return (
		<label className={cn('checkbox')}>
			<input
				className={cn('checkbox-input', bem.pass('form-check-input'))}
				type={'checkbox'}
				name={name}
				checked={!!value}
				onChange={() => setFieldValue(name, !value)}
			/>
			<div className={cn('checkbox-label')}>{label}</div>
		</label>
	)
}
