import React from 'react'
import s from '../list/list.module.scss'
import { getBookingWord } from './SidebarPlacementsList'
import { Tabs } from './Tabs'
import { PlacementBookings } from './PlacementBookings'
import { PlacementInfo } from './PlacementInfo'

function SidebarPlacementDetail({ placement, onBack }) {
	if (!placement) return null

	return (
		<div className={s.componentSideBar}>
			<div className={s.componentOneFrame}>
				<div className={s.whitebox}>
					<button className={s.backbutton} onClick={onBack}>
						<span className="arrow-icon">&#8592;</span> Назад
					</button>
				</div>
				<div className={s.componentOneFrameOne}>
					<div className={s.componentOneFrameOneIcons}>
						<div className={s.componentOneFrameOneIconsPin}>
							<div className={s.iconFrame}>
								<div
									className={`${s.bannerType} ${
										placement.booking_count === 0
											? s.grayBillboardd
											: ''
									}`}
									style={{
										width: 56,
										height: 56,
									}}
								>
									<div
										className={`${s.uniQon} ${
											placement.booking_count === 0
												? s.uniQonNoAdvertising
												: s.uniQonAdvertising
										}`}
										dangerouslySetInnerHTML={{
											__html: placement.placement_type.image
												.replace(
													'fill="#212529"',
													'fill="white"'
												)
												.replace(
													'width="24" height="24"',
													'width="34" height="34"'
												),
										}}
										style={{
											width: '40px',
											height: '40px',
										}}
									/>
								</div>
								<div className={s.bannerState}>
									<div
										className={`${s.bannerBadge} ${
											placement.booking_count === 0
												? s.grayBadge
												: ''
										}`}
									>
										<span>
											{placement.booking_count}{' '}
											{getBookingWord(
												placement.booking_count
											)}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className={s.componentOneFrameOneText}>
						<h1>{placement.name}</h1>
					</div>
				</div>
				<Tabs
					tabs={[
						{
							label: 'Брони',
							key: 'bookings',
							render: () => (
								<PlacementBookings placement={placement} />
							),
						},
						{
							label: 'Информация',
							key: 'info',
							render: () => (
								<PlacementInfo placement={placement} />
							),
						},
					]}
				/>
			</div>
		</div>
	)
}

// const SidebarPlacementDetail = ({ placement, onBack }) => {
// 	// const [showInfotSidebar, setShowInfoSidebar] = useState(false)
// 	// const [showBookingInfo, setShowBookingInfo] = useState(true)
// 	// const [showRightSideBar, setShowRightSideBar] = useState(false)
// 	// const [showModalQuickView, setShowModalQuickView] = useState(false)

// 	// const start_date =
// 	// 	placement &&
// 	// 	moment(placement.advertising_company_start_date).format('D ')
// 	// const end_date =
// 	// 	placement &&
// 	// 	moment(placement.advertising_company_end_date).format('D MMMM YYYY')

// 	// const start__date = useMemo(() => {
// 	// 	return moment(
// 	// 		placement?.advertising_company_start_date,
// 	// 		'YYYY-MM-DD'
// 	// 	)
// 	// }, [placement])
// 	// const end__date = useMemo(() => {
// 	// 	return moment(selectedItem?.advertising_company_end_date, 'YYYY-MM-DD')
// 	// }, [selectedItem])

// 	// const onContentBookingInfo = () => {
// 	// 	setShowBookingInfo(true)
// 	// 	setShowInfoSidebar(false)
// 	// }
// 	// const onContenRightClick = (booking) => {
// 	// 	setSelectedBooking(booking)
// 	// 	setShowInfoSidebar(true)
// 	// 	setShowBookingInfo(false)
// 	// 	setShowRightSideBar(false)
// 	// }

// 	// const duration_in_days = useMemo(() => {
// 	// 	return end__date.diff(start__date, 'day') + 1
// 	// }, [start_date, end_date])

// 	// const duration_in_weeks = useMemo(() => {
// 	// 	const col = Math.round(
// 	// 		(moment(end__date).diff(moment(start__date), 'd') + 1) / 7
// 	// 	)
// 	// 	return `${col} ${declOfNum(col, ['неделя', 'недели', 'недель'])}`
// 	// }, [start__date, end__date])

// 	// const onBack = () => {
// 	// setShowSidebar(!showSidebar)
// 	// setShowSets(true)
// 	// }
// 	// const [isBookingsActionsOpened, setIsBookingActionsOpened] = useState(false)

// 	return (
// 	)
// }

export default SidebarPlacementDetail
