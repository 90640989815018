import React from 'react'
import s from './campaignReportTable.module.scss'
import { Button } from 'react-bootstrap'
import cn from 'classnames'
import { Row, TableBodyPropGetter, TableBodyProps } from 'react-table'
import { ReactComponent as RightIcon } from 'src/assets/images/go-right.svg'
import { ReportDetailModalType, ReportTableData } from '../../types'

type Props = {
	onMoreDetailClick: (type: ReportDetailModalType) => void
	getTableBodyProps: (
		propGetter?: TableBodyPropGetter<ReportTableData> | undefined
	) => TableBodyProps
	rows: Row<ReportTableData>[]
	prepareRow: (row: Row<ReportTableData>) => void
}
export const ReportTableBody = ({
	getTableBodyProps,
	rows,
	prepareRow,
	onMoreDetailClick,
}: Props) => {
	return (
		<tbody {...getTableBodyProps()} className={s.body}>
			{rows.map((row, rowIndex) => {
				prepareRow(row)

				return (
					<tr {...row.getRowProps()} key={rowIndex} className={s.row}>
						{row.cells.map((cell, cellIndex) => (
							<td
								{...cell.getCellProps()}
								key={cellIndex}
								style={{ height: '64px' }}
							>
								<div
									className={cn(s.bodyCell, {
										[s.formatName]:
											cell.column.id === 'format',
									})}
								>
									{cell.render('Cell')}

									{cell.column.id === 'format' &&
										cell.row.values?.format !== 'Итого' && (
											<Button
												variant="light"
												className={s.detailButton}
												onClick={() =>
													onMoreDetailClick(
														row.original.id!
													)
												}
											>
												<p>Подробнее</p> <RightIcon />
											</Button>
										)}
								</div>

								<span></span>
							</td>
						))}
					</tr>
				)
			})}
		</tbody>
	)
}
