import { TabType } from 'src/components/UtilityComponents'
import {
	ModerationAdvStatus,
	ModerationCountsType,
} from '../types/ModerationRequestTypes'

export const getModerationTabsWithCount = (
	counts: ModerationCountsType
): Array<TabType<ModerationAdvStatus>> => {
	return [
		{ count: counts.count_total, key: 'all', title: 'Все' },
		{
			count: counts?.count_moderation || 0,
			key: 'moderation',
			title: 'На модерации',
		},
		{
			count: counts?.count_adjustment || 0,
			key: 'adjustment',
			title: 'На исправлении',
		},
		{
			count: counts?.count_payment_waiting || 0,
			key: 'payment_waiting',
			title: 'Ожидание оплаты',
		},
		{
			count: counts?.count_paid || 0,
			key: 'paid',
			title: 'Оплаченные',
		},
		{
			count: counts?.count_start_waiting || 0,
			key: 'start_waiting',
			title: 'Ожидают запуска',
		},
		{ count: counts?.count_active || 0, key: 'active', title: 'Активная' },
		{
			count: counts?.count_completed || 0,
			key: 'completed',
			title: 'Завершенные',
		},
		{
			count: counts?.count_close_waiting || 0,
			key: 'close_waiting',
			title: 'Ожидают закрытия',
		},

		// {
		// 	count: counts?.count_canceled || 0,
		// 	key: 'canceled',
		// 	title: 'Отменена',
		// },
	]
}
