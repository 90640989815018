import React, { useContext, useEffect, useMemo, useState } from 'react'
import bem from '../../../utils/bem'
import '../../../styles/mediaformats-page.scss'
import { Badge, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import MediaInfo from './MediaInfo'
import ModalMediaFormat from './ModalMediaFormat'
import { useConfirm } from '../../UtilityComponents/ConfirmModalProvider'
import useProviderData from '../../../stores/providerData'
import { ToastsContext } from '../../UtilityComponents/ToastsContextProvider'
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion'
import useResponsive from '../../../utils/useResponsive'
import BottomSheet from '../../UtilityComponents/Bottomsheet'
import { ReactComponent as Arrow } from '../../../assets/images/arrow.svg'
import { useHistory } from 'react-router-dom'
import useHeaderTitle from 'src/components/page/useHeaderTitle'
import ModalPlacementLocation from '../Placements/content/ModalPlacementLocation'
import { MediaFormatsHeader, MediaFormatsTabs } from './components'
const cn = bem('mediaformats-page')

const InitTabs = [
	{
		count: 0,
		display: 'Все',
		title: 'all',
	},
	{
		count: 0,
		display: 'Свободные',
		title: 'unused',
	},
	{
		count: 0,
		display: 'Забронированные',
		title: 'used',
	},
	{
		count: 0,
		display: 'Архив',
		title: 'archive',
	},
]
const MediaformatsPage = (props) => {
	const [provider, providerInterface] = useProviderData()
	const params = new URLSearchParams(props.location.search)
	const history = useHistory()
	const [tabs, setTabs] = useState<any>(InitTabs)
	// @ts-ignore
	const [filter, setFilter] = useState<string>(
		params.get('state') ? (params.get('state') as string) : 'all'
	)
	// @ts-ignore
	const [selected, setSelected] = useState<string>(
		params.get('id') ? (params.get('id') as string) : ''
	)
	const [edited, setEdited] = useState('')
	const [showModal, setShowModal] = useState(false)
	const [bottomModal, setBottomModal] = useState(false)
	const [showLocationQuickView, setShowLocationQuickView] = useState(false)

	const { confirm } = useConfirm()
	const { isTablet } = useResponsive()
	const { addToast } = useContext(ToastsContext)

	const handleModalClose = () => {
		setEdited('')
		setShowModal(false)
	}
	async function handleMediaToArchive(id) {
		const name = provider.mediatypes.find((el) => el.id === id).name
		await providerInterface.ArchiveMedia(id)
		if (isTablet) setBottomModal(false)
		addToast({
			text: `Медиа-формат "${name}" перемещен в архив`,
			type: 'secondary',
			withAction: true,
			actionTitle: 'Отменить',
			actionCallBack: () => providerInterface.RestoreMedia(id),
		})
	}

	function handleMediaEdit(id) {
		setEdited(id)
		setBottomModal(false)
		setShowModal(true)
	}

	async function handleMediaFromArchive(id) {
		const name = provider.mediatypes.find((el) => el.id === id).name
		await providerInterface.RestoreMedia(id)
		if (isTablet) setBottomModal(false)
		addToast({
			text: `Медиа-формат "${name}" восстановлен.`,
			type: 'success',
		})
	}

	async function handleMediaDelete(id) {
		const name = provider?.mediatypes.find((el) => el.id === id).name
		const res = await confirm({
			text: `Медиа-формат «${name}» будет удален без возможности восстановления.`,
			title: 'Удалить навсегда?',
			closeButton: true,
			acceptText: 'Удалить',
			declineText: 'Отменить',
		})
		if (res) {
			await providerInterface.DeleteMedia(id)
			if (isTablet) setBottomModal(false)

			addToast({
				text: `Медиа-формат "${name}" навсегда удален.`,
				type: 'danger',
			})
		}
	}

	const handleModalShow = () => setShowModal(true)

	useEffect(() => {
		let tmp_unused = 0
		let tmp_used = 0
		let tmp_archive = 0
		provider?.mediatypes?.forEach((el, i) => {
			if (el?.state === 'unused' && !el.in_archive) tmp_unused++
			if (el?.state === 'used' && !el.in_archive) tmp_used++
			if (el?.in_archive) tmp_archive++
		})
		// @ts-ignore
		setTabs((prev) =>
			prev.map((el) => {
				if (el.title === 'all')
					return { ...el, count: tmp_used + tmp_unused }
				if (el.title === 'used') return { ...el, count: tmp_used }
				if (el.title === 'unused') return { ...el, count: tmp_unused }
				if (el.title === 'archive') return { ...el, count: tmp_archive }
				return null
			})
		)
	}, [provider])

	const FilteredData = useMemo(() => {
		if (!provider?.mediatypes) return []
		if (filter === 'all') {
			return provider?.mediatypes?.filter((el) => !el.in_archive)
		}
		if (filter === 'used') {
			return provider?.mediatypes?.filter(
				(el) => el.state === 'used' && !el.in_archive
			)
		}
		if (filter === 'unused') {
			return provider?.mediatypes?.filter(
				(el) => el.state === 'unused' && !el.in_archive
			)
		}
		if (filter === 'archive') {
			return provider?.mediatypes?.filter((el) => el.in_archive)
		}
	}, [filter, provider])

	useEffect(() => {
		params.delete('id')
		params.delete('state')

		params.append('id', selected.toString())
		params.append('state', filter.toString())

		history.push({ search: params.toString() })
	}, [filter, selected]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (FilteredData?.length === 0) return
		if (FilteredData?.find((el) => el.id === selected)) return
		if (FilteredData?.length !== 0) return setSelected(FilteredData[0].id)
		return setSelected('')
	}, [filter, FilteredData]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		document.body.style.overflowY = 'overlay'
		return () => {
			document.body.style.overflowY = ''
		}
	}, [showModal])

	useHeaderTitle('Медиа-форматы')

	const { isMobile } = useResponsive()
	return (
		<div
			style={{
				height: '100%',
				maxHeight: 'calc(100vh - 65px)',
				background: '#ffffff',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<div
				style={{
					display: 'flex',
					flexDirection: isMobile ? 'column-reverse' : 'column',
				}}
			>
				<MediaFormatsTabs
					filter={filter}
					setFilter={setFilter}
					tabs={tabs}
				/>

				<MediaFormatsHeader
					isMediaFormats={provider?.mediatypes?.length !== 0}
					handleModalShow={handleModalShow}
					setSelected={setSelected}
					setShowLocationQuickView={setShowLocationQuickView}
				/>
			</div>

			{provider?.mediatypes?.length === 0 ? (
				<NoContent showModal={handleModalShow} />
			) : (
				<>
					<div className={cn('content')}>
						<AnimateSharedLayout>
							<motion.div className="col col_left" layout>
								<AnimatePresence initial={false}>
									{FilteredData.map((i) => (
										<RenderMediaFormatItem
											i={i}
											selected={i.id === selected}
											setSelected={setSelected}
											handleModalShow={handleModalShow}
											handleMediaToArchive={
												handleMediaToArchive
											}
											handleMediaEdit={handleMediaEdit}
											handleMediaFromArchive={
												handleMediaFromArchive
											}
											handleMediaDelete={
												handleMediaDelete
											}
											setBottomModal={setBottomModal}
											key={i.id}
											isTablet={isTablet}
											isMobile={isMobile}
										/>
									))}
								</AnimatePresence>
							</motion.div>
						</AnimateSharedLayout>
						{!isTablet && !isMobile && (
							<>
								<div className={'separator'} />
								<div className="col col_right">
									<MediaInfo
										media={
											selected === ''
												? null
												: FilteredData.find(
														(el) =>
															el.id === selected
												  )
										}
										handleModalShow={handleModalShow}
										handleMediaEdit={handleMediaEdit}
									/>
								</div>
							</>
						)}
					</div>
				</>
			)}

			{showModal && (
				<ModalMediaFormat
					media={
						edited === ''
							? undefined
							: FilteredData.find((el) => el.id === edited)
					}
					handleClose={handleModalClose}
					show={showModal}
					setEdited={setEdited}
				/>
			)}
			{(isTablet || isMobile) && (
				<BottomSheet
					show={bottomModal}
					onHide={() => setBottomModal(false)}
					fullSize
					Footer={
						<BottomControls
							media={
								selected === ''
									? null
									: FilteredData.find(
											(el) => el.id === selected
									  )
							}
							handleMediaToArchive={handleMediaToArchive}
							handleMediaEdit={handleMediaEdit}
							handleMediaFromArchive={handleMediaFromArchive}
							handleMediaDelete={handleMediaDelete}
						/>
					}
				>
					<div className={'p-4 pt-4 media_details'}>
						<MediaInfo
							media={
								selected === ''
									? null
									: FilteredData.find(
											(el) => el.id === selected
									  )
							}
							handleModalShow={handleModalShow}
							withControlsButtons={false}
						/>
					</div>
				</BottomSheet>
			)}
		</div>
	)
}
export default MediaformatsPage

{
	/* {showLocationQuickView && (
					<ModalPlacementLocation
						item={provider?.placements?.results?.find(
							(e) => e?.id?.toString() === selected?.toString()
						)}
						show={showLocationQuickView}
						onHide={() => setShowLocationQuickView(false)}
						isTablet={isTablet}
						setSelectedItem={setSelected}
						selectedItem={selected}
						props={props}
						onEdit={handleEdit}
						onDisable={handleDisable}
						onEnable={handleEnable}
						onDelete={handleDelete}
						onArchive={handleToArchive}
						onFromArchive={handleFromArchive}
					/>
				)} */
}

export const ActiveLine = () => {
	return (
		<motion.div
			layoutId={'active-line'}
			style={{
				width: '100%',
				height: '2px',
				position: 'absolute',
				bottom: '-2px',
				left: '0',
				backgroundColor: '#0D6EFD',
			}}
		/>
	)
}

const NoContent: React.FC<any> = ({ showModal }) => (
	<div className={cn('nocontent')}>
		<span>🙂</span>
		<h2>Нет ни одного медиа-формата</h2>
		<Button color="primary" onClick={showModal}>
			Создать медиа-формат
		</Button>
	</div>
)

export const StatusToRus = (status) => {
	switch (status) {
		case 'used':
			return 'Забронирован'
		case 'unused':
			return 'Свободен'
		default:
			return null
	}
}

const BottomControls = ({
	media,
	handleMediaToArchive,
	handleMediaEdit,
	handleMediaFromArchive,
	handleMediaDelete,
}) => {
	if (!media) return null
	return (
		<>
			{/*  Кнопки, если статус свободен*/}
			{media.state === 'unused' && !media.in_archive && (
				<div className={cn('control_panel')}>
					<div
						className={'control-btn'}
						onClick={() => {
							handleMediaEdit(media.id)
						}}
					>
						<i className="bi bi-pencil" />
						<span style={{ fontWeight: 500 }}>Изменить</span>
					</div>

					<div
						className="control-btn"
						onClick={() => {
							handleMediaToArchive(media.id)
						}}
					>
						<i className="bi bi-trash" />
						<span style={{ fontWeight: 500 }}>Удалить</span>
					</div>
				</div>
			)}
			{media.state === 'unused' && media.in_archive && (
				<div className={cn('control_panel')}>
					<div
						className={'control-btn'}
						onClick={() => {
							handleMediaFromArchive(media.id)
						}}
					>
						<i className="bi bi-box-arrow-up" />

						<span style={{ fontWeight: 500 }}>Восстановить</span>
					</div>

					<div
						className="control-btn"
						onClick={() => {
							handleMediaDelete(media.id)
						}}
					>
						<i className="bi bi-trash" />
						<span style={{ fontWeight: 500 }}>
							Удалить навсегда
						</span>
					</div>
				</div>
			)}
		</>
	)
}
const RenderMediaFormatItem: React.FC<any> = ({
	i,
	selected,
	setSelected,
	setBottomModal,
	isTablet,
	isMobile,
	handleMediaToArchive,
	handleMediaEdit,
	handleMediaFromArchive,
	handleMediaDelete,
}) => {
	return (
		<motion.div
			className={`list-item ${selected ? 'selected' : ''}`}
			key={i.id}
			onClick={() => {
				setSelected(i.id)
				setBottomModal(true)
			}}
			initial={{ opacity: 0 }}
			exit={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.3 }}
			layout
			style={{ cursor: 'pointer' }}
		>
			<div className="left">
				{i.name}
				<span>
					{i.is_image && !i.is_video ? 'Изображение' : ''}
					{i.is_video && !i.is_image ? 'Видео' : ''}
					{i.is_video && i.is_image ? 'Изображение, видео' : ''}
				</span>
			</div>
			<div className="right">
				<div className="d-flex gap-2">
					{i.state !== 'used' && !i.in_archive && (
						<Button
							variant="text"
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: '32px',
								height: '32px',
								borderRadius: '8px',
							}}
							onClick={() => handleMediaEdit(i.id)}
						>
							<i className="bi bi-pencil m-0"></i>
						</Button>
					)}

					{i.in_archive && (
						<Button
							variant="text"
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: '32px',
								height: '32px',
								borderRadius: '8px',
							}}
							onClick={() => handleMediaFromArchive(i.id)}
						>
							<i className="bi bi-box-arrow-up m-0" />
						</Button>
					)}

					{i.state !== 'used' && (
						<Button
							variant="text"
							onClick={
								i.in_archive
									? () => handleMediaDelete(i.id)
									: () => handleMediaToArchive(i.id)
							}
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: '32px',
								height: '32px',
								borderRadius: '8px',
							}}
						>
							<i className="bi bi-trash m-0"></i>
						</Button>
					)}
				</div>
				{!i.in_archive && (
					<Badge bg={'used' === i.state ? 'primary' : 'secondary'}>
						{StatusToRus(i.state)}
					</Badge>
				)}

				{(isTablet || isMobile) && (
					<Arrow
						fill={'#000'}
						style={{
							transform: 'rotate(180deg)',
							marginLeft: '30px',
						}}
					/>
				)}
			</div>
		</motion.div>
	)
}
