import React, { useContext } from 'react'
import { Button, Card } from 'react-bootstrap'
import { ReactComponent as DocumentIcon } from 'src/assets/images/document.svg'
import s from './reportCard.module.scss'
import { ReactComponent as XLSIcon } from 'src/assets/images/xls.svg'
import { ReactComponent as DocsPreview } from 'src/assets/images/doc-preview.svg'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { downloadByRequest } from 'src/utils'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'

type Props = {
	campaignId: string
}
export const CampaignReportCard = ({ campaignId }: Props) => {
	return (
		<Card className={s.reportCard}>
			<CampaignReportContent campaignId={campaignId} />
		</Card>
	)
}

type CampaignReportContentProps = {
	campaignId: string
	classname?: string
}
export const CampaignReportContent = ({
	campaignId,
	classname,
}: CampaignReportContentProps) => {
	const history = useHistory()

	const { addToast } = useContext(ToastsContext)

	const handleWatchReportClick = () => {
		history.push(`/campaigns/${campaignId}/report`)
	}
	const handleDownload = async () => {
		if (campaignId) {
			const url = `adv_companies/${campaignId}/final-stats/export/`

			const downloadResult = (await downloadByRequest(
				url,
				'Отчет.xlsx',
				'POST'
			)) as { success: boolean }

			if (!downloadResult?.success) {
				addToast({
					text: 'При загрузке отчета произошла ошибка',
					type: 'danger',
				})
			}
		}
	}
	return (
		<div className={cn(s.reportContent, classname)}>
			<div className={s.title}>
				<div
					className="d-flex align-items-center justify-content-center"
					style={{ height: '24px', width: '24px' }}
				>
					<DocumentIcon />
				</div>

				<h5>Отчет о завершенной рекламной кампании</h5>
			</div>

			<div className={s.actions}>
				<Button variant="light" onClick={handleDownload}>
					<XLSIcon />
					<h6>Скачать в Excel</h6>
				</Button>
				<Button
					variant="primary"
					onClick={() => handleWatchReportClick()}
				>
					<DocsPreview style={{ fill: '#fff' }} />
					<h6 style={{ color: '#fff' }}>Смотреть</h6>
				</Button>
			</div>
		</div>
	)
}
