import React, { useRef } from 'react'
import { Button, Form } from 'react-bootstrap'
import AdMarketSelect from 'src/components/UtilityComponents/ADMarketSelect/AdMarketSelect'
import InputWithEndText from 'src/components/UtilityComponents/InputWithEndText'
import { DropdownIndicator } from 'src/components/_client/create-campaign/Step1-Campaign/WatchTimeSelector'
import { SingleValue } from 'react-select'
import { DistrictType } from '../../../../types'
import s from './massCoverageItem.module.scss'
import cn from 'classnames'
import IntlFormatter from 'src/utils/intl-money-formatter'
import { NumericFormat } from 'react-number-format'

const errorStyle: React.CSSProperties = {
	color: '#dc3545',
	fontSize: '14px',
}
type OptionType = { label: string; value: number }

type Props = {
	districtBookingId: string
	errors: {
		city: string | undefined
		budget: string | undefined
	}
	city: DistrictType | undefined
	budget: string
	options: OptionType[]
	onCityChange: (newValue: SingleValue<OptionType>) => void
	onBudgetChange: (event: React.ChangeEvent<HTMLInputElement>) => void
	onDelete: () => void
	minBudget: string
	recBudget: number | null | undefined
}
export const MassCoverageItemControl = ({
	errors,
	city,
	budget,
	options,
	onCityChange,
	onBudgetChange,
	onDelete,
	minBudget,
	recBudget,
	districtBookingId,
}: Props) => {
	const blockChange = useRef<boolean>(false)

	const handleBudgetChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (blockChange.current) {
			return
		}
		onBudgetChange(event)
	}

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === '+' || event.key === '-' || event.key === 'e') {
			event.preventDefault()
			blockChange.current = true
		} else {
			blockChange.current = false
		}
	}

	const budgetServerError =
		!errors?.budget?.startsWith('Минимальный') && errors.budget

	return (
		<div className={s.massCoverageItemControl}>
			<div className={s.controlInputs}>
				<Form.Group className={s.controlCity}>
					<Form.Label>{'Город'}</Form.Label>
					<AdMarketSelect
						id={`${districtBookingId}_city`}
						value={
							city
								? { label: city.title, value: city.id }
								: undefined
						}
						placeholder={'Не выбран'}
						components={{ DropdownIndicator }}
						options={options}
						onChange={onCityChange}
					/>
					<div
						style={{
							...errorStyle,
							marginTop: '0.25rem',
							display: errors.city ? 'block' : 'none',
							fontSize: '12px',
						}}
					>
						{errors.city}
					</div>
				</Form.Group>

				<Form.Group className={s.controlBudget}>
					<Form.Label>{'Бюджет на город'}</Form.Label>
					<div style={{ display: 'flex', gap: '0.5rem' }}>
						<NumericFormat
							id={`${districtBookingId}_budget`}
							decimalScale={2}
							decimalSeparator=","
							value={budget}
							suffix=" ₽"
							thousandSeparator={' '}
							customInput={InputWithEndText}
							controlStyle={{
								minHeight: '42px',
								height: '45px',
								fontSize: '1.25rem',
							}}
							disabled={!!errors.city}
							onChange={handleBudgetChange}
							onKeyDown={handleKeyDown}
							// onBlur={(e) => {}}
							containerStyle={{
								overflow: 'hidden',
								width: '100%',
							}}
							isInvalid={false}
							FeedBackComponent={
								!!budgetServerError ? (
									<div
										style={{
											marginTop: '0.25rem',
											color: errors.budget
												? '#dc3545'
												: '#6C757D',
											fontSize: '12px',
										}}
									>
										{errors.budget}
									</div>
								) : (
									<></>
								)
							}
						/>
					</div>
					{city?.id && (
						<div>
							<div
								style={{
									marginTop: '0.25rem',
									color: errors.budget
										? '#dc3545'
										: '#6C757D',
									fontSize: '12px',
								}}
							>
								{`Минимальный бюджет: ${IntlFormatter.format(
									minBudget,
									undefined
								)}`}
							</div>
							{!!recBudget && (
								<div
									style={{ fontSize: '12px' }}
								>{`Рекомендованный бюджет: ${IntlFormatter.format(
									recBudget,
									undefined
								)}`}</div>
							)}
						</div>
					)}
				</Form.Group>
			</div>

			<Button
				className={cn(s.removeItemButton, {
					[s.removeItemButton_recBudget]: !!recBudget,
				})}
				variant={'text'}
				onClick={onDelete}
			>
				<i className="bi bi-trash"></i>
			</Button>
		</div>
	)
}
