import React, { FC, useEffect } from 'react'
import {
	AnimatePresence,
	motion,
	PanInfo,
	useDragControls,
} from 'framer-motion'
import ReactDOM from 'react-dom'
import s from './Bottomsheet.module.css'

interface ISmallBottomSheet {
	children?: React.ReactNode | React.ReactNode[]
	show: boolean
	onHide: () => any
	height?: string
	touchHeight?: string
	styles?: React.CSSProperties
}

const SmallBottomSheet: FC<ISmallBottomSheet> = (props) => {
	return ReactDOM.createPortal(
		<AnimatePresence exitBeforeEnter>
			{props.show && <Body {...props} />}
		</AnimatePresence>,
		document.body
	)
}

const Body: FC<ISmallBottomSheet> = ({
	styles,
	onHide,
	show,
	height = '400px',
	touchHeight = '65px',
	...props
}) => {
	const controls = useDragControls()

	useEffect(() => {
		if (show) {
			document.body.style.overflow = 'hidden'
		}
		return () => {
			document.body.style.overflow = ''
		}
	}, [show])

	function onDrag(
		event: MouseEvent | TouchEvent | PointerEvent,
		info: PanInfo
	) {
		if (info.offset.y > 150) {
			return onHide()
		}
	}

	return (
		<div className={s.container}>
			<motion.div
				className={s.bg}
				onClick={onHide}
				initial={{ opacity: 0 }}
				animate={{ opacity: 0.5 }}
				exit={{ opacity: 0 }}
				key={'bg'}
				style={{ touchAction: 'none' }}
			/>
			<motion.div
				key={'content'}
				className={s.sheet}
				onClick={(e) => {
					e.stopPropagation()
				}}
				initial={{ top: 1000 }}
				exit={{ top: 1000 }}
				animate={{ top: `calc(100% - ${height})` }}
				dragListener={false}
				dragControls={controls}
				drag="y"
				onDragEnd={onDrag}
				dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
				style={{ height, ...styles }}
			>
				<motion.div
					className={s.touch}
					onPointerDown={(e) => controls.start(e)}
					style={{ minHeight: touchHeight, maxHeight: touchHeight }}
				/>
				<motion.div
					className={s.children}
					onClick={(e) => {
						e.stopPropagation()
					}}
				>
					{props.children}
				</motion.div>
			</motion.div>
		</div>
	)
}

export default SmallBottomSheet
