import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Spinner } from 'react-bootstrap'
import {
	UniqDistrictBookingFormat,
	useTechnicalRequirementsStore,
} from 'src/stores/technical-requirements-store'
import { getUniqDistrictsFormats } from 'src/stores/technical-requirements-store/selectors/getUniqDistrictsFormats'
import { ReactComponent as RadiusIcon } from 'src/assets/images/radius.svg'
import s from './districtsUpload.module.scss'
import { motion, AnimatePresence } from 'framer-motion'
import {
	downloadByRequest,
	getFileDescription,
	getMaxFileSize,
} from 'src/utils'
import { CommonMediaModal } from 'src/components/common/garantid-card/garantid-media-format-card/components/common-media/modals/CommonMediaModal'
import { MediaFormatRequirements } from 'src/components/common/garantid-card/garantid-media-format-card/components/common/MediaFormatRequirements'
import { PlacementsOverview } from 'src/components/common/districtBooking-card/districtBooking-item-card/components/PlacementsOverview'
import { TemplatesModal } from '../../templates-modal'
import useCampaignData from 'src/stores/campaignData'
import {
	useCampaignInfoStore,
	VinstanCreativeExternalType,
} from 'src/components/_client/campaign-info/store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from 'src/components/_client/campaign-info/store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import useNotificationData from 'src/stores/notificationsData'
import { CreativeTemplateKeysType } from '../../../constants'
import { CreateCreativeFields } from '../../../CreateCreative/CreateCreativeForm'
import { OrderLayoutModal } from '../../order-layout-modal'
import {
	DistrictBookingExternalType,
	PlacementType,
} from 'src/stores/ADMarketTypes.types'
import { bidInfo } from '../../../CreateCreative/useBidRequest'
import CreativeFileInput from 'src/components/UtilityComponents/CreativeFileInput'
import { EditVinstantModal } from 'src/components/_client/campaign-info/common/edit-vinstant-modal/EditVinstantModal'
import { EditVinstantInputButton } from 'src/components/_client/campaign-info/common/edit-vinstant-input-button/EditVinstantInputButton'
import { EditVinstantProcessingWrapped } from 'src/components/_client/campaign-info/common/edit-vinstant-processing/EditVinstantProcessing'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { InputExtraButtons } from '../common/input-extra-buttons/InputExtraButtons'

export const DistrictBookingsUploadByFormats = () => {
	const formats = useTechnicalRequirementsStore(getUniqDistrictsFormats)
	const requirementsLoading = useTechnicalRequirementsStore(
		(state) => state.isLoading
	)
	const linksIsLoading = useCampaignInfoStore((state) => state.isLoading)

	const isLoading = requirementsLoading || linksIsLoading
	return (
		<Card className={s.distictsUploadContainer}>
			<HeaderWithDescription isLoading={isLoading} />

			<AnimatePresence>
				<motion.div
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					initial={{ opacity: 0 }}
					className={s.distictsUploadItemsContainer}
				>
					{formats.map((el) => (
						<DistictsUploadItem
							key={el.id}
							format={el}
							onPlacementsClick={() => {}}
						/>
					))}
				</motion.div>
			</AnimatePresence>
		</Card>
	)
}

const getVirtualPlacementByFormat = (format: UniqDistrictBookingFormat) => {
	const { media_requirements, media_type_name } = format
	return {
		name: media_type_name,
		size: `до ${getMaxFileSize(format.media_requirements)} МБ`,
		media_type: {
			image_formats: media_requirements.image_formats,
			video_formats: media_requirements.video_formats,

			min_width: media_requirements.min_width,
			min_height: media_requirements.min_height,
			aspect_ratio: media_requirements.aspect_ratio,
		},
		image: 'https://storage.yandexcloud.net/admarket-images/static-img/outdoor_26_11zon.jpg',
		placement_type: {
			name: '',
		},
	} as Omit<PlacementType, 'placement_showing_counts'>
}
type ModalType =
	| 'requirements'
	| 'placements'
	| 'templates'
	| 'order'
	| 'editVinstant'

const modalTitles: Record<ModalType, string> = {
	requirements: 'Требования к загрузке',
	placements: 'Рекламные места',
	templates: 'Создание материала',
	order: 'Заказ материала',
	editVinstant: 'Редактирование винстанса',
}
type DistictsUploadItemProps = {
	format: UniqDistrictBookingFormat
	onPlacementsClick: () => void
}
export const DistictsUploadItem = ({ format }: DistictsUploadItemProps) => {
	const [modalType, setModalType] = useState<ModalType | null>(null)
	const [isLoading, setIsLoading] = useState(false)

	const { addToast } = useContext(ToastsContext)

	const [campaing, campaignInterface] = useCampaignData()
	const fetchTechnicalRequirements = useTechnicalRequirementsStore(
		(state) => state.fetchTechnicalRequirements
	)
	const fetchVinstantCreative = useCampaignInfoStore(
		(state) => state.fetchVinstantCreative
	)
	const [_, notificationsInterface] = useNotificationData()

	const editVinstantKey = format.delegate.id + `${format.external_id}`
	const editCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated(editVinstantKey)
	const editVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'booking_id',
			itemId: String(format.delegate.id),
			external_type: format.external_id as VinstanCreativeExternalType,
		})
	)

	const bidInfo: bidInfo = {
		id: format.delegate.id,
		type: 'districtbooking',
		external: format.external_id as DistrictBookingExternalType,
	}
	const screenResolution =
		`${format.media_requirements.max_width}*${format.media_requirements.max_height}` as CreativeTemplateKeysType

	const refetch = async () => {
		setIsLoading(true)
		const campaign = await campaignInterface.refetchSelected()

		if (campaign) {
			await fetchTechnicalRequirements(campaign)
			await fetchVinstantCreative(campaign.id)
		}
		setIsLoading(false)
	}
	const handleOrderLayoutSubmit = async (values: CreateCreativeFields) => {
		for (let key in values) {
			if (
				values[key] === null ||
				values[key] === undefined ||
				values[key] === ''
			) {
				delete values[key]
			}
		}

		await campaignInterface.createNewBidRequest(values, {
			district_booking_id: String(format.delegate.id),
			district_creative_type: format.external_id,
		})

		setModalType(null)
	}
	const handleUpload = () => {
		const url = `district_booking_creative/${format.media?.id}/download/`
		downloadByRequest(url, format.media?.file_name || '')
	}

	useEffect(() => {
		if (editCreativeVinstantStatus === 'end') {
			addToast({
				text: `Загрузка контента для ${format.media_type_name} завершена`,
				type: 'success',
			})
			notificationsInterface.setNewContendCreated({
				[editVinstantKey]: 'pending',
			})
			refetch()
		}
	}, [editCreativeVinstantStatus])
	return (
		<Card className={s.distictsUploadItem} id={`${format.media_type_name}`}>
			<Card.Header className={s.distictsUploadItemHeader}>
				<h5>{format.media_type_name}</h5>
				<span onClick={() => setModalType('placements')}>
					Рекламных мест: {format.placements.length}
				</span>
			</Card.Header>

			<Card.Body className="p-0">
				{editCreativeVinstantStatus === 'start' ? (
					<EditVinstantProcessingWrapped />
				) : (
					<CreativeFileInput
						ExtraButton={
							<InputExtraButtons
								onEditVinstant={
									editVinstantLink
										? () => setModalType('editVinstant')
										: undefined
								}
								onUpload={handleUpload}
							/>
						}
						description={getFileDescription(
							format.media_requirements
						)}
						maxSizeMb={getMaxFileSize(format.media_requirements)}
						allowedExtension={[
							...format.media_requirements.image_formats,
							...format.media_requirements.video_formats,
						]}
						bidInfo={bidInfo}
						loadingInProgress={
							editCreativeVinstantStatus === 'start'
						}
						initialValue={{
							name: format.media?.file_name || '',
							src: format.media?.file || '',
						}}
						nameInBody="file"
						onFileLoad={refetch}
						onFileDelete={refetch}
						additionalBody={{ external_type: format.external_id }}
						onLoadedMessage={'Материал успешно загружен'}
						updateUrl={`/core/api/v1/district_booking/${format.delegate.id}/creatives/`}
						removeUrl={`/core/api/v1/district_booking_creative/${format?.media?.id}/`}
						removeMethod="DELETE"
						updateMethod="POST"
					/>
				)}
			</Card.Body>

			<div className={s.distictsUploadItemFooter}>
				<Button
					disabled={
						isLoading ||
						!!format.media?.file ||
						editCreativeVinstantStatus === 'start'
					}
					variant="light"
					onClick={() => setModalType('templates')}
				>
					<h6>Создать материал</h6>
				</Button>
				<Button
					variant="light"
					onClick={() => setModalType('requirements')}
				>
					<h6>Требования к загрузке</h6>
				</Button>
			</div>

			<EditVinstantModal
				editVinstantLink={editVinstantLink}
				newContentKey={editVinstantKey}
				onClose={() => setModalType(null)}
				open={modalType === 'editVinstant'}
			/>
			<OrderLayoutModal
				onSubmit={handleOrderLayoutSubmit}
				open={modalType === 'order'}
				onClose={() => setModalType(null)}
			/>

			<TemplatesModal
				campaingId={campaing.id}
				closeModal={() => setModalType(null)}
				clsName="districtbooking"
				instanceId={String(format.delegate.id)}
				modalOpen={modalType === 'templates'}
				newContentKey={editVinstantKey}
				screenResolution={screenResolution}
				externalType={format.external_id as VinstanCreativeExternalType}
				getOrderLayoutModal={() => setModalType('order')}
				placement={getVirtualPlacementByFormat(format)}
			/>
			<CommonMediaModal
				onClose={() => setModalType(null)}
				open={
					!!modalType &&
					modalType !== 'templates' &&
					modalType !== 'order' &&
					modalType !== 'editVinstant'
				}
				title={modalTitles[modalType || 'requirements']}
			>
				{modalType === 'requirements' && (
					<MediaFormatRequirements
						mediaRequierments={format.media_requirements}
					/>
				)}
				{modalType === 'placements' && (
					<PlacementsOverview placements={format.placements} />
				)}
			</CommonMediaModal>
		</Card>
	)
}
export const HeaderWithDescription = (props: { isLoading: boolean }) => {
	return (
		<div className={s.headerWithDescrption}>
			<div className={s.headerTitle}>
				<div className="d-flex gap-2 align-items-center">
					<h5>Максимальный охват</h5>
					{props.isLoading && (
						<Spinner
							animation="border"
							size={'sm'}
							variant="primary"
						/>
					)}
				</div>

				<RadiusIcon />
			</div>
			<p>
				Минимальный объем размещения — экран 6x3 — это наиболее
				популярный формат в городах. Загруженные материалы будут
				транслироваться на экранах во всех выбранных городах.{' '}
			</p>
		</div>
	)
}
