import React, { useContext, useEffect, useState } from 'react'
import s from '../../districts/districtsUpload.module.scss'
import { Button } from 'react-bootstrap'
import { EditVinstantProcessingWrapped } from 'src/components/_client/campaign-info/common/edit-vinstant-processing/EditVinstantProcessing'
import CreativeFileInput from 'src/components/UtilityComponents/CreativeFileInput'
import {
	GarantidItem,
	MediaFormatViewModel,
	PlacementViewModel,
	useTechnicalRequirementsStore,
} from 'src/stores/technical-requirements-store'
import {
	downloadByRequest,
	getFileDescription,
	getMaxFileSize,
} from 'src/utils'
import { bidInfo } from '../../../../CreateCreative/useBidRequest'
import { deleteCompanyMedia, uploadCompanyMedia } from 'src/constants/api'
import { TemplatesModal } from '../../../templates-modal'
import useNotificationData from 'src/stores/notificationsData'
import { CreateCreativeFields } from '../../../../CreateCreative/CreateCreativeForm'
import useCampaignData from 'src/stores/campaignData'
import { CreativeTemplateKeysType } from '../../../../constants'
import { useCampaignInfoStore } from 'src/components/_client/campaign-info/store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from 'src/components/_client/campaign-info/store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { PlacementType } from 'src/stores/ADMarketTypes.types'
import { EditVinstantModal } from 'src/components/_client/campaign-info/common/edit-vinstant-modal/EditVinstantModal'
import { OrderLayoutModal } from '../../../order-layout-modal'
import { CommonMediaModal } from 'src/components/common/garantid-card/garantid-media-format-card/components/common-media/modals/CommonMediaModal'
import { InfoAboutPlaceWrapper } from 'src/components/common/garantid-card/garantid-media-format-card/components/common/InfoAboutPlaceWrapper'
import { MediaFormatRequirements } from 'src/components/common/garantid-card/garantid-media-format-card/components/common/MediaFormatRequirements'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import { InputExtraButtons } from '../../common/input-extra-buttons/InputExtraButtons'

type ModalType =
	| 'templates'
	| 'requirements'
	| 'editVinstant'
	| 'order'
	| 'placementInfo'

const modalTitles: Record<ModalType, string> = {
	requirements: 'Требования к загрузке',
	templates: 'Создание материала',
	order: 'Заказ материала',
	editVinstant: 'Редактирование винстанса',
	placementInfo: 'Подробнее о месте',
}

type Props = {
	format: MediaFormatViewModel<GarantidItem, PlacementViewModel>
	delegate: GarantidItem
}
export const MassUploadForm = ({ format, delegate }: Props) => {
	const bidInfo: bidInfo = {
		id: delegate.id,
		type: 'booking',
	}
	const { addToast } = useContext(ToastsContext)
	const [isLoading, setIsLoading] = useState(false)
	const [_, notificationsInterface] = useNotificationData()
	const [campaign, campaignInterface] = useCampaignData()
	const [modalType, setModalType] = useState<ModalType | null>(null)

	const fetchVinstantCreative = useCampaignInfoStore(
		(state) => state.fetchVinstantCreative
	)
	const fetchTechnicalRequirements = useTechnicalRequirementsStore(
		(state) => state.fetchTechnicalRequirements
	)

	const editVinstantKey = String(delegate.id)
	const editCreativeVinstantStatus =
		notificationsInterface.getNewContentCreated(editVinstantKey)
	const editVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'booking_id',
			itemId: editVinstantKey,
		})
	)
	const screenResolution =
		`${format.media_requirements.max_width}*${format.media_requirements.max_height}` as CreativeTemplateKeysType

	const placementShowingCount = campaign.booking.find(
		(el) => el.id === delegate.id
	)?.placement_showing_count

	const handleOrderLayoutSubmit = async (values: CreateCreativeFields) => {
		for (let key in values) {
			if (
				values[key] === null ||
				values[key] === undefined ||
				values[key] === ''
			) {
				delete values[key]
			}
		}

		if (delegate) {
			await campaignInterface.createNewBidRequest(values, {
				booking_id: String(delegate.id),
			})
		}

		setModalType(null)
	}
	const refetch = async () => {
		setIsLoading(true)
		try {
			const campaign = await campaignInterface.refetchSelected()

			if (campaign) {
				await fetchTechnicalRequirements(campaign)
				await fetchVinstantCreative(campaign.id)
			}
		} catch (e) {
		} finally {
			setIsLoading(false)
		}
	}
	const handleUpload = () => {
		if (delegate?.media_creative?.id) {
			downloadByRequest(
				`adv_company_media/${delegate.media_creative?.id}/download/`,
				delegate.media_creative?.file_name!
			)
		}
	}
	useEffect(() => {
		if (editCreativeVinstantStatus === 'end') {
			addToast({
				text: `Загрузка контента для ${format.media_type_name} завершена`,
				type: 'success',
			})
			notificationsInterface.setNewContendCreated({
				[editVinstantKey]: 'pending',
			})
			refetch()
		}
	}, [editCreativeVinstantStatus])
	return (
		<div className="d-flex flex-column gap-4">
			{editCreativeVinstantStatus === 'start' ? (
				<EditVinstantProcessingWrapped />
			) : (
				<CreativeFileInput
					ExtraButton={
						<InputExtraButtons
							onEditVinstant={
								editVinstantLink
									? () => setModalType('editVinstant')
									: undefined
							}
							onUpload={handleUpload}
						/>
					}
					description={getFileDescription(
						format.media_requirements,
						placementShowingCount
					)}
					maxSizeMb={getMaxFileSize(format.media_requirements)}
					allowedExtension={[
						...format.media_requirements.image_formats,
						...format.media_requirements.video_formats,
					]}
					bidInfo={bidInfo}
					updateUrl={uploadCompanyMedia}
					removeUrl={deleteCompanyMedia.params(
						delegate?.media_creative?.id || 0
					)}
					loadingInProgress={editCreativeVinstantStatus === 'start'}
					initialValue={{
						name: delegate.media_creative?.file_name || '',
						src: delegate.media_creative?.file || '',
					}}
					nameInBody="file"
					onFileLoad={refetch}
					onFileDelete={refetch}
					additionalBody={{
						booking_id: delegate.id,
						is_self_download: false,
					}}
					onLoadedMessage={'Материал успешно загружен'}
					removeMethod="DELETE"
					updateMethod="POST"
				/>
			)}

			<div className={s.distictsUploadItemFooter}>
				<Button
					variant="light"
					disabled={
						isLoading ||
						editCreativeVinstantStatus === 'start' ||
						!!delegate.media_creative?.file
					}
					onClick={() => setModalType('templates')}
				>
					<h6>Создать материал</h6>
				</Button>
				<Button
					variant="light"
					onClick={() => setModalType('requirements')}
				>
					<h6>Требования к загрузке</h6>
				</Button>
			</div>

			<TemplatesModal
				campaingId={campaign.id}
				closeModal={() => setModalType(null)}
				clsName="booking"
				instanceId={String(delegate.id)}
				modalOpen={modalType === 'templates'}
				newContentKey={editVinstantKey}
				screenResolution={screenResolution}
				getOrderLayoutModal={() => setModalType('order')}
				placement={
					campaign.booking.find(
						(booking) => booking.id === delegate.id
					)?.placement as unknown as
						| Omit<PlacementType, 'placement_showing_counts'>
						| undefined
				}
			/>

			<EditVinstantModal
				editVinstantLink={editVinstantLink}
				newContentKey={editVinstantKey}
				onClose={() => setModalType(null)}
				open={modalType === 'editVinstant'}
			/>
			<OrderLayoutModal
				onSubmit={handleOrderLayoutSubmit}
				open={modalType === 'order'}
				onClose={() => setModalType(null)}
			/>
			<CommonMediaModal
				onClose={() => setModalType(null)}
				open={
					!!modalType &&
					modalType !== 'templates' &&
					modalType !== 'order' &&
					modalType !== 'editVinstant'
				}
				title={modalTitles[modalType || 'requirements']}
			>
				{modalType === 'placementInfo' && (
					<InfoAboutPlaceWrapper bookingId={delegate.id} />
				)}
				{modalType === 'requirements' && (
					<MediaFormatRequirements
						mediaRequierments={format.media_requirements}
					/>
				)}
			</CommonMediaModal>
		</div>
	)
}
