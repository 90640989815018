import React, { useEffect, useMemo, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { BASE_URL } from 'src/constants/api'
import { authFetch } from 'src/utils/authFetch'
import { PlacementsImportExportActionSelect } from './PlacementsImportExportActionSelect'
import { ImportProcess } from './ImportProcess'
import { ImportConfirmation } from './ImportConfirmation'
import { ImportModalBody } from './ImportModalBody'
import { ExportModalBody } from './ExportModalBody'
export enum ImportState {
	PROCESSING = 'processing',
	ERROR = 'error',
	COMPLETED = 'completed',
	DOWNLOADED = 'downloaded',
}

export type ImportProcessData = {
	pk: number
	user_id: number
	state: ImportState
	size_file: string
	all_placements: number | null
	imported_placements: number
	imported_percent: number
}
const checkImportProcess = async () => {
	try {
		const importProcessData = await authFetch<ImportProcessData>({
			url: `${BASE_URL}/provider/import_placements/detail/`,
			method: 'GET',
		})
		return importProcessData
	} catch (e) {
		return null
	}
}

let intervalProcessCheckId: null | NodeJS.Timer = null

export const PlacementsImportExport = (props: any) => {
	const [modalType, setModalType] = useState<'import' | 'export' | null>(null)

	const [importProcessData, setImportProcessData] =
		useState<ImportProcessData | null>(null)

	const [importState, setImportState] = useState<ImportState | null>(null)

	const handleImportStarted = () => {
		intervalProcessCheckId = setInterval(async () => {
			console.log('running import process')

			const result = await checkImportProcess()
			setImportProcessData(result)

			if (result?.state) {
				setImportState(result?.state)
			} else {
				setImportState(null)
			}
		}, 1000)
	}

	const firstRenderCheckup = async () => {
		const result = await checkImportProcess()

		if (!!result?.state) {
			setImportState(result.state)
			setImportProcessData(result)
		}
	}

	const confirmImport = async (isConfirmed: boolean) => {
		const url = `${BASE_URL}/provider/import_placements/detail/${
			isConfirmed ? '?confirm' : ''
		}`

		await authFetch({
			url: url,
			method: 'POST',
		})

		setImportState(null)
	}

	const displayController = useMemo(() => {
		switch (importProcessData?.state) {
			case ImportState.COMPLETED:
			case ImportState.ERROR:
			case ImportState.DOWNLOADED:
				return (
					<ImportConfirmation
						confirmImport={confirmImport}
						importProcessData={importProcessData}
					/>
				)

			case ImportState.PROCESSING:
				return <ImportProcess importProcessData={importProcessData} />
		}
	}, [importProcessData])

	useEffect(() => {
		//start processing
		if (importState === ImportState.PROCESSING) {
			handleImportStarted()
		}

		//end processing
		if (
			!importState ||
			importState === ImportState.ERROR ||
			importState === ImportState.COMPLETED
		) {
			if (intervalProcessCheckId) {
				console.log(
					`closing import process, importState: ${importState}`
				)
				clearInterval(intervalProcessCheckId)
			}
		}
	}, [importState])

	useEffect(() => {
		//if process already runned
		firstRenderCheckup()
	}, [])
	return (
		<div>
			{importState ? (
				displayController
			) : (
				<PlacementsImportExportActionSelect
					setModalType={setModalType}
				/>
			)}

			{!!modalType && (
				<Modal
					show={modalType}
					onHide={() => setModalType(null)}
					centered
					size={'sm'}
					animation={true}
				>
					{modalType === 'import' && (
						<Modal.Header>
							<Modal.Title as={'h5'} className={'ms-2'}>
								Импорт рекламных носителей
							</Modal.Title>
						</Modal.Header>
					)}

					<Modal.Body className={'p-0'}>
						{modalType === 'import' ? (
							<ImportModalBody
								onClose={() => setModalType(null)}
								changeImportState={setImportState}
							/>
						) : (
							<ExportModalBody
								onClose={() => setModalType(null)}
							/>
						)}
					</Modal.Body>
				</Modal>
			)}
		</div>
	)
}

export type FileLoadResponse = {
	file: string
	id: number
	is_replacement: boolean
	provider: number
}
