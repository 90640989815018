import './styles/variables.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import React from 'react'
import ReactDOM from 'react-dom'
import AppRouter from './AppRouter'
import './styles/index.scss'
import './assets/fonts/Inter-Black.ttf'
import './assets/fonts/Inter-Thin.ttf'
import './assets/fonts/Inter-ExtraLight.ttf'
import './assets/fonts/Inter-Light.ttf'
import './assets/fonts/Inter-Regular.ttf'
import './assets/fonts/Inter-Medium.ttf'
import './assets/fonts/Inter-SemiBold.ttf'
import './assets/fonts/Inter-Bold.ttf'
import './assets/fonts/Inter-ExtraBold.ttf'
import Fallback from './components/UtilityComponents/Fallback'

ReactDOM.render(
	<React.StrictMode>
		<React.Suspense fallback={<Fallback />}>
			<AppRouter />
		</React.Suspense>
	</React.StrictMode>,
	document.getElementById('root')
)
