import React from 'react'
import ls from '../style.module.scss'
import { InternetAdType } from 'src/stores/ADMarketTypes.types'
import useModerationData from 'src/components/_moderator/store/moderationStore'
import cn from 'classnames'
import VkButtonTextMap from 'src/components/_client/create-campaign/Step3-Upload/Internet/VkButtonText.data'
type InternetInfoType = {
	type: 'yandex' | 'vk'
	item: InternetAdType
}
export const InternetInfo = ({ type, item }: InternetInfoType) => {
	const [moder] = useModerationData()

	return (
		<div
			className={ls.advItemInfo}
			style={{
				padding: '16px 0',
				borderBottom: '1px solid #CED4DA',
			}}
		>
			<div
				className={ls.advItemInfoColumn}
				style={{
					minWidth: 'auto',
					width: '100%',
				}}
			>
				<div
					className={cn(
						ls.advItemInfoField,
						ls.advItemInfoField_internet
					)}
				>
					<span>Caйт:</span>
					<a
						rel="noreferrer"
						target="_blank"
						href={item.link || ''}
						style={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{item.link}
					</a>
				</div>
				{type === 'vk' && (
					<div
						className={cn(
							ls.advItemInfoField,
							ls.advItemInfoField_internet
						)}
					>
						<span>Возраст:</span>
						<div>
							{moder.detailedCampaign.extra_data.age_titles.join(
								','
							)}
						</div>
					</div>
				)}

				<div
					className={cn(
						ls.advItemInfoField,
						ls.advItemInfoField_internet
					)}
				>
					<span>Заголовок:</span>
					<div>{item.heading}</div>
				</div>
				<div
					className={cn(
						ls.advItemInfoField,
						ls.advItemInfoField_internet
					)}
				>
					<span>Описание:</span>
					<div>{item.text}</div>
				</div>

				{type === 'vk' && (
					<div
						className={cn(
							ls.advItemInfoField,
							ls.advItemInfoField_internet
						)}
					>
						<span>Кнопка:</span>
						<div>{VkButtonTextMap.get(item.button_text || '')}</div>
					</div>
				)}
			</div>
		</div>
	)
}
