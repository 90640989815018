import React, { useEffect, useState } from 'react'
import s from './PickerMobileBottomPannel.module.scss'
import {
	AnimatePresence,
	motion,
	PanInfo,
	useDragControls,
} from 'framer-motion'

const sizeConstants = {
	search: {
		max: '80vh',
		min: '15vh',
	},
	details: {
		max: '90vh',
		min: '30vh',
	},
}

interface IPickerMobileBottomPannel {
	show: boolean
	Footer?: React.ReactNode
	withCloseButton?: boolean
	type: 'search' | 'details'
	closeButtonAction?: () => void
}

const PickerMobileBottomPannel: React.FC<IPickerMobileBottomPannel> = (
	props
) => {
	return (
		<AnimatePresence exitBeforeEnter>
			{props.show && <Body {...props} />}
		</AnimatePresence>
	)
}

export default PickerMobileBottomPannel

const Body: React.FC<IPickerMobileBottomPannel> = ({
	children,
	show,
	Footer,
	withCloseButton,
	type,
	closeButtonAction,
}) => {
	const controls = useDragControls()

	const [maxHeight] = useState(() => {
		if (type === 'search') return sizeConstants.search.max
		if (type === 'details') return sizeConstants.details.max
	})

	const [isExpanded, setIsExpanded] = useState(false)

	useEffect(() => {
		if (show) {
			document.body.style.overflow = 'hidden'
		}
		return () => {
			document.body.style.overflow = ''
		}
	}, [show])
	const onDrag = (
		event: MouseEvent | TouchEvent | PointerEvent,
		info: PanInfo
	) => {
		// return console.log(info.offset);
		if (info.offset.y < -100) {
			if (!isExpanded) setIsExpanded(true)
		}
		if (info.offset.y > 100) {
			if (isExpanded) setIsExpanded(false)
		}
	}

	const variants = {
		init: {
			top: '110%',
			maxHeight,
			height:
				type === 'search'
					? sizeConstants.search.min
					: sizeConstants.details.min,
		},
		half: {
			top: `calc(100% - ${
				type === 'search'
					? sizeConstants.search.min
					: sizeConstants.details.min
			} `,
			maxHeight: `${
				type === 'search'
					? sizeConstants.search.min
					: sizeConstants.details.min
			}`,
			height: `${
				type === 'search'
					? sizeConstants.search.min
					: sizeConstants.details.max
			}`,
		},
		full: {
			top: `calc(100% - ${
				type === 'search'
					? sizeConstants.search.max
					: sizeConstants.details.max
			} `,
			maxHeight: `${
				type === 'search'
					? sizeConstants.search.max
					: sizeConstants.details.max
			}`,
			height: `${
				type === 'search'
					? sizeConstants.search.max
					: sizeConstants.details.max
			}`,
		},
	}
	return (
		<motion.div
			key={'content'}
			className={s.sheet}
			initial={'init'}
			variants={variants}
			animate={isExpanded ? 'full' : 'half'}
			dragListener={false}
			dragControls={controls}
			drag="y"
			onDragEnd={onDrag}
			dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
			dragMomentum={false}
			transition={{ duration: 0.3 }}
			onClick={(e) => {}}
		>
			<motion.div
				className={s.touch}
				onPointerDown={(e) => controls.start(e)}
			/>
			<motion.div className={s.children}>
				{withCloseButton && (
					<div
						className={s.close}
						onClick={() => {
							if (closeButtonAction) closeButtonAction()
						}}
					>
						<i className="bi bi-x" style={{ fontSize: '20px' }} />
					</div>
				)}
				{typeof children === 'function'
					? children({ isExpanded: isExpanded })
					: children}
			</motion.div>
			{Footer && <div className={s.footer}>{Footer}</div>}
		</motion.div>
	)
}
