import React from 'react'
import s from '../button.module.scss'

type Props = {
	onClick: () => void
	text?: string
}
export const IconButtonDelete = ({ text = 'Удалить', onClick }: Props) => {
	return (
		<div
			onClick={onClick}
			role="button"
			tabIndex={0}
			className={s.iconButtonDelete}
		>
			<i className="bi bi-trash" />
			<p>{text}</p>
		</div>
	)
}
