import React from 'react'
import { Modal } from 'react-bootstrap'
import { CampaignBadge } from 'src/components/_client/campaigns-list/ClientStatistics'
import declOfNum from 'src/utils/declOfNum'
import { DetailItemNew } from 'src/utils/DetailItem'
import IntlFormatter from 'src/utils/intl-money-formatter'
import BookingsButtons from '../details/BookingsButtons'
import { ReactComponent as Close } from '../../../../../assets/images/close cancel x.svg'
import s from './list.module.scss'
import moment from 'moment'
import { useMemo } from 'react'

export function SidebarBookingDetail({ placement, booking, onHide }) {
	const start_date = moment(booking?.advertising_company.start_date)
	const end_date = moment(booking?.advertising_company.end_date)

	const duration_in_days = useMemo(() => {
		return end_date.diff(start_date, 'day') + 1
	}, [start_date, end_date])

	const duration_in_weeks = useMemo(() => {
		const col = Math.round(
			(moment(end_date).diff(moment(start_date), 'd') + 1) / 7
		)
		return `${col} ${declOfNum(col, ['неделя', 'недели', 'недель'])}`
	}, [start_date, end_date])

	return (
		<Modal
			style={{
				borderLeft: '1px solid #CED4DA',
				margin: 0,
			}}
			className={s.rightSidebar}
			onHide={onHide}
			show
		>
			<Modal.Header
				style={{
					borderBottom: 'none',
					padding: '24px 24px 8px 24px',
				}}
				closeButton
			>
				<div className={s.rightSidebarHeaderContainer}>
					<CampaignBadge state={booking?.advertising_company.state} />
					<h2 className={s.rightSidebarHeaderText}>
						{booking.advertising_company.company.legal_short_name}
					</h2>
				</div>
			</Modal.Header>

			<Modal.Body
				style={{
					overflowX: 'hidden',
					padding: '16px 24px 0 24px',
				}}
			>
				<div className={s.rightSidebarAdvertisingGropup}>
					<h1 className={s.rightSidebarAdvertisingGropupHeader}>
						Данные о рекламной компании
					</h1>

					<div className={s.rightSidebarAdvertisingGropupContent}>
						<DetailItemNew
							left={'Название:'}
							right={booking.advertising_company.name}
							size={16}
						/>

						<DetailItemNew
							left={'Срок размещения:'}
							right={
								<>
									{duration_in_weeks} (
									{duration_in_days +
										' ' +
										declOfNum(duration_in_days, [
											'день',
											'дня',
											'дней',
										])}
									)
								</>
							}
							size={16}
						/>

						<DetailItemNew
							left={'Дата начала и окончания:'}
							right={
								<>
									{start_date.format('DD.MM.YY')} -{' '}
									{end_date.format('DD.MM.YY')}
								</>
							}
							size={16}
						/>

						{/* {placement.door_type === 'outdoor' && (
							<DetailItemNew
								left={'Стоимость в день:'}
								right={DisplayPlacementPriceString(
									bookingInfo?.placement_showing_count
										?.showing_count_per_day,
									bookingInfo?.placement_showing_count?.price
								)}
								size={16}
							/>
						)}

						{placement.door_type === 'indoor' && (
							<DetailItemNew
								left={'Стоимость в день:'}
								right={DisplayInnerPlacementPriceString(
									bookingInfo?.placement.screen_count,
									bookingInfo?.placement_showing_count?.price
								)}
								size={16}
							/>
						)} */}

						<DetailItemNew
							left={'Стоимость за период:'}
							right={IntlFormatter.format(booking?.amount)}
							size={16}
							noBorder
						/>
					</div>
				</div>

				<div className={s.rightSidebarClientGroup}>
					<h1>Данные о заказчике</h1>
					<div className={s.rightSidebarClientGroupContent}>
						<DetailItemNew
							left={'Телефон:'}
							right={
								booking.advertising_company.company
									.owner_phone_number
							}
							size={16}
						/>

						<DetailItemNew
							left={'Email:'}
							right={
								booking.advertising_company.company.owner_email
							}
							size={16}
						/>

						<DetailItemNew
							left={'Название:'}
							right={
								booking.advertising_company.company
									.legal_short_name
							}
							size={16}
						/>

						<DetailItemNew
							left={'Организация или ИП:'}
							right={
								booking.advertising_company.company.legal_name
							}
							size={16}
							noBorder
						/>
					</div>
				</div>

				<div className={s.rightSidebarDownloadGroup}>
					{/* <h1>Загруженные материалы</h1>
					<div className={s.rightSidebarDownloadGroupContent}>
						<ProgressFileInput
							initialValue={{
								src:
									bookingInfo.media_creative?.file ||
									'https://storage.yandexcloud.net/admarket-media-dev/empty_VmO0bhA.jpg',
								name:
									bookingInfo.media_creative?.file_name ||
									'Изображения нет',
							}}
							updateUrl={''}
							nameInBody={''}
							onlyView
							noPadding
						/>
					</div> */}
				</div>

				<div className={s.rightSidebarButtonFrame}>
					<div className={s.buttonsContainer}>
						<div
							style={{
								height: '70px',
								fontSize: 'small',
							}}
						>
							<BookingsButtons booking={booking} />
						</div>
					</div>
				</div>

				<div onClick={onHide} className={s.closeFrame}>
					<Close />
				</div>
			</Modal.Body>
		</Modal>
	)
}
