import React from 'react'
import { motion } from 'framer-motion'
import { ConditionArrowLeftRight } from 'src/components/UtilityComponents'
import {
	MobileFilters,
	MobileFiltersKeyType,
} from 'src/components/_provider/Bookings/filters/booking-filters-mobile/BookingsSettings'
import { PlacementFilterType } from '../../constants'
import { PlacementFilterKeyType } from '../../constants/placementFilters'

type ProviderMobileFiltersListProps = {
	filters: PlacementFilterType[]
	setCurrentFilter: (key: PlacementFilterKeyType) => void
}

const list = {
	visible: { opacity: 1 },
	hidden: { opacity: 0 },
}

const motionItem = {
	visible: { opacity: 1, x: 0 },
	hidden: { opacity: 0, x: -100 },
}
export const ProviderMobileFiltersList = ({
	filters,
	setCurrentFilter,
}: ProviderMobileFiltersListProps) => {
	return (
		<motion.div
			variants={list}
			initial="hidden"
			animate="visible"
			style={{
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			{filters.map((filter) => (
				<motion.div
					variants={motionItem}
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						padding: '8px 16px',
						borderBottom: '1px solid #CED4DA',
					}}
					key={filter.key}
					onClick={() => setCurrentFilter(filter.key)}
				>
					<div style={{ display: 'flex' }}>
						<div>{filter.title}</div>
						<div
							style={{
								borderRadius: '50%',
								minWidth: '24px',
								height: '24px',
								background: '#F5F5F5',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								fontSize: '12px',
								marginLeft: '8px',
							}}
						>
							{filter?.filterItems?.length || 0}
						</div>
					</div>

					<ConditionArrowLeftRight
						styles={{ width: '16px', fill: '#6C757D' }}
						open={false}
					/>
				</motion.div>
			))}
		</motion.div>
	)
}
