import React from 'react'
import s from './customRange.module.scss'
import cn from 'classnames'

type Props = {
	max: number
	min: number
	value: number
	onChange: (value: number) => void
}
export const CustomRange = ({ max, min, value, onChange }: Props) => {
	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		onChange(e.target.valueAsNumber)
	}

	const progress = ((value - min) / (max - min)) * 100

	return (
		<input
			type="range"
			max={max}
			min={min}
			value={value}
			className={cn(s.customRange)}
			style={{
				background: `linear-gradient(to right, #007bff ${progress}%, #ced4da ${progress}%)`,
			}}
			onChange={handleOnChange}
		/>
	)
}
