import React, { FC } from 'react'
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { AutocompleteInput } from '../../../UtilityComponents/AutocompleteInput'
import { TOOLTIPS } from '../tooltips.data'
import FormLabelTooltip from '../../../UtilityComponents/FormLabelTooltip'
import { CompanyType } from '../../../../stores/ADMarketTypes.types'
import { BusinessFormType, IndustryTypes } from './BusinessDataForm'
import AddressPicker from './AdressPicker'
import useResponsive from '../../../../utils/useResponsive'

interface ICompany {
	isPreset: boolean
	companiesList: CompanyType[]
	presetCompany: CompanyType | null
	setPresetCompany: React.Dispatch<React.SetStateAction<CompanyType | null>>
	values: BusinessFormType
	setFieldError: Function
	setFieldValue: Function
	errors: { [key in keyof BusinessFormType]?: string }
	industryTypes: IndustryTypes
	cn: any
}

const Company: FC<ICompany> = ({
	values,
	setFieldValue,
	setFieldError,
	errors,
	isPreset,
	cn,
}) => {
	const { isMobile } = useResponsive()
	return (
		<>
			<InputGroup hasValidation className={'mt-2'}>
				<AutocompleteInput
					value={values.legal_short_name}
					onChange={(e) => {
						setFieldError('legal_short_name', null)
						setFieldValue('legal_short_name', e.target.value)
					}}
					onSelect={(data) => {
						setFieldError('inn', null)
						setFieldError('kpp', null)
						setFieldError('legal_short_name', null)
						setFieldError('legal_name', null)
						setFieldValue('inn', data.inn)
						setFieldValue('kpp', data.kpp)
						setFieldValue('legal_short_name', data.legal_short_name)
						setFieldValue('legal_name', data.legal_name)
					}}
					error={errors.inn || errors.legal_name}
					type={'company'}
					name={'legal_name'}
					disabled={isPreset}
					readOnly={isPreset}
					placeholder={'Введите название, адрес, ИНН или ОГРН'}
				/>
			</InputGroup>
			{values.legal_name && (
				<>
					<InputGroup hasValidation>
						<Form.Label>Полное название организации </Form.Label>
						<Form.Control
							value={values.legal_name}
							size="lg"
							type={'text'}
							className={'rounded'}
							as={'textarea'}
							style={{ resize: 'none' }}
							onChange={(e) => {
								setFieldError('legal_name', null)
								setFieldValue('legal_name', e.target.value)
							}}
							isInvalid={!!errors.legal_name}
							disabled={true}
						/>
						<Form.Control.Feedback type="invalid">
							{errors.legal_name}
						</Form.Control.Feedback>
					</InputGroup>
					<Row>
						<Col className={cn('column')}>
							<InputGroup hasValidation>
								<Form.Label>ИНН</Form.Label>
								<Form.Control
									value={values.inn}
									size="lg"
									type={'text'}
									className={'rounded'}
									onChange={(e) => {
										setFieldError('inn', null)
										setFieldValue('inn', e.target.value)
									}}
									isInvalid={!!errors.inn}
									disabled={true}
								/>
								<Form.Control.Feedback type="invalid">
									{errors.inn}
								</Form.Control.Feedback>
							</InputGroup>
						</Col>
						<Col>
							<InputGroup hasValidation>
								<Form.Label>КПП</Form.Label>
								<Form.Control
									value={values.kpp}
									size="lg"
									type={'text'}
									className={'rounded'}
									disabled={true}
								/>
							</InputGroup>
						</Col>
					</Row>
				</>
			)}

			{
				<AddressPicker
					clearError={() => {
						setFieldError('actual_location', null)
						setFieldError('actual_location_lat', null)
						setFieldError('actual_location_lon', null)
					}}
					value={values.actual_location}
					setFieldValue={setFieldValue}
					cn={cn}
					name={'actual_location'}
					error={errors.actual_location}
					isMobile={isMobile}
					disabled={isPreset}
					textarea={true}
				/>
			}
		</>
	)
}

export default Company
