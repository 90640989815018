import React, { useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { Form, InputGroup, Modal } from 'react-bootstrap'
import { DatePicker } from './DropdownDatePicker'
import bem from '../../utils/bem'
import useResponsive from 'src/utils/useResponsive'
import { DateInput } from './DateInput'
export function normalizeMomentDate(momentDate) {
	return momentDate ? momentDate.toDate() : momentDate
}

const cn = bem('custom-date-picker')

function getDate(string: string) {
	if (string.length === 10) {
		const res = /(\d{2})\.(\d{2})\.(\d{4})/.exec(string)
		if (res) {
			const [, day, month, year] = res
			if (!day || !month || !year) {
				return new Date()
			} else {
				return new Date(+year, +month - 1, +day)
			}
		} else {
			return new Date()
		}
	} else {
		return new Date()
	}
}

const ModalDateInput = ({
	value,
	onChange,
	error,
	title = '' as string | React.ReactNode,
	disabled = false,
	className = '',
	firstAvaliableDate,
	comparisonDay = null as any,
	type = '',
}) => {
	const [pendingValue, setPendingValue] = useState(value)
	const dateValue = useMemo(() => getDate(pendingValue), [pendingValue])
	const [currentMonth, setCurrentMonth] = useState(firstAvaliableDate)
	const [key, setKey] = useState(0)
	const [show, setShow] = useState(false)
	useEffect(() => {
		setPendingValue(value)
	}, [value])

	const { isMobile } = useResponsive()

	function handleChange(v) {
		setPendingValue(v)

		const dateRegex = /^\d{2}\.\d{2}\.\d{4}$/

		if (dateRegex.test(v)) {
			// Parse the date string using moment
			const newDate = moment(v, 'DD.MM.YYYY', true)

			// Check if the parsed date is valid
			if (newDate.isValid()) {
				onChange(newDate.toDate())
			}
		}
	}

	function handleCurrentMonthChange(date) {
		setCurrentMonth(date)
		setKey((k) => (k ? 0 : 1))
	}

	const handleModalClose = () => {
		setShow(false)
	}
	return (
		<div
			className={cn('input', bem.pass(className))}
			style={{ width: isMobile ? '100%' : '' }}
		>
			<InputGroup hasValidation>
				{!!title && (
					<Form.Label
						className="m-0"
						onClick={(e) => {
							e.stopPropagation()
						}}
					>
						{title}
					</Form.Label>
				)}

				<DateInput
					disabled={disabled}
					onIconClick={() => setShow(true)}
					handleChange={handleChange}
					pendingValue={pendingValue}
					type={type}
					error={error}
				/>
				<Form.Control.Feedback
					type="invalid"
					style={{ display: error ? 'block' : 'none' }}
				>
					{error}
				</Form.Control.Feedback>

				<Modal
					show={show}
					onHide={() => setShow(false)}
					centered
					contentClassName={'modal-date-picker-content'}
				>
					<div key={key}>
						<DatePicker
							value={dateValue}
							onChange={(date) => {
								const text = moment(date).format('DD.MM.YYYY')
								handleChange(text)
								handleModalClose()
							}}
							initialVisibleMonth={() => currentMonth}
							onPrevYear={() =>
								handleCurrentMonthChange(
									currentMonth.clone().subtract(1, 'y')
								)
							}
							comparisonDay={comparisonDay}
							onNextYear={() =>
								handleCurrentMonthChange(
									currentMonth.clone().add(1, 'y')
								)
							}
							firstAvaliableDate={firstAvaliableDate}
						/>
					</div>
				</Modal>
			</InputGroup>
		</div>
	)
}

export default ModalDateInput
