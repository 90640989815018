import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Button, Form, InputGroup, Modal } from 'react-bootstrap'
import s from './providerFilter.module.scss'
import cn from 'classnames'
import {
	PlacementFilterKeyType,
	PlacementFilterType,
} from '../../constants/placementFilters'
import { ProviderCampaignsFilterContent } from '../placement-settings/ProviderCampaignsFilterContent'
import { ProviderMobileFilterContent } from '../placement-settings/ProviderMobileFilterContent'

type ModalSetFilters = {
	open: boolean
	onHide: () => void
	filters: Array<PlacementFilterType> | undefined
	onSetFilter: (key: PlacementFilterKeyType, value: Array<string>) => void
	setFilters?: React.Dispatch<React.SetStateAction<PlacementFilterType[]>>
}

export const ProviderModalFiltersView: FC<ModalSetFilters> = ({
	open,
	onHide,
	filters,
	onSetFilter,
	setFilters,
}) => {
	const [currentTab, setCurrentTab] = useState<PlacementFilterKeyType>(
		'advertising_company'
	)

	const [localFilters, setLocalFilters] = useState(filters)

	const [isLocalFiltersChanged, setIsLocalFiltersChanged] = useState(false)

	const handleOnFilterChange = (filterKey, value: Array<string>) => {
		if (localFilters) {
			const newLocalFilters = localFilters.map((filter) =>
				filter.key === filterKey
					? { ...filter, filterItems: value }
					: filter
			)
			setLocalFilters(newLocalFilters)
			setIsLocalFiltersChanged(true)
		}
	}
	const handleSubmit = () => {
		if (setFilters && localFilters) {
			setFilters(localFilters)
		}
		onHide()
	}

	return (
		<Modal
			show={open}
			size="lg"
			aria-labelledby="contained-modal-title-vcenter"
			centered
			onHide={onHide}
			animation={false}
			style={{ background: 'rgba(0, 0, 0, 0.5)' }}
			contentClassName={cn(s.modalContent)}
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Настройка фильтров
				</Modal.Title>
			</Modal.Header>
			<Modal.Body
				style={{
					display: 'flex',
					height: '100%',
					overflowY: 'hidden',
					padding: 0,
				}}
			>
				<div
					style={{
						minWidth: '200px',
						borderRight: '1px solid #CED4DA',
						padding: '8px 0',
					}}
				>
					{localFilters &&
						localFilters.map((filter) => (
							<div
								key={filter.key}
								className={cn(s.modalTab, {
									[s.modalTab_active]:
										filter.key === currentTab,
								})}
								onClick={() => setCurrentTab(filter.key)}
							>
								<div>{filter.title}</div>
								<div>{filter?.filterItems?.length}</div>
							</div>
						))}
				</div>
				<ProviderMobileFilterContent
					filters={localFilters}
					onChange={handleOnFilterChange}
					filterKey={currentTab}
					onClose={onHide}
					setFilter={onSetFilter}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onHide}>
					Отменить
				</Button>
				<Button
					disabled={!isLocalFiltersChanged}
					variant="primary"
					onClick={handleSubmit}
				>
					Применить
				</Button>
			</Modal.Footer>
		</Modal>
	)
}
// styles={{
//     padding: 0,
//     maxHeight: '100vh',
//     height: '100vh',
//     display: 'flex',
//     flexDirection: 'column',
// }}
// paddingContent="0"
// contentStyles={{
//     overflow: 'hidden',
//     height: '100%',
//     flex: '1 1 auto',
// }}
