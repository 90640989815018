import { CampaignInfoTypeBookingsSeparation } from 'src/stores/ADMarketTypes.types'

//в самой форме ограничение на 10 мест, но не все города имеют такое колличество
const minPlacementsCountInMassCoverage = 0

export const checkDistrictBookingsPlacementsCount = (
	campaign: CampaignInfoTypeBookingsSeparation
): boolean => {
	const districtBookingsWithIncorrectPlacementsCount =
		campaign.districtbookings.filter(
			(el) => el.count_placements <= minPlacementsCountInMassCoverage
		)

	if (districtBookingsWithIncorrectPlacementsCount.length > 0) {
		return false
	}

	return true
}
