import React from 'react'
import { Modal } from 'react-bootstrap'
import MobileModal from 'src/components/UtilityComponents/MobileModal'
import useResponsive from 'src/utils/useResponsive'
import { TvType } from '../../TvStateView.types'
import { RenderRequirements } from '../../TvModalDesktop'
import s from '../tvAdsteam.module.scss'

type Props = {
	open: boolean
	onClose: () => void
}
export const AdstreamRequirements = ({ onClose, open }: Props) => {
	const { isMobile } = useResponsive()

	const modalTitle = 'Технические требования к загрузке видеофайла'
	return (
		<div>
			{isMobile ? (
				<MobileModal
					withCloseButton={true}
					title={
						<p className={s.adstreamRequirementsTitle}>
							{modalTitle}
						</p>
					}
					contentStyles={{ padding: 0 }}
					onHide={onClose}
					show={open}
				>
					<RenderRequirements tvType={TvType.ADSTREAM} />
				</MobileModal>
			) : (
				<Modal show={open} onHide={onClose} centered>
					<Modal.Header closeButton>
						<Modal.Title as={'h5'} style={{ marginLeft: '8px' }}>
							{modalTitle}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body className={'p-0'}>
						<RenderRequirements tvType={TvType.ADSTREAM} />
					</Modal.Body>
				</Modal>
			)}
		</div>
	)
}
