import React, { Children } from 'react'
import s from './commonStyles.module.scss'
import cn from 'classnames'

type ReportCheckBoxProps = {
	checked: boolean
	onChange: () => void
	text: string
	children?: React.ReactNode
}
export const ReportCheckBox = ({
	checked,
	onChange,
	text,
	children,
}: ReportCheckBoxProps) => {
	return (
		<div
			onClick={onChange}
			className={cn(s.reportCheckBox, {
				[s.reportCheckBox_withChildren]: !!children,
			})}
		>
			<input type="checkbox" checked={checked} />
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span>{text}</span>
				{children}
			</div>
		</div>
	)
}
