import React from 'react'
import { Button, Spinner } from 'react-bootstrap'
import s from './bottomNav.module.scss'

type Props = {
	onBack: () => void
	onNext: () => void
	isLoading: boolean
	nextDisabled: boolean
}
export const BottomNav = ({
	isLoading,
	onBack,
	onNext,
	nextDisabled,
}: Props) => {
	return (
		<div className={s.bottomNav}>
			<Button variant="secondary" onClick={onBack}>
				Назад
			</Button>
			<Button variant="primary" onClick={onNext} disabled={nextDisabled}>
				<div className="d-flex gap-2 align-items-center justify-content-center">
					<p className="m-0">Добавить в кампанию</p>{' '}
					{isLoading && <Spinner animation="border" size="sm" />}
				</div>
			</Button>
		</div>
	)
}
