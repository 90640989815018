import React, { FC, useEffect, useState } from 'react'
import s from './ContentLayout.module.scss'
import BookingsList from './list/BookingsList'
import BookingsPagination from './pagination/BookingsPagination'
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill'
import BookingDetailsController from './details/BookingDetailsController'
import useResponsive from 'src/utils/useResponsive'
import cn from 'classnames'
import { BookingDetails } from 'src/stores/ProviderTypes.types'
import MobileModal from 'src/components/UtilityComponents/MobileModal'
import BookingsDetailsLayout from './details/BookingsDetailsLayout'
import useProviderData from 'src/stores/providerData'
import useCampaignData from 'src/stores/campaignData'
import { authFetch } from 'src/utils/authFetch'
import { providerBookingInfo } from 'src/constants/api'
import BottomSheet from 'src/components/UtilityComponents/Bottomsheet'

const ContentLayout: FC = () => {
	const { width, ref } = useResizeDetector()

	const [_, campaignInterface] = useCampaignData()
	const [provider, providerInterface] = useProviderData()
	const { isMobile, isDesktop, isTablet } = useResponsive()

	const [selectedBookingId, setSelectedBookingId] = useState<
		number | undefined
	>(undefined)

	const [booking, setBooking] = useState<BookingDetails | undefined>(
		undefined
	)
	const [isLoading, setIsLoading] = React.useState(false)

	const handleModalClose = () => {
		setSelectedBookingId(undefined)
		setBooking(undefined)
	}
	const fetchBookingById = async () => {
		setIsLoading(true)
		try {
			const res = await authFetch<BookingDetails>({
				url: providerBookingInfo.params(selectedBookingId),
				method: 'GET',
			})

			setBooking(res)
		} catch (e) {
			console.log(e)
		}
		setIsLoading(false)
	}
	useEffect(() => {
		if (selectedBookingId) {
			fetchBookingById()
		}
	}, [selectedBookingId])

	return (
		<>
			<section
				className={cn(s.contentLayout, {
					[s.contentLayout_mobile]: isMobile || isTablet,
				})}
			>
				<div ref={ref}>
					<BookingsList setSelectedBookingId={setSelectedBookingId} />
					<BookingsPagination layoutWidth={width} />
				</div>
				{isDesktop && (
					<div>
						<BookingDetailsController
							onModalClose={handleModalClose}
						/>
					</div>
				)}
			</section>

			{isMobile && (
				<MobileModal
					defaultTouchingSetup={false}
					show={!!booking}
					onHide={handleModalClose}
					title={booking?.placement?.placement_type?.name || ''}
					paddingContent="0"
				>
					<BookingsDetailsLayout
						onModalClose={handleModalClose}
						booking={booking!}
					/>
				</MobileModal>
			)}

			{isTablet && (
				<BottomSheet
					show={!!booking}
					onHide={handleModalClose}
					fullSize
				>
					<BookingsDetailsLayout
						onModalClose={handleModalClose}
						booking={booking!}
					/>
				</BottomSheet>
			)}
		</>
	)
}

export default ContentLayout
