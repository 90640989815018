import React from 'react'
import {
	MoscowCentRingRequirementsArticle,
	ArticleItem,
} from './MoscowCentRingRequirementsArticle'

export const MCRStaticContentArticle = (props: any) => {
	return (
		<MoscowCentRingRequirementsArticle title="Дла статичного контента">
			<>
				<ArticleItem title="Формат:">
					<>JPG с минимальной степенью сжатия</>
				</ArticleItem>
				<ArticleItem title="Цветовая модель:">
					<>RGB</>
				</ArticleItem>
				<ArticleItem title="Размер:" style={{ border: 'none' }}>
					<>до 5 МБ</>
				</ArticleItem>
			</>
		</MoscowCentRingRequirementsArticle>
	)
}
