import React from 'react'
import { AdvFormats } from '../../types'
import { SelectedAdvFormat } from './selected-adv-format/SelectedAdvFormat'

type Props = {
	goToSelectors: () => void
	selectedAdvFormat: AdvFormats
	cn: (className: string) => void
	onBack: () => void
	onNextStep: () => void
}
export const AdvFormatStage = ({
	cn,
	onBack,
	onNextStep,
	selectedAdvFormat,
	goToSelectors,
}: Props) => {
	return (
		<>
			<SelectedAdvFormat
				goToSelectors={goToSelectors}
				selectedAdvFormat={selectedAdvFormat}
				onBack={onBack}
				cn={cn}
				onNextStep={onNextStep}
			/>
		</>
	)
}
