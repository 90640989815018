import {
	NotificationAdvStateCountKeys,
	NotificationAdvStateCounts,
	NotificationAdvTypeChoises,
	NotificationAdvTypeCountKeys,
	NotificationAdvTypeCounts,
	NotificationStateAdvChoises,
} from '../types/ModerationRequestTypes'

export type AdvStateCountItemType = {
	title: string
	count: number
	isSelected: boolean
	state: NotificationStateAdvChoises
}
export type AdvChoisesByState = Record<
	NotificationAdvStateCountKeys,
	AdvStateCountItemType
>
export const getNotficationAdvStateCounts = (
	counts: NotificationAdvStateCounts,
	selectedItem: NotificationStateAdvChoises[]
): AdvChoisesByState => {
	return {
		count_adjustment: {
			title: 'На исправлении',
			count: counts.count_adjustment,
			isSelected: selectedItem.includes('adjustment'),
			state: 'adjustment',
		},
		count_canceled: {
			title: 'Отмененные',
			count: counts.count_canceled,
			isSelected: selectedItem.includes('canceled'),
			state: 'canceled',
		},
		count_completed: {
			title: 'Завершенные',
			count: counts.count_completed,
			isSelected: selectedItem.includes('completed'),
			state: 'completed',
		},
		count_start_waiting: {
			title: 'Ожидают запуска',
			count: counts.count_start_waiting,
			isSelected: selectedItem.includes('start_waiting'),
			state: 'start_waiting',
		},
		count_moderation: {
			title: 'На модерации',
			count: counts.count_moderation,
			isSelected: selectedItem.includes('moderation'),
			state: 'moderation',
		},
		count_reject: {
			title: 'Отклоненные',
			count: counts.count_reject,
			isSelected: selectedItem.includes('reject'),
			state: 'reject',
		},
	}
}

export type AdvTypeCountItemType = {
	title: string
	count: number
	isSelected: boolean
	advType: NotificationAdvTypeChoises
}
export type AdvChoisesByAdvType = Record<
	NotificationAdvTypeCountKeys,
	AdvTypeCountItemType
>
//TODO придумать как заменить статику на итератор
export const getNotficationAdvTypeCounts = (
	counts: NotificationAdvTypeCounts,
	selectedItems: NotificationAdvTypeChoises[]
): AdvChoisesByAdvType => {
	return {
		count_type_outdoor: {
			title: 'Наружная реклама',
			count: counts.count_type_outdoor,
			isSelected: selectedItems.includes('outdoor'),
			advType: 'outdoor',
		},
		count_type_indoor: {
			title: 'Внутренняя реклама',
			count: counts.count_type_indoor,
			isSelected: selectedItems.includes('indoor'),
			advType: 'indoor',
		},
		count_type_internet_adv: {
			title: 'Интернет-реклама',
			count: counts.count_type_internet_adv,
			isSelected: selectedItems.includes('internet'),
			advType: 'internet',
		},

		count_type_tv_adv: {
			title: 'ТВ-реклама',
			count: counts.count_type_tv_adv,
			isSelected: selectedItems.includes('tv'),
			advType: 'tv',
		},
		count_type_transport_adv: {
			title: 'Общестевенный транспорт',
			count: counts.count_type_transport_adv,
			isSelected: selectedItems.includes('transport'),
			advType: 'transport',
		},
	}
}
