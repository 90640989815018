import React, { useState } from 'react'
import {
	HandleActiveItemChangeType,
	HelpListNestedItemType,
} from '../../constants'
import s from './helpList.module.scss'
import cn from 'classnames'
import { NestedList } from './NestedList'
import { ConditionArrowUpDown } from '../../../../UtilityComponents/ConditionArrowUpDown'

type HelpListItemProps = {
	item: HelpListNestedItemType
	isActive: boolean
	activeNestedItem?: string
	changeActive: HandleActiveItemChangeType
}
export const HelpListItem = ({
	item,
	isActive,
	changeActive,
	activeNestedItem,
}: HelpListItemProps) => {
	const [open, setOpen] = useState(false)

	const isNestedItems = item.nestedList && item.nestedList.length > 0

	const handleClick = () => {
		if (isNestedItems) {
			setOpen(!open)
			return
		}

		changeActive(item.key)
	}

	return (
		<li
			className={cn(s.listItem, {
				[s.covered]: isActive && !isNestedItems,
			})}
		>
			<div onClick={handleClick} className={cn(s.listItemContent)}>
				<h6 className={cn({ [s.bold]: isActive })}>{item.title}</h6>
				{isNestedItems && (
					<button className={cn({ [s.open]: !open })}>
						<ConditionArrowUpDown
							styles={{ fill: isActive ? '#212529' : '#6C757D' }}
							open={open}
						/>
					</button>
				)}
			</div>

			{open && item?.nestedList?.length && (
				<NestedList
					activeNestedItem={activeNestedItem}
					itemId={item.key}
					isParentActive={isActive}
					changeNestedActive={changeActive}
					items={item.nestedList}
				/>
			)}
		</li>
	)
}
