import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { Dropdown, Modal, Spinner } from 'react-bootstrap'
import { useResizeDetector } from 'react-resize-detector'
import s from './ModalBookingLocation.module.scss'
import ProviderYandexMapNew from './ProviderYandexMapNew'
import FilterButtons, { MapPoint } from '../../filters/FilterButtons'
import BookingsSearch from '../../filters/BookingsSearch'
import SidebarPlacementsList from '../list/SidebarPlacementsList'
import CurrentFilters from '../../filters/CurrentFilters'
import useProviderData from 'src/stores/providerData'
import { useBookingsContext } from '../../context/BookingsProvider'
import ModalSetFilters from '../../filters/ModalSetFilters'

interface IModalBookingLocation {
	show: boolean
	onHide: () => void
	placements?: any[]
	openFilterModal: Function
	isPlacementsLoading?: boolean
}

const ModalBookingLocationNew: React.FC<IModalBookingLocation> = ({
	show,
	onHide,
	placements,
	isPlacementsLoading,
	openFilterModal,
	...props
}) => {
	const [leftMenuVisible, setLeftMenuVisible] = useState(true)
	const { width, ref: leftPanelRef } = useResizeDetector()
	const [showFilters, setShowFilters] = useState(true)
	const [showModal, setShowModal] = useState<boolean | string>(false)
	const [singleCoords, setSingleCoords] = useState<any[] | null>(null)

	const { filterByCompany, filterByAd } = useBookingsContext()
	const filterCountLabel = useMemo(
		() => filterByAd.length + filterByCompany.length,
		[filterByAd, filterByCompany]
	)

	useEffect(() => {
		if (placements?.length) {
			setSingleCoords([placements[0].lat, placements[0].lon])
		}
	}, [placements])

	return (
		<Modal show={show} onHide={onHide} animation={false} fullscreen={true}>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'}>Брони на карте</Modal.Title>
			</Modal.Header>

			<div className={s.modalFiltersContainer}>
				<div className={s.searcContainer}>
					<BookingsSearch />
				</div>
				<div className={s.filterContainer}>
					<FilterButtons
						hideMapButton={false}
						openFilterModal={() => {
							if (filterCountLabel === 0 || showFilters)
								return openFilterModal()
							return setShowFilters(true)
						}}
					/>
					<CurrentFilters
						show={showFilters}
						openFilterModal={openFilterModal}
						onHide={() => setShowFilters(false)}
					/>
				</div>
			</div>

			<Modal.Body className={'p-0'}>
				<div
					className={s.container}
					style={{
						paddingLeft: leftMenuVisible ? `${width}px` : '0',
						paddingTop: leftMenuVisible ? 76 : 0,
					}}
				>
					<div
						className={`${s.leftPanel} ${
							leftMenuVisible ? s.leftPanelShow : s.leftPanelHide
						}`}
						ref={leftPanelRef}
					>
						<SidebarPlacementsList
							placements={placements}
							onSelect={(placement) => {
								setSingleCoords([placement.lat, placement.lon])
							}}
							isLoading={isPlacementsLoading}
						/>
					</div>
					<ProviderYandexMapNew
						width={'100%'}
						height={'103%'}
						singleCoords={singleCoords}
						zoom={17}
						placements={placements}
						withZoomControls
					/>
				</div>
			</Modal.Body>
		</Modal>
	)
}

export default ModalBookingLocationNew
