import React from 'react'
import s from './placeRequirements.module.scss'

type PlaceRequirementsListItemProps = {
	list: Array<string>
	title: string
	isBorderBottom?: boolean
}
//USELESS
export const PlaceRequirementsListItem = ({
	list,
	title,
	isBorderBottom = true,
}: PlaceRequirementsListItemProps) => {
	return (
		<div
			className={s.listItem}
			style={{
				borderBottom: isBorderBottom ? '1px solid #ced4da' : 'none',
			}}
		>
			<span
				className={'text-secondary'}
				style={{ alignSelf: 'flex-start' }}
			>
				{title}:
			</span>
			<ul className={s.list}>
				{list.map((el, index) => (
					<li key={index}>{el}</li>
				))}
			</ul>
		</div>
	)
}
