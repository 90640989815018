import React from 'react'
import { Button } from 'react-bootstrap'

type NoContentProps = {
	onCreate: (itemId?: string) => void
}
export const NoContent = ({ onCreate }: NoContentProps) => (
	<div
		className={
			'd-flex flex-column justify-content-center w-100 h-100 align-items-center'
		}
		style={{ gap: '16px', minHeight: 'calc(100vh - 300px)' }}
	>
		<div style={{ fontSize: '40px' }}>🙂</div>
		<div>
			<h4 className={'text-secondary'}>
				Нет ни одного рекламного носителя
			</h4>
		</div>
		<div>
			<Button onClick={() => onCreate()}>
				Создать рекламный носитель
			</Button>
		</div>
	</div>
)
