import React, { useContext, useEffect, useState } from 'react'
import { TemplatesModal } from '../../templates-modal'
import { CreateCreativeFields } from '../../../CreateCreative/CreateCreativeForm'
import { ToastsContext } from 'src/components/UtilityComponents/ToastsContextProvider'
import useNotificationData from 'src/stores/notificationsData'
import { PlacementType } from 'src/stores/ADMarketTypes.types'
import useResponsive from 'src/utils/useResponsive'
import useBidRequest, { bidInfo } from '../../../CreateCreative/useBidRequest'
import useCampaignData from 'src/stores/campaignData'
import { OrderLayoutModal } from '../../order-layout-modal'
import { Button, Form, Modal } from 'react-bootstrap'
import CreativeFileInput from 'src/components/UtilityComponents/CreativeFileInput'
import { ReactComponent as MCR } from 'src/assets/images/railway-2.svg'
import BottomSheet from 'src/components/UtilityComponents/Bottomsheet'
import { BASE_URL } from 'src/constants/api'
import { MoscowCentRingRequirements } from './requirements/MoscowCentRingRequirements'
import { determineFileType, downloadByRequest } from 'src/utils'
import { NewContentCreatedStatusType } from 'src/stores/types/newContendCreatedType'
import { useCampaignInfoStore } from 'src/components/_client/campaign-info/store/campaignInfo.store'
import { getVinstantLinkByAdAdFormat } from 'src/components/_client/campaign-info/store/campaignInfo-store-selectors/campaignInfo.store.selectors'
import { EditVinstantModal } from 'src/components/_client/campaign-info/common/edit-vinstant-modal/EditVinstantModal'
import { InputExtraButtons } from '../../outdoor/common/input-extra-buttons/InputExtraButtons'
import { EditVinstantProcessingWrapped } from 'src/components/_client/campaign-info/common/edit-vinstant-processing/EditVinstantProcessing'

export const MoscowCentRingUpload = (props: any) => {
	const [campaign, campaignInterface] = useCampaignData()
	const { isHaveBidRequest } = useBidRequest()
	const { isMobile } = useResponsive()
	const [modalOpen, setModalOpen] = useState(false)

	const [loadingInProgress, setLoadingInProgress] = useState(false)

	const [showDesktopModal, setShowDesktopModal] = useState<
		false | 'requirement' | 'editVinstant'
	>(false)
	const [showBottomModal, setShowBottomModal] = useState<
		false | 'requirement'
	>(false)
	const [isOrderLayoutModal, setIsOrderLayoutModal] = useState(false)

	const handleCreateMaterial = async () => {
		setModalOpen(true)
	}

	const closeModal = () => {
		setModalOpen(false)
	}
	const virtualPlacement = {
		name: '',
		size: 'JPG до 5 МБ, MP4 до 10 МБ',
		media_type: {
			video_formats: ['MP4'],
			image_formats: ['JPG'],
			min_width: 640,
			min_height: 896,
			aspect_ratio: '5:7',
		},
		image: '',
		placement_type: {
			name: 'Цифровые экраны на МЦК',
		},
	} as Omit<PlacementType, 'placement_showing_counts'>

	const [_, notificationsInterface] = useNotificationData()
	const editVinstantKey = String(campaign.public_transport?.id)
	const publicTransportVinstantLink = useCampaignInfoStore((state) =>
		getVinstantLinkByAdAdFormat(state, {
			adFormatKey: 'public_transport_id',
			itemId: editVinstantKey,
		})
	)

	const createdMaterialStatus = notificationsInterface.getNewContentCreated(
		'publicTransport'
	) as NewContentCreatedStatusType | undefined

	const { addToast } = useContext(ToastsContext)

	const handleIsOrderLayoutOpen = () => {
		setModalOpen(false)
		setIsOrderLayoutModal(true)
	}
	const bidInfo: bidInfo = {
		id: campaign.public_transport?.id || 0,
		type: 'public_transport',
	}
	const handleOrderLayoutSubmit = async (values: CreateCreativeFields) => {
		for (let key in values) {
			if (
				values[key] === null ||
				values[key] === undefined ||
				values[key] === ''
			) {
				delete values[key]
			}
		}

		await campaignInterface.createNewBidRequest(values, {
			public_transport_id:
				campaign.public_transport?.id?.toString() || '',
		})
		setIsOrderLayoutModal(false)
	}

	const uploadedContent =
		campaign?.public_transport?.image || campaign?.public_transport?.video

	const handleStatusHasChanged = async (
		status: NewContentCreatedStatusType
	) => {
		if (status === 'start') {
			setLoadingInProgress(true)
		}
		if (status === 'end') {
			setLoadingInProgress(false)

			await campaignInterface.refetchSelected()

			addToast({
				text: `Загрузка контента для ${virtualPlacement.placement_type.name} завершена`,
				type: 'success',
			})

			notificationsInterface.setNewContendCreated({
				publicTransport: 'pending',
			})
		}
	}
	const handleUpload = () => {
		if (uploadedContent) {
			downloadByRequest(
				`adv_companies/${campaign.id}/transport_creative/download/`,
				uploadedContent
			)
		}
	}
	useEffect(() => {
		if (!!createdMaterialStatus) {
			handleStatusHasChanged(createdMaterialStatus)
		}
	}, [createdMaterialStatus])

	return (
		<>
			{modalOpen && (
				<TemplatesModal
					getOrderLayoutModal={handleIsOrderLayoutOpen}
					newContentKey="publicTransport"
					placement={virtualPlacement}
					instanceId={
						campaign.public_transport?.id?.toString() || '0'
					}
					clsName={'publictransportadvertising'}
					campaingId={campaign.id}
					closeModal={closeModal}
					modalOpen={modalOpen}
					screenResolution={'640*896'}
				/>
			)}

			<EditVinstantModal
				newContentKey={'publicTransport'}
				editVinstantLink={publicTransportVinstantLink}
				onClose={() => setShowDesktopModal(false)}
				open={showDesktopModal === 'editVinstant'}
			/>

			{isOrderLayoutModal && (
				<OrderLayoutModal
					onSubmit={handleOrderLayoutSubmit}
					open={isOrderLayoutModal}
					onClose={() => setIsOrderLayoutModal(false)}
				/>
			)}
			<div className={'d-flex flex-column gap-4'}>
				<div className={'d-flex justify-content-between'}>
					<h5 className={'mb-0'}>Цифровые экраны на МЦК</h5>
					<div>
						<MCR />
					</div>
				</div>

				<Form.Group>
					{createdMaterialStatus === 'start' ? (
						<EditVinstantProcessingWrapped />
					) : (
						<CreativeFileInput
							ExtraButton={
								<InputExtraButtons
									onEditVinstant={
										publicTransportVinstantLink
											? () =>
													setShowDesktopModal(
														'editVinstant'
													)
											: undefined
									}
									onUpload={handleUpload}
								/>
							}
							allowedExtension={['jpg', 'png', 'mp4']}
							getConditionBodyField={determineFileType}
							loadingInProgress={loadingInProgress}
							bidInfo={bidInfo}
							initialValue={{
								src: uploadedContent || null,
								name: 'Текущий материал',
							}}
							updateUrl={`${BASE_URL}/adv_companies/${campaign.id}/transport-adv/`}
							nameInBody={determineFileType(
								uploadedContent || ''
							)}
							description={
								' Изображения — JPG. Видео - MP4.  Разрешение: 640 x 896 px. Размер: JPG до 5 МБ, MP4 до 10 МБ'
							}
							removeMethod={'PATCH'}
							onFileLoad={() =>
								campaignInterface.refetchSelected()
							}
							onFileDelete={() =>
								campaignInterface.refetchSelected()
							}
						/>
					)}
				</Form.Group>
				<div
					className={isMobile ? 'd-grid' : 'd-flex'}
					style={{
						gap: isMobile ? '8px' : '16px',
						marginTop: '16px',
					}}
				>
					{!isHaveBidRequest(bidInfo) && !uploadedContent && (
						<Button
							variant={'light'}
							onClick={handleCreateMaterial}
							style={{
								width: '100%',
							}}
						>
							Создать материал
						</Button>
					)}

					<Button
						variant={'light'}
						onClick={() => {
							if (isMobile) {
								setShowBottomModal('requirement')
							} else {
								setShowDesktopModal('requirement')
							}
						}}
						style={{
							width: '100%',
						}}
					>
						Требования к материалу
					</Button>
				</div>

				{/*Modals*/}
				{!isMobile && (
					<Modal
						show={
							!!showDesktopModal &&
							showDesktopModal !== 'editVinstant'
						}
						onHide={() => setShowDesktopModal(false)}
						centered
						// size={'lg'}
						animation={true}
					>
						<Modal.Header closeButton>
							<Modal.Title
								as={'h5'}
								style={{ paddingLeft: '8px' }}
							>
								Требования к цифровому экрану МЦК
							</Modal.Title>
						</Modal.Header>
						<Modal.Body className={'p-0'}>
							<MoscowCentRingRequirements isHeader={false} />
						</Modal.Body>
					</Modal>
				)}
				{isMobile && (
					<BottomSheet
						show={Boolean(showBottomModal)}
						onHide={() => setShowBottomModal(false)}
						fullSize={true}
						bgOpacity={0.5}
						touchEvents={true}
					>
						<MoscowCentRingRequirements isHeader />
					</BottomSheet>
				)}
			</div>
		</>
	)
}
