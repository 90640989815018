import { BookingState } from 'src/stores/ADMarketTypes.types'

export const getAdjustmentMessage = (data: {
	state: BookingState
	adjusmentMessage: string
	rejectMessage: string
}) => {
	if (data.state === BookingState.Reject) {
		return data.rejectMessage
	}
	if (data.state === BookingState.Adjustment) {
		return data.adjusmentMessage
	}

	return ''
}
