import { DetailedCampaignInfo } from '../types/ModerationRequestTypes'

export const checkIsConfirmAvailable = (
	detailedCampaign: DetailedCampaignInfo
): boolean => {
	const {
		adstream,
		booking,
		district_booking,
		internet_vk,
		internet_yandex,
	} = detailedCampaign

	if (adstream?.state === 'adjustment') return false

	if (internet_vk?.state === 'adjustment') return false
	if (internet_yandex?.state === 'adjustment') return false

	if (booking?.length > 0) {
		const anyBookingAdjustment = booking.find(
			(item) => item?.state === 'adjustment'
		)
		if (anyBookingAdjustment) return false
	}

	if (district_booking?.length > 0) {
		const anyDistrictBookingAdjustment = district_booking.find(
			(item) => item?.state === 'adjustment'
		)
		if (anyDistrictBookingAdjustment) return false
	}

	return true
}
