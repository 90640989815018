import React, { FC, useEffect, useMemo, useState } from 'react'
import { TvModalContent } from './common/TvControls'
import { TvType } from './TvStateView.types'
import { Modal } from 'react-bootstrap'
import { AdStreamMediaRequirements } from '../../create-campaign/Step3-Upload/Tv/TvUploadConstants'
import DetailItem from '../../../../utils/DetailItem'
import useCampaignData from '../../../../stores/campaignData'
import RowLabelValue from '../../../../utils/RowLabelValue'
import IntlFormatter from '../../../../utils/intl-money-formatter'
import { spreadArray } from '../../../_provider/Mediaformats/MediaInfo'
import {
	ChannelSectionType,
	fetchAdstreamInfo,
} from '../../create-campaign/Step2-ADformats/TVAd/TVvideo'
import getValuesArray from '../../../../utils/getValuesArray'
import LegalInformation from 'src/utils/LegalInformation'
import s from '../../create-campaign/Step3-Upload/DataUpload.module.scss'
import useResponsive from 'src/utils/useResponsive'

interface ITvModalDesktop {
	modalContent: TvModalContent | false
	tvType: TvType
	onClose: () => any
}

const TvModalDesktop: FC<ITvModalDesktop> = ({
	modalContent,
	tvType,
	onClose,
}) => {
	const isMediaPlan = modalContent === TvModalContent.MediaPlan
	const isRequirements = modalContent === TvModalContent.Requirements
	const isAdstream = tvType === TvType.ADSTREAM
	const titleText = (): string => {
		if (isMediaPlan)
			return isAdstream
				? 'Медиаплан показа видеоролика при переключении канала'
				: 'Медиаплан показа всплывающего баннера при просмотре канала'

		if (isRequirements)
			return isAdstream
				? 'Технические требования к загрузке видеофайла'
				: 'Технические требования к загрузке файла'

		return ''
	}

	return (
		<Modal show={modalContent} onHide={onClose} centered>
			<Modal.Header closeButton>
				<Modal.Title as={'h5'} style={{ marginLeft: '8px' }}>
					{titleText()}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className={'p-0'}>
				{isRequirements && <RenderRequirements tvType={tvType} />}
				{isMediaPlan && <RenderMediaPlan tvType={tvType} />}
			</Modal.Body>
		</Modal>
	)
}

export const RenderRequirements = ({ tvType }: { tvType: TvType }) => {
	const isAdstream = tvType === TvType.ADSTREAM
	const { isMobile } = useResponsive()
	const detailProps = { fontSize: '16px' }
	return (
		<section
			style={{
				padding: isMobile ? '16px' : '40px',
				maxWidth: '100%',
				overflow: 'hidden',
			}}
		>
			<h5>Видео</h5>
			{AdStreamMediaRequirements.video.map(
				({ name, value }, index, arr) => (
					<DetailItem
						left={name}
						right={
							name === 'Разрешение:' && isAdstream
								? '1920 × 1080 px'
								: value
						}
						bottomDivider={index !== arr.length - 1}
						{...detailProps}
						key={name}
					/>
				)
			)}
			<h5 className={'mt-3'}>Аудио</h5>
			{AdStreamMediaRequirements.audio.map(
				({ name, value }, index, arr) => (
					<>
						<DetailItem
							left={name}
							right={value}
							bottomDivider={index !== arr.length - 1}
							{...detailProps}
							key={name}
						/>
					</>
				)
			)}

			<div
				className={'w-100 d-flex justify-content-between'}
				style={{
					borderBottom: '1px solid #CED4DA',
				}}
			></div>
			<div
				className={'w-100 d-flex justify-content-between'}
				style={{
					marginTop: '8px',
				}}
			>
				<span className={'text-secondary'}>
					Юридическая информация:
				</span>
			</div>
			<div style={{ marginRight: '-20px' }}>
				<LegalInformation />

				<div
					className={'w-100 d-flex justify-content-between'}
					style={{
						borderBottom: '1px solid #CED4DA',
					}}
				></div>
				<span className={'text-secondary'}>
					Дополнительная информация:
				</span>
				<p className={s.dopInfo}>
					Ознакомиться с требованиями вы также можете по{' '}
					<a href="https://www.21outdoor.ru/upload/medialibrary/fa3/21_vek_TV%20%D0%A2%D0%A2_6x3_vinyl_bez_polei.jpg">
						ссылке.
					</a>
				</p>
			</div>
		</section>
	)
}

const RenderMediaPlan = ({ tvType }: { tvType: TvType }) => {
	const [campaign] = useCampaignData()
	const isAdstream = tvType === TvType.ADSTREAM
	const [variants, setVariants] = useState<null | {
		sections: ChannelSectionType[]
	}>(null)
	const { adstream, teletarget } = campaign
	const h5Style = {
		marginBottom: 0,
		fontSize: '16px',
	}
	//Загрузка всех списков
	//TODO Сделать что-то с хранением этих данных, их не нужно каждый раз перезапрашивать
	useEffect(() => {
		if (!isAdstream) return
		;(async () => {
			const res = await fetchAdstreamInfo()
			setVariants(res)
		})()
	}, []) // eslint-disable-line react-hooks/exhaustive-deps
	//Перевод значений из айдишников в названия, только для AdStream
	const computedValues = useMemo<{
		channels: string[]
	}>(() => {
		const c: string[] = []
		if (!isAdstream) return { channels: c }
		campaign.adstream.selection_ids.forEach((id) => {
			const el = variants?.sections.find((s) => s.id === id)
			if (el) c.push(el.name)
		})
		if (c.length !== 0) return { channels: c }
		return { channels: c }
	}, [campaign.adstream, variants, tvType]) // eslint-disable-line react-hooks/exhaustive-deps
	//AdStream
	if (isAdstream)
		return (
			<section style={{ padding: '24px' }}>
				<RowLabelValue
					label={'Частота показов в час:'}
					value={adstream.frequency}
					isFirst={true}
				/>
				{/*<RowLabelValue*/}
				{/*	label={'Регионы:'}*/}
				{/*	value={spreadArray(computedValues.regions)}*/}
				{/*/>*/}
				<RowLabelValue
					label={'Каналы:'}
					value={spreadArray(computedValues.channels)}
				/>
				<div className={'mt-4'}>
					<DetailItem
						left={<h5 style={h5Style}>Всего показов:</h5>}
						right={<h5 style={h5Style}>{adstream.views_count}</h5>}
						bottomDivider={true}
					/>
					<DetailItem
						left={<h5 style={h5Style}>Стоимость:</h5>}
						right={
							<h5 style={h5Style}>
								{IntlFormatter.format(adstream?.amount || 0)}{' '}
								без НДС
							</h5>
						}
						bottomDivider={false}
					/>
				</div>
			</section>
		)
	//Teletarget
	return (
		<section style={{ padding: '24px' }}>
			<RowLabelValue
				label={'Целевая аудитория:'}
				value={spreadArray(getValuesArray(campaign.genders))}
			/>
			<RowLabelValue
				label={'Возраст аудитории:'}
				value={spreadArray(getValuesArray(campaign.ages))}
			/>
			<RowLabelValue
				label={'Регионы:'}
				value={spreadArray(getValuesArray(campaign.show_regions))}
			/>
			<div className={'mt-4'}>
				<DetailItem
					left={<h5 style={h5Style}>Охват:</h5>}
					right={<h5 style={h5Style}>{adstream.views_count}</h5>}
					bottomDivider={true}
				/>
				<DetailItem
					left={<h5 style={h5Style}>Стоимость:</h5>}
					right={
						<h5 style={h5Style}>
							{IntlFormatter.format(adstream?.amount || 0)}
						</h5>
					}
					bottomDivider={false}
				/>
			</div>
		</section>
	)
}

export default TvModalDesktop
